import { Divider } from '@mui/material';
import React, { Fragment } from 'react';
import {
  ChatBoxContainer,
  ListItemWrapper,
  QuestionContainer,
  QuestionHead,
  QuestionHeaderDiv,
  QuestionItem,
  QuestionListItem,
  QuestionWrapper,
} from './styles';

function SampleQuestions({ data }: { data: any }): React.JSX.Element {
  return (
    <ChatBoxContainer
      container
      justifyContent={'space-between'}
      alignItems={'flex-start'}
    >
      <QuestionContainer id="outer">
        <QuestionHeaderDiv>Popular Questions</QuestionHeaderDiv>
        <QuestionWrapper delay={4} duration={10} id="inner">
          {data?.map((item, index) => {
            return (
              <QuestionItem className="items" key={index}>
                <QuestionHead>{item.head}</QuestionHead>
                <ListItemWrapper>
                  {item.list.map((listItem, listIndex) => (
                    <Fragment key={listIndex}>
                      <QuestionListItem>
                        <p className="viewpoint-item">{listItem}</p>
                      </QuestionListItem>
                      {listIndex < item.list.length - 1 && <Divider />}
                    </Fragment>
                  ))}
                </ListItemWrapper>
              </QuestionItem>
            );
          })}
        </QuestionWrapper>
      </QuestionContainer>
    </ChatBoxContainer>
  );
}

export default SampleQuestions;
