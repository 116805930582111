import React from 'react';
import { Grid } from '@mui/material';
import {
  FileName,
  OneLineDesc,
  SugestedCardsWrapper,
  SuggestedCardContainer,
  TabHeader,
  TabHeaderContainer,
} from './styles';
import { MOCKED_CIO } from './utils';
import CioCard from '@pages/Tour/reusable/CioCard';
import TextInfo from '@pages/Tour/reusable/TextInfo';
import TourContent from './TourContent';
import { TourComponentProps } from './types';

const FirstComponent = () => {
  return (
    <Grid>
      <TextInfo
        textContent={
          'Next, let’s look at Current Markets which include CIO Views and Current Events. Here, you can access the latest market research (CIO Views) and market events (Current Events) from your CIO office, if one exists, or from a curated list of sources and surfaces them as starting points for ideation.'
        }
      />
    </Grid>
  );
};
const SecondComponent = () => {
  const cioData = MOCKED_CIO;
  const [selectedTab] = React.useState<number | null>(1);

  return (
    <SuggestedCardContainer>
      <SugestedCardsWrapper duration={10} delay={3}>
        <TabHeaderContainer>
          <TabHeader active={selectedTab === 1}>CIO Views</TabHeader>
          <TabHeader active={selectedTab === 2}>Current Events</TabHeader>
          <TabHeader active={selectedTab === 3}>Archives</TabHeader>
        </TabHeaderContainer>
        {selectedTab === 1 ? (
          <Grid item xs={12}>
            <OneLineDesc>
              Views derived from market research, translating insights into
              actionable strategies, enabling financial advisors to optimize
              portfolios amid dynamic market events.
            </OneLineDesc>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <OneLineDesc>
              Advisors can strategize amidst global events, aiming to optimize
              client portfolios in an ever-evolving economic landscape
            </OneLineDesc>
          </Grid>
        )}

        {cioData?.data?.map((item, index) => {
          if (JSON.stringify(item?.data) !== '[{}]' && item?.data !== null)
            return (
              <div key={index}>
                <FileName>{item?.filename}</FileName>
                <CioCard
                  data={item.data}
                  filename={item.filename}
                  clientCount={10}
                />
              </div>
            );
        })}
      </SugestedCardsWrapper>
    </SuggestedCardContainer>
  );
};

function SuggestedActions({
  setAwaitingNavItem,
  setRouteState,
  endTour,
}: TourComponentProps): React.JSX.Element {
  // eslint-disable-next-line react/jsx-key
  const components = [
    // eslint-disable-next-line react/jsx-key
    { component: <FirstComponent />, timeToShow: 5000 },
    // eslint-disable-next-line react/jsx-key
    {
      component: <SecondComponent />,
      timeToShow: 17000,
      nextComponent: 'My Clients',
    },
  ];

  return (
    <TourContent
      setAwaitingNavItem={setAwaitingNavItem}
      setRouteState={setRouteState}
      endTour={endTour}
      components={components}
      showFooterArrow={false}
      margin={50}
    />
  );
}

export default SuggestedActions;
