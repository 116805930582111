import React, { useEffect, useState } from 'react';
import SplashCard from './SplashCard';
import { SplashCardWrapper } from './style';

interface SplashContentProps {
  last: boolean;
  section: any;
  renderDelay: number;
  style: any;
  contentOverideStyle?: any;
  dataList: any;
  container?: boolean;
}

function SplashContent(props: SplashContentProps): React.JSX.Element {
  const {
    last = false,
    section = null,
    renderDelay = 0,
    style = {
      animationName: 'ease-in',
      animationTimingFunction: 'ease-in',
      animationDuration: '0.5s',
      transition: 'opacity ease-in 0.5s',
    },
    contentOverideStyle = {},
    dataList = [],
  } = props;
  const [hide] = useState<boolean>(false);
  const [content, setContent] = useState<any>([]);
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  useEffect(() => {
    const __delay =
      dataList[currentIndex] && dataList[currentIndex].renderDelay
        ? dataList[currentIndex].renderDelay
        : renderDelay;
    const timer = setInterval(() => {
      if (dataList && currentIndex < dataList.length) {
        // Create the element and add it to the content array
        const k = dataList[currentIndex];
        const newContent = [
          ...content,
          <SplashCard
            delay={k.animationDuration}
            key={currentIndex}
            sx={{
              animationName: k.animationName,
              animationTimingFunction: k.animationName,
              animationDuration: k.animationDuration,
            }}
          >
            {k.section}
          </SplashCard>,
        ];

        // Update the content state with the new elements
        setContent(newContent);

        // Increment the current index
        setCurrentIndex(currentIndex + 1);
      } else {
        // Stop the timer when all elements have been rendered
        clearInterval(timer);
      }
    }, __delay);

    return () => {
      clearInterval(timer);
    };
  }, [dataList, currentIndex]);

  return (
    <SplashCardWrapper
      hide={hide}
      last={last}
      justifyContent={'center'}
      alignItems={'flex-start'}
      sx={{
        ...style,
      }}
    >
      <div style={{ height: '100%', ...contentOverideStyle }}>
        <React.Fragment>{section}</React.Fragment>
        <React.Fragment>{content}</React.Fragment>
      </div>
    </SplashCardWrapper>
  );
}

export default SplashContent;
