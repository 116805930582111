import { Grid } from '@mui/material';
import React from 'react';
import {
  QuestionsCardContainer,
  QuestionsCardItem,
  QuestionsCardItemMarker,
  QuestionsCardItems,
  QuestionsCardItemText,
  QuestionsCardsContainer,
  QuestionsCardsTitle,
  QuestionsCardTitle,
} from './styles';

interface QuestionsCardProps {
  title: string;
  titleIcon: string;
  questions: {
    title: string;
    icon: string;
    questions: string[];
  }[];
  handleQuestionClick: (question: string) => void;
}

function QuestionsCards(props: QuestionsCardProps): React.JSX.Element {
  const calculateGridMdColumns = (questionsLength: number): number =>
    questionsLength <= 4 ? 12 / questionsLength : 3;

  return (
    <QuestionsCardsContainer>
      <QuestionsCardsTitle>
        <img src={props.titleIcon} alt="" />
        {props.title}
      </QuestionsCardsTitle>

      <Grid container>
        {props.questions.map((card, index) => (
          <Grid
            item
            xs={12}
            md={calculateGridMdColumns(props.questions.length)}
            p="3px"
          >
            <QuestionsCardContainer key={index}>
              <QuestionsCardTitle>
                <img src={card.icon} alt={card.title} />
                {card.title}
              </QuestionsCardTitle>

              <QuestionsCardItems>
                {card.questions?.map((question, index) => (
                  <QuestionsCardItem
                    key={index}
                    onClick={() => props.handleQuestionClick(question)}
                  >
                    <QuestionsCardItemMarker>✦</QuestionsCardItemMarker>
                    <QuestionsCardItemText>{question}</QuestionsCardItemText>
                  </QuestionsCardItem>
                ))}
              </QuestionsCardItems>
            </QuestionsCardContainer>
          </Grid>
        ))}
      </Grid>
    </QuestionsCardsContainer>
  );
}

export default QuestionsCards;
