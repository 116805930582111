/* eslint-disable react/prop-types */
import * as React from 'react';

function User(): React.JSX.Element {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <path
          d="M14.4324 13.2517C13.4806 11.6061 12.0137 10.4261 10.3018 9.8667C11.1486 9.36261 11.8065 8.5945 12.1744 7.68032C12.5424 6.76614 12.6001 5.75645 12.3387 4.8063C12.0773 3.85615 11.5112 3.01807 10.7274 2.42078C9.94359 1.82349 8.98538 1.5 7.99993 1.5C7.01447 1.5 6.05626 1.82349 5.27244 2.42078C4.48863 3.01807 3.92255 3.85615 3.66114 4.8063C3.39973 5.75645 3.45744 6.76614 3.82542 7.68032C4.19339 8.5945 4.85128 9.36261 5.69805 9.8667C3.98618 10.4255 2.5193 11.6055 1.56743 13.2517C1.53252 13.3086 1.50936 13.372 1.49933 13.438C1.4893 13.504 1.49259 13.5713 1.509 13.636C1.52542 13.7008 1.55463 13.7615 1.59492 13.8148C1.63521 13.868 1.68575 13.9127 1.74357 13.9461C1.80138 13.9795 1.8653 14.0009 1.93156 14.0092C1.99781 14.0175 2.06505 14.0125 2.12931 13.9943C2.19358 13.9762 2.25356 13.9454 2.30573 13.9038C2.3579 13.8621 2.40119 13.8104 2.43305 13.7517C3.61055 11.7167 5.6918 10.5017 7.99993 10.5017C10.3081 10.5017 12.3893 11.7167 13.5668 13.7517C13.5987 13.8104 13.642 13.8621 13.6941 13.9038C13.7463 13.9454 13.8063 13.9762 13.8705 13.9943C13.9348 14.0125 14.002 14.0175 14.0683 14.0092C14.1345 14.0009 14.1985 13.9795 14.2563 13.9461C14.3141 13.9127 14.3646 13.868 14.4049 13.8148C14.4452 13.7615 14.4744 13.7008 14.4908 13.636C14.5073 13.5713 14.5106 13.504 14.5005 13.438C14.4905 13.372 14.4673 13.3086 14.4324 13.2517ZM4.49993 6.0017C4.49993 5.30947 4.7052 4.63278 5.08978 4.0572C5.47437 3.48163 6.02099 3.03303 6.66053 2.76812C7.30008 2.50322 8.00381 2.4339 8.68274 2.56895C9.36168 2.704 9.98532 3.03734 10.4748 3.52683C10.9643 4.01631 11.2976 4.63995 11.4327 5.31888C11.5677 5.99782 11.4984 6.70155 11.2335 7.34109C10.9686 7.98063 10.52 8.52726 9.94442 8.91184C9.36885 9.29643 8.69216 9.5017 7.99993 9.5017C7.07197 9.50071 6.18231 9.13164 5.52615 8.47548C4.86999 7.81932 4.50092 6.92965 4.49993 6.0017Z"
          fill="#535256"
        />
      </svg>
    </>
  );
}

const MemoUser = React.memo(User);
export default MemoUser;
