import { price } from '@common/number';
import { validArray } from '@common/validArray';
import { getChatInitResponseState } from '@features/Chat/selector';
import { actions } from '@features/Chat/slice';
import ChatIcon from '@icons/Chat';
import ConfigurationIcon from '@icons/Configuration';
import SearchIcon from '@icons/Search';
import { Grid } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getClientDisplayName, getMenuOptions } from './helpers';
import {
  CardFooter,
  CardPrompQuestions,
  CardPrompQuestionsItem,
  CardPrompQuestionsItemMarker,
  CardPrompQuestionsItemText,
  CardPromptButton,
  CardWrapper,
} from './styles';
import { ClientCardProps } from './types';
import ClientIcon from '@icons/UserCircleOutlined.svg';
import ProspectIcon from '@icons/UserSquareOutlined.svg';

function ClientCard({
  clientName,
  priceValue,
  handleDrawerOpen,
  clientId,
  title = [],
  portfolioDiff,
  isProspect,
}: ClientCardProps): React.JSX.Element {
  const [isHover, setIsHover] = useState<boolean | null>(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { chatHistory: initHistoryState } = useSelector(
    getChatInitResponseState,
  );

  const isChatStreaming = localStorage.getItem('streaming') === 'true';

  const newTitle = validArray(title) ? title : ['No Relevant Viewpoint'];

  const menuOptions = getMenuOptions(newTitle, clientName, 0);

  const handleSelectedClick = (option: any) => {
    navigate(option.href);
    dispatch(
      isChatStreaming
        ? actions.setSearchQuery(option.value)
        : actions.searchQueryRequest({ query: option.value }),
    );
  };

  const addSuggestedQuestions = ({
    user,
    system,
    questions,
  }: {
    user: string;
    system: string;
    questions: string[];
  }) => {
    navigate('/conversations/start');
    dispatch(actions.searchSampleQuestion({ isSampleQuestionAsked: false }));
    dispatch(
      actions.loadChatSuccess({
        chatHistory: [
          ...initHistoryState,
          {
            id: '',
            time: '',
            isSender: true,
            isChatHistory: true,
            mock: true,
            type: 'text',
            data: user,
          },
          {
            id: '65b8f06d84d66bb8215d3173',
            time: '',
            isSender: false,
            isShowFeedback: false,
            isChatHistory: false,
            mock: true,
            data: [
              { type: 'text', content: system },
              {
                type: 'sample_basic_queries',
                content: questions.map(head => ({ head })),
              },
            ],
          },
        ],
      }),
    );
  };

  const action = ['Analyze', 'Adjust', 'Outputs'];
  const icons = [<SearchIcon />, <ConfigurationIcon />, <ChatIcon />];

  const conversationsButtons = [
    {
      href: '/conversations/start',
      key: 'Display adjustments for viewpoint',
      value: `Analyze ${clientName}’s portfolio`,
      response: 'Sure! What would you like to analyze?',
      questions: [
        `Show ${clientName}’s asset allocation`,
        `How has ${clientName}’s portfolio performed Year To Date?`,
        `How would ${clientName}’s portfolio be impacted if US equities fell 20%?`,
      ],
    },
    {
      href: '/conversations/start',
      key: 'Display adjustments for viewpoint',
      value: `Make Adjustments to ${clientName}’s Portfolio`,
      response: 'Sure! What would you like to adjust?',
      questions: [
        `Suggest fund options for ${clientName} to lower risk`,
        `Suggest fund options for ${clientName} to lower fees`,
        `What adjustments can I make to reduce ${clientName}’s sensitivity to Equity Prices?`,
      ],
    },
    {
      href: '/conversations/start',
      key: 'Display adjustments for viewpoint',
      value: `Suggest some client communications actions for ${clientName}`,
      response: 'Sure! What would you like to prepare for your client?',
      questions: [
        `Suggest a Model for ${clientName}`,
        `Send a Risk Assessment to ${clientName}`,
        `Generate a proposal for ${clientName}`,
      ],
    },
  ];

  return (
    <CardWrapper
      container
      item
      xs={12}
      direction={'column'}
      columnGap={1.25}
      rowGap={1.25}
      justifyContent={'space-between'}
      onClick={() =>
        handleDrawerOpen(clientId, title, portfolioDiff, clientName)
      }
      onMouseOver={() => {
        setIsHover(true);
      }}
      onMouseLeave={() => {
        setIsHover(false);
      }}
      position={'relative'}
    >
      <Grid
        item
        container
        justifyContent={'space-between'}
        alignItems={'center'}
        mb={'-5px'}
        padding={1}
        paddingBottom={25}
      >
        <Grid item xs={9} fontSize={'16px'} fontWeight={700}>
          {clientName && <span>{getClientDisplayName(clientName)}</span>}
        </Grid>
        <Grid
          item
          xs={3}
          container
          justifyContent={'right'}
          alignItems={'center'}
        >
          <Grid item fontSize={'17px'} fontWeight={500} color={'#666666'}>
            {priceValue || priceValue === 0 ? (
              <span>{price(priceValue, true, false, false)}</span>
            ) : (
              <span>N/A</span>
            )}
          </Grid>
        </Grid>
        <CardFooter container>
          <p className="card-type">
            <img
              className="card-type-icon"
              src={isProspect ? ProspectIcon : ClientIcon}
              alt=""
            />
            {isProspect ? 'Prospect' : 'Client'}
          </p>
        </CardFooter>
      </Grid>

      <Grid
        item
        container
        position={'absolute'}
        top={65}
        left={0}
        height="180px"
        pb="10px"
        flexDirection={'column'}
        justifyContent={'space-between'}
        alignContent={'right'}
        paddingX="12px"
        sx={{
          display: isHover ? 'flex' : 'none',
          backgroundColor: '#fff',
        }}
      >
        <Grid
          item
          container
          flexDirection={'row'}
          justifyContent={'space-between'}
        >
          {conversationsButtons.map((conversation, index) => (
            <Grid item key={index} xs={4} paddingX="2px">
              <CardPromptButton
                variant="text"
                disableElevation={true}
                sx={{
                  display: 'block',
                  textTransform: 'capitalize',
                  borderRadius: '24px',
                  border: 0,
                  width: '100%',
                  height: '42px',
                  backgroundColor: '#000000',
                  color: '#000000',
                  '&:hover': {
                    backgroundColor: '#00C3DE',
                    border: 0,
                  },
                }}
                onClick={event => {
                  event.stopPropagation();
                  addSuggestedQuestions({
                    user: conversation.value,
                    system: conversation.response,
                    questions: conversation.questions,
                  });
                }}
              >
                <span className="text">{action[index]}</span>
                <span className="icon">{icons[index]}</span>
              </CardPromptButton>
            </Grid>
          ))}
        </Grid>
        <Grid>
          <CardPrompQuestions>
            {menuOptions.map((option, index) => (
              <CardPrompQuestionsItem
                key={index}
                onClick={() => handleSelectedClick(option)}
              >
                <CardPrompQuestionsItemMarker>✦</CardPrompQuestionsItemMarker>
                <CardPrompQuestionsItemText>
                  {option.key}
                </CardPrompQuestionsItemText>
              </CardPrompQuestionsItem>
            ))}
          </CardPrompQuestions>
        </Grid>
      </Grid>
    </CardWrapper>
  );
}

export default ClientCard;
