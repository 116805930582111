/* eslint-disable react/no-unescaped-entities */
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import React, { useState } from 'react';
import QuestionCard from './QuestionCard';
import { useRESTQuery } from '@hooks/useRESTQuery';
import Loader from '@components/Loader';
import DialogModal from '@components/DialogModal';
import {
  AddIconWrapper,
  CardMainContainer,
  ParentContainer,
  QuestionButton,
  Text,
} from './styles';
import QuestionsModal from './QuestionsModal';

function Questions(): React.JSX.Element {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [isModalOpen, setIsModalOpen] = useState(false);

  const { data: extractData, isLoading } = useRESTQuery(
    ['extract-questions'],
    {
      endpoint: `/enterprise/enterpriseQuestions`,
      method: 'GET',
      headers: {
        'X-Tifin-Ai-Auth': localStorage.getItem('x-tifin-ai-token'),
      },
    },
    {
      select: res => res.data,
    },
  );

  const handleClose = () => {
    setIsModalOpen(false);
  };
  const handleUploadButtonClick = () => {
    setIsModalOpen(true);
  };
  if (isLoading) return <Loader />;
  return (
    <ParentContainer container px={isMobile ? 1 : 3}>
      <CardMainContainer
        item
        container
        rowGap={2}
        justifyContent={'flex-start'}
      >
        <Grid item container alignItems={'center'} rowGap={2}>
          <Grid item container xs={12} md={10} direction={'column'} rowGap={1}>
            <Grid item>
              <p className="title">What can I ask Sage Questions</p>
            </Grid>
            <Grid item>
              <p className="desc">
                Here you can add and configure questions for advisors when they
                want to explore the questions they can ask Sage
              </p>
            </Grid>
          </Grid>
          <Grid item xs={8} md={2}>
            <QuestionButton
              variant="contained"
              color="primary"
              onClick={handleUploadButtonClick}
            >
              <AddIconWrapper />
              <Text>Add Questions</Text>
            </QuestionButton>
          </Grid>
        </Grid>
        {Array.isArray(extractData) &&
          extractData?.length > 0 &&
          extractData.map((question, index) => (
            <React.Fragment key={index}>
              {Array.isArray(question.draftQuestion) ? (
                <QuestionCard
                  id={question?.id}
                  documentId={question?.documentId}
                  title={question?.category}
                  description={question?.category}
                  status={question?.status}
                  allowPublish={question?.allowPublish}
                  questions={question.draftQuestion}
                />
              ) : (
                <></>
              )}
            </React.Fragment>
          ))}
      </CardMainContainer>
      <DialogModal
        openModal={isModalOpen}
        handleClose={handleClose}
        title="Add Question"
        showTitle
      >
        <QuestionsModal
          description={''}
          questions={['']}
          handleClose={handleClose}
          id={''}
        />
      </DialogModal>
    </ParentContainer>
  );
}

export default Questions;
