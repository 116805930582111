import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
//sentry
import { ThemeProvider as MuiThemeProvider } from '@mui/material';
import { ThemeProvider as MuiStylesThemeProvider } from '@mui/styles';
import * as Sentry from '@sentry/react';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ThemeProvider } from 'styled-components';

import { COLORS } from 'utils/styles/colors';
import theme from 'utils/styles/theme';

import { configureAppStore } from 'store/configureStore';

import reportWebVitals from './reportWebVitals';

// Import root app
import App from 'app';
import { settings } from 'config';
import { mockServer } from 'mock_server';

async function initializeApp() {
  if (settings.mock_server.enabled) {
    await mockServer.start({
      onUnhandledRequest: 'bypass',
    });
  }

  const store = configureAppStore();
  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
  );

  // Initialise React Query Client
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        retry: 1,
        retryDelay: (attemptIndex: number) => 1000 * 2 ** attemptIndex,
        cacheTime: 1000 * 60 * 60, // 60 minutes of cache time
        staleTime: 1000 * 30, // 30 seconds of stale time
      },
    },
  });

  // Initialize Sentry
  Sentry.init({
    dsn: settings.sentry.dsn,
    environment: settings.sentry.environment,
    integrations: [
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
    ignoreErrors: ['ResizeObserver loop limit exceeded'],
  });

  root.render(
    <Provider store={store}>
      <HelmetProvider>
        <BrowserRouter>
          <QueryClientProvider client={queryClient}>
            {/* !TODO move theme to APP */}
            <MuiStylesThemeProvider theme={theme}>
              <MuiThemeProvider theme={theme}>
                <ThemeProvider theme={COLORS}>
                  <App />
                </ThemeProvider>
              </MuiThemeProvider>
            </MuiStylesThemeProvider>
            {/* !TODO move theme to APP */}
          </QueryClientProvider>
        </BrowserRouter>
      </HelmetProvider>
    </Provider>,
  );

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
}

initializeApp();
