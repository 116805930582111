import { OktaAuth } from '@okta/okta-auth-js';
const SCOPES = ['openid', 'profile', 'email', 'offline_access'];
const CLIENT_ID = process.env.REACT_APP_OKTA_CLIENT_ID;
const REDIRECT_URI = `${window.location.origin}/login/callback`;
const ISSUER = `https://${process.env.REACT_APP_OKTA_ISSUER}/oauth2/default`;
const LABELS = {
  en: {
    // Labels
    'primaryauth.username.placeholder': 'Email',
    'error.username.required': 'Please enter an email',
    remember: 'Remember me',
    forgotpassword: 'Forgot Password?',
  },
};

export const config = {
  oidc: {
    issuer: ISSUER,
    clientId: CLIENT_ID,
    scopes: SCOPES,
    redirectUri: REDIRECT_URI,
  },
  widget: {
    issuer: ISSUER,
    i18n: LABELS,
    clientId: CLIENT_ID,
    scopes: SCOPES,
    redirectUri: REDIRECT_URI,
    useInteractionCodeFlow: process.env.REACT_APP_ENV === 'PROD', // temporary check until the Okta dev instance is upgrated to Identity Engine
  },
};

export const oktaAuth = new OktaAuth(config.oidc);
