import {
  AllEffect,
  call,
  all,
  put,
  fork,
  select,
  takeLatest,
  delay,
  takeEvery,
} from 'redux-saga/effects';
import { actions } from './slice';
import { actions as globalActions } from '../Global/slice';
import { getChatInitResponseState, isSampleQuestionsCalled } from './selector';
import { getChatHistory, getBotResponse, submitChatFeedback } from './api';
import { ChatInitResponseInterface } from './types';
import { getDateTime } from '@common/dateTime';
import { parseData } from '@common/string';
import { createChatHistoryItem, createChatResponseItem } from './utils';

let concurrentQueryCount = 0; // Initialize the count

function* loadChat() {
  try {
    yield put(actions.loadChatFetch());
    const response: ChatInitResponseInterface = yield call(getChatHistory);
    if (response) {
      try {
        let history: any = [...response.chatHistory];
        history = history
          .filter((k: any) => k.response) // TODO: remove filter and find a way to handle missing response
          .flatMap((chat: any) => [
            createChatHistoryItem(chat, true),
            createChatResponseItem(
              chat?.response,
              chat?.debugObj ?? null,
              chat,
            ),
          ]);
        yield put(
          actions.loadChatSuccess({
            ...response,
            chatHistory: history,
          }),
        );
      } catch (error) {
        console.log(error);
        yield put(
          actions.loadChatSuccess({
            ...response,
            chatHistory: [
              {
                label: 'Hi, Welcome to Sage.AI. Ask me anything!',
                isSender: false,
                time: getDateTime(),
                id: '',
                isChatHistory: true,
              },
            ],
          }),
        );
      }
    } else {
      throw '-- Failed to response --';
    }
  } catch (error) {
    console.error(error);
    yield put(
      actions.loadChatSuccess({
        chatHistory: [],
        isValid: false,
        user: '',
      }),
    );
  }
}

function* searchQuery(request: any): Generator<any, void, any> {
  const { payload } = request;
  try {
    if (!payload) throw 'Payload is missing';
    const { query, chatId } = payload;

    if (!query && !chatId) throw 'Query is missing';

    if (query) {
      const userInput: any = {
        data: query,
        isSender: true,
        time: getDateTime(),
        id: '',
        type: 'text',
        isChatHistory: true,
      };
      yield put(actions.pushToChatHistory(userInput));
    }

    localStorage.setItem('chat-initiated', 'true');

    yield delay(500); // Simulating a delay
    yield put(actions.searchQueryFetch());
    const authToken = localStorage.getItem('x-tifin-ai-token');
    const response: any = yield call(getBotResponse, {
      params: {
        ...payload,
        skip_cache: true,
        mode: 'orchestrator',
      },
      headers: {
        'x-tifin-ai-auth': authToken,
      },
    });

    if (response?.response && response.chat_id) {
      const { response: chatResponse, chat_id } = response;
      const { isSampleQuestionAsked } = yield select(isSampleQuestionsCalled) ||
        false;
      const queryReponse: any = {
        id: chat_id,
        isSender: false,
        time: getDateTime(),
        isShowFeedback: true, // change later
        isChatHistory: false,
        feedback: !isSampleQuestionAsked && {
          chatLogId: chat_id,
          scale: '0',
          comment: '',
          isSubmitted: false,
        },
        ...parseData(chatResponse),
      };
      yield put(actions.pushToChatHistory(queryReponse));
      yield delay(100); // Simulating a delay

      const { chatHistory: initHistoryState } = yield select(
        getChatInitResponseState,
      ) || {};

      const chatDetails: any = Object.assign(
        {},
        initHistoryState[initHistoryState.length - 1],
      );
      if (isSampleQuestionAsked) {
        chatDetails.data = [
          ...chatDetails.data,
          {
            id: chat_id,
            type: 'whatElseButton',
          },
        ];
        chatDetails.isShowFeedback = false;
        chatDetails.feedback = {
          chatLogId: chat_id,
          scale: '0',
          comment: '',
          isSubmitted: false,
        };
      }
      yield put(
        actions.loadChatSuccess({
          chatHistory: [
            ...initHistoryState.slice(0, initHistoryState.length - 1),
            chatDetails,
          ],
        }),
      );
    }

    // Decrement the count when a query is processed
    concurrentQueryCount--;

    // Check if there are no more pending concurrent queries
    if (concurrentQueryCount === 0) {
      yield put(
        actions.searchQuerySuccess({
          query: '',
          processingQuery: false,
        }),
      );
    }
  } catch (error: any) {
    console.error('searchQuery error:', error);
    // Decrement the count when an error occurs
    concurrentQueryCount--;

    // Check if there are no more pending concurrent queries
    if (concurrentQueryCount === 0) {
      yield put(
        actions.searchQuerySuccess({
          processingQuery: false,
          query: '',
        }),
      );
    }

    if (
      error?.response &&
      error?.response?.data &&
      error?.response?.data?.message
    ) {
      yield put(
        globalActions.displayToast({
          duration: 3000,
          toastType: 'error',
          toastMessage: error?.response?.data?.message,
        }),
      );
    } else {
      yield put(
        globalActions.displayToast({
          duration: 3000,
          toastType: 'error',
          toastMessage: 'An error occurred while processing the query.',
        }),
      );
    }
  }
}

function* watchConcurrentQueries() {
  yield takeEvery(actions.searchQueryRequest.type, handleConcurrentQuery);
}

function* handleConcurrentQuery(payload: any) {
  try {
    concurrentQueryCount++; // Increment the count when a new query is started
    // yield delay(500); // Simulating a delay
    yield call(searchQuery, payload);
  } catch (error) {
    console.error('handleConcurrentQuery Error:', error);
    concurrentQueryCount--; // Decrement the count when an error occurs
  }
}

function* chatFeedback(request: any): Generator<any, void, any> {
  const { payload } = request;
  try {
    const { scale, comment } = payload;
    if (!scale && !comment) throw '-- Missing Payload --';

    yield put(actions.submitChatFeedbackFetch());
    const { user: chatToken } = yield select(getChatInitResponseState) || {};
    const response: any = yield call(submitChatFeedback, {
      payload,
      headers: {
        'x-tifin-ai-auth': chatToken,
      },
    });
    yield put(
      actions.submitChatFeedbackSuccess({
        ...payload,
        scale,
        response,
        loading: false,
        openModal: false,
      }),
    );
  } catch (error) {
    console.log(error);
    yield put(
      actions.submitChatFeedbackSuccess({
        loading: false,
        openModal: false,
        scale: null,
        chatLogId: null,
        comment: null,
      }),
    );
  }
}

export function* chatSaga(): Generator<AllEffect<any>, void, unknown> {
  yield all([
    fork(watchConcurrentQueries),
    takeLatest(actions.loadChatRequest.type, loadChat),
    takeLatest(actions.submitChatFeedbackRequest.type, chatFeedback),
  ]);
}
