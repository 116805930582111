import React from 'react';
import {
  CardAnalyticsDataWrapper,
  EntCardContainer,
  EntCardImagePlaceholder,
  NameText,
} from './styles';
import { Grid } from '@mui/material';

const EntCard = ({
  name,
  firmNumber,
  totalMembers,
  handleClick,
}: {
  name: string;
  firmNumber: number;
  totalMembers: number;
  handleClick: () => void;
}): React.JSX.Element => {
  return (
    <EntCardContainer
      container
      xs={2.8}
      columnGap={2}
      justifyContent={'flex-start'}
      alignItems={'center'}
      onClick={handleClick}
    >
      <EntCardImagePlaceholder />
      <Grid item container xs={12} pt={2}>
        <NameText> {name}</NameText>
      </Grid>
      <Grid item container xs={12} columnGap={0.6} pt={1}>
        <CardAnalyticsDataWrapper color="textSecondary">
          {firmNumber > 1 ? `${firmNumber} Admins` : `${firmNumber} Admin`}
        </CardAnalyticsDataWrapper>
        <CardAnalyticsDataWrapper color="textSecondary">
          •
        </CardAnalyticsDataWrapper>
        <CardAnalyticsDataWrapper color="textSecondary">
          {totalMembers > 1
            ? `${totalMembers} Members`
            : `${totalMembers} Member`}
        </CardAnalyticsDataWrapper>
      </Grid>
    </EntCardContainer>
  );
};
export default EntCard;
