import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { Grid } from '@mui/material';
import CustomTooltipContent from './CustomTooltipContent';
import { EGetTooltipLegendColors } from 'app/enums';
import { price } from '@common/number';

interface LineData {
  dataKey: string;
  stroke: string;
  name: string;
}

interface LineGraphProps {
  data: any[];
  lines: LineData[];
  useShortNames?: boolean;
  nameMapping?: { [key: string]: string };
  colors?: EGetTooltipLegendColors[];
  customLabel?: string;
}

function SimpleLineGraph({
  data,
  lines,
  colors = [EGetTooltipLegendColors.Color1, EGetTooltipLegendColors.Color2],
  customLabel,
}: LineGraphProps): React.JSX.Element {
  // Calculating x axis ticks for equidistant intervals
  const dataPoints = data?.length;
  const tickInterval = Math.ceil(dataPoints / 5);

  const customTicks = data?.map((entry, index) => {
    if (
      index === 0 ||
      index === dataPoints - 1 ||
      (index + 1) % tickInterval === 0
    ) {
      return entry?.date;
    } else {
      return '';
    }
  });

  return (
    <Grid
      container
      sx={{ background: 'white', borderRadius: '8px', padding: '20px' }}
    >
      <ResponsiveContainer width="100%" height={350}>
        <LineChart
          data={data}
          margin={{ top: 20, right: 30, left: 0, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="none" />
          <XAxis
            dataKey="date"
            tick={{ fontSize: '12px', textAnchor: 'middle' }}
            ticks={customTicks}
            tickMargin={10}
          />
          <YAxis
            tick={{ fontSize: '12px' }}
            // tickFormatter={tick => `${tick}%`}
            tickFormatter={tick => price(tick, true, false, false)}
            domain={['auto', 'auto']}
            tickMargin={5}
          />
          <Tooltip
            cursor={{ stroke: '#000', strokeWidth: 1 }}
            content={
              <CustomTooltipContent colors={colors} customLabel={customLabel} />
            }
          />
          {lines.map((line, index) => (
            <Line
              key={index}
              type="monotone"
              dataKey={line?.dataKey}
              stroke={line?.stroke}
              name={line?.name}
              dot={false}
              connectNulls={true}
              strokeWidth={2}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </Grid>
  );
}

export default SimpleLineGraph;
