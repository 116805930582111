import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { shouldForwardProp } from 'utils/forwardProp';

interface ChartProps {
  value?: number;
  size?: string;
  borderSize?: string;
  fontSize?: string;
}

const ChartContainer = styled(Box, { shouldForwardProp })<ChartProps>(
  ({ value, size = '100px', borderSize = '10px', fontSize = '1em' }) => ({
    width: size,
    height: size,
    margin: '1em auto',
    border: `${borderSize} solid transparent`,
    borderRadius: '50%',
    background: `linear-gradient(white, white) padding-box, 
              conic-gradient(#9C84FC ${value}%, #D9D9D9 ${value}%) border-box`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: fontSize,
    '& p': {
      margin: 0,
      fontSize: fontSize,
    },
  }),
);

const RiskCircularProgress: React.FC<ChartProps> = ({
  value,
  size,
  borderSize,
  fontSize,
}) => {
  return (
    <ChartContainer
      value={value}
      size={size}
      borderSize={borderSize}
      fontSize={fontSize}
    >
      <p>{value}</p>
    </ChartContainer>
  );
};

export default RiskCircularProgress;
