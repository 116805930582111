import React from 'react';
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

interface TableColumn {
  key: string;
  value: string;
  width: string;
  renderCell?: (value: any) => React.ReactNode;
  subText?: string;
}

interface CustomTableProps {
  headers: TableColumn[];
  data: any[];
}

const useStyles = makeStyles({
  root: {
    borderBottom: '1px solid #e4e7ec',
  },
  tableWrapper: {
    padding: '16px',
    background: '#f9f9f9',
    borderRadius: '8px',
  },
  noDataMessage: {
    padding: '10px',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  tableHeaderCell: {
    '&.MuiTableCell-root': {
      fontWeight: 400,
      color: '#535256',
      fontSize: '12px',
      padding: '5px 16px',
      textWrap: 'nowrap',
    },
  },
});

function SimpleTable({ headers, data }: CustomTableProps): React.JSX.Element {
  const classes = useStyles();

  return (
    <Grid container className={classes.tableWrapper} rowGap={2}>
      <Grid item container xs={12}>
        <TableContainer
          component={Paper}
          sx={{
            backgroundColor: '#fff',
            borderRadius: '8px',
            boxShadow: 'none',
            padding: 1.25,
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                {headers.map(header => (
                  <TableCell
                    key={header.key}
                    className={classes.tableHeaderCell}
                    style={{
                      width: header.width,
                    }}
                  >
                    {header.key}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.length === 0 ? (
                <TableRow>
                  <TableCell
                    colSpan={headers.length}
                    className={classes.noDataMessage}
                  >
                    No data available
                  </TableCell>
                </TableRow>
              ) : (
                data?.map((row, index) => (
                  <TableRow key={`row-${index}`} className={classes.root}>
                    {headers?.map((header, colIndex) => (
                      <TableCell
                        key={`${header.key}-${index}`}
                        style={{
                          fontSize: colIndex === 0 ? '14px' : '12px',
                          color: colIndex === 0 ? '#282829' : '#535256',
                          fontWeight: colIndex === 0 ? 500 : 400,
                        }}
                      >
                        {header.renderCell
                          ? header.renderCell(row[header.value])
                          : row[header.value]}
                        {colIndex === 0 && header.subText && (
                          <Grid sx={{ fontSize: '10px', color: '#9e9e9e' }}>
                            {header.subText}
                          </Grid>
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}

export default SimpleTable;
