import React, { useEffect, useState } from 'react';
import { SplashCardBody } from './style';

interface SplashCardProps {
  className?: any;
  sx: any;
  delay: number;
  children: any;
}

function SplashCard({
  className = [],
  sx = {},
  delay = 500,
  children,
  ...rest
}: SplashCardProps): React.JSX.Element {
  const [animate, setAnimate] = useState<boolean>(false);
  useEffect(() => {
    setTimeout(() => {
      setAnimate(true);
    }, delay);
  }, []);

  return (
    <SplashCardBody {...rest} animate={animate} className={className} sx={sx}>
      {children}
    </SplashCardBody>
  );
}

export default SplashCard;
