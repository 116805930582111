import React from 'react';
import Grid from '@mui/material/Grid';
import { Table, TableHead, TableBody } from '@mui/material';
import { SuggestedCardContainer } from '@pages/SuggestedActions/styles';
import { makeStyles } from '@mui/styles';
import {
  CustomTableContainer,
  StyledTableCell,
  StyledTableRow,
} from '@pages/SuperAdminPage/styles';
import downArrow from '@images/Down Arrow.svg';
import upArrow from '@images/Up Arrow.svg';
import {
  CustomizedLineChart,
  CustomLegend,
  periodColors,
} from '@components/Chart/CustomizedLineChart';

const useStyles = makeStyles({
  root: {
    borderBottom: '1px solid #e4e7ec',
  },
  tableWrapper: {
    border: 'none',
  },
  gapRow: {
    height: '190px',
    backgroundColor: 'transparent',
  },
});

interface PerformanceComparisonModuleProps {
  data: any;
}

function PerformanceComparisonModule({
  data,
}: PerformanceComparisonModuleProps): React.JSX.Element {
  const classes = useStyles();

  const graphData = data?.graph_data || [];
  const tableData = data?.table_data || [];

  const legendTickerColors = ['#BFAFFF', '#000000'];

  // Function to add opacity to hex color
  function addOpacityToHex(hex, opacity) {
    const alpha = Math.round(opacity * 255)
      ?.toString(16)
      .padStart(2, '0');
    return `${hex}${alpha}`;
  }

  // giving it the preffered opacity
  const opacity = 0.7;

  const customLegendItems = [
    ...(graphData || []).map((item, index) => ({
      color: legendTickerColors[index % legendTickerColors.length],
      value: item?.ticker || '',
    })),
    ...(tableData || []).flatMap((item, index) => {
      const color = periodColors[index % periodColors.length] || '#000';
      return {
        color: addOpacityToHex(color, opacity),
        value: item?.period || '',
      };
    }),
  ].filter(item => item.value !== '');

  const renderArrow = (percentage: number) => {
    if (percentage > 0) {
      return <img src={upArrow} alt="Up arrow image" />;
    } else if (percentage < 0) {
      return <img src={downArrow} alt="Down arrow image" />;
    } else {
      return null;
    }
  };

  const fundHeadersSet = new Set();
  tableData.forEach(item => {
    item.funds_data.forEach(fund => {
      fundHeadersSet.add(fund.name as string);
    });
  });
  const fundHeaders = Array.from(fundHeadersSet);

  const formatDate = (dateStr: string): string => {
    const [year, month, day] = dateStr.split('-');
    const options: Intl.DateTimeFormatOptions = {
      month: 'short',
      day: '2-digit',
      year: 'numeric',
    };
    return new Intl.DateTimeFormat('en-US', options).format(
      new Date(`${year}-${month}-${day}`),
    );
  };

  const getComparisonString = () => {
    const tickers = (graphData || [])
      .map(item =>
        item.ticker
          .split('_')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' '),
      )
      .join(' vs ');
    return tickers;
  };

  const calculateAdvantageValue = fundsData => {
    if (
      fundsData[0]?.value !== undefined &&
      fundsData[1]?.value !== undefined
    ) {
      const value1 = fundsData[0]?.value;
      const value2 = fundsData[1]?.value;
      const absValue1 = Math.abs(value1);
      const absValue2 = Math.abs(value2);
      const name1 = fundsData[0]?.name
        ? fundsData[0]?.name.charAt(0).toUpperCase() +
          fundsData[0]?.name.slice(1).replace('_', ' ') +
          '\u00A0'
        : '';
      const name2 = fundsData[1]?.name
        ? fundsData[1]?.name.charAt(0).toUpperCase() +
          fundsData[1]?.name.slice(1).replace('_', ' ') +
          '\u00A0'
        : '';

      let displayValue;

      if ((value1 >= 0 && value2 >= 0) || (value1 < 0 && value2 < 0)) {
        // if both the values are positive or both values are negative
        const result = Math.abs(absValue1 - absValue2);
        displayValue = `${value1 > value2 ? name1 : name2}+${(result * 100).toFixed(2)}%`;
      } else {
        // One value is positive and one is negative
        const result = Math.abs(absValue1 + absValue2);
        displayValue = `${value1 > value2 ? name1 : name2}+${(result * 100).toFixed(2)}%`;
      }

      return displayValue;
    }
    return '-';
  };

  return (
    <SuggestedCardContainer item container xs={12} sm={12}>
      <Grid
        item
        container
        direction={'column'}
        justifyContent={'center'}
        alignItems={'flex-start'}
      >
        <Grid
          item
          sx={{
            color: '#424242',
            fontSize: '14px',
            fontWeight: 700,
          }}
        >
          <b>SECURITY COMPARISON</b>
        </Grid>
        <Grid
          item
          sx={{
            color: '#8e8e8e',
            fontSize: '12px',
          }}
          mb={2}
        >
          {getComparisonString()}
        </Grid>
        <Grid item container rowGap={2}>
          <Grid item xs={12}>
            <CustomizedLineChart data={graphData} segments={tableData} />
          </Grid>
          <Grid item container xs={12}>
            <CustomLegend customLegendItems={customLegendItems} />
          </Grid>
          <Grid item container xs={12} className={classes.tableWrapper}>
            <Grid item container xs={12}>
              <CustomTableContainer>
                <Table>
                  <TableHead>
                    <StyledTableRow backgroundColor="#fff">
                      <StyledTableCell color="#535256">
                        Historical Market Event
                      </StyledTableCell>
                      {fundHeaders.map((header, index) => {
                        return (
                          <StyledTableCell
                            key={index}
                            color="#535256"
                            sx={{ paddingLeft: '45px' }}
                          >
                            {`${(header as string).charAt(0).toUpperCase()}${(header as string).slice(1).replace('_', ' ')}`}
                          </StyledTableCell>
                        );
                      })}
                      <StyledTableCell color="#535256">
                        Advantage
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {tableData?.map((rowData: any, index: number) => (
                      <StyledTableRow
                        key={`admin-${index}`}
                        backgroundColor="white"
                        sx={{
                          borderBottom: '1px solid #e4e7ec',
                        }}
                      >
                        <StyledTableCell color="black">
                          <Grid container direction="column">
                            <Grid
                              item
                              sx={{
                                color: '#282829',
                                fontSize: '14px',
                                fontWeight: 500,
                              }}
                            >
                              {rowData.period.replace(/_/g, ' ')}
                            </Grid>
                            <Grid
                              item
                              sx={{
                                color: '#475467',
                                fontSize: '12px',
                                fontWeight: 400,
                              }}
                            >
                              {`${formatDate(rowData.start_date)} - ${formatDate(rowData.end_date)}`}
                            </Grid>
                          </Grid>
                        </StyledTableCell>
                        {fundHeaders.map((header, fundIndex) => {
                          const fundData = rowData.funds_data.find(
                            (fund: any) => fund.name === header,
                          );
                          if (fundData) {
                            const percentageValue = (
                              fundData.value * 100
                            ).toFixed(2);
                            return (
                              <StyledTableCell
                                key={`${index}-fund${fundIndex + 1}`}
                                sx={{
                                  color: '#282829',
                                  fontSize: '14px',
                                  fontWeight: 500,
                                }}
                              >
                                <Grid
                                  container
                                  direction="row"
                                  alignItems="center"
                                >
                                  <Grid item sx={{ display: 'flex' }}>
                                    {renderArrow(fundData.value)}
                                  </Grid>
                                  <Grid item>
                                    {fundData.value > 0
                                      ? `+${percentageValue}%`
                                      : `${percentageValue}%`}
                                  </Grid>
                                </Grid>
                              </StyledTableCell>
                            );
                          } else {
                            // If fund data doesn't exist for this header, displaying "N/A"
                            return (
                              <StyledTableCell
                                key={`${index}-fund${fundIndex + 1}`}
                                sx={{
                                  color: '#282829',
                                  fontSize: '14px',
                                  fontWeight: 500,
                                  paddingLeft: '45px',
                                }}
                              >
                                -
                              </StyledTableCell>
                            );
                          }
                        })}
                        <StyledTableCell
                          sx={{
                            color: '#282829',
                            fontSize: '14px',
                            fontWeight: 500,
                          }}
                        >
                          {calculateAdvantageValue(rowData?.funds_data)}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </CustomTableContainer>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </SuggestedCardContainer>
  );
}

export default PerformanceComparisonModule;
