import { ParagraphBoldtText } from '@styles/Typography';
import styled from 'styled-components';
import { shouldForwardProp } from 'utils/forwardProp';

export const ReadMoreParagraphBoldtText = styled(ParagraphBoldtText).withConfig(
  {
    shouldForwardProp,
  },
)<{
  lineClamp: number;
}>`
  &.ellipses {
    display: -webkit-box;
    -webkit-line-clamp: ${props => props.lineClamp ?? 5};
    -webkit-box-orient: vertical;
  }
`;
