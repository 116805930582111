/* eslint-disable react/prop-types */
import React from 'react';

function GridViewIcon({
  activeColor,
}: {
  activeColor: string;
}): React.JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      fill="none"
      viewBox="0 0 16 17"
    >
      <g
        stroke={activeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.6"
      >
        <path d="M7 3.5H3v4h4v-4z"></path>
        <path d="M13 3.5H9v4h4v-4z"></path>
        <path d="M7 9.5H3v4h4v-4z"></path>
        <path d="M13 9.5H9v4h4v-4z"></path>
      </g>
    </svg>
  );
}

const MemoHomeIcon = React.memo(GridViewIcon);
export default MemoHomeIcon;
