import React from 'react';
import { ButtonUnstyled as Button } from '@mui/core';
import styled from 'styled-components';

const OutlineButton = styled(Button)`
  font-size: 14px;
  font-weight: 500;
  border: 1px solid ${props => props.theme.main};
  border-radius: 50px;
  background-color: #fff;
  color: ${props => props.theme.main};
  display: flex;
  text-transform: none;
  min-width: 90px;
  height: 40px;
  justify-content: center;
  align-items: center;
  padding: 6px 20px;
  position: absolute;
  right: 10px;
  top: 30px;
  &:hover {
    cursor: pointer;
  }
  &:disabled {
    cursor: not-allowed;
    color: #98a2b3;
    background-color: #f2f4f7;
  }
`;
interface EndTourProps {
  endTour: any;
}
const EndTourButton = ({ endTour }: EndTourProps): React.JSX.Element => {
  return (
    <OutlineButton
      disabled={false}
      style={{
        borderRadius: '5px',
        fontSize: '12spx',
        textTransform: 'capitalize',
        float: 'right',
      }}
      onClick={endTour}
    >
      Skip Product Tour
    </OutlineButton>
  );
};
export default EndTourButton;
