import { StyledButton } from '@pages/CIOViewpoints/styles';
import React from 'react';
import BoltIcon from '@icons/bolt';
import ClientsTable from './ClientsTable';
import { AdvisorDetailContainer, DetailWrapper } from './styles';
import { Grid, Typography } from '@mui/material';
import BackArrowIcon from '@icons/BackArrow';
import { useRESTQuery } from '@hooks/useRESTQuery';
import { handleError } from '@common/error';
import { useDispatch } from 'react-redux';
import {
  FirmAdminCard,
  StyledHeaderDiv,
  StyledRectangle,
} from '@pages/EnterpriseAdmin/styles';
import { price } from '@common/number';
import { validArray } from '@common/validArray';

interface AdvisorDetailsPageProps {
  setStepper: React.Dispatch<
    React.SetStateAction<'ADVISOR_LISTING_PAGE' | 'ADVISOR_DETAILS_PAGE'>
  >;
  advisorDetails: any;
  stepper: 'ADVISOR_LISTING_PAGE' | 'ADVISOR_DETAILS_PAGE';
}

function AdvisorDetails({
  setStepper,
  advisorDetails,
  stepper,
}: AdvisorDetailsPageProps): React.JSX.Element {
  console.log({ stepper });
  const dispatch = useDispatch();

  const { data: advisorData } = useRESTQuery(
    ['get-firm-advisor-details', advisorDetails?.id],
    {
      endpoint: `/firms/advisorDetails?advisorId=${advisorDetails?.id}`,
      method: 'GET',
      headers: {
        'X-Tifin-Ai-Auth': localStorage.getItem('x-tifin-ai-token'),
      },
    },
    {
      enabled: !!advisorDetails?.id,
      select: response => response.data,
      onError: (error: any) => {
        handleError(dispatch, error);
      },
    },
  );

  return (
    <AdvisorDetailContainer>
      <DetailWrapper>
        <Grid
          item
          container
          justifyContent={'flex-start'}
          alignItems={'center'}
          columnGap={1}
          onClick={() => setStepper('ADVISOR_LISTING_PAGE')}
          sx={{
            cursor: 'pointer',
          }}
        >
          <Grid item>
            <BackArrowIcon />
          </Grid>
          <Grid item>
            <p>Firm Management</p>
          </Grid>
          <Grid item>/</Grid>
          <Grid item>
            <p>{advisorDetails?.name}</p>
          </Grid>
        </Grid>
        <StyledHeaderDiv
          item
          container
          xs={12}
          rowGap={3}
          p={'25px 15px 15px 0px'}
          justifyContent={'space-between'}
          direction={'row'}
        >
          <Grid item container xs={6}>
            <StyledRectangle xs={1} height={'45px'} />
            <Grid item container xs={5} direction={'column'}>
              <Grid
                item
                justifyContent={'flex-start'}
                color={'#1d2939'}
                fontSize={'16px'}
                fontWeight={500}
              >
                {advisorDetails?.name}
              </Grid>
              <Grid
                item
                container
                fontSize={'12px'}
                direction={'row'}
                columnGap={0.5}
                color={'#667085'}
                mt={'5px'}
              >
                {advisorDetails?.email}
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={2.5}
            justifyContent={'flex-end'}
            alignItems={'center'}
          >
            <StyledButton
              variant="outlined"
              style={{ fontSize: '12px', width: '60%' }}
            >
              Contact
            </StyledButton>
          </Grid>
        </StyledHeaderDiv>
        <Grid
          item
          container
          xs={11.8}
          pt={'15px'}
          columnGap={3}
          rowGap={2}
          justifyContent={'space-between'}
        >
          {validArray(advisorData?.stats) &&
            advisorData?.stats.map((item, index) => (
              <>
                <FirmAdminCard
                  item
                  container
                  key={index}
                  xs={12}
                  sm={5.8}
                  md={3.8}
                >
                  <Grid
                    item
                    alignItems={'center'}
                    justifyContent={'flex-start'}
                    xs={2}
                  >
                    <BoltIcon
                      width="36px"
                      height="36px"
                      fill="#E8FFF7"
                      stroke="#333"
                    />
                  </Grid>
                  <Grid
                    item
                    container
                    height={'30x'}
                    mt={'-5px'}
                    justifyContent={'flex-start'}
                    xs={10}
                    direction={'column'}
                  >
                    <Typography sx={{ fontSize: '14px', color: '#475467' }}>
                      {item?.key}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: '18px',
                        color: '#101828',
                        marginTop: '-3px',
                      }}
                    >
                      {item?.key === 'Total AUM'
                        ? price(item.value)
                        : item.value}
                    </Typography>
                  </Grid>
                </FirmAdminCard>
              </>
            ))}
        </Grid>
        <Grid
          item
          container
          xs={12}
          justifyContent={'flex-start'}
          alignItems={'center'}
          pt={2}
          pb={1.5}
        >
          {advisorDetails?.name} Clients
        </Grid>
        <ClientsTable data={advisorData?.clients} />
      </DetailWrapper>
    </AdvisorDetailContainer>
  );
}

export default AdvisorDetails;
