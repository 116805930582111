import { actions as globalActions } from 'app/features/Global/slice';

type ErrorResponse = {
  response: {
    data: {
      message: string;
    };
  };
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const handleError = (dispatch: any, error: ErrorResponse) => {
  if (
    error &&
    error.response &&
    error.response.data &&
    error.response.data.message &&
    typeof error.response.data.message === 'string'
  ) {
    dispatch(
      globalActions.displayToast({
        duration: 3000,
        toastType: 'error',
        toastMessage: error?.response?.data?.message,
      }),
    );
  } else {
    dispatch(
      globalActions.displayToast({
        duration: 3000,
        toastType: 'error',
        toastMessage: 'Something went wrong',
      }),
    );
  }
};
