import {
  Grid,
  Typography,
  Checkbox,
  FormControlLabel,
  TextField,
} from '@mui/material';
import styled from 'styled-components';

export const Form = styled('form')`
  gap: 16px;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  padding: 40px;
`;

export const FormField = styled('div')``;

export const FormFieldError = styled('p')<any>`
  display: block;
  text-align: left;
  margin-top: -22px;
  font-size: ${props => props?.fontSize ?? '14px'} !important;
  font-style: ${props => props?.fontStyle ?? 'normal'} !important;
  font-weight: ${props => props.fontWeight ?? '300'} !important;
  color: ${props => props.theme?.dangerMain};
  line-height: ${props => props.lineHeight ?? 'normal'} !important;
`;

export const FormFieldCheck = styled(FormField)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 6px;
  margin-top: -4px;
  margin-bottom: -4px;
}`;

export const CheckControlField = styled(FormControlLabel)`
  '& .muibuttonbase-root.muicheckbox-root': {
    padding: 4px;
    margin-left: 4px;
  }
`;

export const CheckField = styled(Checkbox)``;

export const LoginWrapper = styled(Grid)`
  padding: 20px;
  position: relative;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: ${props => props.theme?.layoutBackground};
`;

export const LoginInputBoxHeaderText = styled(Typography)<any>`
  font-size: ${props => props?.fontSize ?? '14px'} !important;
  font-style: ${props => props?.fontStyle ?? 'normal'} !important;
  font-weight: ${props => props.fontWeight ?? '400'} !important;
  line-height: ${props => props.lineHeight ?? 'normal'} !important;
  color: ${props => props.color ?? '#101828'} !important;
`;

export const RememberMeText = styled(Typography)<any>`
  font-size: ${props => props?.fontSize ?? '14px'} !important;
  font-style: ${props => props?.fontStyle ?? 'normal'} !important;
  font-weight: ${props => props.fontWeight ?? '300'} !important;
  line-height: ${props => props.lineHeight ?? 'normal'} !important;
  color: ${props => props.color ?? '#101828'} !important;
`;

export const LoginFormWrapper = styled(Grid)`
  width: 50%;
  height: auto;
  justify-content: center;
  align-items: center;
`;

export const FormWrapper = styled(Grid)`
  justify-content: center;
  align-items: center;
  height: auto;
  max-width: 600px;
  margin: 0 auto;
`;

export const LoginHeaderText = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;
  color: #000;
  font-size: 16px; /* Default for smallest screens */
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  @media (min-width: 481px) {
    font-size: 20px; /* For screens wider than 480px */
  }

  @media (min-width: 670px) {
    font-size: 28px; /* For screens wider than 670px */
  }
`;

export const LoginSubHeaderText = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;
  color: #667085;
  font-size: 14px; /* Default for smallest screens */
  font-style: normal;
  font-weight: 300;
  line-height: normal;

  @media (min-width: 481px) {
    font-size: 16px; /* For screens wider than 480px */
  }

  @media (min-width: 670px) {
    font-size: 16px; /* Retain same size for larger screens */
  }
`;

export const LogoWrapper = styled(Grid)`
  padding: 4rem;
  padding-bottom: 1rem;
  display: flex;
  justify-content: center;
`;

export const StockImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
`;

export const StockOverlayImg = styled.img`
  width: 100%;
  height: 100%;
  background: transparent;
`;

export const LoginHeaderTextWrapper = styled(Grid)`
  padding: 0px;
  display: flex;
  justify-content: center;

  @media (min-width: 480px) {s
    padding: 1rem;
    padding-bottom: 1rem;
  }
`;

export const InputField = styled(TextField)<any>`
  &.MuiFormControl-root {
    &.MuiTextField-root {
      width: 100%;
      height: 36px;
      outline: none;
      border: none;
      box-shadow: none;
      border-radius: 4px;
      &:hover{
        border: none;
      }
      & .MuiInputBase-root {
        &.MuiOutlinedInput-root {
          height: 36px;
          background: transparent;
          color: ${props => props.theme.solidWhite};
          box-shadow: ${props => props.theme.boxShadow2};
          border: 1px solid ${props => props.theme.borderColor};
          outline: 1px solid transparent;
          font-size: 0.875rem;
          &:hover{
            border: 1px solid ${props => props.theme.border1};
          }
          &.Mui-focused{
            outline: 1px solid ${props => props.theme.focusedOutline};
           & .MuiOutlinedInput-notchedOutline{
            border: none
           } 
           &:hover{
            & .MuiOutlinedInput-notchedOutline{
              border: none
             } 
            }
          }
          & .MuiInputBase-input{
            &.MuiOutlinedInput-input{
              box-shadow: none;
              border: none;
              padding: 0px 12px;
              &:focus{
                box-shadow: none;
              border: none;
              outline:0;
              }
             

            }
          }
        }
        }
      }
    }
  }
`;

export const ContentCenter = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180deg, #1e4854 0%, #0c333d 100%);
  height: 100%;
`;

export const LoginContainer = styled(ContentCenter)`
  background: transparent;
  height: 100%;
  height: 90%;
`;

export const LoginOktaWrapper = styled.div`
  #okta-sign-in {
    font-family:
      Inter,
      sans-serif,
      proxima nova,
      montserrat,
      Arial,
      Helvetica,
      sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.4;
    background-color: #f9f9f9;
    color: #777;
    position: relative;
    overflow: hidden;
    border-radius: 3px;
    border-style: solid;
    border-width: 1px;
    height: auto;
    width: 50vw;
    max-width: 550px;
    margin-top: 0px;
  }
  #okta-sign-in.auth-container.main-container .margin-btm-5 {
    margin-bottom: 15px;
  }
  #okta-sign-in .o-form label,
  #okta-sign-in .o-form textarea {
    font-size: 14px;
    color: #101828;
    font-weight: 500;
  }
  #okta-sign-in .o-form .input-fix input[type='text'],
  #okta-sign-in .o-form .input-fix input[type='textbox'] {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 6px 8px;
    line-height: 16px;
    border: none;
    background: transparent;
    box-sizing: border-box;
    color: #101828;
  }
  .js-help {
    display: none !important;
  }
  .js-help-links {
    display: block !important;
  }
  .js-help-link {
    display: none !important;
  }
  #okta-sign-in.auth-container .okta-form-input-field {
    background-color: #fff;
    border-color: #e4e7ec;
    box-shadow: 1px 2px 2px 0px rgba(16, 24, 40, 0.12) inset;
  }

  #okta-sign-in .o-form .input-fix {
    height: 40px;
    border: 2px solid #e4e7ec;
    display: inline-block;
    float: left;
    // box-shadow: 1px 2px 2px 0px rgba(16, 24, 40, 0.12) inset;
  }

  #okta-sign-in .auth-content {
    padding: 0px;
    @media (min-width: 670px) {
      padding: 20px 42px;
    }
  }
  ,
  .auth-container {
    // margin: 0 auto !important;
    border: none !important;
    box-shadow: none !important;
    background-color: transparent !important;
    z-index: 2;
    padding: 0 !important;

    .okta-sign-in-header,
    .okta-form-title {
      display: none;
    }

    input[type='text'],
    input[type='password'] {
      width: 100%;
      background-color: transparent !important;
      color: #101828;
      border: none; /* Remove default border */
      transition: border 0.3s ease-in-out; /* Add transition for smoother effects */
    }

    .focused-input,
    input[type='text']:focus,
    input[type='password']:focus,
    input[type='submit']:focus {
      border: 1px solid #e4e7ec !important;
      box-shadow: inset 1px 2px 2px rgba(16, 24, 40, 0.12) !important;
      border-radius: 4px !important;
    }

    .focused-input,
    input[type='checkbox']:focus {
      border: unset !important;
      box-shadow: unset !important;
    }

    label {
      color: #667085;
    }

    .link {
      color: #283345 !important;
      transition: 0.5s !important;
    }

    .o-form-label {
      text-align: left;
    }

    .button {
      background: #283345 !important;
      color: white;
      transition: 0.5s !important;
      :hover {
        background: #4634d0 !important;
      }
    }

    input[type='submit'] {
      font-weight: 500 !important;
      line-height: 1.75 !important;
      height: unset !important;
      padding: 6px 1rem !important;
      border: 1px solid #e4e7ec !important;
      box-shadow: inset 1px 2px 2px rgba(16, 24, 40, 0.12) !important;
      border-radius: 4px !important;

      &.email-button {
        margin-top: 2rem !important;
      }
    }
  }
`;
