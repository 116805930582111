import { handleError } from '@common/error';
import ApiInstance from '@instances/index';
import { UseInfiniteQueryResult, useInfiniteQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { CLIENT_PER_PAGE } from '../constant';

const fetchClients = async ({ pageParam = 1, queryKey }) => {
  const [
    ,
    search = '',
    isProspect = false,
    viewpoint = false,
    allClients = false,
  ] = queryKey;

  const queryParams = new URLSearchParams({
    page: String(pageParam),
    limit: '18',
    ...(search && { search }),
    ...(isProspect && { prospect: 'true' }),
    ...(viewpoint && { viewpoint: 'true' }),
    ...(allClients && { allClients: 'true' }),
  });

  const { data } = await ApiInstance.get(`/client?${queryParams}`, {
    headers: {
      'X-Tifin-Ai-Auth': localStorage.getItem('x-tifin-ai-token'),
    },
  });
  return data;
};

export const useGetClients = ({
  search = '',
  prospect = false,
  viewpoint = false,
  allClients = false,
}: {
  search: string;
  prospect?: boolean;
  viewpoint?: boolean;
  allClients?: boolean;
}): UseInfiniteQueryResult<any, unknown> => {
  const dispatch = useDispatch();

  return useInfiniteQuery(
    ['get-clients', search, prospect, viewpoint, allClients],
    fetchClients,
    {
      getNextPageParam: (lastPage, pages) => {
        const totalPages = Math.ceil(lastPage.totalCount / CLIENT_PER_PAGE);
        return pages.length < totalPages ? pages.length + 1 : undefined;
      },
      select: data => ({
        pages: data.pages.flatMap(page =>
          page.data.map(client => ({
            id: client.id,
            clientName:
              `${client.firstName ?? ''} ${client.lastName ?? ''}`.trim(),
            clientEmail: client.email ?? '',
            value: client.marketValue ?? 0,
            constraints: (client.restrictions ?? []).join(', '),
            accounts: client.accounts,
            restrictions: client.restrictions ?? [],
            viewpoint: client.viewpoint,
            portfolioDiff: client.portfolioDiff,
            currentPortfolioRisk: client.currentPortfolioRisk,
            riskPreference: client.riskPreference,
            riskCapacity: client.riskCapacity,
            isProspect: client.isProspect,
          })),
        ),
        pageParams: data.pageParams,
      }),
      onError: (error: any) => handleError(dispatch, error),
      keepPreviousData: true,
    },
  );
};
