/* eslint-disable react/prop-types */

import React from 'react';

function ReturnIcon(props: any): React.JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      fill="none"
      viewBox="0 0 48 48"
    >
      <rect
        width="48"
        height="48"
        fill="url(#paint0_linear_2498_28861)"
        rx="4"
      ></rect>
      <path
        fill={props.fill || '#fff'}
        d="M24 14.25A9.75 9.75 0 1033.75 24 9.76 9.76 0 0024 14.25zm.75 1.535c.508.046 1.01.14 1.5.28v15.873c-.49.14-.992.235-1.5.281V15.785zm3 .868a8.236 8.236 0 011.5.988v12.718a8.236 8.236 0 01-1.5.988V16.653zM15.75 24a8.261 8.261 0 017.5-8.215v16.43A8.26 8.26 0 0115.75 24zm15 4.738v-9.476a8.232 8.232 0 010 9.476z"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_2498_28861"
          x1="0"
          x2="48"
          y1="0"
          y2="48"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A8A8A8"></stop>
          <stop offset="1" stopColor="#818181"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}

const MemoReturnIcon = React.memo(ReturnIcon);
export default MemoReturnIcon;
