import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, useTheme } from '@mui/material';
import SageAvatar from '@icons/sageAvatar';
import { getAuthDetails } from '@features/Profile/selector';
import {
  getSearchedQuery,
  isSampleQuestionsCalled,
} from '@features/Chat/selector';
import { actions as chatActions } from '@features/Chat/slice';
import { ChatFeedbackInterface } from '@features/Chat/types';
import ChatBox from './ChatBox';
import {
  AvatarWrapper,
  ChatBoxContainer,
  ChatContentContainer,
  ChatBoxContent,
} from './styles';
import Loading from '@images/Loading.gif';
import { extractText } from '@common/string';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import ChatMarkdownText from './ChatMarkdownText';

type ChatContentProps = {
  sx?: object;
  isMinimized?: boolean;
  isStreaming: boolean;
  chatHistory: any[];
  onSelectChatOption: any;
  portfolioOptions: any[];
  loopCounter: number;
  setLoopCounter: React.Dispatch<number>;
  toggleFeedback: (props: any) => void;
};

function ChatContent({
  sx = {},
  isMinimized = false,
  chatHistory: renderData = [],
  isStreaming = true,
  onSelectChatOption,
  portfolioOptions,
  loopCounter,
  setLoopCounter,
  toggleFeedback,
}: ChatContentProps): React.JSX.Element {
  const isChatStreaming = localStorage.getItem('streaming') as string;

  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = useLocation();
  const profileState: any = useSelector(getAuthDetails);
  const { isSampleQuestionAsked } = useSelector(isSampleQuestionsCalled);
  const query: any = useSelector(getSearchedQuery);

  const chatContentContainerRef = useRef<HTMLDivElement | null>(null);
  const messageBottomRef = useRef<HTMLDivElement | null>(null);
  const chatContentRef = useRef<HTMLDivElement | null>(null);

  const [message, setMessage] = useState('');
  const [markdownToDisplay, setMarkdownToDisplay] = useState('');
  const [oldChatsVisibility, setOldChatsVisibility] = useState<boolean>(false);
  const [es, setEs] = useState<null | EventSource>(null);
  const [streaming, setStreaming] = useState(false);

  const addToHistory = (
    id: string,
    data: any,
    debugObj: any = {},
    isSender = false,
    createdAt: string = new Date().toISOString(),
  ) => {
    let showFeedback = true;
    if (!isSender && isSampleQuestionAsked) {
      showFeedback = false;
      data.push({
        id: id,
        type: 'whatElseButton',
      });
      dispatch(
        chatActions.searchSampleQuestion({ isSampleQuestionAsked: false }),
      );
    }
    dispatch(
      chatActions.loadChatSuccess({
        chatHistory: renderData.concat([
          {
            isSender,
            debugObj,
            time: 'Now',
            id,
            isChatHistory: true,
            isShowFeedback: showFeedback,
            mock: false,
            data,
            createdAt,
            feedback: {
              chatLogId: id,
              isSubmitted: false,
              scale: '0',
            },
          },
        ]),
      }),
    );
  };

  const scrollToMessageTop = (
    delay: number = 50,
    noSmoothScroll: boolean = false,
  ) => {
    setTimeout(() => {
      if (chatContentContainerRef.current) {
        // Calculate the new scroll position to bring the last message to the top
        const lastMessageHeight =
          chatContentContainerRef.current.lastElementChild?.clientHeight || 0;
        const visibleHeight = chatContentContainerRef.current.clientHeight;
        const scrollHeight = chatContentContainerRef.current.scrollHeight;

        // Ensure the new scroll position brings the last message to the top
        chatContentContainerRef.current.scrollTo({
          top: scrollHeight - visibleHeight - lastMessageHeight,
          behavior: noSmoothScroll ? 'auto' : 'smooth',
        });
      }
      setOldChatsVisibility(true);
    }, delay);
  };

  useLayoutEffect(() => {
    // added this for avoid flickering when more queries are present in chathistory while routing back from another page
    setOldChatsVisibility(false);
    scrollToMessageTop(0, true);
  }, []);

  useEffect(() => {
    if (renderData?.length === 0 && pathname !== '/conversations/stream') {
      return navigate('/conversations');
    }
  }, [renderData]);

  const onFeedbackSubmit = (data: ChatFeedbackInterface) => {
    dispatch(chatActions.submitChatFeedbackRequest(data));
  };

  const parseDataFromMessage = (message: string) => {
    const pattern = /\|\|startJson\s(.*?)\|\|endJson/gs;
    const match = pattern.exec(message);

    if (match) {
      const jsonData = match[1];
      const parsedData = JSON.parse(jsonData);
      return parsedData?.data;
    } else {
      return null;
    }
  };

  const onEndStream = async (message: string) => {
    const chatIdRegexMatch = message.match(
      /\|\|chatLogId\s+(\S+)\s+\|\|endChatLogId/,
    );
    const chatId = chatIdRegexMatch ? chatIdRegexMatch[1] : '';
    const debugObj = {};
    const responses: Array<any> = [];

    const parsedJson = parseDataFromMessage(message);
    const { match: _message = '', startTextCount = 0 } = extractText(message);

    const _res = { type: 'markdown', content: _message };
    responses.push(_res);
    if (Array.isArray(parsedJson) && parsedJson.length > 0) {
      if (startTextCount > 2) {
        const suggestions = parsedJson.find(
          (item: any) => item?.type === 'suggestions',
        );
        responses.push(suggestions);
      } else {
        responses.push(...parsedJson);
      }
    }

    addToHistory(chatId, responses, debugObj);
    setMarkdownToDisplay('');
    setMessage('');
    if (isChatStreaming === 'true') {
      dispatch(chatActions.setSearchQuery(''));
    } else {
      dispatch(chatActions.searchQueryRequest({ query: '' }));
    }
  };

  const clearStream = () => {
    es?.close();
    setEs(null);
  };

  useEffect(() => {
    if (
      pathname === '/conversations/stream' &&
      typeof query === 'string' &&
      query !== ''
    ) {
      setOldChatsVisibility(true); // added this incase setTimeout didn't work for scrolling
      addToHistory('', query, {}, true);
      setStreaming(true);
      clearStream();

      setEs(
        new EventSource(
          `${process.env.REACT_APP_API_BASE_URL}/chat/stream?query=${query}&advisorId=${profileState.advisorId}`,
        ),
      );
    }

    return () => {
      clearStream();
    };
  }, [query]);

  useEffect(() => {
    if (es) {
      es.onmessage = event => {
        const data = JSON.parse(event.data);
        setMessage(message => message + data);
      };

      es.onerror = () => {
        clearStream();
        onEndStream(message);
      };
    }
  }, [es]);

  // displaying markdown changes from stream api
  useEffect(() => {
    if (message.trim().endsWith('||endStream')) {
      clearStream();
      onEndStream(message);
    }

    const { match: _message = '' } = extractText(message);
    setMarkdownToDisplay(_message);
  }, [message]);

  useEffect(() => {
    markdownToDisplay !== '' && setStreaming(false);
  }, [markdownToDisplay]);

  return (
    <ChatContentContainer
      item
      sx={{
        ...sx,
        ['&>div']: {
          // FIX: temporary: this visibility logic is added to avoid displaying old chats for fraction of mili-seconds before displaying latest chats when visiting from other routes
          visibility: oldChatsVisibility ? 'visible' : 'hidden',
        },
        ['&>div:nth-last-of-type(3)']: {
          visibility: 'visible',
          minHeight:
            location?.state?.scroll !== 1
              ? chatContentContainerRef?.current?.offsetHeight
                ? streaming || isStreaming
                  ? 69
                  : 69
                : 0
              : '10vh',
        },
        ['&>div:nth-last-of-type(2)']: {
          visibility: 'visible',
          minHeight:
            location?.state?.scroll !== 1
              ? chatContentContainerRef?.current?.offsetHeight
                ? streaming || isStreaming
                  ? chatContentContainerRef.current &&
                    chatContentContainerRef.current.offsetHeight - 115
                  : chatContentContainerRef.current.offsetHeight - 115
                : 0
              : '50vh',
        },
      }}
      p={2}
      isminimized={isMinimized ? 1 : 0}
      ref={chatContentContainerRef}
    >
      {Array.isArray(renderData) &&
        renderData?.length > 0 &&
        renderData.map((chat: any, index: number) => {
          return (
            <React.Fragment key={`chat-container-${index}`}>
              <ChatBox
                key={`${index}+1`}
                isLastItem={index + 1 === renderData.length}
                index={index + 1}
                chat={chat}
                onSelectChatOption={onSelectChatOption}
                portfolioOptions={portfolioOptions}
                loopCounter={loopCounter}
                setLoopCounter={setLoopCounter}
                toggleFeedback={toggleFeedback}
                onFeedbackSubmit={onFeedbackSubmit}
                chatContentRef={chatContentRef}
                scrollToMessageTop={scrollToMessageTop}
              />
            </React.Fragment>
          );
        })}

      {query && markdownToDisplay && (
        <ChatBoxContainer
          container
          justifyContent={'flex-start'}
          alignItems={'flex-start'}
        >
          <Grid
            item
            container
            justifyContent={'flex-start'}
            alignItems={'flex-start'}
            columnGap={1}
            width={'calc(100% - 100px)'}
          >
            <AvatarWrapper>
              <SageAvatar
                borderOpacity={0.3}
                fill={theme.palette.primary['solidWhite']}
              />
            </AvatarWrapper>
            <ChatBoxContent item container sm={10} rowGap={2}>
              <ChatMarkdownText
                content={markdownToDisplay}
                isStreaming={true}
                isSender={false}
              />
            </ChatBoxContent>
          </Grid>
        </ChatBoxContainer>
      )}

      {(streaming || isStreaming) && (
        <ChatBoxContainer
          container
          justifyContent={'flex-start'}
          alignItems={'flex-start'}
        >
          <ChatBoxContent
            sx={{
              marginTop: '12px',
              marginLeft: '75px',
              width: '250px',
              height: '20px',
              flexDirection: 'row',
              display: 'flex',
            }}
          >
            <img
              src={Loading}
              alt="Loading gif"
              style={{
                height: '40px',
                marginTop: '-20px',
                marginLeft: '-25px',
              }}
            />
            {isStreaming && (
              <div style={{ marginLeft: '25px', marginTop: '-22px' }}>
                <p style={{ color: 'black', fontSize: '14px' }}>
                  {/* {messages[currentMessageIndex]} */}
                </p>
              </div>
            )}
          </ChatBoxContent>
        </ChatBoxContainer>
      )}

      <div id="idMessageEnd" ref={messageBottomRef} />
    </ChatContentContainer>
  );
}

export default ChatContent;
