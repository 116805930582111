import {
  LayoutContainer,
  LeftSideContainer,
  RightSideContainer,
} from '@layout/styles';
import React, { useState } from 'react';
import SideNav from './Sidebar';
import Dashboard from './Dashboard';
import SuggestedActions from './SuggestedActions';
import Clients from './Clients';
import Integration from './Integration';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getAuthDetails } from '@features/Profile/selector';

function Tour(): React.JSX.Element {
  const navigate = useNavigate();
  const profileState: any = useSelector(getAuthDetails);
  const [collapse, updateCollapseState] = useState(false);
  const [routeState, setRouteState] = useState('Conversations');
  const [awaitingNavItem, setAwaitingNavItem] = useState('');

  const onArrowClick = () => {
    updateCollapseState(!collapse);
  };
  const endTour = () => {
    if (profileState.role === 'ADVISOR' || profileState.role === 'FIRM_ADMIN') {
      navigate('/conversations');
      setRouteState('Conversations');
    } else if (profileState.role === 'SUPER_ADMIN') {
      navigate('/admin-console');
    } else if (profileState.role === 'ENTERPRISE_ADMIN') {
      navigate('/firms');
    }
  };
  const tourProps = {
    awaitingNavItem: awaitingNavItem,
    setAwaitingNavItem: setAwaitingNavItem,
    setRouteState: setRouteState,
    endTour: endTour,
  };

  const tourComponents = {
    Conversations: <Dashboard {...tourProps} />,
    'Current Markets': <SuggestedActions {...tourProps} />,
    'My Clients': <Clients {...tourProps} />,
    Integrations: <Integration {...tourProps} />,
  };

  return (
    <>
      <LayoutContainer container>
        <LeftSideContainer collapse={collapse}>
          <SideNav
            collapse={collapse}
            onArrowClick={onArrowClick}
            setRouteState={setRouteState}
            routeState={routeState}
            awaitingNavItem={awaitingNavItem}
            setAwaitingNavItem={setAwaitingNavItem}
          />
        </LeftSideContainer>
        <RightSideContainer collapse={collapse}>
          {tourComponents[routeState]}
        </RightSideContainer>
      </LayoutContainer>
    </>
  );
}

export default Tour;
