import React from 'react';
import TextField from '@mui/material/TextField';

interface InputProps {
  required?: boolean;
  type?: string;
  label?: string;
  variant?: 'outlined' | 'filled' | 'standard';
  name?: string;
  value?: string;
  size: 'medium' | 'small';
  [key: string]: any; // additional props
}

type DebounceProps = {
  handleDebounce: (value: string) => void;
  debounceTimeout: number;
};

function Input({
  required = false,
  type = 'text',
  label,
  variant,
  name,
  value,
  size,
  ...all
}: InputProps): React.JSX.Element {
  return (
    <TextField
      variant={variant}
      type={type}
      label={label}
      name={name}
      id={name}
      value={value}
      required={required}
      size={size}
      {...all}
    />
  );
}

export const DebounceInput = (
  props: InputProps & DebounceProps,
): React.JSX.Element => {
  const { handleDebounce, debounceTimeout, ...rest } = props;
  const timerRef = React.useRef<ReturnType<typeof setTimeout>>();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      handleDebounce(event.target.value);
    }, debounceTimeout);
  };

  return <Input {...rest} onChange={handleChange} />;
};

export default Input;
