import React, { useState, useEffect } from 'react';
import { FullScreen, FadingBox } from './styles';
import SageLogo from '@images/sagename.svg';

interface CallBackProp {
  callBack: () => void;
}

const WelcomeToSage = ({ callBack }: CallBackProp): React.JSX.Element => {
  const [step, setStep] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      setStep(c => c + 1);
    }, 500);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    step >= 14 && callBack();
  }, [step]);

  return (
    <FullScreen>
      <FadingBox
        sx={{ fontSize: '64px', opacity: [2, 3, 4].includes(step) ? 1 : 0 }}
      >
        Welcome to
      </FadingBox>
      <FadingBox sx={{ opacity: [8, 9, 10, 11].includes(step) ? 1 : 0 }}>
        <img
          alt="Sage welcome image"
          src={SageLogo}
          style={{ height: '120px' }}
        />
      </FadingBox>
    </FullScreen>
  );
};

export default WelcomeToSage;
