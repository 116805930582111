import React, { forwardRef } from 'react';
import {
  IndividualStack,
  LegendColorDiv,
  LegendTextDiv,
  ProgressBarWrapper,
} from './styles';
import { Box, capitalize, Grid, Tooltip, Typography } from '@mui/material';
import { largeColorSet } from '@styles/utils';

interface AllocationBarChartProps {
  data: any[];
  isMultiple: boolean;
  title: string;
}

const AllocationBarChart = forwardRef<HTMLDivElement, AllocationBarChartProps>(
  ({ data, isMultiple, title }: any, ref): React.JSX.Element => {
    const getRefCurrent = () => {
      if (typeof ref === 'function') return undefined;
      return ref?.current;
    };

    return data?.length > 0 ? (
      <Grid item container direction={'column'} pt={!isMultiple ? 2 : 0.5}>
        <Typography variant="body2">{`${capitalize(title)}`}</Typography>
        <ProgressBarWrapper>
          {Array.isArray(data) &&
            data?.map((item: any, index: number) => (
              <Tooltip
                key={item?.name}
                arrow
                title={
                  <Box
                    display="flex"
                    justifyContent={'space-between'}
                    alignItems={'center'}
                  >
                    <LegendColorDiv
                      style={{ background: largeColorSet[index] }}
                    />
                    <LegendTextDiv>
                      {item?.name} - {item?.value.toFixed(2)}%
                    </LegendTextDiv>
                  </Box>
                }
                PopperProps={{
                  container: getRefCurrent(),
                  modifiers: [
                    {
                      name: 'preventOverflow',
                      options: {
                        boundary: getRefCurrent(), //TODO:
                      },
                    },
                  ],
                }}
              >
                <IndividualStack
                  key={item.name}
                  color={largeColorSet[index]}
                  width={`${item?.value.toFixed(2)}%`}
                />
              </Tooltip>
            ))}
        </ProgressBarWrapper>
      </Grid>
    ) : (
      <Typography
        variant="body2"
        sx={{ marginTop: '10px', margin: '10px 0px' }}
      >
        {`No holdings available for ${title} portfolio`}
      </Typography>
    );
  },
);

export default AllocationBarChart;
