import React, { useEffect, useState } from 'react';

import TextInfo from './reusable/TextInfo';

import SearchIcon from '@mui/icons-material/Search';
import sort from '@images/sort.svg';
import filter from '@images/filter.svg';
import dnld from '@images/dnld.svg';
import {
  CardContainer,
  ClientCardContainer,
  ClientsWrapper,
  Filters,
  OutlineBox,
} from './styles';
import {
  Fade,
  Grid,
  Menu,
  MenuItem,
  Popover,
  Slider,
  Typography,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ClientOverview from './reusable/ClientOverview';
import ClientCard from './reusable/ClientCard';
import { MY_CLIENT_TOUR_MOCK } from './utils';
import TourContent from './TourContent';
import { TourComponentProps } from './types';

const FirstComponent = () => {
  const [showClientData, setShowClientData] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setShowClientData(true);
    }, 1000);
  }, []);
  return (
    <Grid sx={{ height: '60vh' }}>
      <TextInfo
        textContent={
          'Next, is the area called My Clients. Here, you can take a specific client (perhaps in advance of a client meeting) and start your journey to understand their portfolio and which events or views will drive their portfolio.'
        }
      />
      <Fade timeout={1500} in={showClientData}>
        <div>
          <SecondComponent />
        </div>
      </Fade>
    </Grid>
  );
};
function SecondComponent(): React.JSX.Element {
  const [anchorEl] = React.useState(null);
  const [selectedSortOption] = React.useState('Name');

  const [selectedFilterRange] = React.useState<number[] | null>(null);

  const [filterAnchorEl] = React.useState(null);
  const isFilterPopoverOpen = Boolean(filterAnchorEl);

  return (
    <ClientCardContainer sx={{ overflow: 'scroll', height: '48vh' }}>
      <ClientsWrapper container rowGap={2} delay={3} duration={15}>
        <Grid item container>
          <ClientOverview />
        </Grid>
        <Grid item container>
          <Grid item container>
            <Filters
              alignItems="center"
              container
              justifyContent={'space-between'}
              border={'none'}
            >
              <Grid item xs={12} md={1}>
                <OutlineBox
                  item
                  xs={6}
                  container
                  justifyContent={'center'}
                  sx={{ cursor: 'pointer' }}
                >
                  <SearchIcon />
                </OutlineBox>
              </Grid>
              <Grid
                item
                container
                xs={12}
                md={5}
                justifyContent={'flex-end'}
                columnGap={1}
              >
                <OutlineBox
                  item
                  container
                  alignItems={'center'}
                  width={'140px'}
                  height={'40px'}
                  justifyContent={'center'}
                  columnGap={0.5}
                >
                  <Grid item>
                    <img
                      src={sort}
                      alt="Sort image"
                      style={{ marginBottom: '2px', cursor: 'pointer' }}
                    />
                  </Grid>
                  <Grid item fontSize={'14px'} mb={'6px'}>
                    {selectedSortOption}
                  </Grid>
                  <Grid item>
                    <KeyboardArrowDownIcon sx={{ height: '25px' }} />
                  </Grid>
                </OutlineBox>
                <Grid item>
                  <img
                    src={filter}
                    alt="Filter image"
                    style={{ height: '40px', cursor: 'pointer' }}
                  />
                </Grid>
                <Popover
                  open={isFilterPopoverOpen}
                  anchorEl={filterAnchorEl}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  PaperProps={{
                    style: {
                      width: '300px',
                      padding: '16px',
                      maxHeight: '40px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    },
                  }}
                >
                  <Grid item xs={12}>
                    <Typography
                      id="filter-slider"
                      gutterBottom
                      style={{ fontSize: '14px', color: '#818181' }}
                    >
                      {`Filter by Portfolio Value(${selectedFilterRange})`}
                    </Typography>
                    <Slider
                      valueLabelDisplay="auto"
                      valueLabelFormat={value => `$${value}`}
                      min={0}
                      max={5000000}
                      aria-labelledby="filter-slider"
                      sx={{
                        width: '100%',
                        '& .MuiSlider-rail': { height: 2 },
                        '& .MuiSlider-track': { height: 2 },
                        '& .MuiSlider-thumb': { width: 12, height: 12 },
                      }}
                    />
                  </Grid>
                </Popover>
                <Menu anchorEl={anchorEl} open={Boolean(anchorEl)}>
                  <MenuItem>Sort by Name</MenuItem>
                  <MenuItem>Sort by Value</MenuItem>
                </Menu>
                <img
                  src={dnld}
                  alt="Download image"
                  style={{ height: '40px' }}
                />
              </Grid>
            </Filters>
          </Grid>
        </Grid>
        <Grid item container>
          <CardContainer item container columnSpacing={2} rowGap={2}>
            {MY_CLIENT_TOUR_MOCK?.map((client: any, index) => (
              <Grid item xs={3} key={index}>
                <ClientCard
                  clientName={client?.clientName}
                  value={client?.value}
                  title={client?.title}
                  dropdownValue={client?.dropdownValue}
                />
              </Grid>
            ))}
          </CardContainer>
        </Grid>
      </ClientsWrapper>
    </ClientCardContainer>
  );
}

function Clients({
  setAwaitingNavItem,
  setRouteState,
  endTour,
}: TourComponentProps): React.JSX.Element {
  // eslint-disable-next-line react/jsx-key
  const components = [
    // eslint-disable-next-line react/jsx-key
    {
      component: <FirstComponent />,
      timeToShow: 19000,
      nextComponent: 'Integrations',
    },
  ];

  return (
    <TourContent
      setAwaitingNavItem={setAwaitingNavItem}
      setRouteState={setRouteState}
      endTour={endTour}
      components={components}
      showFooterArrow={false}
      margin={105}
    />
  );
}

export default Clients;
