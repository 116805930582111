import { Box } from '@mui/material';
import styled from 'styled-components';
import { shouldForwardProp } from 'utils/forwardProp';

export const MemoSpinnerContainer = styled(Box)<any>`
  height: 15px;
  width: 15px;
  .memo-icon {
    animation: rotation 3s infinite linear;
    height: 15px;
    width: 15px;
  }

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`;

export const MemoCard = styled(Box).withConfig({ shouldForwardProp })<any>`
  border-radius: 18px;
  border: 1px solid ${props => (props.hover ? 'black' : '#818181')};
  background: #fff;
  padding: 8px 18px;
  min-height: 20px;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  cursor: ${props => (props.disabled ? 'unset' : 'pointer')};
  opacity: ${props => (props.disabled ? '0.5' : '1')};

  &:disabled {
    cursor: unset;
    opacity: 0.5;
  }
`;

export const MemoCardText = styled.span.withConfig({ shouldForwardProp })<any>`
  font-size: 11px;
  color: ${props => (props.hover ? 'black' : '#818181')};
  font-weight: 500;
  text-wrap: wrap;
  @media (min-width: 768px) {
    font-size: 14px;
  }
`;
