import { Grid, Box } from '@mui/material';
import styled, { css } from 'styled-components';

interface SplashCardWrapperProps {
  hide?: boolean;
  last?: boolean;
}

interface SplashCardBodyProps {
  animate?: boolean;
}

interface Text2BodyProps {
  isLogout?: boolean;
}

export const SplashCardContainer = styled(Grid)`
  width: 100%;
  height: 100%;
  transition:
    opacity ease-in 0.5s,
    height ease-in 0.5s;

  .slick-slider {
    height: 100%;

    .slick-list {
      height: 100%;

      .slick-track {
        height: 100%;
      }
    }
  }

  ${({ animate }: any) => animate && css``}
`;

export const SplashCardWrapper = styled(Grid)<SplashCardWrapperProps>`
  width: 39%;
  margin: 8rem auto;
  min-width: 484px;
  // height: 350px;
  height: 100%;
  min-height: 55vh;
  opacity: 1;
  transition:
    width ease-in 0.5s,
    min-width ease-in 0.5s,
    max-width ease-in 0.5s,
    opacity ease-in 0.5s;

  ${({ hide, last }) =>
    hide &&
    !last &&
    css`
      opacity: 0;
    `}
`;

export const SplashCardBody = styled(Grid)<SplashCardBodyProps>`
  opacity: 1;
  transition: opacity ease-in 0.5s;

  ${({ animate }) =>
    !animate &&
    css`
      opacity: 0;
    `}
`;

export const Text1 = styled(Grid)`
  color: #fff;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const Text2 = styled(Grid)<Text2BodyProps>`
  position: relative;
  color: #fff;
  font-size: 76px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  display: inline-block;
  text-align: center;

  span {
    color: #182e4b;
    font-size: 86px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  &:before {
    content: '';
    position: absolute;
    width: 80%;
    height: 14px;
    background: rgba(1, 134, 131, 1);
    top: 65px;
    z-index: -1;
    left: 7px;
  }
  &:after {
    content: '';
    position: absolute;
    width: 98%;
    height: 14px;
    background: rgba(1, 134, 131, 1);
    bottom: 10px;
    z-index: -1;
    right: 16px;
  }
  ${({ isLogout }) =>
    isLogout &&
    css`
      &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 14px;
        background: rgba(1, 134, 131, 1);
        // top: 56px;
        z-index: -1;
        left: 6px;
      }
      &:after {
        content: '';
        position: absolute;
        width: 67%;
        height: 14px;
        background: rgba(1, 134, 131, 1);
        bottom: 3px;
        z-index: -1;
        right: 16%;
      }
    `}
`;

export const Text3 = styled(Grid)`
  position: relative;
  color: #fff;
  font-size: 80px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
`;

export const ChatCard = styled(Grid)`
  width: 810px;
  height: 100vh;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    object-fit: contain;
  }
`;

export const LogoutSplashOverlayContainer = styled(Grid)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: rgb(105, 154, 205);
`;

export const LogoutSplashOverlayWrapper = styled(Grid)`
  position: relative;
  width: 100%;
  height: 100vh;
`;

export const LogoutSplashOverlayContent = styled(Grid)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  height: 100vh;
`;
export const StyledButton = styled(Box)`
  padding: 5px 15px;
  font-size: 12px;
  position: absolute;
  right: 0;
  top: 14%;
  font-weight: 500;
  border: 1px solid #e4e7ec;
  border-radius: 20px;
  color: #464646;
  background: #fff;
  width: 25px;
  cursor: pointer;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.12);
  &:hover {
    background-color: rgb(70, 70, 70) !important;
    color: #fff !important;
    border-color: rgb(70, 70, 70) !important;
  }
`;

export const DotsContainer = styled.div`
  position: absolute;
  bottom: 20px; /* Adjust the distance from the bottom as needed */
  left: 17.7%;
  bottom: 23%;
  transform: translateX(-50%);
  z-index: 2; /* Adjust the z-index as needed */
`;
