import ApiInstance from '@instances/index';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const AddQueryForClient = async (payload: any) => {
  const createResponse = await ApiInstance.post('tests/queries', payload, {
    headers: {
      'x-tifin-ai-auth': localStorage.getItem('x-tifin-ai-token'),
    },
  });
  return createResponse?.data;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const updateQueryForClient = async (payload: any) => {
  const createResponse = await ApiInstance.put('tests/queries', payload, {
    headers: {
      'x-tifin-ai-auth': localStorage.getItem('x-tifin-ai-token'),
    },
  });
  return createResponse?.data;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const deleteQueryForClient = async (payload: any) => {
  const createResponse = await ApiInstance.delete(
    `tests/queries?id=${payload.id}`,
  );
  return createResponse?.data;
};
