// HorizontalBarChart.tsx
import React from 'react';
import styled from 'styled-components';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Cell,
} from 'recharts';

interface StyledLiProps {
  value: number;
}

export const LegendWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledList = styled.li<StyledLiProps>`
  color: ${props => (props.value >= 0 ? 'green' : 'red')};
  font-weight: ${props => (props.value < 0 ? 'bold' : 'normal')};
  display: flex;
  align-items: center;
`;

export const StyledUl = styled.ul`
  list-style-type: none;
  padding: 0;
`;

export const StyledSpan = styled.span`
  color: #000;
  font-size: 12px;
`;

function HorizontalBarChart({ data }: { data: any }): React.JSX.Element {
  const CustomLegendContent: React.FC<{ payload?: any[] }> = ({ payload }) => {
    return (
      <LegendWrapper>
        <StyledUl>
          {payload &&
            payload.map((entry, index) => (
              <StyledList key={`item-${index}`} value={entry.value}>
                <StyledSpan>Source: Morningstar</StyledSpan>
              </StyledList>
            ))}
        </StyledUl>
      </LegendWrapper>
    );
  };
  return (
    <div>
      <ResponsiveContainer
        width={450}
        height={
          data && data?.length === 1
            ? 140
            : data?.length === 2
              ? 200
              : data?.length === 3
                ? 250
                : 340
        }
      >
        <BarChart data={data} layout="vertical">
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis type="number" style={{ fontSize: '12px' }} />
          <YAxis dataKey="label" type="category" style={{ fontSize: '12px' }} />
          <Tooltip />
          <Legend content={<CustomLegendContent />} />`
          <Bar dataKey="value">
            {data &&
              data.map((datum, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={datum.value > 0 ? 'green' : 'red'}
                />
              ))}
          </Bar>
          `
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

export default HorizontalBarChart;
