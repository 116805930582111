import {
  AllEffect,
  ForkEffect,
  all,
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import { getQueries } from './api';
import { actions } from './slice';

function* loadQueries() {
  try {
    const response: any = yield call(getQueries);
    if (response) {
      const queries = [...response.data];
      yield put(
        actions.loadQueriesSuccess({
          ...response,
          queries,
        }),
      );
    }
  } catch (error) {
    yield put(actions.loadQueriesError());
  }
}

export function* queriesSaga(): Generator<
  AllEffect<ForkEffect<never>>,
  void,
  unknown
> {
  yield all([
    // Define your sagas here
    takeLatest(actions.loadQueriesRequest, loadQueries),
  ]);
}
