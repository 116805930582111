import { styled } from '@mui/material';
import { ErrorScreenStyleProps } from './types';

export const Wrapper = styled('div')<ErrorScreenStyleProps>`
  display: flex;
  flex-direction: column;
  min-height: ${props => (props.height ? `${props.height}vh` : '90vh')};
  width: ${props => (props.width ? `${props.width}` : '100%')};
  justify-content: center;
  align-items: center;
  margin: auto;

  svg {
    font-size: 1em;
    margin-bottom: 3rem;
  }

  h3 {
    color: #61ffcf;
    font-size: 24px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 6px;
    text-align: center;
    text-transform: uppercase;
  }
  p {
    color: #fff;
    font-weight: 500;
    font-size: 18px;
    text-align: center;
    line-height: normal;
    letter-spacing: 3px;
  }

  button {
    margin-top: 1rem;
  }
`;
