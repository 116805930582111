import { Grid } from '@mui/material';
import styled from 'styled-components';

interface MainContainerProps {
  padding?: string;
  width?: string;
}

interface GraphContainerProps {
  height?: string;
  maxHeight?: string;
}

export const PortfolioContainer = styled.div<MainContainerProps>`
  width: ${props => props.width || ''};
  // height: 91vh;
  height: auto;
  padding: ${props => props.padding ?? '0px 20px 20px'};
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #fff;
`;

export const GraphContainer = styled(Grid)<GraphContainerProps>`
  width: 97%;
  max-height: ${({ maxHeight }) => maxHeight || ''};
  background: #f9f9f9;
  border-radius: 8px;
  padding: 16px;
  gap: 16px;
  flex: 1 0 0;
  overflow-y: auto;
  height: ${({ height }) => height || ''};
`;

export const ComparisonContainerHeader = styled(Grid)<any>`
  color: ${props => props?.color ?? '#282829'};
  font-size: ${props => props?.fontSize ?? '16px'};
  font-weight: ${props => props?.fontWeight ?? '500'};
`;

export const CurrentPageName = styled.div`
  padding: 8px;
  border-radius: 4px;
  background: rgba(16, 24, 40, 0.04);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  font-size: 12px;
  color: #000;
  font-weight: 500;
`;
