import React from 'react';
import { Typography } from '@mui/material';
import { StyledCardContainer, StyledCardContent, StyledSquare } from './styles';

interface FirmCardProps {
  name: string;
  firmNumber: number;
  handleClick: () => void;
  stepper: 'FIRM_LISTING_PAGE' | 'FIRM_DETAILS_PAGE';
}

const FirmCard: React.FC<FirmCardProps> = ({
  name,
  firmNumber,
  handleClick,
}) => {
  return (
    <StyledCardContainer
      item
      onClick={handleClick}
      lg={2.8}
      md={3.7}
      sm={5.75}
      xs={11.8}
    >
      <StyledSquare />
      <StyledCardContent>
        <Typography variant="body1">{name}</Typography>
        <Typography variant="caption" sx={{ color: '#667085' }}>
          {firmNumber} Members
        </Typography>
      </StyledCardContent>
    </StyledCardContainer>
  );
};

export default FirmCard;
