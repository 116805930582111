import { Grid } from '@mui/material';
import React, { useState } from 'react';
import { MemoSpinnerContainer, MemoCard, MemoCardText } from './style';

import MemoMagic from '@icons/Magic';
import Magic from '@images/Magic.svg';
interface Props {
  text: string | React.JSX.Element;
  onClick?: any;
  sx?: any;
  disabled?: boolean;
}

export const MemoSpinner = ({
  hover = false,
}: {
  hover?: boolean;
}): React.JSX.Element => {
  return (
    <MemoSpinnerContainer>
      {hover ? (
        <div className="memo-icon">
          <MemoMagic height={16} width={16} />
        </div>
      ) : (
        <img src={Magic} alt="Magic image" style={{ display: 'flex' }} />
      )}
    </MemoSpinnerContainer>
  );
};

const MemoAnimator: React.FC<Props> = ({
  text,
  onClick,
  sx = () => {},
  disabled = false,
}) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);

  return disabled ? (
    <MemoCard
      width="fit-content"
      alignItems={'center'}
      justifyContent={'center'}
      columnGap={0.75}
      mb={1.25}
      disabled={disabled}
      sx={sx}
    >
      <Grid item alignItems={'center'} display={'flex'}>
        <MemoSpinner />
      </Grid>
      <Grid item>
        <MemoCardText>{text}</MemoCardText>
      </Grid>
    </MemoCard>
  ) : (
    <MemoCard
      width="fit-content"
      alignItems={'center'}
      justifyContent={'center'}
      columnGap={0.75}
      mb={1.25}
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      hover={isHovered}
      sx={sx}
    >
      <Grid item alignItems={'center'} display={'flex'}>
        <MemoSpinner hover={isHovered} />
      </Grid>
      <Grid item>
        <MemoCardText hover={isHovered}>{text}</MemoCardText>
      </Grid>
    </MemoCard>
  );
};

export default MemoAnimator;
