import { convertToNDecimal } from '@common/number';
import { Box } from '@mui/material';
import React from 'react';
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Label,
  Dot,
  ResponsiveContainer,
} from 'recharts';
import { ChartTooltipLabel, ChartTooltipTitle } from './styles';

const CustomizedShape = React.memo((props: any) => {
  const { cx, cy, payload } = props;
  const fill = payload.tickerColor;
  return (
    <g>
      <Dot cx={cx} cy={cy} r={6} fill={fill} />
    </g>
  );
});

const getOverlappedValues = (pointerPayload: any[], data: any[]) => {
  if (!pointerPayload || pointerPayload.length === 0) {
    return null;
  }

  const newPayload: any[] = [];

  const key1 = pointerPayload?.[0]?.dataKey;
  const value1 = pointerPayload?.[0]?.value;

  const key2 = pointerPayload?.[1]?.dataKey;
  const value2 = pointerPayload?.[1]?.value;

  if (!key1 && !key2) {
    return null;
  }

  for (const item of data) {
    if (item && item[key1] === value1 && item[key2] === value2) {
      newPayload.push(item?.fund);
    }
  }

  return newPayload;
};

const CustomTooltip = ({ active, payload, data }: any) => {
  const overlappedValues = getOverlappedValues(payload, data);

  if (active && payload && payload.length) {
    const fund = overlappedValues
      ? overlappedValues.join(', ')
      : payload?.[0].payload.fund;
    return (
      <Box
        sx={{
          background: '#fff',
          border: '1px solid #a9a9a9',
          padding: '10px 20px',
        }}
      >
        <ChartTooltipTitle>{fund}</ChartTooltipTitle>
        {Array.isArray(payload) &&
          payload.length > 0 &&
          payload.map((item: any, i: number) => {
            const value = `${convertToNDecimal(item.value, 1)}${item.name === 'fees' ? ' BPS' : item.unit}`;
            return (
              <Box key={i} display={'flex'}>
                <ChartTooltipLabel>
                  {item.name} : {value}
                </ChartTooltipLabel>
              </Box>
            );
          })}
      </Box>
    );
  }

  return null;
};

const SimpleScatterChart = ({
  data,
  keyY,
  keyX,
}: {
  data: any;
  keyY: string;
  keyX: string;
}): React.JSX.Element => {
  const xTicks = [];
  return (
    <ResponsiveContainer width="100%" height={239}>
      <ScatterChart
        width={350}
        height={239}
        style={{ background: 'white', borderRadius: '2px' }}
        margin={{ top: 15, right: 25, left: 10, bottom: 15 }}
      >
        <CartesianGrid syncWithTicks />
        <XAxis
          type="number"
          axisLine={false}
          dataKey={keyX}
          tick={{ fill: '#86858B', fontSize: '12px' }}
          ticks={xTicks}
          name={keyX.replace('_1d', '')}
          unit={keyX !== 'fees_1d' ? '%' : ''}
          domain={[
            dataMin => (dataMin > 0 ? 0 : 0 - Math.abs(dataMin)),
            dataMax => convertToNDecimal(Number(dataMax * 1.2), 1),
          ]}
        >
          <Label
            value={keyX.replace('_1d', '')}
            offset={-2}
            position={'bottom'}
            style={{
              textTransform: 'capitalize',
              fontSize: '14px',
              color: '#86858B',
            }}
          ></Label>
        </XAxis>
        <YAxis
          dataKey={keyY}
          type="number"
          axisLine={false}
          tick={{ fill: '#86858B', fontSize: '12px' }}
          name={keyY.replace('_1d', '')}
          unit={keyY !== 'fees_1d' ? '%' : ''}
          style={{ fontSize: '12px', color: '#86858B' }}
          domain={[
            dataMin => (dataMin > 0 ? 0 : 0 - Math.abs(dataMin)),
            dataMax => convertToNDecimal(Number(dataMax * 1.2), 1),
          ]}
        >
          <Label
            value={keyY.replace('_1d', '')}
            angle={-90}
            position="center"
            style={{
              textTransform: 'capitalize',
              fontSize: '14px',
              color: '#86858B',
            }}
            dx={-15}
          ></Label>
        </YAxis>
        <Tooltip
          cursor={{ strokeDasharray: '3 3' }}
          content={<CustomTooltip data={data} />}
        />
        <Scatter
          name={`${keyX} VS ${keyY}`}
          data={data}
          shape={<CustomizedShape />}
        />
      </ScatterChart>
    </ResponsiveContainer>
  );
};
export default SimpleScatterChart;
