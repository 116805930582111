import React, { useState } from 'react';
import {
  Modal,
  TextField,
  Button,
  Typography,
  Grid,
  Divider,
  useMediaQuery,
  useTheme,
  Box,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { StyledApplyButton, StyledButton } from '@pages/CIOViewpoints/styles';
import { actions as globalActions } from 'app/features/Global/slice';
import { useDispatch } from 'react-redux';
import { useMutation, useQueryClient } from 'react-query';
import {
  StyledAdminText,
  StyledHorizontalRule,
  StyledLabel,
} from '@pages/SuperAdminPage/styles';
import { addFirmAdmin } from '@pages/SuperAdminPage/mutation';

interface AddFirmModalProps {
  isOpen: boolean;
  onClose: () => void;
  firmDetails: any;
}

function FirmAdminModal({
  isOpen,
  onClose,
  firmDetails,
}: AddFirmModalProps): React.JSX.Element {
  const [firmFirstName, setFirmFirstName] = useState<string>('');
  const [firmLastName, setFirmLastName] = useState<string>('');
  const [firmEmail, setFirmEmail] = useState<string>('');
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const addFirmMutation = useMutation(addFirmAdmin);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleAddFirmSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    const payload = {
      firstName: firmFirstName,
      lastName: firmLastName,
      advioryFirmId: firmDetails?.id,
      email: firmEmail,
    };

    addFirmMutation.mutate(payload, {
      onSuccess: (response: any) => {
        if (response && response.data) {
          queryClient.invalidateQueries('firm-details');
          dispatch(
            globalActions.displayToast({
              duration: 3000,
              toastType: 'success',
              toastMessage: response?.message,
            }),
          );
          onClose();
        } else {
          console.log('Unexpected response:', response);
          dispatch(
            globalActions.displayToast({
              duration: 3000,
              toastType: 'error',
              toastMessage: 'Unexpected response from the server.',
            }),
          );
        }
      },
      onError: (error: any) => {
        console.log({ error });
        if (error?.response?.data && error?.response?.data?.message) {
          dispatch(
            globalActions.displayToast({
              duration: 3000,
              toastType: 'error',
              toastMessage: error?.response?.data?.message,
            }),
          );
        } else {
          dispatch(
            globalActions.displayToast({
              duration: 3000,
              toastType: 'error',
              toastMessage: 'An error occurred while processing the query.',
            }),
          );
        }
      },
    });
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        minHeight="100vh"
      >
        <Box
          width={isSmallScreen ? '90%' : '50%'}
          bgcolor="background.paper"
          p={isSmallScreen ? 2 : 4}
          borderRadius={2}
          boxShadow={24}
        >
          <Grid container rowGap={2.5}>
            <Grid
              item
              container
              xs={12}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <Typography variant="h6" component="div">
                Add Firm Admin
              </Typography>
              <Button
                onClick={onClose}
                style={{
                  minWidth: 'unset',
                  padding: '5px',
                  marginLeft: 'auto',
                }}
              >
                <CloseIcon />
              </Button>
            </Grid>
            <Grid item container xs={12}>
              <Grid item xs={12} sm={5}>
                <StyledAdminText>Administrator Details</StyledAdminText>
              </Grid>
              <Grid
                item
                xs={12}
                sm={7}
                justifyContent={'center'}
                alignSelf={'center'}
              >
                <StyledHorizontalRule />
              </Grid>
            </Grid>
            <Grid item container xs={12} spacing={1}>
              <Grid item xs={12} sm={5.8}>
                <StyledLabel htmlFor="firstName">First Name</StyledLabel>
                <TextField
                  id="firstName"
                  variant="outlined"
                  size="small"
                  fullWidth
                  placeholder="Enter First Name"
                  style={{ paddingTop: '5px' }}
                  value={firmFirstName}
                  onChange={e => setFirmFirstName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={5.8}>
                <StyledLabel htmlFor="lastName">Last Name</StyledLabel>
                <TextField
                  id="lastName"
                  variant="outlined"
                  size="small"
                  fullWidth
                  placeholder="Enter Last Name"
                  style={{ paddingTop: '5px' }}
                  value={firmLastName}
                  onChange={e => setFirmLastName(e.target.value)}
                />
              </Grid>
            </Grid>
            <Grid item container xs={12}>
              <StyledLabel htmlFor="email">Email</StyledLabel>
              <TextField
                id="email"
                variant="outlined"
                size="small"
                fullWidth
                placeholder="Enter Email"
                style={{ paddingTop: '5px' }}
                value={firmEmail}
                onChange={e => setFirmEmail(e.target.value)}
              />
            </Grid>
            <Divider
              style={{
                border: '0.5px solid #e4e7ec',
                width: '100%',
              }}
            />
            <Grid
              item
              container
              xs={12}
              justifyContent={isSmallScreen ? 'center' : 'space-between'}
              alignItems={'center'}
              spacing={isSmallScreen ? 1 : 0}
            >
              <Grid item xs={12} sm="auto">
                <StyledButton
                  variant="outlined"
                  onClick={onClose}
                  style={{
                    marginTop: '10px',
                    width: isSmallScreen ? '100%' : 'auto',
                  }}
                >
                  Cancel
                </StyledButton>
              </Grid>
              <Grid item xs={12} sm="auto">
                <StyledApplyButton
                  variant="outlined"
                  onClick={handleAddFirmSubmit}
                  style={{
                    marginTop: '10px',
                    width: isSmallScreen ? '100%' : 'auto',
                  }}
                  disabled={!firmFirstName || !firmLastName || !firmEmail}
                >
                  Confirm
                </StyledApplyButton>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
}

export default FirmAdminModal;
