/* eslint-disable react/prop-types */
import * as React from 'react';

function BNYIcon(props: any): React.JSX.Element {
  // const paddingTop = '15px';
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={props.width || '136'}
      // width="50"
      height="48"
      viewBox="0 0 50 15"
      // style={{ paddingTop }}
    >
      <defs>
        <filter
          id="alpha"
          width="100%"
          height="100%"
          x="0%"
          y="0%"
          filterUnits="objectBoundingBox"
        >
          <feColorMatrix
            in="SourceGraphic"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"
          ></feColorMatrix>
        </filter>
        <mask id="mask0">
          <g filter="url(#alpha)">
            <path fillOpacity="0.992" d="M0 0H50V15H0z"></path>
          </g>
        </mask>
        <clipPath id="clip1">
          <path d="M0 0H50V15H0z"></path>
        </clipPath>
        <g id="surface5" clipPath="url(#clip1)">
          <path
            fill="#8D908F"
            fillRule="evenodd"
            d="M-.016-.016h.098c1.633.657 3.266 1.317 4.902 1.98-.984.434-1.902.974-2.757 1.622A11.4 11.4 0 01-.016.082v-.098zm0 0"
          ></path>
        </g>
        <mask id="mask1">
          <g filter="url(#alpha)">
            <path fillOpacity="0.949" d="M0 0H50V15H0z"></path>
          </g>
        </mask>
        <clipPath id="clip2">
          <path d="M0 0H50V15H0z"></path>
        </clipPath>
        <g id="surface8" clipPath="url(#clip2)">
          <path
            fill="#4A4A4A"
            fillRule="evenodd"
            d="M34.113 1.914h.387v1.05h-.387v-.523h-1.258v1.407h1V3.39h.356v1.277h-.356v-.461h-1v1.441h1.32V5.16h.356v.883h-2.773v-.328h.418V2.406h-.418v-.328h2.355v-.164zm0 0"
          ></path>
        </g>
        <mask id="mask2">
          <g filter="url(#alpha)">
            <path fillOpacity="0.969" d="M0 0H50V15H0z"></path>
          </g>
        </mask>
        <clipPath id="clip3">
          <path d="M0 0H50V15H0z"></path>
        </clipPath>
        <g id="surface11" clipPath="url(#clip3)">
          <path
            fill="#4B4B4A"
            fillRule="evenodd"
            d="M43.469 2.016c1.082-.07 1.789.402 2.129 1.421.25.852.047 1.594-.614 2.227-.77.566-1.554.586-2.355.066-.688-.597-.93-1.343-.727-2.242.243-.804.762-1.297 1.567-1.472zm.226.39c.57.043.953.336 1.145.887a2.392 2.392 0 01-.227 1.965c-.343.422-.77.535-1.273.344-.29-.176-.5-.418-.63-.739a2.344 2.344 0 01.161-1.933c.203-.293.477-.47.824-.524zm0 0"
          ></path>
        </g>
        <mask id="mask3">
          <g filter="url(#alpha)">
            <path fillOpacity="0.992" d="M0 0H50V15H0z"></path>
          </g>
        </mask>
        <clipPath id="clip4">
          <path d="M0 0H50V15H0z"></path>
        </clipPath>
        <g id="surface14" clipPath="url(#clip4)">
          <path
            fill="#AA8432"
            fillRule="evenodd"
            d="M5.273 2.078a176.83 176.83 0 015 1.965c-1.578.644-3.16 1.281-4.742 1.918a.524.524 0 01-.258.031 11.694 11.694 0 01-2.578-1.949 12.717 12.717 0 012.578-1.965zm0 0"
          ></path>
        </g>
        <mask id="mask4">
          <g filter="url(#alpha)">
            <path fillOpacity="0.957" d="M0 0H50V15H0z"></path>
          </g>
        </mask>
        <clipPath id="clip5">
          <path d="M0 0H50V15H0z"></path>
        </clipPath>
        <g id="surface17" clipPath="url(#clip5)">
          <path
            fill="#4B4A4A"
            fillRule="evenodd"
            d="M12.727 2.078c.687-.004 1.375 0 2.062.02.566.113.867.46.906 1.047-.074.378-.289.64-.648.785.707.285.941.793.695 1.523a1.16 1.16 0 01-.984.574c-.676.016-1.356.02-2.031.016v-.328h.418V2.406h-.418v-.328zm1.097.363c.297-.027.59.012.871.114.262.27.325.57.176.902a.693.693 0 01-.402.309c-.215.015-.43.023-.645.015v-1.34zm0 1.735c.235-.004.473 0 .707.015.395.094.598.344.614.754-.02.38-.211.61-.579.688-.25.015-.496.023-.742.015V4.176zm0 0"
          ></path>
        </g>
        <mask id="mask5">
          <g filter="url(#alpha)">
            <path fillOpacity="0.98" d="M0 0H50V15H0z"></path>
          </g>
        </mask>
        <clipPath id="clip6">
          <path d="M0 0H50V15H0z"></path>
        </clipPath>
        <g id="surface20" clipPath="url(#clip6)">
          <path
            fill="#4B4A4A"
            fillRule="evenodd"
            d="M16.402 2.078c.344-.004.688 0 1.032.02.652.898 1.304 1.8 1.953 2.699.015-.797.023-1.594.015-2.39h-.449v-.329h1.258v.328h-.422V6.11a1.187 1.187 0 01-.371-.035 217.62 217.62 0 00-2.063-2.914 92.828 92.828 0 00-.015 2.555h.418v.328H16.5v-.328h.453c.004-1.047 0-2.098-.02-3.145a.378.378 0 01-.109-.148 3.047 3.047 0 00-.422-.016v-.328zm0 0"
          ></path>
        </g>
        <mask id="mask6">
          <g filter="url(#alpha)">
            <path fillOpacity="0.969" d="M0 0H50V15H0z"></path>
          </g>
        </mask>
        <clipPath id="clip7">
          <path d="M0 0H50V15H0z"></path>
        </clipPath>
        <g id="surface23" clipPath="url(#clip7)">
          <path
            fill="#4B4B4A"
            fillRule="evenodd"
            d="M20.469 2.078h1.613v.328c-.14-.004-.281 0-.422.016.274.437.555.867.84 1.297l.773-1.278a1.577 1.577 0 00-.449-.035v-.328h1.258v.328c-.11-.004-.219 0-.324.016-.36.586-.723 1.172-1.082 1.754-.016.511-.02 1.027-.016 1.539h.453v.328h-1.547v-.328h.45c.007-.492 0-.985-.016-1.473-.383-.601-.762-1.203-1.145-1.8a1.183 1.183 0 00-.386-.036v-.328zm0 0"
          ></path>
        </g>
        <mask id="mask7">
          <g filter="url(#alpha)">
            <path fillOpacity="0.961" d="M0 0H50V15H0z"></path>
          </g>
        </mask>
        <clipPath id="clip8">
          <path d="M0 0H50V15H0z"></path>
        </clipPath>
        <g id="surface26" clipPath="url(#clip8)">
          <path
            fill="#4B4A4A"
            fillRule="evenodd"
            d="M26.242 2.078c.383-.008.766.004 1.145.035.41.91.832 1.817 1.258 2.719.43-.902.847-1.809 1.257-2.719.36-.031.72-.043 1.082-.035v.328h-.453v3.309h.453v.328h-1.55v-.328h.421c.004-.828 0-1.66-.015-2.488-.434.976-.875 1.949-1.324 2.914l-1.418-3.012a95.02 95.02 0 00-.016 2.586h.45v.328h-1.29v-.328h.453V2.406h-.453v-.328zm0 0"
          ></path>
        </g>
        <mask id="mask8">
          <g filter="url(#alpha)">
            <path fillOpacity="0.953" d="M0 0H50V15H0z"></path>
          </g>
        </mask>
        <clipPath id="clip9">
          <path d="M0 0H50V15H0z"></path>
        </clipPath>
        <g id="surface29" clipPath="url(#clip9)">
          <path
            fill="#4A4A4A"
            fillRule="evenodd"
            d="M35.434 2.078h1.55v.328h-.418v3.242h1.223V5.16h.387v.883h-2.742v-.328h.453V2.406h-.453v-.328zm0 0"
          ></path>
        </g>
        <mask id="mask9">
          <g filter="url(#alpha)">
            <path fillOpacity="0.945" d="M0 0H50V15H0z"></path>
          </g>
        </mask>
        <clipPath id="clip10">
          <path d="M0 0H50V15H0z"></path>
        </clipPath>
        <g id="surface32" clipPath="url(#clip10)">
          <path
            fill="#4A4A4A"
            fillRule="evenodd"
            d="M38.758 2.078h1.547v.328h-.45v3.242h1.258V5.16h.356v.883h-2.711v-.328h.418V2.406h-.418v-.328zm0 0"
          ></path>
        </g>
        <mask id="mask10">
          <g filter="url(#alpha)">
            <path fillOpacity="0.969" d="M0 0H50V15H0z"></path>
          </g>
        </mask>
        <clipPath id="clip11">
          <path d="M0 0H50V15H0z"></path>
        </clipPath>
        <g id="surface35" clipPath="url(#clip11)">
          <path
            fill="#4B4A4A"
            fillRule="evenodd"
            d="M49.984 2.078v.328h-.418V6.11a.985.985 0 01-.355-.035c-.695-.969-1.387-1.941-2.082-2.914a92.832 92.832 0 00-.016 2.555h.453v.328h-1.293v-.328h.454c.003-1.047 0-2.098-.016-3.145a.352.352 0 01-.113-.148 3.047 3.047 0 00-.422-.016v-.328c.347-.004.691 0 1.035.02l1.95 2.699a81.29 81.29 0 00.015-2.39h-.45v-.329h1.258zm0 0"
          ></path>
        </g>
        <mask id="mask11">
          <g filter="url(#alpha)">
            <path fillOpacity="0.502" d="M0 0H50V15H0z"></path>
          </g>
        </mask>
        <clipPath id="clip12">
          <path d="M0 0H50V15H0z"></path>
        </clipPath>
        <g id="surface38" clipPath="url(#clip12)">
          <path
            fill="#4C4C4C"
            fillRule="evenodd"
            d="M15.953 8.137h-.258c.008 2.277 0 4.55-.035 6.812V8.105a.721.721 0 01.293.032zm0 0"
          ></path>
        </g>
        <mask id="mask12">
          <g filter="url(#alpha)">
            <path fillOpacity="0.953" d="M0 0H50V15H0z"></path>
          </g>
        </mask>
        <clipPath id="clip13">
          <path d="M0 0H50V15H0z"></path>
        </clipPath>
        <g id="surface41" clipPath="url(#clip13)">
          <path
            fill="#AA8532"
            fillRule="evenodd"
            d="M24.918 9.316h.355v.95h-.32V9.84h-1.195v1.379h.902v-.426h.356v1.21h-.356v-.394h-.902v1.41h1.226v-.425h.356v.816H22.53v-.293h.453V9.742h-.453V9.48h2.387v-.164zm0 0"
          ></path>
        </g>
        <mask id="mask13">
          <g filter="url(#alpha)">
            <path fillOpacity="0.973" d="M0 0H50V15H0z"></path>
          </g>
        </mask>
        <clipPath id="clip14">
          <path d="M0 0H50V15H0z"></path>
        </clipPath>
        <g id="surface44" clipPath="url(#clip14)">
          <path
            fill="#AA8433"
            fillRule="evenodd"
            d="M32.305 9.352h.355v1.046h-.355c.011-.144 0-.285-.032-.425a.835.835 0 00-.804-.149c-.375.27-.383.551-.035.852.39.258.773.523 1.148.804.383.516.402 1.051.063 1.606-.403.379-.872.488-1.403.324a.107.107 0 00-.047.035.909.909 0 00-.293-.164.47.47 0 00-.015.164h-.356v-1.082h.356c-.004.153 0 .305.015.457.36.297.743.336 1.145.117.18-.19.207-.406.082-.64a6.804 6.804 0 00-1.211-.899c-.395-.347-.52-.78-.371-1.293.152-.382.426-.605.824-.671.309-.043.61-.016.902.082a.221.221 0 00.032-.164zm0 0"
          ></path>
        </g>
        <mask id="mask14">
          <g filter="url(#alpha)">
            <path fillOpacity="0.969" d="M0 0H50V15H0z"></path>
          </g>
        </mask>
        <clipPath id="clip15">
          <path d="M0 0H50V15H0z"></path>
        </clipPath>
        <g id="surface47" clipPath="url(#clip15)">
          <path
            fill="#AA8432"
            fillRule="evenodd"
            d="M49.082 9.352h.352v1.078h-.352c.008-.153 0-.305-.035-.457-.875-.403-1.547-.196-2.016.62-.351.852-.195 1.59.469 2.212a1.36 1.36 0 001.258.215v-.95h-.516v-.297h1.711v.297h-.422c.012.399 0 .797-.031 1.196a4.427 4.427 0 01-1.645.195c-1.183-.172-1.804-.848-1.87-2.031.132-1.328.859-2.008 2.175-2.047.305.008.602.062.887.164a.343.343 0 00.035-.195zm0 0"
          ></path>
        </g>
        <mask id="mask15">
          <g filter="url(#alpha)">
            <path fillOpacity="0.945" d="M0 0H50V15H0z"></path>
          </g>
        </mask>
        <clipPath id="clip16">
          <path d="M0 0H50V15H0z"></path>
        </clipPath>
        <g id="surface50" clipPath="url(#clip16)">
          <path
            fill="#AA8432"
            fillRule="evenodd"
            d="M18.953 9.48c.688-.003 1.375 0 2.063.016.695.18.988.633.886 1.36-.117.46-.398.765-.836.917-.238.04-.48.04-.726 0v-.328c.418.075.672-.101.758-.523.039-.281.007-.555-.098-.817a.633.633 0 00-.34-.246c-.16-.02-.32-.023-.484-.02v3.278h.484v.293h-1.707v-.293h.418V9.742h-.418V9.48zm0 0"
          ></path>
        </g>
        <mask id="mask16">
          <g filter="url(#alpha)">
            <path fillOpacity="0.957" d="M0 0H50V15H0z"></path>
          </g>
        </mask>
        <clipPath id="clip17">
          <path d="M0 0H50V15H0z"></path>
        </clipPath>
        <g id="surface53" clipPath="url(#clip17)">
          <path
            fill="#AA8432"
            fillRule="evenodd"
            d="M26.273 9.48c.711-.003 1.422 0 2.13.016.406.043.679.254.824.64.183.716-.059 1.208-.727 1.473.285.45.57.895.855 1.344.133.149.301.203.5.164v.293c-.312.008-.625 0-.937-.015a.594.594 0 01-.465-.375c-.281-.43-.55-.868-.808-1.313a.205.205 0 00-.145-.031v1.441h.418v.293h-1.645v-.293h.422V9.742h-.422V9.48zm1.356.36c.773-.078 1.082.273.918 1.05-.188.38-.492.532-.918.458V9.84zm0 0"
          ></path>
        </g>
        <mask id="mask17">
          <g filter="url(#alpha)">
            <path fillOpacity="0.957" d="M0 0H50V15H0z"></path>
          </g>
        </mask>
        <clipPath id="clip18">
          <path d="M0 0H50V15H0z"></path>
        </clipPath>
        <g id="surface56" clipPath="url(#clip18)">
          <path
            fill="#AA8532"
            fillRule="evenodd"
            d="M33.629 9.48h1.676v.262h-.45v1.477h1.872V9.742h-.454V9.48h1.68v.262H37.5v3.375h.453v.293h-1.68v-.293h.454V11.61h-1.872v1.508h.45v.293h-1.676v-.293h.453V9.742h-.453V9.48zm0 0"
          ></path>
        </g>
        <mask id="mask18">
          <g filter="url(#alpha)">
            <path fillOpacity="0.961" d="M0 0H50V15H0z"></path>
          </g>
        </mask>
        <clipPath id="clip19">
          <path d="M0 0H50V15H0z"></path>
        </clipPath>
        <g id="surface59" clipPath="url(#clip19)">
          <path
            fill="#AA8532"
            fillRule="evenodd"
            d="M38.984 9.48h1.676v.262h-.45v3.375h.45v.293h-1.676v-.293h.45V9.742h-.45V9.48zm0 0"
          ></path>
        </g>
        <mask id="mask19">
          <g filter="url(#alpha)">
            <path fillOpacity="0.969" d="M0 0H50V15H0z"></path>
          </g>
        </mask>
        <clipPath id="clip20">
          <path d="M0 0H50V15H0z"></path>
        </clipPath>
        <g id="surface62" clipPath="url(#clip20)">
          <path
            fill="#AA8432"
            fillRule="evenodd"
            d="M41.531 9.48c.399-.003.797 0 1.196.016.609.879 1.218 1.758 1.82 2.637.02-.797.023-1.594.02-2.39h-.454V9.48h1.356v.262h-.516v3.735c-.14.007-.281 0-.422-.016a131.114 131.114 0 01-1.984-2.832c-.016.828-.02 1.656-.016 2.488h.453v.293H41.66v-.293h.485c.007-1.027 0-2.055-.016-3.078a1.383 1.383 0 01-.176-.277 2.544 2.544 0 00-.422-.02V9.48zm0 0"
          ></path>
        </g>
      </defs>
      <g>
        <use mask="url(#mask0)" xlinkHref="#surface5"></use>
        <use mask="url(#mask1)" xlinkHref="#surface8"></use>
        <use mask="url(#mask2)" xlinkHref="#surface11"></use>
        <use mask="url(#mask3)" xlinkHref="#surface14"></use>
        <use mask="url(#mask4)" xlinkHref="#surface17"></use>
        <use mask="url(#mask5)" xlinkHref="#surface20"></use>
        <use mask="url(#mask6)" xlinkHref="#surface23"></use>
        <use mask="url(#mask7)" xlinkHref="#surface26"></use>
        <use mask="url(#mask8)" xlinkHref="#surface29"></use>
        <use mask="url(#mask9)" xlinkHref="#surface32"></use>
        <use mask="url(#mask10)" xlinkHref="#surface35"></use>
        <path
          fill="#9E8E6B"
          fillRule="evenodd"
          d="M-.016 8.137v-.098a11.494 11.494 0 012.243-3.504c.855.66 1.785 1.203 2.789 1.621A251.95 251.95 0 01.613 7.91a2.383 2.383 0 00-.484.227.3.3 0 00-.145 0zm0 0"
        ></path>
        <use mask="url(#mask11)" xlinkHref="#surface38"></use>
        <path
          fill="#585859"
          fillRule="evenodd"
          d="M15.953 8.137v6.847h-.293v-.035c.035-2.261.043-4.535.035-6.812h.258zm0 0"
        ></path>
        <use mask="url(#mask12)" xlinkHref="#surface41"></use>
        <use mask="url(#mask13)" xlinkHref="#surface44"></use>
        <use mask="url(#mask14)" xlinkHref="#surface47"></use>
        <use mask="url(#mask15)" xlinkHref="#surface50"></use>
        <use mask="url(#mask16)" xlinkHref="#surface53"></use>
        <use mask="url(#mask17)" xlinkHref="#surface56"></use>
        <use mask="url(#mask18)" xlinkHref="#surface59"></use>
        <use mask="url(#mask19)" xlinkHref="#surface62"></use>
      </g>
    </svg>
  );
}

const MemoNoData = React.memo(BNYIcon);
export default MemoNoData;
