import React from 'react';
import Button from '@mui/material/Button';
import NotFound from 'assets/images/ErrorScreens/NotFound';
import DataNotAvailable from 'assets/images/ErrorScreens/DataNotAvailable';
import ServerError from 'assets/images/ErrorScreens/ServerError';
import { DEFAULT_MESSAGE, DEFAULT_TITLE } from './constants';
import { ErrorScreenProps } from './types';
import { Wrapper } from './styles';

function ErrorScreen({
  message = DEFAULT_MESSAGE,
  title = DEFAULT_TITLE,
  variant = 'error',
  showTryAgain = false,
  tryAgain,
  width = '100%',
  height = 100,
  icon,
}: ErrorScreenProps): React.JSX.Element {
  return (
    <Wrapper width={width} height={height}>
      {icon
        ? icon
        : (() => {
            switch (variant) {
              case 'not-found':
                return <NotFound />;
              case 'empty-list':
                return <DataNotAvailable />;
              default:
                return <ServerError />;
            }
          })()}
      <h3>{title}</h3>
      <p>{message}</p>
      {showTryAgain && <Button onClick={tryAgain}>Try Again.</Button>}
    </Wrapper>
  );
}

export default ErrorScreen;
