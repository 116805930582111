import { checkValidNumber, price } from '@common/number';
import { SuggestionCardWrapper } from '@components/SuggestionCard/styles';
import CustomTooltip from '@components/Tooltip';
import { Divider, Grid, useTheme } from '@mui/material';
import {
  Grey2SubLabelText,
  GreySubLabelText,
  ParagraphBoldtText,
  ParagraphLightText,
} from '@styles/Typography';
import React from 'react';
import { Dot } from 'recharts';

type SearchCardProps = {
  cardinfo: any;
};

function index({ cardinfo }: SearchCardProps): React.JSX.Element {
  const theme = useTheme();

  return (
    <SuggestionCardWrapper container>
      <Grid
        item
        container
        rowGap={0.7}
        alignItems={'center'}
        justifyContent={'space-between'}
        sx={{ position: 'relative' }}
      >
        <CustomTooltip title={cardinfo?.instrument?.name || ''}>
          <ParagraphBoldtText
            noWrap
            fontSize={'12px'}
            color={theme.palette.primary['black']}
            width={'100%'}
            sx={{ paddingRight: '26px' }}
          >
            {cardinfo?.instrument?.name}
          </ParagraphBoldtText>
        </CustomTooltip>
        <ParagraphLightText
          width={'100%'}
          fontSize={'10px'}
          color={theme.palette.primary['charcoalBlack']}
        >
          {cardinfo?.instrument?.type}
        </ParagraphLightText>
        <Grey2SubLabelText
          width={'100%'}
          fontSize={'10px'}
          color={theme.palette.primary['grey4']}
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {cardinfo?.instrument?.ticker} <Dot />{' '}
          {price(cardinfo?.instrument?.marketPrice?.value)}
        </Grey2SubLabelText>
        <Divider sx={{ width: '100%' }} />
        <Grid
          container
          rowGap={1}
          alignItems={'center'}
          justifyContent={'center'}
        >
          {Array.isArray(cardinfo?.charts) &&
            cardinfo?.charts?.map((item: any, key: number) => (
              <Grid
                key={key}
                container
                alignItems={'center'}
                justifyContent={'space-between'}
              >
                <GreySubLabelText
                  color={theme.palette.primary['charcoalBlack']}
                >
                  {item?.primaryItemLabel}
                </GreySubLabelText>
                <ParagraphBoldtText fontSize={10} sx={{ display: 'flex' }}>
                  {item?.data?.value
                    ? item.data.value?.label
                    : item?.primaryItemValue?.toFixed(2)}
                </ParagraphBoldtText>
              </Grid>
            ))}
        </Grid>
        <Divider sx={{ width: '100%' }} />
        <Grid
          container
          rowGap={1}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Grid
            container
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <GreySubLabelText color={theme.palette.primary['charcoalBlack']}>
              Fees
            </GreySubLabelText>
            <ParagraphBoldtText
              fontSize={10}
              //   color={getColorCode('Fees').color}
              sx={{ display: 'flex' }}
            >
              {/* {getColorCode('Returns').icon} */}
              {checkValidNumber(
                cardinfo?.instrument?.expenseRatio * 100,
                2,
                '%',
              )}
            </ParagraphBoldtText>
          </Grid>
          <Grid
            container
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <GreySubLabelText color={theme.palette.primary['charcoalBlack']}>
              Returns
            </GreySubLabelText>
            <ParagraphBoldtText
              fontSize={10}
              //   color={getColorCode('Returns').color}
              sx={{ display: 'flex' }}
            >
              {/* {getColorCode('Returns').icon} */}
              {checkValidNumber(
                cardinfo?.instrument?.returns3yUi * 100,
                2,
                '%',
              )}
            </ParagraphBoldtText>
          </Grid>
          <Grid
            container
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <GreySubLabelText color={theme.palette.primary['charcoalBlack']}>
              Risk
            </GreySubLabelText>
            <ParagraphBoldtText
              fontSize={10}
              //   color={getColorCode('Risk').color}
              sx={{ display: 'flex' }}
            >
              {/* {getColorCode('Risk').icon} */}
              {checkValidNumber(
                cardinfo?.instrument?.volatility3yUi * 100,
                2,
                '%',
              )}
            </ParagraphBoldtText>
          </Grid>
        </Grid>
      </Grid>
    </SuggestionCardWrapper>
  );
}

export default index;
