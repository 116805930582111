/* eslint-disable react/prop-types */

import React from 'react';

function LeftArrow(props: any): React.JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke={props.stroke || '#DEDEDF'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M10 12L6 8l4-4"
      ></path>
    </svg>
  );
}

const MemoLeftArrow = React.memo(LeftArrow);
export default MemoLeftArrow;
