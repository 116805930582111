import React from 'react';
import { useDispatch } from 'react-redux';
import ColorSageIcon from '@icons/ColorSage';
import {
  ContentInnerWrapper,
  ContentWrapper,
  DateText,
  DateWrapper,
  DescText,
  IconContainer,
  PortfolioHistoryContainer,
  PortfolioHistoryWrapper,
  SubText,
  TextContainer,
  TitleText,
} from './styles';
import { Button, Grid, Typography } from '@mui/material';
import MemoAnimator from '@components/UIComponents/MemoAnimator';
import { useRESTQuery } from '@hooks/useRESTQuery';
import { handleError } from '@common/error';
import { LoaderContainer } from '@pages/Signup/styles';
import { Spinner } from '@pages/IntegrationPage/styles';
import moment from 'moment';
import { EAdjustmentStepper } from 'app/enums';

interface ProposedAdjustmentProps {
  setStepper: React.Dispatch<React.SetStateAction<string>>;
  setSelectedCard: React.Dispatch<
    React.SetStateAction<{ title: string; desc: string; id: string } | null>
  >;
  clientId?: string;
  clientName?: string;
}

function AdjustmentLog({
  clientId,
  setStepper,
  setSelectedCard,
  clientName,
}: ProposedAdjustmentProps): React.JSX.Element {
  const dispatch = useDispatch();

  const { data: adjustmentData, isLoading } = useRESTQuery(
    ['get-adjustment-data', clientId],
    {
      endpoint: `/client/proposedAdjustments?id=${clientId}`,
      method: 'GET',
      headers: {
        'X-Tifin-Ai-Auth': localStorage.getItem('x-tifin-ai-token'),
      },
    },
    {
      enabled: !!clientId,
      refetchOnMount: 'always',
      select: response => {
        return response?.data || [];
      },

      onError: (error: any) => {
        handleError(dispatch, error);
      },
    },
  );

  // formatting the updatedAt key which holds the date in required format
  const formatDate = (dateString: string): string => {
    const date = moment.tz(dateString, 'America/Phoenix');
    const formattedDate = date.format('MM/DD/YYYY');
    const formattedTime = date.format('hh:mm A');
    const formattedTimeZone = date.format('z');

    return `${formattedDate} • ${formattedTime} (${formattedTimeZone})`;
  };

  if (isLoading)
    return (
      <LoaderContainer>
        <Spinner />
      </LoaderContainer>
    );

  return (
    <PortfolioHistoryContainer container>
      <Grid
        item
        container
        xs={11.6}
        sx={{
          height: '100%',
        }}
      >
        {adjustmentData.length === 0 ? (
          <Grid
            item
            container
            justifyContent={'center'}
            alignItems={'center'}
            xs={12}
          >
            <Typography variant="h6">
              Please create new proposals to display adjustments data.
            </Typography>
          </Grid>
        ) : (
          Array.isArray(adjustmentData) &&
          adjustmentData?.length > 0 &&
          adjustmentData?.map((item, index) => {
            return (
              <PortfolioHistoryWrapper item container xs={12} key={index}>
                <DateWrapper
                  item
                  container
                  xs={12}
                  columnGap={1}
                  // height={'30px'}
                >
                  <IconContainer
                    item
                    container
                    alignItems={'center'}
                    xs={1}
                    justifyContent={'flex-start'}
                  >
                    <ColorSageIcon width="50px" height="50px" />
                  </IconContainer>
                  <TextContainer
                    item
                    container
                    xs={10}
                    mt={'8px'}
                    rowGap={'3px'}
                    // mb={'6px'}
                    justifyContent={'flex-start'}
                    alignItems={'center'}
                  >
                    <Grid item xs={12}>
                      <DateText item container>
                        {item?.updatedAt && (
                          <p>{formatDate(item?.updatedAt)}</p>
                        )}
                      </DateText>
                    </Grid>
                    <Grid item container xs={12}>
                      <SubText>{`Model portfolio match for ${clientName}'s portfolio`}</SubText>
                    </Grid>
                  </TextContainer>
                </DateWrapper>
                <ContentWrapper
                  item
                  container
                  alignItems={'center'}
                  justifyContent={'flex-start'}
                  xs={12}
                >
                  <ContentInnerWrapper
                    item
                    container
                    xs={12}
                    justifyContent={'center'}
                    alignItems={'center'}
                  >
                    <Grid
                      item
                      container
                      xs={12}
                      justifyContent={'flex-start'}
                      alignItems={'center'}
                      pb={'2px'}
                    >
                      <TitleText>
                        {item?.title && <span>{item?.title}</span>}
                      </TitleText>
                    </Grid>
                    <Grid item container xs={12}>
                      <DescText
                        sx={{
                          color: '#86858B',
                          fontSize: '14px',
                        }}
                      >
                        {item?.modelName && <span>{item?.modelName}</span>}
                      </DescText>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={12}
                      justifyContent={'space-between'}
                      alignItems={'center'}
                      mt={3}
                      rowGap={1}
                    >
                      <Grid
                        item
                        container
                        alignItems={'center'}
                        xs={12}
                        sm={6}
                        sx={{
                          pointerEvents: 'none',
                          opacity: 0.5,
                        }}
                      >
                        <MemoAnimator
                          sx={{
                            marginBottom: '0px',
                            width: {
                              xs: '100%',
                              sm: 'auto',
                            },
                          }}
                          text={
                            <p
                              style={{
                                paddingLeft: '5px',
                                fontSize: '14px',
                                fontWeight: 500,
                                lineHeight: 'normal',
                              }}
                            >
                              Display Conversation
                            </p>
                          }
                        />
                      </Grid>
                      <Grid
                        item
                        container
                        justifyContent={{
                          sm: 'flex-end',
                        }}
                        alignItems={'center'}
                        xs={12}
                        sm={6}
                      >
                        <Button
                          variant="outlined"
                          sx={{
                            width: {
                              xs: '100%',
                              sm: 'auto',
                            },
                            textTransform: 'none',
                            fontSize: '14px',
                            lineHeight: 'normal',
                            color: '#535256',
                            padding: '10px',
                            borderRadius: '4px',
                            border: '1px solid #86858b',
                            background: '#fff',
                          }}
                          onClick={() => {
                            setSelectedCard({
                              title: item?.title,
                              desc: item?.modelName,
                              id: item?.id,
                            });
                            setStepper(EAdjustmentStepper.PortfolioComparison);
                          }}
                        >
                          Compare to My Portfolio
                        </Button>
                      </Grid>
                    </Grid>
                  </ContentInnerWrapper>
                </ContentWrapper>
              </PortfolioHistoryWrapper>
            );
          })
        )}
      </Grid>
    </PortfolioHistoryContainer>
  );
}

export default AdjustmentLog;
