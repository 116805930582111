/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import BoltIcon from '@icons/bolt';
import FirmAdminTable from './FirmAdminTable';
import { price } from '@common/number';
import { Grid, Typography } from '@mui/material';
import { FirmAdminCard } from '@pages/EnterpriseAdmin/styles';
import { validArray } from '@common/validArray';

interface FirmListingProps {
  setStepper: React.Dispatch<
    React.SetStateAction<'ADVISOR_LISTING_PAGE' | 'ADVISOR_DETAILS_PAGE'>
  >;
  stepper: 'ADVISOR_LISTING_PAGE' | 'ADVISOR_DETAILS_PAGE';
  setAdvisorDetails: React.Dispatch<React.SetStateAction<any>>;
  firmDetails: any;
}

function FirmManagement({
  setAdvisorDetails,
  stepper,
  setStepper,
  firmDetails,
}: FirmListingProps): React.JSX.Element {
  return (
    <Grid container>
      <Grid
        container
        rowGap={2.5}
        sx={{
          maxHeight: 'calc(100vh - 120px)',
          overflowY: 'auto',
        }}
      >
        <Grid
          item
          container
          xs={11.7}
          columnGap={2}
          rowGap={2}
          lg={12}
          md={11.9}
        >
          {validArray(firmDetails?.stats) &&
            firmDetails.stats
              .filter(
                item =>
                  item?.key !== 'Total Admins' &&
                  item?.key !== 'Total Enterprise Admins',
              )
              .map((item, index) => (
                <FirmAdminCard
                  key={index}
                  item
                  container
                  xs={12}
                  sm={12}
                  md={3.8}
                >
                  <Grid
                    item
                    alignItems={'center'}
                    justifyContent={'flex-start'}
                    xs={2}
                  >
                    <BoltIcon
                      width="36px"
                      height="36px"
                      fill="#E8FFF7"
                      stroke="#333"
                    />
                  </Grid>
                  <Grid
                    item
                    container
                    height={'30x'}
                    mt={'-5px'}
                    justifyContent={'flex-start'}
                    xs={10}
                    direction={'column'}
                  >
                    <Typography sx={{ fontSize: '14px', color: '#475467' }}>
                      {item?.key}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: '18px',
                        color: '#101828',
                        marginTop: '-3px',
                      }}
                    >
                      {item?.key === 'Total AUM'
                        ? price(item.value)
                        : item.value}
                    </Typography>
                  </Grid>
                </FirmAdminCard>
              ))}
        </Grid>
        <Grid item container xs={11.7}>
          <FirmAdminTable
            setAdvisorDetails={setAdvisorDetails}
            setStepper={setStepper}
            stepper={stepper}
            adminData={firmDetails?.firmAdminstrationUsers}
            advisorData={firmDetails?.firmUsers}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default FirmManagement;
