import { Grid, styled } from '@mui/material';
import React, { useState } from 'react';
import DialogModal from '../DialogModal';
import { HTMLParser } from '@components/HTMLParser';
import { useSelector } from 'react-redux';
import { getAuthDetails } from '@features/Profile/selector';
import ReactJson from 'react-json-view';
import GenericAccordion from '@components/GenericAccordion';
import MemoAnimator from '@components/UIComponents/MemoAnimator';
import { shouldForwardProp } from 'utils/forwardProp';

export const TextWrapper = styled(Grid, { shouldForwardProp })`
  background: #eee;
  margin-top: 20px;
  box-shadow: 0px 0px 45px 10px #ccc;
  padding: 1.5rem;
  border-radius: 8px;

  p {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;

interface ExplanationProps {
  data: any;
  dataToVisualize: any;
}

function Explanations({
  data,
  dataToVisualize,
}: ExplanationProps): React.JSX.Element {
  const [showModel, setShowModel] = useState(false);
  const advisorDetails = useSelector(getAuthDetails);

  const handleClose = () => {
    setShowModel(false);
  };

  const convertExpertData = () => [
    {
      key: 'Explore underlying data',
      value: (
        <ReactJson
          src={dataToVisualize}
          enableClipboard={false}
          displayDataTypes={false}
          displayObjectSize={false}
        />
      ),
    },
  ];

  return (
    <>
      <MemoAnimator
        onClick={() => setShowModel(true)}
        text={
          <p
            style={{
              color: 'var(--Gray-2, #333)',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: 'normal',
            }}
          >
            See how we got this response
          </p>
        }
      />
      {showModel && (
        <DialogModal
          openModal={showModel}
          handleClose={handleClose}
          title="Response Explanation"
          showTitle
        >
          <Grid container rowGap={2}>
            <TextWrapper item container>
              <HTMLParser content={data} />
            </TextWrapper>
            {advisorDetails?.expertMode && dataToVisualize?.length > 0 && (
              <GenericAccordion data={convertExpertData()} />
            )}
          </Grid>
        </DialogModal>
      )}
    </>
  );
}

export default Explanations;
