import React, { useState } from 'react';
import {
  Modal,
  TextField,
  Button,
  Typography,
  Grid,
  Divider,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ColorMediaIcon from '@icons/ColorMedia';
import { StyledApplyButton, StyledButton } from '@pages/CIOViewpoints/styles';
import {
  AcceptedFileTypeLabelDiv,
  FileUploadContainer,
  ModalContainer,
  StyledAdminText,
  StyledHorizontalRule,
  StyledLabel,
  StyledModalInput,
  UploadFileLabel,
  UploadFileLabelContainer,
} from './styles';
import { actions as globalActions } from 'app/features/Global/slice';
import { useDispatch } from 'react-redux';
import { useMutation, useQueryClient } from 'react-query';
import { addFirm } from './mutation';

interface AddFirmModalProps {
  isOpen: boolean;
  onClose: () => void;
  handleSuccessAndClose: () => void;
  enterpriseDetails: any;
}

function AddFirmModal({
  isOpen,
  onClose,
  handleSuccessAndClose,
  enterpriseDetails,
}: AddFirmModalProps): React.JSX.Element {
  const [firmFirstName, setFirmFirstName] = useState<string>('');
  const [firmLastName, setFirmLastName] = useState<string>('');
  const [firmEmail, setFirmEmail] = useState<string>('');
  const [firmApl, setFirmApl] = useState<any>('');
  const [firmName, setFirmName] = useState<string>('');
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const addFirmMutation = useMutation(addFirm);
  const handleFileUpload = e => {
    const uploadedFile = e.target.files[0];

    // Check if a file is selected
    if (uploadedFile) {
      // Get the file extension
      // const extension = uploadedFile.name.split('.').pop().toLowerCase();
      // Check if the file extension is allowed (.png or .jpg)
      // if (['png', 'jpg'].includes(extension)) {
      //   setLogoFile(uploadedFile);
      //   setUploadedFileName(uploadedFile.name);
      // } else {
      //   // Display an error message or handle the restriction as needed
      //   console.error('Invalid file type. Please select a .png or .jpg file.');
      // }
    }
  };

  const handleAddFirmSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    const payload = {
      advisoryFirmName: firmName,
      firstName: firmFirstName,
      lastName: firmLastName,
      enterpriseId: enterpriseDetails?.id,
      adminEmail: firmEmail,
    };

    addFirmMutation.mutate(payload, {
      onSuccess: (response: any) => {
        console.log('add firm res--->', response);
        console.log(response.data);
        if (response && response.data) {
          queryClient.invalidateQueries('get-all-advisor-firm');
          dispatch(
            globalActions.displayToast({
              duration: 3000,
              toastType: 'success',
              toastMessage: response?.message,
            }),
          );
          handleSuccessAndClose();
        } else {
          console.log('Unexpected response:', response);
          dispatch(
            globalActions.displayToast({
              duration: 3000,
              toastType: 'error',
              toastMessage: 'Unexpected response from the server.',
            }),
          );
        }
      },
      onError: (error: any) => {
        console.log({ error });
        if (error?.response?.data && error?.response?.data?.message) {
          dispatch(
            globalActions.displayToast({
              duration: 3000,
              toastType: 'error',
              toastMessage: error?.response?.data?.message,
            }),
          );
        } else {
          dispatch(
            globalActions.displayToast({
              duration: 3000,
              toastType: 'error',
              toastMessage: 'An error occurred while processing the query.',
            }),
          );
        }
      },
    });
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <ModalContainer container rowGap={2.5}>
        <Grid
          item
          container
          xs={12}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Typography variant="h6" component="div">
            Add Firm
          </Typography>
          <Button
            onClick={onClose}
            style={{
              minWidth: 'unset',
              padding: '5px',
              marginLeft: 'auto',
            }}
          >
            <CloseIcon />
          </Button>
        </Grid>
        <Grid item container xs={12}>
          <StyledLabel htmlFor="firmName">Firm Name</StyledLabel>
          <TextField
            id="firmName"
            variant="outlined"
            size="small"
            fullWidth
            placeholder="Enter Firm Name"
            style={{ paddingTop: '5px' }}
            value={firmName}
            onChange={e => setFirmName(e.target.value)}
          />
        </Grid>
        <Grid item container xs={12}>
          <Grid item xs={5}>
            <StyledAdminText>Administrator Details</StyledAdminText>
          </Grid>
          <Grid item xs={7} justifyContent={'center'} alignSelf={'center'}>
            <StyledHorizontalRule />
          </Grid>
        </Grid>
        <Grid item container xs={12} columnGap={1}>
          <Grid item container xs={5.8}>
            <StyledLabel htmlFor="firstName">First Name</StyledLabel>
            <TextField
              id="firstName"
              variant="outlined"
              size="small"
              fullWidth
              placeholder="Enter First Name"
              style={{ paddingTop: '5px' }}
              value={firmFirstName}
              onChange={e => setFirmFirstName(e.target.value)}
            />
          </Grid>

          <Grid item container xs={5.8}>
            <StyledLabel htmlFor="lastName">Last Name</StyledLabel>
            <TextField
              id="lastName"
              variant="outlined"
              size="small"
              fullWidth
              placeholder="Enter Last Name"
              style={{ paddingTop: '5px' }}
              value={firmLastName}
              onChange={e => setFirmLastName(e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid item container xs={12}>
          <StyledLabel htmlFor="email">Email</StyledLabel>
          <TextField
            id="email"
            variant="outlined"
            size="small"
            fullWidth
            placeholder="Enter Email"
            style={{ paddingTop: '5px' }}
            value={firmEmail}
            onChange={e => setFirmEmail(e.target.value)}
          />
        </Grid>
        <Divider
          style={{
            border: '0.5px solid #e4e7ec',
            width: '100%',
          }}
        />
        <Grid item container xs={12}>
          <StyledLabel htmlFor="approvedProductList">
            Approved Product List
          </StyledLabel>
          <TextField
            id="approvedProductList"
            variant="outlined"
            size="small"
            fullWidth
            placeholder="Enter Product"
            style={{ paddingTop: '5px' }}
            value={firmApl}
            onChange={e => setFirmApl(e.target.value)}
          />
        </Grid>
        <FileUploadContainer
          item
          container
          xs={12}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <UploadFileLabelContainer htmlFor="uploadLogoInput">
            <StyledModalInput
              type="file"
              id="uploadLogoInput"
              inputProps={{ accept: '.png, .jpg' }}
              onChange={e => handleFileUpload(e)}
            />

            <ColorMediaIcon />
            <UploadFileLabel>Click to upload firm logo</UploadFileLabel>
            <AcceptedFileTypeLabelDiv>.jpg or .PNG</AcceptedFileTypeLabelDiv>
          </UploadFileLabelContainer>
        </FileUploadContainer>
        <Grid
          item
          container
          xs={12}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <StyledButton
            variant="outlined"
            onClick={onClose}
            style={{ marginTop: '10px' }}
          >
            Cancel
          </StyledButton>
          <StyledApplyButton
            variant="outlined"
            onClick={handleAddFirmSubmit}
            style={{ marginTop: '10px' }}
            disabled={
              !firmName || !firmFirstName || !firmLastName || !firmEmail
            }
          >
            Confirm
          </StyledApplyButton>
        </Grid>
      </ModalContainer>
    </Modal>
  );
}

export default AddFirmModal;
