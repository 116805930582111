import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import FirmCard from './FirmCard';
import FirmDetailsPage from './FirmDetailsPage';
import { useRESTQuery } from '@hooks/useRESTQuery';
import { EntAdminCardContainer, EntAdminCardWrapper } from './styles';
import { SuperAdminWrapper } from '@pages/SuperAdminPage/styles';
import { handleError } from '@common/error';
import { validArray } from '@common/validArray';
import { LoaderContainer } from '@pages/Signup/styles';
import { Spinner } from '@pages/IntegrationPage/styles';

type StepperState = 'FIRM_LISTING_PAGE' | 'FIRM_DETAILS_PAGE';

interface FirmDetails {
  id: string;
  name: string;
  totalAdvisors: number;
}

const STEPPER_STATE: Record<string, StepperState> = {
  FIRM_LISTING_PAGE: 'FIRM_LISTING_PAGE',
  FIRM_DETAILS_PAGE: 'FIRM_DETAILS_PAGE',
};

const EnterpriseAdmin: React.FC = () => {
  const [stepper, setStepper] = useState<StepperState>(
    STEPPER_STATE.FIRM_LISTING_PAGE,
  );
  const [firmDetails, setFirmDetails] = useState<FirmDetails>({
    id: '',
    name: '',
    totalAdvisors: 0,
  });
  const dispatch = useDispatch();

  const handleCardClick = (item: FirmDetails) => {
    setFirmDetails(item);
    setStepper(STEPPER_STATE.FIRM_DETAILS_PAGE);
  };

  const { data, error, isLoading } = useRESTQuery(
    ['get-firm-details'],
    {
      endpoint: '/firms/',
      method: 'GET',
      headers: {
        'X-Tifin-Ai-Auth': localStorage.getItem('x-tifin-ai-token') || '',
      },
    },
    {
      select: (res: any) => res?.data,
      onError: (err: any) => handleError(dispatch, err),
    },
  );

  if (isLoading)
    return (
      <LoaderContainer>
        <Spinner />
      </LoaderContainer>
    );

  if (error) {
    return <div>Failed to load firm details. Please try again later.</div>;
  }

  return (
    <SuperAdminWrapper container>
      {stepper === STEPPER_STATE.FIRM_DETAILS_PAGE ? (
        <FirmDetailsPage
          setStepper={setStepper}
          firmDetails={firmDetails}
          stepper={stepper}
        />
      ) : (
        <EntAdminCardContainer item xs={12}>
          <EntAdminCardWrapper item container xs={12}>
            {validArray(data) ? (
              data.map((item: FirmDetails, index: number) => (
                <FirmCard
                  key={index}
                  name={item.name}
                  firmNumber={item.totalAdvisors}
                  handleClick={() => handleCardClick(item)}
                  stepper={stepper}
                />
              ))
            ) : (
              <div>No firms available.</div>
            )}
          </EntAdminCardWrapper>
        </EntAdminCardContainer>
      )}
    </SuperAdminWrapper>
  );
};

export default EnterpriseAdmin;
