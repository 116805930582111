import instance from 'utils/instances';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const deleteCurrentEvent = async (payload: any) => {
  const createResponse = await instance.delete(
    `/viewpoints/currentEvents?id=${payload?.id}`,
    {
      headers: {
        'x-tifin-ai-auth': localStorage.getItem('x-tifin-ai-token'),
        'content-type': undefined,
      },
    },
  );
  return createResponse?.data;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const updateCurrentEvent = async (payload: FormData) => {
  const createResponse = await instance.put(
    `/viewpoints/currentEvents?id=${payload.get('id')}`,
    payload,
    {
      headers: {
        'x-tifin-ai-auth': localStorage.getItem('x-tifin-ai-token'),
        'content-type': undefined,
      },
    },
  );
  return createResponse?.data;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const publishCurrentEvent = async (payload: any) => {
  const createResponse = await instance.put(
    `/viewpoints/currentEvents/publish?id=${payload.id}`,
    payload,
    {
      headers: {
        'x-tifin-ai-auth': localStorage.getItem('x-tifin-ai-token'),
        'content-type': undefined,
      },
    },
  );
  return createResponse?.data;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const createCurrentEvent = async (payload: FormData) => {
  const createResponse = await instance.post(
    '/viewpoints/currentEvents',
    payload,
    {
      headers: {
        'x-tifin-ai-auth': localStorage.getItem('x-tifin-ai-token'),
        'content-type': undefined,
      },
    },
  );
  return createResponse?.data;
};
