import React from 'react';
import { Card, CardContent, Container, Box } from '@mui/material';
import {
  DrawerHeaderWrapper,
  ExpectedFundDataContainer,
  ExpectedFundDataWrapper,
  FundDataNumber,
  FundDataText,
} from '@pages/ClientsV2/styles';
import { IPortfolioProps } from './types';
import ReturnIcon from '@icons/Return';
import RiskIcon from '@icons/Risk';

function PortfolioList({ portfolios }: IPortfolioProps): React.JSX.Element {
  return (
    <Container maxWidth="lg" sx={{ padding: '0 !important' }}>
      <Box display="flex" flexWrap="wrap" justifyContent="space-between">
        {portfolios.map((item, index) => (
          <Card
            key={index}
            style={{
              width: '48%',
              marginBottom: '16px',
              boxShadow: '0px 4px 8px 0px rgba(16, 24, 40, 0.10)',
              border: item?.highlight
                ? '3px solid rgb(123, 115, 228)'
                : '1px solid #e4e7ec',
            }}
          >
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                columnGap: '16px',
                flexWrap: 'wrap',
                rowGap: '8px',
                background: '#fff',
                padding: '20px 25px 0px 25px',
              }}
            >
              <DrawerHeaderWrapper style={{ textShadow: 'none' }}>
                {item?.name}
              </DrawerHeaderWrapper>
              <ExpectedFundDataContainer>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <ReturnIcon />

                  <ExpectedFundDataWrapper
                    style={{ flexDirection: 'column', marginRight: '35px' }}
                  >
                    <FundDataText>Expected Risk</FundDataText>
                    <FundDataNumber style={{ paddingTop: '0px' }}>
                      {item.expectedRisk}%
                    </FundDataNumber>
                  </ExpectedFundDataWrapper>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <RiskIcon />
                  <ExpectedFundDataWrapper style={{ flexDirection: 'column' }}>
                    <FundDataText>Expected Returns</FundDataText>
                    <FundDataNumber style={{ paddingTop: '0px' }}>
                      {item.expectedReturns}%
                    </FundDataNumber>
                  </ExpectedFundDataWrapper>
                </div>
              </ExpectedFundDataContainer>
            </CardContent>
          </Card>
        ))}
      </Box>
    </Container>
  );
}

export default PortfolioList;
