import React from 'react';
import { ITEM_TYPES } from 'utils/constant/itemTypes';
import { IEnhancementFeaturesProps } from '@components/ChatContent/types';
import { useChatEnhancementFeatures } from '@components/ChatContent/hooks/useChatEnhancementFeatures';
import AssessmentRiskResult from '@pages/ClientsV2/AssessmentRiskResult';
import { RiskAssessmentContainer } from '@components/ChatContent/styles';
import MemoAnimator from '@components/UIComponents/MemoAnimator';

const ChatEnhancementFeatures: React.FC<IEnhancementFeaturesProps> = ({
  type,
  content,
  isLastItem,
  createdAt,
}) => {
  const {
    handleProposal,
    isProposalLoading,
    portfolioRisk,
    riskCapacity,
    riskPreference,
  } = useChatEnhancementFeatures({ content, type, createdAt, isLastItem });

  if (type === ITEM_TYPES.generate_proposal) {
    return (
      <MemoAnimator
        text="View Proposal"
        onClick={e => isLastItem && handleProposal?.(e)}
        disabled={!isLastItem || isProposalLoading}
      />
    );
  }

  if (type === ITEM_TYPES.risk_details) {
    return (
      <RiskAssessmentContainer>
        <AssessmentRiskResult
          riskCapacity={riskCapacity}
          riskPreference={riskPreference}
          portfolioRisk={portfolioRisk}
        />
      </RiskAssessmentContainer>
    );
  }

  return <></>;
};

export default ChatEnhancementFeatures;
