import React from 'react';
import { Helmet } from 'react-helmet-async';
import ErrorScreen from '../ErrorScreen';

export function NotFoundPage(): React.JSX.Element {
  return (
    <>
      <Helmet>
        <title>404 Page Not Found</title>
        <meta name="description" content="Page not found" />
      </Helmet>
      <ErrorScreen
        title="Something's wrong here"
        message="This is a 404 error which means either you've clicked on a bad link or entered an invalid URL."
        variant="not-found"
      />
    </>
  );
}
