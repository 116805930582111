import styled from 'styled-components';
import { Grid } from '@mui/material';
import { keyframes } from 'styled-components';

export const StyledDrawerContainer = styled.div`
  padding: 25px 25px 0px 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  height: 100%;
  background: #fff;
`;

export const StyledLoginWrapper = styled.div`
  display: flex;
  padding: 35px 30px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  // border: 1px solid #e4e7ec;
  // width: 86%;
  // height: 200px;
  margin-bottom: 20px;
  border-radius: 4px;
`;

export const StyledLogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 30px;
  padding-top: 40px;
`;

export const StyledLogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 25px;
`;

export const StyledModalTextContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 1rem;
  flex-direction: column;
  padding-bottom: 30px;
`;

export const StyledText = styled.div`
  color: #000;
  font-size: 18px;
  text-align: center;
  font-weight: 500;
`;
export const StyledTextModal = styled.div`
  color: #000;
  font-size: 24px;
  text-align: center;
  font-weight: 600;
`;

export const StyledModalDescription = styled.div`
  color: var(--neutral-600, #475467);
  font-size: 18px;
  text-align: center;
  font-weight: 500;
`;
export const StyledModalCustodialTitle = styled(StyledModalDescription)`
  color: #000;
`;
export const StyledCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const StyledAccordionContainer = styled.div`
  height: 435px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const StyledFooterContainer = styled.div`
  display: flex;
  width: 100%;
  height: 37px;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding-bottom: 20px;
`;

export const StyledText1 = styled.div`
  color: var(--neutral-600, #475467);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const StyledUnderlinedText = styled.div`
  color: var(--neutral-600, #475467);
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-left: 5px;
`;

export const StyledTextDiv2 = styled.div`
  font-size: 14px;
  text-decoration: underline;
  font-weight: 500;
  cursor: pointer;
  padding-left: 5px;
  line-height: normal;
`;

export const StyledTextDiv1 = styled.div`
  font-size: 14px;
  color: #475467;
  font-weight: 400;
  line-height: normal;
`;

export const TextContainer = styled.div`
  height: 20px;
  padding: 0px 25px 25px 30px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  flex-direction: row;
`;

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TitleContainer = styled.div`
  font-size: 14px;
  color: #98a2b3;
  padding-bottom: 10px;
`;

export const TotalAmountContainer = styled.div`
  font-size: 26px;
  color: #101828;
`;

export const LogoContainer = styled.div`
  width: 55px;
  height: 55px;
  border-radius: 4px;
  background: #f2f4f7;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
`;

export const DetailsDataCardContainer = styled.div`
  width: 94%;
  height: 60px;
  flex-direction: row;
  display: flex;
  margin-bottom: 20px;
  padding: 10px 10px 10px 15px;
  border: 1px solid #e4e7ec;
  border-radius: 5.5px;
  align-items: center;
  background: #fff;
`;

export const DetailsDataCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DetailsDataWrapper = styled.div`
  width: 100%;
  height: 600px;
  padding: 10px 0px;
`;

export const DetailsDataContainer = styled.div`
  width: 100%;
  flex: 1;
  flex-direction: column;
  display: flex;
`;

export const LogoContainer1 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: 1px solid #e4e7ec;
  border-radius: 8px;
  cursor: pointer;
`;

export const LogoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 25px;
  padding-left: 45px;
`;

export const StyledChip = styled.div`
  width: 70px;
  height: 20px;
  border-radius: 12px;
  color: #008243;
  background: var(--success-surface, #ebf5f0);
  font-size: 12px;
  border: 1px solid #b3dac7;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 1px;
`;

export const ChipWrapper = styled.div`
  padding: 0px 0px 11px 20px;
`;

export const DateContainer = styled.div`
  padding: 0px 0px 9px 20px;
  font-size: 12px;
  color: #98a2b3;
`;

export const HeaderTextDiv = styled.div`
  padding: 0px 0px 3px 20px;
  font-size: 20px;
  color: #101828;
  font-weight: 500;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 10px 10px 0px;
`;

export const DetailsDrawerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  height: 100px;
`;

export const DetailsDrawerContainer = styled.div`
  display: flex;
  width: 90%;
  height: 97%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 25px 25px 0px 25px;
  background: #fff;
`;
export const StyledBadge = styled.div`
  width: 90px;
  height: 20px;
  border-radius: 12px;
  color: #008243;
  background: var(--success-surface, #ebf5f0);
  font-size: 12px;
  border: 1px solid #b3dac7;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const StyledBadgeI = styled(Grid)<{ $status?: string }>`
  // width: fit-content;
  height: 30px;
  border-radius: 20px;
  color: ${props => (props.$status === 'PENDING' ? '#D6A614' : '#008243')};
  font-size: 12px;
  border: 1px solid
    ${props => (props.$status === 'PENDING' ? '#F4D269' : '#B3DAC7')};
  display: flex;
  align-items: center;
  // justify-content: center;
  padding: 4px 8px;
  gap: 10px;
  background: ${props => (props.$status === 'PENDING' ? '#FCF4DB' : '#EBF5F0')};
`;

export const DescContainer = styled.div`
  display: flex;
  height: 40px;
  overflow: hidden;
  color: var(--neutral-600, #475467);
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  text-align: left;
  padding-bottom: 10px;
`;

export const TitleContainerNew = styled.div`
  display: flex;
  height: 20px;
  color: var(--neutral-900, #101828);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-bottom: 2px;
  @media (min-width: 900px) {
    font-size: 16px;
  }
`;

export const ImgContainer = styled(Grid)`
  // display: flex;
  // height: 50px;
  //padding-bottom: 15px;
`;

export const CRMCardWrapper = styled(Grid)`
  border: 1px solid #e4e7ec;
  border-radius: 8px;
  padding: 16px 16px 20px 16px;
  margin: 0px 3px 12px 10px;
  // max-width: 300px;
  // width: 230px;
  // height: 165px;
  background: var(--neutral-0, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.12);
`;

export const CRMCardContainer = styled.div`
  align-items: center;
  justify-content: flex-start;
  display: flex;
`;

export const HeaderPartWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const StyledButtonWrapper = styled.div`
  height: 60px;
  background-color: #f8f9fa;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  border-top: 1px solid #e4e7ec;
  width: 102%;
  margin-left: -24px;
  justify-content: space-between;
  box-shadow: 0px 1px 8px 0px rgba(38, 38, 38, 0.13);
`;

export const SFConatctsWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  padding: 0px 10px 12px 10px;
  align-items: center;
  color: #475467;
  font-size: 14px;
  font-weight: 400;
`;

export const SFCardTitle = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  padding: 10px 10px 5px 10px;
  align-items: center;
  font-size: 16px;
  color: #000;
  font-weight: 500;
`;

export const SFDataCardWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const SFDataCardContainer = styled.div`
  width: 95%;
  height: 70px;
  border: 1px solid #e4e7ec;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  padding: 3px 15px 3px 5px;
  align-items: center;
  margin-bottom: 15px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.12);
`;

export const DataCard = styled.div`
  width: 100%;
  height: 150px;
  padding: 15px 0px 22px;
`;

export const SFDataHeader = styled.div`
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
  color: #475467;
`;

export const SFIconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
`;

export const StyledContainer = styled.div`
  width: 55%;
  height: 45px;
  border: 1px solid #e4e7ec;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  padding: 5px 10px 5px 15px;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.12);
`;

export const StyledWrapper = styled.div`
  width: 100%;
  padding: 10px 0px;
  height: 250px;
  display: flex;
  flex-direction: column;
`;

export const CloseIconDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
`;

export const SFHeaderText = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  font-size: 20px;
  font-weight: 500;
  color: #101828;
`;

export const DrawerHeaderDataWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  padding: 10px 0px;
  height: 20px;
`;

export const StyledDrawerWrapper = styled.div`
  display: flex;
  height: 90%;
  width: 100%;
  flex-direction: column;
`;

export const StyledSFDrawerContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
`;

const spinAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left: 4px solid #000000;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: ${spinAnimation} 1s linear infinite;
`;
