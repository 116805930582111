import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import SplashContent from './SplashContent';

import {
  LogoutSplashOverlayContainer,
  LogoutSplashOverlayWrapper,
  LogoutSplashOverlayContent,
  SplashCardContainer,
  Text2,
} from './style';
import { HLLogo } from './utils';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import bgImage from '../../../assets/images/bgImage.svg';

function LogoutSplashOverlay(): React.JSX.Element {
  const logutSplashState: any = localStorage.getItem(
    'logout-splashscreen-state',
  );
  const [enableScreen, showScreen] = useState<any>(logutSplashState);
  const [activeSlide, setActiveSlide] = useState<number>(0);
  const [activeSlide2, setActiveSlide2] = useState<number>(0);
  useEffect(() => {
    setTimeout(() => {
      if (logutSplashState === '1' || logutSplashState === 1) {
        showScreen(true);
      }
    }, 500);
  }, [logutSplashState]);

  const afterSlideChange = (current: number) => {
    setActiveSlide2(current);
    setTimeout(() => {
      console.log('logging out..');
      const keepLoggedInCache = localStorage.getItem('tifin-ai-keepLoggedIn');
      localStorage.clear();
      if (keepLoggedInCache) {
        localStorage.setItem('tifin-ai-keepLoggedIn', keepLoggedInCache);
      }
      window.location.href = '/login';
    }, 3500);
  };

  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    pauseOnHover: false,
    speed: 500,
    autoplaySpeed: 3500,
    nextArrow: <></>,
    prevArrow: <></>,
    beforeChange: (current: number, next: number) => setActiveSlide(next),
    afterChange: (current: number) => afterSlideChange(current),
  };

  console.log(activeSlide, activeSlide2);

  return (
    <React.Fragment>
      {enableScreen && (
        <LogoutSplashOverlayContainer>
          <LogoutSplashOverlayWrapper>
            <img
              alt="Splash screen background image"
              src={bgImage}
              width={'100%'}
              height={'100%'}
              style={{
                display: 'block',
                background:
                  'url(../../../assets/images/bg_image.png),lightgray 50% / cover no-repeat',
                mixBlendMode: 'multiply',
              }}
            />
            <div
              style={{
                position: 'absolute',
                height: '100vh',
                width: '100vw',
                top: 0,
                zIndex: 1,
                background:
                  'linear-gradient(to bottom, rgba(255, 255, 255, 0.50) 0%, rgba(217, 217, 217, 0.00) 100%, rgba(255, 255, 255, 0.00) 100%)',
              }}
            />
          </LogoutSplashOverlayWrapper>
          <LogoutSplashOverlayContent>
            <SplashCardContainer
              container
              className="splash-screen-container"
              justifyContent="center"
              alignItems="center"
            >
              <div style={{ width: '100%', height: '100%' }}>
                <Slider {...settings}>
                  <div style={{ display: 'inline-block' }}>
                    <SplashContent
                      container
                      style={{
                        width: '80% !important',
                        height: '100vh !important',
                        margin: '0 auto !important',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        justifyContent: 'center',
                      }}
                      contentOverideStyle={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        height: 320,
                        justifyContent: 'center',
                      }}
                      last={false}
                      renderDelay={500}
                      section={null}
                      dataList={[
                        {
                          section: (
                            <Text2
                              sx={{ fontSize: '58px', textAlign: 'center' }}
                              isLogout
                              style={{ fontSize: '55px' }}
                            >
                              Thank you for being a part of the{' '}
                              <span style={{ fontSize: '50px' }}>
                                Helix by HL
                              </span>{' '}
                              experience powered by TIFIN
                            </Text2>
                          ),
                          animationName: 'ease-in',
                          animationDuration: '0.5s',
                          renderDelay: 1000,
                        },
                      ]}
                    />
                  </div>
                  <div style={{ display: 'inline-block' }}>
                    {activeSlide === 1 && (
                      <SplashContent
                        container
                        style={{
                          height: '100vh !important',
                          margin: '0 auto !important',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                        contentOverideStyle={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          width: 500,
                          height: 320,
                        }}
                        last={false}
                        renderDelay={500}
                        section={null}
                        dataList={[
                          {
                            section: <HLLogo style={{ margin: '20px 0' }} />,
                            animationName: 'ease-in',
                            animationDuration: '0.5s',
                            renderDelay: 800,
                          },
                        ]}
                      />
                    )}
                  </div>
                </Slider>
              </div>
            </SplashCardContainer>
          </LogoutSplashOverlayContent>
        </LogoutSplashOverlayContainer>
      )}
    </React.Fragment>
  );
}
export default LogoutSplashOverlay;
