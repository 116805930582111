import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions as chatActions } from '@features/Chat/slice';
import { getQueryLoaderState } from '@features/Chat/selector';
import ChatContent from '@components/ChatContent';
import ChatSkeleton from './ChatSkeleton';
import { ChatPageContainer, ChatPageWrapper, ChatBody } from './styles';

type ChatProps = {
  isLoading: boolean;
  chatHistory: any[];
};

function Chat({ isLoading, chatHistory }: ChatProps): React.JSX.Element {
  const dispatch = useDispatch();
  const [portfolioOptions, setPortfolioOptions] = useState<any>([]);
  const [loopCounter, setLoopCounter] = useState(0);
  const isStreaming: boolean = useSelector(getQueryLoaderState);
  const isChatStreaming = localStorage.getItem('streaming') as string;

  const onSelectChatOption = (payload: any, clientPortfolios: any[]) => {
    if (clientPortfolios && clientPortfolios.length > 0) {
      setPortfolioOptions([...clientPortfolios]);
    }

    if (portfolioOptions.length > 0 && clientPortfolios.length === 0) {
      if (loopCounter === portfolioOptions.length - 1) setPortfolioOptions([]);
    }

    if (isChatStreaming === 'true') {
      dispatch(
        chatActions.setSearchQuery({
          chatId: payload.chatId,
          selectedId: payload?.selectedOption,
        }),
      );
    } else {
      dispatch(
        chatActions.searchQueryRequest({
          query: {
            chatId: payload.chatId,
            selectedId: payload?.selectedOption,
          },
        }),
      );
    }
  };

  const toggleFeedback = (props: any) => {
    dispatch(
      chatActions.toggleChatFeedbackModal({
        ...props,
        openModal: true,
      }),
    );
  };

  return (
    <ChatPageContainer container justifyContent="center">
      <ChatPageWrapper item xs={12}>
        <ChatBody>
          {isLoading ? (
            <ChatSkeleton />
          ) : (
            <ChatContent
              sx={{ height: '100%' }}
              isStreaming={isStreaming}
              chatHistory={chatHistory}
              onSelectChatOption={onSelectChatOption}
              portfolioOptions={portfolioOptions}
              setLoopCounter={setLoopCounter}
              toggleFeedback={toggleFeedback}
              loopCounter={loopCounter}
            />
          )}
        </ChatBody>
      </ChatPageWrapper>
    </ChatPageContainer>
  );
}

export default Chat;
