/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { tickerRegex } from '@components/ChatContent/ContentDefault';
import { cardData } from '@pages/IntegrationPage';
import { LayoutGutter, ResponsiveLayoutGutter } from '@styles/variables';
import { ECustodians } from 'app/enums';
import React from 'react';
export const regExpEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
export const regExpNumber = /[^0-9]/g;
export const regExpNumberWithDecimal = /[^0-9.]/g;
export const regExpNumberWithSpace = /[^0-9 ]/g;
export const regExpAlphaNumeric = /[^a-zA-Z0-9 ]/g;
export const regExpAlphabetic = /[^a-zA-Z ]/g;
// this is helper function checkout it's implementation before using
export const regExAcceptUpto2Decimals = /(\.\d{2})\d*/;

export const isEmailValid = (emailAddress: string): boolean => {
  const regexEmail = new RegExp(regExpEmail);
  return regexEmail.test(emailAddress);
};

export const titleCase = (str: string | undefined | null) => {
  if (typeof str !== 'string' || !str) {
    return '';
  }
  return str.replace(/\b\w/g, function (char) {
    return char.toUpperCase();
  });
};

export const checkTableContent = (content: string): boolean =>
  typeof content === 'string' && content?.includes('<table');

export const checkParseTableData = (data: any): boolean =>
  typeof data === 'object' &&
  Array.isArray(data?.headers) &&
  Array.isArray(data?.body);

export const isReactComponent = (component: any): boolean => {
  return (
    typeof component === 'function' &&
    component.prototype instanceof React.Component
  );
};

export const isComponent = (component: any): boolean => {
  return (
    typeof component === 'object' &&
    Object.prototype.hasOwnProperty.call(component, '$$typeof') &&
    Object.prototype.hasOwnProperty.call(component, 'type')
  );
};

export const hasHTMLTags = (inputString: string): boolean => {
  const regex = /<\/?[\w\s="'.,;#-/?]+>/;
  return regex.test(inputString);
};

export const parseData = (chat: any, debugObj: any | null = null): any => {
  /*
    NOTE: Please add missing data types details.
    Expected chat data types:
      - string/text -> "hi"
      - enhancer suggestions type -> [{query_var, suggestions, target_card}]
      - enhancer queue -> { type: "", formatted_data: [{ type: 'text', content: 'hi', type: 'enhancement_data', content: [{query_var, suggestions, target_card}] }] }
      - table -> [{ type: 'table', content: [{ {id: 234001, firstname: 'Rob', lastname: 'Leonard' ...}] }]
      - collections -> ['hi', { header: [], body: [] }, 'hello...', { header: [], body: [] }]
      - portfolio_card -> [{ name: 'CIO Portfolios - Tax Smart', assestAllocation: [...], holdings: [...], returns: [], ...}]
      - chart -> [{ type: 'line_chart', data: [] }, { type: 'bar_chart', data: [] }, { type: 'pie_chart', data: [] }, ...]
 */
  const _chat: any = {};
  if (
    chat &&
    chat?.type === 'enhancement_queue' &&
    chat?.data &&
    Array.isArray(chat?.data)
  ) {
    _chat.type = 'enhancement_queue';
    _chat.data = chat.data.map((k: any) => {
      if (k?.type === 'text' && hasHTMLTags(k.content)) {
        k.type = 'table';
        k.content = parseHtmlTableData(k.content);
      }
      return k;
    });
  } else if (chat?.type === 'enhancement' && Array.isArray(chat.data)) {
    _chat.type = 'enhancement';
    _chat.data = chat?.data;
  } else if (chat?.type === 'finsera') {
    _chat.type = 'finsera';
    _chat.data = chat?.data;
  } else if (typeof chat === 'string') {
    if (hasHTMLTags(chat)) {
      if (parser(chat)?.length > 0) {
        _chat.type = 'collections';
        _chat.data = parser(chat)?.map((k: any) => {
          if (checkTableContent(k?.content)) {
            return parseHtmlTableData(k?.content);
          }
          return k?.content;
        });
      } else {
        _chat.type = 'table';
        _chat.data = parseHtmlTableData(chat);
      }
    } else {
      _chat.type = 'text';
      _chat.data = chat;
    }
  } else if (chat?.type === 'sql' && Array.isArray(chat.data)) {
    // console.log('sql -> ', chat.data);
    _chat.type = 'table';
    _chat.data = chat.data;
  } else if (chat?.type === 'options') {
    _chat.data = [...chat.data];
  } else if (chat?.type === 'finsera_create_stress_options') {
    _chat.type = 'enhancement';
    _chat.data = chat?.data;
  } else if (chat?.type === 'portfolio_card') {
    _chat.data = chat?.data;
  } else if (chat?.type === 'smalltalk') {
    _chat.data = chat?.data;
  } else if (chat?.type === 'error') {
    _chat.data = chat?.data;
  } else if (chat?.type === 'plot') {
    _chat.data = chat?.data;
    _chat.debugObj = chat?.debugObj;
  } else {
    // console.log('what is this ? -> ', { chat });
    _chat.type = 'text';
    _chat.data = chat?.data;
  }

  // Temporary solution until we get formatted data from BE:
  _chat.debugObj = chat?.debug_obj?.data_to_visualize ?? null;
  if (!_chat.debugObj) _chat.debugObj = debugObj;

  // if (chat?.suggestions?.queries) {
  //   _chat.data.push({
  //     content: { queries: chat?.suggestions?.queries },
  //     type: 'suggestions',
  //   });
  // }
  return _chat;
};

export function parseHtmlTableData(htmlString: string): any {
  // Create a new DOMParser
  const parser = new DOMParser();
  // Parse the HTML string into a DOM document
  const doc = parser.parseFromString(htmlString, 'text/html');
  // Find the table element in the DOM
  const table = doc.querySelector('table');

  if (!table) {
    return null;
  }

  // Get the headers from the table
  const headers: any = Array.from(table.querySelectorAll('th')).map(th =>
    th.textContent?.trim(),
  );
  // Get all the rows in the table
  const rows: any = Array.from(table.querySelectorAll('tr')).slice(1); // Skip the first row (header row)
  // Parse the data from the rows
  const data: any = rows.map((row: any) => {
    const cells = Array.from(row.querySelectorAll('td')).map((td: any) =>
      td?.textContent?.trim(),
    );
    const rowData: Record<string, any> = {};
    headers.forEach((header: any, index: any) => {
      rowData[header] = cells[index];
    });
    return rowData;
  });
  return {
    headers,
    body: data,
  };
}

interface ParsedItem {
  type: 'text' | 'html';
  content: string | JSX.Element;
}

export function parser(htmlString: string): any[] {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  const parsedItems: ParsedItem[] = [];
  let currentText = '';
  let currentHTML = '';

  const lineBreakTags = new Set(['BR', 'P']); // Add tags that indicate a new line

  const handleNode = (node: Node) => {
    if (node.nodeType === Node.TEXT_NODE) {
      currentText += node.textContent;
    } else if (node.nodeType === Node.ELEMENT_NODE) {
      const tag = node.nodeName;
      if (lineBreakTags.has(tag)) {
        // If the tag indicates a line break, push the accumulated text and reset it
        if (currentText.trim() !== '') {
          parsedItems.push({ type: 'text', content: currentText });
          currentText = '';
        }
      } else if (tag === 'B' || tag === 'STRONG') {
        // Handle <b> or <strong> tags as text
        const content = node.textContent?.trim();
        if (content) {
          currentText += content;
        }
      } else if (tag === 'TABLE') {
        // Handle tables as HTML and push individually
        const content = new XMLSerializer().serializeToString(node);
        if (currentHTML.trim() !== '') {
          parsedItems.push({ type: 'html', content: currentHTML });
          currentHTML = '';
        }
        parsedItems.push({ type: 'html', content });
      }
    }
  };

  doc.body.childNodes.forEach(handleNode);

  // Push any remaining accumulated text or HTML
  if (currentText.trim() !== '') {
    parsedItems.push({ type: 'text', content: currentText });
  }
  if (currentHTML.trim() !== '') {
    parsedItems.push({ type: 'html', content: currentHTML });
  }

  return parsedItems;
}

/**
 *
 * @param data markdown string
 * @returns returns clean markdown without prefixes and replace item based on identifiers to display diff
 */
export const removeInvalidMarkdown = (data: string) => {
  const tickerIdentifier = /\[\[(.*?)\]\]/g;
  // /```markdown\s*([\s\S]*?)```/g -> to check if start/end exists
  const markdownIdentifier = /```markdown\s*([\s\S]*?)(?:```|$)/g;

  // Use a regex to remove ```markdown at the start and ``` at the end
  let cleanedData = data?.replace(markdownIdentifier, '$1');
  // .replace(/```markdown\s*([\s\S]*?)```/g, "$1");
  // console.log('🚀 ~ removeInvalidMarkdown ~ cleanedData:', cleanedData);

  // Replace the text within parentheses with a anchor tag for further replacing it with ticker
  cleanedData = cleanedData?.replace(tickerIdentifier, (_, text) => {
    const tickers = text?.match(tickerRegex);

    if (tickers?.length && tickers?.[0]?.length > 2) {
      const ticker = tickers?.[0];
      return `<span data-ticker="${ticker}">${ticker}</span>`;
    }
    return `(${text})`;
  });

  return cleanedData;
};

/**
 *
 * @param text string received from streaming api
 * @returns returns clean markdown text
 */
export const removeMarkdownTags = (text: string) => {
  // Remove '||markdown' from the start of the text
  text = text.replace(/^(\|\|startText)/, '');

  // Remove '||endMarkdown \n' from the end of the text
  text = text.replace(/(\|\|endText \n)$/, '');

  return text;
};

// FIX: need to updated the data
export const extractText = (
  input: string,
): { startTextCount: number; match: string } => {
  // Count the occurrences of ||startText
  const startTextCount = (input.match(/\|\|startText/g) || []).length;

  // Define regex to match ||startText to ||endText or end of string
  const regex = /\|\|startText([\s\S]*?)(?=\|\|endText|$)/g;

  let match;
  let lastMatch = '';

  // Find all matches and take the last one
  while ((match = regex.exec(input)) !== null) {
    lastMatch = match[1];
  }
  // console.log('🚀 ~ extractText ~ lastMatch:', lastMatch);
  // console.log("🚀 ~ startTextCount:", startTextCount);

  // Return the captured group if match is found, otherwise return an empty string
  return {
    match: lastMatch.trim(),
    startTextCount,
  };
};

export const removeTickerSpans = (input: string): string => {
  // Regular expression to match <span data-ticker="text">text</span> and capture the inner text
  const regex = /<span[^>]*data-ticker[^>]*>(.*?)<\/span>/gi;

  // Replace the matches with the captured inner text
  return input.replace(regex, (_, innerText) => innerText);
};

export const generateRandomId = () => {
  // Generate a random 24-character hexadecimal string
  return Array.from({ length: 24 }, () =>
    Math.floor(Math.random() * 16).toString(16),
  ).join('');
};

/**
 * @param isDesktop boolean to check if response layout gutter style needs to be rendered
 * @returns layout gutter object based on boolean passed
 */
export const isDesktopHandler = (isDesktop?: boolean): any => {
  return isDesktop ? LayoutGutter : ResponsiveLayoutGutter;
};

/**
 * @param query href from anchor
 * @returns id of whichever form
 */
export const extractClientIdFromQuery = (query: string): string | null => {
  if (query) {
    const regex = /content=([^\s&]*)/;
    const match = query.match(regex);
    return match ? match[1] : null;
  }
  return null;
};

/**
 *
 * @param row -> table row
 * @param header -> list of header
 * @returns list of integration full names
 */
export const getIntegrationFullName = (
  row: any,
  header: any,
): string | undefined => {
  const { value } = header;
  const items = row[value];

  if (!Array.isArray(items)) return '--';

  return items
    .map(item =>
      item === ECustodians.Orion ||
      item === ECustodians.FidelityInstitutionalBrokerage ||
      item === ECustodians.FidelityInvestmentsTrust
        ? cardData[item].name
        : item,
    )
    .join(', ');
};
