import { Grid, styled } from '@mui/material';

export const ParentContainer = styled(Grid)`
  width: 100%;
  /* height: fit-content; */
  /* height: calc(100vh - 100px); */
  justify-content: flex-start;
  overflow: auto;
`;

export const CardMainContainer = styled(Grid)`
  overflow-y: auto;
  max-height: calc(100vh - 100px);
  justify-content: flex-start;
  scrollbar-width: none; // this part hides the scrollbar for Firefox browser
  -ms-overflow-style: none; // this for  IE/Edge

  // this for hiding scrollbar on WebKit-based browsers */
  &::-webkit-scrollbar {
    display: none;
  }

  .delete-wrapper {
    border-radius: 20px;
    border: 1px solid #efefef;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.05);
    width: 36px;
    height: 36px;
    padding: 0px 16px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    flex-shrink: 0;
    cursor: pointer;
  }

  .status {
    text-transform: capitalize;
  }
`;

export const CardWrapper = styled(Grid)`
  border-radius: 8px;
  border: 1px solid var(--Silver-200, #e3e3e3);
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.12);
  padding: 24px;

  .title {
    color: #000;
    font-size: 20px;
    font-weight: 500;
  }
  .desc {
    color: var(--Neutral-500, #667085);
    font-size: 14px;
    font-weight: 400;
  }

  .edit-wrapper {
    padding: 8px 16px;
    border-radius: 20px;
    border: 1px solid #efefef;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.05);
    cursor: pointer;
  }

  .delete-wrapper {
    border-radius: 50%;
    border: 1px solid #efefef;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.05);
    padding: 10px;
    cursor: pointer;
  }

  .button {
    border-radius: 20px;
    padding: 0 12px;
    /* border: 1px solid #fff; */
    /* padding: 8px; */
    background: linear-gradient(180deg, #222124 23.75%, #323232 120%);
  }

  .icon {
    margin-top: 5px;
  }

  .questions-title {
    color: #000;
    font-size: 14px;
    font-weight: 500;
  }
  .question {
    color: var(--Neutral-900, #101828);
    font-size: 12px;
    font-weight: 500;
  }
  .question-wrapper {
    border-radius: 4px;
    background: var(--Neutral-100, #f2f4f7);
    padding: 8px;
  }
`;

export const EditWrapper = styled(Grid)`
  background: #fff;
  .label {
    color: var(--Neutral-900, #101828);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .title {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .button {
    width: 100%;
    border-radius: 20px;
  }

  .text-area {
    width: 100%;
    border-radius: 4px;
    border: 1px solid var(--Neutral-200, #e4e7ec);
    background: var(--Neutral-0, #fff);
    color: var(--Neutral-500, #667085);
    font-size: 14px;
    font-weight: 400;

    /* Input Shadow */
    box-shadow: 1px 2px 2px 0px rgba(16, 24, 40, 0.12) inset;
  }

  .custom-input {
    border-radius: 4px;
    border: 1px solid var(--Neutral-200, #e4e7ec);
    background: var(--Neutral-0, #fff);
    padding: 10px;

    /* Input Shadow */
    box-shadow: 1px 2px 2px 0px rgba(16, 24, 40, 0.12) inset;
  }

  .icon {
    cursor: pointer;
  }
`;
