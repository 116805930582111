import React from 'react';
import {
  LegendItemsContainer,
  LegendItemsWrapper,
  LegendTypography,
  MainWrapper,
} from './styles';

interface CustomLegendItem {
  color: string;
  value: string;
}

interface CustomLegendProps {
  customLegendItems: CustomLegendItem[];
}

const CustomLegend: React.FC<CustomLegendProps> = ({ customLegendItems }) => {
  return (
    <MainWrapper>
      {customLegendItems.map((item, index) => (
        <LegendItemsContainer key={index}>
          <LegendItemsWrapper color={item.color} />
          <LegendTypography variant="body2">
            {item.value.replace(/_/g, ' ')}
          </LegendTypography>
        </LegendItemsContainer>
      ))}
    </MainWrapper>
  );
};

export default CustomLegend;
