import React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

type CustomTooltipProps = {
  children: JSX.Element;
  title: string | JSX.Element;
  open?: boolean;
  placement?:
    | 'bottom'
    | 'left'
    | 'right'
    | 'top'
    | 'bottom-end'
    | 'bottom-start'
    | 'left-end'
    | 'left-start'
    | 'right-end'
    | 'right-start'
    | 'top-end'
    | 'top-start';
};

const CustomTooltipWrapper = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.primary['black'],
    color: theme.palette.primary['whiteText'],
    padding: '10px 20px',
    fontSize: theme.typography.pxToRem(12),
    borderRadius: '0.5rem',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.primary['black'],
    '&:before': {
      border: `1px solid ${theme.palette.primary['black']}`,
    },
  },
  [`& .${tooltipClasses.tooltipPlacementBottom} .${tooltipClasses.arrow}`]: {
    marginTop: '-0.6em',
  },
  [`& .${tooltipClasses.tooltipPlacementRight} .${tooltipClasses.arrow}`]: {
    marginTop: '-0.1em',
    marginRight: '-0.4em',
  },
}));

function CustomTooltip({
  children,
  title,
  open = undefined,
  placement = 'bottom',
}: CustomTooltipProps): React.JSX.Element {
  return (
    <CustomTooltipWrapper open={open} title={title} placement={placement} arrow>
      {children}
    </CustomTooltipWrapper>
  );
}

export default CustomTooltip;
