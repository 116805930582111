import React from 'react';
import SuggestionCard from '../SuggestionCard';
import {
  SuggestionCardWidth,
  SuggestionCardHeight,
} from '../SuggestionCard/styles';
import { HoldingListSliderWrapper } from './styles';

export type SliderSettingsProps = {
  dots?: boolean;
  speed?: number;
  className?: string;
  infinite?: boolean;
  variableWidth?: boolean;
  slidesToShow?: number;
  slidesToScroll: number;
  adaptiveHeight?: boolean;
  arrows?: boolean;
  responsive?: any;
};

export type HoldingListSliderProps = {
  ref?: any;
  slideToShow?: number;
  targetCardChartData?: any;
  holdingIndex: string | number;
  suggestions: Array<any>;
  selectedTicker?: string;
  checkBoxDisable?: boolean;
  onSelect: ((event: any, param: any) => void) | undefined;
  sliderSettings?: SliderSettingsProps;
};

function HoldingListSlider({
  slideToShow = 3,
  suggestions,
  holdingIndex,
  selectedTicker = '',
  targetCardChartData,
  sliderSettings = {
    className: 'slider variable-width',
    dots: true,
    infinite: false,
    variableWidth: true,
    slidesToShow: slideToShow,
    slidesToScroll: 1,
    adaptiveHeight: false,
    arrows: false,
  },
  checkBoxDisable = false,
  onSelect,
}: HoldingListSliderProps): React.JSX.Element {
  return (
    <HoldingListSliderWrapper {...sliderSettings}>
      {suggestions &&
        suggestions.length > 0 &&
        suggestions.map((suggestionItem: any, suggestionIndex: number) => {
          return (
            <div
              key={`holdingItem-${holdingIndex}-${suggestionIndex}`}
              style={{
                width: SuggestionCardWidth,
                height: SuggestionCardHeight,
                marginRight: '10px',
              }}
            >
              <SuggestionCard
                checkBoxDisable={checkBoxDisable}
                key={`holdingItem-${holdingIndex}-${suggestionIndex}`}
                targetCardChartData={targetCardChartData}
                cardinfo={suggestionItem?.cardinfo}
                chart={suggestionItem?.chart}
                selectedTicker={selectedTicker}
                onSelect={onSelect}
              />
            </div>
          );
        })}
    </HoldingListSliderWrapper>
  );
}

export default HoldingListSlider;
