import React from 'react';
import { Grid, Typography, useMediaQuery } from '@mui/material';
import {
  CustomButton,
  DocumentContainer,
  TextWrapper,
} from '@pages/Profile/styles';
import documentImg from '@images/document.svg';
import CustomTooltip from '@components/Tooltip';
import theme from '@styles/theme';
import download from '@images/Download.svg';
import { useMutation } from 'react-query';
import { downloadPrivateDocuments } from '@pages/Profile/mutation';
import { downloadBlobDoc } from '@common/downloadBlobData';

interface IRAFilesProps {
  link: string;
  pdfName: string;
}

const RiskAssessmentFiles: React.FC<IRAFilesProps> = ({
  link: fileName,
  pdfName,
}) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { mutate: downloadPrivateMutate } = useMutation(
    downloadPrivateDocuments,
  );

  return (
    <DocumentContainer
      item
      container
      alignItems={'center'}
      p={!isMobile ? 2 : 1}
      xs={12}
      md={8}
      columnGap={!isMobile ? 1 : 0}
      flexWrap={'nowrap'}
    >
      {!isMobile && (
        <Grid item>
          <img src={documentImg} alt="Document Image" />
        </Grid>
      )}
      <Grid item>
        {isMobile ? (
          <CustomTooltip title={pdfName}>
            <TextWrapper>{pdfName}</TextWrapper>
          </CustomTooltip>
        ) : (
          <Typography variant="subtitle2">{pdfName}</Typography>
        )}
      </Grid>
      <Grid item ml={'auto'} justifyContent={isMobile ? 'center' : 'flex-end'}>
        <CustomButton
          color="primary"
          sx={{ padding: !isMobile ? '8px 16px' : '8px 5px' }}
          onClick={() =>
            downloadPrivateMutate(fileName, {
              onSuccess: data => {
                downloadBlobDoc(data?.data, fileName);
              },
              onError: () => {
                console.log('Error downloading file');
              },
            })
          }
        >
          <img
            src={download}
            alt="Download image"
            style={{ marginRight: !isMobile ? '5px' : '' }}
          />
          {!isMobile && <span>Download</span>}
        </CustomButton>
      </Grid>
    </DocumentContainer>
  );
};

export default RiskAssessmentFiles;
