/**
 * Checks if a value is empty. The value is considered empty if:
 * - It is `null` or `undefined`
 * - It is an empty array or string (`""`)
 * - It is an empty object (`{}`)
 * - It is an empty Map or Set
 * - It is a falsy primitive (`false`, `0`, `NaN`, `""`)
 *
 * Non-empty values include:
 * - Non-empty arrays, objects, sets, or maps
 * - Numbers, booleans, functions, dates, and other valid primitives
 *
 * @param value - The value to check
 * @returns `true` if the value is empty, `false` otherwise
 */
export function isEmpty(value: unknown): boolean {
  // Check for null or undefined
  if (value == null) return true;

  // Check for an empty string, array, or TypedArray
  if (
    typeof value === 'string' ||
    Array.isArray(value) ||
    ArrayBuffer.isView(value)
  ) {
    if (typeof value === 'string' || Array.isArray(value)) {
      return value.length === 0;
    }
    return false;
  }

  // Check for an empty object, Map, or Set
  if (typeof value === 'object') {
    if (value instanceof Set || value instanceof Map) {
      return value.size === 0;
    }
    return Object.keys(value).length === 0 && value.constructor === Object;
  }

  // Check for falsy values (false, 0, NaN, '')
  if (typeof value === 'number') return isNaN(value);
  if (
    typeof value === 'boolean' ||
    typeof value === 'function' ||
    value instanceof Date
  ) {
    return false; // These types are not considered empty
  }

  return false;
}
