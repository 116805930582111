import React from 'react';
import { Fade } from '@mui/material';
import { ContentWrapper } from '../styles';
import { ComponentSlides } from './styles';

interface ComponentSliderProps {
  components: Array<any>;
  index: number;
  margin: number;
}
const ComponentSlider = ({
  components,
  index,
  margin,
}: ComponentSliderProps): React.JSX.Element => {
  return (
    <ContentWrapper $margin={margin}>
      {components.map((currentComponent, currentIndex) => {
        return currentIndex === index ? (
          <Fade key={currentIndex} timeout={1500} in={currentIndex === index}>
            <ComponentSlides>{currentComponent.component}</ComponentSlides>
          </Fade>
        ) : null;
      })}
    </ContentWrapper>
  );
};
export default ComponentSlider;
