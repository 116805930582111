import { ReactComponent as UploadIcon } from '@images/uploadIcon.svg';
import { Typography } from '@mui/material';
import { settings } from 'config';
import React, { useEffect, useState } from 'react';
import { DropzoneArea } from 'react-mui-dropzone';
import { Container, FileDropContainer, StyledBox } from './styles';

const getFileType = (file: File): string => {
  if (file) {
    const fileNameParts = file.name.split('.');
    return fileNameParts[fileNameParts.length - 1].toLowerCase();
  }
  return '';
};

const CustomUploadIcon = ({
  upload,
  fileSizeExceeded,
}: {
  upload: string[];
  fileSizeExceeded: boolean;
}) => {
  console.log('upload type:', upload);
  return (
    <Container>
      <UploadIcon />
      <Typography sx={{ paddingTop: '10px' }}>
        Drag & Drop or Choose File to Upload
      </Typography>

      <StyledBox>
        <Typography sx={{ color: '#627288' }}>
          PDF files upto {settings.app.maxFileSize}MB are supported supported
        </Typography>
        {fileSizeExceeded && (
          <Typography color="error">
            File size exceeds {settings.app.maxFileSize} MB
          </Typography>
        )}
      </StyledBox>
    </Container>
  );
};
interface FileDraggerProps {
  setPdfFile: React.Dispatch<React.SetStateAction<File[]>>;
  upload: string[];
  uploadedFiles: File[];
}
const FileDragger: React.FC<FileDraggerProps> = ({
  setPdfFile,
  upload,
  uploadedFiles,
}) => {
  const [, setSelectedFiles] = useState<File[]>([]);
  const [dropzoneKey, setDropzoneKey] = useState(0);
  const [fileSizeExceeded, setFileSizeExceeded] = useState(false);

  useEffect(() => {
    if (Array.isArray(uploadedFiles) && uploadedFiles.length > 0) {
      setSelectedFiles(uploadedFiles);
    } else {
      setDropzoneKey(dropzoneKey + 1);
    }
  }, [uploadedFiles]);

  const handleFileChange = (uploadedFiles: File[]) => {
    console.log('files--->', uploadedFiles);
    if (uploadedFiles.length) {
      const uploadedFile = uploadedFiles[0];
      const fileType = getFileType(uploadedFiles[0]);
      if (upload.includes(fileType)) {
        setPdfFile([uploadedFile]);
        setFileSizeExceeded(false);
      }
    }
  };

  const handleDropRejected = (rejectedFiles: File[]) => {
    setFileSizeExceeded(true);
    console.log('Rejected files:', rejectedFiles);
  };

  const handleDelete = (file: File) => {
    setPdfFile(
      (prevFiles: File[]) => prevFiles.filter(f => f !== file) as File[],
    );
  };

  const maxFileSize = settings.app.maxFileSize * 1024 * 1024;

  const acceptedFiles = upload.map(fileType => `.${fileType}`);

  return (
    <FileDropContainer sx={{ width: '100%' }}>
      <DropzoneArea
        acceptedFiles={acceptedFiles}
        classes={{
          root: 'custom-dropzone',
        }}
        showPreviewsInDropzone={false}
        useChipsForPreview
        showFileNames={true}
        filesLimit={1}
        maxFileSize={maxFileSize}
        onChange={handleFileChange}
        onDelete={handleDelete}
        onDropRejected={handleDropRejected}
        Icon={() => (
          <CustomUploadIcon
            upload={upload}
            fileSizeExceeded={fileSizeExceeded}
          />
        )}
        dropzoneText=""
        showAlerts={false}
        showPreviews={true}
        previewText="File Name :"
        key={dropzoneKey}
      />
    </FileDropContainer>
  );
};

export default React.memo(FileDragger);
