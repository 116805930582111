import React from 'react';
import { useDispatch } from 'react-redux';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { OnHighlightKeyword, searchPlugin } from '@react-pdf-viewer/search';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { Highlight } from '../../pages/EnterpriseDashboardV2/EnterpriseCard';
import { handleError } from '@common/error';
import { useRESTQuery } from '@hooks/useRESTQuery';
import Loader from '@components/Loader';

interface PdfViewerProps {
  fileUrl: string;
  source: Highlight[];
  disableHighLight?: boolean;
}

function PdfViewer({
  fileUrl,
  source,
  disableHighLight = false,
}: PdfViewerProps): React.JSX.Element {
  const dispatch = useDispatch();

  const { data, isLoading } = useRESTQuery(
    ['download-data', fileUrl],
    {
      endpoint: `viewPoints/download?url=${fileUrl}`,
      method: 'GET',
      headers: {
        'X-Tifin-Ai-Auth': localStorage.getItem('x-tifin-ai-token'),
      },
    },
    {
      enabled: !!fileUrl,
      select: res => res.data,
      onError: (error: any) => {
        handleError(dispatch, error);
      },
    },
  );

  const chunks: string[] = source.flatMap((item: Highlight) => {
    const words = item.highlighted.split(/\s+/);
    const chunkArr: string[] = [];
    for (let i = 0; i < words.length; i += 3) {
      chunkArr.push(words.slice(i, i + 3).join(' '));
    }
    return chunkArr;
  });

  const searchPluginInstance = searchPlugin({
    keyword: chunks,
    onHighlightKeyword: (props: OnHighlightKeyword) => {
      // eslint-disable-next-line react/prop-types
      props.highlightEle.style.backgroundColor = disableHighLight
        ? 'transparent'
        : 'rgba(255, 255, 0, 0.4)';
    },
  });

  return (
    <>
      {isLoading ? (
        <Loader text="Getting everything ready with your document…" />
      ) : (
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.js">
          <div
            style={{
              height: '90vh',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          >
            <Viewer
              fileUrl={`data:application/pdf;base64,${data}`}
              initialPage={source.length > 0 ? source[0]?.page_number - 1 : 1}
              plugins={[searchPluginInstance]}
              defaultScale={1.5}
              renderLoader={() => (
                <Loader text="Getting everything ready with your document…" />
              )}
            />
          </div>
        </Worker>
      )}
    </>
  );
}

export default PdfViewer;
