import React, { useEffect, useState } from 'react';
import { actions as globalActions } from 'app/features/Global/slice';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import { Divider, Grid, MenuItem, Tooltip } from '@mui/material';
import edit from '@images/edit.svg';
import AddPortfolioModal from './AddPortfolioModal';
import { useNavigate } from 'react-router-dom';
import { price } from '@common/number';
import { LoaderContainer } from '@pages/Signup/styles';
import { Spinner } from '@pages/IntegrationPage/styles';
import { ITickerWeights, PortfolioData } from './types';
import { useDispatch } from 'react-redux';
import { UpdatePortfolioForClient } from './mutation';
import { useMutation, useQueryClient } from 'react-query';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { actions } from '@features/Chat/slice';
import { handleError } from '@common/error';
import downArrow from '@images/Down Arrow.svg';
import upArrow from '@images/Up Arrow.svg';
import MemoWarningRed from '@icons/WarningRed';
import MemoAnimator from '@components/UIComponents/MemoAnimator';
import {
  FooterButtonWrapper,
  StyledApplyButton,
  StyledButton,
} from '@pages/CIOViewpoints/styles';
import {
  ArrowImg,
  ClientsDrawerFooterButtonContainer,
  ComparisonContainerHeader,
  ConstraintsHeaderWrapper,
  ConstraintsWrapper,
  CurrentPortfolioDataType,
  CurrentPortfolioDataValue,
  // CurrentPortfolioWrapper,
  CustomizedSelect,
  DataDiv,
  DataDivWrapper,
  DescDiv,
  DrawerDataContainer,
  ExpectedFundDataContainer,
  ExpectedFundDataWrapper,
  FundDataNumber,
  FundDataText,
  GraphContainer,
  HoldingBody,
  HoldingHeaderContainer,
  HoldingText,
  IconDiv,
  InvestmentHeader,
  InvestmentSpan,
  MappedDataWrapper,
  PortfolioDataContainer,
  PortfolioDiffTag,
  ScrollContainer,
  StyledInfoBox,
  StyledNewSpan,
  SubTextWrapper,
  TextDiv,
} from './styles';
import SimpleLineGraph from '@components/UIComponents/SimpleLineGraph';
import CustomLegend from '@components/UIComponents/SimpleLineGraph/CustomLegend';
import { EColorMapping } from 'app/enums';
import AssetAllocation from './AssetAllocation';
import { validArray } from '@common/validArray';

interface CurrentPortfolioProps {
  fetchClientDetails: any;
  data?: any;
  // isLoading?: boolean;
  clientMarketValue?: number;
  // portfolioData: any;
  clientViewpointDetails: any;
  onClose: () => void;
  clientEmail?: string;
  clientName?: string;
  setStepper: React.Dispatch<
    React.SetStateAction<'CLIENT_DRAWER_PAGE' | 'PORTFOLIO_DETAILS_PAGE'>
  >;
  setSelectedTicker: any;
  viewpoints: any;
  portfolioDiff: number;
  portfoliosData: any[];
  clientRiskScore: number;
}

function CurrentPortfolio({
  fetchClientDetails,
  data,
  clientMarketValue,
  clientViewpointDetails,
  clientName,
  setStepper,
  setSelectedTicker,
  viewpoints,
  portfolioDiff: clientPortfolioDiff,
  portfoliosData,
  clientRiskScore,
}: CurrentPortfolioProps): React.JSX.Element {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isChatStreaming = localStorage.getItem('streaming') as string;
  const { mutate: portfolioMutate, isLoading } = useMutation(
    UpdatePortfolioForClient,
  );
  const queryClient = useQueryClient();
  const [selectedPortfolio, setSelectedPortfolio] = useState<number>(0);
  const [portfolioData, setPortfolioData] = useState<any>({});
  const [underlinedTickers, setUnderlinedTickers] = useState<string[]>([]);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [isAddPortfolioModalOpen, setAddPortfolioModalOpen] = useState(false);
  const [refreshPortfolioData, setRefreshPortfolioData] =
    useState<boolean>(false);
  const [sortedPortfolios, setSortedPortfolios] = useState(() => {
    return Array.isArray(portfoliosData) && portfoliosData.length > 0
      ? [...portfoliosData].sort((a, b) => {
          if (a.id === 'aggregated') return -1;
          if (b.id === 'aggregated') return 1;
          return 0;
        })
      : [];
  });
  useEffect(() => {
    const index = sortedPortfolios?.findIndex(item => item.id === 'aggregated');
    const initialSelected = index === -1 ? 0 : index;
    setSelectedPortfolio(initialSelected);
    setPortfolioData(sortedPortfolios[initialSelected]);
  }, [sortedPortfolios]);

  useEffect(() => {
    setSortedPortfolios(
      validArray(portfoliosData)
        ? [...portfoliosData].sort((a, b) => {
            if (a.id === 'aggregated') return -1;
            if (b.id === 'aggregated') return 1;
            return 0;
          })
        : [],
    );
  }, [portfoliosData]);

  const handlePortfolioChange = event => {
    const selectedIndex = event.target.value as number;
    setSelectedPortfolio(selectedIndex);
    setPortfolioData(sortedPortfolios[selectedIndex]);
  };

  const handleEditPortfolioClick = () => {
    setAddPortfolioModalOpen(true);
  };

  if (isLoading || portfolioData?.ClientPortfolios?.length === 0) {
    return (
      <LoaderContainer>
        <Spinner />
      </LoaderContainer>
    );
  }
  const handleSubmit = (data: {
    portfolioData: PortfolioData;
    tickerWeights: ITickerWeights[];
  }) => {
    const payload = {
      id: portfolioData.id,
      clientId: portfolioData.clientId,
      accountNumber: data.portfolioData.accNumber || ' ',
      name: data.portfolioData.accName || ' ',
      // investmentObjective: data.portfolioData.investmentObjectives || ' ',
      // riskProfile: data.portfolioData.selectedRiskProfile || ' ',
      holdings: data.tickerWeights,
      // portfolioConstraints: data.portfolioData.constraints || ' ',
      advisorId: localStorage.getItem('tifin-ai-advisorId'),
    };
    setIsDataLoading(true);
    portfolioMutate(payload, {
      onSuccess: (res: any) => {
        if (res) {
          dispatch(
            globalActions.displayToast({
              duration: 3000,
              toastType: 'success',
              toastMessage: 'Portfolio added succesfully.',
            }),
          );
          setIsDataLoading(false);
          queryClient.invalidateQueries('get-clients');
          setRefreshPortfolioData(true);
          setAddPortfolioModalOpen(false);
          fetchClientDetails();
        }
      },
      onError: (error: any) => {
        setIsDataLoading(false);
        handleError(dispatch, error);
      },
    });
  };
  const getClientPortfolioDetails = () => {
    const portfolio = sortedPortfolios[selectedPortfolio] || {};
    const clientPortfolioHoldingsData = portfolio.ClientPortfolioHoldings || [];
    const investmentObjective = portfolio.investmentObjective || '';
    const portfolioConstraints = portfolio.portfolioConstraints || '';
    const portfolioExpRisk = portfolio?.expRisk || '';
    const portfolioExpReturns = portfolio?.expReturns || '';
    const portfolioExpenseRatio = portfolio?.fees || '';

    return {
      portfolioName: portfolio.name,
      portfolioId: portfolio.id,
      clientMarketValue: data?.marketValue || 0,
      portfolioMarketValue: portfolio.marketValue || 0,
      portfolioDiff: portfolio?.portfolioDiff || 0,
      clientEmail: data?.email,
      clientName: `${data?.firstName} ${data?.lastName}`,
      portfolioRiskScore: portfolio.riskScore,
      portfolioType: portfolio.taxable,
      clientPortfolioHoldingsData,
      investmentObjective,
      portfolioConstraints,
      portfolio,
      portfolioExpRisk,
      portfolioExpReturns,
      portfolioExpenseRatio,
    };
  };
  const {
    portfolio,
    portfolioId,
    portfolioMarketValue,
    portfolioDiff,
    portfolioRiskScore,
    portfolioType,
    clientPortfolioHoldingsData,
    investmentObjective,
    portfolioConstraints,
    portfolioExpRisk,
    portfolioExpReturns,
    portfolioExpenseRatio,
  } = getClientPortfolioDetails();

  const getPortfolioDiff = () => {
    if (portfolioId === 'aggregated') {
      return clientPortfolioDiff;
    } else return portfolioDiff;
  };

  const formatValue = (value: any | null | undefined) => {
    if (value !== null && value !== undefined) {
      return `${(value * 100).toFixed(2)}%`;
    } else {
      return 'N/A';
    }
  };

  // Collecting all unique keys (excluding 'date') from objects in formattedData
  const allKeys =
    portfolio?.historicalReturns?.reduce((keys, obj) => {
      Object.keys(obj).forEach((key: string) => {
        if (key !== 'date') {
          keys.add(key);
        }
      });
      return keys;
    }, new Set<string>()) || new Set<string>();

  // Prepares data for rendering lines in the graph, assigning colors and names based on EColorMapping
  const lineData = [...allKeys].map(key => ({
    dataKey: key,
    stroke:
      EColorMapping[
        (key.charAt(0).toUpperCase() +
          key.slice(1)) as keyof typeof EColorMapping
      ] || '#000000',
    name: `${key.charAt(0).toUpperCase() + key.slice(1)}`,
  }));

  const legendItems = lineData.map(line => ({
    color: line.stroke,
    value: `${line.name} Portfolio`,
  }));

  const hideForNow = false;

  const shouldRenderFooter =
    hideForNow || validArray(clientViewpointDetails?.viewpoint);

  return (
    <>
      {!isLoading && (
        <>
          <ScrollContainer>
            <DrawerDataContainer>
              {/* {sortedPortfolios && sortedPortfolios?.length > 0 && ( */}
              <>
                <Grid>
                  {sortedPortfolios && sortedPortfolios.length > 1 && (
                    <Grid item container xs={2} mr={2}>
                      <CustomizedSelect
                        value={selectedPortfolio}
                        onChange={handlePortfolioChange}
                        style={{
                          fontSize: '14px',
                          width: '140px',
                          minWidth: '140px',
                          height: '50px',
                        }}
                        disabled={portfoliosData.length === 1}
                      >
                        {sortedPortfolios?.map((portfolio, index) => (
                          <MenuItem
                            key={portfolio.name}
                            sx={{ fontSize: '12px', color: '#282829' }}
                            value={index}
                          >
                            {portfolio?.accountNumber ?? portfolio?.name}
                          </MenuItem>
                        ))}
                      </CustomizedSelect>
                    </Grid>
                  )}
                  <ExpectedFundDataContainer container>
                    <Grid
                      item
                      container
                      direction={'row'}
                      xs={5.7}
                      md={3}
                      justifyContent={'center'}
                    >
                      <FundDataText item xs={12}>
                        Portfolio Value
                      </FundDataText>
                      <Grid item container xs={12} pl={'3px'} pt={'5px'}>
                        <FundDataNumber>
                          {portfolioId === 'aggregated'
                            ? price(clientMarketValue, true, false, false)
                            : price(portfolioMarketValue, true, false, false)}
                        </FundDataNumber>
                        <>
                          <Grid item container xs={0.8} mr={'5px'}>
                            <ArrowImg
                              alt="Gain/loss arrow image"
                              src={
                                getPortfolioDiff() >= 0 ? upArrow : downArrow
                              }
                            />
                          </Grid>
                          <Grid item>
                            <PortfolioDiffTag
                              style={{
                                color:
                                  getPortfolioDiff() >= 0
                                    ? '#282829'
                                    : '#e11d48',
                              }}
                            >
                              {getPortfolioDiff() >= 0
                                ? getPortfolioDiff()?.toString().split('.')[0]
                                    .length > 6
                                  ? `+${price(getPortfolioDiff(), true, true, true)}`
                                  : `+${price(getPortfolioDiff(), true, true, false)}`
                                : `${price(getPortfolioDiff(), true, true, false)}`}
                            </PortfolioDiffTag>
                          </Grid>
                        </>
                      </Grid>
                    </Grid>
                    <Grid item container xs={5.7} md={3} direction={'column'}>
                      <CurrentPortfolioDataType item>
                        Portfolio Risk Score
                      </CurrentPortfolioDataType>
                      <CurrentPortfolioDataValue item>
                        <p>
                          {portfolioId === 'aggregated'
                            ? clientRiskScore || 'N/A'
                            : portfolioRiskScore || 'N/A'}
                        </p>
                      </CurrentPortfolioDataValue>
                    </Grid>
                    <Grid item container xs={5.7} md={3}>
                      <CurrentPortfolioDataType item xs={12}>
                        Portfolio Type
                      </CurrentPortfolioDataType>
                      <CurrentPortfolioDataValue item xs={12}>
                        <p>
                          {portfolioId !== 'aggregated'
                            ? portfolioType && portfolioType === true
                              ? 'Taxable'
                              : 'Non-Taxable'
                            : 'N/A'}
                        </p>
                      </CurrentPortfolioDataValue>
                    </Grid>
                    {portfolioExpRisk && (
                      <Grid item container xs={5.7} md={3}>
                        <CurrentPortfolioDataType item xs={12}>
                          Expected Risk
                        </CurrentPortfolioDataType>
                        <CurrentPortfolioDataValue item xs={12}>
                          <p>{formatValue(portfolioExpRisk)}</p>
                        </CurrentPortfolioDataValue>
                      </Grid>
                    )}

                    {portfolioExpReturns && (
                      <Grid item container xs={5.7} md={3}>
                        <CurrentPortfolioDataType item xs={12}>
                          Expected Returns
                        </CurrentPortfolioDataType>
                        <CurrentPortfolioDataValue item xs={12}>
                          <p>{formatValue(portfolioExpReturns)}</p>
                        </CurrentPortfolioDataValue>
                      </Grid>
                    )}

                    {portfolioExpenseRatio && (
                      <Grid item container xs={5.7} md={3}>
                        <CurrentPortfolioDataType item xs={12}>
                          Net Expense Ratio
                        </CurrentPortfolioDataType>
                        <CurrentPortfolioDataValue item xs={12}>
                          <p>{formatValue(portfolioExpenseRatio)}</p>
                        </CurrentPortfolioDataValue>
                      </Grid>
                    )}

                    {data?.ClientPortfolios?.[0]?.riskProfile && (
                      <ExpectedFundDataWrapper
                        style={{ flexDirection: 'column' }}
                        xs={6}
                        md={3}
                      >
                        <FundDataText>Risk Appetite</FundDataText>
                        <StyledInfoBox>
                          <span>
                            {data?.ClientPortfolios?.[0]?.riskProfile}
                          </span>
                          {data?.ClientPortfolios?.[0]?.riskProfile ===
                          'low' ? (
                            <TrendingDownIcon />
                          ) : data?.ClientPortfolios?.[0]?.riskProfile ===
                            'high' ? (
                            <TrendingUpIcon />
                          ) : null}{' '}
                        </StyledInfoBox>
                      </ExpectedFundDataWrapper>
                    )}
                  </ExpectedFundDataContainer>
                </Grid>
                <Divider style={{ margin: '20px 0px', width: '97%' }} />
              </>
              {/* )} */}

              {/* <Grid
                container
                xs={12}
                mb={3}
                justifyContent={'flex-start'}
                sx={{
                  borderBottom: '1px solid #e4e7ec',
                }}
              >
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      color: '#282829',
                      fontSize: '16px',
                      fontWeight: 500,
                    }}
                  >{`Viewpoints Affecting ${clientName}`}</Typography>
                </Grid>
                <Grid
                  container
                  xs={12}
                  flexWrap={'wrap'}
                  columnGap={0.6}
                  rowGap={0.6}
                >
                  <Grid
                    item
                    container
                    alignItems="center"
                    justifyContent={'flex-start'}
                    flexWrap={'nowrap'}
                    sx={{
                      width: 'fit-content',
                      height: '36px',
                      border: '1px solid var(--Text-Dark-Secondary, #86858b)', // Add border style
                      borderRadius: '18px',
                      padding: '10px 18px 10px 16px',
                      background: '#fff',
                    }}
                  >
                    <Grid
                      item
                      container
                      xs={2}
                      alignItems={'center'}
                      mr={'8px'}
                    >
                      <MemoMagic />
                    </Grid>
                    <Grid item xs={11}>
                      <p
                        style={{
                          fontSize: '14px',
                          color: ' var(--Text-Dark-Secondary, #86858B)',
                          fontWeight: 500,
                          lineHeight: 'normal',
                        }}
                      >
                        {viewpoints}
                      </p>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid> */}
              {viewpoints?.length > 1 && (
                <>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '97%',
                      justifyContent: 'flex-start',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                      }}
                    >
                      <span
                        style={{
                          width: '100%',
                          display: 'flex',
                          color: '#282829',
                          fontSize: '16px',
                          fontWeight: 500,
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                          paddingBottom: '24px',
                        }}
                      >
                        {`Viewpoints Affecting ${clientName}`}
                      </span>
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          flexDirection: 'row',
                          flexWrap: 'wrap',
                        }}
                      >
                        <MemoAnimator
                          text={
                            <span
                              style={{
                                display: 'flex',
                                fontSize: '14px',
                                fontWeight: 500,
                                lineHeight: 'normal',
                              }}
                            >
                              {viewpoints}
                            </span>
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <Divider style={{ margin: '20px 0px', width: '97%' }} />
                </>
              )}
              {!isLoading && investmentObjective && (
                <>
                  <InvestmentHeader>
                    <StyledNewSpan>Investment Objective:</StyledNewSpan>
                    <InvestmentSpan>{investmentObjective}</InvestmentSpan>
                  </InvestmentHeader>
                  <Divider style={{ margin: '20px 0px', width: '100%' }} />
                </>
              )}
              {!isLoading && portfolioConstraints && (
                <>
                  <ConstraintsWrapper>
                    <ConstraintsHeaderWrapper>
                      <IconDiv>
                        <MemoWarningRed />
                      </IconDiv>
                      <TextDiv>Portfolio Constraints</TextDiv>
                    </ConstraintsHeaderWrapper>

                    <DescDiv>{portfolioConstraints}</DescDiv>
                  </ConstraintsWrapper>

                  <Divider style={{ margin: '20px 0px', width: '100%' }} />
                </>
              )}
              {portfolio?.historicalReturns?.length > 0 && (
                <>
                  <GraphContainer
                    item
                    container
                    xs={12}
                    sm={12}
                    maxHeight="520px"
                    sx={{ overflowY: 'hidden' }}
                  >
                    <ComparisonContainerHeader>
                      <strong>Performance</strong>
                    </ComparisonContainerHeader>

                    <SimpleLineGraph
                      data={portfolio?.historicalReturns}
                      lines={lineData}
                    />

                    <CustomLegend customLegendItems={legendItems} />
                  </GraphContainer>
                  <Divider style={{ margin: '20px 0px', width: '100%' }} />
                </>
              )}
              {(portfolio?.assetAllocation?.length > 0 ||
                portfolio?.regionAllocation?.length > 0 ||
                portfolio?.sectorAllocation?.length > 0) && (
                <>
                  <Grid
                    item
                    container
                    rowGap={1}
                    sx={{
                      background: '#f9f9f9',
                      width: '100%',
                      borderRadius: '8px',
                      padding: '16px',
                    }}
                  >
                    <AssetAllocation
                      currentPortfolioData={portfolio}
                      showProposed={false}
                      showCurrent={true}
                    />
                  </Grid>
                  <Divider style={{ margin: '20px 0px', width: '100%' }} />
                </>
              )}

              <PortfolioDataContainer>
                {!isLoading &&
                  clientMarketValue !== null &&
                  clientMarketValue !== 0 && (
                    <HoldingHeaderContainer>
                      <HoldingText>Portfolio Holdings</HoldingText>
                      {/* <PortfolioValueContainer>
                        <StyledSpan>
                          Total Value:{' '}
                          <ValueSpan>
                            {selectedPortfolio !== 'Aggregated Portfolio'
                              ? price(portfolioMarketValue, true, true, true)
                              : price(clientMarketValue, true, true, true)}
                          </ValueSpan>
                        </StyledSpan>
                      </PortfolioValueContainer> */}
                    </HoldingHeaderContainer>
                  )}
                <HoldingBody>
                  {clientPortfolioHoldingsData &&
                    clientPortfolioHoldingsData?.length > 0 &&
                    clientPortfolioHoldingsData.map((portfolio, index) => {
                      const isUnderlined = underlinedTickers.includes(
                        portfolio?.Instrument?.ticker,
                      );
                      const sponsor = portfolio?.Instrument?.name || '';
                      const ticker = portfolio?.Instrument?.ticker || '';
                      const weight =
                        portfolio?.weight !== undefined ? portfolio.weight : 0;
                      const shares =
                        portfolio?.shares !== undefined ? portfolio.shares : 0;
                      const values =
                        portfolio?.values !== undefined ? portfolio.values : 0;
                      return (
                        <MappedDataWrapper key={index}>
                          <DataDivWrapper width="40%">
                            {sponsor?.length > 38 ? (
                              <Tooltip title={sponsor} arrow placement="top">
                                <DataDiv style={{ cursor: 'pointer' }}>
                                  {`${sponsor.slice(0, 38)}...`}
                                </DataDiv>
                              </Tooltip>
                            ) : (
                              <DataDiv style={{ cursor: 'pointer' }}>
                                {sponsor}
                              </DataDiv>
                            )}

                            <SubTextWrapper
                              style={{
                                textDecoration: isUnderlined
                                  ? 'underline'
                                  : 'none',
                                cursor: 'pointer',
                              }}
                              onMouseOver={() => {
                                setUnderlinedTickers(prevTickers => [
                                  ...prevTickers,
                                  portfolio?.Instrument?.ticker,
                                ]);
                              }}
                              onMouseLeave={() => {
                                setUnderlinedTickers(prevTickers =>
                                  prevTickers.filter(
                                    ticker =>
                                      ticker !== portfolio?.Instrument?.ticker,
                                  ),
                                );
                              }}
                              onClick={e => {
                                e.preventDefault();
                                setSelectedTicker(portfolio?.Instrument.ticker);
                                setStepper('PORTFOLIO_DETAILS_PAGE');
                              }}
                            >
                              {ticker}
                            </SubTextWrapper>
                          </DataDivWrapper>
                          <DataDivWrapper textAlign="right" width="20%">
                            <DataDiv>{price(weight, false)}%</DataDiv>
                            <SubTextWrapper>Weight</SubTextWrapper>
                          </DataDivWrapper>
                          <DataDivWrapper textAlign="right" width="20%">
                            <DataDiv>{price(shares, false)}</DataDiv>
                            <SubTextWrapper>No. of Shares</SubTextWrapper>
                          </DataDivWrapper>
                          <DataDivWrapper textAlign="right" width="20%">
                            <DataDiv>{price(values)}</DataDiv>
                            <SubTextWrapper>Value</SubTextWrapper>
                          </DataDivWrapper>
                        </MappedDataWrapper>
                      );
                    })}
                </HoldingBody>
              </PortfolioDataContainer>
            </DrawerDataContainer>
          </ScrollContainer>
          {shouldRenderFooter && (
            <ClientsDrawerFooterButtonContainer>
              <FooterButtonWrapper>
                {hideForNow && (
                  <StyledButton
                    variant="outlined"
                    onClick={handleEditPortfolioClick}
                  >
                    <img
                      src={edit}
                      alt="Edit image"
                      style={{ marginRight: '5px' }}
                    />
                    Edit Portfolio
                  </StyledButton>
                )}
                {validArray(clientViewpointDetails?.viewpoint) && (
                  <StyledApplyButton
                    variant="outlined"
                    style={{ width: '100%' }}
                    onClick={() => {
                      navigate('/conversations/start');

                      if (isChatStreaming === 'true') {
                        dispatch(
                          actions.setSearchQuery(
                            `What adjustments should I make to ${clientViewpointDetails?.clientName}’s portfolio so it aligns with the ${clientViewpointDetails.viewpoint} viewpoint?`,
                          ),
                        );
                      } else {
                        dispatch(
                          actions.searchQueryRequest({
                            query: `What adjustments should I make to ${clientViewpointDetails?.clientName}’s portfolio so it aligns with the ${clientViewpointDetails.viewpoint} viewpoint?`,
                          }),
                        );
                      }
                    }}
                  >
                    Suggested Adjustments
                  </StyledApplyButton>
                )}
              </FooterButtonWrapper>
            </ClientsDrawerFooterButtonContainer>
          )}
          {isAddPortfolioModalOpen && (
            <AddPortfolioModal
              isOpen={isAddPortfolioModalOpen}
              onClose={() => setAddPortfolioModalOpen(false)}
              onSubmit={handleSubmit}
              showFooterButton={true}
              title={`Edit ${clientViewpointDetails.clientName}'s Portfolio`}
              showTitle={true}
              client_name={clientViewpointDetails.clientName}
              clientPortfolioData={portfolioData}
              refreshData={refreshPortfolioData}
              isDataLoading={isDataLoading}
            />
          )}
        </>
      )}
    </>
  );
}

export default CurrentPortfolio;
