import moment from 'moment';

const FORMAT_DATE = 'MM-DD-YYYY';
const FORMAT_TIME = 'HH:mm';
const FORMAT_DATE_TIME = `${FORMAT_DATE} ${FORMAT_TIME}`;

export const getDate = (dateTime = ''): string => {
  if (dateTime) {
    return moment(dateTime).format(FORMAT_DATE);
  } else {
    return moment().format(FORMAT_DATE);
  }
};

export const getTime = (dateTime = ''): string => {
  if (dateTime) {
    return moment(dateTime).format('HH:mm');
  } else {
    return moment().format('HH:mm');
  }
};

export const getDateTime = (dateTime = ''): string => {
  if (dateTime) {
    return moment(dateTime).format(FORMAT_DATE_TIME);
  } else {
    return moment().format(FORMAT_DATE_TIME);
  }
};
