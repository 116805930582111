// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const downloadSampleFile = (
  data: any[],
  name: string = 'sample.csv',
  type: string = 'text/csv;charset=utf-8;',
): any => {
  // Convert CSV array to string
  const csvContent = data.map(row => row.join(',')).join('\n');

  // Create a Blob from the CSV string
  const blob = new Blob([csvContent], { type });

  // Create a link element
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);
  link.setAttribute('href', url);
  link.setAttribute('download', name);

  // Append link to the body
  document.body.appendChild(link);

  // Trigger the download
  link.click();

  // Cleanup
  document.body.removeChild(link);
};
