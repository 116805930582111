import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Grid, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Avatar from '@images/new-avatar.svg';
import { useRESTQuery } from '@hooks/useRESTQuery';
import { LoaderContainer } from '@pages/Signup/styles';
import CurrentPortfolio from './CurrentPortfolio';
import AdjustmentLog from './AdjustmentLog';
import AssessmentResult from './AssessmentResult';
import { Spinner } from '@pages/IntegrationPage/styles';
import TickerDrawer from '@components/ChatContent/TickerDrawer';
import { handleError } from '@common/error';
import { TabHeader } from '@pages/SuggestedActions/styles';
import {
  ClientEmailWrapper,
  ClientNameWrapper,
  DrawerContainer,
  DrawerDataWrapper,
  DrawerHeaderDataContainer,
  HeaderWrapper,
} from './styles';
import PortfolioComparison from './PortfolioComparison';
import { EAdjustmentStepper } from 'app/enums';

interface DrawerDatapProps {
  onClose: () => void;
  clientId?: string;
  clientsData: any;
  viewpoints: string;
  portfolioDiff: number;
}

function ClientsDrawerData({
  clientId,
  onClose,
  clientsData,
  viewpoints,
  portfolioDiff,
}: DrawerDatapProps): React.JSX.Element {
  const dispatch = useDispatch();
  const [selectedTicker, setSelectedTicker] = useState<string>('');
  const [stepper, setStepper] = useState<
    'CLIENT_DRAWER_PAGE' | 'PORTFOLIO_DETAILS_PAGE'
  >('CLIENT_DRAWER_PAGE');
  const [adjustmentStepper, setAdjustmentStepper] = useState<string>(
    EAdjustmentStepper.ProposedAdjustments,
  );
  const [selectedTab, setSelectedTab] = useState<1 | 2 | 3>(1);
  const [selectedCard, setSelectedCard] = useState<{
    title: string;
    desc: string;
    id: string;
  } | null>(null);

  const {
    data,
    isLoading,
    refetch: fetchClientDetails,
  } = useRESTQuery(
    ['get-clients-details', clientId],
    {
      endpoint: `/client/details?id=${clientId}`,
      method: 'GET',
      headers: {
        'X-Tifin-Ai-Auth': localStorage.getItem('x-tifin-ai-token'),
      },
    },
    {
      enabled: !!clientId,
      refetchOnMount: 'always',
      select: response => response.clientRecord,
      onError: (error: any) => handleError(dispatch, error),
    },
  );

  const toggleTabHeader = (divNumber: 1 | 2 | 3) => {
    if (divNumber !== selectedTab) setSelectedTab(divNumber);
  };

  const getViewpoints = () => clientsData.find(item => item?.id === data?.id);

  const hideProposal =
    (data?.currentPortfolioRisk === null || data?.currentPortfolioRisk === 0) &&
    !data?.riskCapacity &&
    !data?.riskPreference;

  if (isLoading) {
    return (
      <LoaderContainer>
        <Spinner />
      </LoaderContainer>
    );
  }

  const portfolioData = data?.ClientPortfolios?.[0];
  const portfolioName = portfolioData?.name;
  const clientMarketValue = data?.marketValue || 0;
  const clientRiskScore = data?.currentPortfolioRisk;
  const clientEmail = data?.email;
  const clientName = `${data?.firstName} ${data?.lastName}`;

  const renderTabContent = () => {
    switch (selectedTab) {
      case 1:
        if (stepper === 'CLIENT_DRAWER_PAGE') {
          return (
            <CurrentPortfolio
              fetchClientDetails={fetchClientDetails}
              clientMarketValue={clientMarketValue}
              clientRiskScore={clientRiskScore}
              clientViewpointDetails={getViewpoints()}
              clientEmail={clientEmail}
              clientName={clientName}
              onClose={onClose}
              setSelectedTicker={setSelectedTicker}
              setStepper={setStepper}
              viewpoints={viewpoints}
              portfolioDiff={portfolioDiff}
              portfoliosData={data?.ClientPortfolios}
            />
          );
        } else if (stepper === 'PORTFOLIO_DETAILS_PAGE') {
          return (
            <TickerDrawer
              selectedTicker={selectedTicker}
              setSelectedTicker={setSelectedTicker}
              stepper={stepper}
              setSelectedTab={setSelectedTab}
              setStepper={setStepper}
              portfolioName={portfolioName}
              showStepper
              onClose={onClose}
            />
          );
        }
        break;

      case 2:
        return (
          <AssessmentResult
            riskCapacity={data?.riskCapacity}
            riskPreference={data?.riskPreference}
            portfolioRisk={data?.currentPortfolioRisk}
            riskAssessmentUpdatedAt={data?.riskAssessmentUpdatedAt}
          />
        );

      case 3:
        if (adjustmentStepper === EAdjustmentStepper.ProposedAdjustments) {
          return (
            <AdjustmentLog
              clientId={clientId}
              setStepper={setAdjustmentStepper}
              setSelectedCard={setSelectedCard}
              clientName={clientName}
            />
          );
        } else if (
          adjustmentStepper === EAdjustmentStepper.PortfolioComparison
        ) {
          return (
            <PortfolioComparison
              stepper={adjustmentStepper}
              setSelectedTicker={setSelectedTicker}
              setStepper={setAdjustmentStepper}
              showStepper
              selectedCard={selectedCard}
              onClose={onClose}
            />
          );
        } else if (adjustmentStepper === 'PORTFOLIO_DETAILS_PAGE') {
          return (
            <TickerDrawer
              selectedTicker={selectedTicker}
              setAdjustmentStepper={setAdjustmentStepper}
              setSelectedTicker={setSelectedTicker}
              stepper={stepper}
              setStepper={setStepper}
              portfolioName={portfolioName}
              showStepper
              onClose={onClose}
            />
          );
        }
        break;

      default:
        return null;
    }
  };

  const renderHeader = () => {
    if (
      stepper !== 'PORTFOLIO_DETAILS_PAGE' &&
      adjustmentStepper !== EAdjustmentStepper.PortfolioComparison &&
      adjustmentStepper !== 'PORTFOLIO_DETAILS_PAGE'
    ) {
      return (
        <>
          <HeaderWrapper>
            <DrawerHeaderDataContainer>
              <img
                src={Avatar}
                alt="Avatar image"
                style={{ marginRight: '10px' }}
              />
              <DrawerDataWrapper>
                <ClientNameWrapper>{clientName}</ClientNameWrapper>
                <ClientEmailWrapper>{clientEmail}</ClientEmailWrapper>
              </DrawerDataWrapper>
            </DrawerHeaderDataContainer>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </HeaderWrapper>
          <Grid
            item
            container
            pt={3}
            mb={2}
            flexWrap="nowrap"
            sx={{ borderBottom: '1px solid #e4e7ec' }}
          >
            <TabHeader
              style={{ margin: '0px 20px 0px 0px', paddingBottom: '10px' }}
              active={selectedTab === 1}
              onClick={() => toggleTabHeader(1)}
            >
              <p className="tab sm-font">Current Portfolio</p>
            </TabHeader>
            <TabHeader
              style={{ margin: '0px 20px 0px 0px', paddingBottom: '10px' }}
              active={selectedTab === 2}
              onClick={() => toggleTabHeader(2)}
            >
              <p className="tab sm-font">Assessment Results</p>
            </TabHeader>
            {!hideProposal && (
              <TabHeader
                style={{ margin: '0px 20px 0px 0px', paddingBottom: '10px' }}
                active={selectedTab === 3}
                onClick={() => toggleTabHeader(3)}
              >
                <p className="tab sm-font">Proposed Adjustments</p>
              </TabHeader>
            )}
          </Grid>
        </>
      );
    }
    return null;
  };

  return (
    <DrawerContainer>
      {renderHeader()}
      {renderTabContent()}
    </DrawerContainer>
  );
}

export default ClientsDrawerData;
