import { useSelector } from 'react-redux';
import { getAuthDetails } from 'app/features/Profile/selector';
import { AuthDetailsProps } from 'app/features/Profile/types';

const useAuthState = (): AuthDetailsProps => {
  const authState = useSelector(getAuthDetails);
  const apiKey = localStorage.getItem('api-key');
  console.log(authState);
  console.log(apiKey);
  return authState;
};

export default useAuthState;
