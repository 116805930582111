export const price = (
  x: number | string = 0,
  currency = true,
  showDecimals = true,
  showMillions: boolean | number = false,
): string => {
  let decimal = '';

  if (typeof x === 'string' && x.includes('.')) {
    [x, decimal] = x.split('.');
    decimal = '.' + decimal.substr(0, 2);
    decimal = decimal === '.00' || decimal === '.0' ? '' : decimal;
  }

  const config: Intl.NumberFormatOptions = {
    currency: 'USD',
    minimumFractionDigits: 0,
  };

  if (currency) config.style = 'currency';

  x = Number(x);

  if (showMillions) {
    if (x >= 1000000) {
      x = x / 1000000;
      config.minimumFractionDigits = 0;
      config.maximumFractionDigits = 3;
      return new Intl.NumberFormat('en-US', config).format(x) + 'M';
    } else {
      x = x / 1000000;
      config.minimumFractionDigits = 2;
      config.maximumFractionDigits = 2;
      return new Intl.NumberFormat('en-US', config).format(x) + 'M';
    }
  }
  // config.minimumFractionDigits = 2;
  // config.maximumFractionDigits = 2;
  if (!showDecimals) {
    config.minimumFractionDigits = 0;
    config.maximumFractionDigits = 0;
  } else {
    config.minimumFractionDigits = 2;
    config.maximumFractionDigits = 2;
  }

  return new Intl.NumberFormat('en-US', config).format(x).toString() + decimal;
};

export const checkValidNumber = (
  num: number,
  prec: number = 0,
  suffix: any = null,
  handleReturn: any = '--',
): any => {
  return typeof num !== 'undefined' &&
    num !== null &&
    !isNaN(num) &&
    !isNaN(Number(num))
    ? prec === 0
      ? suffix
        ? Number(num) + suffix
        : Number(num)
      : suffix
        ? Number(Number(num).toFixed(prec)) + suffix
        : Number(Number(num).toFixed(prec))
    : handleReturn;
};

export const formatFloatWithCommas = (value: number): string => {
  const fixedValue = value.toFixed(2);
  return parseFloat(fixedValue).toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const convertToNDecimal = (
  num: string | number,
  prec: number = 1,
): number => {
  let n = num;
  if (typeof n !== 'number') {
    n = Number(n);
  }

  if (Number.isInteger(n)) {
    return n;
  } else {
    return Number(n.toFixed(prec));
  }
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const extractNumber = (input: any) => {
  // Check if the input is a number or a string
  if (typeof input === 'number') {
    return { isNaN: false, number: input };
  } else if (typeof input === 'string') {
    // Regular expression to match a number or a percentage
    const regex = /^(\d+(\.\d+)?)(%)?$/;
    const match = input.match(regex);

    if (match) {
      // Extract the number
      const number = parseFloat(match[1]);
      return { isNaN: false, number: number };
    }
  }
  // Return isNaN true for any other cases
  return { isNaN: true };
};
