import { Grid, styled } from '@mui/material';

export const Container = styled(Grid)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledBox = styled(Grid)`
  margin-top: 5px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 5px;
`;

export const FileDropContainer = styled(Grid)`
  .custom-dropzone {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 5rem;
    padding-bottom: 2rem;
  }
`;
