import React, { MouseEvent } from 'react';
import {
  SuggestedFollowUpsContainer,
  SuggestedFollowUpsLi,
  SuggestedFollowUpsLink,
  SuggestedFollowUpsMarker,
} from './styles';

interface SuggestedFollowUpsProps {
  questions: string[];
  disabled?: boolean;
  handleQuestionClick?: (question: string) => void;
}

function SuggestedFollowUps(props: SuggestedFollowUpsProps): React.JSX.Element {
  const handleClick = (ev: MouseEvent<HTMLLIElement>, question: string) => {
    ev.stopPropagation();

    if (!props.disabled) {
      props.handleQuestionClick?.(question);
    }
  };

  return (
    <SuggestedFollowUpsContainer className={props.disabled ? 'disabled' : ''}>
      {props.questions.map((question, index) => (
        <SuggestedFollowUpsLi
          key={index}
          onClick={ev => handleClick(ev, question)}
        >
          <SuggestedFollowUpsLink title={question}>
            <SuggestedFollowUpsMarker>✦</SuggestedFollowUpsMarker>
            {question}
          </SuggestedFollowUpsLink>
        </SuggestedFollowUpsLi>
      ))}
    </SuggestedFollowUpsContainer>
  );
}

export default SuggestedFollowUps;
