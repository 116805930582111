import React, { useEffect, useState } from 'react';
import WelcomeToSage from './WelcomeToSage';

interface SplashScreenProps {
  callBack: () => void;
}

function WelcomeSplashScreen({
  callBack,
}: SplashScreenProps): React.JSX.Element {
  const [content, setContent] = useState(<div></div>);
  const welcomeState: any = localStorage.getItem('welcome-state');
  useEffect(() => {
    if (welcomeState === 1 || welcomeState === '1') {
      setTimeout(() => {
        setContent(<WelcomeToSage callBack={callBack} />);
      }, 500);
    }
  }, [welcomeState, callBack]);

  return <React.Fragment>{content}</React.Fragment>;
}

export default WelcomeSplashScreen;
