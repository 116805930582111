// store/configureStore.ts
import { configureStore } from '@reduxjs/toolkit';
import { settings } from 'config';
import logger from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import { rootReducer, rootSaga } from './reducers';

export function configureAppStore(): any {
  // Create the store with saga middleware
  const sagaMiddleware = createSagaMiddleware();
  const middleware = [
    settings.environment !== 'production' ? logger : undefined,
    sagaMiddleware,
  ];
  const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        thunk: false,
        serializableCheck: false,
      }).prepend(...middleware),
    devTools: settings.environment !== 'production',
  });

  sagaMiddleware.run(rootSaga);

  return store;
}
