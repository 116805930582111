import { Grid, Menu, MenuItem, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { CardWrapper } from './styles';
import { price } from '@common/number';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { actions } from '@features/Chat/slice';
import CustomSlider from '@components/CustomSlider';
import MemoKeyboardDownArrow from '@icons/KeyboardDownArrow';
import { Spinner } from '@pages/IntegrationPage/styles';
import MemoAnimator from '@components/UIComponents/MemoAnimator';
import RiskCircularProgress from '@components/UIComponents/CircularProgressBar';
import RiskBar from '@components/UIComponents/RiskBar';
import TruncatedTextWithTooltip from '@components/UIComponents/TruncatedTextWithTooltip';
import { validArray } from '@common/validArray';
import { ClientCardProps } from './types';
import { getClientDisplayName, getMenuOptions } from './helpers';

function ClientCard({
  clientName,
  priceValue,
  handleDrawerOpen,
  clientId,
  title = [],
  portfolioDiff,
  portfolioRisk,
  riskPreference,
  riskCapacity,
  isLoading,
  viewpointsData,
}: ClientCardProps): React.JSX.Element {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [matchingSummary, setMatchingSummary] = useState<string | null>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isChatStreaming = localStorage.getItem('streaming') === 'true';

  const newTitle = validArray(title) ? title : ['No Relevant Viewpoint'];

  const menuOptions = getMenuOptions(newTitle, clientName, currentIndex);

  useEffect(() => {
    if (validArray(viewpointsData) && validArray(newTitle)) {
      const matchingViewpoint = viewpointsData.find(
        ({ focus }) => focus === newTitle[currentIndex],
      );
      setMatchingSummary(matchingViewpoint?.summary || null);
    }
  }, [viewpointsData, newTitle, currentIndex]);

  const handleDropdownOpen = (
    event:
      | React.MouseEvent<HTMLDivElement>
      | React.KeyboardEvent<HTMLDivElement>,
  ) => {
    event.stopPropagation();
    if (menuOptions.length > 1) {
      setAnchorEl(event.currentTarget);
      setDropdownOpen(true);
    }
  };

  const handleDropdownClose = (event: React.MouseEvent) => {
    event.stopPropagation();
    setAnchorEl(null);
    setDropdownOpen(false);
  };

  const handleSelectedClick = (option: any) => {
    navigate(option.href);
    dispatch(
      isChatStreaming
        ? actions.setSearchQuery(option.value)
        : actions.searchQueryRequest({ query: option.value }),
    );
  };

  const handleInteraction = (
    event:
      | React.MouseEvent<HTMLDivElement>
      | React.KeyboardEvent<HTMLDivElement>,
  ) => {
    if (menuOptions.length > 1) {
      handleDropdownOpen(event);
    } else {
      event.stopPropagation();
    }
  };

  return (
    <CardWrapper
      container
      item
      xs={12}
      direction={'column'}
      columnGap={1.25}
      rowGap={1.25}
      justifyContent={'space-between'}
      onClick={() =>
        handleDrawerOpen(clientId, title, portfolioDiff, clientName)
      }
    >
      <Grid
        item
        container
        justifyContent={'space-between'}
        alignItems={'center'}
        mb={'-5px'}
      >
        <Grid item xs={9}>
          {clientName && (
            <TruncatedTextWithTooltip text={getClientDisplayName(clientName)} />
          )}
        </Grid>
        <Grid
          item
          xs={3}
          container
          p={'6px 0px'}
          justifyContent={'center'}
          alignItems={'center'}
          className="profile_value_info"
        >
          <Grid item>
            {priceValue || priceValue === 0 ? (
              <p className="price">{price(priceValue, true, false, false)}</p>
            ) : (
              <p className="price">N/A</p>
            )}
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        container
        justifyContent={'space-between'}
        alignItems={'center'}
        direction={'row'}
        columnGap={1}
        pb={'10px'}
      >
        <Grid
          item
          container
          justifyContent={'flex-start'}
          alignItems={'baseline'}
          direction={'column'}
          xs={3}
        >
          <Grid item pb={2}>
            <p className="risk_text">Risk Score</p>
          </Grid>
          <Grid item>
            <p className="risk_score">{portfolioRisk ? portfolioRisk : '--'}</p>
          </Grid>
        </Grid>

        <Grid item container xs={8} rowGap={1} mt={1.25} position="relative">
          <RiskBar
            riskPreference={riskPreference}
            riskCapacity={riskCapacity}
            showZeroLabel={false}
            showHundredLabel={false}
            hoverLine={true}
          />
          {portfolioRisk && (
            <Grid
              item
              sx={{
                position: 'absolute',
                left: `${portfolioRisk}%`,
                top: '80%',
                transform: 'translateX(-50%) translateY(-50%)',
                zIndex: 1,
              }}
            >
              <RiskCircularProgress
                value={portfolioRisk}
                size="25px"
                borderSize="5px"
                fontSize="12px"
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid
        item
        container
        p={'var(--Spacing-XSmall, 12px)'}
        alignItems={'flex-start'}
        rowGap={2}
        className="viewpoint_info"
        sx={{
          height: '200px',
        }}
      >
        {newTitle[0] && newTitle[0] !== 'No Relevant Viewpoint' ? (
          <Grid container item xs={12} justifyContent={'space-between'}>
            <Grid item container xs={8} height={'20px'}>
              <Grid
                container
                justifyContent={'center'}
                alignItems={'center'}
                className="viewpoint_wrapper"
              >
                <p className="viewpoint_text">{newTitle[currentIndex]}</p>
              </Grid>
            </Grid>
            <Grid item container xs={4}>
              <CustomSlider
                viewpoints={newTitle}
                currentIndex={currentIndex}
                setCurrentIndex={setCurrentIndex}
              />
            </Grid>
          </Grid>
        ) : (
          <Grid
            item
            container
            columnGap={0.5}
            alignItems={'center'}
            pt={3}
          ></Grid>
        )}

        <Grid
          item
          xs={12}
          sx={{
            height: `calc(200px - 60px)`,
          }}
        >
          {isLoading ? (
            <Grid
              item
              container
              justifyContent={'center'}
              alignItems={'center'}
            >
              <Spinner />
            </Grid>
          ) : title.length > 0 ? (
            <p className="additional_info">
              {matchingSummary
                ? matchingSummary
                : 'No information is available for this viewpoint'}
            </p>
          ) : (
            <p className="no_additional_info">
              This client is unaffected by current viewpoints.
            </p>
          )}
        </Grid>
      </Grid>
      <Grid
        item
        container
        columnGap={1}
        justifyContent={'space-between'}
        onClick={handleInteraction}
        onKeyDown={handleInteraction}
        sx={{
          cursor: menuOptions.length <= 1 ? 'not-allowed' : 'pointer',
        }}
      >
        <Grid
          item
          container
          xs={10.5}
          alignItems={'center'}
          tabIndex={0}
          role="button"
          aria-label="Selected Option"
          justifyContent={'space-between'}
          sx={{
            opacity: menuOptions.length <= 1 ? 0.4 : 1,
            cursor: menuOptions.length <= 1 ? 'not-allowed' : 'pointer',
          }}
          onClick={event => {
            if (menuOptions.length > 1) {
              handleSelectedClick(menuOptions[0]);
            } else {
              event.stopPropagation();
            }
          }}
          onKeyDown={event => {
            if (event.key === 'Enter' || event.key === ' ') {
              event.preventDefault();
              if (menuOptions.length > 1) {
                handleSelectedClick(menuOptions[0]);
              }
            }
          }}
        >
          <MemoAnimator
            text={
              <Typography
                sx={{
                  paddingLeft: '5px',
                  fontSize: '12px',
                  fontWeight: 500,
                  lineHeight: 'normal',
                }}
              >
                {menuOptions[0].key}
              </Typography>
            }
          />
        </Grid>
        <Grid
          item
          container
          xs={1}
          justifyContent={'center'}
          height={'34px'}
          alignItems={'center'}
          tabIndex={0}
          role="button"
          aria-label="Open Menu"
          sx={{
            cursor: menuOptions.length <= 1 ? 'not-allowed' : 'pointer',
          }}
        >
          <MemoKeyboardDownArrow
            opacity={menuOptions.length <= 1 ? 0.3 : 1}
            width={30}
            height={15}
          />
        </Grid>
      </Grid>
      <Menu
        anchorEl={anchorEl}
        open={dropdownOpen}
        onClose={handleDropdownClose}
        sx={{
          marginTop: '15px',
          '& .MuiPaper-root': {
            marginLeft: '10px',
            background: '#fff',
          },
        }}
        MenuListProps={{
          sx: {
            width: `calc(${anchorEl && anchorEl.offsetWidth}px + 20px)`,
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {menuOptions.map((option, index) => (
          <MenuItem
            onClick={() => handleSelectedClick(option)}
            onKeyDown={() => handleSelectedClick(option)}
            key={index}
            sx={{
              '& .MuiPaper-root': {
                background: '#fff',
              },
              '&:hover': {
                backgroundColor: '#fff',
              },
              paddingLeft: '10px',
            }}
          >
            <MemoAnimator
              text={
                <Typography
                  sx={{
                    paddingLeft: '5px',
                    fontSize: '12px',
                    fontWeight: 500,
                    lineHeight: 'normal',
                  }}
                >
                  {option.key}
                </Typography>
              }
            />
          </MenuItem>
        ))}
      </Menu>
    </CardWrapper>
  );
}

export default ClientCard;
