import { Box, Typography, styled } from '@mui/material';

interface ColorBoxProps {
  color: string;
}

export const MainWrapper = styled(Box)`
  background-color: #fff;
  border-radius: 8px;
  padding: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`;

export const LegendItemsContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  margin-right: 20px;
`;

export const LegendItemsWrapper = styled(Box)<ColorBoxProps>`
  width: 16px;
  height: 16px;
  background-color: ${props => props.color};
  display: inline-block;
  margin-right: 5px;
`;

export const LegendTypography = styled(Typography)`
  font-size: 12px;
  color: #424242;
  display: flex;
  align-items: center;
  text-wrap: nowrap;
  font-size: 10px;
  @media (min-width: 900px) {
    font-size: 12px;
  }
`;
