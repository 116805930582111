/* eslint-disable react/prop-types */

import React from 'react';

function ColorMediaIcon(): React.JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="56"
      height="57"
      fill="none"
      viewBox="0 0 56 57"
    >
      <rect width="48" height="48" x="4" y="4.5" fill="#0B5CD7" rx="24"></rect>
      <rect
        width="48"
        height="48"
        x="4"
        y="4.5"
        stroke="#B6CFF3"
        strokeWidth="8"
        rx="24"
      ></rect>
      <path
        stroke="#FCFDFD"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M25 36h-2.25a5.25 5.25 0 110-10.5c.44 0 .877.053 1.303.16M23.5 28.5A7.5 7.5 0 1137 33"
      ></path>
      <path
        stroke="#FCFDFD"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M27.072 31.678L30.25 28.5l3.178 3.178M30.25 36v-7.5"
      ></path>
    </svg>
  );
}

const MemoConnectIcon = React.memo(ColorMediaIcon);
export default MemoConnectIcon;
