import React, { Dispatch, SetStateAction, useMemo, useState } from 'react';
import FundSwapping from './FundSwapping';

type FundSwappingSliderProps = {
  chatId?: any;
  data: any;
  selectTicker: Dispatch<SetStateAction<string>>;
};

const FundSwappingSlider = ({
  data,
  chatId,
  selectTicker,
}: FundSwappingSliderProps): React.JSX.Element => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const fundsItem = useMemo(() => data[currentIndex], [currentIndex]);
  return (
    <FundSwapping
      key={currentIndex}
      chatId={chatId}
      data={fundsItem.content?.[0]?.funds_data || []}
      tickerInfo={fundsItem.content?.[0]?.original_investment}
      selectTicker={selectTicker}
      list={data}
      currentIndex={currentIndex}
      setCurrentIndex={setCurrentIndex}
    />
  );
};

export default FundSwappingSlider;
