import React from 'react';
import TourArrow from '@images/TourArrow.png';
import { Box } from '@mui/material';
import { CardLabel } from './styles';

const BottomArrow = (): React.JSX.Element => {
  return (
    <Box
      style={{
        position: 'absolute',
        bottom: '40px',
        left: '5px',
        display: 'flex',
      }}
    >
      <img src={TourArrow} alt="Upward image" style={{ marginRight: '5px' }} />
      <CardLabel style={{ paddingLeft: '10px', paddingRight: '10px' }}>
        Click here to display popular questions
      </CardLabel>
    </Box>
  );
};
export default BottomArrow;
