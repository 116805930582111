import React, { FC } from 'react';
import { Grid, Typography } from '@mui/material';
import GridViewIcon from '@icons/GridView';
import ListViewIcon from '@icons/ListView';
import {
  AddFirmAdminButton,
  FirmCardViewTypeContainer,
  GridStyleCardContainer,
  ListViewCardIconDiv,
  ListViewContainer,
  ViewStyleContainer,
} from './styles';

type FirmCardData = {
  name: string;
  firm_number: string;
  total_members: string;
};

type FirmCardDataArray = FirmCardData[];

interface FirmListViewProps {
  activeView: string;
  toggleView: (view: string) => void;
  handleFirmCardClick: (item: any) => void;
  FIRM_CARD_OVERVIEW_DATA: FirmCardDataArray;
  allFirmList: any;
}

const FirmSection: FC<FirmListViewProps> = ({
  activeView,
  toggleView,
  handleFirmCardClick,
  allFirmList,
}) => {
  return (
    <Grid
      container
      xs={8.8}
      p={'15px'}
      sx={{
        background: '#fff',
      }}
    >
      <Grid item container justifyContent={'space-between'} xs={12} height={45}>
        <Grid
          item
          container
          justifyContent={'flex-start'}
          xs={10}
          height={45}
          direction={'column'}
        >
          <Typography fontSize={'18px'} color={'#101828'}>
            Firms
          </Typography>
          <Typography fontSize={'12px'} color={'#475467'}>
            Select a firm to view members and their engagement
          </Typography>
        </Grid>
        <FirmCardViewTypeContainer
          item
          container
          xs={2}
          height={35}
          justifyContent={'flex-end'}
          alignItems={'center'}
        >
          <ViewStyleContainer
            item
            container
            activeView={activeView}
            onClick={() => toggleView('grid')}
          >
            <GridViewIcon
              activeColor={activeView === 'list' ? '#101828' : '#fff'}
            />
          </ViewStyleContainer>
          <ListViewContainer
            item
            container
            activeView={activeView}
            onClick={() => toggleView('list')}
          >
            <ListViewIcon
              activeColor={activeView === 'list' ? '#fff' : '#101828'}
            />
          </ListViewContainer>
        </FirmCardViewTypeContainer>
      </Grid>
      <Grid
        item
        container
        sx={{
          height: 'calc(100% - 80px)',
          overflowY: 'auto',
          display: 'block',
        }}
      >
        {activeView === 'grid' ? (
          <Grid
            item
            container
            columnGap={2}
            rowGap={2}
            justifyContent="flex-start"
            flexWrap={'wrap'}
          >
            {allFirmList?.data.map((item, index) => (
              <GridStyleCardContainer
                item
                container
                xs={3.8}
                padding={'15px'}
                key={index}
                columnGap={1.2}
                onClick={() => handleFirmCardClick(item)}
                sx={{
                  height: '72px',
                }}
              >
                <ListViewCardIconDiv item xs={2} marginRight={'0px'} />
                <Grid item container xs={9}>
                  <Typography
                    justifyContent={'flex-start'}
                    fontSize={'16px'}
                    color={'#1d2939'}
                    fontWeight={500}
                  >
                    {item?.name}
                  </Typography>
                  <Grid item container>
                    <Typography fontSize={'12px'} color={'#667085'}>
                      {item?.totalAdvisors > 1
                        ? `${item?.totalAdvisors} Members`
                        : `${item?.totalAdvisors} Member`}
                    </Typography>
                  </Grid>
                </Grid>
              </GridStyleCardContainer>
            ))}
          </Grid>
        ) : (
          <Grid item container sx={{ height: 'auto', display: 'block' }}>
            {allFirmList?.data?.map((item, index) => (
              <Grid
                item
                container
                key={index}
                xs={12}
                padding={'15px 15px 15px 0px'}
                sx={{
                  background: '#fff',
                  borderBottom: '1px solid var(--Neutral-100, #f2f4f7)',
                  cursor: 'pointer',
                  height: 'fit-content',
                }}
              >
                <Grid
                  item
                  container
                  xs={9}
                  justifyContent={'flex-start'}
                  alignItems={'center'}
                >
                  <ListViewCardIconDiv item />
                  <Grid item container xs={8}>
                    <Typography>{item?.name}</Typography>
                    <Grid
                      item
                      container
                      xs={12}
                      fontSize={'12px'}
                      color={'#667085'}
                      alignItems={'center'}
                    >
                      <Typography>{item?.firm_number}</Typography>
                      <Grid item>•</Grid>
                      <Typography pl={0.5}>
                        {item?.totalAdvisors > 1
                          ? `${item?.totalAdvisors} Members`
                          : `${item?.totalAdvisors} Member`}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={3} justifyContent={'flex-end'} fontSize={'12px'}>
                  <AddFirmAdminButton>
                    Add Firm Administrator
                  </AddFirmAdminButton>
                </Grid>
              </Grid>
            ))}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default FirmSection;
