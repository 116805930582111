import { Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import { CardQuestionText, HomepageCardQuestionWrapper } from './styles';
import { MemoSpinner } from '@components/UIComponents/MemoAnimator';
import { EGetStartedQueries } from 'app/enums';
import { validArray } from '@common/validArray';

interface HomepageCardProps {
  handleCardClick: (title: string) => void;
  data: any;
}

const subtitlesMapping: { [key: string]: string } = {
  Viewpoints: 'Translate research into actionable client insights',
  Personalization: 'Create tailored client communications',
  'Investment Solutions': 'Discover, analyze and compare models and products',
};

function HomepageCard({
  handleCardClick,
  data,
}: HomepageCardProps): React.JSX.Element {
  const [hoveredIndexes, setHoveredIndexes] = useState<{
    [key: number]: { [key: number]: boolean };
  }>({});

  // Filtering data to include only the specific headers
  const filteredData = data
    ?.filter((item: any) => {
      return [
        EGetStartedQueries.Viewpoints,
        EGetStartedQueries.Personalization,
        EGetStartedQueries.InvestmentSolutions,
      ].includes(item.head);
    })
    .map((item: any) => ({
      ...item,
      subtitle: subtitlesMapping[item.head] || '',
    }));

  const handleMouseEnter = (cardIndex: number, quesIndex: number) => {
    setHoveredIndexes(prev => ({
      ...prev,
      [cardIndex]: { ...prev[cardIndex], [quesIndex]: true },
    }));
  };

  const handleMouseLeave = (cardIndex: number, quesIndex: number) => {
    setHoveredIndexes(prev => ({
      ...prev,
      [cardIndex]: { ...prev[cardIndex], [quesIndex]: false },
    }));
  };

  const handleKeyDown = (event: React.KeyboardEvent, callback: () => void) => {
    if (['Enter', ' '].includes(event.key)) {
      event.preventDefault();
      callback();
    }
  };

  return (
    <>
      {Array.isArray(filteredData) &&
        filteredData.length > 0 &&
        filteredData?.map((data, cardIndex) => {
          return (
            <Grid
              key={cardIndex}
              item
              container
              sm={5.8}
              md={3.9}
              lg={2.9}
              mt={2.6}
              rowGap={0}
              justifyContent={'flex-start'}
              alignItems={'center'}
              sx={{
                height: '280px',
                padding: '14px 16px',
                border: '1px solid #282829',
                cursor: 'pointer',
                background: '#282829',
                color: data.title ? '#fff' : '',
              }}
            >
              <Grid
                container
                height={'60px'}
                mb={1.25}
                alignContent={'flex-start'}
                gap={0.5}
              >
                <Grid
                  item
                  xs={12}
                  sx={{
                    fontSize: '16px',
                    color: '#fff',
                    fontWeight: 500,
                    letterSpacing: '1.2px',
                  }}
                >
                  {data?.head}
                </Grid>
                <Typography
                  sx={{
                    fontSize: '12px',
                    color: '#c0c0c5',
                  }}
                >
                  {data?.subtitle}
                </Typography>
              </Grid>
              <Grid
                item
                container
                xs={12}
                height={'190px'}
                alignContent={'flex-start'}
              >
                {validArray(data?.list) &&
                  data?.list?.map((question, quesIndex) => {
                    const isHovered =
                      hoveredIndexes[cardIndex]?.[quesIndex] || false;
                    return (
                      <HomepageCardQuestionWrapper
                        item
                        key={quesIndex}
                        container
                        xs={12}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                        columnGap={0.1}
                        tabIndex={0}
                        sx={{
                          borderTop: '1px solid #525252',
                        }}
                        onMouseEnter={() =>
                          handleMouseEnter(cardIndex, quesIndex)
                        }
                        onMouseLeave={() =>
                          handleMouseLeave(cardIndex, quesIndex)
                        }
                        hover={isHovered}
                        onClick={() => {
                          handleCardClick(question);
                        }}
                        onKeyDown={event =>
                          handleKeyDown(event, () => handleCardClick(question))
                        }
                      >
                        <Grid
                          item
                          container
                          alignItems={'center'}
                          justifyContent={'space-between'}
                          xs={1}
                        >
                          <MemoSpinner hover={isHovered} />
                        </Grid>
                        <Grid item container xs={10.9}>
                          <CardQuestionText
                            item
                            sx={{ color: isHovered ? '#fff' : '#c0c0c5' }}
                          >
                            {question}
                          </CardQuestionText>
                        </Grid>
                      </HomepageCardQuestionWrapper>
                    );
                  })}
              </Grid>
            </Grid>
          );
        })}
    </>
  );
}

export default HomepageCard;
