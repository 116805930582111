export const QUERY_TYPE = {
  CALCULATED_DATA: { value: 'Calculated Data', label: 'Calculated Data' },
  DESCRIPTIVE: { value: 'Descriptive', label: 'Descriptive' },
  SQL: { value: 'SQL', label: 'SQL' },
  ENHANCEMENT: { value: 'Enhancement', label: 'Enhancement' },
  ENHANCEMENT_WITH_TICKERS: {
    value: 'Enhancement With Tickers',
    label: 'Enhancement With Tickers',
  },
  FINSERA: { value: 'Finsera', label: 'Finsera' },

  CIO: { value: 'CIO', label: 'CIO' },
  VIEW_POINT: { value: 'View Point', label: 'View Point' },
  ENHANCEMENT_QUEUE: { value: 'Enhancement Queue', label: 'Enhancement Queue' },
};

export const OPTIONS_QUERY_TYPE = [
  QUERY_TYPE.CALCULATED_DATA,
  QUERY_TYPE.DESCRIPTIVE,
  QUERY_TYPE.SQL,
  QUERY_TYPE.ENHANCEMENT,
  QUERY_TYPE.ENHANCEMENT_QUEUE,
  QUERY_TYPE.ENHANCEMENT_WITH_TICKERS,
  QUERY_TYPE.FINSERA,
  QUERY_TYPE.CIO,
  QUERY_TYPE.VIEW_POINT,
];
