import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { CSVLink } from 'react-csv';
import { useQueryClient } from 'react-query';
import {
  Box,
  Button,
  TableContainer,
  Table,
  TableBody,
  TableFooter,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
  Typography,
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import moment from 'moment';
import { isEmpty } from 'utils/isEmpty';
import TestResultListSkeleton from './TestResultListSkeleton';
import BaseLineSwitch from './BaseLineSwitch';
import Row from './Row';
import { StyledTableRow, StyledTableCell, fontStyles } from './styles';
import { useRESTQuery } from 'utils/hooks';
import { getDateTime } from 'utils/date-time';
import { getSortedData } from 'utils/array';
import { COLUMNS_INITIAL, COLUMNS_LOADING } from './constants';
import { actions as globalActions } from 'app/features/Global/slice';
import { ToastTypes } from '@components/ToastAlert/types';
import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

function TestResultList(): React.JSX.Element {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const [columns, setColumns] = useState([
    ...COLUMNS_INITIAL,
    ...COLUMNS_LOADING,
  ]);
  const [rows, setRows] = useState<any>([]);
  const [queryData, setQueryData] = useState<Array<any>>([]);
  const [responseData, setResponseData] = useState<Array<any>>([]);
  const [csvData, setCsvData] = useState<Array<any>>([]);
  const [isQueryTestRun, setIsQueryTestRun] = React.useState(false);
  const [baseLineActive, setBaseLineActive] = React.useState('');
  const [runTimeIds, setRunTimeIds] = React.useState({});
  // const [runTimeOrder, setRunTimeOrder] = useState<Array<string>>([]);
  // console.log(runTimeOrder);

  const handleToastMsg = (message: string, type: ToastTypes = 'success') => {
    if (message === 'WENT_WRONG') {
      message = 'Something went wrong.';
      type = 'error';
    }
    dispatch(
      globalActions.displayToast({
        duration: 3000,
        toastType: type,
        toastMessage: message,
      }),
    );
  };

  useEffect(() => {
    if (!isEmpty(queryData) && !isEmpty(responseData)) {
      const responsesColumns: Array<any> = [];
      const queryRowsTemp = JSON.parse(JSON.stringify(queryData));
      let columnsTemp: Array<any> = [];
      let baseLineResponses: Array<any> = [];
      if (!isEmpty(runTimeIds) && baseLineActive) {
        const rtis = Object.keys(runTimeIds);
        const n = rtis.length;
        let i = rtis.findIndex(v => v === baseLineActive);
        while (i < n) {
          const tmp = rtis[i];
          rtis.splice(i, 1);
          rtis.unshift(tmp);
          i++;
        }
        rtis.forEach(rt => {
          const runTimeId = runTimeIds[rt] ? runTimeIds[rt] : '';
          responsesColumns.push({
            label: getDateTime(rt),
            value: rt,
            baseLineColumn: runTimeId ? true : false,
            baseLineActive: baseLineActive === rt ? true : false,
            id: runTimeId,
          });
        });
        columnsTemp = [...COLUMNS_INITIAL, ...responsesColumns];
        setColumns(columnsTemp);
        baseLineResponses = responseData[baseLineActive];
      }
      for (const key in responseData) {
        queryRowsTemp.forEach(row => {
          if (!row.results) {
            row['results'] = [];
          }
          const matchedResponse = responseData[key].find(
            x => x.queryId === row._id,
          );
          if (matchedResponse) {
            row[`${key}`] = matchedResponse.response;
            row['results'][`${key}`] = {
              response: matchedResponse.response,
              runTime: matchedResponse.runTime,
              resultTime: key,
              _id: matchedResponse._id,
              matchBaseline:
                matchedResponse.response ===
                baseLineResponses.find(
                  r => r.queryId === matchedResponse.queryId,
                )?.response,
            };
          } else {
            row[`${key}`] = ``;
            row['results'][`${key}`] = {
              response: ``,
              runTime: ``,
              resultTime: key,
              _id: '',
            };
          }
        });
      }
      setRows(queryRowsTemp);

      const csvDataTemp: Array<any> = [];
      let csvDataRow: Array<any> = [];
      columnsTemp.map(column => {
        csvDataRow.push(column.label);
      });
      csvDataTemp.push(csvDataRow);
      queryRowsTemp.map(row => {
        csvDataRow = [];
        columnsTemp.map(column => {
          csvDataRow.push(
            !isEmpty(row[column.value])
              ? column.value === 'query'
                ? row[column.value]
                : row[column.value]
              : 'NA',
          );
        });
        csvDataTemp.push(csvDataRow);
      });
      setCsvData(csvDataTemp);
    }
  }, [queryData, responseData]);

  const onErrorResponse = error => {
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.message &&
      typeof error.response.data.message === 'string'
    ) {
      handleToastMsg(error.response.data.message, 'error');
    } else {
      handleToastMsg('WENT_WRONG');
    }
  };

  const {
    //isLoading: isLoadingQueries,
    isFetching: isLoadingQueries,
    isError: isErrorQueries,
    // refetch: refetchQueries,
  } = useRESTQuery(
    ['testResultsListQueries'],
    {
      endpoint: `tests/queries`,
      queryParams: {},
      method: 'GET',
      headers: {
        'X-Tifin-Ai-Auth': localStorage.getItem('x-tifin-ai-token'),
      },
    },
    {
      retry: false,
      cacheTime: 0,
      //enabled: isFormSubmitted ? true : false,
      onSuccess: res => {
        if (!isEmpty(res) && !isEmpty(res.message)) {
          if (res.message === 'Success') {
            if (res.data && !isEmpty(res.data)) {
              const sortData = getSortedData(res.data, 'sortIdx', 'asc', true);
              setRows(sortData);
              setQueryData(sortData);
            } else {
              //remove dummy date-colum if response is empty
              setColumns([...COLUMNS_INITIAL]);
            }
          } else {
            handleToastMsg(res.message, 'error');
          }
        } else {
          handleToastMsg('WENT_WRONG');
        }
      },
      onError: error => {
        onErrorResponse(error);
      },
    },
  );

  const {
    isLoading: isLoadingResponses,
    // isError: isErrorResponses,
    // isSuccess: isSuccessResponses,
    // refetch: refetchResponses,
  } = useRESTQuery(
    ['testResultsListResponses'],
    {
      endpoint: `tests/responses`,
      queryParams: {},
      method: 'GET',
      headers: {
        'X-Tifin-Ai-Auth': localStorage.getItem('x-tifin-ai-token'),
      },
    },
    {
      retry: false,
      cacheTime: 0,
      onSuccess: async res => {
        if (!isEmpty(res) && !isEmpty(res.message)) {
          if (res.message === 'Success') {
            setBaseLineActive(res.baselineTime);
            if (!isEmpty(res.runTimeIds)) {
              setRunTimeIds(res.runTimeIds ? res.runTimeIds : {});
            }
            if (!isEmpty(res.data)) {
              setResponseData(res.data);
            }
          } else {
            handleToastMsg(res.message, 'error');
          }
        } else {
          handleToastMsg('WENT_WRONG');
        }
      },
      onError: error => {
        onErrorResponse(error);
      },
    },
  );

  // const { isLoading: isLoadingRun } =
  useRESTQuery(
    ['testResultListRund', isQueryTestRun],
    {
      endpoint: `tests/run`,
      method: 'POST',
      headers: {
        'X-Tifin-Ai-Auth': localStorage.getItem('x-tifin-ai-token'),
      },
    },
    {
      retry: false,
      cacheTime: 0,
      enabled: isQueryTestRun,
      onSuccess: res => {
        if (!isEmpty(res) && !isEmpty(res.message)) {
          if (res.message === 'Success') {
            handleToastMsg(res.data);
          } else {
            handleToastMsg(res.message, 'error');
          }
        } else {
          handleToastMsg('WENT_WRONG');
        }
        onCancelRun();
      },
      onError: error => {
        onErrorResponse(error);
        onCancelRun();
      },
    },
  );

  const onClickQueries = () => {
    navigate('/test/queries');
  };
  const onClickRun = () => {
    setIsQueryTestRun(true);
  };
  const onCancelRun = () => {
    setIsQueryTestRun(false);
  };

  const onClickDownload = () => {
    const idDownloadCsv = document.getElementById('idDownloadCsvTestResults');
    if (idDownloadCsv) {
      idDownloadCsv.click();
    }
  };

  const onRefresh = () => {
    queryClient.invalidateQueries({ queryKey: ['testResultsListResponses'] });
    queryClient.invalidateQueries({ queryKey: ['testResultsListQueries'] });
    //refetchQueries();
    //refetchResponses();

    setColumns([...COLUMNS_INITIAL, ...COLUMNS_LOADING]);
    setRows([]);
    setQueryData([]);
    setResponseData([]);
    setCsvData([]);
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          flexGrow: 1,
        }}
      >
        <Box>
          <Box
            sx={{
              gap: 1.5,
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'right',
              marginBottom: '20px',
            }}
          >
            <Tooltip title="Load results again" arrow>
              <Button
                variant="contained"
                color="primary"
                startIcon={<RefreshOutlinedIcon />}
                sx={{
                  visibility: !isEmpty(rows) ? 'visible' : 'hidden',
                }}
                onClick={() => onRefresh()}
              >
                {'Refresh'}
              </Button>
            </Tooltip>
            <Button
              variant="contained"
              color="primary"
              startIcon={<AccessAlarmsIcon />}
              sx={{
                visibility: !isEmpty(rows) ? 'visible' : 'hidden',
              }}
              onClick={() => onClickRun()}
            >
              {'Run Now'}
            </Button>
            <Button
              variant="contained"
              color="primary"
              startIcon={<DownloadIcon />}
              sx={{
                visibility: !isEmpty(rows) ? 'visible' : 'hidden',
              }}
              onClick={() => onClickDownload()}
            >
              {'Download'}
            </Button>
            <Button
              variant="contained"
              startIcon={<FormatListBulletedIcon />}
              color="primary"
              onClick={() => onClickQueries()}
            >
              {'Queries'}
            </Button>
          </Box>
          <CSVLink
            data={csvData}
            filename={`TIFIN-AI_Test_Results-${moment().format(
              'DD-MM-YYYY',
            )}.csv`}
            id={'idDownloadCsvTestResults'}
            style={{
              visibility: 'hidden',
              opacity: 0,
              width: 0,
              height: 0,
            }}
          />
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            height: '100%',
            p: '0px 0 0 0px',
          }}
        >
          {(isLoadingQueries || isLoadingResponses) && (
            <TestResultListSkeleton />
          )}

          {/* {!isLoadingQueries && isErrorQueries && <WentWrong />} */}

          {!isLoadingQueries && !isErrorQueries && (
            <TableContainer
              component={Paper}
              sx={{
                maxHeight: 'calc(100vh - 124px)', //if sticky-header requred
                boxShadow: 'none',
                background: 'transparent',
              }}
            >
              <Table
                sx={{ width: '100%' }}
                stickyHeader
                aria-label="sticky table"
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell
                      sx={{ minWidth: '30px !important' }}
                      align="left"
                    >
                      &nbsp;
                    </StyledTableCell>
                    {columns.map((column, columnIndex) => {
                      return (
                        <StyledTableCell
                          align="left"
                          key={`th-${columnIndex}-${column.value}`}
                        >
                          {column.label}
                          {column.baseLineColumn && (
                            <React.Fragment>
                              <br />
                              <BaseLineSwitch
                                index={columnIndex}
                                runId={column.id}
                                defaultValue={
                                  column.value === baseLineActive ? true : false
                                }
                                onSuccess={onRefresh}
                                handleToastMsg={handleToastMsg}
                              />
                            </React.Fragment>
                          )}
                        </StyledTableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* {runTimeOrder.map((runTime, rowIndex) => { */}
                  {rows.map((runTime, rowIndex) => {
                    return (
                      <Row
                        key={rowIndex}
                        index={rowIndex}
                        row={runTime}
                        columns={columns}
                        baseLineActive={baseLineActive}
                      />
                    );
                  })}
                </TableBody>
                {isEmpty(rows) && (
                  <TableFooter>
                    <StyledTableRow key={'test-results-table-foot'}>
                      <StyledTableCell
                        colSpan={columns.length + 1}
                        align="center"
                        sx={{
                          '&.MuiTableCell-root': {
                            color: '#222',
                          },
                        }}
                      >
                        <Typography
                          component={'div'}
                          sx={{
                            color: '#fff',
                            ...fontStyles,
                          }}
                        >{`Test results not found`}</Typography>
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableFooter>
                )}
              </Table>
            </TableContainer>
          )}
        </Box>
      </Box>
    </React.Fragment>
  );
}

export default TestResultList;
