import React from 'react';
import { Grid, styled } from '@mui/material';

export const BulletWrapper = styled(Grid)`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  ul {
    color: #000;
    padding: 0 0 0 15px;
  }
  p {
    color: #222124;
  }
`;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function BulletPointRenderer({ data }: { data: any }) {
  return (
    <BulletWrapper container>
      <Grid item>
        <p>{data?.description}</p>
      </Grid>
      <ul>
        {Array.isArray(data.bullet_points) &&
          data?.bullet_points.map(item => (
            <li key={item}>
              <p>{item}</p>
            </li>
          ))}
      </ul>
    </BulletWrapper>
  );
}

export default BulletPointRenderer;
