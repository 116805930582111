import { PrimaryButton } from '@components/Button';
import MemoDeleteIcon from '@icons/delete';
import { CircularProgress, Grid } from '@mui/material';
import React, { useState } from 'react';
import Input from '@components/Input';
import { updateViewpoint } from './mutate';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { actions as globalActions } from 'app/features/Global/slice';
import { EditWrapper } from './styles';
import { handleError } from '@common/error';
import { StyledCard } from '@pages/Profile/styles';
import media from '@images/new-media.svg';
import {
  AcceptedFileTypeLabelDiv,
  FileUploadContainer,
  StyledModalInput,
  UploadFileLabel,
  UploadFileLabelContainer,
} from '@pages/SuperAdminPage/styles';
import viewpointImg from '@images/viewpoint_img.svg';

interface EditModalProps {
  description: string;
  questions: string[];
  handleClose: any;
  id: string;
  setShowSuccessModal: React.Dispatch<boolean>;
  imgUrl: string;
}

function EditModal({
  description: initialDescription,
  questions: initialQuestions,
  handleClose,
  id,
  imgUrl,
  setShowSuccessModal,
}: EditModalProps): React.JSX.Element {
  const { mutate: updateMutate, isLoading } = useMutation(updateViewpoint);
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const [description, setDescription] = useState<string>(initialDescription);
  const [questions, setQuestions] = useState<string[]>(initialQuestions);

  const [uploadedImageUrl, setUploadedImageUrl] = useState<string | null>(null);
  const [logoFile, setLogoFile] = useState<File | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleDelete = (index: number) => {
    const updatedQuestions = [...questions];
    updatedQuestions.splice(index, 1);
    setQuestions(updatedQuestions);
  };

  const handleSubmit = () => {
    const formData = new FormData();
    formData.append('id', id);
    formData.append('summary', description);
    formData.append('advisorQuestions', JSON.stringify(questions));
    if (logoFile) {
      formData.append('image', logoFile);
    }

    updateMutate(formData, {
      onSuccess: res => {
        handleClose();
        setShowSuccessModal(true);
        queryClient.invalidateQueries('extract-viewpoint');
        dispatch(
          globalActions.displayToast({
            duration: 3000,
            toastType: 'success',
            toastMessage: res?.message,
          }),
        );
      },
      onError: (error: any) => {
        handleError(dispatch, error);
      },
    });
  };

  const handleFileUpload = e => {
    const uploadedFile = e.target.files?.[0];

    if (uploadedFile) {
      // Getting the file extension
      const fileName = uploadedFile.name;
      const extension = fileName.split('.').pop()?.toLowerCase();
      const fileSize = uploadedFile.size / 1024;

      // Check if the file extension is allowed (.png, .jpg)
      if (!['png', 'jpg', 'jpeg'].includes(extension)) {
        setErrorMessage(
          'Invalid file type. Please select a .png or .jpg or .jpeg file.',
        );
        return; // Exit the function if file type is invalid
      }

      const fileSizeInKb = fileSize;
      if (fileSizeInKb > 500) {
        setErrorMessage(
          'File size exceeds 500 kb. Please select a smaller file.',
        );
      } else {
        setLogoFile(uploadedFile);
        setErrorMessage(null);

        const reader = new FileReader();
        reader.onload = () => {
          setUploadedImageUrl(reader.result as string);
        };
        reader.readAsDataURL(uploadedFile);
      }
    }
  };

  return (
    <EditWrapper
      container
      rowGap={2}
      sx={{ overflowY: 'auto', padding: '16px' }}
    >
      <Grid item container xs={12} direction="column" rowGap={2}>
        <Grid item>
          <p className="label">Viewpoint Description</p>
        </Grid>
        <Grid item>
          <textarea
            cols={30}
            rows={6}
            className="text-area"
            value={description}
            onChange={e => setDescription(e.target.value)}
            style={{ width: '100%' }}
          />
        </Grid>
      </Grid>

      <Grid item container xs={12} rowGap={2} py={2} pb={0}>
        <Grid item xs={12}>
          <p className="title">List of Questions</p>
        </Grid>
        {questions?.map((item, index) => (
          <Grid
            item
            container
            key={index}
            columnSpacing={1}
            alignItems="center"
            wrap="nowrap"
          >
            <Grid item xs={12} sm={11}>
              <Input
                className="custom-input"
                value={item}
                onChange={e => {
                  const updatedQuestions = [...questions];
                  updatedQuestions[index] = e.target.value;
                  setQuestions(updatedQuestions);
                }}
              />
            </Grid>
            <Grid
              item
              xs={2}
              sm={1}
              onClick={() => handleDelete(index)}
              className="icon"
            >
              <MemoDeleteIcon />
            </Grid>
          </Grid>
        ))}
      </Grid>

      <Grid item container xs={12} rowGap={2}>
        <Grid item xs={12}>
          <p className="title">Upload Image*</p>
        </Grid>
        <Grid
          item
          container
          xs={12}
          justifyContent="space-between"
          columnGap={1}
          alignItems="center"
          flexWrap="nowrap"
        >
          <Grid item xs={4} sm={3}>
            <StyledCard
              sx={{
                margin: '0px',
                width: '100%',
                border: '1px solid #e4e7ec',
                '&.MuiCard-root': {
                  borderRadius: '6px',
                  boxShadow: 'none',
                },
                maxHeight: '140px',
                overflow: 'hidden',
              }}
            >
              <img
                src={uploadedImageUrl || imgUrl || viewpointImg}
                alt="Uploaded image"
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                }}
              />
            </StyledCard>
          </Grid>
          <FileUploadContainer
            item
            container
            xs={8}
            sm={9}
            justifyContent="center"
            alignItems="center"
            sx={{
              padding: '5px',
              height: '140px',
              background: '#F6F4FC',
            }}
          >
            <UploadFileLabelContainer htmlFor="uploadLogoInput">
              <StyledModalInput
                type="file"
                id="uploadLogoInput"
                inputProps={{ accept: '.png, .jpg, .jpeg' }}
                onChange={handleFileUpload}
              />
              <img src={media} alt="Media image" />
              <UploadFileLabel
                style={{
                  fontSize: '14px',
                  padding: '0px',
                  textAlign: 'center',
                }}
              >
                <span style={{ fontWeight: 'bold', color: '#282829' }}>
                  Click to upload Viewpoint Image
                </span>{' '}
                <span style={{ color: '#86858B' }}>&nbsp;or drag and drop</span>
              </UploadFileLabel>
              {errorMessage && (
                <Grid item xs={12}>
                  <p
                    style={{
                      color: 'red',
                      fontSize: '14px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {errorMessage}
                  </p>
                </Grid>
              )}
            </UploadFileLabelContainer>
          </FileUploadContainer>
        </Grid>
        <Grid item container xs={12} justifyContent="space-between" mt={1}>
          <Grid item>
            <AcceptedFileTypeLabelDiv
              sx={{ color: '#535256', fontSize: '12px', textAlign: 'center' }}
            >
              Supported formats: .PNG, .JPG, .JPEG
            </AcceptedFileTypeLabelDiv>
          </Grid>
          <Grid item>
            <AcceptedFileTypeLabelDiv
              sx={{ color: '#535256', fontSize: '12px', textAlign: 'center' }}
            >
              Maximum size: 500kb
            </AcceptedFileTypeLabelDiv>
          </Grid>
        </Grid>
      </Grid>

      <Grid item container spacing={2} mt={2}>
        <Grid item xs={12} sm={6}>
          <PrimaryButton
            variant="outlined"
            className="button"
            onClick={handleClose}
            fullWidth
          >
            Cancel
          </PrimaryButton>
        </Grid>
        <Grid item xs={12} sm={6}>
          <PrimaryButton
            className="button"
            onClick={handleSubmit}
            disabled={isLoading || !logoFile}
            fullWidth
          >
            {isLoading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              'Submit'
            )}
          </PrimaryButton>
        </Grid>
      </Grid>
    </EditWrapper>
  );
}

export default EditModal;
