import React, { useState } from 'react';
import {
  ActionCardWrapper,
  ButtonGrid,
  CardContentContainer,
  ConnectGrid,
  MessageGrid,
  SuggestedCardBody,
} from './styles';
import MemoArchived from '@icons/Archived';
import MemoYellowBulb from '@icons/YellowBulb';
import { Grid, IconButton, Menu, MenuItem } from '@mui/material';
import { CardLabel } from '@components/SuggestionCard/styles';
import PriorityIcon from '@icons/PriorityIcon';
import { ParagraphBoldtText, ParagraphLightText } from '@styles/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useNavigate } from 'react-router-dom';

interface CioCardProps {
  data: any;
  filename?: string;
  isArchived?: boolean;
  triggerQuery?: (query: string) => void;
  handleDrawerOpen?: (implementationPlan: any) => void;
  clientCount: number;
}

function CioCard({
  data,
  filename,
  triggerQuery = () => {},
  isArchived = false,
  clientCount,
}: CioCardProps): React.JSX.Element {
  console.log('filename for hover', filename);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  const handleMenuOpen = (
    event: React.MouseEvent<HTMLElement>,
    index: number,
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedIndex(index);
  };

  const handleButtonClick = (query: string | undefined) => {
    triggerQuery(query || ''); // Provide a default value for query
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedIndex(null);
  };

  const getDropdownOptions = item => {
    if (Array.isArray(item?.advisorQuestions)) {
      return item?.advisorQuestions[0];
    }
    return item?.advisorQuestions;
  };

  return (
    <CardContentContainer container>
      <SuggestedCardBody item container columnGap={2} rowGap={2}>
        {data.map((item, index) => {
          if (item === null) return null; // Skip mapping if item has no keys
          return (
            <Grid key={index} item container xs={5.8}>
              <ActionCardWrapper item container key={index}>
                <Grid
                  item
                  container
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  sx={{
                    padding: '6px',
                    background: isArchived
                      ? 'var(--Neutral-400, #98A2B3)'
                      : '#333',
                    borderRadius: '4px',
                    marginBottom: '10px',
                  }}
                >
                  <Grid item container alignItems={'center'} xs={11}>
                    <Grid item mt={0.2}>
                      <PriorityIcon />
                    </Grid>
                    <Grid item>
                      <CardLabel>{item?.focus}</CardLabel>
                    </Grid>
                  </Grid>
                  {!isArchived && (
                    <Grid item>
                      <IconButton
                        sx={{
                          padding: 0,
                          cursor: 'pointer',
                        }}
                      >
                        <MemoArchived />
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
                {item?.summary && (
                  <MessageGrid
                    item
                    container
                    style={{
                      height: '180px',
                    }}
                    direction={'column'}
                    justifyContent={'space-between'}
                  >
                    <Grid item>
                      {item?.summary && (
                        <ParagraphBoldtText fontSize={'13px'}>
                          <span style={{ height: '100px' }}>
                            {item?.summary}
                          </span>
                        </ParagraphBoldtText>
                      )}
                    </Grid>
                    {item?.no_clients !== 0 ? (
                      <Grid item>
                        <ParagraphLightText color={'#818181'}>
                          {item?.no_clients}
                          {' clients can benefit from this'}
                        </ParagraphLightText>
                      </Grid>
                    ) : (
                      <></>
                    )}
                  </MessageGrid>
                )}

                <ButtonGrid
                  container
                  justifyContent={'space-between'}
                  mt={1.25}
                  alignItems={'center'}
                >
                  <Grid
                    item
                    xs={11}
                    onClick={event => {
                      event.stopPropagation();
                      handleButtonClick(getDropdownOptions(item));
                    }}
                  >
                    <p className="text">{getDropdownOptions(item)}</p>
                  </Grid>
                  <Grid
                    item
                    onClick={event => {
                      event.stopPropagation();
                      handleMenuOpen(event, index);
                    }}
                  >
                    <KeyboardArrowDownIcon />
                  </Grid>

                  {selectedIndex === index && (
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleMenuClose}
                      sx={{
                        marginTop: '5px',
                        width: '70%',
                      }}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                    >
                      {Array.isArray(item?.advisorQuestions) ? (
                        item?.advisorQuestions.map((option, i) => (
                          <MenuItem
                            key={i}
                            onClick={() => {
                              handleButtonClick(option);
                              handleMenuClose();
                            }}
                            sx={{ fontSize: '12px' }}
                          >
                            {option}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem
                          onClick={() => {
                            handleButtonClick(item?.advisorQuestions);
                            handleMenuClose();
                          }}
                          sx={{ fontSize: '12px' }}
                        >
                          {item?.advisorQuestions}
                        </MenuItem>
                      )}
                    </Menu>
                  )}
                </ButtonGrid>
              </ActionCardWrapper>
              {clientCount === 0 ? (
                <ConnectGrid
                  item
                  container
                  xs={12}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  onClick={() => navigate('/integrations')}
                  style={{ marginTop: '-5px' }}
                >
                  <Grid
                    item
                    container
                    xs={10}
                    alignItems={'center'}
                    columnGap={1}
                  >
                    <Grid item>
                      <MemoYellowBulb />
                    </Grid>
                    <Grid item container xs={10} justifyContent={'flex-start'}>
                      <p className="text">
                        Connect client data to view impacts on your book
                      </p>
                    </Grid>
                  </Grid>

                  <Grid item container xs={2} justifyContent={'flex-end'}>
                    <KeyboardArrowRightIcon style={{ color: '#fff' }} />
                  </Grid>
                </ConnectGrid>
              ) : (
                <></>
              )}
            </Grid>
          );
        })}
      </SuggestedCardBody>
    </CardContentContainer>
  );
}

export default CioCard;
