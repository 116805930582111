import React, { useEffect, useState } from 'react';
import { Fade, Grid } from '@mui/material';
import { DashboardWrapper } from './styles';
import SageBlackLogo from '@images/LogoTour.svg';
import SampleQuestions from './reusable/SampleQuestions';
import { TOUR_SAMPLE_QUESTIONS } from './utils';
import TextInfo from '@pages/Tour/reusable/TextInfo';
import TourContent from './TourContent';
import { TourComponentProps } from './types';

const InitialComponent = () => {
  const [showWelcome, setShowWelcome] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setShowWelcome(false);
    }, 2000);
  }, []);
  return (
    <DashboardWrapper container direction={'column'}>
      <Fade timeout={1500} in={showWelcome}>
        <Grid item>
          <p className="grey-text">Welcome to Sage</p>
        </Grid>
      </Fade>
      <Fade timeout={1000} in={showWelcome}>
        <Grid item>
          <p className="bold-grey-text">Here’s a quick tour!</p>
        </Grid>
      </Fade>
      <Fade timeout={1000} in={!showWelcome}>
        <Grid item>
          <p className="bold-grey-text">
            <img
              src={SageBlackLogo}
              alt="Sage black image"
              style={{ marginLeft: '50%' }}
              width={'50%'}
              height={'30%'}
            />
          </p>
        </Grid>
      </Fade>
    </DashboardWrapper>
  );
};

const FirstComponent = () => {
  return (
    <TextInfo
      textContent={
        'Let’s begin with where we are right now – This is the main page for our Conversations. Here, you can ask Sage questions about markets, your book of business, client portfolios and more.'
      }
    />
  );
};

const SecondComponent = () => {
  const [showQuestions, setShowQuestions] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setShowQuestions(true);
    }, 2000);
  }, []);
  return (
    <>
      {' '}
      <TextInfo textContent={'To get started, here is a list of questions:'} />
      <Fade timeout={1500} in={showQuestions}>
        <div>
          <SampleQuestions data={TOUR_SAMPLE_QUESTIONS} />
        </div>
      </Fade>
    </>
  );
};

const ThirdComponent = ({
  setShowFooterArrow,
}: {
  setShowFooterArrow: any;
}) => {
  useEffect(() => {
    setTimeout(() => {
      setShowFooterArrow(true);
    }, 2000);
  }, []);
  return (
    <>
      <TextInfo
        textContent={`Display popular questions anytime by hovering over the question mark and clicking "What can I ask Sage?"`}
      />
    </>
  );
};

function Dashboard({
  setAwaitingNavItem,
  setRouteState,
  endTour,
}: TourComponentProps): React.JSX.Element {
  const [showFooterArrow, setShowFooterArrow] = React.useState(false);
  // eslint-disable-next-line react/jsx-key
  const components = [
    // eslint-disable-next-line react/jsx-key
    { component: <InitialComponent />, timeToShow: 4000 },
    // eslint-disable-next-line react/jsx-key
    { component: <FirstComponent />, timeToShow: 5000 },
    // eslint-disable-next-line react/jsx-key
    { component: <SecondComponent />, timeToShow: 15000 },
    // eslint-disable-next-line react/jsx-key
    {
      component: <ThirdComponent setShowFooterArrow={setShowFooterArrow} />,
      timeToShow: 7500,
      hoverQuestionMark: true,
      nextComponent: 'Current Markets',
    },
  ];

  return (
    <TourContent
      setAwaitingNavItem={setAwaitingNavItem}
      setRouteState={setRouteState}
      endTour={endTour}
      components={components}
      showFooterArrow={showFooterArrow}
      margin={-10}
    />
  );
}

export default Dashboard;
