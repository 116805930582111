/* eslint-disable react/prop-types */

import React from 'react';

function StarIcon(props: any): React.JSX.Element {
  const marginLeft = '20px';
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      style={{ marginLeft }}
    >
      <path
        fill={props.fill || '#7B73E4'}
        d="M19.519 14.55a.416.416 0 00-.095-.156l-6.859-6.859a.42.42 0 00-.136-.091c-.004-.002-.01-.001-.013-.003a.344.344 0 00-.137-.029l-.005.001h-.006a.42.42 0 00-.297.122l-6.858 6.859a.472.472 0 00.01.624l6.86 6.86c.038.038.084.069.135.09.005.002.01.001.014.003.043.019.09.029.136.029l.006-.001.005.001a.42.42 0 00.297-.123l6.858-6.859a.412.412 0 00.085-.467zm-2.985.301a.421.421 0 01-.091.136l-2.465 2.465a.496.496 0 00-.082.124l-1.32 1.32a.42.42 0 01-.594 0l-1.384-1.384a.493.493 0 00-.04-.06l-2.464-2.465a.402.402 0 01.01-.563l2.465-2.464a.5.5 0 00.082-.124l1.32-1.32a.42.42 0 01.594 0L13.95 11.9c.012.021.025.04.04.06l2.465 2.465a.387.387 0 01.08.426zm-2.945-.383a.345.345 0 01-.074.376l-1.003 1.002a.344.344 0 01-.488 0l-1.002-1.002a.345.345 0 010-.488l1.002-1.002a.344.344 0 01.488 0l1.003 1.002c.032.032.057.07.074.112zm4.56-6.751l1.002 1.002a.345.345 0 010 .489L18.15 10.21a.344.344 0 01-.488 0l-1.003-1.002a.345.345 0 010-.489l1.003-1.002a.344.344 0 01.488 0zm-4.411-1.43a.346.346 0 010-.489l1.002-1.002a.346.346 0 01.488 0l1.002 1.002a.346.346 0 010 .489l-1.002 1.002a.346.346 0 01-.488 0l-1.002-1.002zm2.695-2.695a.346.346 0 010-.489l1.002-1.002a.346.346 0 01.488 0l1.002 1.002a.346.346 0 010 .489l-1.002 1.002a.344.344 0 01-.488 0l-1.002-1.002z"
      ></path>
    </svg>
  );
}

const MemoNoData = React.memo(StarIcon);
export default MemoNoData;
