import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { GreyLabelText } from '@styles/Typography';
import { Container } from '@mui/material';
import { ICioListProps } from './types';
import CIOCardDetails from './CIOCardDetail';

function CIOCardComponent({
  title,
  desc,
  cioList,
}: ICioListProps): React.JSX.Element {
  const primary = cioList.filter(y => y.primaryFocus === true);
  const otherConsiderations = cioList.filter(y => y.primaryFocus === false);

  return (
    <div>
      <Container maxWidth="sm" sx={{ padding: '0 !important' }}>
        <Card
          sx={{
            border: '1px solid #e4e7ec',
            boxShadow: 'none',
            borderRadius: '8px',
          }}
        >
          <CardContent sx={{ background: '#fff' }}>
            <GreyLabelText fontSize="18px" color="#000">
              {title}
            </GreyLabelText>
            <GreyLabelText mt={1}>{desc}</GreyLabelText>
            <Divider style={{ margin: '20px 0' }} />
            <GreyLabelText fontSize="16px" color="#000">
              Implementation
            </GreyLabelText>
            <GreyLabelText mt={2} mb={0.5}>
              Primary Focus
            </GreyLabelText>
            <Grid container spacing={2}>
              {primary.map((card, index) => (
                <Grid item xs={12} key={index}>
                  <CIOCardDetails
                    displayTag={false}
                    card={card}
                    key={`card-${index}`}
                  ></CIOCardDetails>
                </Grid>
              ))}
            </Grid>
            <Divider style={{ margin: '20px 0' }} />
            <GreyLabelText mt={2} mb={0.5}>
              Other Considerations
            </GreyLabelText>
            <Grid container spacing={2}>
              {otherConsiderations.map((card, index) => (
                <Grid item xs={12} key={index}>
                  <CIOCardDetails
                    displayTag={true}
                    card={card}
                    key={`card-${index}`}
                  ></CIOCardDetails>
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </div>
  );
}

export default CIOCardComponent;
