import { Grid } from '@mui/material';
import styled from 'styled-components';

// question cards

export const QuestionsCardsContainer = styled.div`
  box-sizing: border-box;
  padding: 13px;
  width: 100%;
  background: #f5f5f5;
`;

export const QuestionsCardsTitle = styled.h2`
  display: flex;
  align-items: center;
  margin: 0 0 10px 6px;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #4f4f4f;

  & img {
    margin-right: 8px;
  }
`;

// question card

export const QuestionsCardContainer = styled(Grid)`
  position: relative;
  width: 100%;
  height: 100%;
  background: #000;
  font-family: Inter, sans-serif;
`;

export const QuestionsCardTitle = styled.h3`
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: #fff;
  transition: all 0.75s cubic-bezier(0.53, -0.19, 0.49, 1.16);

  ${QuestionsCardContainer}:hover & {
    top: 20px;
    left: 20px;
    transform: translate(0, 0);
    font-size: 12px;
    color: #a1a1a1;
  }

  & img {
    margin-right: 5px;
    width: 20px;
    height: 20px;
    transition: all 0.75s cubic-bezier(0.53, -0.19, 0.49, 1.16);

    ${QuestionsCardContainer}:hover & {
      width: 14px;
      height: 14px;
    }
  }
`;

// question items

export const QuestionsCardItems = styled.ul`
  opacity: 0;
  margin: 0;
  padding: 45px 20px 10px 20px;
  transition: opacity 1s;
  list-style: none;
  cursor: pointer;

  ${QuestionsCardContainer}:hover & {
    opacity: 1;
  }

  &::after {
    // On touch devices you can click on the LIs without hovering first
    // We created this layer to prevent clicking on the LIs
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: width 0.75s;
  }

  ${QuestionsCardContainer}:hover &::after {
    // We hide the layer when we are on hover
    width: 0;
  }
`;

export const QuestionsCardItem = styled.li`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding: 5px 0;
  font-size: 14px;
  color: #fff;

  &:hover {
    color: #1bc29a;
  }
`;

export const QuestionsCardItemMarker = styled.span`
  display: inline-block;
  font-size: 18px;

  ${QuestionsCardItem}:hover & {
    transition: transform 30s linear;
    transform: rotate(3600deg);
    color: #1bc29a;
  }
`;

export const QuestionsCardItemText = styled.a`
  width: 100%;
  padding-left: 8px;
`;
