import instance from 'utils/instances';
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const uploadProfilePic = async (payload: any) => {
  const createResponse = await instance.post('/user/profilePicture', payload, {
    headers: {
      'x-tifin-ai-auth': localStorage.getItem('x-tifin-ai-token'),
      'content-type': undefined,
    },
  });
  return createResponse?.data;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const updateAddress = async (payload: any) => {
  const createResponse = await instance.post('/user/userAddress', payload, {
    headers: {
      'x-tifin-ai-auth': localStorage.getItem('x-tifin-ai-token'),
    },
  });
  return createResponse?.data;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const downloadReport = async (id: any) => {
  const response = await instance.get(`/documents/download?id=${id}`, {
    headers: {
      'x-tifin-ai-auth': localStorage.getItem('x-tifin-ai-token'),
    },
  });

  return response?.data;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const downloadPrivateDocuments = async (fileName: any) => {
  const response = await instance.get(
    `/documents/privateDocument?url=${fileName}.pdf`,
    {
      headers: {
        'x-tifin-ai-auth': localStorage.getItem('x-tifin-ai-token'),
      },
    },
  );

  return response?.data;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const updateExpertMode = async () => {
  const response = await instance.put(
    '/advisor/expertMode',
    {},
    {
      headers: {
        'x-tifin-ai-auth': localStorage.getItem('x-tifin-ai-token'),
      },
    },
  );

  return response?.data;
};
