import instance from 'utils/instances';
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const uploadCSV = async (payload: any) => {
  const createResponse = await instance.post('/viewpoints/', payload, {
    headers: {
      'x-tifin-ai-auth': localStorage.getItem('x-tifin-ai-token'),
      'content-type': undefined,
    },
  });
  return createResponse?.data;
};
