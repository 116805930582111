import React from 'react';
import { AssetContainer } from '@components/ChatContent/styles';
import AssetAllocation from '@pages/ClientsV2/AssetAllocation';
import { Grid } from '@mui/material';
import { ComparisonContainerHeader } from '@pages/ClientsV2/styles';
import { IAllocationBarChartProps } from '../types';
import { AllocationType } from 'app/enums';

const getTitle = (name: string): string => {
  const titles: Record<string, string> = {
    [AllocationType.AssetAllocation]: 'Asset Mix Comparison',
    [AllocationType.SectorAllocation]: 'Sector Mix Comparison',
    [AllocationType.RegionAllocation]: 'Region Mix Comparison',
  };

  return titles?.[name] || '';
};

const AllocationBarChartChat: React.FC<IAllocationBarChartProps> = ({
  content,
}) => {
  return (
    <AssetContainer item xs={12} sm={12}>
      <Grid
        item
        container
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        gap={1}
      >
        <ComparisonContainerHeader>
          <strong>{getTitle(content?.name)}</strong>
        </ComparisonContainerHeader>

        <AssetAllocation
          currentPortfolioData={content?.currentPortfolio}
          proposedPortfolioData={content?.recommendedPortfolio}
          currentPortfolioName={
            content?.currentPortfolioName || 'Current Portfolio'
          }
          recommendedPortfolioName={
            content?.recommendedPortfolioName || 'Proposed Portfolio'
          }
          showProposed={true}
          showCurrent={true}
          hideDropdown={true}
        />
      </Grid>
    </AssetContainer>
  );
};

export default AllocationBarChartChat;
