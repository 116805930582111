import instance from 'utils/instances';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const uploadQuestions = async payload => {
  const createResponse = await instance.post(
    '/enterprise/enterpriseQuestions',
    payload,
    {
      headers: {
        'x-tifin-ai-auth': localStorage.getItem('x-tifin-ai-token'),
        'content-type': undefined,
      },
    },
  );
  return createResponse?.data;
};
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const updateQuestions = async payload => {
  const createResponse = await instance.put(
    `/enterprise/enterpriseQuestions?id=${payload.id}`,
    payload,
    {
      headers: {
        'x-tifin-ai-auth': localStorage.getItem('x-tifin-ai-token'),
        'content-type': undefined,
      },
    },
  );
  return createResponse?.data;
};
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const deleteQuestions = async payload => {
  const createResponse = await instance.delete(
    `/enterprise/enterpriseQuestions/?${payload.key}=${payload.value}`,
    {
      headers: {
        'x-tifin-ai-auth': localStorage.getItem('x-tifin-ai-token'),
        'content-type': undefined,
      },
    },
  );
  return createResponse?.data;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const publishQuestions = async payload => {
  const createResponse = await instance.patch(
    `/enterprise/enterpriseQuestions/${payload.action}/?id=${payload.id}`,
    payload,
    {
      headers: {
        'x-tifin-ai-auth': localStorage.getItem('x-tifin-ai-token'),
        'content-type': undefined,
      },
    },
  );
  return createResponse?.data;
};
