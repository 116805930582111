/* eslint-disable react/prop-types */

import React from 'react';

function ClientsIcon(): React.JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 24 25"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 17.363a3.75 3.75 0 100-7.5 3.75 3.75 0 000 7.5zM18.375 11.363a5.608 5.608 0 014.5 2.25M1.125 13.613a5.608 5.608 0 014.5-2.25M6.6 20.738a6.01 6.01 0 0110.8 0M5.625 11.363A3 3 0 118.57 7.8M15.431 7.8a3 3 0 112.944 3.563"
      ></path>
    </svg>
  );
}

const MemoClientsIcon = React.memo(ClientsIcon);
export default MemoClientsIcon;
