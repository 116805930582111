import React, { useState } from 'react';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import {
  StyledLoginWrapper,
  StyledLogoContainer,
  StyledLogoWrapper,
  StyledModalCustodialTitle,
  StyledModalTextContainer,
  StyledTextModal,
} from './styles';
import CloseIcon from '@mui/icons-material/Close';
import SageIcon from '@icons/OnlySage';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import { Button, Grid, useMediaQuery, useTheme } from '@mui/material';
import { ToastTypes } from '@components/ToastAlert/types';
import { useRESTQuery } from '@hooks/useRESTQuery';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { actions as globalActions } from 'app/features/Global/slice';
// import { regExpNumber } from '@common/string';
import Input from '@components/UIComponents/Input';
import { ECustodians } from 'app/enums';
import { useQueryClient } from 'react-query';

export const useStyles = makeStyles({
  drawer: {
    width: '100%',
    '@media (min-width: 700px)': {
      width: 600,
    },
  },
  drawerPaper: {
    width: '100%',
    '@media (min-width: 700px)': {
      width: 720,
    },
  },
  root: {
    display: 'flex',
  },
});

interface CustodianDrawerProps {
  open: boolean;
  setOpen: any;
  modalData: any;
  onConnectSuccess?: any;
}

const CustodianDrawer: React.FC<CustodianDrawerProps> = ({
  open,
  setOpen,
  modalData,
  onConnectSuccess = () => {},
}) => {
  const initialAccNumber = Array(8).fill('');

  const classes = useStyles();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [connectApi, setConnectApi] = useState<any>(false);
  const [accountNumbers, setAccountNumbers] =
    useState<string[]>(initialAccNumber);

  const handleClose = () => setOpen(false);
  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    i: number,
  ) => {
    const newAccountNumbers = [...accountNumbers];
    newAccountNumbers[i] = event.target.value;
    setAccountNumbers(newAccountNumbers);
  };

  const isSubmitDisabled = () => accountNumbers.every(item => item === '');

  const handleButtonClick = () => {
    setConnectApi(true);
    refetchConnectionApi();
  };

  const handleToastMsg = (message: string, type: ToastTypes = 'success') => {
    if (message === 'WENT_WRONG') {
      message = 'Something went wrong.';
      type = 'error';
    }
    dispatch(
      globalActions.displayToast({
        duration: 3000,
        toastType: type,
        toastMessage: message,
      }),
    );
  };

  const onErrorResponse = (error: any) => {
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.message &&
      typeof error.response.data.message === 'string'
    ) {
      handleToastMsg(error.response.data.message, 'error');
    } else {
      handleToastMsg('WENT_WRONG');
    }
  };

  const payloadAccountNumbers = () =>
    accountNumbers.filter(item => item !== '');

  const { refetch: refetchConnectionApi, isLoading: connecting } = useRESTQuery(
    [`connectIntegrations`],
    {
      endpoint: `integrations/custodians`,
      body: {
        source: modalData.source,
        accountNumbers: payloadAccountNumbers(),
      },
      method: 'POST',
      headers: {
        'X-Tifin-Ai-Auth': localStorage.getItem('x-tifin-ai-token'),
      },
    },
    {
      retry: false,
      cacheTime: 0,
      enabled: connectApi,
      onSuccess: res => {
        setConnectApi(false);
        onConnectSuccess(res?.data?.createdIntegration?.id, res?.data?.source);
        const message =
          modalData?.source === ECustodians.FidelityInstitutionalBrokerage
            ? 'Account added successfully. Please complete the process with Advent and take other necessary actions.'
            : 'Account added successfully. Please complete the document signing process with Advent.';
        handleToastMsg(message, 'success');
        setAccountNumbers(initialAccNumber);
        queryClient.invalidateQueries('integrations');
        setOpen(false);
      },
      onError: error => {
        setConnectApi(false);
        onErrorResponse(error);
      },
    },
  );

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent default action if necessary
      handleClose();
    }
  };

  return (
    <Drawer
      anchor="right"
      variant="temporary"
      open={open}
      className={classes.drawer}
      classes={{ paper: classes.drawerPaper }}
      onClose={handleClose}
    >
      <Box sx={{ position: 'relative' }}>
        <StyledLoginWrapper>
          <StyledLogoContainer>
            <StyledLogoWrapper>
              <SageIcon />
            </StyledLogoWrapper>
            <StyledLogoWrapper>
              <SyncAltIcon />
            </StyledLogoWrapper>
            <StyledLogoWrapper
              style={{ paddingRight: '0px', marginTop: '8px' }}
            >
              {modalData?.logo}
            </StyledLogoWrapper>
          </StyledLogoContainer>
          <StyledModalTextContainer>
            <StyledTextModal
              style={{
                fontSize: isMobile ? '18px' : '24px',
              }}
            >{`Connect to ${modalData?.name}`}</StyledTextModal>
            <StyledModalCustodialTitle
              style={{
                fontSize: isMobile ? '14px' : '18px',
              }}
            >
              Custodial Master Account Numbers
            </StyledModalCustodialTitle>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '1rem',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Grid
                container
                spacing={2.5}
                justifyContent={'center'}
                py="1rem"
                sx={{
                  gap: isMobile ? '0.5rem' : '1rem',
                }}
              >
                {accountNumbers.map((name, index) => (
                  <Grid
                    key={index}
                    item
                    sx={{ width: isMobile ? '100%' : '46.5%' }}
                  >
                    <Input
                      label={`Account number ${index + 1}`}
                      variant="outlined"
                      size="small"
                      sx={{
                        width: '100%',
                      }}
                      value={name}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleInputChange(e, index)
                      }
                    />
                  </Grid>
                ))}
              </Grid>
              <Button
                variant="contained"
                color="primary"
                sx={{ width: isMobile ? '100%' : '90%' }}
                onClick={handleButtonClick}
                disabled={isSubmitDisabled()}
              >
                {connecting ? 'Connecting...' : 'Connect'}
              </Button>
            </Box>
          </StyledModalTextContainer>
          <Grid
            container
            justifyContent="flex-end"
            alignItems="flex-end"
            sx={{ position: 'absolute', top: '10px', right: '10px' }}
          >
            <Grid
              item
              container
              alignItems="center"
              tabIndex={0}
              onKeyDown={handleKeyDown}
              onClick={handleClose}
              sx={{
                cursor: 'pointer',
                padding: '20px 15px 20px 20px',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <CloseIcon />
            </Grid>
          </Grid>
        </StyledLoginWrapper>
      </Box>
    </Drawer>
  );
};

export default CustodianDrawer;
