import React, { Fragment } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import { Logout, ChevronLeft, ChevronRight } from '@mui/icons-material';
import SageBlackLogo from '@icons/sage_logo_black';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { useOktaAuth } from '@okta/okta-react';
import {
  NavMenuWrapper,
  LogoContainer,
  ToggleButton,
  LogoutWrapper,
  LogoMask,
  ProfileIconWrapper,
} from '@layout/SideNav/styles';
import { TOUR_NAVIGATION } from '../utils';
import NavButton from './NavButton';

interface SideNavProps {
  collapse: boolean;
  onArrowClick:
    | ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void)
    | undefined;
  setRouteState: React.Dispatch<string>;
  routeState: any;
  awaitingNavItem: any;
  setAwaitingNavItem: React.Dispatch<any>;
}

export default function SideNav({
  collapse = false,
  onArrowClick,
  setRouteState,
  routeState,
  awaitingNavItem,
  // setAwaitingNavItem,
}: SideNavProps): React.JSX.Element {
  // const location = useLocation();
  const navigate = useNavigate();
  const { oktaAuth } = useOktaAuth();
  // const { pathname } = location;
  const userType = localStorage.getItem('tifin-ai-user-type');

  const handleLogout = async () => {
    localStorage.clear();
    try {
      await oktaAuth.signOut();
    } catch (error) {
      console.error('Sign out error:', error);
    }
  };

  const onMenuClick = (path: any) => {
    // navigate(path);

    setRouteState(path);
  };

  const handleProfileClick = () => {
    console.log('clicked');
    // window.location.href = '/profile';
    navigate('/profile');
  };

  const isRouteActive = (path: string) => routeState.startsWith(path);

  return (
    <Fragment>
      <LogoContainer container collapse={collapse} alignItems={'center'}>
        <Link to={'/conversations'}>
          <LogoMask collapse={collapse}>
            <SageBlackLogo fill={'#283345'} width={'100%'} height={'46'} />
          </LogoMask>
        </Link>
        <ToggleButton collapse={collapse} onClick={onArrowClick} size={'large'}>
          {collapse ? (
            <ChevronRight style={{ color: '#333' }} />
          ) : (
            <ChevronLeft style={{ color: '#333' }} />
          )}
        </ToggleButton>
      </LogoContainer>
      <NavMenuWrapper
        container
        flexDirection={'column'}
        justifyContent={'space-between'}
      >
        <Grid
          item
          container
          px={3}
          rowGap={1}
          margin={'0 auto'}
          justifyContent={'center'}
        >
          {TOUR_NAVIGATION.map((item, index) => {
            if (item.showToUser.includes(userType)) {
              return (
                <NavButton
                  key={index}
                  collapse={collapse}
                  cursor={item.cursor}
                  tooltipText={item.name}
                  startIcon={item.Icon && <item.Icon />}
                  active={isRouteActive(item.name)}
                  enableNotifications={item.enableNotifications}
                  onClick={() =>
                    item.cursor !== 'not-allowed' && onMenuClick(item.name)
                  }
                  awaitingNavItem={awaitingNavItem === item.name}
                >
                  {item.name}
                </NavButton>
              );
            }
          })}
        </Grid>
        <LogoutWrapper container alignItems="center">
          <NavButton
            collapse={collapse}
            cursor={'pointer'}
            tooltipText={'Logout'}
            startIcon={<Logout />}
            active={false}
            enableNotifications={false}
            onClick={() => handleLogout()}
          >
            Logout
          </NavButton>
          {!collapse ? (
            <NavButton
              collapse={collapse}
              cursor={'pointer'}
              tooltipText={'My Profile'}
              startIcon={<AccountCircleOutlinedIcon />}
              active={false}
              enableNotifications={false}
              onClick={() => handleProfileClick()}
            >
              My Profile
            </NavButton>
          ) : (
            <ProfileIconWrapper onClick={() => handleProfileClick()}>
              RT
            </ProfileIconWrapper>
          )}
        </LogoutWrapper>
        {/* </ProfileWrapper> */}
      </NavMenuWrapper>
    </Fragment>
  );
}
