import React, { useEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import Chat from './index';
import SubsequentLogin from './SubsequentLogin';
import { useSelector, useDispatch } from 'react-redux';
import { actions as chatActions } from '@features/Chat/slice';
import { getChatInitResponseState } from '@features/Chat/selector';

function ChatView(): React.JSX.Element {
  const dispatch = useDispatch();
  const { chatHistory: initHistoryState, loading: isLoading } = useSelector(
    getChatInitResponseState,
  );

  useEffect(() => {
    if (
      localStorage.getItem('x-tifin-ai-token') &&
      initHistoryState?.length === 0
    ) {
      dispatch(chatActions.loadChatRequest());
    }
  }, []);
  return (
    <Routes>
      <Route path="/" element={<SubsequentLogin />} />
      <Route
        path="/stream"
        element={<Chat isLoading={isLoading} chatHistory={initHistoryState} />}
      />
      <Route path="/start" element={<Navigate to="/conversations/stream" />} />
    </Routes>
  );
}

export default ChatView;
