import { PaletteOptions } from '@mui/material/styles/createPalette';

export interface CustomPaletteOptions extends PaletteOptions {
  main: string;
  solidWhite: string;
  blue: string;
  blueShade: string;
  light: string;
  dark: string;
  white: string;
  whiteText: string;
  whiteShade: string;
  pink: string;
  orange: string;
  yellow: string;
  aquaGreen: string;
  darkGreen: string;
  purpleBrand: string;
  darkBlue: string;
  lightBlue: string;
  accent: string;
  successMain: string;
  successLight: string;
  warningMain: string;
  warningLight: string;
  dangerMain: string;
  dangerLight: string;
  layoutBackground: string;
  backgroundShade: string;
  borderColor: string;
  borderColorShade: string;
  inputBackgroundColor: string;
  focusedOutline: string;
  darkGrey: string;
  seaGreen: string;
  mediumGrey: string;
  orangeBorder: string;
  lightGrey1: string;
  orange1: string;
  background1: string;
  background2: string;
  background3: string;
  background4: string;
  background5: string;
  background6: string;
  background7: string;
  background8: string;
  background9: string;
  boxShadow1: string;
  boxShadow2: string;
  boxShadow3: string;
  boxShadow4: string;
  boxShadow5: string;
  border1: string;
  border2: string;
  black: string;
  solidBlack: string;
  textBlack: string;
  lightBlack: string;
  fadedBlack: string;
  charcoalBlack: string;
  charcoalBlack2: string;
  charcoalBlack3: string;
  grey: Partial<PaletteOptions['grey']>;
  grey2: string;
  grey3: string;
  grey4: string;
  grey5: string;
  grey6: string;
  lightGrey: string;
  gradientGreen: string;
  gradientBlue: string;
  blueGradient1: string;
  blueGradient2: string;
  scrollbarTrackColor: string;
  scrollbarThumbColor: string;
  activeDotColor: string;
  inactiveDotColor: string;
  graph: {
    blue: string;
  };
  table: {
    [key: string]: string;
  };
}

export const COLORS: CustomPaletteOptions = {
  /* Standard Color */
  main: '#283345',
  blue: '#42CFFA',
  blueShade: '#d3cef7',
  light: '#41926E',
  dark: '#151412',
  white: '#EEEFF1',
  whiteText: '#FCFDFD',
  whiteShade: '#F9FAFB',
  solidWhite: '#FFFFFF',
  pink: '#FF0A9D',
  orange: '#FA6F15',
  yellow: '#FFA32C',
  aquaGreen: '#00B186',
  darkGreen: '#033E3E',
  purpleBrand: '#EFEFFD',
  darkBlue: '#2B5C69',
  lightBlue: '#1262E3',
  accent: '#457F8F',
  successMain: '#008243',
  successLight: '#EBF5F0',
  warningMain: '#FF8D23',
  warningLight: '#FFF2E7',
  dangerMain: '#E00000',
  dangerLight: '#FFECEC',
  layoutBackground: 'linear-gradient(to bottom, #f5f6f7, #f7f3f1, #f6ede8)',
  backgroundShade: 'rgba(255, 255, 255, 0.40)',
  borderColor: '#E4E7EC',
  borderColorShade: 'rgba(255, 255, 255, 0.7)',
  inputBackgroundColor: 'rgba(255, 255, 255, 0.35)',
  focusedOutline: '#cfd1d4',
  darkGrey: '#a9a9a9',
  seaGreen: '#61ffcf',
  mediumGrey: '#8a8a8a',
  orangeBorder: '#FFB890',
  orange1: '#FF5C12',

  /* Background Color Variant */
  background1: 'rgba(255, 255, 255, 0.4)',
  background2: 'rgba(255, 255, 255, 0.60)',
  background3: '#f0effa',
  background4: '#f6f4fb',
  background5: 'rgba(66, 207, 250, 0.1)',
  background6: 'rgb(83 82 82)',
  background7: '#a9cf54',
  background8: 'rgba(240, 240, 240, 0.50)',
  background9: '#f7f7f7',

  /* Box Shadow color Variant */
  boxShadow1: '0px 0px 10px 0px rgba(16, 24, 40, 0.10)',
  boxShadow2: 'inset 1px 2px 2px rgba(16, 24, 40, 0.12)',
  boxShadow3: '0px 1px 2px 0px rgba(0, 0, 0, 0.12)',
  boxShadow4: '0px 0px 16px 0px rgba(66, 207, 250, 0.8)',
  boxShadow5: '0 0 5px rgba(0, 0, 0, 0.4)',

  /*Border Color Variant */
  border1: '#cccccc',
  border2: '#aaa',

  /* Black Color */
  black: '#101828',
  solidBlack: '#000000',
  textBlack: '#222124',
  lightBlack: 'rgba(34, 33, 36, 0.5)',
  fadedBlack: '#283345',
  charcoalBlack: '#475467',
  charcoalBlack2: '#1A263A',
  charcoalBlack3: '#333',
  // textBlack: '#101828',

  /* Grey Color */
  grey: {
    // Adjust the shades based on your requirements
    50: '#f5f5f5',
    100: '#eeeeee',
    200: '#e0e0e0',
    300: '#bdbdbd',
    400: '#9e9e9e',
    500: '#777777', // Your original value
    600: '#616161',
    700: '#424242',
    800: '#212121',
    900: '#000000',
  },
  grey2: '#474747',
  grey3: '#818181',
  grey4: '#98A2B3',
  grey5: '#7d7d7d',
  grey6: '#667085',
  lightGrey: '#999999',
  lightGrey1: '#969696',

  /* Gradient Color */
  gradientGreen: '#60FFCE',
  gradientBlue: '#60ECFF',
  blueGradient1: '#40CFFA',
  blueGradient2: '#97C1FF',

  scrollbarTrackColor: '#e9e9e9',
  scrollbarThumbColor: '#cfcfcf',
  activeDotColor: '#033E3E',
  inactiveDotColor: '#CED0D3',

  graph: {
    blue: '#7B96F5',
  },
  table: {
    background: '#86858B',
  },
};
