import * as React from 'react';

function YellowBulb(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 18 18" fill="none" {...props}>
      <path
        d="M6.188 16.313h5.625"
        stroke="url(#prefix__paint0_linear_1243_68537)"
        strokeWidth={1.575}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.534 11.742a5.59 5.59 0 01-2.159-4.394c-.014-3.052 2.44-5.59 5.491-5.66a5.625 5.625 0 013.607 10.047 1.7 1.7 0 00-.66 1.343v.422a.563.563 0 01-.563.563h-4.5a.562.562 0 01-.562-.563v-.422a1.716 1.716 0 00-.654-1.336v0z"
        stroke="url(#prefix__paint1_linear_1243_68537)"
        strokeWidth={1.575}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.57 3.994a3.368 3.368 0 012.756 2.749"
        stroke="#FFB169"
        strokeWidth={1.575}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="prefix__paint0_linear_1243_68537"
          x1={9}
          y1={16.313}
          x2={5}
          y2={21.5}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB169" />
          <stop offset={1} stopColor="#FF6C2C" />
        </linearGradient>
        <linearGradient
          id="prefix__paint1_linear_1243_68537"
          x1={9}
          y1={1.686}
          x2={0.5}
          y2={30.5}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB169" />
          <stop offset={1} stopColor="#FF6C2C" />
        </linearGradient>
      </defs>
    </svg>
  );
}

const MemoYellowBulb = React.memo(YellowBulb);
export default MemoYellowBulb;
