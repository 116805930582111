import { handleError } from '@common/error';
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { actions as globalActions } from 'app/features/Global/slice';
import { ITickerWeights } from '@pages/ClientsV2/types';
import ApiInstance from '@instances/index';
import { IAddPortfolio, IAddPortfolioPayload } from '@pages/ClientsV2/mutation';

// API call function
const AddPortfolioForClient = async (
  payload: IAddPortfolio | IAddPortfolioPayload,
): Promise<any> => {
  const createResponse = await ApiInstance.post('/client/portfolio', payload, {
    headers: {
      'X-Tifin-Ai-Auth': localStorage.getItem('x-tifin-ai-token'),
    },
  });
  return createResponse?.data;
};

interface CreatePortfolioPayload {
  clientId: string;
  accountNumber: string;
  name: string;
  holdings: ITickerWeights[];
  setStep: React.Dispatch<React.SetStateAction<number>>;
  closeAddClientModal: () => void;
  setCleanUpPortfolioModal: React.Dispatch<React.SetStateAction<boolean>>;
}

// Define the return type for useCreatePortfolio
type UseCreatePortfolioResult = UseMutationResult<
  any,
  any,
  CreatePortfolioPayload
>;

const useCreatePortfolio = (): UseCreatePortfolioResult => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation(
    (payload: CreatePortfolioPayload) =>
      AddPortfolioForClient({
        clientId: payload.clientId,
        accountNumber: payload.accountNumber,
        name: payload.name,
        holdings: payload.holdings,
      }),
    {
      onSuccess: (
        res,
        { setStep, setCleanUpPortfolioModal, closeAddClientModal },
      ) => {
        if (res) {
          dispatch(
            globalActions.displayToast({
              duration: 3000,
              toastType: 'success',
              toastMessage: 'Portfolio added successfully.',
            }),
          );
          queryClient.invalidateQueries('get-clients');
          queryClient.invalidateQueries('get-prospects');
          setStep(prevStep => prevStep - 1);
          closeAddClientModal();
          setCleanUpPortfolioModal(false);
        }
      },
      onError: (error: any) => {
        handleError(dispatch, error);
      },
    },
  );
};

export default useCreatePortfolio;
