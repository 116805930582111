import { ButtonProps } from '@mui/base';
import { Button as MuiButton } from '@mui/material';
import styled, { css } from 'styled-components';
import { shouldForwardProp } from 'utils/forwardProp';

export const Button = styled(MuiButton)<ButtonProps>`
  color: ${props => props.theme.white};
  background: ${props => props.theme.main};
  background-color: ${props => props.theme.main};
`;

export const HeaderMenu = styled(MuiButton).withConfig({
  shouldForwardProp,
})<any>`
  position: relative;
  height: 46px;
  padding: 0px;
  margin: 0 auto;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  font-style: normal;
  text-transform: capitalize;
  cursor: ${props => props.cursor ?? 'default'};
  color: ${props =>
    props.active ? props.theme.charcoalBlack : props.theme.grey6};
  background-color: ${props => props.active && props.theme.solidWhite};
  border: ${props =>
    props.open && props.active && `2px solid ${props.theme.info}`};
  border-left: ${props =>
    !props.open && props.active && `5px solid ${props.theme.info}`};

  transition:
    background-color cubic-bezier(0.48, 0.65, 0.6, 0.79) 0.5s,
    transform cubic-bezier(0.48, 0.65, 0.6, 0.79) 0.5s;

  ${({ enableNotifications, notification }: any) =>
    enableNotifications &&
    css`
      &:before {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        top: 0;
        right: 0;
        border-top: ${notification
          ? '10.5px solid #151412'
          : '10.5px solid #151412'};
        border-right: ${notification
          ? '10.5px solid #151412'
          : '10.5px solid #151412'};
        background-color: ${notification ? '#151412' : ''};
        clip-path: polygon(0 0, 100% 0, 0 100%);
        transform: scaleX(-1);
        transition:
          background-color cubic-bezier(0.48, 0.65, 0.6, 0.79) 0.5s,
          transform cubic-bezier(0.48, 0.65, 0.6, 0.79) 0.5s;
      }
    `}
`;

export const HeaderMenuNotify = styled('span')<any>`
  position: absolute;
  width: 0;
  height: 0;
  top: 0;
  right: 0;
  border-top: 8px solid transparent;
  border-right: 8px solid transparent;
  clip-path: polygon(0 0, 100% 0, 0 100%);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15));
  transform: scaleX(-1);
  transition:
    background-color cubic-bezier(0.48, 0.65, 0.6, 0.79) 0.5s,
    transform cubic-bezier(0.48, 0.65, 0.6, 0.79) 0.5s;

  ${({ enableNotifications, notification }: any) =>
    enableNotifications &&
    css`
      background-color: ${notification ? '#FF0A9D' : 'rgb(255 255 255 / 21%)'};
    `}
`;
