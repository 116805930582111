import React from 'react';
import { Grid, useTheme } from '@mui/material';
import SageAvatar from '@icons/sageAvatar';
import { AvatarWrapper } from '../styles';

interface TextInfoProps {
  textContent: string;
}

const TextInfo = (props: TextInfoProps): React.JSX.Element => {
  const theme = useTheme();
  return (
    <Grid sx={{ margin: '10px 0' }} item>
      <Grid container>
        <Grid item md={0.5}>
          <AvatarWrapper>
            <SageAvatar
              borderOpacity={0.3}
              fill={theme.palette.primary['solidWhite']}
            />
          </AvatarWrapper>
        </Grid>
        <Grid item md={11.5} sx={{ marginTop: '5px' }}>
          {props.textContent}
        </Grid>
      </Grid>
    </Grid>
  );
};
export default TextInfo;
