import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'app/types';
import { initialState } from './slice';

const selectSlice = (state: RootState) => state?.profile || initialState;

export const getAuthDetails = createSelector(
  [selectSlice],
  state => state.authDetails,
);
