import * as React from 'react';

function GreenTick(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M16.125 9.75L10.622 15l-2.747-2.625"
        stroke="#008243"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 21a9 9 0 100-18 9 9 0 000 18z"
        stroke="#008243"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const MemoGreenTick = React.memo(GreenTick);
export default MemoGreenTick;
