// import { Box } from '@mui/material';
import styled from 'styled-components';

export const TestCardContainer = styled('div')`
  margin-right: 2rem;
  padding-right: 2px;
`;

export const ButtonsDiv = styled('div')`
  text-align: right;
  margin-bottom: 20px;
`;
