import { Dialog, DialogContent, Grid } from '@mui/material';

import React, { useEffect, useState } from 'react';
import { ITickerWeights, PortfolioData } from './types';
import { LoaderContainer } from '@pages/Signup/styles';
import { Spinner } from '@pages/IntegrationPage/styles';
import PortfolioForm from './PortfolioForm';

interface AddPortfolioModalProps {
  isDataLoading?: boolean;
  isOpen?: boolean;
  onClose?: () => void;
  onSubmit: any;
  showFooterButton?: boolean;
  title?: string;
  showTitle?: boolean;
  refreshData?: boolean;
  client_name?: string;
  clientPortfolioData?: any;
}

const AddPortfolioModalV2: React.FC<AddPortfolioModalProps> = ({
  isDataLoading = false,
  isOpen = false,
  onClose = () => {},
  refreshData,
  onSubmit,
  clientPortfolioData,
}: AddPortfolioModalProps) => {
  const [portfolioData, setPortfolioData] = useState<PortfolioData>({
    portfolioId: '',
    accName: '',
    accNumber: '',
    // investmentObjectives: '',
    // constraints: '',
    // selectedRiskProfile: '',
  });
  const [tickerWeights, setTickerWeights] = useState<ITickerWeights[]>([]);
  const handlePortfolioDataChange = (fieldName, value) => {
    setPortfolioData({
      ...portfolioData,
      [fieldName]: value,
    });
  };
  useEffect(() => {
    const {
      id,
      name,
      accountNumber,
      // investmentObjective,
      // portfolioConstraints,
      // riskProfile,
    } = clientPortfolioData;
    setPortfolioData({
      portfolioId: id,
      accName: name,
      accNumber: accountNumber,
      // investmentObjectives: investmentObjective,
      // constraints: portfolioConstraints,
      // selectedRiskProfile: riskProfile,
    });
    setTickerWeights(
      clientPortfolioData?.ClientPortfolioHoldings?.map(portfolio => {
        return { ticker: portfolio.ticker, weight: portfolio.shares };
      }),
    );
  }, []);
  const handleSubmit = () => {
    onSubmit({
      portfolioData,
      tickerWeights,
    });
  };
  return (
    <>
      <Dialog
        open={isOpen}
        onClose={onClose}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          style: {
            background: '#fff',
            overflow: 'hidden',
          },
        }}
      >
        <DialogContent sx={{ overflowY: 'scroll' }}>
          {isDataLoading ? (
            <LoaderContainer>
              <Spinner />
            </LoaderContainer>
          ) : (
            <Grid container spacing={2}>
              <PortfolioForm
                showFooterButton={true}
                title={'title'}
                showTitle={true}
                refreshData={refreshData}
                onClose={onClose}
                onSubmit={handleSubmit}
                portfolioData={portfolioData}
                setPortfolioData={handlePortfolioDataChange}
                tickerWeights={tickerWeights}
                setTickerWeights={setTickerWeights}
              />
            </Grid>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddPortfolioModalV2;
