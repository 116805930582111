import React from 'react';

const AltruistLogo: React.FC = () => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="100"
    height="48"
    viewBox="0 0 383 131"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0,131) scale(0.1,-0.1)"
      fill="#000000"
      stroke="none"
    >
      <path d="M550 1277 c-204 -61 -361 -206 -436 -402 -32 -85 -43 -235 -25 -341 16 -92 85 -233 151 -306 69 -77 182 -151 283 -185 71 -23 98 -27 202 -27 104 0 131 4 202 27 159 53 295 169 372 316 53 102 71 178 71 296 0 192 -70 351 -211 478 -162 147 -405 204 -609 144z m315 -32 c214 -54 395 -232 450 -441 20 -76 19 -220 -1 -298 -41 -158 -170 -318 -315 -389 -312 -153 -680 -24 -825 288 -87 188 -70 415 44 583 83 121 221 219 362 256 73 19 211 20 285 1z" />
      <path d="M616 1114 c-235 -57 -397 -295 -358 -527 41 -236 228 -397 462 -398 146 0 262 50 356 155 164 181 159 457 -11 635 -118 124 -286 174 -449 135z m218 -39 c260 -68 397 -359 282 -601 -82 -172 -277 -277 -457 -245 -261 45 -426 306 -349 552 69 223 300 352 524 294z" />
      <path d="M2150 655 l0 -255 30 0 30 0 0 255 0 255 -30 0 -30 0 0 -255z" />
      <path d="M1737 663 c-53 -125 -99 -236 -103 -246 -6 -16 -2 -18 31 -15 35 3 38 6 59 58 l22 55 115 3 114 3 24 -61 c23 -58 25 -60 61 -60 l37 0 -102 243 c-99 234 -103 242 -132 245 l-29 3 -97 -228z m169 17 l42 -100 -84 0 c-46 0 -84 3 -84 8 0 12 75 192 80 192 3 0 24 -45 46 -100z" />
      <path d="M3096 872 c-11 -34 4 -53 38 -50 27 3 31 7 31 33 0 26 -4 30 -32 33 -24 2 -33 -1 -37 -16z" />
      <path d="M2320 795 c0 -54 0 -55 -30 -55 -27 0 -30 -3 -30 -30 0 -26 3 -30 29 -30 l29 0 3 -114 c4 -103 6 -117 27 -139 44 -46 132 -47 132 -1 0 21 -5 24 -33 24 -51 0 -57 13 -57 127 l0 103 45 0 c43 0 45 1 45 30 0 29 -2 30 -45 30 l-45 0 0 55 0 55 -35 0 -35 0 0 -55z" />
      <path d="M3580 795 c0 -54 0 -55 -30 -55 -27 0 -30 -3 -30 -30 0 -27 3 -30 30 -30 l30 0 0 -115 c0 -109 1 -117 24 -140 25 -24 85 -42 111 -31 8 3 15 17 15 31 0 21 -5 25 -29 25 -52 0 -61 20 -61 131 l0 99 45 0 c43 0 45 1 45 30 0 29 -2 30 -45 30 l-45 0 0 55 c0 54 0 55 -30 55 -30 0 -30 -1 -30 -55z" />
      <path d="M3291 735 c-45 -20 -60 -42 -61 -85 0 -53 23 -77 88 -95 91 -25 102 -32 102 -59 0 -50 -84 -64 -124 -21 -28 30 -76 35 -76 7 0 -69 117 -114 205 -78 40 17 55 42 55 94 0 50 -27 76 -104 96 -69 18 -91 35 -81 65 13 43 99 39 115 -5 8 -21 14 -23 37 -18 37 9 37 8 21 40 -30 61 -112 88 -177 59z" />
      <path d="M2530 570 l0 -170 35 0 35 0 0 115 c0 125 9 147 65 161 20 5 25 12 25 36 0 29 -2 30 -32 24 -17 -3 -40 -14 -50 -23 -17 -15 -18 -15 -18 5 0 18 -6 22 -30 22 l-30 0 0 -170z" />
      <path d="M2740 620 c0 -139 12 -179 62 -210 41 -25 83 -25 131 -1 29 15 37 16 37 5 0 -9 11 -14 30 -14 l30 0 0 171 0 170 -32 -3 -33 -3 -5 -123 c-5 -115 -7 -125 -29 -143 -30 -25 -62 -24 -95 2 -26 20 -26 22 -26 145 l0 124 -35 0 -35 0 0 -120z" />
      <path d="M3100 570 l0 -170 30 0 30 0 0 170 0 170 -30 0 -30 0 0 -170z" />
    </g>
  </svg>
);

export default AltruistLogo;
