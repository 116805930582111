import React from 'react';
import { Grid, TextField, Typography } from '@mui/material';
import { ClientData } from './types';
import { StyledInputLabel } from '@pages/Profile/styles';
import { regExpAlphabetic } from '@common/string';

interface ClientFormProps {
  clientData: ClientData;
  setClientData: (fieldName: string, value: any) => any;
}

function ClientForm(props: ClientFormProps): React.JSX.Element {
  const { clientData, setClientData } = props;

  return (
    <Grid container p={2} sx={{ background: '#fff' }} rowGap={2} columnGap={2}>
      <Grid
        item
        container
        xs={12}
        sm={12}
        columnGap={2}
        rowGap={2}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Grid item xs={12} md={5.7}>
          <StyledInputLabel>
            <Typography variant="subtitle2">
              First Name&nbsp;<span style={{ color: 'red' }}>*</span>
            </Typography>
          </StyledInputLabel>
          <TextField
            variant="outlined"
            fullWidth
            size={'small'}
            type="text"
            value={clientData.firstName}
            required
            placeholder="First Name"
            onChange={e =>
              setClientData(
                'firstName',
                e.target.value.replace(regExpAlphabetic, ''),
              )
            }
            inputProps={{
              maxLength: 50, // Adjust the number as per your requirement
            }}
          />
        </Grid>

        <Grid item xs={12} md={5.7}>
          <StyledInputLabel>
            <Typography variant="subtitle2">
              Last Name&nbsp;<span style={{ color: 'red' }}>*</span>
            </Typography>
          </StyledInputLabel>
          <TextField
            variant="outlined"
            fullWidth
            size={'small'}
            type="text"
            value={clientData.lastName}
            required
            placeholder="Last Name"
            onChange={e =>
              setClientData(
                'lastName',
                e.target.value.replace(regExpAlphabetic, ''),
              )
            }
            inputProps={{
              maxLength: 50, // Adjust the number as per your requirement
            }}
          />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <StyledInputLabel>
          <Typography variant="subtitle2">
            Email&nbsp;<span style={{ color: 'red' }}>*</span>
          </Typography>
        </StyledInputLabel>
        <TextField
          variant="outlined"
          fullWidth
          size={'small'}
          type="text"
          value={clientData.email}
          required
          placeholder="Email"
          onChange={e => setClientData('email', e.target.value)}
        />
      </Grid>
    </Grid>
  );
}

export default ClientForm;
