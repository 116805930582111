/* eslint-disable react/prop-types */
import * as React from 'react';

function ChartViewIcon({ fill }: { fill?: string }): React.JSX.Element {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.5 21.625H22.875V9.125C22.875 8.95924 22.8092 8.80027 22.6919 8.68306C22.5747 8.56585 22.4158 8.5 22.25 8.5H17.875C17.7092 8.5 17.5503 8.56585 17.4331 8.68306C17.3158 8.80027 17.25 8.95924 17.25 9.125V12.25H13.5C13.3342 12.25 13.1753 12.3158 13.0581 12.4331C12.9408 12.5503 12.875 12.7092 12.875 12.875V16H9.75C9.58424 16 9.42527 16.0658 9.30806 16.1831C9.19085 16.3003 9.125 16.4592 9.125 16.625V21.625H8.5C8.33424 21.625 8.17527 21.6908 8.05806 21.8081C7.94085 21.9253 7.875 22.0842 7.875 22.25C7.875 22.4158 7.94085 22.5747 8.05806 22.6919C8.17527 22.8092 8.33424 22.875 8.5 22.875H23.5C23.6658 22.875 23.8247 22.8092 23.9419 22.6919C24.0592 22.5747 24.125 22.4158 24.125 22.25C24.125 22.0842 24.0592 21.9253 23.9419 21.8081C23.8247 21.6908 23.6658 21.625 23.5 21.625ZM18.5 9.75H21.625V21.625H18.5V9.75ZM14.125 13.5H17.25V21.625H14.125V13.5ZM10.375 17.25H12.875V21.625H10.375V17.25Z"
        fill={fill ?? '#96969B'}
      />
    </svg>
  );
}

const MemoChartViewIcon = React.memo(ChartViewIcon);
export default MemoChartViewIcon;
