import React, { useRef, useState, useEffect } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { styled, Typography } from '@mui/material';

interface TruncatedTextWithTooltipProps {
  text: string;
}

export const StyledTypography = styled(Typography)`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* Limit to 2 lines */
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 3em; /* Approximately 2 lines height */
  color: #282829;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
`;

const TruncatedTextWithTooltip: React.FC<TruncatedTextWithTooltipProps> = ({
  text,
}) => {
  const textRef = useRef<HTMLDivElement>(null);
  const [isTruncated, setIsTruncated] = useState(false);

  useEffect(() => {
    const element = textRef.current;
    if (element && element.scrollHeight > element.clientHeight) {
      setIsTruncated(true);
    }
  }, [text]);

  return (
    <Tooltip title={isTruncated ? text : ''} arrow>
      <StyledTypography ref={textRef}>{text}</StyledTypography>
    </Tooltip>
  );
};

export default TruncatedTextWithTooltip;
