import React from 'react';
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { price } from '@common/number';

interface TableColumn {
  key: string;
  value: string;
  width: string;
  renderCell?: (value: any) => React.ReactNode;
  subText?: string;
}

interface HoldingsTableProps {
  headers: TableColumn[];
  data: any[];
  setStepper?: React.Dispatch<React.SetStateAction<string>>;
  setSelectedTicker?: (x: string) => void;
}

const useStyles = makeStyles({
  root: {
    borderBottom: '1px solid #e4e7ec',
  },
  tableWrapper: {
    borderRadius: '8px',
    height: '100%',
  },
  noDataMessage: {
    padding: '10px',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  tableHeaderCell: {
    '&.MuiTableCell-root': {
      fontWeight: 400,
      color: '#535256',
      fontSize: '12px',
      padding: '5px 16px',
    },
  },
});

function HoldingsTable({
  headers,
  data,
  setStepper,
  setSelectedTicker,
}: HoldingsTableProps): React.JSX.Element {
  const classes = useStyles();

  const renderCellValue = (key: string, value: any) => {
    if (key === 'weight' && typeof value === 'number') {
      return `${(value * 100).toFixed(2)}%`;
    } else if (key === 'price') {
      return `${price(value)}`;
    }
    return value !== null && value !== undefined ? value : 'N/A';
  };

  return (
    <Grid container className={classes.tableWrapper} rowGap={2}>
      <Grid item container xs={12} height={'100%'}>
        <TableContainer
          component={Paper}
          sx={{
            backgroundColor: '#fff',
            borderRadius: '8px',
            boxShadow: 'none',
            padding: 1.25,
          }}
        >
          {data?.length === 0 ? (
            <Typography variant="body2" className={classes.noDataMessage}>
              No data available
            </Typography>
          ) : (
            <Table>
              <TableHead>
                <TableRow>
                  {headers.map(header => (
                    <TableCell
                      key={header.key}
                      className={classes.tableHeaderCell}
                      style={{
                        width: header.width,
                        textWrap: 'nowrap',
                      }}
                    >
                      {header.key}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((row, index) => (
                  <TableRow key={`row-${index}`} className={classes.root}>
                    {headers.map((header, colIndex) => (
                      <TableCell
                        key={`${header.key}-${index}`}
                        style={{
                          fontSize: colIndex === 0 ? '14px' : '14px',
                          color: colIndex === 0 ? '#282829' : '#535256',
                          fontWeight: colIndex === 0 ? 500 : 400,
                        }}
                      >
                        {header.renderCell
                          ? header.renderCell(row[header.value])
                          : renderCellValue(header.value, row[header.value])}
                        {colIndex === 0 && row.ticker && (
                          <Grid
                            sx={{
                              fontSize: '12px',
                              color: '#86858b',
                              cursor: 'pointer',
                              textDecoration: 'underline',
                            }}
                            onClick={() => {
                              setSelectedTicker &&
                                setSelectedTicker(row.ticker);
                              setStepper &&
                                setStepper('PORTFOLIO_DETAILS_PAGE');
                            }}
                          >
                            {row.ticker}
                          </Grid>
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </TableContainer>
      </Grid>
    </Grid>
  );
}

export default HoldingsTable;
