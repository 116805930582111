import React from 'react';
import { MUIDataTableOptions } from 'mui-datatables';
import { Tooltip } from '@mui/material';
import {
  ChatTableContainer,
  ChatTableWrapper,
  ChatTableHead,
  ChatTableBody,
  ChatTableRow,
  ChatTableCell,
  StyledDataTable,
} from './styles';
import StarIcon from '@icons/starIcon';
import { validArray } from '@common/validArray';

interface TableProps {
  headers: string[];
  body: { [key: string]: string }[];
}

interface ChatTableProps {
  data: any[];
}

export function ChatTable({ data }: any = []): React.JSX.Element {
  if (!data) {
    return <></>;
  }
  const tableData: any = data?.data || data;
  return validArray(tableData) ? (
    <Table1 data={tableData} />
  ) : (
    <Table2 headers={tableData?.headers} body={tableData?.body} />
  );
}

const Table1 = ({ data = [] }: any): React.JSX.Element => {
  if (!data || data.length === 0) {
    return <></>;
  }
  const headers: any = Object.keys(data[0]);
  const RenderHeaderRow = React.memo(
    ({ columns }: any): React.JSX.Element => (
      <ChatTableRow>
        {columns?.map((header: any, index: number) => (
          <ChatTableCell
            key={`table-header-row-cell-${header}-${index}`}
            sx={{
              padding: '8px 12px',
              fontSize: '12px',
              display:
                header == 'highlight' && index === columns.length - 1
                  ? 'none'
                  : null,
            }}
          >
            {header}
          </ChatTableCell>
        ))}
      </ChatTableRow>
    ),
  );
  const RenderTableRow = React.memo(
    ({ columns, index, row }: any): React.JSX.Element => (
      <ChatTableRow
        key={`table-body-row-${index}`}
        sx={{
          borderLeft:
            columns.includes('highlight') && row.highlight
              ? '3px solid #7B73E4'
              : '3px solid transparent',
        }}
      >
        {columns?.map((header: any, colIndex: number) => (
          <ChatTableCell
            key={`table-body-row-cell-${index}-${colIndex}`}
            sx={{
              padding: '8px 12px',
              fontSize: '12px',
              backgroundColor: row.highlight ? '#EFEFFD' : '#fff',
              justifyContent:
                index === columns.length - 1 ? 'flex-end' : 'flex-start',
            }}
          >
            {header == 'highlight' && row.highlight ? (
              <StarIcon />
            ) : (
              row[header]
            )}
          </ChatTableCell>
        ))}
      </ChatTableRow>
    ),
  );
  return (
    <ChatTableContainer>
      <ChatTableWrapper data-key="content-default-table-container">
        <ChatTableHead>
          <RenderHeaderRow columns={headers} />
        </ChatTableHead>
        <ChatTableBody>
          {data?.map((row: any, rowIndex: number) => (
            <RenderTableRow
              key={rowIndex}
              columns={headers}
              index={rowIndex}
              row={row}
            />
          ))}
        </ChatTableBody>
      </ChatTableWrapper>
    </ChatTableContainer>
  );
};

const Table2 = ({ headers = [], body = [] }: TableProps): React.JSX.Element => {
  if (headers?.length === 0 || body?.length === 0) {
    return <></>;
  }
  const RenderHeaderRow = React.memo(
    ({ columns }: any): React.JSX.Element => (
      <ChatTableRow>
        {columns?.map((header: any, index: number) => (
          <ChatTableCell
            key={`table-header-row-cell-${header}-${index}`}
            sx={{ padding: '8px 12px', fontSize: '12px' }}
          >
            {header}
          </ChatTableCell>
        ))}
      </ChatTableRow>
    ),
  );
  const RenderTableRow = React.memo(
    ({ columns, index, row }: any): React.JSX.Element => (
      <ChatTableRow key={`table-body-row-${index}`}>
        {columns?.map((header: any, colIndex: number) => (
          <ChatTableCell
            key={`table-body-row-cell-${index}-${colIndex}`}
            sx={{ padding: '8px 12px', fontSize: '12px' }}
          >
            {row[header]}
          </ChatTableCell>
        ))}
      </ChatTableRow>
    ),
  );
  return (
    <ChatTableContainer>
      <ChatTableWrapper data-key="content-default-table-container">
        <ChatTableHead>
          <RenderHeaderRow columns={headers} />
        </ChatTableHead>
        <ChatTableBody>
          {body?.map((row: any, rowIndex: number) => (
            <RenderTableRow
              key={rowIndex}
              columns={headers}
              index={rowIndex}
              row={row}
            />
          ))}
        </ChatTableBody>
      </ChatTableWrapper>
    </ChatTableContainer>
  );
};

export const ChatTableMUI: React.FC<ChatTableProps> = ({ data }: any) => {
  let DataList: any = [];
  let Headers: any = [];
  if (data && data.headers) {
    DataList = data.body;
    Headers = data.headers.map((header: any) => ({
      name: header,
      label: header,
    }));
  } else if (data && Array.isArray(data) && data.length > 0) {
    const headers = data[0] ? Object.keys(data[0]) : [];
    Headers = headers.map(header => ({
      name: header,
      label: header === 'highlight' ? ' ' : header,
    }));
    DataList = data;
  }

  if (!DataList || !Headers) {
    return <div>No data available</div>;
  }
  const highlightColumnIndex = 6;

  const options: MUIDataTableOptions = {
    pagination: false,
    selectableRows: 'none',
    search: false,
    print: false,
    download: false,
    viewColumns: false,
    filter: false,
    sort: false,
    responsive: 'standard',
    serverSide: true,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    customHeadRender: (columnMeta, handleToggleColumn) => {
      return (
        <th
          key={columnMeta.name}
          onClick={() => handleToggleColumn(columnMeta.columnIndex)}
        >
          {columnMeta.label}
        </th>
      );
    },
    customRowRender: (data, rowIndex) => {
      const isHighlighted = data[highlightColumnIndex] === true;
      return (
        <tr
          key={rowIndex}
          style={{
            backgroundColor: isHighlighted ? '#EFEFFD' : '#fff',
            borderBottom: '0.5px solid #e4e7ec',
            borderLeft: isHighlighted ? '3px solid #7B73E4' : 'none',
          }}
        >
          {data.map((value, colIndex) => {
            const header = Headers[colIndex]?.name;
            const isHighlightColumn = header === 'highlight';
            const isTooltipIdColumn = header === 'id';

            const tooltipContent =
              isTooltipIdColumn && value.length > 7
                ? `${value.substring(0, 7)}...`
                : value;

            return (
              <td
                key={colIndex}
                style={{
                  padding: '15px',
                  fontSize: '12px',
                }}
              >
                {isHighlightColumn && isHighlighted ? (
                  <StarIcon />
                ) : isTooltipIdColumn && value.length > 7 ? (
                  <Tooltip title={value} placement="top">
                    {tooltipContent}
                  </Tooltip>
                ) : (
                  value
                )}
              </td>
            );
          })}
        </tr>
      );
    },
  };

  return (
    <StyledDataTable
      title=""
      columns={Headers}
      data={DataList}
      options={options}
    />
  );
};

export default React.memo(ChatTableMUI);
