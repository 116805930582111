import { Button, Grid, styled, Tooltip } from '@mui/material';
import React from 'react';
import { Spinner, StyledBadgeI } from './styles';
import { titleCase } from '@common/string';
import { ECustodians } from 'app/enums';

interface CustodianCardDataProps {
  logo: React.ReactNode;
  name: string;
  description: string;
  custodianData: any;
  isLoading: boolean;
  handleOpen: (name: string, status?: string) => void;
  handleConnect: (id: string, source: string) => void;
}

const CustodianCardWrapper = styled(Grid)`
  border: 1px solid #e4e7ec;
  border-radius: 8px;
  padding: 16px 16px 20px 16px;
  background: var(--neutral-0, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.12);
`;

const ImgContainer = styled(Grid)``;

const TitleContainer = styled('div')`
  display: flex;
  height: 20px;
  color: var(--neutral-900, #101828);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-bottom: 2px;
  @media (min-width: 900px) {
    font-size: 16px;
  }
`;

const DescContainer = styled('div')`
  display: flex;
  height: 40px;
  overflow: hidden;
  color: var(--neutral-600, #475467);
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  text-align: left;
  padding-bottom: 10px;
`;

function CustodianCard({
  logo,
  name,
  description,
  custodianData,
  isLoading,
  handleOpen,
  handleConnect,
}: CustodianCardDataProps): React.JSX.Element {
  const dynamicDescription =
    custodianData?.status === 'PENDING'
      ? 'View and sign the authorization letter to import your client accounts.'
      : description;

  const formattedCustodianCardStatusLabel = titleCase(
    custodianData?.status.toLowerCase(),
  );

  return (
    <Grid
      item
      container
      justifyContent={'flex-start'}
      alignItems={'center'}
      xs={11.5}
      sm={5.7}
      lg={2.83}
    >
      <CustodianCardWrapper item xs={12}>
        <Grid
          item
          container
          xs={12}
          justifyContent={'space-between'}
          alignItems={'flex-start'}
        >
          <Grid
            item
            container
            xs={6}
            justifyContent={'flex-start'}
            alignItems={'flex-start'}
            height={'65px'}
          >
            <ImgContainer item>{logo}</ImgContainer>
          </Grid>
          {custodianData && (
            <Grid
              item
              container
              xs={6}
              justifyContent={'flex-end'}
              alignItems={'center'}
            >
              <StyledBadgeI $status={formattedCustodianCardStatusLabel}>
                {formattedCustodianCardStatusLabel}
              </StyledBadgeI>
            </Grid>
          )}
        </Grid>
        <TitleContainer>{name}</TitleContainer>
        {description.length > 75 ? (
          <Tooltip title={dynamicDescription} placement="top">
            <DescContainer>
              {`${dynamicDescription.substring(0, 75)}...`}
            </DescContainer>
          </Tooltip>
        ) : (
          <DescContainer>{dynamicDescription}</DescContainer>
        )}
        {custodianData?.status === 'PENDING' &&
        custodianData.name !== ECustodians.FidelityInstitutionalBrokerage ? (
          <Button
            variant="outlined"
            style={{
              width: '100%',
              borderRadius: '20px',
              border: '1px solid var(--neutral-200, #EFEFEF)',
              boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.05)',
              textTransform: 'none',
              marginBottom: '-10px',
              color: '#101828',
              backgroundColor: 'white',
              fontSize: '14px',
            }}
            onClick={() => handleConnect(custodianData.id, custodianData.name)}
            disabled={isLoading}
          >
            {isLoading ? <Spinner /> : 'Review & Sign'}
          </Button>
        ) : (
          <Button
            variant="outlined"
            style={{
              width: '100%',
              borderRadius: '20px',
              border: '1px solid var(--neutral-200, #EFEFEF)',
              boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.05)',
              textTransform: 'none',
              marginBottom: '-10px',
              color: '#101828',
              fontSize: '14px',
              backgroundColor:
                custodianData?.status === 'CONNECTED' ? '#e5e6e8' : 'white',
            }}
            onClick={() => handleOpen(name, custodianData?.status)}
            disabled={
              custodianData?.status === 'CONNECTED' ||
              custodianData?.status === 'PROCESSING'
            }
          >
            {custodianData?.status === 'CONNECTED' ||
            custodianData?.status === 'PROCESSING' ? (
              'Disconnect'
            ) : isLoading ? (
              <Spinner />
            ) : (
              'Connect'
            )}
          </Button>
        )}
      </CustodianCardWrapper>
    </Grid>
  );
}

export default CustodianCard;
