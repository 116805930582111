import { Grid, styled, Typography } from '@mui/material';

export const TableFilters = styled(Grid)`
  padding: 20px 15px;
  border-bottom: 0px;
`;

export const AdvisorDetailContainer = styled(Grid)`
  width: 100%;
  // max-height: 110%;
  // overflow-y: auto;
`;

export const DetailWrapper = styled(Grid)``;

export const StyledNameDiv = styled(Typography)`
  justify-content: flex-start;
  color: #1d2939;
  font-size: 18px;
  font-weight: 500;
`;
