import React from 'react';
import styled from 'styled-components';
import RectangleImage from '@images/backdrop.svg';

const BackdropContainer = styled.div`
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
`;

const OverlapGroupWrapper = styled.div`
  width: 1440px;
  height: 1024px;
  width: 100%;
  height: auto;
  overflow: hidden;
  background: linear-gradient(
    180deg,
    rgba(250.75, 250.75, 250.75, 1) 0%,
    rgba(242.25, 242.25, 242.25, 1) 100%
  );
  background-color: rgba(255, 255, 255, 1);
  background-position: 50% 50%;
  background-size: cover;
`;

const OverlapGroup = styled.div`
  height: 2352px;
  left: -742px;
  position: relative;
  top: -619px;
  width: 2773px;
`;

const Ellipse = styled.div`
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(168, 180, 186, 0.1) 0%,
    rgba(213, 213, 213, 0.1) 100%
  );
  border-radius: 522px;
  height: 1044px;
  left: 1568px;
  opacity: 0.4;
  position: absolute;
  top: 273px;
  width: 1044px;
`;

const Div = styled.div`
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(213, 213, 213, 0.4) 0%,
    rgba(168, 180, 186, 0) 100%
  );
  border-radius: 522px;
  height: 1044px;
  left: 444px;
  opacity: 0.6;
  position: absolute;
  top: 499px;
  width: 1044px;
`;

const Ellipse2 = styled.div`
  width: 1044px;
  height: 1044px;
  position: absolute;
  left: 1392px;
  top: 204px;
  opacity: 0.6;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(213, 213, 213, 0.4) 0%,
    rgba(168, 180, 186, 0) 100%
  );
  border-radius: 522px;
`;

const Ellipse3 = styled.div`
  border: 6px solid;
  border-color: #a8b4ba4c;
  border-radius: 420.5px/420px;
  height: 840px;
  left: 320px;
  position: absolute;
  top: 51%;
  transform: rotate(50.16deg);
  width: 841px;
`;

const Ellipse4 = styled.div`
  border: 6px solid;
  border-color: #a8b4ba4c;
  border-radius: 420.5px;
  height: 841px;
  left: 172px;
  position: absolute;
  top: 49%;
  transform: rotate(50.16deg);
  width: 841px;
`;

const Ellipse5 = styled.div`
  border: 6px solid;
  border-color: #a8b4ba4c;
  border-radius: 420.5px/420px;
  height: 840px;
  left: 1761px;
  position: absolute;
  top: 225px;
  transform: rotate(50.16deg);
  width: 841px;
`;

const Ellipse6 = styled.div`
  border: 6px solid;
  border-color: #a8b4ba4c;
  border-radius: 420.5px;
  height: 841px;
  left: 1613px;
  position: absolute;
  top: 172px;
  transform: rotate(50.16deg);
  width: 841px;
`;

const Ellipse7 = styled.div`
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(213, 213, 213, 0.4) 0%,
    rgba(168, 180, 186, 0) 100%
  );
  border-radius: 522px;
  height: 1044px;
  left: 1138px;
  opacity: 0.4;
  position: absolute;
  top: 1285px;
  width: 1044px;
`;

const LayoutBackdrop: React.FC = () => {
  return (
    <BackdropContainer>
      <OverlapGroupWrapper
        style={{ backgroundImage: `url(${RectangleImage})` }}
      >
        <OverlapGroup>
          <Ellipse />
          <Div />
          <Ellipse2 />
          <Ellipse3 />
          <Ellipse4 />
          <Ellipse5 />
          <Ellipse6 />
          <Ellipse7 />
        </OverlapGroup>
      </OverlapGroupWrapper>
    </BackdropContainer>
  );
};

export default LayoutBackdrop;
