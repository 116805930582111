import React from 'react';
import styled from 'styled-components';
import SageWatermark from '@icons/SageWatermark';

interface StartBackdropContainerProps {
  customTop?: string;
}

const StartBackdropContainer = styled.div<StartBackdropContainerProps>`
  position: fixed;
  // top: 0;
  top: ${props => props.customTop || '0'};
  z-index: -1;
  width: 100%;
  height: 90vh;
`;

const SvgWrapper = styled.div`
  position: absolute;
  top: 27%;
  right: 3%;
  bottom: 0;
  svg {
    width: 100%;
    height: 100%;
  }
`;

interface StartPageOverlayProps {
  customTop?: string;
}

const StartPageOverlay: React.FC<StartPageOverlayProps> = ({ customTop }) => {
  return (
    <StartBackdropContainer customTop={customTop}>
      <SvgWrapper>
        <SageWatermark />
      </SvgWrapper>
    </StartBackdropContainer>
  );
};

export default StartPageOverlay;
