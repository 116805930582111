/* eslint-disable react/prop-types */
import * as React from 'react';

function SwapIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="25"
      style={props.style}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Arrows">
        <path
          id="Path"
          d="M16.5 13.5381L19.5 16.5381L16.5 19.5381"
          stroke={props.color || '#667085'}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Path_2"
          d="M4.5 16.5381H19.5"
          stroke={props.color || '#667085'}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Path_3"
          d="M7.5 10.5381L4.5 7.53809L7.5 4.53809"
          stroke={props.color || '#667085'}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Path_4"
          d="M19.5 7.53809H4.5"
          stroke={props.color || '#667085'}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

const MemoNoData = React.memo(SwapIcon);
export default MemoNoData;
