import styled from 'styled-components';
import { Grid, Accordion as MuiAccordion } from '@mui/material';
import { AccordionSummary as MuiAccordionSummary } from '@mui/material';
import { Typography as MuiTypography } from '@mui/material';
import { AccordionDetails as MuiAccordionDetails } from '@mui/material';
import Button from '@mui/material/Button';

export const InsightPageWrapper = styled.div`
  width: 100%;
  padding: 0px 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
`;

export const InsightCardContainer = styled.div`
  display: flex;
  width: 96%;
  flex-direction: column;
  align-items: flex-start;
  padding-right: 32px;
  margin-bottom: 10px;
`;
export const InsightCardWrapper = styled.div`
  width: 100%;
  padding: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid #e3e3e3;
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.12);
  background: #fff;
  z-index: 999;
`;

export const DataSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
`;

export const StyledTitle = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  font-size: 18px;
  color: #000;
  padding-bottom: 10px;
`;

export const StyledDesc = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  color: #4d4d4d;
  white-space: pre-line;
`;
export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 26%;
  justify-content: space-between;
  align-items: center;
`;

export const InsightPageContentContainer = styled.div`
  overflow-y: auto;
  max-height: 78vh;
  height: 78vh;
  overflow-x: hidden;
  position: relative;
`;

export const InsightPageContainer = styled.div``;

export const PaginationContainer = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  background: transparent;
  padding: 0px 10px 10px 0px;
  display: flex;
  justify-content: space-between;
`;

export const DateWrapper = styled.div`
  width: 100%;
  height: 40px;
  font-size: 20px;
  color: var(--silver-400, #bfbfbf);
  padding: 0px 15px 0px 2px;
  /* Add padding top to all instances except the first one */
  &:not(:first-of-type) {
    padding-top: 10px; /* Adjust the value as needed */
  }
`;

export const StyledDrawerContainer = styled.div`
  width: 88%;
  display: flex;
  height: 91vh;
  flex-direction: column;
  padding: 30px 25px;
`;
export const StyledAccordionContainer = styled.div`
  width: 100%;
  padding: 20px 10px 5px 5px;
  position: relative;
  height: 75%;
  overflow-y: auto;
`;
export const ImplementationHeader = styled.div`
  font-size: 18px;
  color: #000;
`;

export const AccordionContainer = styled.div`
  width: 100%;
  padding: 15px 0px 0px 0px;
  flex-direction: column;
  display: flex;
`;

export const AccordionHeaderText = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 12px;
  padding-bottom: 5px;
`;

export const FooterButtonWrapper = styled(Grid)`
  // display: flex;
  // justify-content: center;
  width: 100%;
  // align-items: center;
  padding: 0px 30px;
`;

export const FooterButtonContainer = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  background: transparent;
  height: 9vh;
  // border: 1px solid #e4e7ec;
  display: flex;
  align-items: center;
`;

export const StyledAccordion = styled(MuiAccordion)`
  && {
    border: 1px solid #e4e7ec;
    border-radius: 8px;
    background: var(--neutral-50, #f9fafb);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.12);
  }
`;

export const StyledAccordionSummary = styled(MuiAccordionSummary)`
  && {
    // height: 60px;
    // min-height: 60px;
    border-bottom: 1px solid #e4e7ec;
    padding: 0px 5px 0px 15px;
    align-items: center;
  }
`;

export const BoltIconDiv = styled.div`
  margin-right: 10px;
  display: flex;
  align-items: center;
`;

export const StyledTypography = styled(MuiTypography)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 5px;
`;

export const StyledAccordionDetails = styled(MuiAccordionDetails)`
  max-height: 195px;
  overflow-y: auto;
  background: #fff;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;

export const DetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 98%;
`;

export const DetailsDataContainer = styled.div`
  border-bottom: 1px solid #e4e7ec;
  padding: 12px 20px;
  display: flex;
  flex-direction: row;
  width: 93%;
`;

export const DetailsDataWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 80%;
`;

export const VerticleSeparator = styled.div`
  margin: 0px 8px;
  border-right: 1px solid #e4e7ec;
`;

export const StyledTagText = styled(MuiTypography)`
  color: var(--neutral-500, #667085);
  font-size: 12px;
  display: flex;
  align-items: center;
`;

export const StyledValueTextWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 20%;
`;

export const SecondAccordionContainer = styled.div`
  width: 102%;
  flex-direction: column;
  display: flex;
`;

export const SecondAccordionHeaderText = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 12px;
  padding-bottom: 10px;
`;

export const SecondContainer = styled.div`
  height: 210px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const SecondAccordionDataWrapper = styled.div`
  display: flex;
  width: 98%;
  flex-direction: column;
  height: auto;
`;

export const StyledSecondAccordion = styled(MuiAccordion)`
  && {
    margin-bottom: 10px;
    border: 1px solid #e4e7ec;
    border-radius: 8px;
    background: var(--neutral-50, #f9fafb);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.12);

    &:before {
      height: 0;
    }
  }
`;

export const StyledSecondAccordionSummary = styled(MuiAccordionSummary)`
  && {
    // height: 60px;
    // min-height: 60px;
    display: flex;
    flex-direction: row;
    padding-left: 13px;
    justify-content: center;
    align-items: center;
    padding: 0px 5px 0px 20px;
  }
`;

export const StyledImgDiv = styled.div`
  margin-right: 10px;
  display: flex;
  align-items: center;
`;

export const StyledSecondAccordionTitleText = styled(MuiTypography)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 5px;
`;

export const StyledSecondAccordionTypeText = styled(MuiTypography)`
  color: var(--neutral-500, #667085);
  font-size: 12px;
  display: flex;
  align-items: center;
`;

export const StyledButton = styled(Button)`
  && {
    border-radius: 20px;
    color: #000;
    font-size: 14px;
    text-transform: none;
    width: 48%;
    background: linear-gradient(0deg, #fff 0%, #d5d5d5 921.25%);
    background-color: transparent;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.12);
    border: 1px solid #e4e7ec;
    &:disabled {
      cursor: not-allowed;
      color: #98a2b3;
      background-color: #f2f4f7;
    }
  }
`;

export const StyledApplyButton = styled(Button)`
  && {
    border-radius: 20px;
    background: #000;
    color: #fff;
    font-size: 14px;
    text-transform: none;
    width: 48%;
    &:hover {
      opacity: 0.9;
      background: #000;
    }
  }
`;

export const CardContainer = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 5px;
  padding-top: 15px;
  border-radius: 8px;
  background: var(--Silver-50, #f7f7f7);
  width: 95%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const FileNameWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 15px;
  width: 100%;
  padding-left: 13px;
  font-size: 20px;
  color: var(--Gray-1, #818181);
`;
