import React from 'react';
import { Modal, Typography } from '@mui/material';
import SuccessIcon from '@icons/Success';
import { StyledApplyButton } from '@pages/CIOViewpoints/styles';
import {
  SuccessIconWrapper,
  SuccessModalBtnWrapper,
  SuccessModalContainer,
  SuccessMsgWrapper,
} from './styles';

interface SuccessModalProps {
  isOpen: boolean;
  ctaMessage: string;
  cta: string;
  handleCtaClick: () => void;
  handleClose: () => void;
  message: string;
}

function SuccessModal({
  isOpen,
  ctaMessage,
  cta,
  message,
  handleClose = () => {},
  handleCtaClick = () => {},
}: SuccessModalProps): React.JSX.Element {
  return (
    <Modal open={isOpen} onClose={handleClose}>
      <SuccessModalContainer style={{ width: '300px' }}>
        <Typography
          variant="h6"
          component="div"
          style={{ textAlign: 'center' }}
        >
          {message}
        </Typography>
        <SuccessIconWrapper>
          <SuccessIcon />
        </SuccessIconWrapper>
        <SuccessMsgWrapper>{ctaMessage}</SuccessMsgWrapper>
        <SuccessModalBtnWrapper>
          <StyledApplyButton
            variant="outlined"
            onClick={handleCtaClick}
            style={{ width: '100%' }}
          >
            {cta}
          </StyledApplyButton>
        </SuccessModalBtnWrapper>{' '}
      </SuccessModalContainer>
    </Modal>
  );
}

export default SuccessModal;
