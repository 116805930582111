import instance from 'utils/instances';
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const addArchievedViewpoint = async (payload: any) => {
  const createResponse = await instance.post(
    '/viewPoints/archived-viewpoints/',
    payload,
    {
      headers: {
        'x-tifin-ai-auth': localStorage.getItem('x-tifin-ai-token'),
        'content-type': undefined,
      },
    },
  );
  return createResponse?.data;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const undoArchievedViewpoint = async (payload: any) => {
  const createResponse = await instance.delete(
    `/viewPoints/archived-viewpoints?id=${payload.id}`,
    {
      headers: {
        'x-tifin-ai-auth': localStorage.getItem('x-tifin-ai-token'),
        'content-type': undefined,
      },
    },
  );
  return createResponse?.data;
};
