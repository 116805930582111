/* eslint-disable react/prop-types */
import * as React from 'react';

function Ellipses(): React.JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M12.8 12C12.8 12.178 12.7472 12.352 12.6483 12.5C12.5494 12.648 12.4089 12.7634 12.2444 12.8315C12.08 12.8996 11.899 12.9174 11.7244 12.8827C11.5498 12.848 11.3895 12.7623 11.2636 12.6364C11.1377 12.5105 11.052 12.3502 11.0173 12.1756C10.9826 12.001 11.0004 11.82 11.0685 11.6556C11.1366 11.4911 11.252 11.3506 11.4 11.2517C11.548 11.1528 11.722 11.1 11.9 11.1C12.1387 11.1 12.3676 11.1948 12.5364 11.3636C12.7052 11.5324 12.8 11.7613 12.8 12ZM11.9 7.8C12.078 7.8 12.252 7.74722 12.4 7.64832C12.548 7.54943 12.6634 7.40887 12.7315 7.24442C12.7996 7.07996 12.8174 6.899 12.7827 6.72442C12.748 6.54984 12.6623 6.38947 12.5364 6.2636C12.4105 6.13774 12.2502 6.05202 12.0756 6.01729C11.901 5.98257 11.72 6.00039 11.5556 6.06851C11.3911 6.13663 11.2506 6.25198 11.1517 6.39999C11.0528 6.54799 11 6.722 11 6.9C11 7.1387 11.0948 7.36761 11.2636 7.5364C11.4324 7.70518 11.6613 7.8 11.9 7.8ZM11.9 16.2C11.722 16.2 11.548 16.2528 11.4 16.3517C11.252 16.4506 11.1366 16.5911 11.0685 16.7556C11.0004 16.92 10.9826 17.101 11.0173 17.2756C11.052 17.4502 11.1377 17.6105 11.2636 17.7364C11.3895 17.8623 11.5498 17.948 11.7244 17.9827C11.899 18.0174 12.08 17.9996 12.2444 17.9315C12.4089 17.8634 12.5494 17.748 12.6483 17.6C12.7472 17.452 12.8 17.278 12.8 17.1C12.8 16.8613 12.7052 16.6324 12.5364 16.4636C12.3676 16.2948 12.1387 16.2 11.9 16.2Z"
        fill="#86858B"
      />
    </svg>
  );
}

const MemoEllipses = React.memo(Ellipses);
export default MemoEllipses;
