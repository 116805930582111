import React, { useState } from 'react';
import { Grid } from '@mui/material';
import StartFilled from '@images/Essentials-1.svg';
import StartEmpty from '@images/StarEmpty.png';
import FeedbackNotes from '@images/FeedbackNotes.svg';
import { Box } from '@mui/system';
import { getChatInitResponseState } from '@features/Chat/selector';
import { useSelector } from 'react-redux';
import CustomTooltip from '@components/Tooltip';
import { removeInvalidMarkdown, removeTickerSpans } from '@common/string';
import { ChatFeedbackContainer } from './styles';
interface FeedbackRatingProps {
  onFeedbackSubmit: (data: {
    scale: string | number | null;
    chatLogId: string | null;
    comment: string | null;
  }) => void;
  chatLogId: string | null;
  scale: string | number | null;
  isFeedbackEnabled: boolean;
  hideTextAndIcon?: boolean;
  children?: React.JSX.Element | string;
}

const RATING_SCALE = [1, 2, 3, 4, 5];

const FeedbackForm2: React.FC<FeedbackRatingProps> = ({
  onFeedbackSubmit,
  scale,
  chatLogId,
  isFeedbackEnabled,
  children,
}): React.JSX.Element => {
  const [selectedRating, onChangeFeedbackRating] = useState(scale);

  const { chatHistory } = useSelector(getChatInitResponseState);

  const handleRatingChange = value => {
    if (isFeedbackEnabled) {
      onChangeFeedbackRating(value);
      if (selectedRating !== null) {
        onFeedbackSubmit({
          chatLogId,
          scale: value,
          comment: '',
        });
      }
    }
  };

  const copyText = () => {
    const text = chatHistory[chatHistory.length - 1]?.data?.find(
      response => response.type === 'markdown',
    )?.content;
    navigator.clipboard.writeText(
      removeTickerSpans(removeInvalidMarkdown(text)),
    );
  };

  const scaleRating = iconSize =>
    RATING_SCALE.map((ratingScale: any, index: number) => {
      return (
        <Grid key={'chat-history-feedback-' + index + '-rate-' + ratingScale}>
          <CustomTooltip title={'Rate this Response'}>
            <img
              src={
                selectedRating != null && selectedRating < ratingScale
                  ? StartEmpty
                  : StartFilled
              }
              alt="Rating image"
              style={{ cursor: 'pointer' }}
              height={iconSize ?? 18}
              width={iconSize ?? 18}
              onClick={() => handleRatingChange(ratingScale)}
            />
          </CustomTooltip>
        </Grid>
      );
    });
  return (
    <ChatFeedbackContainer container item>
      <Box display={'flex'} alignItems={'center'} columnGap={'10px'}>
        {scaleRating(20)} {children}
      </Box>

      <Grid
        onClick={copyText}
        alignItems={'center'}
        sx={{ margin: '0 10px 0 10px' }}
        item
      >
        <CustomTooltip title={'Copy'}>
          <img
            style={{ cursor: 'pointer' }}
            src={FeedbackNotes}
            alt="Feedback notes image"
            height={18}
            width={15}
          />
        </CustomTooltip>
      </Grid>
    </ChatFeedbackContainer>
  );
};

export default React.memo(FeedbackForm2);
