import React from 'react';
import { useDispatch } from 'react-redux';
import { actions } from '@features/Chat/slice';
import SuggestedFollowUps from '@components/ChatContent/SuggestedFollowUps';

type Suggestions = {
  chatId?: any;
  data: any;
};

const SuggestedQuestions = ({
  chatId = '',
  data = [],
}: Suggestions): React.JSX.Element => {
  const dispatch = useDispatch();
  const handleQuestionClick = question => {
    const isChatStreaming = localStorage.getItem('streaming') as string;
    if (isChatStreaming === 'true') {
      dispatch(actions.setSearchQuery(question));
    } else {
      dispatch(actions.searchQueryRequest({ question }));
    }
  };

  return (
    <SuggestedFollowUps
      key={chatId}
      questions={data}
      handleQuestionClick={handleQuestionClick}
    />
  );
};

export default SuggestedQuestions;
