import React from 'react';
import { Box } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {
  ApplyButton,
  ButtonBox,
  Description,
  InvestmentCard,
  InvestmentContainer,
  ViewImplementationButton,
} from './styles';
interface IInvestment {
  id: string;
  title: string;
  createdAt: string;
  desc: string;
}

interface IInvestmentProps {
  investments: IInvestment[];
}

function InvestmentInsightsList({
  investments,
}: IInvestmentProps): React.JSX.Element {
  return (
    <InvestmentContainer maxWidth="lg" sx={{ padding: 0 }}>
      {investments.map((item, index) => {
        return (
          <InvestmentCard key={index}>
            <Box style={{ flex: '70%' }}>
              <div>{item.title}</div>
              <Description>{item.desc}</Description>
            </Box>
            <ButtonBox>
              <Box style={{ display: 'flex', flexDirection: 'row' }}>
                <ViewImplementationButton variant="outlined" color="primary">
                  View Implementation&nbsp;&nbsp;
                  <ArrowForwardIosIcon
                    sx={{ fontSize: '12px', marginTop: '-2px' }}
                  />
                </ViewImplementationButton>
                <ApplyButton variant="contained" color="secondary">
                  Apply
                </ApplyButton>
              </Box>
            </ButtonBox>
          </InvestmentCard>
        );
      })}
    </InvestmentContainer>
  );
}

export default InvestmentInsightsList;
