import React from 'react';
import SendIcon from 'assets/icons/SendIcon';
import {
  InputField,
  QuestionMarkCircle,
  RevealText,
  // QuestionMarkCircle,
  // RevealText,
  SearchBoxContainer,
  SuffixIcon,
} from './styles';
import { useTheme } from '@mui/material';
import { Fade } from '@mui/material';
import BottomArrow from '../../reusable/BottomArrow';
type SearchBoxTypes = {
  userQuery: string;
  isStreaming: boolean;
  setUserQuery: (query: string) => void;
  onInputSubmit: () => void;
  handleQuestionMarkClick: () => void;
  isQuestionMarkHovered?: boolean;
  setIsQuestionMarkHovered?: any;
  showFooterArrow?: boolean;
};

function SearchBox(props: SearchBoxTypes): React.JSX.Element {
  const theme = useTheme();

  const {
    userQuery,
    isStreaming,
    setUserQuery,
    onInputSubmit,
    handleQuestionMarkClick,
    isQuestionMarkHovered,
    setIsQuestionMarkHovered,
    showFooterArrow = false,
  } = props;
  return (
    <SearchBoxContainer container>
      <Fade timeout={1500} in={showFooterArrow}>
        <div>
          <BottomArrow />
        </div>
      </Fade>
      <QuestionMarkCircle
        onClick={handleQuestionMarkClick}
        onMouseEnter={() => setIsQuestionMarkHovered(true)}
        onMouseLeave={() => setIsQuestionMarkHovered(false)}
        $expand={isQuestionMarkHovered}
      >
        {isQuestionMarkHovered ? null : <span>?</span>}
        {isQuestionMarkHovered && <RevealText>What can I ask Sage?</RevealText>}
      </QuestionMarkCircle>
      <InputField
        disabled={isStreaming}
        placeholder={'Begin typing to ask Sage a question...'}
        onChange={(e: any) => setUserQuery(e?.target?.value)}
        value={userQuery}
        expand={isQuestionMarkHovered}
        onKeyDown={(e: any) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            onInputSubmit();
            // props?.callback(e?.target?.value);
          }
        }}
      />

      <SuffixIcon onClick={onInputSubmit}>
        <SendIcon fill={theme.palette.primary['lightGrey']} />
      </SuffixIcon>
    </SearchBoxContainer>
  );
}

export default SearchBox;
