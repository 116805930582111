/* eslint-disable react/prop-types */
import * as React from 'react';

function ClientBrief(): React.JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M14.3437 7.06217L10.3793 5.62467L8.93742 1.65592C8.86638 1.46454 8.73847 1.29949 8.57089 1.18294C8.4033 1.06638 8.20405 1.00391 7.99992 1.00391C7.79578 1.00391 7.59653 1.06638 7.42894 1.18294C7.26136 1.29949 7.13345 1.46454 7.06242 1.65592L5.62492 5.6203L1.65617 7.06217C1.46479 7.13321 1.29974 7.26111 1.18318 7.4287C1.06662 7.59629 1.00415 7.79554 1.00415 7.99967C1.00415 8.20381 1.06662 8.40305 1.18318 8.57064C1.29974 8.73823 1.46479 8.86613 1.65617 8.93717L5.62054 10.3747L7.06242 14.3434C7.13345 14.5348 7.26136 14.6999 7.42894 14.8164C7.59653 14.933 7.79578 14.9954 7.99992 14.9954C8.20405 14.9954 8.4033 14.933 8.57089 14.8164C8.73847 14.6999 8.86638 14.5348 8.93742 14.3434L10.3793 10.3784L14.3437 8.93717C14.535 8.86613 14.7001 8.73823 14.8167 8.57064C14.9332 8.40305 14.9957 8.20381 14.9957 7.99967C14.9957 7.79554 14.9332 7.59629 14.8167 7.4287C14.7001 7.26111 14.535 7.13321 14.3437 7.06217ZM10.0355 9.43717C9.89873 9.48714 9.77449 9.56639 9.6715 9.66938C9.56851 9.77237 9.48926 9.89661 9.43929 10.0334L7.99992 13.9934L6.56242 10.0353C6.51248 9.89795 6.43305 9.77322 6.32971 9.66988C6.22637 9.56654 6.10164 9.48711 5.96429 9.43717L2.00617 7.99967L5.96429 6.56217C6.10164 6.51224 6.22637 6.4328 6.32971 6.32946C6.43305 6.22613 6.51248 6.10139 6.56242 5.96405L7.99992 2.00592L9.43742 5.96405C9.48738 6.10086 9.56663 6.2251 9.66962 6.32809C9.77261 6.43108 9.89685 6.51033 10.0337 6.5603L13.9918 7.9978L10.0355 9.43717Z"
        fill="#535256"
      />
    </svg>
  );
}

const MemoClientBrief = React.memo(ClientBrief);
export default MemoClientBrief;
