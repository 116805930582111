import { Grid } from '@mui/material';
import React from 'react';
import { ChatBoxContentWrapper } from './styles';
import { SuggestionsContainer } from './Suggestions';
import SearchCard from './SearchCard';
import {
  SuggestionCardHeight,
  SuggestionCardWidth,
} from '@components/SuggestionCard/styles';
import { HoldingListSliderWrapper } from '@components/HoldingListSlider/styles';

type SearchSuggestion = {
  id?: any;
  data: any;
};

function SearchSuggestions({
  id: chatId = '',
  data = [],
}: SearchSuggestion): React.JSX.Element {
  const sliderSettings = () => {
    return {
      className: 'slider variable-width',
      dots: true,
      infinite: false,
      variableWidth: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      adaptiveHeight: false,
      arrows: false,
    };
  };

  if (!data)
    return (
      <ChatBoxContentWrapper>
        {`Sorry, I wasn't able to process this request.`}
      </ChatBoxContentWrapper>
    );

  return (
    <SuggestionsContainer>
      <Grid
        container
        sx={{
          minWidth: '232px',
        }}
      >
        <HoldingListSliderWrapper {...sliderSettings()}>
          {data && Array.isArray(data) && data.length > 0 ? (
            data?.map((searchItem, searchKey: number) => {
              return (
                <div
                  key={`searchItem-${searchKey}-${chatId}`}
                  style={{
                    width: SuggestionCardWidth,
                    height: SuggestionCardHeight,
                    marginRight: '10px',
                  }}
                >
                  <SearchCard cardinfo={searchItem?.node} />
                </div>
              );
            })
          ) : (
            <></>
          )}
        </HoldingListSliderWrapper>
      </Grid>
    </SuggestionsContainer>
  );
}

export default SearchSuggestions;
