import RiskCircularProgress from '@components/UIComponents/CircularProgressBar';
import RiskBar from '@components/UIComponents/RiskBar';
import { Divider, Grid } from '@mui/material';
import React from 'react';

interface AssessmentRiskResultProps {
  riskCapacity?: number;
  riskPreference?: number;
  portfolioRisk?: number;
}

const AssessmentRiskResult = ({
  riskCapacity,
  riskPreference,
  portfolioRisk,
}: AssessmentRiskResultProps): React.JSX.Element => {
  return (
    <Grid item container rowGap={1.5} color={'#000'}>
      <Grid
        item
        container
        xs={12}
        sx={{ background: '#fff' }}
        padding={'70px 16px 16px 16px'}
        position="relative"
      >
        <Grid item xs={12} rowGap={1} height={'100px'} position="relative">
          <RiskBar
            riskPreference={riskPreference ?? 0}
            riskCapacity={riskCapacity ?? 0}
            preferenceLabel="Preference"
            capacityLabel="Capacity"
            showZeroLabel={true}
            showHundredLabel={true}
            // hoverLine={false}
            // sx={{ zIndex: '0' }}
          />
          {portfolioRisk !== 0 &&
            portfolioRisk !== null &&
            portfolioRisk !== undefined && (
              <Grid
                item
                sx={{
                  position: 'absolute',
                  // top: '30%',
                  left: `calc(${portfolioRisk}% - 12.5px)`,
                  transform: 'translateY(-50%)',
                  zIndex: 1,
                }}
              >
                <RiskCircularProgress
                  value={portfolioRisk}
                  size="25px"
                  borderSize="5px"
                  fontSize="12px"
                />
              </Grid>
            )}
        </Grid>
        <Divider sx={{ width: '100%' }} />
        <Grid item container xs={12} pt={2} rowGap={2}>
          <Grid
            item
            container
            xs={6}
            sm={5.5}
            justifyContent={'flex-start'}
            alignItems={'center'}
          >
            <p
              style={{
                fontSize: '14px',
                color: '#535256',
                fontWeight: 600,
                width: '100%',
              }}
            >
              Preference
            </p>
            <p
              style={{
                fontSize: '13px',
                color: '#86858B',
                fontWeight: 400,
              }}
            >
              Willing to lose up to{' '}
              <strong style={{ fontSize: '14px', fontWeight: 600 }}>
                {riskPreference}%
              </strong>{' '}
              of their portfolio.
            </p>
          </Grid>
          <Grid
            item
            container
            xs={6}
            sm={5.5}
            justifyContent={'flex-start'}
            alignItems={'center'}
          >
            <p
              style={{
                fontSize: '14px',
                color: '#535256',
                fontWeight: 600,
                width: '100%',
              }}
            >
              Capacity
            </p>
            <p
              style={{
                fontSize: '13px',
                color: '#86858B',
                fontWeight: 400,
              }}
            >
              Can lose up to{' '}
              <strong style={{ fontSize: '14px', fontWeight: 600 }}>
                {riskCapacity}%
              </strong>{' '}
              of their portfolio without significant life impact.
            </p>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AssessmentRiskResult;
