import React, { useState } from 'react';
import CioCard from './CioCard';
import { useRESTQuery } from '@hooks/useRESTQuery';
import { useDispatch } from 'react-redux';
import { actions } from '@features/Chat/slice';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Drawer, Grid } from '@mui/material';
import InsightDrawer from '@pages/CIOViewpoints/InsightDrawer';
import Loader from '@components/Loader';
import { handleError } from '@common/error';
import {
  FileName,
  OneLineDesc,
  SuggestedCardContainer,
  TabHeader,
} from './styles';
import { ECURRENT_MARKET_TABS } from 'app/enums';

const useStyles = makeStyles({
  drawer: {
    width: 500,
  },
  drawerPaper: {
    width: 500,
  },
  root: {
    display: 'flex',
  },
});

const TABS = [...Object.values(ECURRENT_MARKET_TABS)];

function SuggestedActions(): React.JSX.Element {
  const [selectedTab, setSelectedTab] =
    React.useState<ECURRENT_MARKET_TABS | null>(ECURRENT_MARKET_TABS.CIO_VIEWS);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userType = localStorage.getItem('tifin-ai-user-type');
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);
  const [viewpointsData, setViewpointsData] = useState<any>({});
  const [archiveData, setArchiveData] = useState<any>([]);
  const [selectedData, setDrawerData] = useState({});
  const [currentEventsData, setCurrentEventsData] = useState<any>([]);
  const isChatStreaming = localStorage.getItem('streaming') as string;

  const toggleTabHeader = (item: ECURRENT_MARKET_TABS) => {
    setSelectedTab(item === selectedTab ? null : item);
  };
  const handleTabKeyDown = (
    event: React.KeyboardEvent,
    item: ECURRENT_MARKET_TABS,
  ) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      toggleTabHeader(item);
    } else if (event.key === 'ArrowRight') {
      event.preventDefault();
      const nextTabIndex = (TABS.indexOf(item) + 1) % TABS.length;
      const nextTab = TABS[nextTabIndex];
      setSelectedTab(nextTab);
    } else if (event.key === 'ArrowLeft') {
      event.preventDefault();
      const prevTabIndex = (TABS.indexOf(item) - 1 + TABS.length) % TABS.length;
      const prevTab = TABS[prevTabIndex];
      setSelectedTab(prevTab);
    }
  };

  const { data: archivedViewpoints, isLoading: archivedLoading } = useRESTQuery(
    ['get-archived-viewpoints'],
    {
      endpoint: `/viewPoints/archived-viewpoints`,
      method: 'GET',
      headers: {
        'X-Tifin-Ai-Auth': localStorage.getItem('x-tifin-ai-token'),
      },
    },
    {
      select: response => response?.archivedViewpoints,
      onError: (error: any) => {
        handleError(dispatch, error);
      },
    },
  );

  const { data: cioData, isLoading: cioLoading } = useRESTQuery(
    ['get-cio-viewpoints'],
    {
      endpoint: `/viewpoints/`,
      method: 'GET',
      headers: {
        'X-Tifin-Ai-Auth': localStorage.getItem('x-tifin-ai-token'),
      },
    },
    {
      select: response => response,
      onError: (error: any) => {
        handleError(dispatch, error);
      },
    },
  );

  const { data: currentData, isLoading: currentEventsLoading } = useRESTQuery(
    ['get-current-events'],
    {
      endpoint: `/viewpoints/currentEvents`,
      method: 'GET',
      headers: {
        'X-Tifin-Ai-Auth': localStorage.getItem('x-tifin-ai-token'),
      },
    },
    {
      select: res => res.data,
      onError: (error: any) => {
        handleError(dispatch, error);
      },
    },
  );

  React.useEffect(() => {
    if (cioData && archivedViewpoints && currentData) {
      const filteredData =
        Array.isArray(cioData.data) && cioData.data?.length > 0
          ? cioData.data
              ?.map(item => {
                const filteredViewpoints =
                  Array.isArray(item.data) &&
                  item.data?.length > 0 &&
                  item.data.filter(viewpoint => {
                    return !archivedViewpoints.some(
                      archived => archived.viewpointId === viewpoint.id,
                    );
                  });
                if (filteredViewpoints.length > 0) {
                  return {
                    filename: item.filename,
                    data: filteredViewpoints,
                  };
                }
                return null;
              })
              .filter(Boolean)
          : [];
      setViewpointsData({ data: filteredData });

      const archiveFilteredData =
        Array.isArray(cioData.data) && cioData.data.length > 0
          ? cioData.data.flatMap(item => {
              const newArchivedViewpoints =
                Array.isArray(item.data) &&
                item.data.length > 0 &&
                item.data.filter(viewpoint => {
                  return archivedViewpoints.some(
                    archived => archived.viewpointId === viewpoint.id,
                  );
                });

              if (newArchivedViewpoints.length > 0) {
                return newArchivedViewpoints;
              }
              return null;
            })
          : [];

      const filteredCurrentEvents =
        Array.isArray(currentData) && currentData.length > 0
          ? currentData.filter(viewpoint => {
              return archivedViewpoints.some(
                archived => archived.viewpointId === viewpoint.id,
              );
            })
          : [];

      const combinedArchiveData = [
        ...archiveFilteredData,
        ...filteredCurrentEvents,
      ];

      if (combinedArchiveData.length > 0) {
        setArchiveData(combinedArchiveData);
      }
    }

    if (archivedViewpoints) {
      const filteredCurrentEvents =
        Array.isArray(currentData) && currentData.length > 0
          ? currentData.filter(viewpoint => {
              return !archivedViewpoints.some(
                archived => archived.viewpointId === viewpoint.id,
              );
            })
          : [];

      setCurrentEventsData(filteredCurrentEvents);
    }
  }, [cioData, archivedViewpoints, currentData]);

  const { data: clientCount } = useRESTQuery(
    ['get-clients'],
    {
      endpoint: `/client`,
      method: 'GET',
      headers: {
        'X-Tifin-Ai-Auth': localStorage.getItem('x-tifin-ai-token'),
      },
    },
    {
      refetchOnMount: true,
      select: response => response?.totalCount,
      onError: (error: any) => {
        handleError(dispatch, error);
      },
    },
  );

  const triggerQuery = query => {
    if (userType === 'FIRM_ADMIN' || userType === 'ADVISOR') {
      navigate('/conversations/start');
      if (isChatStreaming === 'true') {
        dispatch(actions.setSearchQuery(query));
      } else {
        dispatch(actions.searchQueryRequest({ query }));
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
    setDrawerData({});
  };
  if (archivedLoading || cioLoading || currentEventsLoading) return <Loader />;

  return (
    <SuggestedCardContainer>
      <Grid item container>
        <Grid
          item
          container
          sx={{
            borderBottom: '1px solid #e4e7ec',
          }}
        >
          <TabHeader
            active={selectedTab === ECURRENT_MARKET_TABS.CIO_VIEWS}
            onClick={() => toggleTabHeader(ECURRENT_MARKET_TABS.CIO_VIEWS)}
            onKeyDown={event =>
              handleTabKeyDown(event, ECURRENT_MARKET_TABS.CIO_VIEWS)
            }
            tabIndex={0}
            role="cio views tab"
            aria-selected={selectedTab === ECURRENT_MARKET_TABS.CIO_VIEWS}
          >
            <p className="tab">CIO Views</p>
          </TabHeader>
          {/* THIS SHOULD GO NEXT WEEK */}
          {/* <TabHeader
            active={selectedTab === ECURRENT_MARKET_TABS.MODEL_COMMENTARY}
            onClick={() =>
              toggleTabHeader(ECURRENT_MARKET_TABS.MODEL_COMMENTARY)
            }
            onKeyDown={event =>
              handleTabKeyDown(event, ECURRENT_MARKET_TABS.MODEL_COMMENTARY)
            }
            tabIndex={0}
            role="current event tab"
            aria-selected={
              selectedTab === ECURRENT_MARKET_TABS.MODEL_COMMENTARY
            }
          >
            <p className="tab">Model Commentary</p>
          </TabHeader> */}
          <TabHeader
            active={selectedTab === ECURRENT_MARKET_TABS.ARCHIVE}
            onClick={() => toggleTabHeader(ECURRENT_MARKET_TABS.ARCHIVE)}
            onKeyDown={event =>
              handleTabKeyDown(event, ECURRENT_MARKET_TABS.ARCHIVE)
            }
            tabIndex={0}
            role="archive tab"
            aria-selected={selectedTab === ECURRENT_MARKET_TABS.ARCHIVE}
          >
            <p className="tab">Archive</p>
          </TabHeader>
        </Grid>
      </Grid>

      {(() => {
        switch (selectedTab) {
          case ECURRENT_MARKET_TABS.CIO_VIEWS /*Case 0 */:
            return (
              <Grid item xs={12}>
                <OneLineDesc>
                  Viewpoints derived from research, translating insights into
                  actionable strategies, enabling you to quickly identify which
                  clients could benefit and see suggested adjustments that can
                  prepare portfolios for future market moves.
                </OneLineDesc>
              </Grid>
            );
          case ECURRENT_MARKET_TABS.MODEL_COMMENTARY:
            return (
              <Grid item xs={12}>
                <OneLineDesc>
                  Insights derived from model commentary, providing analysis on
                  the latest updates for models and model providers that you
                  leverage for your book of business.
                </OneLineDesc>
                <OneLineDesc>Model Commentary Coming Soon!</OneLineDesc>
              </Grid>
            );
          case ECURRENT_MARKET_TABS.CURRENT_EVENTS:
            return (
              <Grid item xs={12}>
                <OneLineDesc>
                  Viewpoints derived from recent market movements and
                  macroeconomic indicators, enabling you to quickly identify
                  which clients could be impacted.
                </OneLineDesc>
              </Grid>
            );
          default:
            return <></>;
        }
      })()}

      <Grid container>
        {(() => {
          switch (selectedTab) {
            case ECURRENT_MARKET_TABS.CIO_VIEWS /*Case 0 */:
              return viewpointsData?.data?.map(item => {
                if (
                  JSON.stringify(item?.data) !== '[{}]' &&
                  item?.data !== null
                )
                  return (
                    <Grid item key={item?.filename} container rowGap={2}>
                      <Grid item>
                        <FileName>{item?.filename}</FileName>
                      </Grid>
                      <Grid item container>
                        <CioCard
                          triggerQuery={triggerQuery}
                          data={item.data}
                          clientCount={clientCount}
                        />
                      </Grid>
                    </Grid>
                  );
              });
            case ECURRENT_MARKET_TABS.CURRENT_EVENTS:
              return (
                <Grid item container mt={2}>
                  <CioCard
                    key={0}
                    data={currentEventsData}
                    triggerQuery={triggerQuery}
                    clientCount={clientCount}
                  />
                </Grid>
              );
            default:
              return (
                <Grid item container mt={2}>
                  <CioCard
                    key={0}
                    data={archiveData}
                    triggerQuery={triggerQuery}
                    clientCount={clientCount}
                    isArchived={true}
                  />
                </Grid>
              );
          }
        })()}
      </Grid>
      <div className={classes.root}>
        <Drawer
          anchor="right"
          variant="temporary"
          open={open}
          className={classes.drawer}
          classes={{ paper: classes.drawerPaper }}
          onClose={handleClose}
        >
          <InsightDrawer
            selectedData={selectedData}
            triggerQuery={triggerQuery}
            handleClose={handleClose}
          />
        </Drawer>
      </div>
    </SuggestedCardContainer>
  );
}

export default SuggestedActions;
