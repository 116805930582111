import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid } from '@mui/material';

interface AccordionProps {
  data: { key: string; value: any }[];
}

export default function GenericAccordion({
  data,
}: AccordionProps): React.JSX.Element {
  return (
    <Grid container xs={12}>
      {data?.map((item, index) => (
        <Accordion key={index}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            {item?.key}
          </AccordionSummary>
          <AccordionDetails>{item.value}</AccordionDetails>
        </Accordion>
      ))}
    </Grid>
  );
}
