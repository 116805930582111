import { handleError } from '@common/error';
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import ApiInstance from '@instances/index';
import { actions as globalActions } from 'app/features/Global/slice';

import { IAddClient } from '@pages/ClientsV2/mutation';

// API call function
export const AddClient = async (payload: IAddClient): Promise<any> => {
  const createResponse = await ApiInstance.post('/client/', payload, {
    headers: {
      'X-Tifin-Ai-Auth': localStorage.getItem('x-tifin-ai-token'),
    },
  });
  return createResponse?.data;
};
interface ClientPayload {
  firstName: string;
  lastName: string;
  email: string;
  advisorId: string;
  isProspect: boolean;
  selectedMenuItem: string;
  step: number;
  setClientId: React.Dispatch<React.SetStateAction<string>>;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  setCleanUpAddClientForm: React.Dispatch<React.SetStateAction<boolean>>;
}

interface AddClientResponse {
  data: {
    id: string;
  };
}

// Define the return type for useCreatePortfolio
type UseAddClientResult = UseMutationResult<
  AddClientResponse,
  any,
  ClientPayload
>;

const useAddClient = (): UseAddClientResult => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation(
    (payload: ClientPayload) =>
      AddClient({
        firstName: payload.firstName,
        lastName: payload.lastName,
        email: payload.email,
        advisorId: payload.advisorId,
        isProspect: payload.isProspect,
      }),
    {
      onSuccess: (
        res: any,
        {
          step,
          selectedMenuItem,
          isProspect,
          setClientId,
          setStep,
          setCleanUpAddClientForm,
        },
      ) => {
        if (res && step === 1) {
          dispatch(
            globalActions.displayToast({
              duration: 3000,
              toastType: 'success',
              toastMessage:
                selectedMenuItem === 'Add Client'
                  ? 'Client added succesfully.'
                  : 'Prospect added successfully',
            }),
          );
          if (isProspect) {
            queryClient.invalidateQueries('get-prospects');
          } else {
            queryClient.invalidateQueries('get-clients');
          }
          setStep(step + 1);
          setClientId(res?.data?.id);
          setCleanUpAddClientForm(true);
        }
      },

      onError: (error: any) => {
        handleError(dispatch, error);
      },
    },
  );
};

export default useAddClient;
