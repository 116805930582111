import * as React from 'react';

function File(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 16 17" fill="none" {...props}>
      <path
        d="M13.354 5.646l-3.5-3.5A.5.5 0 009.5 2h-6a1 1 0 00-1 1v11a1 1 0 001 1h9a1 1 0 001-1V6a.499.499 0 00-.146-.354zM10 3.706L11.793 5.5H10V3.707zM12.5 14h-9V3H9v3a.5.5 0 00.5.5h3V14z"
        fill="#535256"
      />
    </svg>
  );
}

const MemoFile = React.memo(File);
export default MemoFile;
