/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import ApiInstance from 'utils/instances';

export const getQueries = async (): Promise<any> => {
  return await ApiInstance.get(`/tests/queries`, {
    headers: {
      'x-tifin-ai-auth': localStorage.getItem('x-tifin-ai-token'),
      'ngrok-skip-browser-warning': '69420',
    },
  }).then(res => res.data);
};
