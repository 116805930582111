import { Grid } from '@mui/material';
import React, { useState, useRef, useEffect } from 'react';
import { ReadMoreParagraphBoldtText } from './styles';

interface Props {
  text: string;
  maxHeight: number; // Maximum height in pixels
  lineClamp?: number;
}

const ReadMoreReadLess: React.FC<Props> = ({
  text,
  maxHeight,
  lineClamp = 5,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const textRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (textRef.current) {
      // Check if the text height exceeds the maxHeight
      setIsOverflowing(textRef.current.scrollHeight > maxHeight);
    }
  }, [text]);

  const toggleIsExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Grid
      item
      height={maxHeight}
      sx={{
        overflowY: 'auto',
        width: '100%',
      }}
    >
      <Grid
        item
        container
        justifyContent={'space-between'}
        sx={{ width: '97%' }}
      >
        <div>
          <ReadMoreParagraphBoldtText
            lineClamp={lineClamp}
            fontSize={'14px'}
            ref={textRef}
            style={{
              maxHeight: isExpanded ? 'none' : `${maxHeight}px`,
              overflow: 'hidden',
            }}
            className={isOverflowing && !isExpanded ? 'ellipses' : ''}
          >
            {text}
          </ReadMoreParagraphBoldtText>
          {isOverflowing && (
            <span
              onClick={toggleIsExpanded}
              style={{
                cursor: 'pointer',
                textDecoration: 'underline',
                fontWeight: 400,
                fontSize: '14px',
                color: '#535256',
                display: 'flex',
                alignItems: 'flex-end',
                marginTop: '10px',
              }}
            >
              {isExpanded ? 'View Less' : 'View More'}
            </span>
          )}
        </div>
      </Grid>
    </Grid>
  );
};

export default ReadMoreReadLess;
