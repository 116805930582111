import React, { useState } from 'react';
import Papa from 'papaparse';
import {
  Box,
  Button,
  IconButton,
  LinearProgress,
  Typography,
} from '@mui/material';
import {
  FileUploadContainer,
  UploadFileLabel,
  UploadFileLabelContainer,
} from './styles';
import { ReactComponent as UploadIcon } from '@images/uploadIcon2.svg';
import { ReactComponent as DownloadIcon } from '@images/ArrowLineDown2.svg';
import { downloadSampleFile } from '@common/downloadSampleFile';
import { sampleCsvData } from '@common/constant';
import { DropzoneArea } from 'react-mui-dropzone';
import CloseIcon from '@mui/icons-material/Close';
import MemoFile from '@icons/File';
import { transformCSVData } from '@common/transform';
import { CSVRow, ICsvUploadProps, Model } from './types';

interface ModelData {
  name: string;
  ticker: string;
  weight: number;
}

const CustomDragzoneUI = () => {
  return (
    <FileUploadContainer
      item
      sx={{
        width: '100%',
      }}
    >
      <UploadFileLabelContainer htmlFor="uploadCSV" tabIndex={0}>
        <UploadIcon />
        <UploadFileLabel>
          Click to upload Custom Models{' '}
          <Box component="span" color={'#86858B'}>
            &nbsp; or drag and drop
          </Box>
        </UploadFileLabel>
        <Typography color={'#86858B'} fontSize={'12px'}>
          Limit: 1 file per upload
        </Typography>
        <Button
          onClick={e => {
            e.stopPropagation();
            downloadSampleFile(sampleCsvData);
          }}
          sx={{
            fontSize: '12px !important',
            textTransform: 'capitalize',
          }}
          variant="text"
          color="primary"
        >
          Download File Template
          <DownloadIcon />
        </Button>
      </UploadFileLabelContainer>
    </FileUploadContainer>
  );
};

const CsvUpload: React.FC<ICsvUploadProps> = ({
  setModelList,
  setErrorMessage,
}) => {
  const maxkb = 500;
  const maxAllowedSize = maxkb * 1024;
  const [uploadProgress, setUploadProgress] = useState<number | null>(null);
  const [fileName, setFileName] = useState<string | null>(null);
  const [fileSize, setFileSize] = useState<number | null>(null);
  const requiredFields = ['model_name', 'ticker', 'weight'];

  const resetFileInput = () => {
    setFileName(null);
    setFileSize(null);
    setUploadProgress(null);
  };

  const handleRemoveFile = () => {
    resetFileInput();
    setModelList([]);
    setErrorMessage('');
  };

  // INFO: This is manual stimulation of progress to display progress on UI, it's not according to file upload
  const simulateProgress = (callback: () => void) => {
    let progress = 0;
    const interval = setInterval(() => {
      progress += 10;
      setUploadProgress(progress);
      if (progress >= 100) {
        clearInterval(interval);
        callback(); // Trigger the parsing process when the progress reaches 100%
      }
    }, 100); // Adjust the interval time as needed
  };

  const validateCSV = (
    data: CSVRow[],
    requiredColumns: string[],
  ): {
    error: string | null;
    data: Model[];
  } => {
    const updatedData = transformCSVData(data, requiredFields);

    if (updatedData.length === 0) {
      return {
        error:
          'File upload failed - Incorrect format. Please download the template.',
        data: [],
      };
    }
    const requiredColumnValidation = requiredColumns.slice(1);

    for (const model of updatedData) {
      if (!model.name) {
        return {
          error: 'File upload failed - model name is empty.',
          data: [],
        };
      }

      const modelName = model.name;
      let totalWeight = 0;
      for (const row of model.holdings) {
        // Check for missing or empty fields
        const rowHasError = requiredColumnValidation.some(field => {
          return !row[field];
        });

        if (rowHasError) {
          return {
            error:
              'File upload failed - either some fields are incorrect or missing',
            data: [],
          };
        }

        // Convert weight to a number if it's a string
        const weight =
          typeof row.weight === 'string' ? parseFloat(row.weight) : row.weight;

        if (isNaN(weight)) {
          return {
            error: 'File upload failed - some weights are not valid numbers.',
            data: [],
          };
        }

        totalWeight += weight;
      }
      if (totalWeight !== 100) {
        return {
          error: `File upload failed - the total weights for ${modelName} must add up to 100.`,
          data: [],
        };
      }
    }

    return {
      error: null,
      data: updatedData,
    };
  };

  const fileUpload = (file: any) => {
    if (file) {
      setFileName(file.name);
      setFileSize(file.size);
      setUploadProgress(0);
      simulateProgress(() => {
        Papa.parse<ModelData>(file, {
          header: true,
          complete: result => {
            const { error: validationError, data } = validateCSV(
              result.data,
              requiredFields,
            );

            if (validationError) {
              setErrorMessage(validationError);
              setModelList([]);
              resetFileInput();
            } else {
              setModelList(data);
              setErrorMessage('');
              setUploadProgress(100);
            }
          },
          error: error => {
            console.error('Error parsing CSV:', error);
            setErrorMessage(error.message);
            setModelList([]);
            resetFileInput();
          },
        });
      });
    }
  };

  const handleFileUpload = (event: any) => {
    const file = event?.target?.files?.[0];
    fileUpload(file);
  };

  const onDrop = (droppedFiles: any) => {
    const file = droppedFiles[0];
    fileUpload(file);
  };

  return (
    <>
      {fileName ? (
        <Box
          display={'flex'}
          flexDirection={'column'}
          width={'100%'}
          bgcolor={'#F6F4FC'}
          p={2}
          mt={1}
          borderRadius={'4px'}
          fontSize={'14px'}
          rowGap={1}
        >
          <Box
            display={'flex'}
            justifyContent={'flex-start'}
            gap={1}
            alignItems={'center'}
          >
            <MemoFile width={'30px'} height={'30px'} />
            <Box>
              <Typography variant="body2">{fileName}</Typography>
              <Typography variant="body2" color={'#818181'}>
                {(fileSize! / 1024).toFixed(2)}kb
              </Typography>
            </Box>
            <Box
              display={'flex'}
              flexDirection={'column'}
              ml="auto"
              alignItems={'flex-end'}
            >
              <IconButton
                aria-label="close"
                onClick={handleRemoveFile}
                sx={{
                  p: '0',
                  color: '#000',
                }}
              >
                <CloseIcon />
              </IconButton>
              <Box component={'span'} color={'#818181'} fontSize={'12px'}>
                {uploadProgress}%
              </Box>
            </Box>
          </Box>
          {uploadProgress !== null && (
            <LinearProgress
              variant="determinate"
              value={uploadProgress}
              sx={{
                borderRadius: '4px',
              }}
            />
          )}
        </Box>
      ) : (
        <>
          <DropzoneArea
            acceptedFiles={['.csv']}
            classes={{
              root: 'custom-dropzone',
            }}
            showPreviewsInDropzone={false}
            showPreviews={false}
            showFileNames={false}
            useChipsForPreview
            filesLimit={1}
            maxFileSize={maxAllowedSize}
            onDrop={onDrop} // ON DROP HANDLER WORKS FIRST EVEN BEFORE OnChange.
            onChange={handleFileUpload}
            Icon={() => <CustomDragzoneUI />}
            dropzoneText=""
            previewText="Uploaded File Name:"
            key={1}
            showAlerts={['error']}
          />
          <Box
            display={'flex'}
            justifyContent={'space-between'}
            width={'100%'}
            fontSize={'12px'}
          >
            <Box component={'span'} color={'#535256'}>
              Supported formats: .CSV
            </Box>
            <Box component={'span'} color={'#535256'}>
              Maximum size: {maxkb}kb
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default CsvUpload;
