import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { price } from '@common/number';
import DataBanner from '@components/DataBanner';
import QuestionsCards from '@components/QuestionsCards';
import { actions as chatActions } from '@features/Chat/slice';
import { getAuthDetails } from '@features/Profile/selector';
import { useEnterpriseQueries } from '@features/enterprise-questions';
import colorLine from '@images/color-line.svg';
import IconDotChart from '@images/icon-dot-chart.svg';
import { Box, Grid } from '@mui/material';
import { GreyLabelText } from '@styles/Typography';
import { EConvertedQuestionMapping, EQuestionToNavigate } from 'app/enums';

function SubsequentLogin(): React.JSX.Element {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const advisorDetails = useSelector(getAuthDetails);

  const { questions, isLoaded } = useEnterpriseQueries();

  const handleWorkflowsQuestionClick = (question: string) => {
    // The normal operation of this method is to send the question to the backend and display the chat.
    // We have two special behaviors set adhoc in the code.

    // 1º If the question is EQuestionToNavigate.WhichViewpointsImpactClients we should go to /actions.
    if (question === EQuestionToNavigate.WhichViewpointsImpactClients) {
      navigate('/actions');
    } else {
      // 2º If the question is in EConvertedQuestionMapping, we must change the question before sending it to the backend.
      question = EConvertedQuestionMapping[question] || question;
      // If the question is not in EConvertedQuestionMapping, we follow the normal behavior.
      const isChatStreaming =
        (localStorage.getItem('streaming') as string) === 'true';
      if (isChatStreaming) {
        dispatch(chatActions.setSearchQuery(question));
      } else {
        dispatch(chatActions.searchQueryRequest({ query: question }));
      }
      navigate('/conversations/start');
    }
  };

  return (
    <Grid
      id="subsequent-login"
      container
      alignItems="center"
      overflow="auto"
      padding="48px"
      height="calc(100vh - 184px)"
    >
      <Grid container>
        <GreyLabelText
          fontSize="24px"
          color="#000"
          mt={1}
          letterSpacing="-1px"
          onClick={() => {
            const pendoWindow = window as any;
            pendoWindow.pendo.showGuideById('WgEI2ancgdEnrAd2Q0eX1vqln9c');
          }}
        >
          {advisorDetails?.firstName
            ? `Welcome back ${advisorDetails?.firstName},`
            : 'Welcome back'}
        </GreyLabelText>

        <Box
          sx={{
            width: '100%',
            paddingBottom: '24px',
            marginBottom: '32px',
            backgroundImage: `url('${colorLine}')`,
            backgroundRepeat: 'repeat-x',
            backgroundPositionY: 'bottom',
          }}
        >
          <GreyLabelText
            fontSize="48px"
            color="#000"
            fontWeight={700}
            fontFamily="Inter"
            letterSpacing="-3px"
            lineHeight="77px"
          >
            Here’s the latest on your book
          </GreyLabelText>
        </Box>

        <Grid container spacing={1}>
          <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
            <DataBanner
              title="AUM"
              mainNumber={price(advisorDetails.summary.totalAum)}
            />
          </Grid>
          <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
            <DataBanner
              title="Clients"
              mainNumber={advisorDetails.summary.noOfClients}
            />
          </Grid>
        </Grid>

        {isLoaded && (
          <QuestionsCards
            title="Workflows"
            titleIcon={IconDotChart}
            questions={questions}
            handleQuestionClick={handleWorkflowsQuestionClick}
          />
        )}
      </Grid>
    </Grid>
  );
}

export default SubsequentLogin;
