/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Grid } from '@mui/material';
import SuggestionCard from 'app/components/SuggestionCard';
import SwapIcon from 'assets/icons/swap';
import { ParagraphBoldtText } from 'utils/styles/Typography';
import HoldingListSlider from '../HoldingListSlider';
import { ChatBoxContentWrapper } from './styles';
import { ChatInterface } from 'app/features/Chat/types';
import { SuggestionCardWrapper } from '../SuggestionCard/styles';

export const SuggestionsContainer = styled(Grid)`
  width: 100%;
  padding: 0;
  color: ${props => props.theme.textBlack};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background: transparent;
`;

export const SuggestionsWrapper = styled(Grid)`
  width: 100%;
  max-width: 100%;
`;

type EnhanceReplacement = {
  ticker: string;
  weight?: number;
  replace: string;
};

function Suggestions({
  id: chatId,
  data = [],
}: ChatInterface): React.JSX.Element {
  const [enhanceList, updateEnhanceList] = useState<Array<EnhanceReplacement>>(
    [],
  );
  const [showFinalizeButton, setFinalizeButtonVisiblilty] =
    useState<boolean>(true);

  if (!data)
    return (
      <ChatBoxContentWrapper>
        {`Sorry, I wasn't able to process this request.`}
      </ChatBoxContentWrapper>
    );

  useEffect(() => {
    setInitialEnhanceListState();
  }, [data]);

  const setInitialEnhanceListState = () => {
    if (data && Array.isArray(data)) {
      const enhancedList: Array<EnhanceReplacement> = data.map(
        (holdingItem: any) => {
          const ticker: any =
            (holdingItem?.target_card &&
              holdingItem?.target_card.length > 0 &&
              holdingItem?.target_card[0] &&
              holdingItem?.target_card[0]?.cardinfo?.ticker) ||
            holdingItem?.ticker;
          return {
            ticker,
            weight: 100 / data.length,
            replace: '',
          };
        },
      );
      updateEnhanceList(enhancedList);
    }
  };

  const onTickerSelect = (event: any, data: EnhanceReplacement) => {
    if (!showFinalizeButton) {
      setFinalizeButtonVisiblilty(true);
    }
    const foundIndex: number = enhanceList.findIndex(
      (item: any) => item.ticker === data?.ticker,
    );
    if (
      enhanceList[foundIndex] &&
      typeof enhanceList[foundIndex].replace !== 'undefined'
    ) {
      if (foundIndex !== -1) {
        enhanceList[foundIndex].replace = data?.replace;
      } else {
        enhanceList[foundIndex].replace = '';
      }
    }

    updateEnhanceList(JSON.parse(JSON.stringify(enhanceList)));
  };

  return (
    <SuggestionsContainer>
      <Grid
        container
        sx={{
          minWidth: '232px',
        }}
      >
        <SuggestionsWrapper container rowGap={1.5} flexDirection={'column'}>
          {data &&
            Array.isArray(data) &&
            data
              .filter((k: any) => k.suggestions?.length > 0)
              .map((holdingItem: any, holdingIndex: number) => {
                const targetCardChartData: any =
                  holdingItem?.target_card &&
                  holdingItem?.target_card.length > 0 &&
                  holdingItem?.target_card[0] &&
                  holdingItem?.target_card[0]?.chart;
                const replaceTicker = enhanceList.find(
                  k =>
                    k.ticker === holdingItem?.target_card[0]?.cardinfo?.ticker,
                )?.replace;
                return (
                  <React.Fragment key={`holdingItem-${chatId}-${holdingIndex}`}>
                    <ParagraphBoldtText style={{ margin: '4px 0' }}>
                      {`Suggested Replacement Funds for ${holdingItem?.target_card[0]?.cardinfo?.ticker}`}
                    </ParagraphBoldtText>
                    <Grid container alignItems={'center'}>
                      {holdingItem?.target_card[0] && (
                        <Grid
                          item
                          xs={3}
                          container
                          pb={'1.3rem'}
                          alignItems={'flex-start'}
                          sx={{
                            height: '100%',
                          }}
                        >
                          <Grid
                            item
                            container
                            xs={11}
                            alignItems={'flex-start'}
                          >
                            <SuggestionCard
                              isTargetCard={true}
                              targetCardChartData={targetCardChartData}
                              cardinfo={holdingItem?.target_card[0]?.cardinfo}
                              chart={holdingItem?.target_card[0]?.chart}
                            />
                          </Grid>
                          <Grid container xs alignItems={'center'}>
                            <SwapIcon
                              style={{
                                width: '100%',
                                height: 'auto',
                              }}
                            />
                          </Grid>
                        </Grid>
                      )}
                      <Grid
                        item
                        xs={9}
                        sx={{
                          width: '100%',
                          height: '222px',
                        }}
                      >
                        {holdingItem?.suggestions?.length > 0 ? (
                          <HoldingListSlider
                            checkBoxDisable={!chatId || !showFinalizeButton}
                            holdingIndex={holdingIndex}
                            selectedTicker={replaceTicker}
                            suggestions={holdingItem?.suggestions}
                            targetCardChartData={targetCardChartData}
                            onSelect={(e: any, replace: string) =>
                              onTickerSelect(e, {
                                ticker:
                                  holdingItem?.target_card[0]?.cardinfo?.ticker,
                                replace,
                              })
                            }
                          />
                        ) : (
                          <SuggestionCardWrapper
                            container
                            rowGap={1}
                            width={'264px'}
                            height={'200px'}
                            alignItems={'center'}
                            justifyContent={'center'}
                            sx={{ margin: 0, background: 'none', border: 0 }}
                          >
                            {`No replacement found`}
                          </SuggestionCardWrapper>
                        )}
                      </Grid>
                    </Grid>
                  </React.Fragment>
                );
              })}
        </SuggestionsWrapper>
      </Grid>
    </SuggestionsContainer>
  );
}

export default Suggestions;
