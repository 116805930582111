import React from 'react';
import SendIcon from 'assets/icons/SendIcon';
import {
  InputField,
  QuestionMarkCircle,
  RevealText,
  SearchBoxContainer,
  SuffixIcon,
} from './styles';
import { useTheme } from '@mui/material';

type SearchBoxTypes = {
  userQuery: string;
  isStreaming: boolean;
  setUserQuery: (query: string) => void;
  onInputSubmit: () => void;
  handleQuestionMarkClick: () => void;
};

function SearchBox(props: SearchBoxTypes): React.JSX.Element {
  const theme = useTheme();
  const [isQuestionMarkHovered, setIsQuestionMarkHovered] =
    React.useState(false);
  const {
    userQuery,
    isStreaming,
    setUserQuery,
    onInputSubmit,
    handleQuestionMarkClick,
  } = props;
  return (
    <SearchBoxContainer container>
      <QuestionMarkCircle
        onClick={handleQuestionMarkClick}
        onMouseEnter={() => setIsQuestionMarkHovered(true)}
        onMouseLeave={() => setIsQuestionMarkHovered(false)}
      >
        {isQuestionMarkHovered ? null : <span>?</span>}
        {isQuestionMarkHovered && <RevealText>What can I ask Sage?</RevealText>}
      </QuestionMarkCircle>
      <InputField
        disabled={isStreaming}
        placeholder={'Begin typing to ask Sage a question ...'}
        onChange={(e: any) => setUserQuery(e?.target?.value)}
        value={userQuery}
        onKeyDown={(e: any) => {
          if (e.key === 'Enter' && userQuery.trim() !== '') {
            e.preventDefault();
            onInputSubmit();
          }
        }}
      />

      <SuffixIcon
        role="button"
        aria-label="chat enter button"
        onClick={() => {
          if (userQuery.trim() !== '') {
            onInputSubmit();
          }
        }}
      >
        <SendIcon fill={theme.palette.primary['lightGrey']} />
      </SuffixIcon>
    </SearchBoxContainer>
  );
}

export default SearchBox;
