import { RestQueryConfig, useRESTQuery } from '@hooks/useRESTQuery';
import IconDotLine from '@images/icon-dot-line.svg';
import IconDotPlot from '@images/icon-dot-plot.svg';
import IconPeople from '@images/icon-people.svg';
import { EGetStartedQueries } from 'app/enums';
import { useMemo } from 'react';

const categories = [
  {
    category: EGetStartedQueries.Viewpoints,
    title: 'Viewpoints',
    icon: IconPeople,
  },
  {
    category: EGetStartedQueries.Personalization,
    title: 'Personalization',
    icon: IconDotPlot,
  },
  {
    category: EGetStartedQueries.InvestmentSolutions,
    title: 'Investments',
    icon: IconDotLine,
  },
];

export type EnterpriseQuestions = {
  title: string;
  icon: string;
  questions: string[];
};

export type useEnterpriseQueriesReturnType = {
  questions: EnterpriseQuestions[];
  isLoaded: boolean;
};

export function useEnterpriseQueries(): useEnterpriseQueriesReturnType {
  const token = localStorage.getItem('x-tifin-ai-token');
  const queryKey = ['extract-questions'];
  const config: RestQueryConfig = {
    method: 'GET',
    endpoint: `/enterprise/enterpriseQuestions`,
    headers: { 'X-Tifin-Ai-Auth': token },
  };

  const { data } = useRESTQuery(queryKey, config, {
    select: res =>
      res.data.map(question => ({
        questions: question?.question,
        category: question?.category,
      })),
  });

  const questions = useMemo(
    () =>
      categories
        .map(({ category, title, icon }) => {
          const questions =
            data?.find(item => item.category === category)?.questions ?? [];

          return { title, icon, questions };
        })
        .filter(({ questions }) => questions.length),
    [data],
  );

  return { questions, isLoaded: !!data };
}
