// CustodianDrawer.tsx
import React from 'react';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import {
  StyledLoginWrapper,
  StyledLogoContainer,
  StyledLogoWrapper,
  StyledModalDescription,
  StyledModalTextContainer,
  StyledTextModal,
} from './styles';
import CloseIcon from '@mui/icons-material/Close';
import SageIcon from '@icons/OnlySage';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import { Button, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';

// Other imports...

// const style = {
//   position: 'absolute' as 'const',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   width: '70%',
//   bgcolor: 'background.paper',
//   boxShadow: 24,
//   borderRadius: 1.5,
//   p: 4,
// };
export const useStyles = makeStyles({
  drawer: {
    width: 600,
  },
  drawerPaper: {
    width: 600,
  },
  root: {
    display: 'flex',
  },
});

interface CustodianDrawerProps {
  open: boolean;
  setOpen: any;
  modalData: any;
}

const CustodianDrawer: React.FC<CustodianDrawerProps> = ({
  open,
  setOpen,
  modalData,
}) => {
  const classes = useStyles();
  const handleClose = () => setOpen(false);

  return (
    <Drawer
      anchor="right"
      variant="temporary"
      open={open}
      className={classes.drawer}
      classes={{ paper: classes.drawerPaper }}
      onClose={handleClose}
    >
      <Box sx={{ position: 'relative' }}>
        <StyledLoginWrapper>
          <StyledLogoContainer>
            <StyledLogoWrapper>
              <SageIcon />
            </StyledLogoWrapper>
            <StyledLogoWrapper>
              <SyncAltIcon />
            </StyledLogoWrapper>
            <StyledLogoWrapper
              style={{ paddingRight: '0px', marginTop: '8px' }}
            >
              {modalData?.logo}
            </StyledLogoWrapper>
          </StyledLogoContainer>
          <StyledModalTextContainer>
            <StyledTextModal>{`Connect to ${modalData?.name}`}</StyledTextModal>
            <StyledModalDescription>
              {modalData?.description}
            </StyledModalDescription>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '1rem',
                justifyContent: 'center',
                alignItems: 'center',
                pt: '2rem',
              }}
            >
              <TextField
                label={modalData?.input}
                variant="outlined"
                sx={{ width: '90%' }}
              />
              <Button
                variant="contained"
                color="primary"
                sx={{ width: '90%' }}
              ></Button>
            </Box>
          </StyledModalTextContainer>
          <CloseIcon
            onClick={handleClose}
            sx={{
              cursor: 'pointer',
              position: 'absolute',
              top: '10px',
              right: '10px',
              padding: '20px 15px 20px 20px',
            }}
          />
        </StyledLoginWrapper>
      </Box>
    </Drawer>
  );
};

export default CustodianDrawer;
