import React from 'react';
import { Grid, IconButton } from '@mui/material';
import PdfViewer from '@components/PdfViewer';
import MemoFile from '@icons/File';
import { validArray } from '@common/validArray';
import DialogModal from '@components/DialogModal';
import { SourceDocumentWrapper } from '../styles';
import CloseIcon from '@mui/icons-material/Close';

function SourceDocument({
  data,
  isLastItem,
}: {
  data: any[];
  isLastItem: boolean;
  key: string;
}): JSX.Element {
  const [showSource, setShowSource] = React.useState(false);
  const [document, setDocument] = React.useState<any>({});

  const handleCloseSource = () => {
    setShowSource(false);
  };

  return (
    <>
      <SourceDocumentWrapper
        container
        columnGap={1}
        rowGap={1}
        onClick={() => isLastItem && setShowSource(true)}
      >
        {validArray(data) &&
          data.map((document: any, index: number) => (
            <Grid
              item
              container
              className="card"
              xs={12}
              md={3.5}
              rowGap={1}
              key={index}
              onClick={() => setDocument(document)}
            >
              <Grid item xs={12}>
                <p className="documentName">{document?.name}</p>
              </Grid>

              <Grid item container columnGap={1}>
                <Grid item xs={1}>
                  <MemoFile />
                </Grid>
                <Grid item xs={10}>
                  <p className="fileUrl">{document?.fileUrl}</p>
                </Grid>
              </Grid>
            </Grid>
          ))}
      </SourceDocumentWrapper>

      {showSource && (
        <DialogModal
          openModal={showSource}
          handleClose={handleCloseSource}
          fullWidth={true}
          maxWidth={'lg'}
          closeIcon={
            <IconButton
              aria-label="close"
              onClick={handleCloseSource}
              sx={{
                position: 'absolute',
                right: 0,
                top: 0,
                color: theme => theme.palette.grey[500],
                zIndex: 1,
              }}
            >
              <CloseIcon />
            </IconButton>
          }
        >
          <PdfViewer
            fileUrl={document?.fileUrl}
            source={document?.sources}
            disableHighLight={true}
          />
        </DialogModal>
      )}
    </>
  );
}

export default SourceDocument;
