import axios, { AxiosInstance } from 'axios';
import { settings } from 'config';
import { decode } from 'msgpackr';

const ApiInstance: AxiosInstance = axios.create({
  baseURL: settings.api.baseUrl,
  headers: {
    Accept: '*/*',
    http_host: 'TG',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': process.env.REACT_APP_FRONTEND_URL,
    'Access-Control-Allow-Headers': '*',
    'x-tifin-ai-auth': localStorage.getItem('x-tifin-ai-token'),
  },
});

ApiInstance.interceptors.request.use(
  config => {
    if (localStorage.getItem('tifin-ai-api-key')) {
      config.headers['x-tifin-ai-token'] =
        localStorage.getItem('tifin-ai-api-key'); // AUTH_DETAILS.apiKey;
    }
    if (localStorage.getItem('impersonation-id')) {
      config.headers['impersonation-id'] =
        localStorage.getItem('impersonation-id'); // impersonation details;
    }
    if (config.params && config.params.queryWithoutToken) {
      // Remove headers if queryWithoutToken is true
      delete config.headers['x-tifin-ai-auth'];
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

ApiInstance.interceptors.response.use(
  response => {
    if (response.headers['content-type'] === 'application/x-msgpack') {
      return {
        ...response,
        data: decode(response.data),
      };
    }
    return response;
  },
  error => {
    if (error && error.response && error.response.status === 401) {
      // Handle 401 Unauthorized error here
      console.log('====== Auth issue 401 ======');
      localStorage.clear();
      sessionStorage.clear();
      window.location.replace('/login');
      // You can add your logic to handle the 401 error here
    }
    return Promise.reject(error);
  },
);

export default ApiInstance;
