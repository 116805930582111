import React from 'react';
import { Typography, Box, Collapse } from '@mui/material';
import { fontStyles, StyledTableCell, StyledTableRow } from './styles';
import { isEmpty } from 'utils/isEmpty';

interface PropsType {
  row: any;
  index: number;
  columns: Array<any>;
  baseLineActive: string;
  open: boolean;
}

const RowCollapse = (props: PropsType): React.JSX.Element => {
  const { row, index, open, columns = [], baseLineActive = '' } = props;

  return (
    <StyledTableRow
      sx={{
        '&.MuiTableRow-root': {
          '& .MuiTableCell-root': {
            ...(!open && { borderBottom: 'none' }),
          },
        },
      }}
    >
      <StyledTableCell
        style={{ paddingBottom: 0, paddingTop: 0 }}
        colSpan={columns.length + 2}
      >
        <Collapse in={open} timeout="auto" unmountOnExit sx={{}}>
          <Box
            sx={{
              margin: 1,
              display: 'flex',
              flexDirection: 'column',
              gap: 1.5,
              position: 'relative',
              '&::before': {
                position: 'absolute',
                content: "''",
                height: '1px',
                //background: "rgba(242, 243, 245, 1)",
                width: '100%',
                top: '-4%',
              },
            }}
          >
            {columns.map((column, columnIndex) => {
              return (
                <RowCollapseItem
                  key={`td-collapse-${index}-${columnIndex}`}
                  column={column}
                  row={row}
                  baseLineActive={baseLineActive}
                />
              );
            })}
          </Box>
        </Collapse>
      </StyledTableCell>
    </StyledTableRow>
  );
};

export default RowCollapse;

interface CollapsePropsType {
  column: any;
  row: any;
  baseLineActive: string;
}

const RowCollapseItem = (props: CollapsePropsType): React.JSX.Element => {
  const { column, row, baseLineActive = '' } = props;
  if (isEmpty(column.value)) return <React.Fragment />;
  return (
    <Box sx={{ display: 'flex' }}>
      <Typography
        component={'div'}
        sx={{
          color: '#464646',
          width: '144px',
          ...fontStyles,
          ...(baseLineActive === column.value && {
            fontWeight: 600,
          }),
        }}
      >
        {column.label}:
      </Typography>
      <Typography
        component={'div'}
        sx={{
          color: '#464646',
          width: '800px',
          wordBreak: 'break-all',
          ...fontStyles,
          ...(baseLineActive === column.value && {
            fontWeight: 400,
          }),
        }}
        dangerouslySetInnerHTML={{
          __html: !isEmpty(row[column.value])
            ? column.value === 'query'
              ? row[column.value]
              : row[column.value]
            : 'NA',
        }}
      />
    </Box>
  );
};
