import React, { useState } from 'react';
import SageIcon from '@icons/OnlySage';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import {
  Button,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { actions as globalActions } from 'app/features/Global/slice';
import CloseIcon from '@mui/icons-material/Close';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import { useStyles } from './CustodianDrawer';
import { useMutation } from 'react-query';
import { connectRedTail } from './mutation';
import { useDispatch } from 'react-redux';

interface CRMDrawerProps {
  open: boolean;
  setOpen: any;
  modalData: any;
  handleSuccess: () => void;
}

function CRMDrawer({
  open,
  setOpen,
  modalData,
  handleSuccess,
}: CRMDrawerProps): React.JSX.Element {
  const dispatch = useDispatch();
  const [inputEmail, setInputEmail] = useState('');
  const [inputPassword, setInputPassword] = useState('');
  const classes = useStyles();
  const [connecting, setConnecting] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClose = () => setOpen(false);
  const redTailMutate = useMutation(connectRedTail);

  const handleButtonClick = () => {
    if (!(inputEmail || inputPassword)) return;
    const payload = {
      username: inputEmail,
      password: inputPassword,
    };
    setConnecting(true);
    redTailMutate.mutate(payload, {
      onSuccess: () => {
        setOpen(false);
        setConnecting(false);
        handleSuccess();
      },
      onError: () => {
        setConnecting(false);
        dispatch(
          globalActions.displayToast({
            duration: 3000,
            toastType: 'error',
            toastMessage:
              'Authentication failed. Check your username/password and try again.',
          }),
        );
      },
    });
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputEmail(event.target.value);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputPassword(event.target.value);
  };

  return (
    <Drawer
      anchor="right"
      variant="temporary"
      open={open}
      className={classes.drawer}
      classes={{ paper: classes.drawerPaper }}
      onClose={handleClose}
      PaperProps={{
        sx: {
          width: isMobile ? '100%' : '400px',
        },
      }}
    >
      <Box sx={{ position: 'relative' }}>
        <Grid
          container
          padding={isMobile ? '20px' : '35px 30px'}
          justifyContent={'center'}
          alignItems={'center'}
          borderRadius={'4px'}
          mb={'20px'}
        >
          <Grid
            item
            container
            padding={isMobile ? '20px 0' : '40px 0px 30px 0px'}
            justifyContent={'center'}
            alignItems={'center'}
            columnGap={3}
          >
            <Grid item>
              <SageIcon />
            </Grid>
            <Grid item>
              <SyncAltIcon />
            </Grid>
            <Grid item>{modalData?.logo}</Grid>
          </Grid>
          <Grid item container xs={12} pb={'30px'} rowGap={2}>
            <Grid item xs={12} justifyContent={'center'} alignItems={'center'}>
              <Typography
                sx={{
                  color: '#000',
                  fontSize: isMobile ? '20px' : '24px',
                  textAlign: 'center',
                  fontWeight: 600,
                }}
              >
                {`Connect to ${modalData?.name}`}
              </Typography>
            </Grid>
            <Grid item xs={12} justifyContent={'center'} alignItems={'center'}>
              <Typography
                sx={{
                  color: 'var(--neutral-600, #475467)',
                  fontSize: isMobile ? '16px' : '18px',
                  textAlign: 'center',
                  fontWeight: 500,
                }}
              >
                {modalData?.description}
              </Typography>
            </Grid>
            <Grid
              item
              container
              justifyContent={'center'}
              alignItems={'center'}
              rowGap={2}
              pt={'30px'}
            >
              <TextField
                fullWidth
                value={inputEmail}
                label={'Username'}
                variant="outlined"
                sx={{ width: isMobile ? '100%' : '90%' }}
                onChange={handleInputChange}
              />
              <TextField
                label={'Password'}
                variant="outlined"
                value={inputPassword}
                sx={{ width: isMobile ? '100%' : '90%' }}
                type={'password'}
                onChange={handlePasswordChange}
              />
              <Button
                sx={{ width: isMobile ? '100%' : '90%' }}
                variant="contained"
                color="primary"
                onClick={handleButtonClick}
                disabled={connecting}
              >
                {connecting ? 'Connecting...' : 'Connect'}
              </Button>
            </Grid>
          </Grid>
          <CloseIcon
            onClick={handleClose}
            sx={{
              cursor: 'pointer',
              position: 'absolute',
              top: '10px',
              right: '10px',
              padding: '20px 15px 20px 20px',
            }}
          />
        </Grid>
      </Box>
    </Drawer>
  );
}

export default CRMDrawer;
