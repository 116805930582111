import { Box, Button, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import styled from 'styled-components';

// import CustodianCardModal from './CustodianCardModal';

import CharlesSchwabIcon from '@icons/CharlesSchwabLogo';
import FidelityIcon from '@icons/Fidelity';
import BNYIcon from '@icons/BNYLogo';
import { Spinner, StyledBadgeI } from './styles';
import CustodianDrawer from './CustodianDrawer';

interface data {
  logo: React.ReactElement | string;
  name: string;
  description: string;
  input: string;
  source: string;
}

const cardModalData: data[] = [
  {
    logo: <CharlesSchwabIcon />,
    name: 'Charles Schwab',
    description:
      'Enter your Master Account Number to pull in clients and portfolios',
    input: 'Master Account Number',
    source: 'Schwab',
  },

  {
    logo: <FidelityIcon />,
    name: 'Fidelity',
    description:
      'Connect your Fidelity Rep Code or G-Number to pull in Clients and Portfolios ',
    input: 'Rep Code / G-number / Branch Code 3030#',
    source: 'Fidelity',
  },

  {
    logo: <BNYIcon />,
    name: 'BNY Mellon Pershing',
    description: 'Connect your IP Number and pull in your Clients and Accounts',
    input: 'IP Number',
    source: 'Pershing',
  },
];

interface CustodianCardDataProps {
  logo: React.ReactNode;
  name: string;
  description: string;
  custodianData: any;
  isLoading: boolean;
}

const CustodianCardContainer = styled.div`
  align-items: center;
  justify-content: flex-start;
  display: flex;
`;

const CustodianCardWrapper = styled.div`
  border: 1px solid #e4e7ec;
  border-radius: 8px;
  padding: 16px 16px 20px 16px;
  margin: 0px 3px 12px 10px;
  max-width: 300px;
  width: 245px;
  height: 165px;
  background: var(--neutral-0, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.12);
`;

const ImgContainer = styled.div`
  display: flex;
  height: 50px;
  padding-bottom: 15px;
`;

const TitleContainer = styled.div`
  display: flex;
  height: 20px;
  color: var(--neutral-900, #101828);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-bottom: 2px;
`;

const DescContainer = styled.div`
  display: flex;
  height: 40px;
  overflow: hidden;
  color: var(--neutral-600, #475467);
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  text-align: left;
  padding-bottom: 10px;
`;

function CustodianCard({
  logo,
  name,
  description,
  custodianData,
  isLoading,
}: CustodianCardDataProps): React.JSX.Element {
  const [open, setOpen] = React.useState(false);
  const [modalData, setModalData] = useState<any>({});

  const handleOpen = (name: string) => {
    const selectedCustodian = cardModalData.find(
      custodian => custodian.name === name,
    );

    if (selectedCustodian) {
      setModalData(selectedCustodian);
      setOpen(true);
    }
  };

  const dynamicDescription =
    custodianData?.status === 'PENDING'
      ? 'View and sign the authorization letter to import your client accounts.'
      : description;

  return (
    <CustodianCardContainer>
      <CustodianCardWrapper>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <ImgContainer style={{ display: 'flex', height: '50px' }}>
            {logo}
          </ImgContainer>
          {custodianData && (
            <StyledBadgeI $status={custodianData?.status}>
              {custodianData?.status}
            </StyledBadgeI>
          )}
        </Box>
        <TitleContainer>{name}</TitleContainer>
        {/* {description.length > 75 ? (
          <Tooltip title={description} placement="top">
            <DescContainer>
              {`${description.substring(0, 75)}...`}
            </DescContainer>
          </Tooltip>
        ) : (
          <DescContainer>{description}</DescContainer>
        )} */}

        {description.length > 75 ? (
          <Tooltip title={dynamicDescription} placement="top">
            <DescContainer>
              {`${dynamicDescription.substring(0, 75)}...`}
            </DescContainer>
          </Tooltip>
        ) : (
          <DescContainer>{dynamicDescription}</DescContainer>
        )}

        {custodianData?.status === 'PENDING' ? (
          <Button
            variant="outlined"
            style={{
              width: '100%',
              borderRadius: '20px',
              border: '1px solid var(--neutral-200, #EFEFEF)',
              boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.05)',
              textTransform: 'none',
              color: '#101828',
              backgroundColor: 'white',
              fontSize: '14px',
            }}
            // disabled={false}
            disabled={isLoading}
          >
            {isLoading ? <Spinner /> : 'Review & Sign'}
          </Button>
        ) : (
          <Button
            variant="outlined"
            style={{
              width: '100%',
              borderRadius: '20px',
              border: '1px solid var(--neutral-200, #EFEFEF)',
              boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.05)',
              textTransform: 'none',
              color: '#101828',
              fontSize: '14px',
              backgroundColor:
                custodianData?.status === 'CONNECTED' ? '#e5e6e8' : 'white',
            }}
            onClick={() => handleOpen(name)}
            disabled={
              custodianData?.status === 'CONNECTED' ||
              custodianData?.status === 'PROCESSING'
            }
          >
            {/* Connect */}
            {custodianData?.status === 'CONNECTED' ||
            custodianData?.status === 'PROCESSING'
              ? 'Disconnect'
              : 'Connect'}
          </Button>
        )}
      </CustodianCardWrapper>
      <CustodianDrawer open={open} setOpen={setOpen} modalData={modalData} />
    </CustodianCardContainer>
  );
}

export default CustodianCard;
