import { Box, Grid, IconButton, ListItemIcon } from '@mui/material';
import styled, { css } from 'styled-components';
import { AnimationName, AnimationTime } from '../styles';
import { shouldForwardProp } from 'utils/forwardProp';
import { isDesktopHandler } from '@common/string';
const LogoContainerHeight = 114;

export const SideNavWrapper = styled.div<any>`
  padding: ${({ isDesktopDevice }) =>
    `${isDesktopHandler(isDesktopDevice)?.y} ${isDesktopHandler(isDesktopDevice)?.x}`};
  display: flex;
  flex-direction: row;
`;

export const NavMenuWrapper = styled(Grid)`
  height: calc(100% - ${LogoContainerHeight}px);
`;

export const LogoContainer = styled(Grid).withConfig({
  shouldForwardProp,
})<any>`
  position: relative;
  flex-direction: column !important;
  padding: 2rem 18px;
  ${({ collapse }: any) =>
    collapse &&
    css`
      padding: 2rem 9px !important;
    `}

  @media (min-width: 900px) {
    ${({ collapse }: any) =>
      collapse &&
      css`
        padding: 2rem 17px !important;
      `}
  }
  transition:
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    width 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    transform 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
`;

export const LogoMask = styled(Box).withConfig({ shouldForwardProp })<{
  collapse: boolean;
  isDesktopDevice?: boolean;
}>`
  width: 100%;
  margin: ${({ isDesktopDevice }) =>
    `0 ${isDesktopHandler(isDesktopDevice)?.sidebar?.x}`};
  overflow: hidden;

  svg {
    width: auto;
    height: 40px;
  }

  @media (min-width: 900px) {
    svg {
      height: 46px;
    }
  }

  ${({ collapse }: any) =>
    collapse &&
    css`
      margin: 0;
      svg {
        transition: height 250ms ease-out;
      }
    `}
  transition: 
    padding 250ms ease-out,
    width 250ms ease-out,
    margin 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
`;

export const ToggleButton = styled(IconButton).withConfig({
  shouldForwardProp,
})<{ collapse: boolean }>`
  position: absolute !important;
  top: 44px;
  z-index: 1;
  right: -14px;
  width: 20px;
  height: 20px;
  font-size: 20px !important;
  border-radius: 4px !important;
  background: ${props => props.theme.solidWhite} !important;
  border: 1px solid ${props => props.theme.orangeBorder} !important;
  transition:
    margin 250ms ease-in 250ms,
    width 250ms ease-in 250ms,
    right ${AnimationName} ${AnimationTime},
    transform 250ms ease-in 250ms !important;

  svg {
    width: 26px !important;
    height: 26px !important;
  }
  ${({ collapse }: any) =>
    !collapse &&
    css`
      right: 24px;
    `}
`;

export const LogoutWrapper = styled(Grid)`
  width: 100%;
  height: 125px;
  margin: 0;
  padding: 0 14px;
  cursor: pointer;
  margin-bottom: 30px;

  @media (min-width: 900px) {
    padding: 0 24px;
  }
`;

export const LogoutIcon = styled(ListItemIcon)<any>`
  min-width: 30px;
  height: 40px;
  align-items: center;
  padding-left: 13px;
  background: ${props => props.bgColor};
  border: ${props => props.border};
  border-radius: 4px;
`;

export const ProfileIconWrapper = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: 2px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--neutral-0, #fff);
  color: #ff5c12;
  font-size: 14px;
  font-weight: 700;
`;

export const ProfileWrapper = styled.div`
  width: 57%;
  height: 20px;
  padding: 0px 20px;
  margin-top: -20px;
  display: flex;
  align-items: center;
`;

export const CountWrapper = styled.span`
  display: flex;
  margin-left: 18px;
  height: 6px;
  align-items: center;
  background: rgba(255, 255, 255, 0.6);
  padding: 10px;
  border-radius: 2px;
  font-size: 14px;
  color: #333;
  font-weight: 600;
`;
