import { Grid } from '@mui/material';
import styled, { css } from 'styled-components';

import { Accordion as MuiAccordion } from '@mui/material';
import { AccordionSummary as MuiAccordionSummary } from '@mui/material';
import { Typography as MuiTypography } from '@mui/material';
import { AccordionDetails as MuiAccordionDetails } from '@mui/material';
import { keyframes } from 'styled-components';
import { shouldForwardProp } from 'utils/forwardProp';

export const ChatContentContainer = styled(Grid)<{
  isMinimized: boolean;
  isLoading?: boolean;
}>`
  width: 100%;
  height: calc(100% - 130px);
  color: #fff;
  padding: 0 16px 16px 0 !important;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 4px !important;
    height: 2px !important;
  }

  &::-webkit-scrollbar-thumb {
    background: ${props => props.theme.grey4} !important;
    border-radius: 20px !important;
    margin-bottom: 10px !important;
  }

  &::-webkit-scrollbar-track {
    background: transparent !important;
    margin-top: 12px !important;
  }

  transition:
    padding cubic-bezier(0.48, 0.65, 0.6, 0.79) 0.5s,
    display cubic-bezier(0.48, 0.65, 0.6, 0.79) 0.5s,
    opacity cubic-bezier(0.48, 0.65, 0.6, 0.79) 0.3s;

  ${({ isMinimized }: any) =>
    !isMinimized &&
    css`
      padding: 0;
    `}

  ${({ isLoading }: any) =>
    isLoading &&
    css`
      opacity: 0;
    `}
`;

export const ChatBoxContainer = styled(Grid).withConfig({
  shouldForwardProp,
})<{ isSender?: boolean }>`
  position: relative;
  width: 100%;
  padding: 1rem;
  margin-bottom: 10px;

  ${({ isSender }) =>
    isSender &&
    css`
      border-radius: 48px;
      background: rgba(213, 213, 213, 0.2);
    `}
`;

const scrolly = keyframes`
  0%  { transform: translateY(calc(0%)); }
  25%  { transform: translateY(calc(-9%)); }
  50%  { transform: translateY(calc(-18%)); }
  75%  { transform: translateY(calc(-27%)); }
  100%  { transform: translateY(calc(-36%)); }
`;
export const QuestionWrapper = styled.div.withConfig({
  shouldForwardProp,
})<{ duration: number; delay: number }>`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  height: auto;
  animation: ${scrolly}
    ${props => `${props.duration}s linear ${props.delay}s 1 forwards`};
`;

export const QuestionItem = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  border: 1px solid #e3e3e3;
  border-radius: 8px;
  background: #fff;
  // width: 29%;
  flex-basis: calc(
    50% - 10px
  ); /* Set width for two cards in a row with some margin */
  margin-bottom: 20px;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.12);
`;

export const QuestionHead = styled.div`
  color: #333;
  font-size: 16px;
  font-weight: 900;
  padding: 15px 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
`;

export const QuestionListItem = styled.div`
  color: #667085;
  font-size: 14px;
  padding: 10px 0px;
  cursor: pointer;

  :hover {
    font-weight: 900;
    transition: font-size 0.2s linear 0s;
  }
`;

export const ListItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const QuestionHeaderDiv = styled.div`
  display: flex;
  color: #000;
  padding-bottom: 15px;
  font-size: 14px;
`;

export const CardLabel = styled('div')<any>`
  background: black;
  height: 25px;
  width: auto;
  padding-top: 8px;
  border-radius: 5px;
  margin-top: -10px;
  font-size: ${props => props?.fontSize ?? '14px'};
  font-style: ${props => props?.fontSize ?? 'normal'};
  font-weight: ${props => props.fontWeight ?? '500'};
  line-height: ${props => props.fontWeight ?? 'normal'};
  color: ${props => props.color ?? '#fff'};
  padding-left: 5px;
`;

export const CardContentContainer = styled(Grid)`
  // display: flex;
  // justify-content: flex-start;
  width: 100%;
  // flex-wrap: wrap;
  // height: 100%;
  //   flex: 1 0 0;
  // margin-top: 5px;
`;
export const ButtonGrid = styled(Grid)`
  border: 1px solid #e4e7ec;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.12);
  border-radius: 4px;
  padding: 8px;
  cursor: pointer;
  .text {
    font-size: 12px;
    color: #333;
    font-weight: 500;
  }
`;
export const MessageGrid = styled(Grid)`
  padding: 10px;
  border-radius: 4px;
  background: ${props => props.theme.background4};
  color: black;
  font-family: Inter;
  font-size: '14px';
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 10px;
  flex-direction: column;
  justify-content: space-between;
`;

export const ConnectGrid = styled(Grid)`
  border-radius: 0px 0px 8px 8px;
  border: 1px solid var(--Neutral-200, #e4e7ec);
  background: #6d6d6d;
  cursor: pointer;
  /* padding: 5px; */

  .text {
    color: #fff;
    font-size: 12px;
  }
`;

export const SuggestedCardBody = styled(Grid)`
  /* display: flex;
  flex-wrap: wrap; */
  /* gap: 16px;
  padding: 10px 0px 8px 0px;
  overflow: hidden; */
  // margin-bottom:50px;
`;
export const ActionCardWrapper = styled(Grid)`
  background: ${props => props.theme.solidWhite};
  border-radius: 8px;
  border: 1px solid var(--neutral-200, ${props => props.theme.borderColor});
  box-sizing: border-box;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.12);
  padding: 12px;
  min-height: '323px';
  cursor: pointer;
`;

export const StyledSFAccordion = styled(MuiAccordion)`
  && {
    border: 1px solid #e4e7ec;
    border-radius: 4px;
    background: #fff;
    margin-bottom: 15px;
    &:before {
      height: 0;
    }
  }
`;

export const StyledSFAccordionSummary = styled(MuiAccordionSummary)`
  && {
    height: 100px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`;

export const StyledSFTypography = styled(MuiTypography)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledSFAccordionDetails = styled(MuiAccordionDetails)`
  max-height: 195px;
  overflow-y: auto;
  background: #fff;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;

export const StyledAccordionTitleText = styled(MuiTypography)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px !important;
  color: #101828 !important;
  font-weight: 500 !important;
`;

export const StyledAccordionDescText = styled(MuiTypography)`
  color: var(--neutral-600, #475467) !important;
  font-size: 14px !important;
  display: flex;
  align-items: center;
`;

export const StyledImgDiv = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledDrawerContainer = styled.div`
  padding: 25px 25px 0px 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  height: 100%;
  background: #fff;
`;

export const StyledLoginWrapper = styled.div`
  display: flex;
  padding: 35px 30px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  // border: 1px solid #e4e7ec;
  // width: 86%;
  // height: 200px;
  margin-bottom: 20px;
  border-radius: 4px;
`;

export const StyledLogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 30px;
  padding-top: 40px;
`;

export const StyledLogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 25px;
`;

export const StyledTextContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-bottom: 30px;
`;
export const StyledModalTextContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 1rem;
  flex-direction: column;
  padding-bottom: 30px;
`;

export const StyledText = styled.div`
  color: #000;
  font-size: 18px;
  text-align: center;
  font-weight: 500;
`;
export const StyledTextModal = styled.div`
  color: #000;
  font-size: 24px;
  text-align: center;
  font-weight: 600;
`;

export const StyledDescription = styled.div`
  color: var(--neutral-600, #475467);
  font-size: 12px;
  text-align: center;
  font-weight: 500;
`;

export const StyledModalDescription = styled.div`
  color: var(--neutral-600, #475467);
  font-size: 18px;
  text-align: center;
  font-weight: 500;
`;
export const StyledCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const StyledCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
`;

export const StyledAccordionContainer = styled.div`
  height: 435px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const StyledFooterContainer = styled.div`
  display: flex;
  width: 100%;
  height: 37px;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding-bottom: 20px;
`;

export const StyledText1 = styled.div`
  color: var(--neutral-600, #475467);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const StyledUnderlinedText = styled.div`
  color: var(--neutral-600, #475467);
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-left: 5px;
`;

export const StyledTextDiv2 = styled.div`
  font-size: 14px;
  text-decoration: underline;
  font-weight: 500;
  cursor: pointer;
  padding-left: 5px;
  line-height: normal;
`;

export const StyledTextDiv1 = styled.div`
  font-size: 14px;
  color: #475467;
  font-weight: 400;
  line-height: normal;
`;

export const TextContainer = styled.div`
  height: 20px;
  padding: 0px 25px 25px 30px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  flex-direction: row;
`;

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TitleContainer = styled.div`
  font-size: 14px;
  color: #98a2b3;
  padding-bottom: 10px;
`;

export const TotalAmountContainer = styled.div`
  font-size: 26px;
  color: #101828;
`;

export const LogoContainer = styled.div`
  width: 55px;
  height: 55px;
  border-radius: 4px;
  background: #f2f4f7;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
`;

export const DetailsDataCardContainer = styled.div`
  width: 94%;
  height: 60px;
  flex-direction: row;
  display: flex;
  margin-bottom: 20px;
  padding: 10px 10px 10px 15px;
  border: 1px solid #e4e7ec;
  border-radius: 5.5px;
  align-items: center;
  background: #fff;
`;

export const DetailsDataCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DetailsDataWrapper = styled.div`
  width: 100%;
  height: 600px;
  padding: 10px 0px;
`;

export const DetailsDataContainer = styled.div`
  width: 100%;
  flex: 1;
  flex-direction: column;
  display: flex;
`;

export const LogoContainer1 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: 1px solid #e4e7ec;
  border-radius: 8px;
  cursor: pointer;
`;

export const LogoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 25px;
  padding-left: 45px;
`;

export const StyledChip = styled.div`
  width: 70px;
  height: 20px;
  border-radius: 12px;
  color: #008243;
  background: var(--success-surface, #ebf5f0);
  font-size: 12px;
  border: 1px solid #b3dac7;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 1px;
`;

export const ChipWrapper = styled.div`
  padding: 0px 0px 11px 20px;
`;

export const DateContainer = styled.div`
  padding: 0px 0px 9px 20px;
  font-size: 12px;
  color: #98a2b3;
`;

export const HeaderTextDiv = styled.div`
  padding: 0px 0px 3px 20px;
  font-size: 20px;
  color: #101828;
  font-weight: 500;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 10px 10px 0px;
`;

export const DetailsDrawerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  height: 100px;
`;

export const DetailsDrawerContainer = styled.div`
  display: flex;
  width: 90%;
  height: 97%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 25px 25px 0px 25px;
  background: #fff;
`;
export const StyledBadge = styled.div`
  width: 90px;
  height: 20px;
  border-radius: 12px;
  color: #008243;
  background: var(--success-surface, #ebf5f0);
  font-size: 12px;
  border: 1px solid #b3dac7;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const StyledBadgeI = styled.div<{ $status?: string }>`
  width: 90px;
  height: 20px;
  border-radius: 12px;
  color: ${props => (props.$status === 'PENDING' ? '#ffc107' : 'green')};
  font-size: 12px;
  border: 1px solid
    ${props => (props.$status === 'PENDING' ? '#ffc107' : 'green')};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DescContainer = styled.div`
  display: flex;
  height: 40px;
  overflow: hidden;
  color: var(--neutral-600, #475467);
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  text-align: left;
  padding-bottom: 10px;
`;

export const TitleContainerNew = styled.div`
  display: flex;
  height: 20px;
  color: var(--neutral-900, #101828);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-bottom: 2px;
`;

export const ImgContainer = styled.div`
  display: flex;
  height: 50px;
  padding-bottom: 15px;
`;

export const CRMCardWrapper = styled.div`
  border: 1px solid #e4e7ec;
  border-radius: 8px;
  padding: 16px 16px 20px 16px;
  margin: 0px 3px 12px 10px;
  max-width: 300px;
  width: 215px;
  height: 165px;
  background: var(--neutral-0, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.12);
`;

export const CRMCardContainer = styled.div`
  align-items: center;
  justify-content: flex-start;
  display: flex;
`;

export const HeaderPartWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const StyledButtonWrapper = styled.div`
  height: 60px;
  background-color: #f8f9fa;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  border-top: 1px solid #e4e7ec;
  width: 102%;
  margin-left: -24px;
  justify-content: space-between;
  box-shadow: 0px 1px 8px 0px rgba(38, 38, 38, 0.13);
`;

export const SFConatctsWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  padding: 0px 10px 12px 10px;
  align-items: center;
  color: #475467;
  font-size: 14px;
  font-weight: 400;
`;

export const SFCardTitle = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  padding: 10px 10px 5px 10px;
  align-items: center;
  font-size: 16px;
  color: #000;
  font-weight: 500;
`;

export const SFDataCardWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const SFDataCardContainer = styled.div`
  width: 95%;
  height: 70px;
  border: 1px solid #e4e7ec;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  padding: 3px 15px 3px 5px;
  align-items: center;
  margin-bottom: 15px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.12);
`;

export const DataCard = styled.div`
  width: 100%;
  height: 150px;
  padding: 15px 0px 22px;
`;

export const SFDataHeader = styled.div`
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
  color: #475467;
`;

export const SFIconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
`;

export const StyledContainer = styled.div`
  width: 55%;
  height: 45px;
  border: 1px solid #e4e7ec;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  padding: 5px 10px 5px 15px;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.12);
`;

export const StyledWrapper = styled.div`
  width: 100%;
  padding: 10px 0px;
  height: 250px;
  display: flex;
  flex-direction: column;
`;

export const CloseIconDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
`;

export const SFHeaderText = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  font-size: 20px;
  font-weight: 500;
  color: #101828;
`;

export const DrawerHeaderDataWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  padding: 10px 0px;
  height: 20px;
`;

export const StyledDrawerWrapper = styled.div`
  display: flex;
  height: 90%;
  width: 100%;
  flex-direction: column;
`;

export const StyledSFDrawerContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
`;

const spinAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left: 4px solid #000000;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: ${spinAnimation} 1s linear infinite;
`;

// export const ProgressBar = styled.div<{ $width?: number ,$timeToTransit?: number }>`
//   height:10px,
//   width: ${props => `${props.$width}%`},
//   backgroundColor:red,
//   transition: ${props => `all ${props.$timeToTransit}s ease-in`}
// `;

export const ComponentSlides = styled.div``;

export const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 57vh;
  overflow: hidden;
  background: rgba(213, 213, 213, 0.2);
  padding: 15px;
  border-radius: 8px;
`;
