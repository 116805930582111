import axios from 'axios';

export const getChartsData = async (
  selectedTicker: string,
  period: string,
): Promise<any> => {
  try {
    const chartType = 'AdjustedNAV';

    const response = await axios.get(
      `${process.env.REACT_APP_MAGNIFI}/securities/charts/find-one?Ticker=${selectedTicker}&Type=${chartType}&Period=${period}`,
      {
        headers: {
          Accept: 'application/json, text/plain, */*',
        },
      },
    );
    return response.data;
  } catch (error: any) {
    console.error('Error fetching key stats data:', error.message);
    throw new Error(error.message);
  }
};

export const fetchAndSetKeyStatsData = async (
  selectedTicker: string,
  period: string,
): Promise<any> => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_MAGNIFI}/securities/pdp/keystats?Ticker=${selectedTicker}&Period=${period}`,
      {
        headers: {
          Accept: 'application/json, text/plain, */*',
        },
      },
    );

    const keyStatsData = response.data;
    return keyStatsData;
  } catch (error: any) {
    console.error('Error fetching key stats data:', error.message);
    throw new Error(error.message);
  }
};
