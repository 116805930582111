import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { actions } from 'app/features/Chat/slice';
import { FooterContainer } from './styles';
import { useSelector } from 'react-redux';
import { getChatInitResponseState } from '@features/Chat/selector';
import { CHEAT_SHEET } from '@common/constant';
import SearchBox from '../reusable/SearchBox';

interface FooterProps {
  isQuestionMarkHovered?: boolean;
  setIsQuestionMarkHovered?: any;
  showFooterArrow?: boolean;
}
function Footer({
  isQuestionMarkHovered,
  setIsQuestionMarkHovered,
  showFooterArrow = false,
}: FooterProps): React.JSX.Element {
  const dispatch = useDispatch();
  const isChatStreaming = localStorage.getItem('streaming') as string;

  const navigate = useNavigate();
  const { chatHistory: initHistoryState } = useSelector(
    getChatInitResponseState,
  );
  const [query, setQuery] = useState('');
  const onInputSubmit = () => {
    navigate('/conversations/start');
    if (isChatStreaming === 'true') {
      dispatch(actions.setSearchQuery(query));
    } else {
      dispatch(actions.searchQueryRequest({ query }));
    }
    setQuery('');
  };

  const handleQuestionMarkClick = () => {
    navigate('/conversations/start');
    dispatch(
      actions.loadChatSuccess({
        chatHistory: [
          ...initHistoryState,
          {
            data: 'Display Suggested Questions',
            isSender: true,
            time: '',
            id: '',
            type: 'text',
            isChatHistory: true,
            mock: true,
          },
          {
            id: '65b8f06d84d66bb8215d3173',
            isSender: false,
            time: '',
            isShowFeedback: false,
            isChatHistory: false,
            mock: true,
            feedback: {
              chatLogId: '65b8f06d84d66bb8215d3173',
              scale: '0',
              comment: '',
              isSubmitted: false,
            },
            data: CHEAT_SHEET,
          },
        ],
      }),
    );
  };

  return (
    <FooterContainer
      container
      alignItems={'center'}
      justifyContent={'space-between'}
    >
      <SearchBox
        showFooterArrow={showFooterArrow}
        userQuery={query}
        isStreaming={false}
        setUserQuery={setQuery}
        onInputSubmit={onInputSubmit}
        handleQuestionMarkClick={handleQuestionMarkClick}
        isQuestionMarkHovered={isQuestionMarkHovered}
        setIsQuestionMarkHovered={setIsQuestionMarkHovered}
      />
    </FooterContainer>
  );
}

export default Footer;
