import React from 'react';
import { Grid, IconButton, SxProps } from '@mui/material';
import { SliderWrapper } from './styles';
import MemoLeftArrow from '@icons/LeftArrow';
import MemoRightArrow from '@icons/RightArrow';

interface CustomSliderProps {
  viewpoints: any[];
  currentIndex: number;
  setCurrentIndex: (index: number) => void;
  sx?: SxProps;
}

const CustomSlider: React.FC<CustomSliderProps> = ({
  viewpoints,
  currentIndex,
  setCurrentIndex,
  sx,
}) => {
  const handlePrevious = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleNext = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (currentIndex < viewpoints.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const isFirstIndex = currentIndex === 0;
  const isLastIndex = currentIndex === viewpoints.length - 1;
  const arrowColor = isFirstIndex ? '#dededf' : '#282829';

  return (
    <SliderWrapper
      container
      alignItems="center"
      justifyContent="flex-end"
      rowGap={1.5}
      sx={sx}
    >
      <Grid item container alignItems={'center'}>
        <Grid item>
          <IconButton
            disabled={isFirstIndex}
            onClick={handlePrevious}
            aria-label="previous"
            sx={{ height: 20, cursor: isFirstIndex ? 'default' : 'pointer' }}
          >
            <MemoLeftArrow stroke={arrowColor} />
          </IconButton>
        </Grid>
        <Grid item>
          <p>{`${currentIndex + 1}/${viewpoints.length}`}</p>
        </Grid>
        <Grid item>
          <IconButton
            disabled={isLastIndex}
            onClick={handleNext}
            aria-label="next"
            sx={{ height: 20 }}
          >
            <MemoRightArrow stroke={isLastIndex ? '#dededf' : '#282829'} />
          </IconButton>
        </Grid>
      </Grid>
    </SliderWrapper>
  );
};

export default CustomSlider;
