import React from 'react';
import styled from 'styled-components';
import SageLogoWatermark from '@icons/SageLogoWatermark';

const BackdropContainer = styled.div`
  position: fixed;
  top: 0;
  z-index: -1;
  width: 50%;
  height: 100vh;
`;

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;

  svg {
    width: 100%;
    height: 100%;
  }
`;

const PageOverlay: React.FC = () => {
  return (
    <BackdropContainer>
      <Wrapper>
        <SageLogoWatermark />
      </Wrapper>
    </BackdropContainer>
  );
};

export default PageOverlay;
