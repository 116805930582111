import React, { useState } from 'react';
import BasicPopover from '@mui/material/Popover';
import { Button, IconButton } from '@mui/material';

interface IPopoverProps {
  children: React.JSX.Element;
  anchorOrigin: {
    vertical: 'top' | 'center' | 'bottom';
    horizontal: 'left' | 'center' | 'right';
  };
  icon?: React.ReactNode;
  cta?: React.ReactNode;
}

function Popover({
  children,
  anchorOrigin,
  icon,
  cta,
}: IPopoverProps): React.JSX.Element {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'popover' : undefined;

  return (
    <div>
      {icon ? (
        <IconButton
          onClick={handleClick}
          aria-describedby={id}
          aria-label="next"
          sx={{ p: 0 }}
        >
          {icon}
        </IconButton>
      ) : (
        <Button
          onClick={handleClick}
          aria-describedby={id}
          aria-label="next"
          sx={{ p: 0 }}
        >
          {cta}
        </Button>
      )}

      <BasicPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={anchorOrigin}
      >
        {children}
      </BasicPopover>
    </div>
  );
}
export default Popover;
