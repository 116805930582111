import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { LayoutWrapper } from '@layout/styles';
import { ConversationWrapper, FooterContainer } from './styles';
import ProgressBar from './reusable/ProgressBar';
import ComponentSlider from './reusable/ComponentSlider';
import Footer from './Footer';
import EndTourButton from './reusable/EndTourButton';

interface TourContentProps {
  setAwaitingNavItem: any;
  setRouteState: any;
  endTour: any;
  showFooterArrow: boolean;
  components: any;
  margin: number;
}

function TourContent(props: TourContentProps): any {
  const {
    setAwaitingNavItem,
    setRouteState,
    endTour,
    components,
    showFooterArrow,
    margin,
  } = props;
  const [index, setIndex] = React.useState(0);
  const [progressBarWidth, setProgressBarWidth] = useState(0);
  const [progressBarTime, setProgressBarTime] = useState(0);
  const [isQuestionMarkHovered, setIsQuestionMarkHovered] =
    React.useState(false);

  useEffect(() => {
    setProgressBarWidth(100);
    setProgressBarTime(
      components.reduce((accumulator, object) => {
        return accumulator + object.timeToShow;
      }, 0) / 1000,
    );
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setAwaitingNavItem('');
      if (components[index].endTour) {
        endTour();
      } else {
        setIndex(index + 1);
      }

      if (components[index].nextComponent) {
        setAwaitingNavItem(components[index].nextComponent);
        setRouteState(components[index].nextComponent);
      }
    }, components[index].timeToShow);
    if (components[index].hoverQuestionMark) {
      setTimeout(() => {
        setIsQuestionMarkHovered(true);
      }, 3500);
      setTimeout(() => {
        setIsQuestionMarkHovered(false);
      }, 5500);
    }

    return () => clearTimeout(timeout);
  }, [index, components.length]);
  return (
    <>
      <ConversationWrapper container direction={'column'} rowGap={3}>
        <Grid item>
          <p className="title">Conversations</p>
          <EndTourButton endTour={endTour} />
        </Grid>
        <Grid item>
          <ProgressBar
            width={progressBarWidth}
            timeToTransit={progressBarTime}
          />
        </Grid>
        <Grid item>
          <ComponentSlider
            components={components}
            index={index}
            margin={margin}
          />
        </Grid>
      </ConversationWrapper>
      <FooterContainer>
        <LayoutWrapper
          container
          animate={true}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Footer
            isQuestionMarkHovered={isQuestionMarkHovered}
            setIsQuestionMarkHovered={setIsQuestionMarkHovered}
            showFooterArrow={showFooterArrow}
          />
        </LayoutWrapper>
      </FooterContainer>
    </>
  );
}

export default TourContent;
