import React from 'react';
import { Grid, Modal, Typography } from '@mui/material';
import SuccessIcon from '@icons/Success';
import { StyledApplyButton } from '@pages/CIOViewpoints/styles';
import {
  SuccessModalContainer,
  SuccessMsgWrapper,
} from '@pages/SuperAdminPage/styles';

interface AdvisorUploadSuccessModalProps {
  isOpen: boolean;
  onClose: () => void;
}

function AdvisorUploadSuccessModal({
  isOpen,
  onClose,
}: AdvisorUploadSuccessModalProps): React.JSX.Element {
  return (
    <Modal open={isOpen} onClose={onClose}>
      <SuccessModalContainer
        container
        xs={3.5}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Typography
          variant="h6"
          component="div"
          style={{ textAlign: 'center' }}
        >
          Advisor Added Successfully!
        </Typography>
        <Grid
          item
          container
          xs={12}
          justifyContent={'center'}
          alignItems={'center'}
          mt={2.5}
        >
          <SuccessIcon />
        </Grid>
        <SuccessMsgWrapper mt={2.5}>
          Advisor added successfully, an email has been sent to them to complete
          their onboarding process.
        </SuccessMsgWrapper>
        <Grid item container mt={2.5} justifyContent={'center'}>
          <StyledApplyButton variant="outlined" onClick={onClose}>
            Continue
          </StyledApplyButton>
        </Grid>
      </SuccessModalContainer>
    </Modal>
  );
}

export default AdvisorUploadSuccessModal;
