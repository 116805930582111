/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import { InputField, InputBoxContainer, SuffixIcon } from './styles';

function Input(props: any): React.JSX.Element {
  const { suffixIcon, onIconClick, ...rest } = props;
  return (
    <InputBoxContainer container>
      <InputField {...rest} />
      {suffixIcon && (
        <SuffixIcon onClick={onIconClick}>{suffixIcon}</SuffixIcon>
      )}
    </InputBoxContainer>
  );
}

export default Input;
