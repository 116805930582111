import * as React from 'react';

function UndoIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 19 19" fill="none" {...props}>
      <path
        d="M6.292 10.063L2.917 6.687l3.375-3.375"
        stroke="#fff"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.292 14.563h6.187a3.937 3.937 0 003.938-3.938v0a3.938 3.938 0 00-3.938-3.938H2.917"
        stroke="#fff"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const MemoUndoIcon = React.memo(UndoIcon);
export default MemoUndoIcon;
