/* eslint-disable react/prop-types */

import React from 'react';

function SuccessIcon(): React.JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="154"
      height="161"
      fill="none"
      viewBox="0 0 154 161"
    >
      <g>
        <circle
          cx="77.016"
          cy="84.128"
          r="55.924"
          fill="#4ACCA9"
          stroke="#B4E9DA"
          strokeWidth="12"
        ></circle>
        <path
          fill="#E1F6F1"
          d="M110.041 73.886L98.385 62.454 71.187 89.13 55.646 73.886 43.989 85.318l27.198 26.675 38.854-38.107z"
        ></path>
        <path
          fill="#B4E9DA"
          d="M15.623 0.5H34.269V19.146H15.623z"
          transform="rotate(45 15.623 .5)"
        ></path>
        <path
          fill="#B4E9DA"
          d="M9.675 26.915H22.106V39.346H9.675z"
          transform="rotate(45 9.675 26.915)"
        ></path>
        <path
          fill="#B4E9DA"
          d="M139.931 134.131H158.577V152.777H139.931z"
          transform="rotate(45 139.931 134.131)"
        ></path>
      </g>
    </svg>
  );
}

const MemoSuitcaseIcon = React.memo(SuccessIcon);
export default MemoSuitcaseIcon;
