import React, { useState } from 'react';
import {
  ComparisonContainerHeader,
  GraphContainer,
  PortfolioContainer,
} from './styles';
import { Box, Grid, IconButton, Stack } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { PageHeading, SmallText } from '@styles/Typography';
import SimpleLineGraph from '@components/UIComponents/SimpleLineGraph';
import HoldingsTable from '@components/UIComponents/HoldingsTable';
import { holdingHeadersJson } from '@common/constant';
import { EColorMapping } from 'app/enums';
import MemoTableViewIcon from '@icons/TableViewIcon';
import MemoChartViewIcon from '@icons/ChartViewIcon';
import AssetAllocation from '@pages/ClientsV2/AssetAllocation';
import { fetchModelPortfolio } from '@components/ChatContent/mutations';
import { useQuery } from 'react-query';
import { ClientsLoaderContainer } from '@components/Loader';
import { Spinner } from '@pages/IntegrationPage/styles';
import { validArray } from '@common/validArray';
import { handleError } from '@common/error';
import { useDispatch } from 'react-redux';

interface ModelPortfolioDrawerProps {
  setSelectedTicker: (value: string) => void;
  setSelectedModel: (value: string) => void;
  selectedModel: string;
}

const ModelPortfolioDrawer = ({
  setSelectedTicker,
  setSelectedModel,
  selectedModel,
}: ModelPortfolioDrawerProps): React.JSX.Element => {
  const dispatch = useDispatch();
  const [isHoldingsChartView, setIsHoldingsChartView] = useState(false);

  const { data: modelPortfolio, isLoading } = useQuery(
    ['fetchModelPortfolio', selectedModel],
    () => fetchModelPortfolio(selectedModel),
    {
      enabled: !!selectedModel,
      onError: (error: any) => {
        handleError(dispatch, error);
        setSelectedModel('');
      },
    },
  );

  if (isLoading) {
    return (
      <ClientsLoaderContainer>
        <Spinner />
      </ClientsLoaderContainer>
    );
  }

  const formattedData =
    modelPortfolio && validArray(modelPortfolio?.historicalReturns)
      ? modelPortfolio.historicalReturns?.map(entry => ({
          date: entry?.date,
          current: entry?.current * 100,
        }))
      : [];

  const allKeys =
    formattedData?.reduce((keys, obj) => {
      Object.keys(obj).forEach((key: string) => {
        // Explicitly type 'key' as string
        if (key !== 'date') {
          keys.add(key);
        }
      });
      return keys;
    }, new Set()) || new Set();

  const lineData = [...allKeys].map((key: any) => ({
    dataKey: key,
    stroke:
      EColorMapping[
        (key.charAt(0).toUpperCase() +
          key.slice(1)) as keyof typeof EColorMapping
      ] || '#000000',
    name: `${key.charAt(0).toUpperCase() + key.slice(1)}`,
  }));

  return (
    <PortfolioContainer>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        sx={{ marginTop: '28px' }}
      >
        {modelPortfolio?.modelName && (
          <PageHeading
            fontSize="24px"
            lineHeight="29.05px"
            sx={{ marginBottom: '7px' }}
          >
            {modelPortfolio?.modelName}
          </PageHeading>
        )}
        <IconButton
          onClick={() => setSelectedModel('')}
          sx={{ marginLeft: 'auto' }}
        >
          <CloseIcon />
        </IconButton>
      </Stack>
      <Box sx={{ margin: '0 0 24px' }}>
        {modelPortfolio?.sponsorName && (
          <Stack direction="row">
            <SmallText fontSize="14px" sx={{ marginRight: '7px !important' }}>
              Model Issuer
            </SmallText>
            <SmallText fontSize="14px" color="#86858B">
              {modelPortfolio?.sponsorName}
            </SmallText>
          </Stack>
        )}
      </Box>

      <GraphContainer item container xs={12} sm={12} m={'20px 0'}>
        <ComparisonContainerHeader item>
          <strong>Returns</strong>
        </ComparisonContainerHeader>
        <SimpleLineGraph
          data={formattedData}
          lines={lineData}
          customLabel={'Returns'}
        />
      </GraphContainer>
      <GraphContainer item container xs={12} sm={12}>
        <Grid
          item
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          sx={{
            width: '100%',
            color: '#282829',
            fontSize: '16px',
            fontWeight: 500,
          }}
        >
          <ComparisonContainerHeader>
            <strong>Proposed Model Holdings</strong>
          </ComparisonContainerHeader>
          <Grid item display={'flex'} gap={'4px'}>
            <Box
              sx={{
                background: !isHoldingsChartView ? '#000' : '',
                borderRadius: !isHoldingsChartView ? '50%' : '',
                height: !isHoldingsChartView ? '32px' : '',
                cursor: 'pointer',
              }}
              onClick={() => setIsHoldingsChartView(false)}
            >
              <MemoTableViewIcon
                fill={!isHoldingsChartView ? '#fff' : '#96969B'}
              />
            </Box>
            <Box
              sx={{
                background: isHoldingsChartView ? '#000' : '',
                borderRadius: isHoldingsChartView ? '50%' : '',
                height: isHoldingsChartView ? '32px' : '',
                cursor: 'pointer',
              }}
              onClick={() => setIsHoldingsChartView(true)}
            >
              <MemoChartViewIcon
                fill={isHoldingsChartView ? '#fff' : '#96969B'}
              />
            </Box>
          </Grid>
        </Grid>
        {!isHoldingsChartView ? (
          <Grid item xs={12}>
            <HoldingsTable
              headers={holdingHeadersJson}
              setSelectedTicker={setSelectedTicker}
              data={modelPortfolio?.holdings}
            />
          </Grid>
        ) : (
          <AssetAllocation
            proposedPortfolioData={modelPortfolio}
            showProposed={true}
            showCurrent={false}
          />
        )}
      </GraphContainer>
    </PortfolioContainer>
  );
};

export default ModelPortfolioDrawer;
