/* eslint-disable react/prop-types */

import React from 'react';

function ListViewIcon({
  activeColor,
}: {
  activeColor: string;
}): React.JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      fill="none"
      viewBox="0 0 16 17"
    >
      <g>
        <path
          stroke={activeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.6"
          d="M5.5 4.5h8"
        ></path>
        <path
          stroke={activeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M5.5 8.5h8"
        ></path>
        <path
          stroke={activeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M5.5 12.5h8"
        ></path>
        <path
          fill={activeColor}
          d="M2.75 5.25a.75.75 0 100-1.5.75.75 0 000 1.5z"
        ></path>
        <path
          fill={activeColor}
          d="M2.75 9.25a.75.75 0 100-1.5.75.75 0 000 1.5z"
        ></path>
        <path
          fill={activeColor}
          d="M2.75 13.25a.75.75 0 100-1.5.75.75 0 000 1.5z"
        ></path>
      </g>
    </svg>
  );
}

const MemoHomeIcon = React.memo(ListViewIcon);
export default MemoHomeIcon;
