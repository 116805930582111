import React, { useState, useRef } from 'react';
import {
  AssetAllocationContainer,
  CustomizedSelect,
  LegendColorDiv,
  LegendTextDiv,
  LegendWrapper,
  ProgressBarLegendContainer,
} from './styles';
import { MenuItem } from '@mui/material';
import { largeColorSet } from '@styles/utils';
import { EAssetMix } from 'app/enums';
import { ALLOCATION_MIX } from '@common/constant';
import AllocationBarChart from './AllocationBarChart';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const AssetAllocation = ({
  currentPortfolioData,
  proposedPortfolioData,
  currentPortfolioName = 'Current',
  recommendedPortfolioName = 'Proposed',
  showCurrent,
  showProposed,
  hideDropdown = false,
}: any): React.JSX.Element => {
  const [selectedMix, setSelectedMix] = useState<EAssetMix>(
    EAssetMix.assetAllocation,
  );

  const drawerRef = useRef<HTMLDivElement>(null);

  // Retrieve current and proposed assets if available, or default to empty arrays
  // hideDropdown is added to display data directly without depending on dropdown. Expecting data to be directly passed as props
  const currentAssets = showCurrent
    ? (hideDropdown
        ? currentPortfolioData
        : currentPortfolioData?.[selectedMix]) || []
    : [];
  const proposedAssets = showProposed
    ? (hideDropdown
        ? proposedPortfolioData
        : proposedPortfolioData?.[selectedMix]) || []
    : [];

  return (
    <>
      <AssetAllocationContainer>
        {!hideDropdown && (
          <CustomizedSelect
            value={selectedMix}
            onChange={(e: any) => setSelectedMix(e.target.value)}
            sx={{
              fontSize: '14px',
              width: '300px',
              '& .MuiSelect-select': {
                padding: '11px 14px',
              },
            }}
          >
            {ALLOCATION_MIX?.map(portfolio => (
              <MenuItem
                key={portfolio?.identifier}
                sx={{ fontSize: '12px', color: '#282829' }}
                value={portfolio?.identifier}
              >
                {portfolio?.name}
              </MenuItem>
            ))}
          </CustomizedSelect>
        )}
        {showCurrent && (
          <>
            <AllocationBarChart
              ref={drawerRef}
              data={currentAssets}
              isMultiple={false}
              title={currentPortfolioName}
            />
          </>
        )}
        {showProposed && (
          <>
            <AllocationBarChart
              ref={drawerRef}
              data={proposedAssets}
              isMultiple={showCurrent}
              title={recommendedPortfolioName}
            />
          </>
        )}
      </AssetAllocationContainer>
      {/* Legends section */}
      {(showCurrent || showProposed) && (
        <AssetAllocationContainer ref={drawerRef} style={{ marginTop: '10px' }}>
          <ProgressBarLegendContainer>
            {/* Legends for proposed portfolio */}
            {showProposed &&
              proposedAssets.length > 0 &&
              proposedAssets.map((item: any, index: number) => (
                <LegendWrapper key={item.name}>
                  <LegendColorDiv
                    style={{ background: largeColorSet[index] }}
                  />
                  <LegendTextDiv> {item?.name}</LegendTextDiv>
                </LegendWrapper>
              ))}
            {/* Legends for current portfolio */}
            {showCurrent &&
              currentAssets.length > 0 &&
              currentAssets.map((item: any, index: number) => {
                // Check if the item is already in proposedAssets to avoid duplicate legends
                const isAlreadyInProposed = proposedAssets.some(
                  (proposedItem: any) => proposedItem?.name === item?.name,
                );
                if (!isAlreadyInProposed) {
                  return (
                    <LegendWrapper key={item.name}>
                      <LegendColorDiv
                        style={{ background: largeColorSet[index] }}
                      />
                      <LegendTextDiv> {item?.name}</LegendTextDiv>
                    </LegendWrapper>
                  );
                }
                return null;
              })}
          </ProgressBarLegendContainer>
        </AssetAllocationContainer>
      )}
    </>
  );
};

export default AssetAllocation;
