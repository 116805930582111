/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { getDateTime } from 'utils/common/dateTime';
import { parseData } from 'utils/common/string';

export const createChatHistoryItem = (chat: any, isSender: any) => ({
  id: chat?.id,
  isSender,
  isChatHistory: true,
  type: 'text',
  time: getDateTime(chat?.createdAt),
  data: chat?.query,
});

export const createChatResponseItem = (
  chatResponse: any,
  debugObj: any,
  chat: any,
) => ({
  id: '',
  isSender: false,
  time: getDateTime(chat?.createdAt),
  feedback: {
    chatLogId: '',
    scale: '0',
    comment: '',
    isSubmitted: false,
  },
  createdAt: chat?.createdAt,
  ...parseData({ data: chatResponse }, debugObj),
});
