import {
  Button,
  Card,
  Divider,
  Grid,
  Input,
  InputLabel,
  styled,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';

import { shouldForwardProp } from 'utils/forwardProp';

interface StyledHorizontalRuleProps {
  width?: string;
}

interface IconProps {
  activeView: string;
}

interface ListViewCardIconDivProps {
  marginRight?: string;
}

interface StyledLabelProps {
  fontSize?: string;
  color?: string;
  fontWeight?: number;
}

interface StyledTableRowProps {
  backgroundColor?: string;
  color?: string;
}

interface StyledTableCellProps {
  color?: string;
}

export const ModalContainer = styled(Grid)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  width: 450px;
`;

export const StyledLabel = styled(InputLabel)<StyledLabelProps>`
  font-size: ${props => props.fontSize || '14px'};
  color: ${props => props.color || '#101828'};
  font-weight: ${props => props.fontWeight || 400};
`;

export const FileUploadContainer = styled(Grid)`
  border: 1px dashed var(--Neutral-200, #e4e7ec);
  // height: 120px;
  padding: 20px;
  background: var(--Neutral-25, #fcfdfd);
  border-radius: 8px;
`;

export const UploadFileLabelContainer = styled(InputLabel)`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  text-align: center;
  gap: 8px;
`;

export const StyledInput = styled('input')`
  display: none;
`;
export const StyledModalInput = styled(Input)`
  display: none;
`;

export const StyledAdminText = styled(Typography)`
  font-size: 16px;
  color: #101828;
  font-weight: 500;
`;

export const StyledHorizontalRule = styled(Divider)<StyledHorizontalRuleProps>`
  border: 0.5px solid #e4e7ec;
  width: ${props => props.width || '100%'};
  height: 0px;
`;

export const UploadFileLabel = styled(Typography)`
  padding: 15px 0px 10px 0px;
  display: flex;
  justify-content: center;
  color: #101828;
  font-size: 16px;
`;
export const AcceptedFileTypeLabelDiv = styled(Typography)`
  padding: 0px 0px 0px 10px;
  color: #475467;
  font-size: 14px;
`;

export const EntCardContainer = styled(Grid)`
  padding: 15px;
  background: #fff;
  height: 160px;
  border: 1px solid var(--Neutral-100, #f2f4f7);
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.12);
  cursor: pointer;
`;

export const EntCardImagePlaceholder = styled(Grid)`
  width: 65px;
  height: 65px;
  background: #f2f4f7;
  border-radius: 4px;
`;

export const NameText = styled(Typography)`
  justify-content: flex-start;
  color: #1d2939;
  font-size: 16px;
  font-weight: 500;
`;

export const CardAnalyticsDataWrapper = styled(Typography)`
  font-size: 12px;
`;

export const StyledDiv = styled(Grid)`
  width: 105%;
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
`;

export const DetailsContainer = styled(Grid)`
  // width: 97%;
  flex-direction: column;
`;

export const StyledContainer = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const StyledText = styled(Typography)`
  color: #1d2939;
  font-size: 16px;
  font-weight: 500;
`;

export const StyledCardWrapper = styled('div')`
  display: flex;
  width: 29%;
  height: 30px;
  flex-direction: row;
  padding: 15px;
  border: 1px solid #e4e7ec;
  background: #fff;
  margin-bottom: 10px;
  border-radius: 8px;
`;

export const StyledTableContainer = styled('div')`
  border-radius: 8px;
  max-height: 450px;
  overflow-y: auto;
`;

export const StyledTable = styled('table')`
  border-collapse: collapse;
  width: 100%;
  border: 1px solid #e4e7ec;
`;

export const StyledAdminHeaderRow = styled('tr')`
  background-color: black;
  color: white;
  font-size: 12px;
  text-align: left;
`;

export const StyledAdminHeaderCell = styled('th')`
  padding: 10px;
  padding-left: 20px;
`;

export const StyledAdminHeader = styled('th')`
  padding: 5px;
  font-size: 12px;
  text-align: left;
  padding-left: 20px;
`;

export const StyledDataCell = styled('td')`
  padding: 10px;
  font-size: 12px;
  padding-left: 18px;
  text-wrap: wrap;
`;

export const StyledSectionRow = styled('tr')`
  background-color: rgba(129, 129, 129, 0.2);
`;

export const SearchBoxContainer = styled('div')`
  margin-right: 10px;
`;

export const StyledSpan = styled('span')`
  margin-right: 5px;
  display: flex;
  justify-content: center;
`;

export const HeaderContainer = styled('div')`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

export const IconContainer = styled(Grid)`
  width: 36px;
  height: 36px;
  background: #f2f4f7;
  border-radius: 4px;
  margin-right: 10px;
`;

export const EnterpriseName = styled(Grid)`
  color: #1d2939;
  font-size: 18px;
  font-weight: 500;
`;

export const ImgContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 10px;
`;

export const StyledData = styled('div')`
  font-size: 18px;
  color: #101828;
`;

export const FirmCardViewTypeContainer = styled(Grid)`
  // display: flex;
  // flex-wrap: wrap;
  cursor: pointer;
`;

export const ViewStyleContainer = styled(Grid)<IconProps>`
  width: 40px;
  height: 40px;
  border: 1px solid #e4e7ec;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  align-items: center;
  justify-content: center;
  border-right: 0px solid;
  background: ${({ activeView }) => (activeView === 'grid' ? '#000' : '#fff')};
  color: ${({ activeView }) => (activeView === 'grid' ? '#fff' : '#000')};
`;

export const ListViewContainer = styled(Grid)<IconProps>`
  width: 40px;
  height: 40px;
  border: 1px solid #e4e7ec;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  align-items: center;
  background: ${({ activeView }) => (activeView === 'list' ? '#000' : '#fff')};
  justify-content: center;
  color: ${({ activeView }) => (activeView === 'list' ? '#fff' : '#000')};
`;

export const ListViewCardIconDiv = styled(Grid)<ListViewCardIconDivProps>`
  width: 40px;
  height: 40px;
  background: #f2f4f7;
  border-radius: 4px;
  margin-right: ${props => props.marginRight || '10px'};
`;

export const ScrollableContainer = styled(Grid)`
  max-height: 100%;
  overflow-y: auto;
`;

export const FirmDetailsWrapper = styled(Grid)`
  padding-top: 20px;
`;

export const SearchContainer = styled('div')`
  margin-right: 10px;
`;

export const SearchBoxInput = styled('input')`
  padding: 10px;
  margin-right: 10px;
  border: 1px solid #e4e7ec;
  border-radius: 4px;
  outline: none;
  box-shadow: 1px 2px 2px 0px rgba(16, 24, 40, 0.12) inset;
  width: 125px;
  @media (min-width: 600px) {
    width: 200px;
  }
`;

export const CustomButton = styled(Button)`
  && {
    display: flex;
    align-items: center; /* Align items vertically */
    font-size: 12px;
    text-transform: none;
    width: 100%;
    background: #000;
    border: 1px solid #e4e7ec;
    color: #fff;
    border-radius: 20px;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.12);
    padding: 8px 16px; /* Adjust padding as needed */

    &:hover {
      opacity: 0.9;
      background: #000;
    }
  }
`;

export const CustomSpan = styled(Grid)`
  display: flex;
  margin-right: 8px; /* Adjust margin as needed */
`;

export const CustomSoloEntCardWrapper = styled(Card)`
  width: calc(22% - 16px);
  padding: 15px;
  display: flex;
  background: #fff;
  height: 130px;
  flex-direction: column;
  border: 1px solid var(--Neutral-100, #f2f4f7);
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.12);
  justify-content: center;
  align-items: center;
`;

export const SuccessModalContainer = styled(Grid)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  background: #fff;
  border-radius: 8px;
`;

export const SuccessIconWrapper = styled('div')`
  width: 100%;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

export const SuccessMsgWrapper = styled(Typography)`
  color: #475467;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
`;

export const SuccessModalBtnWrapper = styled('div')`
  margin-top: 20px;
  display: flex;
  justify-content: center;
`;

export const TextContainer = styled('div')`
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #101828;
`;

export const StyledResendLink = styled('span')`
  display: flex;
  justify-content: flex-start;
  text-decoration: underline;
  cursor: pointer;
`;
//new styles:

export const SuperAdminWrapper = styled(Grid)``;

export const AddFirmAdminButton = styled(Grid)`
  color: #000;
  display: flex;
  border-radius: 20px;
  height: 45px;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  text-transform: none;
  background: linear-gradient(0deg, #fff 0%, #d5d5d5 921.25%);
  background-color: transparent;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.12);
  border: 1px solid #e4e7ec;
`;

export const GridStyleCardContainer = styled(Grid)`
  width: calc(29.5% - 14px);
  background: #fff;
  border: 1px solid var(--Neutral-100, #f2f4f7);
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.12);
  cursor: pointer;
`;

export const StyledTableCell = styled(TableCell)<StyledTableCellProps>`
  padding: 10px;
  padding-left: 20px;
  font-size: 12px;
  text-align: left;
  text-wrap: nowrap;
  color: ${({ color }) => color || 'white'};
`;

export const StyledTableRow = styled(TableRow, {
  shouldForwardProp,
})<StyledTableRowProps>`
  background-color: ${({ backgroundColor }) => backgroundColor || 'black'};
`;

export const CustomTableContainer = styled(TableContainer)`
  background-color: #fff;
  padding: 10px;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 8px;
  box-shadow: none;
`;
