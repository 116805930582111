import instance from 'utils/instances';
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const LoginMutate = async (payload: any) => {
  const createResponse = await instance.post('/user/login', payload, {
    params: {
      queryWithoutToken: true,
    },
  });
  return createResponse?.data;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const recordLoginMutate = async () => {
  const createResponse = await instance.post(
    '/user/recordLogin',
    {},
    {
      headers: {
        'x-tifin-ai-auth': localStorage.getItem('x-tifin-ai-token'),
        'content-type': undefined,
      },
    },
  );
  return createResponse?.data;
};
