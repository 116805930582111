import React from 'react';
import { price } from '@common/number';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { EGetTooltipLegendColors } from 'app/enums';

interface CustomTooltipContentProps {
  active?: boolean;
  payload?: any[];
  label?: string;
  colors?: EGetTooltipLegendColors[];
  backgroundColor?: string;
  fontColor?: string;
  customLabel?: string;
}

const CustomTooltipContent: React.FC<CustomTooltipContentProps> = ({
  active,
  payload,
  label,
  colors = [EGetTooltipLegendColors.Color1, EGetTooltipLegendColors.Color2],
  backgroundColor = '#000',
  fontColor = '#d0d5dd',
  customLabel,
}) => {
  if (active && payload && payload.length && label) {
    return (
      <Grid
        container
        style={{
          backgroundColor: backgroundColor,
          padding: '10px',
          borderRadius: '8px',
          fontSize: '14px',
          maxWidth: '210px',
        }}
      >
        <Grid item xs={12}>
          <p
            style={{
              margin: '0',
              marginBottom: '5px',
              fontWeight: 400,
              color: fontColor,
            }}
          >
            {label}
          </p>
        </Grid>
        {payload.map((entry, index) => {
          return (
            <Grid
              item
              xs={12}
              key={index}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Box display="flex" alignItems="center">
                <Box
                  width={10}
                  height={10}
                  bgcolor={colors[index % colors.length]}
                  marginRight={1}
                />
                <p
                  style={{
                    color: '#fcfdfd',
                    fontSize: '12px',
                    fontWeight: 500,
                    margin: '0',
                  }}
                >
                  {customLabel ??
                    entry?.name?.charAt(0).toUpperCase() +
                      entry?.name?.slice(1)}
                </p>
              </Box>
              <p
                style={{
                  color: '#d0d5dd',
                  fontSize: '12px',
                  fontWeight: 400,
                  margin: '0',
                }}
              >
                {price(entry?.value as number, true, true, false)}
              </p>
            </Grid>
          );
        })}
      </Grid>
    );
  }
  return null;
};

export default CustomTooltipContent;
