import { price } from '@common/number';
import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ReferenceArea,
  ResponsiveContainer,
} from 'recharts';
import { LegendStyles } from './styles';

interface CustomLineChartProps {
  data: {
    ticker: string;
    timeseries_data: { Date: string; Price: number }[];
  }[];
  segments: {
    period: string;
    start_date: string;
    end_date: string;
    funds_data: { name: string; value: number | undefined }[];
  }[];
}

interface CustomLegendItem {
  color: string;
  value: string;
}

export const periodColors = [
  '#a020f0',
  '#5a5a5a',
  '#92fdea',
  '#0047AB',
  '#FF7F50',
  '#008000',
  '#D2042D',
  '#FFEA00',
];

const CustomizedLineChart = ({
  data,
  segments,
}: CustomLineChartProps): React.JSX.Element => {
  // Generating date range from start to end
  const generateDateRange = (startDate: string, endDate: string): string[] => {
    const dates: string[] = [];
    const currentDate = new Date(startDate);
    const lastDate = new Date(endDate);

    while (currentDate <= lastDate) {
      dates.push(currentDate.toISOString().split('T')[0]);
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return dates;
  };

  // searching for the earliest and latest dates in the data
  const allDates = data.flatMap(series =>
    series.timeseries_data.map(item => item.Date),
  );
  const minDate = Math.min(...allDates.map(date => new Date(date).getTime()));
  const maxDate = Math.max(...allDates.map(date => new Date(date).getTime()));

  // then generating a continuous date range
  const continuousDateRange = generateDateRange(
    new Date(minDate).toISOString(),
    new Date(maxDate).toISOString(),
  );

  // after that merging the graph data into the continuous date range
  const mergedData = continuousDateRange.map(date => {
    const entry: { [key: string]: number | string | null } = { date };
    data.forEach(series => {
      const dataPoint = series.timeseries_data.find(item => item.Date === date);
      entry[series.ticker] = dataPoint ? dataPoint.Price : null;
    });
    return entry;
  });

  // Function to generate equidistant ticks
  const generateEquidistantTicks = (
    startDate: string,
    endDate: string,
    numTicks: number,
  ): string[] => {
    const ticks: string[] = [];
    const start = new Date(startDate).getTime();
    const end = new Date(endDate).getTime();
    const interval = (end - start) / (numTicks - 1);

    for (let i = 0; i < numTicks; i++) {
      const tickDate = new Date(start + i * interval);
      ticks.push(tickDate.toISOString().split('T')[0]);
    }

    return ticks;
  };

  // Number of ticks to generate
  const numTicks = 5;
  const customTicks = generateEquidistantTicks(
    new Date(minDate).toISOString().split('T')[0],
    new Date(maxDate).toISOString().split('T')[0],
    numTicks,
  );

  // Defining reference areas for segments
  const referenceAreas = segments?.map((segment, index) => (
    <ReferenceArea
      key={index}
      x1={segment.start_date}
      x2={segment.end_date}
      fill={periodColors[index % periodColors.length]}
      // fillOpacity={0.5}
    />
  ));

  // Format X-axis tick
  const formatXAxisTick = (tick: string): string => {
    const [year, month, day] = tick.split('-');
    return new Intl.DateTimeFormat('en-US', {
      month: 'short',
      day: '2-digit',
      year: 'numeric',
    }).format(new Date(`${year}-${month}-${day}`));
  };

  // Format Y-axis tick
  const formatYAxisTick = (tick: number): string => `$${tick}`;

  return (
    <div style={{ background: 'white', borderRadius: '8px', padding: '20px' }}>
      <ResponsiveContainer width="100%" height={450}>
        <LineChart
          data={mergedData}
          margin={{ top: 10, right: 20, bottom: 30, left: 5 }}
        >
          <CartesianGrid strokeDasharray="none" />
          <XAxis
            dataKey="date"
            style={{ fontSize: '12px' }}
            dx={0}
            dy={10}
            minTickGap={20}
            ticks={customTicks}
            tickFormatter={formatXAxisTick}
            label={{
              value: 'Date',
              position: 'insideBottom',
              offset: -30,
              style: {
                fontSize: '12px',
                fill: '#424242',
                textAnchor: 'middle',
              },
            }}
          />
          <YAxis
            style={{ fontSize: '12px' }}
            tickFormatter={formatYAxisTick}
            label={{
              value: 'Market Price',
              angle: -90,
              position: 'insideLeft',
              offset: 0,
              style: {
                fontSize: '12px',
                fill: '#424242',
                textAnchor: 'middle',
              },
            }}
          />
          <Tooltip
            cursor={{ stroke: '#878b93', strokeWidth: 6 }}
            content={({ active, payload, label }) => {
              if (active && payload && payload.length) {
                const formattedDate = new Intl.DateTimeFormat('en-US', {
                  month: 'short',
                  day: '2-digit',
                  year: 'numeric',
                }).format(new Date(label));
                return (
                  <div
                    style={{
                      backgroundColor: '#fff',
                      padding: '10px',
                      border: '1px solid #ccc',
                      borderRadius: '8px',
                      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                      fontSize: '14px',
                    }}
                  >
                    <p
                      style={{
                        margin: '0',
                        marginBottom: '10px',
                        fontWeight: 'bold',
                        color: '#818181',
                      }}
                    >
                      Date: {formattedDate}
                    </p>
                    {payload.map((entry, index) => (
                      <p
                        key={index}
                        style={{
                          color: entry.color,
                          margin: '0',
                          marginBottom: '5px',
                        }}
                      >
                        {typeof entry.name === 'string' &&
                          entry.name
                            .split('_')
                            .map(
                              word =>
                                word.charAt(0).toUpperCase() + word.slice(1),
                            )
                            .join(' ')}
                        : {price(entry.value as number, true, true, false)}
                      </p>
                    ))}
                  </div>
                );
              }
              return null;
            }}
          />

          {data.map((series, index) => (
            <Line
              key={index}
              type="monotone"
              dot={false}
              strokeWidth={2}
              dataKey={series.ticker}
              stroke={index === 0 ? '#BFAFFF' : index === 1 ? '#000' : 'red'}
              name={series.ticker}
              connectNulls={true}
            />
          ))}
          {referenceAreas}
        </LineChart>
      </ResponsiveContainer>{' '}
    </div>
  );
};

const CustomLegend = ({
  customLegendItems,
}: {
  customLegendItems: CustomLegendItem[];
}): React.JSX.Element => (
  <LegendStyles>
    {customLegendItems.map((item, index) => (
      <div
        key={index}
        style={{
          marginRight: '20px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: 'fit-content',
        }}
      >
        <div
          style={{
            width: '16px',
            height: '16px',
            backgroundColor: item.color,
            display: 'inline-block',
          }}
        />
        <span
          style={{
            marginLeft: '5px',
            fontSize: '12px',
            color: '#424242',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {item.value.replace(/_/g, ' ')}
        </span>
      </div>
    ))}
  </LegendStyles>
);

export { CustomizedLineChart, CustomLegend };
