import React from 'react';
import styled from 'styled-components';
import ChatWatermark from '@icons/ChatWatermark';

const ChatBackdropContainer = styled.div`
  position: fixed;
  top: 90%;
  z-index: -1;
  width: 100%;
  height: 10vh;
`;

const SvgWrapper = styled.div`
  position: absolute;
  top: 0%;
  right: 0;
  bottom: 0;
  width: 100%;
`;

const ChatOverlay: React.FC = () => {
  return (
    <ChatBackdropContainer>
      <SvgWrapper>
        <ChatWatermark />
      </SvgWrapper>
    </ChatBackdropContainer>
  );
};

export default ChatOverlay;
