import { PrimaryButton } from '@components/Button';
import Input from '@components/Input';
import { CircularProgress, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { actions as globalActions } from 'app/features/Global/slice';
import { handleError } from '@common/error';
import MemoDeleteIcon from '@icons/DeleteIcon';
import {
  createCurrentEvent,
  updateCurrentEvent,
} from '@pages/CurrentEvents/mutation';
import { CurrentEventWrapper } from './styles';
import { StyledCard } from '@pages/Profile/styles';
import {
  AcceptedFileTypeLabelDiv,
  FileUploadContainer,
  StyledModalInput,
  UploadFileLabel,
  UploadFileLabelContainer,
} from '@pages/SuperAdminPage/styles';
import media from '@images/new-media.svg';
import viewpointImg from '@images/viewpoint_img.svg';

interface CurrentEventProps {
  handleClose: any;
  editMode?: boolean;
  description?: string;
  questions?: string[];
  focus?: string;
  id?: string;
  imgUrl?: string;
}

const CurrentEventModal = ({
  handleClose,
  editMode = false,
  description: initialDescription,
  questions: initialQuestions,
  focus: initialFocus,
  id,
  imgUrl,
}: CurrentEventProps): React.JSX.Element => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const [questions, setQuestions] = useState(['']);
  const [focus, setFocus] = useState('');
  const [description, setDescription] = useState('');

  const [uploadedImageUrl, setUploadedImageUrl] = useState<string | null>(null);
  const [logoFile, setLogoFile] = useState<File | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const { mutate, isLoading } = useMutation(createCurrentEvent);
  const { mutate: updateMutate, isLoading: updateLoading } =
    useMutation(updateCurrentEvent);

  useEffect(() => {
    if (editMode && initialFocus && initialDescription && initialQuestions) {
      setFocus(initialFocus);
      setDescription(initialDescription);
      setQuestions(initialQuestions);
    }
  }, [editMode]);

  const addQuestion = () => {
    setQuestions([...questions, '']);
  };

  const handleFocusChange = event => {
    setFocus(event.target.value);
  };

  const handleDescriptionChange = event => {
    setDescription(event.target.value);
  };

  const handleQuestionChange = (index, value) => {
    const newQuestions = [...questions];
    newQuestions[index] = value;
    setQuestions(newQuestions);
  };

  const handleDelete = (index: number) => {
    const updatedQuestions = [...questions];
    updatedQuestions.splice(index, 1);
    setQuestions(updatedQuestions);
  };

  const handleSubmit = () => {
    const payload = new FormData();
    payload.append('focus', focus);
    payload.append('summary', description);
    payload.append('advisorQuestions', JSON.stringify(questions));
    if (logoFile) {
      payload.append('image', logoFile);
    }

    mutate(payload, {
      onSuccess: res => {
        handleClose();
        queryClient.invalidateQueries('get-current-events');
        dispatch(
          globalActions.displayToast({
            duration: 3000,
            toastType: 'success',
            toastMessage: res?.message,
          }),
        );
      },
      onError: (error: any) => {
        handleError(dispatch, error);
      },
    });
  };

  const handleEditSubmit = () => {
    const payload = new FormData();
    if (id) {
      payload.append('id', id);
    }

    payload.append('focus', focus);
    payload.append('summary', description);
    payload.append('advisorQuestions', JSON.stringify(questions));
    if (logoFile) {
      payload.append('image', logoFile);
    }

    updateMutate(payload, {
      onSuccess: res => {
        handleClose();
        queryClient.invalidateQueries('get-current-events');
        dispatch(
          globalActions.displayToast({
            duration: 3000,
            toastType: 'success',
            toastMessage: res?.message,
          }),
        );
      },
      onError: (error: any) => {
        handleError(dispatch, error);
      },
    });
  };

  const handleFileUpload = e => {
    const uploadedFile = e.target.files?.[0];

    if (uploadedFile) {
      // Getting the file extension
      const fileName = uploadedFile.name;
      const extension = fileName.split('.').pop()?.toLowerCase();
      const fileSize = uploadedFile.size / 1024;

      // Checkimg if the file extension is allowed (.png or .jpg)
      if (extension && ['png', 'jpg'].includes(extension) && fileSize <= 500) {
        setLogoFile(uploadedFile);
        setErrorMessage(null);

        const reader = new FileReader();
        reader.onload = () => {
          setUploadedImageUrl(reader.result as string);
        };
        reader.readAsDataURL(uploadedFile);
      } else {
        if (fileSize > 5) {
          setErrorMessage(
            'File size exceeds 500 kb. Please select a smaller file.',
          );
        } else {
          setErrorMessage(
            'Invalid file type. Please select a .png or .jpg file.',
          );
        }
      }
    }
  };

  return (
    <CurrentEventWrapper container direction={'column'} rowGap={2}>
      <Grid item container direction={'column'} rowGap={2}>
        <Grid item>
          <p>Focus</p>
        </Grid>
        <Grid item>
          <Input
            className="custom-input"
            value={focus}
            onChange={handleFocusChange}
          />
        </Grid>
      </Grid>
      <Grid item container direction={'column'} rowGap={2}>
        <Grid item>
          <p>Description</p>
        </Grid>
        <Grid item>
          <Input
            className="custom-input"
            value={description}
            onChange={handleDescriptionChange}
          />
        </Grid>
      </Grid>
      <Grid item container direction={'column'} rowGap={2}>
        <Grid item>
          <p>Questions</p>
        </Grid>
        <Grid item container xs={12} rowGap={1}>
          {questions.map((question, index) => (
            <Grid item container columnSpacing={1} alignItems={'center'}>
              <Grid item xs={question.length > 0 ? 11 : 12}>
                <Input
                  className="custom-input"
                  value={question}
                  onChange={e => handleQuestionChange(index, e.target.value)}
                />
              </Grid>
              {question.length > 0 && (
                <Grid
                  item
                  xs={1}
                  onClick={() => handleDelete(index)}
                  className="icon"
                >
                  <MemoDeleteIcon />
                </Grid>
              )}
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item container justifyContent={'flex-end'}>
        <PrimaryButton variant="outlined" color="primary" onClick={addQuestion}>
          Add Question
        </PrimaryButton>
      </Grid>
      <Grid item container xs={12} rowGap={2}>
        <Grid item xs={12}>
          <p className="title">Upload Image*</p>
        </Grid>
        <Grid
          item
          container
          xs={12}
          justifyContent={'space-between'}
          columnGap={1}
          alignItems={'center'}
          flexWrap={'nowrap'}
        >
          <Grid item xs={3}>
            <StyledCard
              sx={{
                margin: '0px',
                width: '100%',
                border: '1px solid #e4e7ec',
                '&.MuiCard-root': {
                  borderRadius: '6px',
                  boxShadow: 'none',
                },
              }}
            >
              {uploadedImageUrl ? (
                <img
                  src={uploadedImageUrl}
                  alt="Uploaded image preview"
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'fill',
                  }}
                />
              ) : imgUrl ? (
                <img
                  src={imgUrl}
                  alt="Uploaded image"
                  style={{
                    width: '100%',
                    height: '100%',
                    // objectFit: 'contain',
                  }}
                />
              ) : (
                <img
                  src={viewpointImg}
                  alt="Default profile image"
                  style={{ objectFit: 'cover' }}
                  width={'100%'}
                  height={'100%'}
                />
              )}
            </StyledCard>
          </Grid>
          <FileUploadContainer
            item
            container
            xs={9}
            justifyContent={'center'}
            alignItems={'center'}
            sx={{
              padding: '5px',
              height: '140px',
              background: '#F6F4FC',
            }}
          >
            <UploadFileLabelContainer htmlFor="uploadLogoInput">
              <StyledModalInput
                type="file"
                id="uploadLogoInput"
                inputProps={{ accept: '.png, .jpg' }}
                onChange={e => handleFileUpload(e)}
              />

              <img src={media} alt="Media image" />
              <UploadFileLabel style={{ fontSize: '14px', padding: '0px' }}>
                <span style={{ fontWeight: 'bold', color: '#282829' }}>
                  Click to upload Viewpoint Image
                </span>{' '}
                <span style={{ color: '#86858B' }}>&nbsp;or drag and drop</span>
              </UploadFileLabel>
              {errorMessage && (
                <Grid item xs={12}>
                  <p
                    style={{
                      color: 'red',
                      fontSize: '14px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {errorMessage}
                  </p>
                </Grid>
              )}
            </UploadFileLabelContainer>
          </FileUploadContainer>
        </Grid>
        <Grid
          item
          container
          xs={12}
          justifyContent={'space-between'}
          ml={17}
          mt={-1}
        >
          <Grid item>
            <AcceptedFileTypeLabelDiv
              sx={{ color: '#535256', fontSize: '12px' }}
            >
              Supported formats: .PNG, .JPG
            </AcceptedFileTypeLabelDiv>
          </Grid>
          <Grid item>
            <AcceptedFileTypeLabelDiv
              sx={{ color: '#535256', fontSize: '12px' }}
            >
              Maximum size: 500kb
            </AcceptedFileTypeLabelDiv>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container columnSpacing={2}>
        <Grid item xs={6}>
          <PrimaryButton
            variant="outlined"
            width={'100%'}
            onClick={() => handleClose()}
          >
            Cancel
          </PrimaryButton>
        </Grid>
        <Grid item xs={6}>
          <PrimaryButton
            width={'100%'}
            onClick={() => {
              if (editMode) {
                handleEditSubmit();
              } else {
                handleSubmit();
              }
            }}
            disabled={isLoading || !logoFile}
          >
            {isLoading || updateLoading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              'Submit'
            )}
          </PrimaryButton>
        </Grid>
      </Grid>
    </CurrentEventWrapper>
  );
};

export default CurrentEventModal;
