import { Grid, IconButton } from '@mui/material';
import styled from 'styled-components';

interface InputStyleProps {
  padding?: string;
  fontSize?: string;
  fontWeight?: string;
  lineHeight?: string;
}

export const InputBoxContainer = styled(Grid)`
  position: relative;
  width: 100%;
  height: auto;
  transition: 0.2s ease-in;
`;

export const InputField = styled.input<InputStyleProps>`
  width: 100%;
  height: ${props => props.height ?? '100%'};
  padding: ${props => props.padding ?? '0 40px 0 8px'};
  font-size: ${props => props.fontSize ?? '14px'};
  font-weight: ${props => props.fontWeight ?? '400'};
  line-height: ${props => props.lineHeight ?? 'normal'};
  color: ${props => props.theme.textBlack};
  outline: 0px;
  border-radius: 4px;
  border: 2px solid ${props => props.theme.borderColor};
  background: ${props => props.theme.inputBackgroundColor};
  box-shadow: 1px 2px 2px 0px rgba(16, 24, 40, 0.12) inset;

  transition:
    border cubic-bezier(0.48, 0.65, 0.6, 0.79) 0.5s,
    background cubic-bezier(0.48, 0.65, 0.6, 0.79) 0.5s;

  &:focus,
  &:focus-visible,
  &:focus-within {
    border: 2px solid ${props => props.theme.main} !important;
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0px 0px 16px 0px rgba(110, 91, 229, 0.1);
    &:placeholder {
      opacity: 0.5;
    }
  }

  &:hover {
    border: 2px solid ${props => props.theme.lightBlack};
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 0px 0px 16px 0px rgba(110, 91, 229, 0.1);
  }

  &:focus ~ label,
  &:hover ~ label {
    transition: 0.2s ease-in;
    font-size: ${props => props.fontSize ?? '10px'};
    font-weight: ${props => props.fontWeight ?? '600'};
    line-height: ${props => props.lineHeight ?? '12px'};
    color: ${props => props.theme.grey5};
  }
  &::placeholder {
    font-size: ${props => props.fontSize ?? '14px'};
    font-weight: ${props => props.fontWeight ?? '200'};
    line-height: ${props => props.lineHeight ?? 'normal'};
  }
`;

export const SuffixIcon = styled(IconButton)`
  position: absolute !important;
  top: 2px;
  right: 7px;
  width: 36px;
  height: 36px;
  flex-shrink: 0;
  transition: 0.2s ease-in;
`;
