import MemoDeleteIcon from '@icons/DeleteIcon';
import MemoPencilIcon from '@icons/PencilIcon';
import { Grid } from '@mui/material';
import React, { useState } from 'react';
import { PrimaryButton } from '@components/Button';
import DoneIcon from '@mui/icons-material/Done';
import RemoveIcon from '@mui/icons-material/Remove';
import { useMutation, useQueryClient } from 'react-query';
import DialogModal from '@components/DialogModal';
import SuccessModal from '@components/SuccessModal';
import { CardWrapper, Text } from './styles';

// import { deleteViewpoint, updateViewpoint, viewpointStatus } from './mutate';

import { deleteViewpoint, viewpointStatus } from './mutate';
import { useDispatch } from 'react-redux';
import { actions as globalActions } from 'app/features/Global/slice';
import { handleError } from '@common/error';
import EditModal from './EditModal';
import ConfirmationModal from './ConfirmationModal';
import MemoGreenTick from '@icons/GreenTick';
import PdfViewer from '@components/PdfViewer';
// import PdfViewer from './PdfViewer';
// import PdfHighlighter from './PdfHighlighter';
// import PdfView from './PdfView';

export interface Highlight {
  highlighted: string;
  page_number: number;
}
interface EnterpriseCardprops {
  title: string;
  description: string;
  questions: string[];
  id: string;
  documentId: string;
  status: string;
  source: Highlight[];
  fileUrl: string;
  imgUrl: string;
}

function EnterpriseCard({
  title,
  description,
  questions,
  status,
  id,
  source,
  fileUrl,
  imgUrl,
}: EnterpriseCardprops): React.JSX.Element {
  const dispatch = useDispatch();
  const { mutate: publishMutate, isLoading: publishLoading } =
    useMutation(viewpointStatus);
  const { mutate: deleteMutate, isLoading: deleteLoading } =
    useMutation(deleteViewpoint);
  const queryClient = useQueryClient();
  const [showEditModel, setShowEditModel] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showPublishModal, setShowPublishModal] = useState(false);
  const [showSource, setShowSource] = useState(false);
  const publishStatus = status === 'draft' ? 'publish' : 'unpublish';

  const handlePublish = () => {
    const payload = {
      id,
      action: publishStatus,
    };

    publishMutate(payload, {
      onSuccess: res => {
        handlePublishClose();
        queryClient.invalidateQueries('extract-viewpoint');
        dispatch(
          globalActions.displayToast({
            duration: 3000,
            toastType: 'success',
            toastMessage: res?.message,
          }),
        );
      },
      onError: (err: any) => {
        handleError(dispatch, err);
      },
    });
  };

  const handleDelete = () => {
    const payload = {
      key: 'id',
      value: id,
    };

    deleteMutate(payload, {
      onSuccess: res => {
        handleDeleteClose();
        queryClient.invalidateQueries('extract-viewpoint');
        dispatch(
          globalActions.displayToast({
            duration: 3000,
            toastType: 'success',
            toastMessage: res?.message,
          }),
        );
      },
      onError: (err: any) => {
        handleError(dispatch, err);
      },
    });
  };

  const getButtonTextAndIcon = status => {
    if (status === 'draft') {
      return {
        text: 'Publish Viewpoint',
        icon: <DoneIcon />,
      };
    } else {
      return {
        text: 'Unpublish Viewpoint',
        icon: <RemoveIcon />,
      };
    }
  };

  const handleClose = () => {
    setShowEditModel(false);
  };

  const handleDeleteClose = () => {
    setShowDeleteModal(false);
  };

  const handlePublishClose = () => {
    setShowPublishModal(false);
  };

  const handleCloseSource = () => {
    setShowSource(false);
  };

  return (
    <>
      <CardWrapper container rowGap={1}>
        <Grid item container xs={12} justifyContent={'space-between'}>
          <Grid item container alignItems={'center'}>
            <Grid item xs={12} md={4}>
              <p className="title">{title}</p>
            </Grid>
            <Grid
              item
              container
              xs={12}
              md={8}
              justifyContent={'flex-end'}
              alignItems={'center'}
              columnGap={2}
              rowGap={1}
            >
              <Grid
                item
                xs={3}
                md={1}
                container
                justifyContent={'center'}
                className="delete-wrapper"
                onClick={() => setShowDeleteModal(true)}
              >
                <MemoDeleteIcon />
              </Grid>
              <Grid
                item
                container
                xs={6}
                md={2}
                alignItems={'center'}
                columnGap={1}
                className="edit-wrapper"
                justifyContent={'center'}
                onClick={() => setShowEditModel(true)}
              >
                <Grid item>
                  <MemoPencilIcon />
                </Grid>
                <Grid item>
                  <p className="edit">Edit</p>
                </Grid>
              </Grid>
              <Grid item>
                <PrimaryButton
                  color="primary"
                  className="button"
                  style={{ textTransform: 'none' }}
                  onClick={() => setShowPublishModal(true)}
                >
                  <Grid
                    item
                    container
                    alignItems={'center'}
                    columnGap={1}
                    justifyContent={'center'}
                  >
                    <Grid item className="icon">
                      {getButtonTextAndIcon(status).icon}
                    </Grid>
                    <Grid item>
                      <Text>{getButtonTextAndIcon(status).text}</Text>
                    </Grid>
                  </Grid>
                </PrimaryButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <p className="desc">{description}</p>
        </Grid>
        <Grid item container direction={'column'} rowGap={2}>
          <Grid item>
            <p className="questions-title">List of Questions</p>
          </Grid>
          <Grid item container columnGap={2} rowGap={2}>
            {questions.map((item, index) => (
              <Grid key={index} item className="question-wrapper">
                <p className="question">{item}</p>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item onClick={() => setShowSource(true)}>
          <p className="edit-wrapper">Source</p>
        </Grid>
      </CardWrapper>

      {showSuccessModal && (
        <SuccessModal
          isOpen={showSuccessModal}
          cta={'Continue'}
          message={'Viewpoint Updated Successfully!'}
          ctaMessage={''}
          handleCtaClick={() => setShowSuccessModal(false)}
          handleClose={() => setShowSuccessModal(false)}
        />
      )}

      {showDeleteModal && (
        <DialogModal
          openModal={showDeleteModal}
          handleClose={handleDeleteClose}
          // title={`${()}`}
        >
          <ConfirmationModal
            icon={<MemoDeleteIcon />}
            headerText={'Delete Viewpoint'}
            title={`You are about to permanently delete ${title} viewpoint for all your advisors. Are you sure you want to continue?`}
            subTitle={
              'Note - Once you delete this viewpoint it will not visible to any of your advisors.'
            }
            handleClose={handleDeleteClose}
            handleSubmit={handleDelete}
            isLoading={deleteLoading}
          />
        </DialogModal>
      )}

      {showPublishModal && (
        <DialogModal
          openModal={showPublishModal}
          handleClose={handlePublishClose}
        >
          <ConfirmationModal
            icon={<MemoGreenTick />}
            headerText={'Confirm'}
            title={`Are you sure you want to ${publishStatus} Consumer Discretionary viewpoint for all your advisors?`}
            subTitle={
              'Note - Once you publish this viewpoint will be visible to all your advisors.'
            }
            handleClose={handlePublishClose}
            handleSubmit={handlePublish}
            isLoading={publishLoading}
          />
        </DialogModal>
      )}

      {showEditModel && (
        <DialogModal
          openModal={showEditModel}
          handleClose={handleClose}
          title="Response Explanation"
          showTitle
        >
          <EditModal
            description={description}
            questions={questions}
            handleClose={handleClose}
            id={id}
            setShowSuccessModal={setShowSuccessModal}
            imgUrl={imgUrl}
          />
        </DialogModal>
      )}

      {showSource && (
        <DialogModal
          openModal={showSource}
          handleClose={handleCloseSource}
          // fullWidth={'lg'}
          fullWidth={true}
          maxWidth={'lg'}
        >
          <PdfViewer fileUrl={fileUrl} source={source} />
        </DialogModal>
      )}
    </>
  );
}

export default EnterpriseCard;
