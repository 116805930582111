export const LayoutGutter = {
  x: '2rem',
  y: '2rem',
  sidebar: {
    x: '1rem',
    y: '2rem',
    expandWidth: 280,
    collapseWidth: 94,
  },
};

export const ResponsiveLayoutGutter = {
  x: '0.5rem',
  y: '0.5rem',
  sidebar: {
    ...LayoutGutter.sidebar,
    expandWidth: 70,
    collapseWidth: 70,
  },
};

export const ContentMaxWidth = '1440px';

export const LayoutSize = {
  headerHeght: '94px',
  contentHeight: 'calc(90vh - 94px)',
  footerHeight: '10vh',
};
