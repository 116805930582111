import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { CircularProgress, Drawer } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { actions } from '@features/Chat/slice';
import InsightsCard from './InsightsCard';
import InsightDrawer from './InsightDrawer';
import {
  CardContainer,
  DateWrapper,
  FileNameWrapper,
  InsightPageContainer,
  InsightPageContentContainer,
  PaginationContainer,
} from './styles';
import StartPageOverlay from '@components/StartPageOverlay';
import { useRESTQuery } from '@hooks/useRESTQuery';
import { actions as globalActions } from 'app/features/Global/slice';
import { useQueryClient } from 'react-query';
import CustomPagination from '@components/Pagination';

const useStyles = makeStyles({
  drawer: {
    width: 500,
  },
  drawerPaper: {
    width: 500,
  },
  root: {
    display: 'flex',
  },
});

function CIOViewpoints(): React.JSX.Element {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);
  const [selectedData, setDrawerData]: any = useState({});
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage] = useState<number>(10);
  const isChatStreaming = localStorage.getItem('streaming') as string;

  const parentWidth = '94%';

  const customTop = '10%';
  const queryClient = useQueryClient();

  useEffect(() => {
    // Scroll to the top when the page changes
    containerRef.current?.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [page]);

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
    queryClient.invalidateQueries('get-cio-viewpoints');
  };

  const handleDrawerOpen = (implementation_plan: any) => {
    if (setDrawerData) {
      setDrawerData(implementation_plan);
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setDrawerData({});
  };

  const triggerQuery = (query: string) => {
    navigate('/conversations/start');
    if (isChatStreaming === 'true') {
      dispatch(actions.setSearchQuery(query));
    } else {
      dispatch(actions.searchQueryRequest({ query }));
    }
  };

  const handleError = (error: any) => {
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.message &&
      typeof error.response.data.message === 'string'
    ) {
      dispatch(
        globalActions.displayToast({
          duration: 3000,
          toastType: 'error',
          toastMessage: error?.response?.data?.message,
        }),
      );
    } else {
      dispatch(
        globalActions.displayToast({
          duration: 3000,
          toastType: 'error',
          toastMessage: 'Something went wrong',
        }),
      );
    }
  };
  const { data: cioData, isLoading } = useRESTQuery(
    ['get-cio-viewpoints', page],
    {
      endpoint: `/viewpoints?page=${page}&limit=${rowsPerPage}`,
      method: 'GET',
      headers: {
        'X-Tifin-Ai-Auth': localStorage.getItem('x-tifin-ai-token'),
      },
    },

    {
      select: (response: any) => {
        const totalCount = response?.total;
        return {
          data: response?.data,
          totalCount,
        };
      },

      onSuccess: (response: any) => {
        console.log('response--->', response);
      },

      onError: (error: any) => {
        handleError(error);
      },
    },
  );
  return (
    <InsightPageContainer>
      <InsightPageContentContainer
        style={{ zIndex: 2, position: 'relative' }}
        ref={containerRef}
      >
        {isLoading && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              background: 'transparent',
            }}
          >
            <CircularProgress />
          </div>
        )}{' '}
        {!isLoading && cioData && (
          <>
            {cioData?.data?.length > 0 && (
              <>
                {cioData?.data?.map((item: any, index: number) => {
                  console.log('ITEM--->', item);
                  return (
                    <React.Fragment key={index}>
                      <DateWrapper>
                        {item.createdDate
                          ? new Date(item.createdDate).toLocaleDateString(
                              'en-US',
                              {
                                year: 'numeric',
                                month: '2-digit',
                                day: '2-digit',
                              },
                            )
                          : ''}
                      </DateWrapper>
                      <CardContainer>
                        <FileNameWrapper>{item?.filename}</FileNameWrapper>
                        {item?.data?.map((dataItem, dataIndex) => {
                          return (
                            <InsightsCard
                              key={dataIndex}
                              item={dataItem}
                              triggerQuery={triggerQuery}
                              handleDrawerOpen={handleDrawerOpen}
                            />
                          );
                        })}
                      </CardContainer>
                    </React.Fragment>
                  );
                })}
              </>
            )}
          </>
        )}
      </InsightPageContentContainer>
      {!isLoading && cioData && cioData.data.length > 0 && (
        <PaginationContainer>
          <CustomPagination
            page={page}
            count={cioData?.totalCount}
            rowsPerPage={rowsPerPage}
            onPageChange={handlePageChange}
            parentWidth={parentWidth}
          />
        </PaginationContainer>
      )}
      <div className={classes.root}>
        <Drawer
          anchor="right"
          variant="temporary"
          open={open}
          className={classes.drawer}
          classes={{ paper: classes.drawerPaper }}
          onClose={handleClose}
        >
          <InsightDrawer
            selectedData={selectedData}
            triggerQuery={triggerQuery}
            handleClose={handleClose}
          />
        </Drawer>
      </div>
      <StartPageOverlay customTop={customTop} />
    </InsightPageContainer>
  );
}

export default CIOViewpoints;
