import React from 'react';
import { Skeleton, Box } from '@mui/material';

const TestResultListSkeleton = (): React.JSX.Element => {
  return (
    <React.Fragment>
      <Box
        sx={{
          width: '100%',
          height: 'calc(100vh - 124px)',
        }}
      >
        <Skeleton
          variant="rectangular"
          animation="wave"
          width={'100%'}
          height={'100%'}
        />
      </Box>
    </React.Fragment>
  );
};

export default TestResultListSkeleton;
