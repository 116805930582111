import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  IconButton,
  Typography,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { styled } from '@mui/system';
import { StyledInputLabel } from './styles';
import instance from 'utils/instances';
import { actions as globalActions } from 'app/features/Global/slice';

interface MyModalFormProps {
  open: boolean;
  onClose: () => void;
}

const CenteredDialogActions = styled(DialogActions)({
  justifyContent: 'center',
  paddingBottom: '20px',
});

const StyledButton = styled(Button)({
  margin: '0 10px',
});

const CloseIconButton = styled(IconButton)({
  position: 'absolute',
  right: '8px',
  top: '8px',
});

const MyModalForm: React.FC<MyModalFormProps> = ({ open, onClose }) => {
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const [confirmPassword, setConfirmPassword] = useState('');

  const handleSubmit = async () => {
    const payload = {
      currentPassword: password,
      newPassword: confirmPassword,
    };
    instance
      .post('/resetPassword', payload, {})
      .then(() => {
        setPassword('');
        setConfirmPassword('');
        onClose();
        dispatch(
          globalActions.displayToast({
            duration: 3000,
            toastType: 'success',
            toastMessage: 'Password reset successfully.',
          }),
        );
      })
      .catch(error => {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message &&
          typeof error.response.data.message === 'string'
        ) {
          dispatch(
            globalActions.displayToast({
              duration: 3000,
              toastType: 'error',
              toastMessage: error?.response?.data?.message,
            }),
          );
        } else {
          dispatch(
            globalActions.displayToast({
              duration: 3000,
              toastType: 'error',
              toastMessage: 'Something went wrong',
            }),
          );
        }
      });
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <CloseIconButton onClick={onClose}>
        <CloseIcon />
      </CloseIconButton>
      <DialogTitle>Reset Password</DialogTitle>
      <DialogContent>
        <StyledInputLabel>
          <Typography variant="subtitle2">Enter Password*</Typography>
        </StyledInputLabel>
        <TextField
          variant="outlined"
          fullWidth
          size={'small'}
          type="password"
          value={password}
          required
          onChange={e => setPassword(e.target.value)}
        />
        <StyledInputLabel>
          <Typography mt={2} variant="subtitle2">
            Confirm Password*
          </Typography>
        </StyledInputLabel>
        <TextField
          variant="outlined"
          fullWidth
          size={'small'}
          type="password"
          required
          value={confirmPassword}
          onChange={e => setConfirmPassword(e.target.value)}
        />
      </DialogContent>
      <CenteredDialogActions>
        <StyledButton variant="outlined" color="primary" onClick={onClose}>
          Cancel
        </StyledButton>
        <StyledButton
          variant="contained"
          color="primary"
          onClick={handleSubmit}
        >
          Submit
        </StyledButton>
      </CenteredDialogActions>
    </Dialog>
  );
};

export default MyModalForm;
