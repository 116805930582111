/* eslint-disable react/prop-types */

import React from 'react';

interface IntegrationIconProps {
  strokeColor?: string;
  height?: number;
  width?: number;
}

function IntegrationIcon({
  height = 22,
  width = 22,
}: IntegrationIconProps): React.JSX.Element {
  const marginLeft = '-3px';
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 24 25"
      style={{ marginLeft }}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M8.822 15.666L15.178 9.3M13.594 17.26l-2.654 2.653a4.5 4.5 0 01-7.684-3.183c0-1.194.475-2.339 1.319-3.183l2.653-2.653M16.772 14.081l2.653-2.653a4.502 4.502 0 00-6.366-6.365l-2.653 2.653"
      ></path>
    </svg>
  );
}

const MemoIntegrationIcon = React.memo(IntegrationIcon);
export default MemoIntegrationIcon;
