import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { OutlineBox } from '@pages/ClientsV2/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Grid,
  Menu,
  MenuItem,
  Typography,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Paper,
  TableRow,
  TableCell,
} from '@mui/material';
import SimpleScatterChart from '@components/Chart/ScatterChart';
import { COLORS } from '@common/constant';
import theme from '@styles/theme';
import useMediaQuery from '@mui/material/useMediaQuery';
import { convertToNDecimal } from '@common/number';
import { validArray } from '@common/validArray';
import { FundSwapComponent } from './styles';
import CustomSlider from '@components/CustomSlider';

type Suggestions = {
  chatId?: any;
  data: any;
  tickerInfo: {
    ticker: string;
    name: string;
  };
  selectTicker: Dispatch<SetStateAction<string>>;
  list: any[];
  currentIndex: number;
  setCurrentIndex: (s: number) => void;
};

type DropdownType = {
  anchorEl: any;
  handleClose: (event: any) => void;
  options: { key: string; value: string | number }[];
  onChange: any;
};

const Dropdown = ({
  anchorEl,
  handleClose,
  options = [{ key: 'test', value: '1' }],
  onChange,
}: DropdownType): React.JSX.Element => {
  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      sx={{
        marginTop: '3px',
        width: '50%',
        '& .MuiPaper-root': {
          marginLeft: '22px',
          background: '#fff',
        },
        '& .MuiMenuItem-root:first-of-type': {
          background: '#fff',
        },
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      {Array.isArray(options) &&
        options.length > 0 &&
        options.map((option, i: number) => (
          <MenuItem
            key={i}
            sx={{
              fontSize: '12px',
              color: '#101828',
              '& .MuiPaper-root': {
                background: '#fff',
              },
              '&:hover': {
                backgroundColor: '#fff',
              },
            }}
            onClick={() => onChange(option.value)}
          >
            {option.key}
          </MenuItem>
        ))}
    </Menu>
  );
};

const DropdownTarget = ({
  text,
  onChange,
  graphConfig,
}: any): React.JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dropdownOptions = !graphConfig
    ? [
        { key: '5-Year', value: '5-Year' },
        { key: '3-Year', value: '3-Year' },
        { key: '1-Year', value: '1-Year' },
      ]
    : [
        { key: 'Similarity', value: 'similarity' },
        { key: 'Fees', value: 'fees' },
        { key: 'Risk', value: 'risk' },
        { key: 'Return', value: 'return' },
        { key: 'Yield', value: 'yield' },
      ].filter(column => column.value !== text);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <OutlineBox
      item
      container
      display={'flex'}
      direction={'row'}
      alignItems={'flex-start'}
      xs={12}
      lg={5}
      height={'32px'}
      width={'fit-content'}
      minWidth={'100px'}
      justifyContent={'space-evenly'}
      columnGap={0.1}
      borderRadius={'0px'}
    >
      <Grid
        item
        container
        alignItems={'center'}
        xs={8}
        lg={9}
        fontSize={'12px'}
        fontWeight={500}
        lineHeight={'14.5px'}
        textTransform={'capitalize'}
      >
        {text}
        {/* Sort */}
      </Grid>
      <Grid
        item
        container
        xs={2}
        lg={1}
        mt={'-4px'}
        onClick={handleClick}
        alignItems={'center'}
        sx={{ cursor: 'pointer' }}
      >
        <KeyboardArrowDownIcon
          sx={{
            height: '25px',
            fontSize: '16px',
            fontWeight: 300,
          }}
        />
      </Grid>
      <Dropdown
        anchorEl={anchorEl}
        handleClose={handleClose}
        onChange={onChange}
        options={dropdownOptions}
      />
    </OutlineBox>
  );

  // <Box sx={{height:'28px', width:'fit-content', minWidth:'60px', alignItems:'center', padding:'5px 20px', background:'#FFFFFF'}}>
  //   <Typography onClick={handleClick}>{text}</Typography>
  //   <Dropdown anchorEl={anchorEl} handleClose={handleClose} onChange={onChange} options={[{ value: '3-Year' }]} />
  // </Box>
};

const FundCell = ({
  text,
  selectTicker,
  color,
}: {
  text: string;
  selectTicker: Dispatch<SetStateAction<string>>;
  color: string;
}): React.JSX.Element => {
  return (
    <Box
      sx={{
        borderRadius: '2px',
        padding: '8px 10px',
        width: '70px',
        background: color,
        color: '#FFFFFF',
        textAlign: 'center',
        fontWeight: 500,
        cursor: 'pointer',
      }}
      onClick={() => selectTicker?.(text)}
    >
      {text}
    </Box>
  );
};

const CurrentCell = ({
  text,
  conversion,
}: {
  text: string | number;
  conversion?: (n: number | string) => string;
}): React.JSX.Element => {
  return (
    <Box
      sx={{
        borderRadius: '2px',
        padding: '8px 10px',
        background: '#F6F4FC',
        color: '#535256',
      }}
    >
      {text || text === 0
        ? conversion
          ? conversion(text)
          : `${convertToNDecimal(text, 2)}%`
        : '-'}
    </Box>
  );
};
const FundSwapping = ({
  chatId = '',
  data = [],
  tickerInfo,
  selectTicker,
  list,
  currentIndex,
  setCurrentIndex,
}: Suggestions): React.JSX.Element => {
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  const [dropdownYear, setDropdownYear] = useState<string>('3-Year');
  const [dropdown2, setDropdown2] = useState<string>('risk');
  const [dropdown3, setDropdown3] = useState<string>('return');
  const [selectedData, setSelectedData] = useState<any>([...data]);

  useEffect(() => {
    setSelectedData(
      data
        .filter(fund => fund?.period == dropdownYear[0])
        .reduce((acc, item) => {
          if (!acc?.find(obj => obj.fund === item.fund)) {
            acc.push(item);
          }
          return acc;
        }, [])
        .map((item: any, i: number) => {
          const newObj = {};
          for (const key in item) {
            if (key === 'fees') {
              newObj[`${key}_1d`] = convertToNDecimal(
                Number(item[key] * 100),
                1,
              );
            } else if (typeof item[key] === 'number') {
              newObj[`${key}_1d`] = convertToNDecimal(item[key], 1);
            }
          }

          return {
            ...item,
            ...newObj,
            fees: item.fees ? Number(item.fees) * 100 : item.fees,
            tickerColor: COLORS[i % COLORS.length],
          };
        }),
    ); //filter logic

    console.log('selectedData : ', selectedData);
  }, [dropdownYear, dropdown2, dropdown3]);

  const headers = [
    { key: 'fund', width: '17%' },
    { key: 'similarity', width: '17%' },
    {
      key: 'fees',
      width: '17%',
      title: 'Fees (BPS)',
      conversion: (n: number | string) => {
        return `${convertToNDecimal(Number(n), 2)}`;
      },
    },
    {
      key: 'risk',
      width: '17%',
    },
    { key: 'return', width: '17%' },
    { key: 'yield', width: '17%' },
  ];

  return (
    <FundSwapComponent key={chatId}>
      <Box justifyContent={'space-between'} display={'flex'} flexWrap={'wrap'}>
        <Box>
          {tickerInfo?.ticker && (
            <Typography
              sx={{ fontSize: '14px', fontWeight: '700', color: '#424242' }}
            >
              RECOMMENDED REPLACEMENTS FOR {tickerInfo?.ticker}
            </Typography>
          )}
          {tickerInfo?.name && (
            <Typography
              sx={{ fontSize: '12px', fontWeight: '400', color: '#8E8E8E' }}
            >
              {tickerInfo?.name}
            </Typography>
          )}
        </Box>
        <Box>
          {validArray(list) && list.length > 1 && (
            <CustomSlider
              viewpoints={list}
              currentIndex={currentIndex}
              setCurrentIndex={setCurrentIndex}
              sx={{
                fontSize: '14px',
              }}
            />
          )}
        </Box>
      </Box>

      <Grid
        container
        item
        width={'400px'}
        sx={{ margin: '20px 0 20px 0' }}
        columnGap={'10px'}
        rowGap={'10px'}
        alignItems={'center'}
        direction={'row'}
        color={'#8E8E8E'}
        xs={12}
      >
        <Grid item xs={6} sm={3}>
          {' '}
          <DropdownTarget text={dropdownYear} onChange={setDropdownYear} />{' '}
        </Grid>
        <Grid
          item
          xs={0.25}
          sx={{
            display: {
              xs: 'none',
              sm: 'grid',
            },
          }}
        >
          |
        </Grid>
        <Grid item xs={6} sm={3}>
          <DropdownTarget
            text={dropdown2}
            onChange={setDropdown2}
            graphConfig
          />
        </Grid>
        <Grid
          item
          xs={0.5}
          sx={{
            display: {
              xs: 'none',
              sm: 'grid',
            },
          }}
        >
          vs
        </Grid>
        <Grid item xs={6} sm={3}>
          <DropdownTarget
            text={dropdown3}
            onChange={setDropdown3}
            graphConfig
          />
        </Grid>
      </Grid>

      <Grid
        container
        columnGap={'10px'}
        gap={'1rem'}
        flexWrap={isLargeScreen ? 'nowrap' : 'wrap'}
      >
        <Grid item xs={12} md={10} lg={8}>
          <TableContainer component={Paper}>
            <Table
              sx={{
                background: '#FFFFFF',
                height: 'fit-content',
                padding: '10px',
              }}
            >
              <TableHead>
                <TableRow>
                  {validArray(headers) &&
                    headers.map((header: any, i: number) => (
                      <TableCell
                        key={i}
                        sx={{
                          fontWeight: '400',
                          fontSize: '12px',
                          lineHeight: '14.52px',
                          color: '#86858B',
                          textTransform: 'capitalize',
                          textAlign: 'center',
                          textWrap: 'nowrap',
                          width: header.width,
                        }}
                      >
                        {header.title ?? header.key}
                      </TableCell>
                    ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {validArray(selectedData) &&
                  selectedData
                    .sort((a, b) => a[dropdown3] - b[dropdown3])
                    .slice(0, 4)
                    .map((row, index) => (
                      <TableRow key={index} hover>
                        {headers.map((header, index) => (
                          <TableCell
                            key={index}
                            sx={{
                              fontWeight: '400',
                              fontSize: '14px',
                              lineHeight: '16.94px',
                              color: '#86858B',
                              height: '20px',
                              textAlign: 'center',
                              py: '7px',
                            }}
                          >
                            {header.key === 'fund' ? (
                              <FundCell
                                text={row[header.key]}
                                color={row.tickerColor}
                                selectTicker={selectTicker}
                              />
                            ) : header.key === dropdown2 ? (
                              <CurrentCell
                                text={row[header.key]}
                                conversion={header.conversion}
                              />
                            ) : row[header.key] || row[header.key] === 0 ? (
                              `${header.conversion ? header.conversion(row[header.key]) : `${convertToNDecimal(row[header.key], 2)}%`}`
                            ) : (
                              '-'
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          sx={{
            boxShadow: 1,
            borderRadius: '4px',
            overflow: 'hidden',
          }}
        >
          <SimpleScatterChart
            data={selectedData
              .filter(fund => fund[dropdown3.toLocaleLowerCase()])
              .sort((a, b) => a[dropdown3] - b[dropdown3])
              .slice(0, 4)}
            keyY={`${dropdown3}_1d`}
            keyX={`${dropdown2}_1d`}
          />
        </Grid>
      </Grid>
    </FundSwapComponent>
  );
};

export default FundSwapping;
