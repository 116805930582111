import React from 'react';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import { useNavigate } from 'react-router-dom';
import ErrorScreen from '../ErrorScreen';
import { ErrorBoundaryProps } from './types';

function ErrorBoundary({
  children,
  onError,
}: ErrorBoundaryProps): React.JSX.Element {
  const navigate = useNavigate();
  const handleError = () => {
    if (onError) {
      onError();
    } else {
      navigate('/dashboard');
    }
  };
  return (
    <ReactErrorBoundary
      FallbackComponent={({ resetErrorBoundary }) => {
        return (
          <ErrorScreen
            title="An unknown error occurred"
            message="Please try again after sometime."
            tryAgain={() => {
              handleError();
              resetErrorBoundary();
            }}
            showTryAgain
          />
        );
      }}
      onReset={() => {
        // Handle reset or retry logic here
        handleError();
      }}
    >
      {children}
    </ReactErrorBoundary>
  );
}

export default ErrorBoundary;
