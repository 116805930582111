import { CSVRow, Model } from '@components/CsvUpload/types';
import { validArray } from './validArray';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const handlePortfolio = (jsonPortFolio: any) => {
  const currentData = jsonPortFolio.current_portfolio.holdings?.map(
    ({ name, weight, ticker, estTradeValue = 0 }) => {
      const proposedHoldingData =
        jsonPortFolio.proposed_portfolio.holdings.find(
          val => val?.name === name,
        );
      return {
        fund: name,
        subText: ticker,
        estTradeValue: estTradeValue, //FIX: Needs to be passed from BE
        currentWeight: weight,
        proposedWeight: proposedHoldingData?.weight || 0,
      };
    },
  );
  const filteredData = jsonPortFolio.proposed_portfolio.holdings.reduce(
    (acc, cur) => {
      if (!currentData.find(val => val.fund === cur.name)) {
        acc.push({
          fund: cur.name,
          subText: cur.ticker,
          estTradeValue: cur.estTradeValue ?? 0,
          proposedWeight: cur.weight ?? 0,
          currentWeight: 0,
        });
      }
      return acc;
    },
    [],
  );
  return [...currentData, ...filteredData];
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const handleHistoricalReturn = historicalReturns => {
  const combinedData = historicalReturns.currentPortfolioReturns.map(val => {
    return {
      date: val.date,
      current: val.value,
      proposed:
        historicalReturns.recommendationReturns.find(
          value => value.date === val.date,
        )?.value || 0,
    };
  });
  const filteredData = historicalReturns.recommendationReturns.reduce(
    (acc, cur) => {
      if (!combinedData.find(val => val.date === cur.date)) {
        acc.push({
          date: cur.date,
          current: 0,
          proposed: cur.value,
        });
      }
      return acc;
    },
    [],
  );
  return [...combinedData, ...filteredData];
};

export const transformCSVData = (
  csvData: CSVRow[],
  requiredFields: string[],
): Model[] => {
  if (!validArray(csvData)) return [];

  const modelMap: Record<string, Model> = {};

  csvData.forEach(row => {
    const { model_name, ticker, weight } = row;
    if (!requiredFields.every(field => field in row)) return;

    if (!modelMap[model_name]) {
      modelMap[model_name] = { name: model_name, holdings: [] };
    }

    const existingHolding = modelMap[model_name].holdings.find(
      holding => holding.ticker === ticker,
    );

    const weightNum = typeof weight === 'string' ? parseFloat(weight) : weight;
    if (!weightNum) return;

    if (existingHolding) {
      // If the ticker already exists, add the weight to the existing holding
      existingHolding.weight += weightNum;
    } else {
      // Otherwise, create a new holding
      modelMap[model_name].holdings.push({ ticker, weight: weightNum });
    }
  });

  // Convert the map to an array
  return Object.values(modelMap);
};
