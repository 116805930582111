/* eslint-disable react/prop-types */
import * as React from 'react';

function PriorityIcon(props: any): React.JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <g clipPath="url(#clip0_2443_13608)">
        <path
          fill={props.fill || '#fff'}
          d="M9.432 14.377a.379.379 0 01-.082.413l-1.1 1.1a.378.378 0 01-.535 0l-1.1-1.1a.379.379 0 010-.536l1.1-1.099a.379.379 0 01.536 0l1.099 1.1a.379.379 0 01.082.122zm.426-2.57a.562.562 0 010-.795l2.704-2.703a.46.46 0 000-.651L8.309 3.404a.46.46 0 00-.652 0L3.404 7.658a.46.46 0 000 .65l2.704 2.704a.563.563 0 010 .795l-.84.84a.561.561 0 01-.795 0L.135 8.309a.46.46 0 010-.651L7.657.135a.46.46 0 01.652 0l7.522 7.523a.46.46 0 010 .65l-4.338 4.339a.56.56 0 01-.795 0l-.84-.84zM9.35 7.616l-1.1-1.1a.379.379 0 00-.535 0l-1.1 1.1a.379.379 0 000 .535l1.1 1.1a.379.379 0 00.536 0l1.099-1.1a.379.379 0 000-.535z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_2443_13608">
          <path fill="#fff" d="M0 0H15.966V16H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

const MemoPriorityIcon = React.memo(PriorityIcon);
export default MemoPriorityIcon;
