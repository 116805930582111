import React from 'react';
import { Grid } from '@mui/material';
import { Button } from '@components/Button';
import Arrow from '@icons/Arrow';
import CustomTooltip from '@components/Tooltip';
import { ChatBoxContentWrapper, TextWrapper, ButtonContainer } from './styles';

function ChatContentOptions(props: any): React.JSX.Element {
  const { data, chatId, onOptionClick, buttonView } = props;
  if (!data)
    return (
      <ChatBoxContentWrapper>
        {`Sorry, I wasn't able to process this request.`}
      </ChatBoxContentWrapper>
    );
  return (
    <Grid container columnGap={2} rowGap={2}>
      {buttonView && buttonView === 'finsera' ? (
        <ButtonContainer container rowGap={2} columnGap={2}>
          {data.map((optionItem: any, optionIndex: number) => {
            return (
              <Grid item xs={3.8} key={`optionItem-${optionIndex}`}>
                <Button
                  variant={'outlined'}
                  disabled={!chatId}
                  style={{
                    textTransform: 'capitalize',
                    borderRadius: '8px',
                    border: '1px solid var(--neutral-200, #E4E7EC)',
                    background: '#FFF',
                    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.12)',
                  }}
                  onClick={() => onOptionClick(optionItem)}
                  width={'100%'}
                >
                  <CustomTooltip title={optionItem?.text}>
                    <Grid
                      item
                      container
                      justifyContent={'space-between'}
                      alignItems={'center'}
                    >
                      <Grid item>
                        <TextWrapper className="text">
                          {optionItem?.text}{' '}
                        </TextWrapper>
                      </Grid>
                      <Grid item mt={1}>
                        <Arrow />
                      </Grid>
                    </Grid>
                  </CustomTooltip>
                </Button>
              </Grid>
            );
          })}
        </ButtonContainer>
      ) : (
        <>
          {data.map((optionItem: any, optionIndex: number) => {
            return (
              <Grid key={`optionItem-${optionIndex}`}>
                <Button
                  variant={'outlined'}
                  disabled={!chatId}
                  style={{
                    textTransform: 'capitalize',
                  }}
                  onClick={() => onOptionClick(optionItem)}
                >
                  <Grid item>{optionItem?.text}</Grid>
                </Button>
              </Grid>
            );
          })}
        </>
      )}
    </Grid>
  );
}

export default React.memo(ChatContentOptions);
