import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { actions } from 'app/features/Chat/slice';
import SearchBox from 'app/components/SearchBox';
import { FooterContainer } from './styles';
import { useSelector } from 'react-redux';
import { getChatInitResponseState } from '@features/Chat/selector';
import { useRESTQuery } from '@hooks/useRESTQuery';
import { EGetStartedQueries } from 'app/enums';

function Footer(): React.JSX.Element {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { pathname } = location;

  const { data: questionsData, isLoading } = useRESTQuery(
    ['extract-questions'],
    {
      endpoint: `/enterprise/enterpriseQuestions`,
      method: 'GET',
      headers: {
        'X-Tifin-Ai-Auth': localStorage.getItem('x-tifin-ai-token'),
      },
    },
    {
      select: res => [
        {
          type: 'text',
          content:
            'Absolutely, which one of the following categories would you like to check out?.',
        },
        {
          type: 'sample_categories',
          content: [
            ...res.data
              .filter(category => category.question.length > 0)
              .map(question => {
                return {
                  head: question.category,
                  list: question.question,
                };
              })
              .filter(
                category => category.head !== EGetStartedQueries.CreateProposal,
              ),
          ],
        },
      ],
    },
  );

  useEffect(() => {
    dispatch(actions.setSampleQuestion({ sampleQuestions: questionsData }));
  }, [questionsData]);

  const { chatHistory: initHistoryState } = useSelector(
    getChatInitResponseState,
  );
  const [sageQuestionsClicked, setIsSageQuestionsClicked] = useState(0);
  const isChatStreaming = localStorage.getItem('streaming') as string;
  const [query, setQuery] = useState('');
  const onInputSubmit = () => {
    if (isChatStreaming === 'true') {
      dispatch(actions.setSearchQuery(query));
    } else {
      dispatch(actions.searchQueryRequest({ query }));
    }

    if (pathname !== '/conversations/stream') {
      navigate('/conversations/stream', { state: { scroll: 0 } });
    }
    setQuery('');
  };

  const handleQuestionMarkClick = () => {
    setIsSageQuestionsClicked(sageQuestionsClicked + 1);
  };

  useEffect(() => {
    if (sageQuestionsClicked > 0 && !isLoading && questionsData) {
      if (pathname !== '/conversations/stream') {
        navigate('/conversations/start');
      }
      dispatch(
        actions.loadChatSuccess({
          chatHistory: initHistoryState.concat([
            {
              data: 'Display Suggested Questions',
              isSender: true,
              time: '',
              id: '',
              type: 'text',
              isChatHistory: true,
              mock: true,
            },
            {
              id: '65b8f06d84d66bb8215d3173',
              isSender: false,
              time: '',
              isShowFeedback: false,
              isChatHistory: false,
              mock: true,
              data: questionsData,
            },
          ]),
        }),
      );
    }
  }, [sageQuestionsClicked, isLoading]);

  return (
    <FooterContainer
      container
      alignItems={'center'}
      justifyContent={'space-between'}
    >
      <SearchBox
        userQuery={query}
        isStreaming={false}
        setUserQuery={setQuery}
        onInputSubmit={onInputSubmit}
        handleQuestionMarkClick={handleQuestionMarkClick}
      />
    </FooterContainer>
  );
}

export default Footer;
