/* eslint-disable react/prop-types */

import React from 'react';

function KeyboardDownArrow(props: any): React.JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || '32'}
      height={props.width || '32'}
      fill="none"
      viewBox="0 0 32 32"
    >
      <rect width="32" height="32" x="0" fill="#F7F7F7" rx="4"></rect>
      <path
        stroke={props.stroke || '#282829'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 14l4 4 4-4"
        opacity={props.opacity || 1}
      ></path>
    </svg>
  );
}

const MemoKeyboardDownArrow = React.memo(KeyboardDownArrow);
export default MemoKeyboardDownArrow;
