import MemoDeleteIcon from '@icons/DeleteIcon';
import deleteGreySvg from '@images/deleteGrey.svg';
import editPenSvg from '@images/edit-pen.svg';
import { Grid } from '@mui/material';
import React, { useState } from 'react';
import { PrimaryButton } from '@components/Button';
import DoneIcon from '@mui/icons-material/Done';
import RemoveIcon from '@mui/icons-material/Remove';
import { useMutation, useQueryClient } from 'react-query';
import DialogModal from '@components/DialogModal';
import SuccessModal from '@components/SuccessModal';
import { CardWrapper, Text } from './styles';
import { deleteQuestions, publishQuestions } from './mutate';
import { useDispatch } from 'react-redux';
import { actions as globalActions } from 'app/features/Global/slice';
import { handleError } from '@common/error';
import QuestionsModal from './QuestionsModal';
import ConfirmationModal from './ConfirmationModal';
import MemoGreenTick from '@icons/GreenTick';

export interface Highlight {
  highlighted: string;
  page_number: number;
}
interface QuestionCardprops {
  title: string;
  description: string;
  questions: string[];
  id: string;
  documentId: string;
  status: string;
  allowPublish: boolean;
}

function QuestionCard({
  title,
  description,
  questions,
  allowPublish,
  id,
}: QuestionCardprops): React.JSX.Element {
  const dispatch = useDispatch();
  const { mutate: publishMutate, isLoading: publishLoading } =
    useMutation(publishQuestions);
  const { mutate: deleteMutate, isLoading: deleteLoading } =
    useMutation(deleteQuestions);
  const queryClient = useQueryClient();
  const [showEditModel, setShowEditModel] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showPublishModal, setShowPublishModal] = useState(false);
  const publishStatus = allowPublish ? 'publish' : 'unpublish';

  const handlePublish = () => {
    const payload = {
      id,
      action: publishStatus,
    };

    publishMutate(payload, {
      onSuccess: res => {
        handlePublishClose();
        queryClient.invalidateQueries('extract-questions');
        dispatch(
          globalActions.displayToast({
            duration: 3000,
            toastType: 'success',
            toastMessage: res?.message,
          }),
        );
      },
      onError: (err: any) => {
        handleError(dispatch, err);
      },
    });
  };

  const handleDelete = () => {
    const payload = {
      key: 'id',
      value: id,
    };

    deleteMutate(payload, {
      onSuccess: res => {
        handleDeleteClose();
        queryClient.invalidateQueries('extract-questions');
        dispatch(
          globalActions.displayToast({
            duration: 3000,
            toastType: 'success',
            toastMessage: res?.message,
          }),
        );
      },
      onError: (err: any) => {
        handleError(dispatch, err);
      },
    });
  };

  const getButtonTextAndIcon = allowPublish => {
    if (allowPublish) {
      return {
        text: 'Publish Question',
        icon: <DoneIcon />,
      };
    } else {
      return {
        text: 'Unpublish Question',
        icon: <RemoveIcon />,
      };
    }
  };

  const handleClose = () => {
    setShowEditModel(false);
  };

  const handleDeleteClose = () => {
    setShowDeleteModal(false);
  };

  const handlePublishClose = () => {
    setShowPublishModal(false);
  };

  return (
    <>
      <CardWrapper container rowGap={1}>
        <Grid item container xs={12} justifyContent={'space-between'}>
          <Grid item container alignItems={'center'}>
            <Grid item xs={4} md={4}>
              <p className="title">{title}</p>
            </Grid>
            <Grid
              item
              container
              xs={12}
              md={8}
              justifyContent={'flex-end'}
              alignItems={'center'}
              columnGap={2}
            >
              <img
                src={editPenSvg}
                onClick={() => setShowEditModel(true)}
                alt="Edit image"
                style={{ marginTop: '-6px', cursor: 'pointer' }}
              />
              <img
                src={deleteGreySvg}
                onClick={() => setShowDeleteModal(true)}
                alt="B&w delete image"
                style={{ marginTop: '-6px', cursor: 'pointer' }}
              />

              <Grid item>
                <PrimaryButton
                  color="primary"
                  className="button"
                  style={{ textTransform: 'none' }}
                  onClick={() => setShowPublishModal(true)}
                >
                  <Grid item container alignItems={'center'} columnGap={1}>
                    <Grid item className="icon">
                      {getButtonTextAndIcon(allowPublish).icon}
                    </Grid>
                    <Grid item>
                      <Text>{getButtonTextAndIcon(allowPublish).text}</Text>
                    </Grid>
                  </Grid>
                </PrimaryButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container direction={'column'} rowGap={2}>
          <Grid item container columnGap={2} rowGap={2}>
            {questions.map((item, index) => (
              <Grid key={index} item className="question-wrapper">
                <p className="question">{item}</p>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </CardWrapper>

      {showSuccessModal && (
        <SuccessModal
          isOpen={showSuccessModal}
          cta={'Continue'}
          message={'Question Updated Successfully!'}
          ctaMessage={''}
          handleCtaClick={() => setShowSuccessModal(false)}
          handleClose={() => setShowSuccessModal(false)}
        />
      )}

      {showDeleteModal && (
        <DialogModal
          openModal={showDeleteModal}
          handleClose={handleDeleteClose}
          // title={`${()}`}
        >
          <ConfirmationModal
            icon={<MemoDeleteIcon fontSize={20} style={{ marginTop: '5px' }} />}
            headerText={'Delete Question'}
            title={`You are about to permanently delete ${title} question for all your advisors. Are you sure you want to continue?`}
            subTitle={
              'Note - Once you delete this question it will not be visible to any of your advisors.'
            }
            handleClose={handleDeleteClose}
            handleSubmit={handleDelete}
            isLoading={deleteLoading}
          />
        </DialogModal>
      )}

      {showPublishModal && (
        <DialogModal
          openModal={showPublishModal}
          handleClose={handlePublishClose}
        >
          <ConfirmationModal
            icon={<MemoGreenTick fontSize={20} style={{ marginTop: '5px' }} />}
            headerText={`Confirm ${allowPublish ? 'Publish' : 'Unpublish'}`}
            title={`Are you sure you want to ${publishStatus} ${description} question for all your advisors?`}
            subTitle={`Note - Once you ${allowPublish ? 'publish' : 'unpublish'} this question will be ${allowPublish ? 'visible' : 'invisible'} to all your advisors.`}
            handleClose={handlePublishClose}
            handleSubmit={handlePublish}
            isLoading={publishLoading}
          />
        </DialogModal>
      )}

      {showEditModel && (
        <DialogModal
          openModal={showEditModel}
          handleClose={handleClose}
          title="Update Question"
          showTitle
        >
          <QuestionsModal
            description={description}
            questions={questions}
            handleClose={handleClose}
            id={id}
            setShowSuccessModal={setShowSuccessModal}
          />
        </DialogModal>
      )}
    </>
  );
}

export default QuestionCard;
