export const keyStatsPeriodOptions = [
  { label: '1 Week', value: 'W1' },
  { label: '1 Month', value: 'M1' },
  { label: '3 Months', value: 'M3' },
  { label: 'Year to Date', value: 'YTD' },
  { label: '1 Year', value: 'Y1' },
  { label: '3 Years', value: 'Y3' },
  { label: '5 Years', value: 'Y5' },
  { label: '10 Years', value: 'Y10' },
];

export interface StockDetailsProps {
  selectedTicker: string;
  setSelectedTicker: any;
  setStepper?: React.Dispatch<
    React.SetStateAction<'CLIENT_DRAWER_PAGE' | 'PORTFOLIO_DETAILS_PAGE'>
  >;
  stepper?: 'CLIENT_DRAWER_PAGE' | 'PORTFOLIO_DETAILS_PAGE';
  setSelectedTab?: React.Dispatch<React.SetStateAction<1 | 2 | 3>>;
  setAdjustmentStepper?: React.Dispatch<React.SetStateAction<string>>;
  portfolioName?: string;
  showStepper: boolean;
  onClose?: any;
}

export interface KeyStatsData {
  category: string;
  data: {
    title: string;
    subItems?: {
      title: string;
      value: string;
      compareVal: string;
      graph: string;
    }[];
  }[];
  from: string;
  to: string;
}

export interface ChartData {
  Base?: string | undefined;
  D?: string | undefined;
  P?: number | undefined;
  Data?: Array<{ D: string; P: number }> | undefined;
}
