import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ErrorBoundary from '@helper/ErrorBoundary';
// import LayoutBackdrop from '@components/LayoutBackdrop';
import Footer from './Footer';
import SideNav from './SideNav';
import { LayoutProps } from './types';
import {
  LayoutContainer,
  LayoutWrapper,
  ContentWrapper,
  FooterContainer,
  UploadButtonContainer,
  SplashOVerlay,
  TermsConditionSideContainer,
  BottomSideContainer,
  TopSideContainer,
} from './styles';
import ChatOverlay from '@components/ChatOverlay';
import { Button, useMediaQuery } from '@mui/material';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import UploadModal from './UploadModal';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { StyledApplyButton } from '@pages/CIOViewpoints/styles';
import AddIcon from '@mui/icons-material/Add';
import AddAdvisorModal from '@pages/FirmAdmin/AddAdvisorModal';
import AdvisorUploadSuccessModal from '@pages/FirmAdmin/AdvisorUploadSuccessModal';
import SplashScreen from '@pages/SplashScreenV2';
// import LogoutSplashOverlay from '@pages/SplashScreenV2/LazyComponent';
import DialogModal from '@components/DialogModal';
import CurrentEventModal from '@components/CurrentEventsModal';
import theme from '@styles/theme';

const LayoutRoute: React.FC<LayoutProps> = ({
  isLoggedIn,
  children,
  animate = false,
  navState = '',
}) => {
  const location = useLocation();
  const isDesktopDevice = useMediaQuery(theme.breakpoints.up('md'));

  const { pathname } = location;
  const [collapse, updateCollapseState] = useState(
    isDesktopDevice ? false : true,
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentEventModal, setCurrentEventsModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('Last 90 Days');
  const [selectedPriorityOption, setSelectedPriorityOption] =
    useState('Most Recent First');
  const [fullHeight, updateHeight] = useState<boolean>(
    localStorage.getItem('onboarding-state') === '1',
  );
  const onBoarding: string | null = localStorage.getItem('onboarding-state');
  useEffect(() => {
    console.log('useEffect is triggered');
    console.log('onboarding-state:', onBoarding);
    if (onBoarding === '1') {
      console.log('Setting fullHeight to true');
      updateHeight(true);
    }
  }, [onBoarding]);
  const splashScreenCallback = () => {
    console.log(
      'Before updating fullHeight to false. Current value:',
      fullHeight,
    );
    localStorage.setItem('onboarding-state', '0');
    updateHeight(false);
    console.log(
      'After updating fullHeight to false. Current value:',
      fullHeight,
    );
  };

  const handleOptionChange = event => {
    setSelectedOption(event.target.value);
  };

  const handlePriorityOptionChange = event => {
    setSelectedPriorityOption(event.target.value);
  };

  const handleAddAdvisor = () => {
    setOpenModal(true);
  };

  const handleCurrentEvents = () => {
    setCurrentEventsModal(true);
  };

  const closeCurrentEvents = () => {
    setCurrentEventsModal(false);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleSuccessModalClose = () => {
    setSuccessModalOpen(false);
  };

  const handleUploadButtonClick = () => {
    console.log('upload click');
    if (!isModalOpen) {
      setIsModalOpen(true);
    }
  };

  const onArrowClick = () => {
    updateCollapseState(!collapse);
  };
  console.log('path name--->', pathname);
  return (
    <>
      <LayoutContainer container>
        {onBoarding === '0' && (
          <>
            {pathname !== '/terms-condition' && (
              <TopSideContainer
                collapse={collapse}
                isDesktopDevice={isDesktopDevice}
              >
                <SideNav collapse={collapse} onArrowClick={onArrowClick} />
              </TopSideContainer>
            )}
            <>
              {pathname === '/terms-condition' ? (
                <TermsConditionSideContainer collapse={collapse}>
                  <ErrorBoundary key={pathname}>
                    <ContentWrapper
                      animate={animate}
                      navstate={navState}
                      isDesktopDevice={isDesktopDevice}
                    >
                      <LayoutWrapper animate={animate}>
                        {children}
                      </LayoutWrapper>
                    </ContentWrapper>
                  </ErrorBoundary>
                </TermsConditionSideContainer>
              ) : (
                <BottomSideContainer
                  sx={{
                    display: 'flex',
                    alignContent: 'space-around',
                    flexDirection: 'column',
                  }}
                >
                  {pathname !== '/terms-condition' && (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        position: 'relative',
                        overflow: 'hidden',
                        opacity: isLoggedIn ? 1 : 0,
                      }}
                    >
                      <div>
                        {pathname === '/view-points' && (
                          <UploadButtonContainer>
                            <FormControl sx={{ m: 1, minWidth: 180 }}>
                              <Select
                                value={selectedPriorityOption}
                                onChange={handlePriorityOptionChange}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                                sx={{
                                  height: '30px',
                                  fontSize: '13px',
                                  '& .MuiSelect-icon': {
                                    minHeight: '27px',
                                  },
                                }}
                              >
                                <MenuItem
                                  value="Most Recent First"
                                  sx={{ fontSize: '13px' }}
                                >
                                  Most Recent First
                                </MenuItem>
                                <MenuItem
                                  value="Oldest First"
                                  sx={{ fontSize: '13px' }}
                                >
                                  Oldest First
                                </MenuItem>
                              </Select>
                            </FormControl>
                            <FormControl sx={{ m: 1, minWidth: 150 }}>
                              <Select
                                value={selectedOption}
                                onChange={handleOptionChange}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                                sx={{
                                  height: '30px',
                                  fontSize: '13px',
                                  '& .MuiSelect-icon': {
                                    minHeight: '27px',
                                  },
                                }}
                              >
                                <MenuItem
                                  value="Last 90 Days"
                                  sx={{ fontSize: '13px' }}
                                >
                                  Last 90 Days
                                </MenuItem>
                                <MenuItem
                                  value="Last 60 Days"
                                  sx={{ fontSize: '13px' }}
                                >
                                  Last 60 Days
                                </MenuItem>
                                <MenuItem
                                  value="Last 30 Days"
                                  sx={{ fontSize: '13px' }}
                                >
                                  Last 30 Days
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </UploadButtonContainer>
                        )}
                        {pathname === '/viewpoints_dashboard' && (
                          <UploadButtonContainer>
                            {/* FIX: Commented unused dropdown */}
                            {/* <FormControl sx={{ m: 1, minWidth: 150 }}>
                                <Select
                                  value={selectedOption}
                                  onChange={handleOptionChange}
                                  displayEmpty
                                  inputProps={{ 'aria-label': 'Without label' }}
                                  sx={{
                                    height: '30px',
                                    fontSize: '13px',
                                    '& .MuiSelect-icon': {
                                      minHeight: '27px',
                                    },
                                  }}
                                >
                                  <MenuItem
                                    value="Last 90 Days"
                                    sx={{ fontSize: '13px' }}
                                  >
                                    Last 90 Days
                                  </MenuItem>
                                  <MenuItem
                                    value="Last 60 Days"
                                    sx={{ fontSize: '13px' }}
                                  >
                                    Last 60 Days
                                  </MenuItem>
                                  <MenuItem
                                    value="Last 30 Days"
                                    sx={{ fontSize: '13px' }}
                                  >
                                    Last 30 Days
                                  </MenuItem>
                                </Select>
                              </FormControl> */}
                            <Button
                              variant="contained"
                              color="primary"
                              style={{
                                width: '100%',
                                background:
                                  'linear-gradient(180deg, #222124 23.75%, #323232 120%)',
                                color: '#fff',
                                borderRadius: '20px',
                                textTransform: 'none',
                                height: '38px',
                              }}
                              onClick={handleUploadButtonClick}
                            >
                              <FileUploadOutlinedIcon
                                style={{
                                  height: '20px',
                                  width: '20px',
                                  alignItems: 'center',
                                  marginRight: '3px',
                                }}
                              />
                              Upload Viewpoint
                            </Button>
                          </UploadButtonContainer>
                        )}
                        {pathname === '/manage-firms' && (
                          <UploadButtonContainer>
                            <StyledApplyButton
                              variant="outlined"
                              style={{
                                width: '100%',
                                height: '40px',
                                fontSize: '13px',
                              }}
                              onClick={handleAddAdvisor}
                            >
                              <AddIcon
                                sx={{
                                  width: '25px !important',
                                  height: '25px !important',
                                }}
                              />
                              Add Advisor
                            </StyledApplyButton>
                          </UploadButtonContainer>
                        )}
                        {pathname === '/current-events' && (
                          <UploadButtonContainer>
                            <Button
                              variant="contained"
                              color="primary"
                              style={{
                                width: '100%',
                                background:
                                  'linear-gradient(180deg, #222124 23.75%, #323232 120%)',
                                color: '#fff',
                                borderRadius: '20px',
                                textTransform: 'none',
                                height: '38px',
                              }}
                              onClick={handleCurrentEvents}
                            >
                              <AddIcon
                                sx={{
                                  width: '25px !important',
                                  height: '25px !important',
                                }}
                              />
                              Add
                            </Button>
                          </UploadButtonContainer>
                        )}

                        {/* Modal */}
                        <AddAdvisorModal
                          isOpen={openModal}
                          onClose={handleCloseModal}
                        />
                        {/* Success Modal */}
                        <AdvisorUploadSuccessModal
                          isOpen={successModalOpen}
                          onClose={handleSuccessModalClose}
                        />
                      </div>
                    </div>
                  )}
                  <UploadModal
                    isModalOpen={isModalOpen}
                    setIsModalOpen={setIsModalOpen}
                  />

                  <ErrorBoundary key={pathname}>
                    <ContentWrapper
                      animate={animate}
                      navstate={navState}
                      isDesktopDevice={isDesktopDevice}
                      height={'92vh'}
                    >
                      <LayoutWrapper
                        animate={animate}
                        sx={{
                          alignItems: 'flex-start',
                          display: 'flex',
                          justifyContent: 'center',
                          padding:
                            pathname === '/conversations' ||
                            pathname === '/conversations/start' ||
                            pathname === '/conversations/stream' ||
                            pathname === '/insights'
                              ? 0
                              : 3,
                        }}
                      >
                        {children}
                      </LayoutWrapper>
                    </ContentWrapper>
                  </ErrorBoundary>

                  {pathname === '/conversations' ||
                  pathname === '/conversations/start' ||
                  pathname === '/insights' ? (
                    <ChatOverlay />
                  ) : null}
                </BottomSideContainer>
              )}
              {pathname === '/conversations' ||
              pathname === '/conversations/start' ||
              pathname === '/conversations/stream' ||
              pathname === '/insights' ? (
                <FooterContainer
                  isDesktopDevice={isDesktopDevice}
                  sx={{
                    position: 'fixed',
                    left: 0,
                    bottom: 0,
                    height: '100px',
                    zIndex: 2,
                  }}
                >
                  <LayoutWrapper
                    container
                    animate={animate}
                    alignItems={'center'}
                    justifyContent={'center'}
                  >
                    <Footer />
                  </LayoutWrapper>
                </FooterContainer>
              ) : null}
            </>
          </>
        )}
        {onBoarding === '1' && (
          <SplashOVerlay
            style={{
              width: '100%',
              position: 'absolute',
              opacity: fullHeight ? 1 : 0,
              zIndex: fullHeight ? 99 : -1,
            }}
          >
            <SplashScreen callBack={splashScreenCallback} />
          </SplashOVerlay>
        )}
        {/* <LayoutBackdrop />
        <LogoutSplashOverlay /> */}
      </LayoutContainer>

      <DialogModal
        openModal={currentEventModal}
        handleClose={closeCurrentEvents}
        title="Add Current Events"
        showTitle
      >
        <CurrentEventModal handleClose={closeCurrentEvents} />
      </DialogModal>
    </>
  );
};

export default LayoutRoute;
