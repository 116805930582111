import React from 'react';
// import PeopleGroupIcon from '@images/black-people.svg';
// import DollarIcon from '@images/black-dollar.svg';
import {
  Box,
  // Divider,
  Grid,
  Typography,
  useMediaQuery,
} from '@mui/material';
// import EyeIcon from '@images/black-eye.svg';
import {
  ClientOverviewContainer,
  TotalCard,
  // FirstBlockText,
  // RecentlyViewedIconContainer,
  // RecentlyViewedTextContainer,
  RecentlyViewedUserContainer,
  TotalCardDetails,
  // SecondBlockIconContainer,
  // StyledIconContainer,
} from './styles';
import { price } from '@common/number';
import ClientUserIcon from '@images/client-user.svg';
import ClientDollarIcon from '@images/dollar.svg';
import theme from '@styles/theme';

interface ClientOverviewProps {
  handleDrawerOpen: any;
  clientSummary: any;
}

function ClientOverview({
  handleDrawerOpen,
  clientSummary,
}: ClientOverviewProps): React.JSX.Element {
  const isDesktopDevice = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <ClientOverviewContainer container>
      <TotalCard item xs={12} sm={6} lg={2.5}>
        <Box component={'figure'} p={0} m={0} display={'flex'}>
          <img src={ClientUserIcon} alt="Client user image" />
        </Box>
        <TotalCardDetails>
          <Typography
            sx={{
              fontSize: '12px',
              fontWeight: 500,
              color: '#96969B',
              lineHeight: '16px',
              textWrap: 'nowrap',
            }}
          >
            Total Clients
          </Typography>
          <Typography
            sx={{
              fontSize: '20px',
              fontWeight: 600,
              lineHeight: '24px',
              color: '#353536',
            }}
          >
            {clientSummary?.summary?.noOfClients}
          </Typography>
        </TotalCardDetails>
      </TotalCard>
      <TotalCard item xs={12} sm={6} lg={2.5}>
        <Box component={'figure'} p={0} m={0} display={'flex'}>
          <img src={ClientDollarIcon} alt="Client dollar image" />
        </Box>
        <TotalCardDetails>
          <Typography
            sx={{
              fontSize: '12px',
              fontWeight: 500,
              color: '#96969B',
              lineHeight: '16px',
            }}
          >
            Total AUM
          </Typography>
          <Typography
            sx={{
              fontSize: '20px',
              fontWeight: 600,
              lineHeight: '24px',
              color: '#353536',
            }}
          >
            {price(clientSummary?.summary?.totalAum, true, true, true)}{' '}
          </Typography>
        </TotalCardDetails>
      </TotalCard>
      {isDesktopDevice && clientSummary?.summary?.recentlyViewed && (
        <Grid item xs={12} lg={7} className="basic_info" p={'1rem'}>
          <Typography
            sx={{
              color: '#96969B',
              fontSize: '12px',
              fontWeight: 500,
              lineHeight: '16px',
              mb: '4px',
            }}
          >
            Recently Viewed
          </Typography>
          <Grid item container xs={12} columnGap={1} rowGap={1}>
            {clientSummary?.summary?.recentlyViewed
              ?.filter(
                (item, index, array) =>
                  array.findIndex(i => i.name === item.name) === index,
              )
              .map((item, index) => {
                return (
                  <RecentlyViewedUserContainer
                    item
                    container
                    width={'fit-content'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    key={index}
                    onClick={() =>
                      handleDrawerOpen(item?.id, null, null, item.name)
                    }
                    tabIndex={0}
                    onKeyDown={event => {
                      if (event.key === 'Enter') {
                        event.preventDefault();
                        handleDrawerOpen(item?.id, null, null, item.name);
                      }
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '12px',
                        color: '#101828',
                        fontWeight: 500,
                        lineHeight: '16px',
                      }}
                    >
                      {item.name}
                    </Typography>
                  </RecentlyViewedUserContainer>
                );
              })}
          </Grid>
        </Grid>
      )}
    </ClientOverviewContainer>
  );
}

export default ClientOverview;
