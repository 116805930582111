import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Divider,
} from '@mui/material';
import { styled } from '@mui/system';
import {
  FooterButtonWrapper,
  StyledApplyButton,
  StyledButton,
} from '@pages/CIOViewpoints/styles';
import { useDispatch } from 'react-redux';
import { actions as globalActions } from 'app/features/Global/slice';
import { ClientData, ITickerWeights, PortfolioData } from './types';
import PortfolioForm from './PortfolioForm';
import ClientForm from './ClientForm';

interface AddClientModalProps {
  open: boolean;
  onClose: () => void;
  step?: number;
  refreshData: boolean;
  onContinue: (data: {
    firstName: string;
    lastName: string;
    email: string;
  }) => void;
  onSubmit: (data: {
    portfolioData: PortfolioData;
    tickerWeights: ITickerWeights[];
  }) => void;
  headerText?: string;
}

const CenteredDialogActions = styled(DialogActions)({
  justifyContent: 'center',
  paddingBottom: '20px',
});

const AddClientModal: React.FC<AddClientModalProps> = ({
  open,
  onClose,
  onContinue,
  step,
  refreshData,
  onSubmit,
  headerText,
}) => {
  const [clientData, setClientData] = useState<ClientData>({
    firstName: '',
    lastName: '',
    email: '',
  });

  const [portfolioData, setPortfolioData] = useState<PortfolioData>({
    accName: '',
    accNumber: '',
  });
  const [tickerWeights, setTickerWeights] = useState<ITickerWeights[]>([]);
  const dispatch = useDispatch();

  const { firstName, lastName, email } = clientData;
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

  const isStep1Valid =
    firstName.trim() !== '' &&
    lastName.trim() !== '' &&
    email.trim() !== '' &&
    emailRegex.test(email.trim());

  const tickerWeightsValidation = tickerWeights.every(
    tw => tw?.ticker?.trim() !== '' && !isNaN(tw?.weight) && tw?.weight > 0,
  );
  const isStep2Valid = tickerWeights.length > 0 && tickerWeightsValidation;

  useEffect(() => {
    if (refreshData == true) {
      setClientData({
        firstName: '',
        lastName: '',
        email: '',
      });
      setPortfolioData({
        accName: '',
        accNumber: '',
      });
      setTickerWeights([]);
    }
  }, [refreshData]);

  const handleClientDataChange = (fieldName, value) => {
    setClientData({
      ...clientData,
      [fieldName]: value,
    });
  };
  const handlePortfolioDataChange = (fieldName, value) => {
    setPortfolioData({
      ...portfolioData,
      [fieldName]: value,
    });
  };

  const handleContinue = (e: React.FormEvent) => {
    e.preventDefault();
    if (!firstName.trim() || !lastName.trim() || !email.trim()) {
      dispatch(
        globalActions.displayToast({
          duration: 3000,
          toastType: 'error',
          toastMessage: 'Please fill all the mandatory fields.',
        }),
      );
      return;
    }
    if (!emailRegex.test(email)) {
      dispatch(
        globalActions.displayToast({
          duration: 3000,
          toastType: 'error',
          toastMessage: 'Invalid email. Please enter a valid email.',
        }),
      );
      return;
    }
    onContinue({ firstName, lastName, email });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!tickerWeightsValidation) {
      dispatch(
        globalActions.displayToast({
          duration: 3000,
          toastType: 'error',
          toastMessage: 'Please fill all the mandatory fields.',
        }),
      );
      return;
    }
    onSubmit({ portfolioData, tickerWeights });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      PaperProps={{
        sx: {
          background: '#fff',
          width: '100%',
          maxWidth: {
            xs: '100%',
            sm: '50%',
          },
        },
      }}
    >
      <DialogTitle>{headerText}</DialogTitle>
      <Grid
        container
        padding={'16px 16px 0px'}
        sx={{ background: '#f7f7f7', flexWrap: 'nowrap' }}
      >
        <Grid
          item
          container
          xs={12}
          sm={6}
          sx={{
            display: 'flex',
            padding: '16px 6px 16px 16px',
            alignItems: 'flex-start',
            alignSelf: 'stretch',
            direction: 'column',
            background: '#fff',
            gap: '12px',
          }}
        >
          {step === 1 && (
            <Grid
              item
              xs={12}
              sx={{ border: '3px solid #101829', borderRadius: '4px' }}
            ></Grid>
          )}
          {step === 2 && (
            <Grid
              item
              xs={12}
              sx={{ border: '3px solid #ececec', borderRadius: '4px' }}
            ></Grid>
          )}

          <Grid item xs={12}>
            <p
              style={{
                fontSize: '12px',
                color: '#282829',
                fontWeight: 500,
              }}
            >
              {' '}
              {`1. ${headerText === 'Add New Client' ? 'Client' : 'Prospect'} Details`}
            </p>
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={12}
          sm={6}
          sx={{
            display: 'flex',
            padding: '16px 16px 16px 6px',
            alignItems: 'flex-start',
            background: '#fff',
            gap: '12px',
            alignSelf: 'stretch',
            direction: 'column',
          }}
        >
          {step === 1 && (
            <Grid
              item
              xs={12}
              sx={{ border: '3px solid #ececec', borderRadius: '4px' }}
            ></Grid>
          )}
          {step === 2 && (
            <Grid
              item
              xs={12}
              sx={{ border: '3px solid #282829', borderRadius: '4px' }}
            ></Grid>
          )}{' '}
          <Grid item xs={12}>
            <p
              style={{
                fontSize: '12px',
                color: '#282829',
                fontWeight: 500,
              }}
            >
              {' '}
              2. Portfolio Details
            </p>
          </Grid>{' '}
        </Grid>
      </Grid>
      <form onSubmit={handleSubmit}>
        <DialogContent
          sx={{
            background: '#f7f7f7',
            padding: '16px',
            borderRadius: '4px',
          }}
        >
          {step === 1 && (
            <ClientForm
              clientData={clientData}
              setClientData={handleClientDataChange}
            />
          )}
          {step === 2 && (
            <PortfolioForm
              refreshData={refreshData}
              portfolioData={portfolioData}
              setPortfolioData={handlePortfolioDataChange}
              tickerWeights={tickerWeights}
              setTickerWeights={setTickerWeights}
            />
          )}
        </DialogContent>

        <Grid mt={1}>
          <Divider style={{ margin: '0px 0px 10px 10px' }} />
        </Grid>
        <CenteredDialogActions>
          <FooterButtonWrapper
            container
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <StyledButton variant="outlined" onClick={onClose}>
              {step === 1 ? 'Cancel' : 'Skip'}
            </StyledButton>
            {step === 1 && (
              <StyledApplyButton
                variant="outlined"
                disabled={!isStep1Valid}
                onClick={handleContinue}
              >
                Continue
              </StyledApplyButton>
            )}
            {step === 2 && (
              <StyledApplyButton
                type="submit"
                variant="outlined"
                disabled={!isStep2Valid}
                onClick={() => console.log('clicked')}
              >
                Submit
              </StyledApplyButton>
            )}
          </FooterButtonWrapper>
        </CenteredDialogActions>
      </form>
    </Dialog>
  );
};

export default AddClientModal;
