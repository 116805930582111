import React from 'react';
import { Spinner } from '@pages/IntegrationPage/styles';
import { styled, Typography } from '@mui/material';

export const ClientsLoaderContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  height: 70vh;
  margin: 0 auto;
`;

function Loader({ text }: { text?: string }): React.JSX.Element {
  return (
    <ClientsLoaderContainer>
      <Spinner />
      {text && <Typography>{text}</Typography>}
    </ClientsLoaderContainer>
  );
}

export default Loader;
