import React from 'react';
import { Grid, Modal, Typography } from '@mui/material';
import SuccessIcon from '@icons/Success';
import { StyledApplyButton } from '@pages/CIOViewpoints/styles';
import { SuccessModalContainer, SuccessMsgWrapper } from './styles';

interface EnterpriseSuccessModalProps {
  isOpen: boolean;
  onClose: () => void;
}

function EnterpriseSuccessModal({
  isOpen,
  onClose,
}: EnterpriseSuccessModalProps): React.JSX.Element {
  return (
    <Modal open={isOpen} onClose={onClose}>
      <SuccessModalContainer
        container
        xs={3.5}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Typography
          variant="h6"
          component="div"
          style={{ textAlign: 'center' }}
        >
          Enterprise Created Successfully!
        </Typography>
        <Grid
          item
          container
          xs={12}
          justifyContent={'center'}
          alignItems={'center'}
          mt={2.5}
        >
          <SuccessIcon />
        </Grid>
        <SuccessMsgWrapper mt={2.5}>
          Enterprise created successfully, you can now add firms and assign
          administrators to each firm.
        </SuccessMsgWrapper>
        <Grid item container mt={2.5} justifyContent={'center'}>
          <StyledApplyButton variant="outlined" onClick={onClose}>
            Continue
          </StyledApplyButton>
        </Grid>
      </SuccessModalContainer>
    </Modal>
  );
}

export default EnterpriseSuccessModal;
