import { Grid } from '@mui/material';
import React from 'react';
import { CardWrapper } from '../styles';
import MemoWarning from '@icons/Warning';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { price } from '@common/number';
import MemoAnimator from '@components/UIComponents/MemoAnimator';

interface ClientCardProps {
  clientName: string;
  value: number;
  title: string;
  dropdownValue: string;
}

function ClientCard({
  clientName,
  value,
  title,
  dropdownValue,
}: ClientCardProps): React.JSX.Element {
  return (
    <CardWrapper
      item
      container
      xs={12}
      direction={'column'}
      justifyContent={'space-between'}
    >
      <Grid item container columnGap={0.5} alignItems={'center'}>
        {title && (
          <Grid item xs={1} display={'flex'} alignItems={'center'}>
            <MemoWarning />
          </Grid>
        )}
        <Grid item>
          <p className="text">{title}</p>
        </Grid>
      </Grid>
      <Grid item container direction={'column'} rowGap={2}>
        <Grid item>
          <p className="name">{clientName}</p>
        </Grid>
        <Grid item>
          <p className="price">{price(value)}</p>
        </Grid>
        <Grid item container columnGap={1}>
          <MemoAnimator
            text={
              <p
                style={{
                  fontSize: '14px',
                  marginLeft: '-3px',
                }}
              >
                {dropdownValue}
              </p>
            }
          />

          <Grid
            item
            container
            xs={1.5}
            className="circle-border"
            justifyContent={'center'}
            height={'26px'}
            alignItems={'center'}
          >
            <KeyboardArrowDownIcon />
          </Grid>
        </Grid>
      </Grid>
    </CardWrapper>
  );
}

export default ClientCard;
