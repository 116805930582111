import { useEffect, useCallback } from 'react';

export function useIntersectionObserver(
  ref: React.RefObject<Element>,
  callback: () => void,
  dependencies: any[] = [],
  threshold = 0.1,
): void {
  const observerCallback = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      if (entries[0].isIntersecting) {
        callback();
      }
    },
    [callback],
  );

  useEffect(() => {
    const observer = new IntersectionObserver(observerCallback, {
      threshold,
    });

    const currentRef = ref.current;
    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
      observer.disconnect();
    };
  }, [ref, observerCallback, ...dependencies]);
}
