/* eslint-disable react/prop-types */
import * as React from 'react';

function HubspotIcon(props: any): React.JSX.Element {
  const paddingTop = '8px';
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="90"
      height="29"
      viewBox="0 0 99 29"
      style={{ paddingTop }}
    >
      <g>
        <path
          fill={props.fill || '#45535E'}
          d="M.012 5.395h3.453v7.714h7.277V5.395H14.2v18.933h-3.457v-7.875H3.465v7.875H.012zM25.137 18.16a2.872 2.872 0 01-2.86 2.875 2.87 2.87 0 01-2.859-2.875v-8.152h-3.273v8.152c0 3.402 2.75 6.168 6.132 6.168 3.38 0 6.13-2.765 6.13-6.168v-8.152h-3.27v8.152m12.41-8.055c-1.625 0-2.758.473-3.852 1.555V5.395H30.41v11.613c0 4.344 3.125 7.32 6.633 7.32 3.902 0 7.324-3.027 7.324-7.11 0-4.03-3.152-7.113-6.82-7.113zm.082 11.168c-2.227 0-3.93-1.87-3.93-4.054 0-2.188 1.703-4.059 3.93-4.059 1.883 0 3.586 1.871 3.586 4.059 0 2.183-1.703 4.054-3.586 4.054zm12.254-10.335c0-1.66 1.097-2.188 2.304-2.188.97 0 2.25.738 3.09 1.633l2.145-2.528c-1.074-1.449-3.246-2.453-5.027-2.453-3.563 0-6.13 2.082-6.13 5.535 0 6.399 7.833 4.372 7.833 7.954 0 1.105-1.075 2.082-2.305 2.082-1.938 0-2.566-.95-3.457-1.95L45.953 21.5c1.52 1.871 3.406 2.82 5.656 2.82 3.38 0 6.102-2.11 6.102-5.402 0-7.113-7.828-4.898-7.828-7.98m16.476-1.02c-3.511 0-6.636 2.977-6.636 7.324v11.645h3.285V22.59c1.094 1.078 2.226 1.555 3.851 1.555 3.668 0 6.82-3.082 6.82-7.114 0-4.082-3.421-7.113-7.32-7.113zm.582 11.172c-2.226 0-3.925-1.875-3.925-4.059 0-2.187 1.699-4.058 3.925-4.058 1.887 0 3.59 1.87 3.59 4.058 0 2.184-1.703 4.059-3.59 4.059zm30.91.183c-1.933 0-2.484-.843-2.484-2.132v-5.72h3.012v-2.894h-3.012v-3.82l-3.324 1.5v11.645c0 2.976 2.039 4.476 4.84 4.476.422 0 .996-.027 1.312-.105l.813-3c-.367.023-.785.05-1.156.05"
        ></path>
        <path
          fill={props.fill || '#F8761F'}
          d="M89.191 13.594c-.629-1.09-1.515-1.965-2.62-2.621a7.049 7.049 0 00-2.653-.926V6.586a2.562 2.562 0 001.566-2.395c0-1.453-1.16-2.632-2.601-2.632a2.632 2.632 0 00-2.625 2.632c0 1.067.566 1.98 1.531 2.395v3.46a7.471 7.471 0 00-2.305.731 7938.25 7938.25 0 00-9.273-7.082c.07-.25.121-.504.121-.77 0-1.612-1.297-2.917-2.894-2.917a2.907 2.907 0 00-2.899 2.918 2.906 2.906 0 002.898 2.914c.547 0 1.051-.16 1.489-.426l.605.461 8.32 6.031a7.06 7.06 0 00-1.18 1.387c-.663 1.059-1.066 2.223-1.066 3.492v.262c0 .89.168 1.734.454 2.523a6.68 6.68 0 001.078 1.883l-2.762 2.785a2.217 2.217 0 00-2.352.52c-.421.422-.66.996-.656 1.594 0 .601.235 1.164.656 1.59.418.425.989.663 1.582.66a2.212 2.212 0 001.586-.66 2.243 2.243 0 00.551-2.266l2.856-2.875c.39.273.812.504 1.27.699.894.39 1.882.629 2.968.629h.195c1.2 0 2.328-.285 3.387-.863a6.988 6.988 0 002.656-2.512c.664-1.07 1.031-2.254 1.031-3.555v-.066c0-1.278-.293-2.461-.914-3.54zm-3.488 6.023c-.773.867-1.664 1.399-2.672 1.399h-.164c-.574 0-1.137-.16-1.687-.45-.621-.32-1.094-.777-1.48-1.355-.4-.566-.614-1.188-.614-1.844v-.199c0-.648.121-1.262.434-1.84a3.979 3.979 0 011.375-1.515 3.367 3.367 0 011.906-.579h.066c.63 0 1.23.125 1.793.418.574.309 1.043.73 1.41 1.29.352.554.563 1.156.63 1.808.01.137.015.277.015.41 0 .887-.336 1.711-1.012 2.457zm0 0"
        ></path>
      </g>
    </svg>
  );
}

const MemoNoData = React.memo(HubspotIcon);
export default MemoNoData;
