/* eslint-disable react/prop-types */

import React from 'react';

function WhitePlus(): React.JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="19"
      fill="none"
      viewBox="0 0 18 19"
    >
      <g stroke="#fff" strokeWidth="2">
        <path
          strokeMiterlimit="10"
          d="M9 16.25a6.75 6.75 0 100-13.5 6.75 6.75 0 000 13.5z"
        ></path>
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M6.188 9.5h5.625"
        ></path>
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M9 6.688v5.625"
        ></path>
      </g>
    </svg>
  );
}

const MemoNoData = React.memo(WhitePlus);
export default MemoNoData;
