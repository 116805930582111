import React, { cloneElement, useEffect, useState } from 'react';
import { price } from '@common/number';
import { makeStyles } from '@mui/styles';
import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
} from '@mui/material';
import {
  SearchBoxInput,
  StyledDataCell,
  StyledTableCell,
  StyledTableRow,
} from '@pages/SuperAdminPage/styles';
import CustomPagination from '@components/Pagination';
import { StyledTypography } from '@pages/EnterpriseAdmin/styles';
import Loader from '@components/Loader';

import Impersonate from '@components/impersonate';
import InviteStatus from '@components/IntiveStatus/inviteStatus';
import { getAuthDetails } from '@features/Profile/selector';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getIntegrationFullName } from '@common/string';

export interface AdvisorListingProps {
  setStepper: React.Dispatch<
    React.SetStateAction<'ADVISOR_LISTING_PAGE' | 'ADVISOR_DETAILS_PAGE'>
  >;
  stepper: 'ADVISOR_LISTING_PAGE' | 'ADVISOR_DETAILS_PAGE';
  setAdvisorDetails: React.Dispatch<React.SetStateAction<any>>;
  adminData: any;
  advisorData: any;
}

const useStyles = makeStyles({
  root: {
    borderBottom: '1px solid #e4e7ec',
  },
  tableWrapper: {
    border: '1px solid #e4e7ec',
  },
  noDataMessage: {
    padding: '10px',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  gapRow: {
    height: '190px',
    backgroundColor: 'transparent',
  },
});

const FirmAdminTable = ({
  setAdvisorDetails,
  setStepper,
  adminData,
  advisorData,
}: AdvisorListingProps): React.JSX.Element => {
  const handleAdvisorClick = item => {
    setAdvisorDetails(item);
    setStepper('ADVISOR_DETAILS_PAGE');
  };
  const classes = useStyles();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const adminHeaders: any[] = [
    { key: 'Name', value: 'name', width: '10%' },
    { key: 'Emails', value: 'email', width: '10%' },
    { key: 'Total Clients', value: 'totalClients', width: '9%' },
    { key: 'Total AUMs', value: 'totalAum', width: '10%' },
  ];
  const advisorHeaders: any[] = [
    {
      key: 'Impersonate',
      value: 'impersonate',
      component: <Impersonate />,
      doFormat: true,
      width: '8%',
    },
    ...adminHeaders,
    {
      key: 'Status',
      value: 'status',
      component: <InviteStatus />,
      doFormat: true,
      width: '8%',
    },
    { key: 'Last Login', value: 'lastLogin', width: '9%' },
    { key: 'Integrations', value: 'integrationDetails', width: '37%' },
  ];

  const TableCell = (header, row) => {
    switch (header.value) {
      case 'totalAum':
        return price(row[header.value]);
      case 'integrationDetails':
        return (
          <Box component={'span'}>
            {getIntegrationFullName(row, header) || '--'}
          </Box>
        );
      case 'lastLogin':
        return row[header?.value]?.substring(0, 10) || '--';
      case 'status':
        return cloneElement(header?.component, {
          status: row[header?.value],
          id: row['id'],
        });
      case 'impersonate':
        return cloneElement(header?.component, {
          status: row['status'],
          userId: row['userId'],
          setIsLoading: setIsLoading,
        });
      default:
        return row[header?.value];
    }
  };

  const rowsPerPage = 10;
  const [adminPage, setAdminPage] = React.useState(1);
  const [advisorPage, setAdvisorPage] = React.useState(1);
  const [adminSearchTerm, setAdminSearchTerm] = React.useState('');
  const [advisorSearchTerm, setAdvisorSearchTerm] = React.useState('');

  const profileState = useSelector(getAuthDetails);
  useEffect(() => {
    if (
      profileState?.role?.length > 0 &&
      (profileState.termsAndConditionsAcceptedAt === '' ||
        profileState.termsAndConditionsAcceptedAt === null)
    ) {
      setIsLoading(false);
      localStorage.setItem('onboarding-state', '1');
      navigate('/terms-condition');
    } else {
      setIsLoading(false);
      if (profileState.role === 'ADVISOR') {
        navigate('/conversations');
      } else if (profileState.role === 'FIRM_ADMIN') {
        navigate('/manage-firms');
      } else if (profileState.role === 'SUPER_ADMIN') {
        navigate('/admin-console');
      } else if (profileState.role === 'ENTERPRISE_ADMIN') {
        navigate('/firms');
      }
    }
  }, [profileState]);

  const handleAdminSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAdminSearchTerm(event.target.value);
  };

  const handleAdvisorSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAdvisorSearchTerm(event.target.value);
  };

  const handleAdminPageChange = (newPage: number) => {
    setAdminPage(newPage);
  };

  const handleAdvisorPageChange = (newPage: number) => {
    setAdvisorPage(newPage);
  };

  const filteredAdminData = adminData?.filter(
    item =>
      item?.name?.toLowerCase().includes(adminSearchTerm.toLowerCase()) ||
      item?.email?.toLowerCase().includes(adminSearchTerm.toLowerCase()),
  );

  const filteredAdvisorData = advisorData?.filter(
    item =>
      item?.name?.toLowerCase().includes(advisorSearchTerm.toLowerCase()) ||
      item?.email?.toLowerCase().includes(advisorSearchTerm.toLowerCase()),
  );

  const adminStartIndex = (adminPage - 1) * rowsPerPage;
  const adminEndIndex = Math.min(
    adminStartIndex + rowsPerPage,
    filteredAdminData.length,
  );
  const adminDataOnCurrentPage = filteredAdminData.slice(
    adminStartIndex,
    adminEndIndex,
  );

  const adminTotalCount = filteredAdminData.length;

  const advisorStartIndex = (advisorPage - 1) * rowsPerPage;
  const advisorEndIndex = Math.min(
    advisorStartIndex + rowsPerPage,
    filteredAdvisorData.length,
  );
  const advisorDataOnCurrentPage = filteredAdvisorData.slice(
    advisorStartIndex,
    advisorEndIndex,
  );

  const advisorTotalCount = filteredAdvisorData.length;

  if (isLoading) return <Loader />;

  return (
    <>
      <Grid item container xs={12} rowGap={1}>
        <Grid item container xs={12}>
          <Grid
            item
            container
            xs={12}
            justifyContent={'space-between'}
            mb={1.5}
          >
            <Grid
              item
              container
              xs={6}
              justifyContent={'flex-start'}
              alignItems={'center'}
            >
              <StyledTypography
                sx={{
                  paddingBottom: '0px',
                }}
              >
                Firm Administrator
              </StyledTypography>
            </Grid>

            <Grid
              item
              container
              xs={6}
              justifyContent={'flex-end'}
              alignItems={'center'}
            >
              <SearchBoxInput
                type="text"
                placeholder="Search"
                value={adminSearchTerm}
                onChange={handleAdminSearch}
                sx={{
                  marginRight: '0px',
                }}
              />
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            className={classes.tableWrapper}
            rowGap={2}
          >
            <Grid item container xs={12}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <StyledTableRow>
                      {adminHeaders.map(header => (
                        <StyledTableCell
                          key={header.key}
                          style={{
                            width: header.width,
                          }}
                        >
                          {header.key}
                        </StyledTableCell>
                      ))}
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {adminDataOnCurrentPage.map((row, index) => (
                      <StyledTableRow
                        backgroundColor="white"
                        key={`admin-${index}`}
                        className={classes.root}
                      >
                        {adminHeaders.map(header => (
                          <StyledTableCell key={header.key} color="black">
                            {TableCell(header, row)}
                          </StyledTableCell>
                        ))}
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
                <Grid item xs={12} p={'0px 15px 0px 15px'}>
                  <CustomPagination
                    page={adminPage}
                    count={adminTotalCount}
                    rowsPerPage={rowsPerPage}
                    onPageChange={handleAdminPageChange}
                  />
                </Grid>
              </TableContainer>
            </Grid>
          </Grid>
        </Grid>

        <Grid item container xs={12} mt={2}>
          <Grid
            item
            container
            xs={12}
            justifyContent={'space-between'}
            mb={1.5}
          >
            <Grid
              item
              container
              xs={6}
              justifyContent={'flex-start'}
              alignItems={'center'}
            >
              <StyledTypography
                sx={{
                  paddingBottom: '0px',
                }}
              >
                Firm Advisor
              </StyledTypography>
            </Grid>
            <Grid
              item
              container
              xs={6}
              justifyContent={'flex-end'}
              alignItems={'center'}
            >
              <SearchBoxInput
                type="text"
                placeholder="Search"
                value={advisorSearchTerm}
                onChange={handleAdvisorSearch}
                sx={{
                  marginRight: '0px',
                }}
              />
            </Grid>
          </Grid>
          <Grid item container xs={12} className={classes.tableWrapper}>
            <Grid item container xs={12}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <StyledTableRow>
                      {advisorHeaders.map(header => (
                        <StyledTableCell
                          key={header.key}
                          sx={{
                            width: header.width,
                          }}
                        >
                          {header.key}
                        </StyledTableCell>
                      ))}
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {advisorDataOnCurrentPage.map((row, index) => (
                      <StyledTableRow
                        key={`advisor-${index}`}
                        backgroundColor="white"
                        className={classes.root}
                        onClick={() => handleAdvisorClick(row)}
                        style={{ cursor: 'pointer' }}
                      >
                        {advisorHeaders.map(header => (
                          <StyledDataCell
                            key={header.key}
                            color="black"
                            className={
                              header.value === 'impersonate'
                                ? 'impersonate-column'
                                : ''
                            }
                          >
                            {TableCell(header, row)}
                          </StyledDataCell>
                        ))}
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
                <Grid item xs={12} p={'0px 15px 0px 15px'}>
                  <CustomPagination
                    page={advisorPage}
                    count={advisorTotalCount}
                    rowsPerPage={rowsPerPage}
                    onPageChange={handleAdvisorPageChange}
                  />
                </Grid>
              </TableContainer>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default FirmAdminTable;
