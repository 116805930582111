import React from 'react';
import { Modal, Typography } from '@mui/material';
import SuccessIcon from '@icons/Success';
import { StyledApplyButton } from '@pages/CIOViewpoints/styles';
import {
  SuccessIconWrapper,
  SuccessModalBtnWrapper,
  SuccessModalContainer,
  SuccessMsgWrapper,
} from '../SuperAdminPage/styles';

interface EnterpriseSuccessModalProps {
  isOpen: boolean;
  handleConfirmClick: () => void;
}

function PasswordSuccessModal({
  isOpen,
  handleConfirmClick,
}: EnterpriseSuccessModalProps): React.JSX.Element {
  return (
    <Modal open={isOpen}>
      <SuccessModalContainer style={{ width: '300px' }}>
        <Typography
          variant="h6"
          component="div"
          style={{ textAlign: 'center' }}
        >
          Your Password has been successfully created!
        </Typography>
        <SuccessIconWrapper>
          <SuccessIcon />
        </SuccessIconWrapper>
        <SuccessMsgWrapper>
          Click on the link below to login to your account
        </SuccessMsgWrapper>
        <SuccessModalBtnWrapper>
          <StyledApplyButton
            variant="outlined"
            onClick={handleConfirmClick}
            style={{ width: '100%' }}
          >
            Continue to Login
          </StyledApplyButton>
        </SuccessModalBtnWrapper>{' '}
      </SuccessModalContainer>
    </Modal>
  );
}

export default PasswordSuccessModal;
