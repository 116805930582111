import React from 'react';
import { Button } from '@mui/material';
import {
  ButtonWrapper,
  DataSectionWrapper,
  InsightCardContainer,
  InsightCardWrapper,
  InsightPageWrapper,
  StyledDesc,
  StyledTitle,
} from './styles';
import CheckIcon from '@mui/icons-material/Check';

interface InsightsCardProps {
  item: {
    focus: {
      description: string;
      title: string;
    };
    implementation_plan: {
      [key: string]: string;
    };
  };
  triggerQuery: (query: string) => void;
  handleDrawerOpen: (data: any) => void;
}

function InsightsCard({
  item,
  triggerQuery,
  handleDrawerOpen,
}: InsightsCardProps): React.JSX.Element {
  const { focus } = item;
  const query = `Show me details on how to implement "${focus?.title}" viewpoints`;
  return (
    <InsightPageWrapper>
      <InsightCardContainer>
        <InsightCardWrapper>
          <DataSectionWrapper>
            <StyledTitle>{focus?.title}</StyledTitle>
            <StyledDesc>{focus?.description}</StyledDesc>
          </DataSectionWrapper>
          <ButtonWrapper>
            <div>
              <Button
                variant="outlined"
                style={{
                  borderRadius: '20px',
                  color: '#000',
                  fontSize: '14px',
                  textTransform: 'none',
                  border: '1px solid #e4e7ec',
                  background: 'linear-gradient(0deg, #FFF 0%, #D5D5D5 921.25%)',
                  backgroundColor: 'transparent',
                  boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.12)',
                }}
                onClick={() =>
                  handleDrawerOpen({
                    item,
                  })
                }
              >
                View Implementation
              </Button>
            </div>
            <div>
              <Button
                variant="outlined"
                style={{
                  borderRadius: '20px',
                  background: '#000',
                  color: '#fff',
                  fontSize: '14px',
                  textTransform: 'none',
                }}
                onClick={() => triggerQuery(query)}
              >
                <CheckIcon sx={{ fontSize: 'medium', marginRight: '4px' }} />
                Apply
              </Button>
            </div>
            {/* )} */}
          </ButtonWrapper>
        </InsightCardWrapper>
      </InsightCardContainer>
    </InsightPageWrapper>
  );
}

export default InsightsCard;
