const tou = `This website, which is operated by The TIFIN Group and its
subsidiaries (“TIFIN”, “the Company”, “we”, “us”, or “our”).
This License and Services Agreement and Terms of Use (this
“Agreement” or “Terms”) sets forth the terms and conditions for
your use of any TIFIN website(s), products, services and
applications, including but not limited to our finance
marketplace platform (the “Site”) that allows you (“Customer,”
“you” or “your”) to research, model and analyze assets and
potential investments using certain data and information tools,
features, and services provided by TIFIN and its agents,
third-party vendors’ or licensors’ (“Third Party Service
Providers”) on or through our platform (collectively, the
“Services”), in accordance with the terms and conditions of this
Agreement. In addition, and as set forth further below, if you
choose to use certain Services on TIFIN, you not only agree to
TIFIN’s Terms, but also to the Terms of Service for any Third
Party Service Providers. For the avoidance of doubt, these Terms
and the Privacy Policy may be accepted electronically, and it is
the intention of all parties that such acceptance shall be
deemed to be as valid as an original signature. In addition, and
as set forth further below, if you choose to use certain
Services on TIFIN, you not only agree to TIFIN’s Terms, but also
to the Terms of Service for any Third Party Service Providers.
If any term or condition of this Agreement or the Terms of
Service for any Third Party Service Providers is unacceptable to
you, please do not visit, access, or use the Site or Services.
<br />
<br /> NOTICE: Investing involves significant risks and
potential for financial loss, including the loss of all the
value of your assets. You should therefore carefully consider
whether trading or holding assets is suitable for in light of
the investors financial condition. By using the Services, you
acknowledge and agree that you are aware of, and assume, all
risks associated with any investment of such assets and you are
solely responsible for conducting your own independent analysis
of the risks specific to assets, investments and the Services.
TIFIN is not responsible or liable for any such risks or adverse
outcomes. In exchange for using the Service, you hereby agree
not to hold the Company or its Third Party Service Providers
liable for any possible claim for damages arising from any
decision you make based on information made available to you
through the Service or the Site.
<br />
<br /> 1. AGREEMENT CONDITIONS: TIFIN reserves the right to, in
its sole discretion, change, amend or modify the Terms at any
time. You acknowledge and agree that TIFIN may notify you of any
changes by sending you an email notifying you of the changes,
updating the “Effective Date” at the top of the Terms and/or by
another means. By clicking an “Agree” button presented with the
amended Terms or by continuing to access or use any of the
Services after a revision to the Terms, you confirm your
agreement to the amended Terms. Unless stated otherwise, any new
features or enhancements to the Service shall be subject to this
Agreement in addition to any terms that may be applicable. You
are responsible for regularly reviewing this Agreement and any
amendments or modifications hereto. If you do not agree to the
amended terms, you must stop using the Service. Continued use of
the Service after any changes take effect will constitute your
acceptance of such changes. If you accept this Agreement
including all of its terms, please click on the check box next
to the Terms of Use before clicking the “Submit” button. You may
also wish to print out a copy of this Agreement for your
records. If you do not accept this Agreement, then you will not
be able to access the Service.
<br />
<br /> 2. ELIGIBILITY: By registering for a TIFIN Account
(defined below), you represent and warrant that you (a) are at
least 18 years of age or of legal age to form a binding contract
under applicable law; (b) are an individual, legal person or
other organization with full legal capacity and authority to
enter into these Terms; and (c) have not previously been
suspended or removed from using our Services. TIFIN maintains
the right to select the markets and jurisdictions in which the
Services are available, and may, at its sole discretion,
restrict or prohibit the Services in certain locations
(“Restricted Locations”). For that reason, the range of Services
available to you will depend upon the location from which you
access the Services. You may not use the Services if you are
located in, or a citizen or resident of, any Restricted
Location, or where your use of the Services would be illegal or
otherwise violate any applicable law. TIFIN currently only
provides its Premium Services to residents of the United States.
<br />
<br />
3. ACCOUNT REGISTRATION AND REQUIREMENTS:
<br /> a. Registration When you register for the Service
(“Registration”) and create an account (“Account”), we may ask
you to give us certain identifying information, including
non-public personal information (“Account Information”). You
agree to provide true, accurate, current and complete
information about yourself. You also agree not to impersonate
any person or entity, misrepresent any affiliation with another
person, entity or association, use false headers or otherwise
conceal your identity from the Company for any purpose. You may
not transfer your Account to anyone else without our prior
written permission. We agree to treat with care your Account
Information that you entrust to us, in accordance with our
Privacy Policy. The Company may offer you the opportunity to
personalize the Service or your online experience. While certain
personalization features can provide a more convenient way to
access the data and features most relevant to you, be aware that
“cookies” and other similar identification techniques are used
to associate you with the computer or electronic device that you
are using. If you access the Service from a public location or
if you otherwise share a computer or electronic device, some
personalization features could reveal non-public personal
information about you to others. You alone are responsible for
deciding whether a particular personalization feature is
appropriate for you and for any consequences that result from
your decision. For your protection and the protection of our
other customers and Site users, you agree to maintain your
Account Information (including passwords, user names, and screen
names) in strict confidence and not to share Account Information
with any third party, including, but not limited to colleagues,
co-workers, advisors or agents. In the event that you share your
Account Information with a third party, the Company will
consider their activities to have been authorized by you. You
are solely responsible for all transactions initiated, messages
posted, statements made, or acts or omissions that occur within
the Service through the use of your Account. You agree that the
Company is not liable for any damage or interruptions caused by
any computer viruses, spyware, or other malware that may affect
your computer or other equipment, or any phishing, spoofing, or
other attack or otherwise. You agree to immediately notify the
Company if you become aware of any loss or theft of your Account
Information or any unauthorized use or acquisition of your
access to the Service or any other breach of security. <br/>b.
Identity Verification When registering for an Account, you agree
to provide current, accurate, and complete information for all
required fields on the registration page, including your full
legal name, address, email address, phone number, taxpayer or
government identification information, proof of identity, and
bank account details, credit card numbers and/or digital wallet
information. You hereby authorize the Company, directly or
through third parties, to make any inquiries we deem necessary
to verify your identity, protect against fraud or other
financial crime, and/or comply with our legal obligations under
various anti-money laundering (“AML”) obligations, such as under
the U.S. Bank Secrecy Act (“BSA”). When we carry out these
required inquiries, you acknowledge and agree that your personal
information may be disclosed as required to certain fraud
prevention or financial crime agencies and that these agencies
may respond to our inquiries in full. You further authorize the
Company to take whatever action we deem required under
applicable laws and reasonably necessary based upon such
inquiries. <br/>c. Account Usage You are the only person authorized
to use or access your Account. By registering for an Account,
you agree and represent that you will use the Services only for
your own personal, non-commercial use, and not on behalf or for
the benefit of any third party, unless you have obtained prior
approval from the Company. You further agree and represent that
you will use the Services only in a manner that complies with
all applicable laws. You are solely responsible for all activity
that occurs under your Account or with your User ID, including
any fraudulent activity.
<br />
<br /> 4. PRIVACY: TIFIN takes the privacy of its users
seriously. TIFIN’s Privacy Policy provides a summary of its
practices relating to the collection, sharing and use of covered
personal information. You represent and warrant that you have
read the Privacy Policy before providing any of your personal
information to TIFIN.
<br />
<br /> 5. RIGHTS TO USE SERVICES:
<br /> a. Limited License. Subject to the terms and conditions
of this Agreement and your Customer payment obligations, the
Company hereby grants to Customer and Authorized Users (as
defined below) a revocable, non-exclusive, non-sublicensable,
non-transferable, limited license (“License”) during the Term of
this Agreement to use the Services, Site and Software, as
defined herein, in accordance with this Agreement and the
Subscription Agreement. You shall have no right to receive, use
or examine any source code or design documentation relating to
the Services or Software except as provided specifically agreed
in writing. The Software, or any portion thereof, may not be
used by or on behalf of, accessed by, re-sold to, rented to, or
distributed to any other party. The license granted herein
automatically terminates upon the Company’s suspension or
termination of your Account or your ability to access or use the
Services, or upon your cancellation of your Account. All rights
not expressly granted under these Terms are hereby reserved.
Delivery of the Software and Services is conditioned upon
completion of the Customer onboarding process as required. b.
Definitions <br/>i. “Authorized Users” means those affiliates,
employees, contractors, customers, agents and other authorized
individuals identified by Customer as permitted to use the
Software. <br/>ii. “Software” means the source code, object code or
underlying structure, ideas or algorithms of any software,
documentation or data related to, provided with, or used to
provide the Service or the commercial versions of software
product/s, in object code form only (and accompanying
documentation, if applicable), identified in this Agreement and
any SOW or PO incorporated herein, including all enhancements
thereto, and made available by the Customer to Customer and its
Authorized Users as part of the Software. 
<br/>c. Limitations On
Rights Granted For Use Of Software; Third Party Software;
Limited Location License. Subject to the terms and conditions of
this Agreement and your payment obligations, the Company hereby
grants you the right to access and use the Software for the sole
purposes of searching, discovering, analyzing, researching, and
evaluating enhancements related to investment products,
including but not limited to mutual funds, ETFs, and model
portfolios (the “Purpose”).<br/> Unless otherwise agreed in writing,
you do not acquire under this Agreement any right or license to
use the Software or any component or derivative thereof, in
excess of the Purpose, scope and/or duration of the rights set
forth herein and the services described in an applicable
Statement of Work (SOW) or Purchase Order (PO), during the Term
and any renewal thereof. If this license is not renewed or
extended as provided herein, all rights to access and use the
Software will terminate at the expiration of such Term.<br/> Each
user accessing the Software must be an Authorized User and must
have access to only those functions for which the Company has
granted specific user rights. Use may occur by way of an
interface delivered with or as a part of the Software by the
Company, Customer or a third party approved by the Company. The
license granted hereunder is limited to the number of Authorized
Users presented in the payment terms of the Purchase Form.
Customer hereby represents and warrants that each such user will
access and use the Software solely for the Purpose and for no
other reason.
<br />
<br /> 6. USE OF THE SERVICE & OWNERSHIP: The Services are
proprietary to TIFIN. Any materials displayed or performed or
available on or through the Services, including but not limited
to, text, graphics, data, articles, photos, images,
illustrations (all of the foregoing, the “Content”) are
protected by copyright and/or other intellectual property laws.
You agree not modify, publish, transmit, participate in the
transfer or sale of, reproduce, create derivative works based
on, or otherwise exploit any of the Services. You acknowledge
and agree that TIFIN transfers no ownership or intellectual
property interest or title in and to the Content or the Services
to you or anyone else in connection with your use of the
Services. You acknowledge that the Service and the Software are
protected by ownership and intellectual property rights of the
Company or its Third Party Service Providers (as applicable).
Under no circumstances shall you be deemed to receive title to
any portion of any Software or Service, title to which at all
times shall vest exclusively in the Company or its Third Party
Service Providers (as applicable).<br/> TIFIN may, in its sole
discretion, remove any Content from the Services at any time,
for any or no reason and without notice.<br/> You represent and
warrant that you will not use, copy, reproduce, modify,
translate, publish, broadcast, transmit, distribute, perform,
upload, display, license, sell, commercialize or otherwise
exploit for any purpose any Content not owned by you, without
the prior consent of the owner of that Content, or in a way that
violates the Company’s rights or the rights of any other third
party. You shall not derive, disassemble or otherwise attempt to
discover or derive the source code, source files, underlying
ideas or algorithms or structure of all or any portion of the
Software by any form of reverse engineering, disassembly, or
decompilation.<br/> You may download material displayed on the
Service or the Site for non-commercial, personal use. If you do
so, you agree not to remove or alter any copyright and other
proprietary notices contained on such materials. You may not
use, distribute, modify, translate, or create derivative works
or transmit, or post the content of Service or the Site for
public or commercial purposes, including any text, images,
audio, or video.<br/> The following requirements apply to your and
your Authorized Users’ use of the Service and the Site: <br/>i. You
will not rent, lease, distribute, sell, resell, assign, or
transfer its rights to use the Software. <br/>ii. You will not
publish or disclose to third parties any evaluation of the
Software without TIFIN’s prior written consent. <br/>iii. You will
not access, or attempt to access, any data, software, systems,
networks or other property of TIFIN or a Third Party Service
Provider while accessing and using the Software. <br/>iv. You will
not use any electronic communication feature of a Service for
any purpose that is unlawful, tortious, abusive, intrusive on
another’s privacy, harassing, libelous, defamatory,
embarrassing, obscene, threatening or hateful. <br/>v. You will not
upload, post, reproduce or distribute any information, software
or other material protected by copyright or any other
intellectual property right (as well as rights of publicity and
privacy) without first obtaining the permission of the owner of
such rights. <br/>vi. You will not collect or store personal data
about other users. <br/>vii. You will not use the Service or the Site
for any commercial purpose not expressly approved by us or our
Third Party Service Providers in writing.<br/>viii. You will not
upload, post, e-mail or otherwise transmit any material that
contains viruses, worms, Trojan horses or any other
contaminating or destructive features or any other computer
code, files or programs which might interrupt, limit or
interfere with the functionality of the Service, the Site, or
any computer software, hardware or telecommunications equipment.
<br/>ix. Use any robot, spider, scraper, deep link or other similar
automated data gathering or extraction tools, program, algorithm
or methodology to access, acquire, copy or monitor the Service
or the Site. <br/>A breach of this subsection shall constitute
unauthorized use or use in excess of the authorized use of the
Software and any third-party hosting service.
<br />
<br /> 7. PROHIBITED USE: You are prohibited from accessing or
using the Services, or registering for an Account, if you: (1)
have been identified as a “Specially Designated National” by the
Office of Foreign Assets Control; (2) have been placed on the
U.S. Commerce Department’s Denied Persons List; or (3) are
located in, under the control of, or a national, citizen or
resident of any Restricted Locations, or any country to which
the United States has embargoed goods or services.<br/> You
represent, warrant, and agree that you will not use or interact
with the Services, including by contributing any Content or User
Information (as those terms are defined herein), in a manner
that: (1) Jeopardizes the security of your Account or any other
user’s Account (such as allowing someone else to log in to the
Services as you); (2) Violates the security of any computer
network, including any attempts to hack users’ passwords or
security encryption codes; (3) Runs Maillist, Listserv, any form
of auto- responder or “spam” on the Services, or any processes
that run or are activated while you are not logged into the
Services, or that otherwise interfere with the proper working of
the Services (including by placing an unreasonable load on the
Services’ infrastructure); (4) “Crawls,” “scrapes,” or “spiders”
any page, data, or portion of or relating to the Services or
Content (through use of manual or automated means); (5) Copies
or stores any significant portion of the Content; (6)
Decompiles, reverse engineers, or otherwise attempts to obtain
the source code or underlying information of or relating to the
Services; or (7) Brings disrepute to, or otherwise harms the
reputation of, TIFIN or any of its affiliates or Third Party
Service Providers. You are further prohibited from using the
Services in any manner that is not expressly and unambiguously
authorized by these Terms. <br/><br/>8. PERMITTED DATA USE; CUSTOMER
CONTENT: You hereby grant the Company and its Third Party Service
Providers a non-exclusive, transferable, world- wide,
royalty-free, sublicensable transferable, perpetual,
irrevocable, non-exclusive, worldwide license to use, copy,
modify, create derivative works of, display, perform, publish
and distribute, in any form, medium or manner, any data that you
provide for use in connection with the Service (including
Account Information) for purposes of providing the Service,
usage analytics, product development and other related purposes.
Furthermore, the Company and its Third Party Service Providers
shall have the right to compile, distribute, and otherwise
exploit anonymized, aggregated data derived from your Account
for their respective business purposes, including assessing the
aggregate usage of the Service generally. You represent and
warrant that: (i) you own the user information or have the right
to grant the rights and licenses in these Terms, and (ii) the
Company’s use of the user information as licensed herein does
not and will not violate, misappropriate or infringe on the
rights of any third party.<br/> You understand and agree that the
Company, in performing the required technical steps to provide
the Services to users (including you), may need to make changes
to your User Information to conform and adapt that user
information to the technical requirements of connection
networks, devices, services, or media, and the foregoing
licenses include the rights to do so. The Company, at its sole
discretion, may remove any user content from any of the Services
at any time for any or no reason.<br/> You hereby grant a
non-exclusive, world-wide, royalty-free license to the Company
and its Third Party Service Providers to reproduce, disclose,
transmit, publish, broadcast, or post any communications or
material of any kind that you e-mail, post or otherwise transmit
through the Service, including data, questions, comments, or
suggestions (your “Communications”) either on the Site or
elsewhere with no liability or obligation to you. You hereby
grant permission to the Company and its Third Party Service
Providers to use any ideas, concepts, know-how, or techniques
contained in your Communications for any purpose including, but
not limited to, developing and marketing products using such
information. The Company and its Third Party Service Providers,
each of their respective affiliates and agents, are entitled,
but not obligated, to review or retain your Communications. We
and our Third Party Service Providers may monitor your
Communications to evaluate the quality of service you receive,
your compliance with this Agreement, the security of the
Service, or for other reasons. You agree that these monitoring
activities will not entitle you to any cause of action or other
right with respect to the manner in which we or our Third Party
Service Providers monitor your Communications and enforce or
fail to enforce the terms of this Agreement or any other
guideline, terms and conditions, or policies relating to the
Service or the Site. In no event will we or our Third Party
Service Providers be liable for any costs, damages, expenses or
any other liabilities incurred by you as a result of any
monitoring activities. <br/><br/>9. THIRD PARTY SERVICE PROVIDER SOFTWARE
AND SERVICES; THIRD PARTY BENEFICIARIES: You acknowledge that, to
the extent the Company licenses some or all of the Software or
other components of the Service from Third Party Service
Providers (with or without notice to you), (a) the Company may
make available to you certain third party software or services
(the use of which may require your acceptance of such third
party’s license agreement or terms and conditions), which shall
be deemed part of the Service; and (b) the Company shall not
have any responsibility to update such third party software or
services, but such Third Party Service Providers, may (without
any obligation) make them available to the Company for use in
connection with the Service. <br/>You consent and authorize the
Company to delegate the authorizations you provide to the
Company to its Third Party Service Provider(s) as the Company
deems necessary or desirable to provide the Service to you. You
agree that the terms and conditions of this Agreement, including
any of the other terms, conditions, warranty disclaimers and
liability disclaimers incorporated into this Agreement, inure to
the benefit of such Third Party Service Providers and such Third
Party Service Providers are deemed to be third party
beneficiaries of this Agreement, including any other terms,
conditions, warranty disclaimers and liability disclaimers
incorporated into this Agreement. You also agree that all
references to “the Company” within this Agreement and any
incorporated terms are also deemed to include, where applicable,
the Company’s agents, vendors and licensors, including Third
Party Service Providers. To protect the privacy and security of
your Account Information, Third Party Service Providers will
only be authorized to use or maintain your Account Information
only in accordance with our Privacy Policy. <br/>Furthermore, you
acknowledge and agree that (1) you assume the risk of use of the
Software; (2) you are responsible for selecting appropriate
remediation for, and resolving, any issues found on your network
or system that prevents the proper functioning of the Software;
and (3) the Company is not liable for, or responsible to,
remediate any issues found on your network or system that
prevents the proper functioning of the Software. <br/><br/>10. THIRD PARTY
CONTENT: The Services may contain links, access or connections
(“links”) to third party websites, applications, services or
other materials (collectively “Third Party Content”) that are
not affiliated, owned or controlled by the Company. You accept
and acknowledge the risks of accessing any Third Party Content,
and that the Company has no control over and assumes no
responsibility for and cannot monitor, verify, censor or edit
any Third Party Content.<br/> The fact that we have provided a link
to a site is not an endorsement, representation, authorization,
sponsorship, or affiliation with respect to such site, its
owners, or its providers. You expressly waive as against the
Company, and the Company expressly disclaims, any liability,
losses or damages arising from your use or access or as a result
of any actions taken in reliance on any Third Party Content. We
encourage you to be aware when you leave the Services, and to
read the terms and conditions and investigate the source of any
Third Party Content that you visit or use.<br/> a. Financial Market
Information; No Warranty; Financial Information<br/> The Service may
make available certain financial market data, quotes, news,
research, analyst research, reports and opinions or other
financial information (collectively, the “Market Information”)
that has been independently obtained by certain financial market
information services, financial publishers, various securities
markets including stock exchanges and their affiliates,
investment bankers and other providers or has been obtained by
the Company or its Third Party Service Providers (collectively,
the “Information Providers”). Neither the Company nor our Third
Party Service Providers guarantee or certify the accuracy,
completeness, timeliness or correct sequencing of the Market
Information made available to you by the Information Providers
or any other third party transmitting the Market Information
(the “Information Transmitters”). You understand that none of
the Market Information available through the Service constitutes
a tailored recommendation or solicitation that you should
purchase or sell any particular security or other asset without
further analysis including the investors specific financial
circumstances and needs. Neither the Company or nor Third Party
Service Providers endorse or approve any of the Market
Information and only make such Market Information available as a
service and convenience you. You will not redistribute or
facilitate the redistribution of Market Information, nor will
you provide access to Market Information to anyone who is not
authorized by us or our Third Party Service Providers to receive
Market Information. If you are a securities broker, dealer,
banker, or investment advisor, you agree not to use Market
Information provided by us or our Third Party Service Providers
for any purpose related to your business other than for the
Purpose of this Agreement.<br/> All Market Information is provided on
an “as-is” basis. You agree that neither the Company, nor its
Third Party Service Providers shall be liable in any way for the
accuracy, completeness, timeliness or correct sequencing of the
Market Information, or for any decision made or action taken by
you relying upon the Market Information. Neither the Company,
nor its Third Party Service Providers, guarantee any future
performance, nor any specific level of performance of any trade
nor the success of any investment decision or strategy. Neither
the Company nor any of its Third Party Service Providers shall
be responsible for investment decisions, damages, or other
losses resulting from use of the Service. <br/><br/>11. CONDUCT;
COMPLIANCE WITH LAWS: You shall be solely responsible for actions
and the actions of its Authorized Users while using the
Software. You agree: (1) to abide by all local, state, national,
and international laws and regulations applicable to Customer’s
use of the Software, including, if applicable and without
limitation, intellectual property and privacy laws, including,
without limiting the foregoing, the U.S. Computer Fraud and
Abuse Act; (2) to take all necessary steps to avoid uploading or
distributing malware, viruses, corrupted files, or any other
software or programs that may damage the operation of the
Software or another’s computer or mobile device; (3) not to use
the Software for illegal, fraudulent, harmful, unethical or
inappropriate, deceptive, threatening, harassing, defamatory, or
otherwise objectionable purposes in TIFIN’s sole discretion; (4)
not to use the Software to distribute, promote or transmit any
unlawful, harassing, libelous, abusive, threatening, harmful,
vulgar, obscene, pornographic, indecent, defamatory, hateful,
racially, ethnically, unwanted or otherwise objectionable
material of any kind or nature; (5) not to use any information
or other knowledge gained through use of the Services to provoke
an interference with any patent application which TIFIN has
filed with respect to the Services, or to amend any claim in any
pending patent applications to expand such claim to read on,
cover or dominate any invention (whether or not patentable)
relating to the Services; (5) not to intentionally interfere
with another customer’s use and enjoyment of the Software or
another person or entity’s use and enjoyment of similar
services; and (6) to comply with all regulations, policies and
procedures of Third Party Service Providers. You acknowledge and
agree that the Company is not liable for any use of the Software
in your networks and systems that constitutes infringement of a
third party’s intellectual property rights to the extent that
such infringement would not occur outside your network or
system. <br/><br/>12. FEES AND TAXES:<br/> a. Fees: By using the Premium
Services, you agree to pay the Company any and all applicable
fees for the Term and Extended Term as defined herein. You
authorize the Company to charge or deduct any applicable fees
from your Account. You agree to pay all fees and charges in
accordance with the billing terms set forth in this Agreement
and any Purchase Order (collectively, “Fees”). All Fees are
quoted and payable in United States Dollars. In addition to such
Fees, you shall pay all applicable sales, use and other taxes or
duties (excluding taxes based on the Company’s net income). <br/>b.
Payments: The Fees will be invoiced monthly, in advance, unless
otherwise stated in the SOW or PO. Invoices will be sent to the
address on the Purchase Order. <br/>c. Additional Authorized Users: 
During the Term of this Agreement, in order to (i) modify the
list of Authorized Users, (ii) order additional licenses, or
(iii) increase the Term, you must obtain the Company’s advance
written consent. <br/><br/>13. TERM This Agreement commences on the
Effective Date of the Order Form and shall continue for an
initial period of one (1) year (the “Initial Term”). At the end
of the Initial Term, the Agreement will automatically renew for
additional twelve (12) month periods (the “Extended Term”)
pursuant to the terms hereof unless the parties negotiate a
further amendment or one party gives the other party written
notice of non-renewal at least sixty (60) days prior to the end
of the Term (the Initial Term and any Extended Term are
collectively referred to herein as the “Term”). <br/><br/>14. TERMINATION:<br/>
a. Breach Either party may terminate this Agreement upon written
notice if the other party has breached a material term of this
Agreement and has not cured such breach within sixty (60) days
of receipt of notice from the non-breaching party specifying the
breach. <br/>b. Insolvency Either party may terminate this Agreement
if (i) the other party has a receiver appointed for it or its
property; (ii) the other party makes an assignment for the
benefit of creditors; (iii) any proceedings are commenced by,
for or against the other party under any bankruptcy, insolvency
or debtor’s relief law; or (iv) the other party is liquidated or
dissolved. <br/>c. Failure to Pay/Customer Conduct The Company may
suspend or terminate access to the Software, at its sole option,
with or without notice to Customer, if (i) any payment is
delinquent by more than thirty (30) days, or (ii) Customer
breaches this Agreement. <br/>d. Termination for Convenience Either
party may terminate this Agreement for convenience upon written
notice delivered to the other party not less than ninety (90)
days prior to the effective date of termination. Customer shall
remain obligated to make all payments for the Software and
applicable services during the termination period. <br/>e. Effect of
Termination The Company shall not be liable to Customer or any
third party for suspension or termination of Customer’s access
to, or right to use, the Software under this Agreement. If
Customer or the Company terminates this Agreement, Customer will
be obligated to pay the pro-rated balance due for the Software
provided prior to the effective date of termination. Upon the
effective date of expiration or termination of this Agreement
for any reason, whether by Customer or the Company, Customer’s
right to use the Software shall immediately cease and Customer
and its Authorized Users’ access to the Software will terminate.
The terms of this Agreement shall survive its expiration or
termination for any reason as described herein in the Survival
section of this Agreement. <br/><br/>15. DISCLAIMER OF WARRANTIES: The
Company covenants, represents and warrants that: (a) access to
the Software will be provided in compliance with applicable laws
and regulations, including license and permit requirements; (b)
it has authority to enter into this Agreement; and (c) it has
the right to grant the rights granted herein, including
appropriate written agreements with its employees, agents and
subcontractors and Third Party providers, if any. Customer
covenants, represents and warrants that it will access and use
the Software in strict adherence to the rights and limitations
described herein and in compliance with all applicable law.<br/> None
of the Company, its licensors, partners, suppliers or service
providers make any representations or warranties concerning any
Content or other material contained in or accessed through the
Services, and we will not be responsible or liable for the
accuracy, copyright compliance, legality, or decency of material
contained in or accessed through the Services, including,
without limitation, any Financial Market Information for any
assets. The Company will have no liability or responsibility for
any permanent or temporary inability to access or use any
Services. <br/><br/>THE SERVICE IS PROVIDED “AS-IS” AND “AS-AVAILABLE”,
WITH ALL FAULTS, AND WITHOUT WARRANTY OF ANY KIND. THE COMPANY,
ITS THIRD PARTY SERVICE PROVIDERS AND EACH OF THEIR RESPECTIVE
AFFILIATES (COLLECTIVELY, THE “DISCLAIMING PARTIES”) DISCLAIM
ALL OTHER WARRANTIES, EXPRESS AND IMPLIED, INCLUDING, BUT NOT
LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS
FOR A PARTICULAR PURPOSE, QUIET ENJOYMENT, QUALITY OF
INFORMATION (INCLUDING, WITHOUT LIMITATION, THE ACCURACY,
ADEQUACY OR COMPLETENESS OF ANY PORTFOLIO OR ACCOUNT
INFORMATION, HISTORICAL PERFORMANCE AND ANALYTIC, FORWARD
LOOKING ANALYICS, COST BASIS, OR OTHER INFORMATION ABOUT YOU OR
YOUR ACCOUNT MADE AVAILABLE THROUGH THE SERVICE), TITLE AND
NON-INFRINGEMENT. THE DISCLAIMING PARTIES FURTHER DISCLAIM ANY
WARRANTY REGARDING NONINTERRUPTION OF USE, DELAY, FREEDOM FROM
BUGS, AND THAT USE OF THE SERVICE IS ERROR-FREE. YOU EXPRESSLY
AGREE AND ACKNOWLEDGE THAT ANY USE OF THE SERVICE IS AT YOUR
SOLE RISK. NO ORAL OR WRITTEN INFORMATION OR ADVICE GIVEN BY THE
COMPANY OR ITS AUTHORIZED REPRESENTATIVES SHALL CREATE A
WARRANTY OR IN ANY WAY INCREASE THE SCOPE OF THE COMPANY OR ITS
THIRD PARTY SERVICE PROVIDERS’ OBLIGATIONS HEREUNDER. YOU ARE
SOLELY RESPONSIBLE FOR COMPLIANCE WITH ALL APPLICABLE LAWS. THE
SERVICE MAY BE USED TO ACCESS AND TRANSFER INFORMATION OVER THE
INTERNET. YOU ACKNOWLEDGE AND AGREE THAT NONE OF THE DISCLAIMING
PARTIES OPERATE OR CONTROL THE INTERNET AND THAT (A) VIRUSES,
WORMS, TROJAN HORSES, OR OTHER UNDESIRABLE DATA OR SOFTWARE OR
(B) UNAUTHORIZED USERS (SUCH AS HACKERS) MAY ATTEMPT TO OBTAIN
ACCESS TO YOUR COMPUTERS OR NETWORKS, AND DAMAGE THEM OR YOUR
DATA OR OTHER ACCOUNT INFORMATION PROVIDED BY YOU. YOU
ACKNOWLEDGE THAT INFORMATION YOU STORE OR TRANSFER THROUGH THE
SERVICES MAY BECOME IRRETRIEVABLY LOST OR CORRUPTED OR
TEMPORARILY UNAVAILABLE DUE TO A VARIETY OF CAUSES, INCLUDING
SOFTWARE FAILURES, PROTOCOL CHANGES BY THIRD PARTY PROVIDERS,
INTERNET OUTAGES, FORCE MAJEURE EVENT OR OTHER DISASTERS
INCLUDING THIRD PARTY ATTACKS, SCHEDULED OR UNSCHEDULED
MAINTENANCE OR OTHER CAUSES EITHER WITHIN OR OUTSIDE OUR
CONTROL. YOU ARE SOLELY RESPONSIBLE FOR BACKING UP AND
MAINTAINING DUPLICATE COPIES OF ANY INFORMATION YOU STORE OR
TRANSFER THROUGH THE SERVICES. THE DISCLAIMING PARTIES SHALL IN
NO WAY BEAR ANY RESPONSIBILITY OR LIABILITY FOR ANY SUCH
ACTIVITIES. <br/><br/>16. INDEMNIFICATION, RELEASE AND LIMITATION OF
LIABILITY: YOU AGREE TO INDEMNIFY AND HOLD THE COMPANY, ITS THIRD
PARTY SERVICE PROVIDERS AND THEIR RESPECTIVE AFFILIATES,
DIRECTORS, OFFICERS, AGENTS, AND EMPLOYEES HARMLESS FROM ANY
CLAIM, DEMAND, LOSS, COSTS OR EXPENSE (INCLUDING ATTORNEYS’
FEES) ARISING OUT OF OR RELATING TO YOUR ACCESS OR USE OF THE
SERVICE OR THE SITE; INCLUDING, BUT NOT LIMITED TO, YOUR
VIOLATION OF THIS AGREEMENT, STATE OR FEDERAL SECURITIES OR
OTHERS LAWS OR REGULATIONS, OR ANY OTHER PERSON’S RIGHTS,
INCLUDING, BUT NOT LIMITED TO, INFRINGEMENT OF ANY COPYRIGHT OR
VIOLATION OF ANY INTELLECTUAL PROPERTY, PROPRIETARY OR PRIVACY
RIGHT. IF YOU ARE OBLIGATED TO INDEMNIFY TIFIN OR ANY OF THE
INDEMNIFIED PARTIES IDENTIFIED ABOVE, TIFIN (OR, AT TIFIN’S
DISCRETION, THE APPLICABLE INDEMNIFIED PARTY) WILL HAVE THE
RIGHT, IN ITS SOLE DISCRETION, TO CONTROL ANY ACTION OR
PROCEEDING AND TO DETERMINE WHETHER TIFIN WISHES TO SETTLE, AND
IF SO, ON WHAT TERMS. <br/>IN NO EVENT SHALL ANY DISCLAIMING PARTY BE
LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL,
CONSEQUENTIAL, PUNITIVE, OR EXEMPLARY DAMAGES, INCLUDING, BUT
NOT LIMITED TO, <br/>DAMAGES FOR LOSS OF PROFITS, REVENUE, INCOME,
GOODWILL, BUSINESS, USE, DATA OR ANY INFORMATION (INCLUDING YOUR
PERSONAL DATA, ACCOUNT INFORMATION OR DOCUMENTATION), OR OTHER
INTANGIBLE LOSSES, OR DAMAGES CAUSED BY THEFT, UNAUTHORIZED
ACCESS, SYSTEMS FAILURE, OR COMMUNICATIONS LINE FAILURE, OR THE
COST OF PROCURING SUBSTITUTE GOODS OR SERVICES, CAUSED BY THE
USE OF OR INABILITY TO USE THE SERVICE, THE SITE, MATERIALS OR
ANY PRODUCTS PROVIDED HEREIN, OR ANY OTHER MATTER RELATING TO
THIS AGREEMENT OR THE SUBJECT MATTER HEREOF, EVEN IF SUCH PARTY
HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, HOWEVER
CAUSED. TO THE EXTENT THAT A JURISDICTION DOES NOT PERMIT THE
EXCLUSION OR LIMITATION OF LIABILITY AS SET FORTH HEREIN, THE
LIABILITY OF THE DISCLAIMING PARTIES IS LIMITED TO THE EXTENT
PERMITTED BY LAW IN SUCH JURISDICTIONS. YOUR SOLE AND EXCLUSIVE
REMEDY UNDER THIS AGREEMENT SHALL BE FOR YOU TO DISCONTINUE YOUR
USE OF THE SERVICE AND THE SITE. <br/>If you are a California
resident, you waive California Civil Code Section 1542, which
states: “A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE
CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR
AT THE TIME OF EXECUTIVE THE RELEASE, WHICH IF KNOWN BY HIM OR
HER MUST HAVE MATERIALLY HIS OR HER SETTLEMENT WITH THE DEBTOR.”
If you are a resident of another jurisdiction, you waive any
comparable statute or doctrine. <br/><br/>17. LIMITATION OF LIABILITY: TO
THE FULLEST EXTENT ALLOWED BY APPLICABLE LAW, IN NO EVENT AND
UNDER NO LEGAL THEORY (INCLUDING, WITHOUT LIMITATION, TORT,
CONTRACT, STRICT LIABILITY, OR OTHERWISE) SHALL TIFIN, ITS
LICENSORS, SUPPLIERS, AFFILIATES AND SERVICE PROVIDERS, OR ANY
OF THEIR RESPECTIVE OFFICERS, DIRECTORS, AGENTS, JOINT
VENTURERS, EMPLOYEES OR REPRESENTATIVES BE LIABLE TO YOU OR TO
ANY OTHER PERSON FOR (A) ANY INDIRECT, SPECIAL, INCIDENTAL, OR
CONSEQUENTIAL OR SIMILAR DAMAGES, INCLUDING DAMAGES FOR LOST
PROFITS, LOSS OF GOODWILL, LOSS OF DATA, WORK STOPPAGE, ACCURACY
OF RESULTS, OR COMPUTER FAILURE OR MALFUNCTION ARISING OUT OF OR
RELATED TO THE TERMS, YOUR USE OR ATTEMPTED USE OF TIFIN, ANY OF
THE SERVICES (AS DEFINED ABOVE), OR ANY OF THE OTHER
INFORMATION, SERVICES OR TRANSACTIONS CONTEMPLATED BY THESE
TERMS, OR (B) ANY AMOUNT, IN THE AGGREGATE, IN EXCESS OF THE
FEES PAID BY YOU TO THE COMAPNY IN CONNECTION WITH THE SERVICES
IN THE SIX (6) MONTH PERIOD PRECEDING THE APPLICABLE CLAIM. SOME
STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF CERTAIN
DAMAGES, SO THE ABOVE LIMITATION AND EXCLUSIONS MAY NOT APPLY TO
YOU.<br/><br/>18. MODIFICATIONS, SUSPENSION AND TERMINATIONS OF THE
SERVICE: The Company and its Third Party Service Providers
reserve the right to modify or discontinue, temporarily or
permanently, the Site or the Service (or any part thereof) with
or without notice. You agree that neither the Company nor its
Third Party Service Providers will be liable to you or to any
third party for any modification, suspension or discontinuance
of the Service or the Site. The license granted hereunder will
terminate if we or our Third Party Service Providers believe
that any information provided by you, including your email
address, is no longer current or accurate, or if you fail to
otherwise comply with any term or condition of this Agreement or
any other guideline, terms and conditions, or policies relating
to the Service or the Site. Upon such violation, you agree to
terminate access to the Service.<br/> Furthermore, the Company or its
Third Party Service Providers may, in their sole discretion,
suspend your access to and use of the Service or reject or
remove from the Service any of your Data, Account Information or
any other information provided by you to (a) prevent damages to,
or degradation of, the Service or the Site; (b) comply with any
law, regulation, court order, or other governmental request; or
(c) otherwise protect us or our Third Party Service Providers
from potential legal liability.<br/> You may close your Account or
cease using the Services at any time. To close your Account, you
must contact us at hello@TIFIN.com. Account termination may
result in destruction of any Content or User Information
associated with your Account. <br/><br/>19. NOTICES, COMMUNICATIONS, AND
ELECTRONIC SIGNATURES: You agree to accept all communications
from us regarding use of the Service at the addresses you
provide during Registration. It is your responsibility to
provide the Company with your accurate email address, your
contract information and/or mobile phone number, and to update
such information with the Company. Please promptly update any
changes to your Account Information. The Company and its Third
Party Service Providers are entitled to rely on the e-mail
address, mobile phone number and U.S. mail address that you last
provided, for purposes of communication and providing
information relating to the Company or its Third Party Service
Providers’ products and services. <br/>If the Company sends you an
electronic communication but you do not receive it because your
contact information on file is incorrect, or you are otherwise
unable to receive electronic communications, the Company will be
deemed to have provided the communication to you. All
communications from the Company in electronic format will be
deemed to be “in writing,” and to have been received within
twenty- four (24) hours after posting or dissemination. You
agree to waive all claims resulting from failure to receive
communications because of changes in your e-mail, mobile phone
number or U.S. mail address. You can opt out of receiving
marketing information from us or our Third Party Service
Providers by following the instructions in any e-mail sent to
you by such party. You agree to be bound by any affirmation,
assent, or agreement you transmit through the Service, including
but not limited to, any consent you give to receive
communications from us or our Third Party Service Providers
solely through electronic transmission. You agree that, when in
the future you click on an “I agree,” “I consent” or other
similarly worded “button” or entry field with your mouse,
keystroke or other device, or sign your name electronically on a
touchscreen device, your agreement or consent will be legally
binding and enforceable and the legal equivalent of your
handwritten signature. <br/>You may decline or withdraw consent to
receive communications electronically from the Company, but you
agree and acknowledge that the Company may suspend or terminate
your use of the Services if you do not consent to receive
communications in electronic format.<br/><br/>20. GENERAL PROVISIONS:<br/> a.
Relationship of the Parties<br/> TIFIN is an independent contractor
for all purposes. Nothing in these Terms shall be deemed to
create or constitute, imply, give effect to or otherwise
recognize a partnership, employment, joint venture, or formal
business relationship of any kind. Under specifically
authorized, TIFIN is not your broker, intermediary, agent or
advisor, and has no fiduciary relationship, duty or obligation
to you or any other user in connection with your use of the
Services. Neither TIFIN nor its licensors or Third Party Service
Providers are providing tax advice, legal advice, or other
professional advice by allowing you to use the Services. <br/>b. No
Publicity<br/> Nothing in this Agreement gives either party a right
to use publicly the other party’s name, trademark(s), trade
name(s) or refer to the existence or terms of this Agreement
directly or indirectly without the other party’s prior written
consent. The parties acknowledge and agree that prior consents
given by either party prior to the Effective Date are and will
remain valid. <br/>c. Export<br/> Both parties acknowledge and agree that
the Software and technology subject to this Agreement are
subject to the export and re-export control laws and regulations
of the United States and any applicable jurisdiction, including
but not limited to the Export Administration Regulations
(“EAR”), and sanctions regimes of the U.S. Department of
Treasury, Office of Foreign Asset Controls. Both parties will
comply with these laws and regulations. Both parties shall not,
without prior U.S. government authorization, export, re-export,
or transfer any goods, software, or technology subject to this
Agreement, either directly or indirectly, to any country subject
to a U.S. trade embargo (currently Cuba, Iran, North Korea,
Sudan, and Syria) or to any resident or national of any such
country, or to any person or entity listed on the “Entity List”
or “Denied Persons List” maintained by the U.S. Department of
Commerce or the list of “Specifically Designated Nationals and
Blocked Persons” maintained by the U.S. Department of Treasury.
<br/>d. Governing Law<br/> This Agreement shall be governed by and
construed in accordance with the laws of the State of New York
without reference to its choice of law rules. Any legal suit,
action or proceeding arising out of or relating to this
Agreement shall be commenced in a federal court in the Southern
District of New York or in state court in the County of New
York, New York, and each party hereto irrevocably submits to the
exclusive jurisdiction and venue of any such court in any such
suit, action or proceeding. If any part of this Agreement is
held to be invalid, void or unenforceable, that part will be
deemed severable and will not affect the validity and
enforceability of any remaining provisions hereof. <br/>e. Entire
Agreement<br/> This Agreement shall inure to the benefit of each
party’s successors and permitted assigns. This Agreement,
together with all addenda, schedules, exhibits and Privacy
Policy constitutes the entire agreement between the parties and
supersedes all prior or contemporaneous written and oral
agreements and understandings between the parties relating to
the subject matter hereof. <br/>f. Survival<br/> All provisions of these
Terms which by their nature extend beyond the expiration or
termination of these Terms shall survive termination of your
Account. By way of example, all of the following will survive
termination: any obligation you have to pay us or indemnify us,
any limitations on our liability, any terms regarding ownership
or intellectual property rights, and terms regarding disputes
between us. </br>g. No Waiver<br/> The failure of either you or us to
exercise, in any way, any right herein shall not be deemed a
waiver of any further rights hereunder. <br/>h. Assignment<br/> You may
not assign, delegate or transfer these Terms or your rights or
obligations hereunder, or your Account, in any way (by operation
of law or otherwise) without the Company’s prior written
consent. We may transfer, assign, or delegate these Terms and
our rights and obligations without consent, including as part of
a change of control or other corporate combination or
transaction.<br/> You acknowledge and agree that any of the Company’s
officers, directors, employees and independent contractors
(“Personnel”) are third party beneficiaries of these Terms, and
that upon your acceptance of these Terms, Personnel will have
the right (and will be deemed to have accepted the right) to
enforce these Terms against you as the third party beneficiary
hereof. <br/>i. Headings<br/> The section headings in these Terms are for
convenience only and shall not govern the meaning or
interpretation of any provision of these Terms.<br/> j. Severability<br/>
If any provision of these Terms shall be determined to be
unenforceable or invalid under any rule, law or regulation of
any local, state or federal government, such provision will be
limited, changed or eliminated only to the minimum extent
necessary to accomplish the objectives of the provision to the
greatest extent possible under applicable law and these Terms
shall otherwise remain valid, in full force, and enforceable. <br/>k.
Force Majeure <br/>The Company shall not be liable for delays,
failure in performance or interruption of any of the Services
which result directly or indirectly from any abnormal or
unforeseeable circumstance, cause or condition beyond our
reasonable control, including but not limited to, significant
market volatility, any delay or failure due to any act of God,
act of civil or military authorities, act of terrorists, civil
disturbance, war, pandemic, strike or other labor dispute, fire,
interruption in telecommunications or Internet services or
network provider services, failure of equipment and/or software,
other catastrophe or any other occurrence which is beyond our
reasonable control and shall not affect the validity and
enforceability of any remaining provisions. <br/>l. CONTACT<br/> For more
information about TIFIN or any of the Services, please visit our
website TIFIN.com. If you have any questions, comments, or
concerns regarding these Terms or the Services, please contact
our Customer Support team at hello@TIFIN.com.`;

export default tou;
