import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { styled, useTheme, useMediaQuery } from '@mui/material';

interface ModelProps {
  openModal: boolean;
  handleClose: any;
  showTitle?: boolean;
  title?: string;
  children: any;
  fullWidth?: boolean;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  closeIcon?: React.ReactElement;
}

const StyledModal = styled(Dialog)(({ theme }) => ({
  borderRadius: '8px',
  border: '1px solid var(--Silver-200, #e3e3e3)',
  boxShadow: '0px 4px 8px 0px rgba(16, 24, 40, 0.1)',
  [theme.breakpoints.down('sm')]: {
    margin: '0 16px',
  },
  [theme.breakpoints.up('sm')]: {
    margin: '32px auto',
  },
}));

function DialogModal({
  openModal,
  handleClose,
  showTitle = false,
  title,
  children,
  fullWidth = true,
  maxWidth = 'sm',
  closeIcon,
}: ModelProps): React.JSX.Element {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <StyledModal
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      open={openModal}
      onClose={handleClose}
      PaperProps={{
        style: {
          margin: isMobile ? '0 16px' : '32px auto',
          width: '100%',
        },
      }}
    >
      {showTitle && (
        <DialogTitle sx={{ fontSize: isMobile ? '16px' : '20px' }}>
          {title}
        </DialogTitle>
      )}
      {closeIcon}
      <DialogContent sx={{ padding: isMobile ? '8px' : '24px' }}>
        {children}
      </DialogContent>
    </StyledModal>
  );
}

export default DialogModal;
