/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import { Box, Divider, Grid, styled, useTheme } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { checkValidNumber, price } from 'utils/common/number';
import CustomTooltip from 'app/components/Tooltip';
import { SuggestionCardWrapper } from './styles';
import {
  ParagraphBoldtText,
  ParagraphLightText,
  GreySubLabelText,
  Grey2SubLabelText,
} from 'utils/styles/Typography';

type ChartProps = {
  name: string;
  suffix: string;
  decimal: number;
  multiplier: number;
  value: number;
};

type CardInfoProps = {
  name?: string | '';
  ticker: string;
  category?: string;
  nav: number;
  value: number;
};

type SuggestionCardProps = {
  isTargetCard?: boolean;
  checkBoxDisable?: boolean;
  targetCardChartData?: any;
  cardinfo: CardInfoProps;
  chart: Array<ChartProps>;
  selectedTicker?: string | null | undefined;
  onSelect?: ((event: any, param: any) => void) | undefined;
};

const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 10,
  borderRadius: 5,
}));

function SuggestionCard({
  chart = [],
  cardinfo,
  isTargetCard,
  selectedTicker = '',
  targetCardChartData,
}: SuggestionCardProps): React.JSX.Element {
  const theme = useTheme();
  const Dot = styled('i')`
    width: 2px;
    height: 2px;
    margin: 2px 5px;
    border-radius: 10px;
    background: ${theme.palette.primary['grey4']};
  `;
  const getColorCode = (chartObj: any) => {
    if (!targetCardChartData || !targetCardChartData?.length || isTargetCard)
      return {
        color: theme.palette.primary['black'],
        icon: null,
      };
    const founRet: any = targetCardChartData.find(
      (l: any) => l.name === 'Returns',
    );
    if (
      founRet &&
      founRet.name === 'Returns' &&
      chartObj &&
      chartObj?.name === 'Returns'
    ) {
      if (chartObj.value > founRet.value) {
        return {
          color: theme.palette.primary['aquaGreen'],
          icon: <ArrowDropUpIcon sx={{ fontSize: 'inherit' }} />,
        };
      }
      return {
        color: theme.palette.primary['dangerMain'],
        icon: <ArrowDropDownIcon sx={{ fontSize: 'inherit' }} />,
      };
    }
    return {
      color: theme.palette.primary['black'],
      icon: null,
    };
  };

  return (
    <SuggestionCardWrapper
      container
      rowGap={1}
      alignItems={'center'}
      justifyContent={'space-between'}
      height={isTargetCard ? 'auto' : '100%'}
      sx={{ margin: isTargetCard ? 0 : '0 auto' }}
      checked={selectedTicker === cardinfo?.ticker}
    >
      <Grid
        item
        container
        rowGap={0.7}
        alignItems={'center'}
        justifyContent={'space-between'}
        sx={{ position: 'relative' }}
      >
        <CustomTooltip title={cardinfo?.name || ''}>
          <ParagraphBoldtText
            noWrap
            fontSize={'12px'}
            color={theme.palette.primary['black']}
            width={'100%'}
            sx={{ paddingRight: '26px' }}
          >
            {cardinfo?.name}
          </ParagraphBoldtText>
        </CustomTooltip>
        <ParagraphLightText
          width={'100%'}
          fontSize={'10px'}
          color={theme.palette.primary['charcoalBlack']}
        >
          {cardinfo?.category}
        </ParagraphLightText>
        <Grey2SubLabelText
          width={'100%'}
          fontSize={'10px'}
          color={theme.palette.primary['grey4']}
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {cardinfo?.ticker} <Dot /> {price(cardinfo?.nav)}
        </Grey2SubLabelText>
      </Grid>
      <Divider sx={{ width: '100%' }} />
      <Grid
        container
        rowGap={1}
        alignItems={'center'}
        justifyContent={'center'}
      >
        {chart &&
          chart
            .filter(k => ['Fees', 'Risk', 'Returns'].includes(k.name))
            .map((k: any, i: number) => (
              <Grid
                key={i}
                container
                alignItems={'center'}
                justifyContent={'space-between'}
              >
                <GreySubLabelText
                  color={theme.palette.primary['charcoalBlack']}
                >
                  {k.name}
                </GreySubLabelText>
                <ParagraphBoldtText
                  fontSize={10}
                  color={getColorCode(k).color}
                  sx={{ display: 'flex' }}
                >
                  {getColorCode(k).icon}
                  {checkValidNumber(
                    k.value * k.multiplier,
                    k.decimal,
                    k.suffix,
                    '--',
                  )}
                </ParagraphBoldtText>
              </Grid>
            ))}
      </Grid>
      {!isTargetCard &&
        chart &&
        chart
          .filter(
            (k: any) =>
              k.chart_type === 'odometer' &&
              (k.variable.includes('overlap') ||
                k.variable.includes('correlation')),
          )
          .slice(0, 1)
          .map((k: any, ki: number) => (
            <Grid
              key={ki}
              container
              rowGap={0.7}
              sx={{
                width: '100%',
                padding: '8px',
                background: '#F9FAFB',
                borderRadius: '4px',
              }}
            >
              <ParagraphBoldtText fontSize={'12px'}>
                {k.view_name}
              </ParagraphBoldtText>
              <Grid container rowGap={1} columnGap={1} alignItems={'center'}>
                <Box sx={{ flexGrow: 1 }}>
                  <BorderLinearProgress
                    variant="determinate"
                    sx={{
                      '& .MuiLinearProgress-bar': {
                        backgroundColor: theme.palette.primary['graph']['blue'],
                      },
                    }}
                    value={k.value * k.multiplier}
                  />
                </Box>
                <ParagraphBoldtText fontSize={'12px'}>
                  {checkValidNumber(
                    k.value * k.multiplier,
                    k.decimal,
                    k.suffix,
                    '--',
                  )}
                </ParagraphBoldtText>
              </Grid>
            </Grid>
          ))}
    </SuggestionCardWrapper>
  );
}

export default SuggestionCard;
