import React from 'react';
import FirmManagement from './FirmManagement';
import AdvisorDetails from './AdvisorDetails';
import { useRESTQuery } from '@hooks/useRESTQuery';
import { useDispatch } from 'react-redux';
import { handleError } from '@common/error';
import Loader from '@components/Loader';
import { Grid } from '@mui/material';

function FirmAdmin(): React.JSX.Element {
  const dispatch = useDispatch();
  const [stepper, setStepper] = React.useState<
    'ADVISOR_LISTING_PAGE' | 'ADVISOR_DETAILS_PAGE'
  >('ADVISOR_LISTING_PAGE');
  const [advisorDetails, setAdvisorDetails] = React.useState({});

  const { data: firmDetails, isLoading: firmDetailsLoading } = useRESTQuery(
    ['get-firm-details-for-firm-admin'],
    {
      endpoint: `/firms/firmDetails`,
      method: 'GET',
      headers: {
        'X-Tifin-Ai-Auth': localStorage.getItem('x-tifin-ai-token'),
      },
    },
    {
      select: response => response.data,
      onError: (error: any) => {
        handleError(dispatch, error);
      },
    },
  );

  if (firmDetailsLoading) return <Loader />;
  return (
    <Grid container>
      {(() => {
        switch (stepper) {
          case 'ADVISOR_DETAILS_PAGE' /*Case 0 */:
            return (
              <AdvisorDetails
                setStepper={setStepper}
                advisorDetails={advisorDetails}
                stepper={stepper}
              />
            );
          default:
            return (
              <FirmManagement
                setAdvisorDetails={setAdvisorDetails}
                stepper={stepper}
                setStepper={setStepper}
                firmDetails={firmDetails}
              />
            );
        }
      })()}
    </Grid>
  );
}

export default FirmAdmin;
