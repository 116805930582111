import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from 'recharts';

interface LineChartProps {
  data: {
    Period?: string;
    D?: string;
    P?: number;
    Data?: Array<{ D: string; P: number }>;
  } | null;
}

function SimpleLineChart({ data }: LineChartProps): React.JSX.Element {
  const formattedData =
    data?.Data?.map(item => ({
      name: new Date(item.D).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      }),
      pv: Number(item?.P.toFixed(2)) || 0,
    })) || [];

  const getCustomTicks = () => {
    if (formattedData.length === 0) return [];

    const customTicks: string[] = [];
    const startDate = new Date(formattedData[0].name);
    const endDate = new Date(formattedData[formattedData.length - 1].name);

    if (data?.Period === 'W1') {
      // Generate daily ticks for one week
      for (let i = 0; i < 7; i++) {
        const date = new Date(startDate);
        date.setDate(startDate.getDate() + i);
        customTicks.push(
          date.toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
          }),
        );
      }
    } else if (data?.Period === 'M1') {
      // Generate weekly ticks for one month
      while (startDate <= endDate) {
        customTicks.push(
          startDate.toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
          }),
        );
        // Incrementing by a week
        startDate.setDate(startDate.getDate() + 7);
      }
    } else {
      // Generate ticks at regular intervals
      const interval = Math.max(1, Math.floor((formattedData.length - 2) / 3));
      customTicks.push(
        formattedData[0].name,
        ...formattedData
          .slice(1, formattedData.length - 1)
          .filter((_, index) => index % interval === 0)
          .map(item => item.name),
        formattedData[formattedData.length - 1].name,
      );
    }

    return customTicks;
  };

  const customTicks = getCustomTicks();

  const formatYAxisTick = (value: number) => `$${value.toFixed(2)}`;

  const pvValues = formattedData.map(item => item.pv);
  const yDomain = [
    Math.floor(Math.min(...pvValues) - 1),
    Math.ceil(Math.max(...pvValues) + 1),
  ];

  const tickCount = 5;
  const tickStep = (yDomain[1] - yDomain[0]) / (tickCount - 1);
  const yTicks = Array.from({ length: tickCount }, (_, index) =>
    Math.round(yDomain[0] + index * tickStep),
  );

  return (
    <LineChart
      width={670}
      height={290}
      data={formattedData}
      margin={{
        top: 5,
        right: 45,
        left: 10,
        bottom: 5,
      }}
    >
      <CartesianGrid horizontal={false} vertical={false} />
      <XAxis
        dataKey="name"
        interval="preserveStartEnd"
        ticks={customTicks}
        style={{ fontSize: '12px' }}
        dx={10}
        dy={10}
        tickFormatter={(value: string) => {
          const [month, day, year] = value.split(' ');
          return `${month} ${day.replace(/,/g, '')}, ${year}`;
        }}
      />
      <YAxis
        domain={yDomain}
        ticks={yTicks}
        tickFormatter={formatYAxisTick}
        style={{ fontSize: '12px' }}
      />
      <Tooltip
        cursor={{ stroke: '#878b93', strokeWidth: 6 }}
        labelFormatter={(value: string) => value}
        formatter={(value: number) => [`$${value}`]}
      />
      <Line
        type="monotone"
        dataKey="pv"
        stroke="#8884d8"
        dot={false}
        strokeWidth={3}
      />
    </LineChart>
  );
}

export default SimpleLineChart;
