// utils/styles/global.ts
import { createGlobalStyle } from 'styled-components';
import { COLORS } from './colors';
import GeneralSansTTF from '../../assets/fonts/GeneralSans-Variable.ttf';
import GeneralSansWOFF from '../../assets/fonts/GeneralSans-Variable.woff';
import GeneralSansWOFF2 from '../../assets/fonts/GeneralSans-Variable.woff2';
import GeneralSansEOT from '../../assets/fonts/GeneralSans-Variable.eot';
import InterVariable from '../../assets/fonts/Inter-Variable.ttf';
import Kallisto from '../../assets/fonts/Kallisto-Medium.ttf';

const GlobalStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
    width: 100%;
    margin: 0;
    font-family: Inter, sans-serif;
    scroll-behavior: smooth;
  }

  body {
    background: linear-gradient(118deg, #fbfbfb 23.61%, #f2f2f2 95.63%);
    transition: text 0.25s ease-in;
  }

  p {
    margin: 0;
  }

  &::-webkit-scrollbar {
    width: 4px !important;
    height: 2px !important;
  }

  &::-webkit-scrollbar-thumb {
    background: ${COLORS.scrollbarThumbColor} !important;
    border-radius: 20px !important;
    margin-bottom: 10px !important;
  }

  &::-webkit-scrollbar-track {
    background: ${COLORS.scrollbarTrackColor} !important;
    border-radius: 20px;
  }

  transition:
    padding cubic-bezier(0.48, 0.65, 0.6, 0.79) 0.5s,
    display cubic-bezier(0.48, 0.65, 0.6, 0.79) 0.5s,
    opacity cubic-bezier(0.48, 0.65, 0.6, 0.79) 0.3s;

  @font-face {
    font-family: "General Sans Variable";
    font-display: block;
    src: url(${GeneralSansTTF}) format('truetype'),
         url(${GeneralSansWOFF}),
         url(${GeneralSansWOFF2}) format('woff'),
         url(${GeneralSansEOT});
    /* Add more src entries for other formats if needed */
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: "Inter";
    font-display: block;
    src: url(${InterVariable}) format('truetype');
    font-style: normal;
  }

  @font-face {
    font-family: "Kallisto";
    font-display: block;
    src: url(${Kallisto}) format('truetype');
    font-style: normal;
  }

  /* ... other styles ... */
`;
export default GlobalStyle;
