import { Grid, Typography } from '@mui/material';
import styled from 'styled-components';
import { shouldForwardProp } from 'utils/forwardProp';

interface TabHeaderProps {
  active?: boolean;
}

export const SuggestedCardContainer = styled(Grid)`
  width: 97%;
  max-height: 100%;
  background: ${props => props.theme.background8};
  border-radius: 8px;
  padding: 16px;
  gap: 16px;
  flex: 1 0 0;
  overflow-y: auto;
  height: 100%;
`;

export const SuggestedCardWrapper = styled('div')`
  position: relative;
  height: 100%;
  overflow: hidden;
`;

export const TabHeaderContainer = styled.div`
  display: flex;
  border-bottom: 1px solid #e4e7ec;
`;

export const TabHeader = styled.div.withConfig({
  shouldForwardProp,
})<TabHeaderProps>`
  margin: 0 4px;
  cursor: pointer;
  color: ${({ active }) => (active ? '#101828' : '#818181')};
  border-bottom: ${({ active }) => (active ? '2px solid #101828' : 'none')};
  padding-bottom: 4px;

  @media (min-width: 600px) {
    margin: 0 10px;
  }

  .tab {
    font-size: 14px;
    color: ${({ active }) => (active ? '#101828' : '#818181')};
  }
  .sm-font {
    font-size: 12px;
  }
`;

export const CardContentContainer = styled(Grid)`
  // display: flex;
  // justify-content: flex-start;
  width: 100%;
  // flex-wrap: wrap;
  // height: 100%;
  //   flex: 1 0 0;
  // margin-top: 5px;
`;
export const CardLabel = styled(Typography)`
  &.MuiTypography-root {
    font-size: 12px;
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: normal;
    text-transform: capitalize;
    position: relative;
    padding-left: 6px;
    color: ${props => props.theme.solidWhite} !important;
  }
`;

export const SuggestedCardBody = styled(Grid)`
  /* display: flex;
  flex-wrap: wrap; */
  /* gap: 16px;
  padding: 10px 0px 8px 0px;
  overflow: hidden; */
  // margin-bottom:50px;
`;
export const ActionCardWrapper = styled(Grid)`
  background: ${props => props.theme.solidWhite};
  border-radius: 8px;
  border: 1px solid var(--neutral-200, ${props => props.theme.borderColor});
  box-sizing: border-box;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.12);
  padding: 12px;
  min-height: '323px';
  cursor: pointer;
  z-index: 999;
`;

export const MessageGrid = styled(Grid)`
  padding: 10px;
  border-radius: 4px;
  background: ${props => props.theme.background9};
  color: #535256;
  font-size: '14px';
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 10px;
  flex-direction: column;
  justify-content: space-between;
`;

export const ConnectGrid = styled(Grid)`
  border-radius: 0px 0px 8px 8px;
  border: 1px solid var(--Neutral-200, #e4e7ec);
  background: #6d6d6d;
  cursor: pointer;
  padding: 10px;
  /* position: absolute; */
  /* top: 0; */
  /* padding: 5px; */
  margin-top: -8px;

  .text {
    color: #fff;
    font-size: 12px;
  }
`;

export const FileName = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding-left: 5px;
  color: var(--Gray-2, #333);
  font-size: 14px;
  padding-top: 16px;
`;

export const ButtonGrid = styled(Grid)`
  border: 1px solid #e4e7ec;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.12);
  border-radius: 4px;
  padding: 8px;
  cursor: pointer;
  .text {
    font-size: 12px;
    color: #333;
    font-weight: 500;
  }
`;

export const ArrowBoxGrid = styled(Grid)`
  border: 1px solid #e4e7ec;
  border-radius: 4px;
  padding: 8px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.12);
  cursor: pointer;
`;
export const OneLineDesc = styled.p`
  color: var(--Gray-2, #333);
  font-size: 14px;
  padding-left: 5px;
  margin-top: 20px;
  font-weight: 400;
  word-spacing: -1px;
`;

export const CurvedButton = styled(Grid)`
  border-radius: 20px;
  border: 1px solid #efefef;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.05);
  padding: 8px;
  cursor: pointer;
  .text {
    font-size: 14px;
    color: #000;
    font-weight: 500;
  }
`;

export const CioCardDescription = styled(Grid)`
  justify-content: space-between;
  flex-direction: column !important;
  flex-wrap: nowrap !important;

  @media (min-width: 500px) {
    flex-direction: row !important;
  }
`;

export const CioCardImg = styled.img`
  width: 100%;
  height: 160px;
  border-radius: 4px;
  @media (min-width: 500px) {
    height: 160px;
  }
`;
