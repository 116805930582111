import instance from 'utils/instances';
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const createAdvisor = async (payload: any) => {
  const createResponse = await instance.post('/advisor/', payload, {
    headers: {
      'x-tifin-ai-auth': localStorage.getItem('x-tifin-ai-token'),
    },
  });
  return createResponse?.data;
};
