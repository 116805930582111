import * as React from 'react';

function PencilIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 18 18" fill="none" {...props}>
      <path
        d="M6.518 15.187H3.375a.562.562 0 01-.563-.562v-3.143a.556.556 0 01.162-.394l8.438-8.437a.563.563 0 01.801 0l3.136 3.136a.562.562 0 010 .801l-8.437 8.438a.557.557 0 01-.394.161v0z"
        stroke="#000"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.563 4.5L13.5 8.438"
        stroke="#101828"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const MemoPencilIcon = React.memo(PencilIcon);
export default MemoPencilIcon;
