import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Grid, useMediaQuery } from '@mui/material';
import PageOverlay from '@components/PageOverlay';
import { actions as profileActions } from '@features/Profile/slice';
import { IsUserLoggedIn } from '@common/authValidate';
import { getAuthDetails } from '@features/Profile/selector';
import sageNew from '@images/sageNew.svg';
import loginImg from '@images/loginImg.svg';
import SageBlackLogo from '@icons/sage_logo_black';
import {
  FormWrapper,
  LoginFormWrapper,
  LoginHeaderText,
  LoginHeaderTextWrapper,
  LoginSubHeaderText,
  LoginWrapper,
  LogoWrapper,
  StockImg,
} from './styles';
import { config } from '@services/oktaAuth';
import { Tokens } from '@okta/okta-auth-js';
import OktaSigninWidget from './OktaSigninwidget';
import { useSelector } from 'react-redux';
import { useMutation } from 'react-query';
import { recordLoginMutate } from './mutation';

function LoginV3(): React.JSX.Element {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const profileState: any = useSelector(getAuthDetails);
  const recordLoginMutation = useMutation(recordLoginMutate);
  const isLoggedIn: boolean = IsUserLoggedIn({
    apiToken: localStorage.getItem('x-tifin-ai-token') as string,
  });

  useEffect(() => {
    if (isLoggedIn && profileState?.role) {
      if (
        profileState?.role?.length > 0 &&
        (profileState.termsAndConditionsAcceptedAt === '' ||
          profileState.termsAndConditionsAcceptedAt === null)
      ) {
        localStorage.setItem('onboarding-state', '1');
        navigate('/terms-condition');
      } else {
        if (profileState.role === 'ADVISOR') {
          navigate('/conversations');
        } else if (profileState.role === 'FIRM_ADMIN') {
          navigate('/conversations');
        } else if (profileState.role === 'SUPER_ADMIN') {
          navigate('/admin-console');
        } else if (profileState.role === 'ENTERPRISE_ADMIN') {
          navigate('/firms');
        }
      }
    }
  }, [isLoggedIn, profileState]);

  const onSuccess = (tokens: Tokens) => {
    console.log('tokens--->', tokens);
    const { accessToken } = tokens;
    localStorage.setItem(
      'x-tifin-ai-token',
      `Bearer ${accessToken?.accessToken}`,
    );
    localStorage.setItem('tifin-ai-isLoggedIn', '1');
    localStorage.setItem('onboarding-state', '0');
    if (accessToken?.accessToken) {
      dispatch(
        profileActions.userInfoRequest({
          token: `Bearer ${accessToken?.accessToken}`,
        }),
      );
      recordLoginMutation.mutate();
    }
  };

  const onError = (error: any) => {
    console.log('Sign in error:', error);
  };

  const isMobile = useMediaQuery('(max-width:670px)');

  return (
    <LoginWrapper
      container
      justifyContent={'center'}
      sx={{
        left: 0,
        right: 0,
      }}
    >
      {!isMobile && (
        <Grid
          width="50%"
          height="100%"
          position={'relative'}
          top={0}
          left={0}
          zIndex={3}
        >
          <Grid
            width="100%"
            height="100%"
            position={'relative'}
            top={0}
            left={0}
          >
            <StockImg alt="Login image" src={loginImg} />
          </Grid>
          <Grid
            position={'absolute'}
            width={'auto'}
            top={'42%'}
            bottom={0}
            left={'33%'}
            right={0}
            margin={'0 auto'}
          >
            <SageBlackLogo fill={'#fff'} width={'50%'} />
          </Grid>
        </Grid>
      )}

      <LoginFormWrapper position={'relative'} zIndex={2}>
        <FormWrapper
          item
          container
          sx={{
            margin: '0 auto',
            height: 'auto',
          }}
        >
          <LogoWrapper item xs={12}>
            <img alt="Sage login image" src={sageNew} />
          </LogoWrapper>
          <LoginHeaderTextWrapper
            item
            xs={12}
            sx={{
              flexDirection: 'column',
            }}
          >
            <LoginHeaderText>Welcome To Sage</LoginHeaderText>
            <LoginSubHeaderText>Please enter your details </LoginSubHeaderText>
          </LoginHeaderTextWrapper>

          <OktaSigninWidget
            config={config.widget}
            onSuccess={onSuccess}
            onError={onError}
          />
        </FormWrapper>
        <PageOverlay />
      </LoginFormWrapper>
    </LoginWrapper>
  );
}

export default LoginV3;
