import { Grid, Typography } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import { actions } from '@features/Chat/slice';
import { Box } from '@mui/system';
import MemoAnimator from '@components/UIComponents/MemoAnimator';

type Suggestions = {
  chatId?: any;
  data: any;
};

type QuestionType = {
  query: string;
};

const Question = ({ query }: QuestionType): React.JSX.Element => {
  const isChatStreaming = localStorage.getItem('streaming') as string;

  const dispatch = useDispatch();
  const loadQuestions = () => {
    if (isChatStreaming === 'true') {
      dispatch(actions.setSearchQuery(query));
    } else {
      dispatch(actions.searchQueryRequest({ query }));
    }
  };
  return <MemoAnimator onClick={loadQuestions} text={query} />;
};

const SuggestedQuestions = ({
  chatId = '',
  data = [],
}: Suggestions): React.JSX.Element => {
  return (
    <Box width={'100%'} key={chatId}>
      <Grid
        pt={'14px'}
        borderTop={'6px solid #d5d5d533'}
        rowGap={1}
        direction={'column'}
        container
      >
        <Typography color={'black'} fontSize={'12px'} fontWeight={'500'}>
          Suggested Follow-ups
        </Typography>
        {data.length > 1 ? (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '6px' }}>
            {data?.map((q: string, i: number) => (
              <Question key={i} query={q} />
            ))}
          </Box>
        ) : (
          data?.map((q: string, i: number) => <Question key={i} query={q} />)
        )}
      </Grid>
    </Box>
  );
};

export default SuggestedQuestions;
