/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */

// store/reducers.ts
import { combineReducers } from '@reduxjs/toolkit';
import { all } from 'redux-saga/effects';

import { reducer as chatReducer } from '@features/Chat/slice';
import { reducer as globalReducer } from '@features/Global/slice';
import { reducer as profileReducer } from '@features/Profile/slice';
import { reducer as QueryReducer } from '@features/Queries/slice';
import { chatSaga } from '@features/Chat/saga';
import { globalSaga } from '@features/Global/saga';
import { profileSaga } from '@features/Profile/saga';
import { queriesSaga } from '@features/Queries/saga';

export const rootReducer = combineReducers({
  chat: chatReducer,
  global: globalReducer,
  profile: profileReducer,
  queries: QueryReducer,
  // ... add other reducers as needed
});

export function* rootSaga() {
  yield all([
    chatSaga(),
    globalSaga(),
    profileSaga(),
    queriesSaga(),
    // ... add other sagas
  ]);
}

export type RootState = ReturnType<typeof rootReducer>;
