import styled from 'styled-components';
import { Box } from '@mui/material';

export const FullScreen = styled(Box)`
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const FadingBox = styled(Box)`
  transition: opacity 0.75s ease-in-out;
  position: absolute;
`;
