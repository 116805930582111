/* eslint-disable react/prop-types */

import * as React from 'react';

function WarningRed(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <g>
        <path
          stroke="#E11D48"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M12 9.75v3.75"
        ></path>
        <path
          stroke="#E11D48"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M10.706 3.75L2.456 18a1.5 1.5 0 001.294 2.25h16.5A1.5 1.5 0 0021.544 18l-8.25-14.25a1.49 1.49 0 00-2.588 0v0z"
        ></path>
        <path
          fill="#E11D48"
          d="M12 18a1.125 1.125 0 100-2.25A1.125 1.125 0 0012 18z"
        ></path>
      </g>
    </svg>
  );
}

const MemoWarningRed = React.memo(WarningRed);
export default MemoWarningRed;
