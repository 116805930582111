import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProfileState, AuthDetailsProps } from './types';

export const initialState: ProfileState = {
  /* Common State */
  authDetails: {
    email: '',
    role: '',
    advisoryFirmId: '',
    advisoryFirmName: '',
    enterpriseId: '',
    advisorId: '',
    firstName: '',
    lastName: '',
    oktaUid: '',
    expertMode: false,
    address: '',
    city: '',
    state: '',
    zipcode: '',
    profilePicUrl: '',
    termsAndConditionsAcceptedAt: '',
    summary: {
      id: '',
      noOfClients: 0,
      recentlyViewed: [
        {
          id: '',
          name: '',
        },
      ],
      totalAum: 0,
    },
  },
  loading: false,
};

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    updateProfile(state, action: PayloadAction<AuthDetailsProps>) {
      state.authDetails = action.payload;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    userInfoRequest(state, action: PayloadAction<any>) {},

    userInfoFetch(state) {
      state.loading = true;
    },

    userInfoSuccess(state, action: PayloadAction<AuthDetailsProps>) {
      state.loading = false;
      state.authDetails = action.payload;
    },

    updateUserExpertState(
      state,
      action: PayloadAction<{ expertMode: boolean }>,
    ) {
      state.authDetails.expertMode = action.payload.expertMode;
    },
    updateTermsAndConditionState(
      state,
      action: PayloadAction<{ termsAndConditionsAcceptedAt: string }>,
    ) {
      state.authDetails.termsAndConditionsAcceptedAt =
        action.payload.termsAndConditionsAcceptedAt;
    },
    updateAddress(
      state,
      action: PayloadAction<{
        address: string;
        zipcode: string;
        state: string;
        city: string;
      }>,
    ) {
      state.authDetails.address = action.payload.address;
      state.authDetails.zipcode = action.payload.zipcode;
      state.authDetails.state = action.payload.state;
      state.authDetails.city = action.payload.city;
    },
    addToRecentlyViewed(
      state,
      action: PayloadAction<{ id: string; name: string }>,
    ) {
      const { id, name } = action.payload;
      const { recentlyViewed } = state.authDetails.summary;

      // Find index of existing item
      const existingIndex = recentlyViewed.findIndex(item => item.id === id);

      let updatedRecentlyViewed;
      if (existingIndex > -1) {
        // Move existing item to the top
        updatedRecentlyViewed = [
          { id, name },
          ...recentlyViewed.filter((_, index) => index !== existingIndex),
        ];
      } else {
        // Add new item to the top
        updatedRecentlyViewed = [{ id, name }, ...recentlyViewed];
      }

      // Update recentlyViewed
      state.authDetails.summary.recentlyViewed = updatedRecentlyViewed.slice(
        0,
        5,
      );
    },
  },
});

export const { actions, reducer, name: sliceKey } = profileSlice;
