import { Button, Grid } from '@mui/material';
import styled, { css, keyframes } from 'styled-components';
import { LayoutSize } from 'utils/styles/variables';
import {
  AnimatedContainerProps,
  AnimateWrapperProps,
  StyleAnimatedContainerProps,
} from './types';
import { isDesktopHandler } from '@common/string';
import { shouldForwardProp } from 'utils/forwardProp';

export const AnimationName = 'ease-out';
export const AnimationTime = '0.5s';
const HeightAnimationTime = '1s';

export const RightToLeftAnimation = keyframes`
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
`;

export const LeftToRightAnimation = keyframes`
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
`;

export const LayoutContainer = styled(Grid)`
  width: 100%;
  height: 100%;
  z-index: 1;
  // background: linear-gradient(118deg, #fbfbfb 23.61%, #f2f2f2 95.63%);
`;

export const LayoutWrapper = styled(Grid).withConfig({
  shouldForwardProp,
})<AnimateWrapperProps>`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  height: 100%;

  ${({ animate }: AnimatedContainerProps) =>
    animate &&
    css`
      transition: opacity ${AnimationName} ${HeightAnimationTime};
    `}
`;

export const LeftSideContainer = styled(Grid).withConfig({
  shouldForwardProp,
})<{ isDesktopDevice?: boolean; collapse?: boolean }>`
  width: ${({ isDesktopDevice }) =>
    `${isDesktopHandler(isDesktopDevice)?.sidebar?.expandWidth}px`};
  border-right: 1.5px solid rgba(168, 180, 186, 0.4);
  // backdrop-filter: blur(12px);
  transition: width ${AnimationName} ${AnimationTime};
  background: linear-gradient(180deg, #ffc38b 0%, #ff8c5a 100%);

  @media (min-width: 900px) {
    ${({ collapse, isDesktopDevice }: any) =>
      collapse &&
      css`
        width: ${isDesktopHandler(isDesktopDevice)?.sidebar?.collapseWidth}px;
      `}
  }
`;

export const RightSideContainer = styled(Grid).withConfig({
  shouldForwardProp,
})<{ isDesktopDevice?: boolean; collapse?: boolean }>`
  width: ${({ isDesktopDevice }) =>
    `calc(100% - ${isDesktopHandler(isDesktopDevice)?.sidebar?.expandWidth}px)`};
  transition: width ${AnimationName} ${AnimationTime};
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(12px);

  @media (min-width: 900px) {
    ${({ collapse, isDesktopDevice }: any) =>
      collapse &&
      css`
        width: calc(
          100% - ${isDesktopHandler(isDesktopDevice)?.sidebar?.collapseWidth}px
        );
      `}
  }
`;

export const TermsConditionSideContainer = styled(Grid).withConfig({
  shouldForwardProp,
})<any>`
  width: 100%;
  transition: width ${AnimationName} ${AnimationTime};
  background: #f8f2ed;
  backdrop-filter: blur(12px);
  margin: 0 auto;

  ${({ collapse }: any) =>
    collapse &&
    css`
      width: 100%;
    `}
`;

export const HeaderContainer = styled(Grid).withConfig({
  shouldForwardProp,
})<AnimatedContainerProps>`
  width: 100%;
  height: 105px;
  padding: ${({ isDesktopDevice }) =>
    `${isDesktopHandler(isDesktopDevice)?.y} 0 1rem ${isDesktopHandler(isDesktopDevice)?.y}`};
  background-color: ${({ theme }) => theme.backgroundShade};

  @media (min-width: 600px) {
    height: ${LayoutSize.headerHeght};
  }

  svg {
    width: auto;
    max-width: 460px;
    height: 20%;
    min-height: 36px;
    max-height: 165px;
  }

  ${({ animate }: AnimatedContainerProps) =>
    animate &&
    css`
      // height: ${LayoutSize.headerHeght};
      transition:
        display ${AnimationName} ${AnimationTime},
        height ${AnimationName} ${HeightAnimationTime},
        opacity ${AnimationName} 0.8s;
    `}
`;

export const ContentWrapper = styled(Grid).withConfig({
  shouldForwardProp,
})<StyleAnimatedContainerProps>`
  width: 100%;
  height: calc(90vh - 105px);
  padding: ${({ isDesktopDevice }) =>
    `0 0 0 ${isDesktopHandler(isDesktopDevice)?.x}`};
  margin: 0 auto;

  @media (min-width: 600px) {
    height: ${LayoutSize.contentHeight};
  }

  ${({ animate, navstate }: StyleAnimatedContainerProps) =>
    animate &&
    css`
      animation: 0.4s
        ${navstate === 'Prev' ? LeftToRightAnimation : RightToLeftAnimation};
      transition: animation cubic-bezier(0.48, 0.65, 0.6, 0.79) ${AnimationTime};
    `}
`;

export const LayoutPageContainer = styled(Grid).withConfig({
  shouldForwardProp,
})<StyleAnimatedContainerProps>`
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;

  ${({ animate, navstate }: StyleAnimatedContainerProps) =>
    animate &&
    css`
      animation: 0.4s
        ${navstate === 'Prev' ? LeftToRightAnimation : RightToLeftAnimation};
      transition: animation cubic-bezier(0.48, 0.65, 0.6, 0.79) ${AnimationTime};
    `}
`;

export const FooterContainer = styled(Grid).withConfig({
  shouldForwardProp,
})<{ isDesktopDevice?: boolean }>`
  width: 100%;
  height: ${LayoutSize.footerHeight};
  padding: ${({ isDesktopDevice }) =>
    `0 ${isDesktopHandler(isDesktopDevice)?.x}`};
  // border-top: 1.5px solid ${props => props.theme.borderColor};
`;

export const UploadButtonContainer = styled(Grid)`
  display: flex;
  align-items: center;
  margin-right: 40px;
  margin-left: 1rem;
`;

export const Container = styled(Grid)`
  width: 100%;
  display: flex;
  border-radius: 8px;
  gap: 16px;
  height: 320px;
`;

export const UploadButton = styled(Button)`
  border-radius: 4px;
  background: #182e4b;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  padding: 10px;
  height: 2rem;
`;

export const CancelButton = styled(Button)`
  border-radius: 4px;
  border: 1px solid #182e4b;
  color: #182e4b;
  height: 2rem;
  font-size: 14px;
  font-weight: 500;
  width: 100px;
`;

export const ButtonParent = styled(Grid)`
  display: flex;
  justify-content: space-between;
  gap: 4px;
  @media (max-width: 1199px) {
    flex-direction: column;
    align-items: center;
  }
  gap: 8px;
`;

export const SplashOVerlay = styled('div')`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  // padding: 110px 120px 0;
`;
