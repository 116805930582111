import styled from 'styled-components';

export const SuccessIconWrapper = styled.div`
  width: 100%;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

export const SuccessMsgWrapper = styled.div`
  color: #475467;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  margin-top: 20px;
`;

export const SuccessModalBtnWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
`;

export const SuccessModalContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  width: 400px;
`;
