import { Box, Grid } from '@mui/material';
import React, { useState } from 'react';
import { actions } from '@features/Chat/slice';

import MemoMagic from '@icons/Magic';
import Magic from '@images/Magic.svg';
import {
  MemoSpinnerContainer,
  QuestionCategoryContainer,
  QuestionsCardText,
} from './styles';
import { useDispatch } from 'react-redux';
import {
  getChatInitResponseState,
  getSampleQuestionsState,
} from '@features/Chat/selector';
import { useSelector } from 'react-redux';
import MemoAnimator from '@components/UIComponents/MemoAnimator';
import { useNavigate } from 'react-router-dom';
import { EQuestionToNavigate } from 'app/enums';
import { getConvertedQuestion } from '@pages/Chat/NewHomePage';

export const MemoSpinner = ({
  hover,
}: {
  hover: boolean;
}): React.JSX.Element => {
  return (
    <MemoSpinnerContainer>
      {hover ? (
        <div className="memo-icon">
          <MemoMagic height={16} width={16} />
        </div>
      ) : (
        <img src={Magic} alt="Magic image" />
      )}
    </MemoSpinnerContainer>
  );
};
export const WhatElseButton = (): React.JSX.Element => {
  const dispatch = useDispatch();

  const { chatHistory: initHistoryState } = useSelector(
    getChatInitResponseState,
  );
  const { sampleQuestions: questionsData } = useSelector(
    getSampleQuestionsState,
  );

  const loadQuestions = () => {
    dispatch(actions.searchSampleQuestion({ isSampleQuestionAsked: false }));
    dispatch(
      actions.loadChatSuccess({
        chatHistory: [
          ...initHistoryState,
          {
            data: 'Display Suggested Questions',
            isSender: true,
            time: '',
            id: '',
            type: 'text',
            isChatHistory: true,
            mock: true,
          },
          {
            id: '65b8f06d84d66bb8215d3173',
            isSender: false,
            time: '',
            isShowFeedback: false,
            isChatHistory: false,
            mock: true,

            data: questionsData,
          },
        ],
      }),
    );
  };
  return (
    <MemoAnimator onClick={loadQuestions} text={'What else can I ask Sage?'} />
  );
};

export const SampleQuestions = ({
  data,
  hideWhatElse = false,
}: {
  data: any;
  hideWhatElse?: boolean;
}): React.JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [viewAll, setViewAll] = useState<boolean>(data.length <= 5); //kept it for future use, removed only for 5 items
  const isChatStreaming = localStorage.getItem('streaming') as string;

  const triggerQuery = (query: string) => {
    query = getConvertedQuestion(query);
    switch (true) {
      case query === EQuestionToNavigate.WhichViewpointsImpactClients:
        navigate('/actions');
        return;
      default:
        break;
    }

    // If not navigating
    if (isChatStreaming === 'true') {
      dispatch(actions.setSearchQuery(query));
    } else {
      dispatch(actions.searchQueryRequest({ query }));
      // this ensures that regardless of the streaming status (true or not), the logging or recording of sample questions is consistently executed whenever a question is asked
      dispatch(
        actions.searchSampleQuestion({
          isSampleQuestionAsked: true,
          question: query,
        }),
      );
    }
  };
  const viewMore = () => {
    setViewAll(!viewAll);
  };
  const Question = ({ listItem }: any) => {
    return (
      <MemoAnimator
        onClick={() => listItem && triggerQuery(listItem.head)}
        text={listItem.head}
      />
    );
  };
  return (
    <QuestionCategoryContainer>
      {
        <Grid item rowGap={1} xs={12}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              columnGap: '10px',
            }}
          >
            {data?.map((listItem, index) => {
              if (!viewAll && index > 3) {
                return <></>;
              }
              return <Question key={index} listItem={listItem} />;
            })}
          </Box>
          {!viewAll && (
            <Grid lg={12} mt={1.5} item>
              <QuestionsCardText
                className="viewMoreButton"
                onClick={() => viewMore()}
              >
                View More
              </QuestionsCardText>
            </Grid>
          )}
          {!hideWhatElse && viewAll && (
            <Box className="whatElseButton">
              <WhatElseButton />
            </Box>
          )}
        </Grid>
      }
    </QuestionCategoryContainer>
  );
};

function SampleCategories({ data }: { data: any }): React.JSX.Element {
  const dispatch = useDispatch();
  const { chatHistory: initHistoryState } = useSelector(
    getChatInitResponseState,
  );

  const getQuestions = (category: any) => {
    dispatch(
      actions.loadChatSuccess({
        chatHistory: [
          ...initHistoryState.slice(0, initHistoryState.length - 1),

          {
            id: '65b8f06d84d66bb8215d3173',
            isSender: false,
            time: '',
            isShowFeedback: false,
            isChatHistory: false,
            mock: true,
            data: [
              {
                type: 'text',
                content: 'Here you go! Select a prompt below.',
              },
              {
                type: 'sample_questions',
                content: category?.list.map(question => {
                  return {
                    head: question,
                  };
                }),
              },
            ],
          },
        ],
      }),
    );
  };

  const QuestionCategory = ({ listItem }: any) => {
    return (
      <MemoAnimator
        onClick={() => listItem && getQuestions(listItem)}
        text={listItem.head}
      />
    );
  };
  return (
    <QuestionCategoryContainer>
      {
        <Grid item container columnGap={1} xs={12}>
          {data?.map((listItem, index) => {
            return <QuestionCategory key={index} listItem={listItem} />;
          })}
        </Grid>
      }
    </QuestionCategoryContainer>
  );
}

export default SampleCategories;
