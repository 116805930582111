import instance from 'utils/instances';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const connectRedTail = async (payload: any) => {
  const createResponse = await instance.post(
    '/integrations/crm/redtail/authenticate',
    payload,
    {
      headers: {
        'x-tifin-ai-auth': localStorage.getItem('x-tifin-ai-token'),
        'content-type': undefined,
      },
    },
  );
  return createResponse?.data;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const syncRedtail = async (payload: any) => {
  const createResponse = await instance.post(
    '/integrations/crm/redtail/sync',
    payload,
    {
      headers: {
        'x-tifin-ai-auth': localStorage.getItem('x-tifin-ai-token'),
        'content-type': undefined,
      },
    },
  );
  return createResponse?.data;
};

export const syncWealthbox = async (payload: { id: string }): Promise<any> => {
  const createResponse = await instance.post(
    '/integrations/crm/wealthbox/sync',
    payload,
    {
      headers: {
        'x-tifin-ai-auth': localStorage.getItem('x-tifin-ai-token'),
        'content-type': undefined,
      },
    },
  );
  return createResponse?.data;
};

export const syncSalesforce = async (payload: { id: string }): Promise<any> => {
  const createResponse = await instance.post(
    '/integrations/crm/salesforce/sync',
    payload,
    {
      headers: {
        'x-tifin-ai-auth': localStorage.getItem('x-tifin-ai-token'),
        'content-type': undefined,
      },
    },
  );
  return createResponse?.data;
};

// hubspot sync
export const syncHubSpot = async (payload: { id: string }): Promise<any> => {
  const createResponse = await instance.post(
    '/integrations/crm/hubspot/sync',
    payload,
    {
      headers: {
        'x-tifin-ai-auth': localStorage.getItem('x-tifin-ai-token'),
        'content-type': undefined,
      },
    },
  );
  return createResponse?.data;
};

export const disconnectRedtail = async (payload: {
  id: string;
}): Promise<any> => {
  const createResponse = await instance.put(
    '/integrations/crm/redtail/disconnect',
    payload,
    {
      headers: {
        'x-tifin-ai-auth': localStorage.getItem('x-tifin-ai-token'),
        'content-type': undefined,
      },
    },
  );
  return createResponse?.data;
};

export const disconnectHubspot = async (payload: {
  id: string;
}): Promise<any> => {
  const createResponse = await instance.put(
    '/integrations/crm/hubspot/disconnect',
    payload,
    {
      headers: {
        'x-tifin-ai-auth': localStorage.getItem('x-tifin-ai-token'),
        'content-type': undefined,
      },
    },
  );
  return createResponse?.data;
};

export const disconnectWealthbox = async (payload: {
  id: string;
}): Promise<any> => {
  const createResponse = await instance.put(
    '/integrations/crm/wealthbox/disconnect',
    payload,
    {
      headers: {
        'x-tifin-ai-auth': localStorage.getItem('x-tifin-ai-token'),
        'content-type': undefined,
      },
    },
  );
  return createResponse?.data;
};

export const disconnectSalesforce = async (payload: {
  id: string;
}): Promise<any> => {
  const createResponse = await instance.put(
    '/integrations/crm/salesforce/disconnect',
    payload,
    {
      headers: {
        'x-tifin-ai-auth': localStorage.getItem('x-tifin-ai-token'),
        'content-type': undefined,
      },
    },
  );
  return createResponse?.data;
};

export const generateDocuSign = async (payload: {
  integrationId: string;
}): Promise<any> => {
  const response = await instance.post(
    'integrations/custodians/docusign',
    payload,
    {
      headers: {
        'x-tifin-ai-auth': localStorage.getItem('x-tifin-ai-token'),
        'content-type': undefined,
      },
    },
  );
  return response?.data;
};

export const generateDirectionLetter = async (payload: {
  integrationId: string;
}): Promise<any> => {
  const response = await instance.post(
    '/integrations/custodians/direction-letter',
    payload,
    {
      headers: {
        'x-tifin-ai-auth': localStorage.getItem('x-tifin-ai-token'),
        'content-type': undefined,
      },
    },
  );
  return response?.data;
};
