import React, { useCallback, useEffect, useState } from 'react';
import Divider from '@mui/material/Divider';
import { actions as globalActions } from 'app/features/Global/slice';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import upSvg from '@images/up.svg';
import CloseIcon from '@mui/icons-material/Close';
import downArrowSvg from '@images/downArrow.svg';
import SimpleLineChart from '@components/Chart/LineChart';
import {
  BackArrowWrapper,
  CategoryPercentage,
  CurrentPageName,
  CurrentPercentage,
  DataContainer,
  DescPara,
  DescText,
  DescriptionDivWrapper,
  DrawerWrapper,
  FundBoxHeader,
  FundBoxSubHeader,
  FundDataName,
  FundValue,
  HeaderAnalyticDataDiv,
  HeaderDivWrapper,
  HeaderSection,
  KeyStatisticsDataWrapper,
  LineGraphContainer,
  PercentageDataContainer,
  PrevPageName,
  PriceData,
  ReturnsDiv,
  StepperContainer,
  StyledDivider,
  StyledWrapper,
  SubText,
  TickerDataWrapper,
  TickerDrawerContainer,
  TickerName,
  TickerOrgName,
  TitleText,
} from '../../components/ChatContent/styles';

import { useQuery } from 'react-query';
import {
  fetchData,
  fetchMarketPriceData,
} from '../../components/ChatContent/mutations';
import CustomLinearProgress from '@components/Chart/LinearProgressBar';
import { LoaderContainer } from '@pages/Signup/styles';
import { Spinner } from '@pages/IntegrationPage/styles';
import { useDispatch } from 'react-redux';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MemoBackArrowIcon from '@icons/BackArrow';
import { Box, Grid, useMediaQuery, useTheme } from '@mui/material';
import { StockDetailsProps, keyStatsPeriodOptions } from '@common/tickerDrawer';
import {
  fetchAndSetKeyStatsData,
  getChartsData,
} from '@features/apis/tickerDrawer';

function TickerDrawer({
  selectedTicker = '',
  setSelectedTicker,
  setStepper,
  portfolioName,
  setAdjustmentStepper,
  showStepper = false,
  onClose,
}: StockDetailsProps): JSX.Element | null {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();

  const [selectedOption, setSelectedOption] = useState('M3');
  const [selectedGraphDropDownOption, setSelectedGraphDropDownOption] =
    useState('M3');
  const [selectedReturnDropDownOption, setSelectedReturnDropDownOption] =
    useState('Cumulative Return');
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [selectedDropDownOption, setSelectedDropDownOption] = useState('');

  const handleReturnDropDownOptionChange = useCallback(event => {
    setSelectedReturnDropDownOption(event.target.value);
  }, []);

  const { data, isLoading, isError } = useQuery(
    ['magnifiData', selectedTicker],
    () => fetchData({ ticker: selectedTicker }),
    {
      enabled: !!selectedTicker,
    },
  );

  const { data: priceData } = useQuery(
    ['priceData', selectedTicker],
    () => fetchMarketPriceData({ ticker: selectedTicker }),
    {
      enabled: !!selectedTicker,
    },
  );

  const { data: chartsData } = useQuery(
    ['getChartsData', selectedTicker, selectedGraphDropDownOption],
    () => getChartsData(selectedTicker, selectedGraphDropDownOption),
    {
      enabled: !!selectedTicker,
    },
  );

  const { data: keyStatsData } = useQuery(
    ['fetchAndSetKeyStatsData', selectedTicker, selectedOption],
    () => fetchAndSetKeyStatsData(selectedTicker, selectedOption),
    {
      enabled: !!selectedTicker,
    },
  );

  const initialDescriptionLimit = 353;
  const shortDescription = data?.description?.slice(0, initialDescriptionLimit);

  useEffect(() => {
    const defaultOptionFromAPI = keyStatsData?.data?.[1]?.subItems?.[0]?.title;
    if (defaultOptionFromAPI) {
      setSelectedDropDownOption(defaultOptionFromAPI);
    }
  }, [keyStatsData]);

  const handleGraphDropdownOptionChange = useCallback(event => {
    setSelectedGraphDropDownOption(event.target.value);
  }, []);

  const handleOptionChange = useCallback(event => {
    setSelectedOption(event.target.value);
  }, []);

  const handleDropDownOptionChange = event => {
    setSelectedDropDownOption(event.target.value);
  };

  useEffect(() => {
    if (isLoading || data) return;

    if (isError) {
      setAdjustmentStepper?.('PORTFOLIO_COMPARISON');
      // FIX: breadcrumbs need handled gracefully in future maybe with query params
      // setStepper?.(prev =>
      //   prev === 'PORTFOLIO_DETAILS_PAGE'
      //     ? 'CLIENT_DRAWER_PAGE'
      //     : 'PORTFOLIO_DETAILS_PAGE',
      // );
      setStepper?.('CLIENT_DRAWER_PAGE');
      dispatch(
        globalActions.displayToast({
          duration: 3000,
          toastType: 'error',
          toastMessage: "Ticker doesn't exist",
        }),
      );
      setSelectedTicker('');
    }
  }, [data, isLoading, isError]);

  return (
    <>
      {!isLoading ? (
        <TickerDrawerContainer
          padding={showStepper ? '0 20px 0px 0px' : '0px 20px 20px'}
          width={showStepper ? '97%' : '94%'}
        >
          {isMobile && (
            <div
              className="close-container"
              onClick={() => onClose && onClose()}
            >
              <CloseIcon />
            </div>
          )}

          {showStepper && setStepper && (
            <StepperContainer
              onClick={() => {
                if (setAdjustmentStepper) {
                  setAdjustmentStepper('PORTFOLIO_COMPARISON');
                }
                // FIX: breadcrumbs need handled gracefully in future maybe with query params
                // setStepper(prev =>
                //   prev === 'PORTFOLIO_DETAILS_PAGE'
                //     ? 'CLIENT_DRAWER_PAGE'
                //     : 'PORTFOLIO_DETAILS_PAGE',
                // );
                setStepper('CLIENT_DRAWER_PAGE');
              }}
            >
              <BackArrowWrapper>
                <MemoBackArrowIcon
                  stroke="#667085"
                  strokeWidth="3"
                  height="20px"
                />
              </BackArrowWrapper>
              <PrevPageName>
                <p>{portfolioName}</p>
              </PrevPageName>
              <StyledDivider>/</StyledDivider>
              <CurrentPageName>
                <p>{data?.name}</p>
              </CurrentPageName>
            </StepperContainer>
          )}
          <DrawerWrapper>
            <HeaderDivWrapper>
              <HeaderSection>
                <TickerDataWrapper>
                  <TickerName>{selectedTicker}</TickerName>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {data?.logo && <img src={data.logo} alt="Logo image" />}
                  </div>
                </TickerDataWrapper>
                <TickerOrgName>{data?.name}</TickerOrgName>
              </HeaderSection>
              {priceData && (
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <PriceData>{`$${priceData?.Prices[0]?.Price.toFixed(2)}`}</PriceData>
                  <HeaderAnalyticDataDiv>
                    <img
                      src={
                        priceData?.Prices[0]?.Diff > 0 ? upSvg : downArrowSvg
                      }
                      alt={
                        priceData?.Prices[0]?.Diff > 0
                          ? 'Upward image'
                          : 'Down arrow image'
                      }
                      style={{ marginRight: '5px' }}
                    />
                    <Box
                      sx={{
                        color:
                          priceData?.Prices[0]?.Diff > 0
                            ? '#00b186'
                            : '#e00000',
                      }}
                    >
                      {`${priceData?.Prices[0]?.Diff.toFixed(2)}`}
                    </Box>
                    <Box
                      sx={{
                        color:
                          priceData?.Prices[0]?.Change > 0
                            ? '#00b186'
                            : '#e00000',
                      }}
                    >
                      {`(${priceData?.Prices[0]?.Change.toFixed(2)}%)`}
                    </Box>
                  </HeaderAnalyticDataDiv>
                </Box>
              )}
            </HeaderDivWrapper>
            <Divider
              style={{
                width: '100%',
                border: '0.5px solid #e4e7ec',
                marginBottom: '20px',
              }}
            />
            <DescriptionDivWrapper>
              <DescText>Description</DescText>
              <DescPara>
                {showFullDescription ? (
                  <span>
                    {data?.description}
                    <span
                      style={{
                        cursor: 'pointer',
                        fontWeight: 600,
                        fontSize: '14px',
                        color: '#000',
                      }}
                      onClick={() =>
                        setShowFullDescription(!showFullDescription)
                      }
                    >
                      &nbsp;See less
                    </span>
                  </span>
                ) : (
                  <span>
                    {shortDescription}
                    {data?.description?.length > initialDescriptionLimit && (
                      <span
                        style={{
                          cursor: 'pointer',
                          fontWeight: 600,
                          fontSize: '14px',
                          color: '#000',
                        }}
                        onClick={() =>
                          setShowFullDescription(!showFullDescription)
                        }
                      >
                        &nbsp;... See more
                      </span>
                    )}
                  </span>
                )}
              </DescPara>
            </DescriptionDivWrapper>

            <Divider
              style={{
                width: '100%',
                border: '0.5px solid #e4e7ec',
                marginBottom: '20px',
              }}
            />
            <Grid container>
              <Grid
                item
                container
                justifyContent={'space-between'}
                alignItems={'center'}
              >
                <Grid item>Key Statistics</Grid>
                <Grid item>
                  <Select
                    value={selectedOption}
                    onChange={handleOptionChange}
                    displayEmpty
                    IconComponent={KeyboardArrowDownIcon}
                    inputProps={{ 'aria-label': 'Without label' }}
                    sx={{
                      height: '30px',
                      minWidth: 100,
                      fontSize: '14px',
                      color: '#101828',
                      '& .MuiSelect-icon': {
                        minHeight: '27px',
                        color: '#000',
                        marginTop: '-2px',
                      },
                    }}
                  >
                    {keyStatsPeriodOptions.map(({ label, value }, index) => (
                      <MenuItem
                        key={index}
                        value={value}
                        sx={{ fontSize: '14px', color: '#101828' }}
                      >
                        {label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
              <Grid
                item
                container
                alignItems={'center'}
                columnSpacing={4}
                pt={2.5}
              >
                {keyStatsData?.data?.map((item, index) => {
                  return (
                    <Grid
                      item
                      container
                      key={index}
                      justifyContent={'space-between'}
                      alignItems={'center'}
                      xs={12}
                      md={4}
                    >
                      <Grid
                        item
                        container
                        justifyContent={'space-between'}
                        alignItems={'center'}
                      >
                        <Grid item>
                          <TitleText>{item.title}</TitleText>
                        </Grid>
                        {/* Check if there are two subItems for keyStatsData.data[0] */}
                        {index === 0 &&
                          item?.subItems &&
                          item.subItems.length === 2 && (
                            <Grid>
                              <FormControl sx={{ minWidth: 160 }}>
                                <Select
                                  value={selectedReturnDropDownOption}
                                  onChange={handleReturnDropDownOptionChange}
                                  displayEmpty
                                  IconComponent={KeyboardArrowDownIcon}
                                  inputProps={{
                                    'aria-label': 'Without label',
                                  }}
                                  sx={{
                                    height: '25px',
                                    marginTop: '-10px',
                                    fontSize: '12px',
                                    '& .MuiSelect-icon': {
                                      minHeight: '27px',
                                      color: '#000',
                                      marginTop: '-2px',
                                    },
                                    '& .MuiOutlinedInput-root': {
                                      border: 'none',
                                      '&:hover': {
                                        border: 'none',
                                      },
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                      border: 'none',
                                    },
                                    boxShadow: 'none',
                                  }}
                                >
                                  {item?.subItems?.map((subItem, subIndex) => (
                                    <MenuItem
                                      key={subIndex}
                                      value={subItem?.title}
                                      sx={{ fontSize: '13px' }}
                                    >
                                      {subItem?.title}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Grid>
                          )}
                        {/* Check if there are two subItems for keyStatsData.data[1] */}
                        {index === 1 &&
                          item?.subItems &&
                          item.subItems.length === 2 && (
                            <Grid item>
                              <FormControl
                                sx={{
                                  minWidth:
                                    selectedDropDownOption ===
                                    'Annualized Volatility'
                                      ? 160
                                      : 110,
                                }}
                              >
                                <Select
                                  value={selectedDropDownOption}
                                  onChange={handleDropDownOptionChange}
                                  displayEmpty
                                  IconComponent={KeyboardArrowDownIcon}
                                  inputProps={{
                                    'aria-label': 'Without label',
                                  }}
                                  sx={{
                                    height: '25px',
                                    marginTop: '-10px',
                                    fontSize: '12px',

                                    '& .MuiSelect-icon': {
                                      minHeight: '27px',
                                      color: '#000',
                                      marginTop: '-2px',
                                    },
                                    '& .MuiOutlinedInput-root': {
                                      border: 'none',
                                      '&:hover': {
                                        border: 'none',
                                      },
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                      border: 'none',
                                    },
                                    boxShadow: 'none',
                                  }}
                                >
                                  {item?.subItems?.map((subItem, subIndex) => (
                                    <MenuItem
                                      key={subIndex}
                                      value={subItem?.title}
                                      sx={{ fontSize: '13px' }}
                                    >
                                      {subItem?.title}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Grid>
                          )}

                        <Grid item container>
                          {item?.subItems && item?.subItems.length > 1
                            ? item?.subItems?.map(
                                (subItem: any, subIndex: number) => {
                                  // Check if either selected option matches the current subItem
                                  if (
                                    subItem?.title ===
                                      selectedReturnDropDownOption ||
                                    subItem?.title === selectedDropDownOption
                                  ) {
                                    return (
                                      <Grid
                                        item
                                        container
                                        key={subIndex}
                                        height={isMobile ? '100px' : 'auto'}
                                      >
                                        <PercentageDataContainer>
                                          <CurrentPercentage>
                                            {subItem?.value}
                                          </CurrentPercentage>
                                          <CategoryPercentage>{`vs Category: ${subItem?.compareVal}`}</CategoryPercentage>
                                        </PercentageDataContainer>
                                        <CustomLinearProgress
                                          value={subItem?.graph}
                                          title={item?.title}
                                        />
                                      </Grid>
                                    );
                                  }
                                  return null; // Skip rendering for non-matching subItems
                                },
                              )
                            : // Render a default state or message when there are no sub-items
                              item?.subItems?.map(
                                (subItem: any, subIndex: any) => (
                                  <Grid
                                    item
                                    container
                                    key={subIndex}
                                    height={isMobile ? '100px' : 'auto'}
                                  >
                                    <PercentageDataContainer>
                                      <CurrentPercentage>
                                        {subItem?.value}
                                      </CurrentPercentage>
                                      <CategoryPercentage>{`vs Category: ${subItem?.compareVal}`}</CategoryPercentage>
                                    </PercentageDataContainer>
                                    <CustomLinearProgress
                                      value={subItem?.graph}
                                      title={item?.title}
                                    />
                                  </Grid>
                                ),
                              )}
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                })}
                {keyStatsData?.data?.length === 2 && (
                  <KeyStatisticsDataWrapper>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                        marginTop: '-8px',
                        flexDirection: 'column',
                      }}
                    >
                      <TitleText style={{ width: '60%' }}>
                        Net Expense Ratio
                      </TitleText>
                      <TitleText
                        style={{ width: '60%', justifyContent: 'center' }}
                      >
                        --------
                      </TitleText>
                    </div>
                  </KeyStatisticsDataWrapper>
                )}
              </Grid>
              <Divider
                style={{
                  width: '100%',
                  border: '0.5px solid #e4e7ec',
                  marginBottom: '20px',
                  marginTop: '40px',
                }}
              />
            </Grid>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <ReturnsDiv>
                <div>Returns Chart</div>
                <FormControl sx={{ m: 1, minWidth: 100 }}>
                  <Select
                    value={selectedGraphDropDownOption}
                    onChange={handleGraphDropdownOptionChange}
                    IconComponent={KeyboardArrowDownIcon}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    sx={{
                      height: '30px',
                      fontSize: '14px',
                      color: '#101828',
                      '& .MuiSelect-icon': {
                        minHeight: '27px',
                        marginTop: '-2px',
                        color: '#000',
                      },
                    }}
                  >
                    {keyStatsPeriodOptions.map(({ label, value }) => (
                      <MenuItem
                        key={value}
                        value={value}
                        sx={{ fontSize: '14px', color: '#101828' }}
                      >
                        {label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </ReturnsDiv>
              <LineGraphContainer>
                <SimpleLineChart data={chartsData ? chartsData : null} />
              </LineGraphContainer>
              <Divider
                style={{
                  width: '100%',
                  border: '0.5px solid #e4e7ec',
                  marginBottom: '20px',
                }}
              />
            </div>
            <Grid item container direction={'column'}>
              <Grid item>
                <FundBoxHeader style={{ fontSize: 16 }}>
                  Fund Information
                </FundBoxHeader>
              </Grid>
              <Grid item>
                <FundBoxSubHeader>{`As of ${data?.date}`}</FundBoxSubHeader>
              </Grid>
              <Grid item container py={2}>
                <Grid
                  item
                  container
                  style={{
                    borderRight: isMobile ? 'none' : '1px solid  #e4e7ec',
                  }}
                  xs={12}
                  md={6}
                  alignItems={'center'}
                >
                  {data?.fundInformation?.slice(0, 6).map((item, index) => (
                    <DataContainer key={index}>
                      <StyledWrapper>
                        <FundDataName
                          style={{
                            padding: '5px 0px',
                          }}
                        >
                          {item?.title}
                        </FundDataName>
                        {item?.Date && <SubText>{item?.Date}</SubText>}
                      </StyledWrapper>
                      <FundValue
                        style={{
                          paddingRight: '10px',
                        }}
                      >
                        {item?.value}
                      </FundValue>
                    </DataContainer>
                  ))}
                </Grid>
                <Grid item container xs={12} md={6} direction={'column'}>
                  {data?.fundInformation
                    ?.filter(
                      item => item?.title !== 'Initial Minimum Investment',
                    )
                    .slice(6)
                    .map((item, index) => (
                      <DataContainer key={index}>
                        <StyledWrapper>
                          <FundDataName
                            style={{
                              padding: isMobile
                                ? '5px 0px'
                                : '5px 0px 5px 10px',
                            }}
                          >
                            {item?.title}
                          </FundDataName>
                          {item?.Date && <SubText>{item?.Date}</SubText>}
                        </StyledWrapper>
                        <FundValue
                          style={{
                            textAlign: 'right',
                            paddingRight: isMobile ? '10px' : 0,
                          }}
                        >
                          {item?.value}
                        </FundValue>
                      </DataContainer>
                    ))}
                </Grid>
              </Grid>
            </Grid>
          </DrawerWrapper>
        </TickerDrawerContainer>
      ) : (
        <LoaderContainer>
          <Spinner />
        </LoaderContainer>
      )}
    </>
  );
}

export default TickerDrawer;
