import { AccordionDetails, AccordionSummary, Grid } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import React, { useState } from 'react';
import { AccordionWrapper, StyledAssessmentAccordion } from './styles';
import moment from 'moment-timezone';
import AssessmentRiskResult from './AssessmentRiskResult';

interface AssessmentResultProps {
  riskCapacity?: number;
  riskPreference?: number;
  portfolioRisk?: number;
  riskAssessmentUpdatedAt?: string;
}

function AssessmentResult({
  riskCapacity,
  riskPreference,
  portfolioRisk,
  riskAssessmentUpdatedAt,
}: AssessmentResultProps): React.JSX.Element {
  const [expanded, setExpanded] = useState<string | false>('panel1');

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  const riskAssessmentUpdatedAtDate = moment(riskAssessmentUpdatedAt);
  return (
    <AccordionWrapper
      item
      container
      rowGap={2}
      xs={11.7}
      sx={{ borderRadius: '8px' }}
    >
      <StyledAssessmentAccordion
        expanded={expanded === 'panel1'}
        onChange={handleChange('panel1')}
        sx={{
          width: '100%',
          boxShadow: 'none',
          background: '#f9f9f9',
          height: {
            xs: expanded === 'panel1' ? '360px' : '80px',
            sm: expanded === 'panel1' ? '345px' : '50px',
          },
        }}
      >
        <AccordionSummary
          expandIcon={<KeyboardArrowDownIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{
            width: '100%',
            padding: '0px 10px 0px 16px',
          }}
        >
          <Grid item container xs={12}>
            <Grid
              item
              container
              alignItems={'center'}
              justifyContent={'flex-start'}
              xs={1}
              sm={2}
            >
              <p>Risk</p>
            </Grid>
            {riskCapacity && riskPreference && (
              <Grid item container xs={10}>
                <Grid
                  item
                  container
                  columnGap={1}
                  alignItems={'center'}
                  justifyContent={'flex-end'}
                >
                  <Grid item xs={6} sm={'auto'}>
                    <p style={{ color: '#6C55C6', fontSize: '12px' }}>
                      Recently Completed
                    </p>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sm={'auto'}
                    display={'flex'}
                    columnGap={1}
                    alignItems={'center'}
                  >
                    <span>•</span>
                    <p style={{ color: '#86858B', fontSize: '12px' }}>
                      {riskAssessmentUpdatedAtDate.format('MM/DD/YYYY')}
                    </p>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sm={'auto'}
                    display={'flex'}
                    columnGap={1}
                    alignItems={'center'}
                  >
                    <span>•</span>
                    <p style={{ color: '#86858B', fontSize: '12px' }}>
                      {riskAssessmentUpdatedAtDate
                        .tz('America/Phoenix')
                        .format('hh:mm A')}{' '}
                      (MST)
                    </p>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <AssessmentRiskResult
            riskCapacity={riskCapacity}
            riskPreference={riskPreference}
            portfolioRisk={portfolioRisk}
          />
        </AccordionDetails>
      </StyledAssessmentAccordion>
    </AccordionWrapper>
  );
}

export default AssessmentResult;
