import instance from 'utils/instances';
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const addEnterprise = async (payload: any) => {
  const createResponse = await instance.post('/enterprise/', payload, {
    headers: {
      'x-tifin-ai-auth': localStorage.getItem('x-tifin-ai-token'),
      'content-type': undefined,
    },
  });
  return createResponse?.data;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const addFirm = async (payload: any) => {
  const createResponse = await instance.post('/firms/', payload, {
    headers: {
      'x-tifin-ai-auth': localStorage.getItem('x-tifin-ai-token'),
      'content-type': undefined,
    },
  });
  return createResponse?.data;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const addFirmAdmin = async (payload: any) => {
  const createResponse = await instance.post('/firms/admin', payload, {
    headers: {
      'x-tifin-ai-auth': localStorage.getItem('x-tifin-ai-token'),
      'content-type': undefined,
    },
  });
  return createResponse?.data;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const resendInvite = async (payload: any) => {
  const createResponse = await instance.post('/user/resendInvite', payload, {
    headers: {
      'x-tifin-ai-auth': localStorage.getItem('x-tifin-ai-token'),
      'content-type': undefined,
    },
  });
  return createResponse?.data;
};
