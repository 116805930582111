import React, { useState } from 'react';
import { Box, Button, ButtonGroup, Grid, styled } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ImportExportOutlinedIcon from '@mui/icons-material/ImportExportOutlined';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import ClientAccordionTable from '@components/ClientAccordionTable';
import { TableFilters } from './styles';
import CustomPagination from '@components/Pagination';
import { SearchBoxInput, SearchContainer } from '@pages/SuperAdminPage/styles';
import Loader from '@components/Loader';

const StyledTableContainer = styled(Grid)`
  background: #fff;
  border: 1px solid #e4e7ec; /* Add border styling */
  border-radius: 4px; /* Optional: Add border-radius for rounded corners */
`;

interface ClientTableProps {
  data: any[];
}

function ClientsTable({ data }: ClientTableProps): React.JSX.Element {
  const rowsPerPage = 5;
  const [page, setPage] = useState(1);
  const [isSearchBoxOpen, setIsSearchBoxOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleSearchIconClick = () => {
    setIsSearchBoxOpen(!isSearchBoxOpen);
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filteredSearchedClientData = data?.filter(item =>
    `${item?.firstName} ${item?.lastName}`
      .toLowerCase()
      .includes(searchTerm.toLowerCase()),
  );

  const startIndex = (page - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const paginatedData = filteredSearchedClientData?.slice(startIndex, endIndex);

  if (!data) return <Loader />;

  return (
    <Grid container xs={11.8}>
      <StyledTableContainer item container xs={12}>
        <Grid
          item
          container
          xs={12}
          sx={{
            background: '#fff',
          }}
        >
          <TableFilters alignItems="center" item container>
            <Grid xs={12} md={1}>
              {isSearchBoxOpen ? (
                <SearchContainer>
                  <SearchBoxInput
                    type="text"
                    placeholder="Search Clients"
                    value={searchTerm}
                    onChange={handleSearch}
                  />
                </SearchContainer>
              ) : (
                <Button variant="outlined">
                  <SearchIcon
                    fontSize="small"
                    onClick={handleSearchIconClick}
                    sx={{ cursor: 'pointer' }}
                  />
                </Button>
              )}
            </Grid>
            <Grid justifyContent="flex-end" xs={12} md={11}>
              <Box display="flex" justifyContent="flex-end">
                <ButtonGroup size="medium" aria-label="large button group">
                  <Button key="sortBy">
                    <ImportExportOutlinedIcon fontSize="small" />
                    &nbsp;Sort By
                  </Button>
                  ,
                  <Button key="filter">
                    <FilterAltOutlinedIcon fontSize="small" />
                    &nbsp;Filter
                  </Button>
                  ,
                  <Button key="export">
                    <FileDownloadOutlinedIcon fontSize="small" />
                    &nbsp;Export
                  </Button>
                </ButtonGroup>
              </Box>
            </Grid>
          </TableFilters>
          <ClientAccordionTable data={paginatedData} />
          <Grid
            item
            container
            xs={12}
            justifyContent={'center'}
            alignItems={'center'}
            padding={'0px 15px 0px 15px'}
          >
            <CustomPagination
              page={page}
              count={filteredSearchedClientData?.length ?? 0}
              rowsPerPage={rowsPerPage}
              onPageChange={handlePageChange}
            />
          </Grid>
        </Grid>
      </StyledTableContainer>
    </Grid>
  );
}

export default ClientsTable;
