import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Collapse,
  IconButton,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  GreyLabelText,
  GreySubLabelText,
  ParagraphBoldtText,
} from '@styles/Typography';
import { InnerTableColumn, TableColumn, TableHeader } from './styles';
import styled from 'styled-components';
import { price } from '@common/number';

interface IAccordianProps {
  data: IRowData[];
}

interface IAccount {
  type: string;
  name: string;
  marketValue: number;
}
interface IRowData {
  action?: any;
  clientName: string;

  implications: string;
  id: string;
  ClientPortfolios: IAccount[];
}

const Row = ({ row }: any) => {
  const [open, setOpen] = useState(false);
  const email = row?.email || '';

  return (
    <>
      <TableRow style={{ backgroundColor: open ? '#F9FAFB' : '#fff' }}>
        <TableColumn
          sx={{
            borderBottom: '0px solid #e4e7ec',
            fontSize: '14px',
            color: '#101828',
          }}
        >
          {row?.firstName} {row?.lastName} <br />
          <div style={{ fontSize: '12px', color: '#101828' }}>{email}</div>
        </TableColumn>
        <TableColumn sx={{ borderBottom: '0px solid #e4e7ec' }}>
          {price(row?.marketValue)}
        </TableColumn>
        <TableColumn sx={{ borderBottom: '0px solid #e4e7ec' }}>
          {row?.implications}
        </TableColumn>
        <TableColumn
          sx={{ borderBottom: '0px solid #e4e7ec', textAlign: 'right' }}
        >
          {row?.ClientPortfolios && row?.ClientPortfolios.length > 0 && (
            <IconButton size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
        </TableColumn>
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: '0px' }} colSpan={4}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table size="small" aria-label="purchases">
              <TableBody
                style={{ paddingLeft: '0px', backgroundColor: '#F9FAFB' }}
              >
                {row?.ClientPortfolios &&
                  row?.ClientPortfolios.length > 0 &&
                  row?.ClientPortfolios.map(
                    (innerRow: IAccount, index: number) => {
                      return (
                        <TableRow key={index}>
                          <InnerTableColumn
                            sx={{
                              borderTop: '0.5px solid #e4e7ec',
                              borderBottom: 'none',
                            }}
                          >
                            <ParagraphBoldtText
                              fontSize={'12px'}
                              fontWeight="600"
                              mt={1}
                            >
                              {innerRow.type}
                            </ParagraphBoldtText>
                            <GreySubLabelText mt={0.5} mb={1}>
                              Account Type
                            </GreySubLabelText>
                          </InnerTableColumn>
                          <InnerTableColumn
                            sx={{
                              borderTop: '0.5px solid #e4e7ec',
                              borderBottom: 'none',
                            }}
                          >
                            <ParagraphBoldtText
                              fontSize={'12px'}
                              fontWeight="600"
                              mt={1}
                            >
                              {innerRow?.name}
                            </ParagraphBoldtText>
                            <GreySubLabelText mt={0.5} mb={1}>
                              Account Name
                            </GreySubLabelText>
                          </InnerTableColumn>
                          <InnerTableColumn
                            sx={{
                              borderTop: '0.5px solid #e4e7ec',
                              borderBottom: 'none',
                            }}
                          >
                            <ParagraphBoldtText
                              fontSize={'12px'}
                              fontWeight="600"
                              mt={1}
                            >
                              ${innerRow.marketValue}
                            </ParagraphBoldtText>
                            <GreySubLabelText mt={0.5} mb={1}>
                              Market Value
                            </GreySubLabelText>
                          </InnerTableColumn>
                          <InnerTableColumn
                            sx={{
                              borderTop: '0.5px solid #e4e7ec',
                              textAlign: 'right',
                              borderBottom: 'none',
                            }}
                          >
                            <GreyLabelText mt={1}>
                              <ParagraphBoldtText
                                fontSize="12px"
                                color="#0B5CD7"
                                fontWeight="500"
                              >
                                View Account
                              </ParagraphBoldtText>
                            </GreyLabelText>
                          </InnerTableColumn>
                        </TableRow>
                      );
                    },
                  )}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const StyledTableContainer = styled(TableContainer)`
  max-height: 320px;
  overflow-y: auto;
`;

const StyledTableBody = styled(TableBody)``;

function ClientAccordionTable({ data }: IAccordianProps): React.JSX.Element {
  return (
    <StyledTableContainer>
      <Table>
        <TableHead style={{ backgroundColor: '#F9FAFB' }}>
          <TableRow>
            <TableHeader>Client Name</TableHeader>
            <TableHeader>Portfolio Value</TableHeader>
            <TableHeader>Implications</TableHeader>
            <TableHeader></TableHeader>
          </TableRow>
        </TableHead>
        <StyledTableBody>
          {Array.isArray(data) &&
            data?.length > 0 &&
            data.map(row => <Row key={row?.id} row={row} />)}
        </StyledTableBody>
      </Table>
    </StyledTableContainer>
  );
}

export default ClientAccordionTable;
