import React, { useState } from 'react';
import { Box, Grid, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MemoBackArrowIcon from '@icons/BackArrow';
import {
  BackArrowWrapper,
  CurrentPageName,
  PrevPageName,
  StepperContainer,
  StyledDivider,
} from '@components/ChatContent/styles';
import downArrow from '@images/Down Arrow.svg';
import upArrow from '@images/Up Arrow.svg';
import {
  ComparisonContainer,
  ComparisonContainerHeader,
  ComparisonHeaderText,
  FundDataNumber,
  FundDataText,
  GraphContainer,
  StyledDiffText,
} from './styles';
import SimpleTable from '@components/UIComponents/SimpleTable';
import SimpleLineGraph from '@components/UIComponents/SimpleLineGraph';
import HoldingsTable from '@components/UIComponents/HoldingsTable';
import CustomLegend from '@components/UIComponents/SimpleLineGraph/CustomLegend';
import MemoTableViewIcon from '@icons/TableViewIcon';
import MemoChartViewIcon from '@icons/ChartViewIcon';
import AssetAllocation from './AssetAllocation';
import { useRESTQuery } from '@hooks/useRESTQuery';
import { handleError } from '@common/error';
import { useDispatch } from 'react-redux';
import { LoaderContainer } from '@pages/Signup/styles';
import { Spinner } from '@pages/IntegrationPage/styles';
import { price } from '@common/number';
import { EAdjustmentStepper, EColorMapping } from 'app/enums';

interface PortfolioComparisonProps {
  stepper?: EAdjustmentStepper;
  setStepper: React.Dispatch<React.SetStateAction<string>>;
  setSelectedTicker: React.Dispatch<React.SetStateAction<string>>;
  showStepper?: boolean;
  selectedCard?: { title: string; desc: string; id: string } | null;
  onClose: () => void;
}

function PortfolioComparison({
  stepper,
  setStepper,
  setSelectedTicker,
  showStepper = false,
  selectedCard,
  onClose,
}: PortfolioComparisonProps): React.JSX.Element {
  console.log('step--->', stepper);
  const [isHoldingsChartView, setIsHoldingsChartView] = useState(false);

  const dispatch = useDispatch();
  const portfolioId = selectedCard?.id;

  const {
    data: adjustmentDetailsData,
    isLoading: isAdjustmentDetailsLoading,
    // refetch: fetchProposedAdjustmentDetails,
  } = useRESTQuery(
    ['get-proposed-adjustment-details', portfolioId],
    {
      endpoint: `/client/proposedAdjustmentDetails?id=${portfolioId}`,
      method: 'GET',
      headers: {
        'X-Tifin-Ai-Auth': localStorage.getItem('x-tifin-ai-token'),
      },
    },
    {
      enabled: !!portfolioId,
      select: response => {
        return response?.data || [];
      },

      onError: (error: any) => {
        handleError(dispatch, error);
      },
    },
  );

  // this is the header data for first comparison table
  const headers = [
    { key: 'Portfolio', value: 'portfolio', width: '20%' },
    { key: 'Exp. Risk', value: 'expRisk', width: '15%' },
    { key: 'Exp. Returns', value: 'expReturns', width: '20%' },
    { key: 'Net Expense Ratio', value: 'netExpenseRatio', width: '25%' },
    { key: 'Risk Score', value: 'riskScore', width: '20%' },
  ];

  // it converts a numeric value into a percentage string and returns 'N/A' if the value is undefined or null.
  const formatValue = (value: number | null | undefined) => {
    if (value !== null && value !== undefined) {
      return `${(value * 100).toFixed(2)}%`;
    } else {
      return 'N/A';
    }
  };

  // this is the data for first table that is the comparison table
  const data = [
    {
      portfolio: 'Current',
      expRisk: formatValue(adjustmentDetailsData?.currentPortfolio?.expRisk),
      expReturns: formatValue(
        adjustmentDetailsData?.currentPortfolio?.expReturns,
      ),
      netExpenseRatio: formatValue(
        adjustmentDetailsData?.currentPortfolio?.fees,
      ),
      riskScore: adjustmentDetailsData?.currentPortfolio?.riskScore
        ? `${adjustmentDetailsData?.currentPortfolio?.riskScore}`
        : 'N/A',
    },
    {
      portfolio: 'Proposed',
      expRisk: formatValue(
        adjustmentDetailsData?.recommendedPortfolio?.expRisk,
      ),
      expReturns: formatValue(
        adjustmentDetailsData?.recommendedPortfolio?.expReturns,
      ),
      netExpenseRatio: formatValue(
        adjustmentDetailsData?.recommendedPortfolio?.fees,
      ),
      riskScore: adjustmentDetailsData?.recommendedPortfolio?.riskScore
        ? `${adjustmentDetailsData?.recommendedPortfolio?.riskScore}`
        : 'N/A',
    },
  ];

  // this is the header data for holding table
  const holdingHeaders = [
    { key: 'Fund', value: 'name', width: '75%' },
    { key: 'Proposed Weight', value: 'weight', width: '25%' },
  ];

  // Creating an array of objects for rendering legends with colors and formatted names to show the legends for the graph
  const customLegendItems = Object.entries(EColorMapping).map(
    ([key, color]) => ({
      color,
      value: `${key.charAt(0).toUpperCase() + key.slice(1)} Portfolio`,
    }),
  );

  // Collecting all unique keys (excluding 'date') from objects in formattedData
  const allKeys =
    adjustmentDetailsData?.cumulativeHistoricalReturn?.reduce((keys, obj) => {
      Object.keys(obj).forEach((key: string) => {
        if (key !== 'date') {
          keys.add(key);
        }
      });
      return keys;
    }, new Set<string>()) || new Set<string>();

  // Prepares data for rendering lines in the graph, assigning colors and names based on EColorMapping
  const lineData = [...allKeys].map(key => ({
    dataKey: key,
    stroke:
      EColorMapping[
        (key.charAt(0).toUpperCase() +
          key.slice(1)) as keyof typeof EColorMapping
      ] || '#000000',
    name: `${key.charAt(0).toUpperCase() + key.slice(1)}`,
  }));

  const currentMarketValue =
    adjustmentDetailsData?.currentPortfolio?.currentMarketValue;

  const recommendedMarketValue =
    adjustmentDetailsData?.recommendedPortfolio?.currentMarketValue;

  const priceDifference =
    currentMarketValue && recommendedMarketValue
      ? currentMarketValue - recommendedMarketValue
      : 0;

  if (isAdjustmentDetailsLoading)
    return (
      <LoaderContainer>
        <Spinner />
      </LoaderContainer>
    );

  return (
    <ComparisonContainer
      container
      rowGap={3}
      padding={showStepper ? '0 20px 0px 20px' : '0px 20px 20px'}
      width={showStepper ? '100%' : '94%'}
      showStepper={showStepper}
    >
      {showStepper && (
        <StepperContainer
          style={{ width: '100%' }}
          onClick={() => setStepper(EAdjustmentStepper.ProposedAdjustments)}
        >
          <Grid
            container
            item
            alignItems="center"
            justifyContent="space-between"
            direction={'row'}
          >
            <Grid item container xs={11} sm={10}>
              <BackArrowWrapper>
                <MemoBackArrowIcon
                  stroke="#667085"
                  strokeWidth="3"
                  height="20px"
                />
              </BackArrowWrapper>
              <PrevPageName
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <p>Proposed Adjustment</p>
              </PrevPageName>
              <StyledDivider
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                /
              </StyledDivider>
              <CurrentPageName>
                <p>Portfolio Comparison</p>
              </CurrentPageName>
            </Grid>
            <Grid item container xs={1} sm={2} justifyContent={'flex-end'}>
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </StepperContainer>
      )}
      <Grid item container>
        {selectedCard && (
          <>
            <Grid item xs={12}>
              <ComparisonHeaderText>{selectedCard.title}</ComparisonHeaderText>
            </Grid>
            <Grid item xs={12}>
              <ComparisonHeaderText
                color="#86858b"
                fontSize="14px"
                fontWeight="400"
              >
                {selectedCard.desc}
              </ComparisonHeaderText>
            </Grid>
          </>
        )}
      </Grid>
      <Grid
        item
        container
        direction={'row'}
        xs={6}
        sm={3.5}
        justifyContent={'center'}
      >
        <FundDataText item xs={12}>
          Current Value
        </FundDataText>
        {adjustmentDetailsData ? (
          <Grid item container xs={12} pl={'3px'} pt={'5px'}>
            {currentMarketValue && (
              <FundDataNumber>
                {price(currentMarketValue, true, false, false)}
              </FundDataNumber>
            )}
            <>
              <Grid item container xs={0.8} mr={'5px'} ml={'5px'}>
                <img
                  alt="Gain loss arrow image"
                  src={priceDifference >= 0 ? upArrow : downArrow}
                  style={{
                    height: '17px',
                  }}
                />
              </Grid>
              {adjustmentDetailsData && (
                <Grid item>
                  <StyledDiffText
                    color={priceDifference >= 0 ? '#282829' : '#e11d48'}
                  >
                    {price(priceDifference, true, false, false)}
                  </StyledDiffText>
                </Grid>
              )}
            </>
          </Grid>
        ) : (
          <Grid item container xs={12} pl={'3px'} pt={'5px'}>
            N/A
          </Grid>
        )}
      </Grid>
      <SimpleTable headers={headers} data={data} />
      {adjustmentDetailsData?.cumulativeHistoricalReturn?.length > 0 && (
        <GraphContainer item container xs={12} sm={12}>
          <ComparisonContainerHeader>
            <strong>Performance</strong>
          </ComparisonContainerHeader>

          <SimpleLineGraph
            data={adjustmentDetailsData?.cumulativeHistoricalReturn}
            lines={lineData}
          />

          <CustomLegend customLegendItems={customLegendItems} />
        </GraphContainer>
      )}
      <GraphContainer item container xs={12} sm={12}>
        <Grid
          item
          container
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
          gap={1}
          height={'100%'}
        >
          <Grid
            item
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
            sx={{
              width: '100%',
              color: '#282829',
              fontSize: '16px',
              fontWeight: 500,
            }}
          >
            <ComparisonContainerHeader>
              <strong>Proposed Portfolio Holdings</strong>
            </ComparisonContainerHeader>
            <Grid item display={'flex'} gap={'4px'}>
              <Box
                sx={{
                  background: !isHoldingsChartView ? '#000' : '',
                  borderRadius: !isHoldingsChartView ? '50%' : '',
                  height: !isHoldingsChartView ? '32px' : '',
                  cursor: 'pointer',
                }}
                onClick={() => setIsHoldingsChartView(false)}
              >
                <MemoTableViewIcon
                  fill={!isHoldingsChartView ? '#fff' : '#96969B'}
                />
              </Box>
              <Box
                sx={{
                  background: isHoldingsChartView ? '#000' : '',
                  borderRadius: isHoldingsChartView ? '50%' : '',
                  height: isHoldingsChartView ? '32px' : '',
                  cursor: 'pointer',
                }}
                onClick={() => setIsHoldingsChartView(true)}
              >
                <MemoChartViewIcon
                  fill={isHoldingsChartView ? '#fff' : '#96969B'}
                />
              </Box>
            </Grid>
          </Grid>

          {!isHoldingsChartView ? (
            <Grid item xs={12}>
              <HoldingsTable
                headers={holdingHeaders}
                setSelectedTicker={setSelectedTicker}
                setStepper={setStepper}
                data={adjustmentDetailsData?.recommendedPortfolio?.holdings}
              />
            </Grid>
          ) : (
            <AssetAllocation
              currentPortfolioData={adjustmentDetailsData?.currentPortfolio}
              proposedPortfolioData={
                adjustmentDetailsData?.recommendedPortfolio
              }
              showProposed={true}
              showCurrent={true}
            />
          )}
        </Grid>
      </GraphContainer>
    </ComparisonContainer>
  );
}

export default PortfolioComparison;
