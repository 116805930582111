import * as React from 'react';

function DeleteIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 18 18" fill="none" {...props}>
      <path
        d="M15.188 3.938H2.812M7.313 7.313v4.5M10.688 7.313v4.5M14.063 3.938v10.687a.562.562 0 01-.563.563h-9a.563.563 0 01-.563-.563V3.937"
        stroke="#E00000"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.813 3.938V2.812a1.125 1.125 0 00-1.126-1.124H7.313a1.125 1.125 0 00-1.125 1.125v1.124"
        stroke="#E00000"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const MemoDeleteIcon = React.memo(DeleteIcon);
export default MemoDeleteIcon;
