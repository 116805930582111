import React from 'react';
import initial from '@images/initial.svg';
import { Grid } from '@mui/material';
import { StyledButton } from '@pages/CIOViewpoints/styles';
import integration from '@images/integration.svg';
import rightArrow from '@images/right-arrow.svg';
import PlusIcon from '@icons/plusIcon';
import { useNavigate } from 'react-router-dom';
import {
  AddClientBtnText,
  ClientsWrapper,
  ConnectPlatformButton,
  StyledPTag,
  StyledText,
} from './styles';

interface NoClientsProps {
  openAddClientModal: () => void;
}

function NoClients({ openAddClientModal }: NoClientsProps): React.JSX.Element {
  const navigate = useNavigate();

  const handleConnctCRMClick = () => {
    navigate('/integrations');
  };
  return (
    <ClientsWrapper
      container
      justifyContent={'center'}
      alignItems={'center'}
      rowGap={2}
      direction={'column'}
    >
      <Grid item>
        <StyledPTag>Looks like there is no one here yet</StyledPTag>
      </Grid>

      <StyledText item xs={12} mb={1}>
        Sync your custodian and CRM platform to bring in your client data for an
        elevated experience
      </StyledText>

      <Grid item container justifyContent={'center'} columnGap={2}>
        <Grid item>
          <ConnectPlatformButton
            variant="outlined"
            onClick={handleConnctCRMClick}
          >
            <img
              src={integration}
              alt="Integration image"
              style={{ marginRight: '5px' }}
              height={24}
              width={24}
            />
            Connect Custodian & CRM Platforms
            <img src={rightArrow} alt="Right arrow image" />
          </ConnectPlatformButton>
        </Grid>
        {false && (
          <Grid item>
            <StyledButton
              variant="outlined"
              style={{ width: '100%' }}
              onClick={openAddClientModal}
            >
              <PlusIcon stroke="#333" />
              <AddClientBtnText>Add Clients Manually</AddClientBtnText>
            </StyledButton>
          </Grid>
        )}
      </Grid>
      <Grid item alignItems={'center'} justifyContent={'center'}>
        <img src={initial} alt="Initial image" />
      </Grid>
    </ClientsWrapper>
  );
}

export default NoClients;
