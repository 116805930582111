export function getClientDisplayName(clientName: string): string {
  const [firstName = '', lastName = ''] = clientName.trim().split(/\s+/);

  return firstName && lastName ? clientName : firstName || lastName;
}

export const getMenuOptions = (
  newTitle: string[],
  clientName: string,
  currentIndex: number,
): any => {
  const baseOptions = [
    {
      key: 'Display viewpoint',
      value: 'Viewpoint Details',
      href: '/actions',
    },
  ];

  return newTitle[0] !== 'No Relevant Viewpoint'
    ? [
        {
          key: 'Display adjustments for viewpoint',
          value: `What adjustments should I make to ${clientName}’s portfolio so it aligns with the ${newTitle[currentIndex]} viewpoint?`,
          href: '/conversations/start',
        },
        {
          key: 'Display implications for viewpoint',
          value: `How will ${clientName}’s portfolio be impacted by the ${newTitle[currentIndex]} viewpoint?`,
          href: '/conversations/start',
        },
        ...baseOptions,
      ]
    : baseOptions;
};

type DataItem = {
  value?: number;
  clientName: string;
};

type SortOrder = 'asc' | 'desc';

export const filterAndSortData = (
  dataToFilter: DataItem[] = [],
  selectedFilterRange: number[] | null,
  sortOrder: SortOrder = 'asc',
  selectedSortOption: string,
): DataItem[] => {
  return dataToFilter
    .filter(({ value = 0 }) =>
      selectedFilterRange
        ? value >= selectedFilterRange[0] && value <= selectedFilterRange[1]
        : true,
    )
    .sort((a, b) => {
      const compare =
        selectedSortOption === 'Name'
          ? a.clientName.localeCompare(b.clientName, undefined, {
              sensitivity: 'base',
            })
          : (b.value || 0) - (a.value || 0);
      return sortOrder === 'asc' ? compare : -compare;
    });
};
