import React, { useEffect } from 'react';
import ChatTable from './ChatTable';
import { ChatBoxContentWrapper } from './styles';
import { Typography } from '@mui/material';
import styled from 'styled-components';

const textStyle = {
  color: '#222124',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: 'normal',
};

export const TickerSpan = styled.span`
  cursor: pointer;
  display: inline-block;
  text-decoration: underline;
  &:hover {
    background-color: #ddd;
    text-decoration: none;
    box-shadow: 0px 0px 3px #aaa;
  }
`;

const excludedWords = [
  '$CASH',
  'CASH',
  'AMA',
  'ARK',
  'BTW',
  'CAGR',
  'CEO',
  'CNBC',
  'COVID',
  'DCA',
  'ESOP',
  'ETF',
  'EU',
  'FAANG',
  'FOREX',
  'FUD',
  'FYI',
  'GLOBAL',
  'HODL',
  'IMHO',
  'IRA',
  'LOL',
  'MARKETS',
  'METALS',
  'MONEY',
  'NASDAQ',
  'NFT',
  'NYSE',
  'PFOF',
  'ROTH',
  'SEC',
  'SEP',
  'SPAC',
  'THE',
  'WSB',
  'US',
  'I',
  'P',
  'N',
  'Y',
  'Q',
  '_',
  '-',
  'SPDR',
  'CIO',
  'BDC',
  'VIX',
];

const excludedWordsRegex = excludedWords.map(word => `\\b${word}\\b`).join('|');

export const tickerRegex = new RegExp(
  `\\b(?!${excludedWordsRegex})[A-Z.-]*[A-Z-][A-Z.-]*\\b`,
);

const StyledTypography = styled(Typography)`
  font-size: 12px !important;
  @media (min-width: 600px) {
    font-size: 14px !important;
  }
`;

function ContentDefault(props: any): React.JSX.Element {
  const {
    data,
    isSender,
    type,
    selectTicker = () => {},
    streaming = true,
    scrollToMessageTop,
  } = props;

  let lines = [];
  if (type === 'text') {
    try {
      lines = data?.split(streaming ? '\n' : '\n') || [];
    } catch (err) {
      console.log(err);
    }
  }

  // added this condition to scroll messages to top once user clicks on query from any place.
  useEffect(() => {
    scrollToMessageTop && scrollToMessageTop();
  }, []);

  return (
    <ChatBoxContentWrapper
      isSender={isSender}
      istablecontent={type === 'table' ? 1 : 0}
    >
      {type === 'table' ? (
        <ChatTable data={data} />
      ) : (
        <>
          {lines?.map((t: string, idx) => {
            t = t.replace(/\*\*/g, '');
            return (
              <StyledTypography
                key={idx}
                sx={{ ...textStyle, marginTop: idx === 0 ? 0 : 0.65 }}
              >
                {t.split(' ').map(nt => {
                  const tickers = nt.match(tickerRegex);
                  if (!isSender && tickers?.length && tickers[0].length > 2) {
                    const ticker = tickers[0];
                    const [before, after] = nt.split(ticker);
                    return [
                      before,
                      <TickerSpan
                        onClick={() => selectTicker(ticker)}
                        key={ticker}
                      >
                        {ticker}
                      </TickerSpan>,
                      after,
                      ' ',
                    ];
                  }
                  return `${nt} `;
                })}
              </StyledTypography>
            );
          })}
        </>
      )}
    </ChatBoxContentWrapper>
  );
}

export default React.memo(ContentDefault);
