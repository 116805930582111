/* eslint-disable react/prop-types */
import * as React from 'react';

function CharlesSchwabIcon(props: any): React.JSX.Element {
  const borderRadius = '4px';

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="48"
      viewBox="0 0 50 48"
      fill="none"
      style={{ borderRadius }}
    >
      <path d="M0 0H49.5497V48H0V0Z" fill="#00A0DF" />
      <path
        d="M43.0781 21.7254C43.0781 21.039 42.6371 20.5254 42.2804 20.007C41.9236 19.6614 41.661 19.3206 41.661 18.8886C41.661 18.375 42.0178 18.0294 42.4588 18.0294C42.7263 18.0294 42.8998 18.1158 43.0781 18.2022C43.2565 18.2886 43.5191 18.375 43.6975 18.375C44.0543 18.375 44.3169 18.0294 44.3169 17.775C44.3169 17.4294 43.9601 17.0886 43.3408 17.0886C41.9236 17.0886 40.6799 18.6342 40.6799 19.8342C40.6799 20.5206 41.1209 21.0342 41.4777 21.4662C41.7453 21.8118 42.0079 22.1526 42.0079 22.4982C42.0079 23.0118 41.4777 23.3574 41.0317 23.3574C40.5016 23.3574 40.234 23.0982 39.8772 22.8438C39.6988 22.671 39.5205 22.4982 39.347 22.4982C39.0795 22.4982 38.9903 23.0118 38.9903 23.271C38.9903 23.6166 39.6097 24.1302 40.4074 24.1302C41.661 23.9574 43.0781 22.9254 43.0781 21.7254Z"
        fill={props.fill || 'white'}
      />
      <path
        d="M12.8533 13.3118C12.7642 13.3118 12.7642 13.3118 12.8533 13.3118L12.7642 13.6574V13.7438H13.2051C13.5619 13.8302 13.6461 13.8302 13.6461 14.003C13.6461 14.2622 13.2894 15.1214 12.8484 16.3214L12.4916 17.4398C12.1349 18.3854 11.6047 19.7582 11.1637 20.8766C10.6335 22.2494 10.1876 23.3678 10.1876 23.5406C10.1876 23.7134 10.2768 23.7998 10.7177 23.7998C11.1587 23.7998 11.3371 23.7134 11.4263 23.5406C12.2241 21.0494 14.3497 18.2174 15.4151 18.2174C15.7718 18.2174 15.8561 18.3038 15.8561 18.6446C15.8561 19.1582 15.4151 20.1902 14.8799 21.2222C14.4389 22.0814 14.0822 22.9406 14.0822 23.3678C14.0822 23.7134 14.2606 23.8814 14.6124 23.8814C15.6777 23.8814 16.5646 22.9358 17.184 22.163L17.2732 22.0766L17.0056 21.8174H16.9164C16.5597 22.163 15.9403 22.763 15.7619 22.763H15.6727V22.5902C15.6727 22.5038 15.7619 22.2446 15.9403 21.9038C16.3813 20.9582 17.0948 19.3262 17.0948 18.3854C17.0948 17.6126 16.738 17.1854 16.0295 17.1854C14.7015 17.1854 13.6362 18.6446 13.106 19.4174C13.0169 19.5902 12.8385 19.763 12.8385 19.763C12.8385 19.763 12.9277 19.5902 13.0169 19.2494C13.1952 18.9038 13.3736 18.3902 13.6362 17.7038C14.6124 15.1262 15.0534 13.8398 15.0534 13.667C15.0534 13.4942 14.875 13.4942 14.7858 13.4942H12.8335L12.8533 13.3118Z"
        fill={props.fill || 'white'}
      />
      <path
        d="M9.8414 22.4111C9.93058 22.3247 9.93058 22.3247 9.8414 22.4111C9.8414 22.3247 9.57383 22.1519 9.57383 22.1519C9.13283 22.4975 8.33508 23.1839 7.62157 23.1839C7.354 23.1839 7.09138 23.0975 6.91301 22.9247C6.64544 22.7519 6.55625 22.4111 6.55625 21.9791C6.46706 20.9471 7.44319 19.3151 8.33013 18.3743C8.86031 17.8607 9.30626 17.6015 9.56887 17.6015C9.92563 17.6015 10.1882 17.8607 10.4558 18.1151C10.6342 18.3743 10.8126 18.5423 11.0752 18.5423C11.1644 18.5423 11.3428 18.4559 11.3428 18.3695C11.4319 18.2831 11.5211 18.1103 11.5211 18.0239C11.5211 17.5967 11.0801 16.9919 10.104 16.9055C9.03869 16.9055 7.62157 17.5055 6.55625 18.5375C5.58012 19.4831 5.04993 20.6831 5.13912 21.8015C5.13912 22.7471 5.66931 23.7743 7.09138 23.7743C8.50851 23.9567 9.66302 22.7567 9.8414 22.4111Z"
        fill={props.fill || 'white'}
      />
      <path
        d="M27.7432 20.0087C27.7432 20.0951 27.654 20.1815 27.654 20.1815V20.0951C27.654 20.0087 27.7432 19.7495 27.8324 19.4087C28.0108 18.7223 28.1892 17.8631 28.1892 17.6039C28.1892 17.2583 28.0108 17.0903 27.659 17.0903C26.9504 17.0903 25.7959 18.2903 25.4441 18.8951L25.6225 19.1543L25.8009 18.9815C26.0685 18.6359 26.5986 18.0359 26.8662 18.0359C27.0446 18.0359 27.1338 18.2087 27.1338 18.4631C27.1338 19.0631 26.4252 20.9543 25.9793 22.2407C25.7117 22.9271 25.5383 23.4407 25.5383 23.5271C25.5383 23.6999 25.6275 23.6999 25.7167 23.6999H26.336C26.4252 23.6999 26.5144 23.6135 26.6036 23.4407C26.6036 23.4407 26.8712 22.7543 27.0446 22.4087C28.2833 20.0039 28.9077 18.8039 29.4378 18.2855C29.7946 17.9399 30.0572 17.8583 30.2356 17.8583C30.414 17.8583 30.414 17.9447 30.5032 18.0311C30.5923 18.1175 30.6815 18.2903 30.8599 18.2903C30.9491 18.2903 31.1275 18.2903 31.2167 18.2039C31.3951 18.0311 31.4842 17.8583 31.4842 17.6039C31.4842 17.1767 31.1275 17.0039 30.8649 17.0039C29.6063 17.0903 28.8086 17.8631 27.7432 20.0087Z"
        fill={props.fill || 'white'}
      />
      <path
        d="M23.9344 16.9178C23.8452 16.9178 23.756 17.0042 23.756 17.0906C23.6668 17.177 23.6668 17.2634 23.4885 17.2634C23.3993 17.2634 23.3101 17.2634 23.1317 17.177C22.9533 17.177 22.7749 17.0906 22.5123 17.0906C19.4105 17.0906 17.815 21.1274 17.815 22.5002C17.815 23.4458 18.3452 23.873 18.8803 23.873C19.4997 23.873 20.3866 23.7002 21.5412 21.9818L21.8087 21.5546V21.641L21.7195 21.8138C21.5412 22.073 21.2785 22.673 21.2785 23.1866C21.2785 23.4458 21.3677 23.6138 21.4569 23.7002C21.5461 23.7866 21.7245 23.873 21.8979 23.873C22.9632 23.873 23.8502 22.5002 24.202 21.9818L23.9344 21.7226H23.8452L23.5777 21.9818C23.2209 22.3274 22.8691 22.841 22.6015 22.841H22.5123V22.6682C22.5123 22.3226 23.2209 20.6954 23.6668 19.4042C24.0236 18.4586 24.3754 17.5994 24.3754 17.4314C24.3754 17.4314 24.3754 17.2586 24.2862 17.2586L23.8452 16.9994C24.0236 16.9178 23.9344 16.9178 23.9344 16.9178ZM19.0587 22.241C19.0587 20.2682 20.6542 17.6042 22.2497 17.6042C22.7799 17.6042 23.0475 17.8634 23.0475 18.377C23.0475 19.1498 20.9218 22.841 19.4997 22.841C19.2371 22.9274 19.0587 22.841 19.0587 22.241Z"
        fill={props.fill || 'white'}
      />
      <path
        d="M33.8624 22.0669C33.8624 23.1853 34.571 23.9581 35.6363 23.9581C36.875 23.9581 38.2079 22.7581 38.5597 22.4125V22.3261L38.3813 22.0669H38.2922L38.1138 22.1533C37.6728 22.4989 36.9593 23.0125 36.3399 23.0125C35.6313 23.0125 35.1854 22.5853 35.1854 21.8941C35.1854 21.0349 35.6264 20.4349 35.894 19.9213V19.8349H36.6025C37.757 19.8349 39.8827 19.3213 39.8827 18.0301C39.8827 17.5165 39.3525 16.9117 38.4656 16.9117C36.6967 17.0893 33.8624 19.6669 33.8624 22.0669ZM38.3813 17.5165C38.7381 17.5165 39.0007 17.7757 39.0007 18.1165C39.0007 18.9757 38.0246 19.4029 36.3399 19.4029C36.6075 18.8077 37.5836 17.5165 38.3813 17.5165Z"
        fill={props.fill || 'white'}
      />
      <path
        d="M33.3278 22.1518C33.3278 22.0654 33.0603 21.8062 33.0603 21.8062L32.9711 21.8926C32.3517 22.4926 32.0841 22.9246 31.7323 22.9246H31.6431C31.6431 22.9246 31.5539 22.8382 31.5539 22.7518C31.5539 22.4062 32.1733 20.6926 33.417 17.6014L34.304 15.3694C34.745 14.251 35.0125 13.651 35.0125 13.5646V13.4782C35.0125 13.3918 34.9233 13.3918 34.745 13.3918H32.7085C32.7085 13.3918 32.6193 13.3918 32.6193 13.4782V13.9054H33.0603C33.3278 13.9918 33.5013 14.0782 33.5013 14.251C33.5013 14.5102 32.1733 17.6878 32.1733 17.6878C31.4648 19.4062 30.2211 22.411 30.2211 23.0974C30.2211 23.443 30.3102 23.6974 30.3994 23.7838C30.5778 23.9566 30.7562 24.043 31.0188 24.043C31.8166 24.043 32.7035 23.011 33.2337 22.3246C33.2386 22.3246 33.3278 22.1518 33.3278 22.1518Z"
        fill={props.fill || 'white'}
      />
      <path
        d="M5.22878 28.1672C5.22878 29.1128 6.02653 29.6264 6.7351 29.972L7.09185 30.1448C7.80042 30.4904 8.15717 30.9176 8.15717 31.4312C8.15717 32.0312 7.71618 32.5496 7.09185 32.5496C6.11572 32.5496 5.58554 31.604 5.31797 31.004L4.96121 31.0904C5.0504 31.6904 5.22878 32.3768 5.22878 32.636H5.40716L5.84816 32.8088C6.11572 32.8952 6.46753 32.9816 6.82429 32.9816C8.15222 32.9816 9.12835 32.2088 9.12835 31.0088C9.12835 29.8904 8.24141 29.3768 7.62204 29.1176L7.35447 29.0312C6.55672 28.604 6.19996 28.2584 6.19996 27.7448C6.19996 27.2312 6.55672 26.7128 7.17609 26.7128C7.97384 26.7128 8.3306 27.3128 8.50402 27.9128L8.86078 27.8264C8.77159 27.3128 8.6824 26.8808 8.6824 26.54L8.41483 26.4536L8.23645 26.3672C7.96888 26.2808 7.61708 26.1944 7.34951 26.1944C6.11572 26.276 5.22878 27.0488 5.22878 28.1672Z"
        fill={props.fill || 'white'}
      />
      <path
        d="M9.93026 29.7115C9.93026 31.3435 11.0848 32.8891 13.6514 32.8891C14.2708 32.8891 15.1578 32.7163 15.5145 32.6299C15.6929 32.2027 15.9555 31.4299 16.0447 30.9979L15.6879 30.9115C15.1578 31.9435 14.7118 32.4571 13.5623 32.4571C12.1451 32.4571 11.169 31.2571 11.169 29.4523C11.169 28.1659 11.7884 26.7067 13.5623 26.7067C14.6276 26.7067 15.1578 27.1339 15.4253 28.1659H15.7821L15.6929 27.7387L15.5145 26.6203H15.3361C14.9794 26.5339 14.4492 26.4475 13.7406 26.4475C11.5258 26.2747 9.93026 27.6475 9.93026 29.7115Z"
        fill={props.fill || 'white'}
      />
      <path
        d="M23.4923 26.4497H20.6581V26.7953C21.3666 26.8817 21.4558 26.8817 21.4558 27.9953V29.2817H18.4432V27.9953C18.4432 26.9633 18.5324 26.8769 19.241 26.7953V26.4497H16.3175V26.7953C17.2045 26.8817 17.2045 26.9681 17.2045 27.9953V31.4321C17.2045 32.4641 17.1153 32.5505 16.3175 32.6321V32.9777H19.241V32.6321C18.5324 32.5457 18.354 32.5457 18.354 31.4321V29.8001H21.3666V31.4321C21.3666 32.4641 21.2775 32.5505 20.4797 32.6321V32.9777H23.4031V32.6321C22.6054 32.5457 22.5162 32.5457 22.5162 31.4321V27.9953C22.5162 26.9633 22.6054 26.8769 23.4031 26.7953C23.4031 26.7905 23.4923 26.4497 23.4923 26.4497Z"
        fill={props.fill || 'white'}
      />
      <path
        d="M33.5038 26.4497H31.1997V26.7953H31.5565C31.7348 26.7953 31.824 26.8817 31.9132 26.8817C31.9132 26.9681 32.0024 26.9681 32.0024 27.0545C32.0024 27.1409 32.0024 27.3137 31.9132 27.4817C31.9132 27.5681 31.383 29.7137 30.9371 31.0865C30.8479 30.9137 29.1632 26.5361 29.1632 26.5361H28.7222C28.7222 26.5361 27.2159 31.0865 27.2159 31.1729C27.1267 31.0001 26.0614 27.3953 26.0614 27.3953C26.0614 27.2225 25.9722 27.1361 25.9722 27.0497V26.8769C26.0614 26.7905 26.1506 26.7905 26.329 26.7905H26.6857V26.5313H23.9308V26.8769C24.4609 26.9633 24.6393 26.9633 24.9069 27.8225L26.5916 32.9729H27.0326C27.0326 32.9729 28.5389 28.5089 28.6281 28.4225C28.7173 28.5953 30.3128 32.9729 30.3128 32.9729H30.7538C31.6407 29.9681 32.4384 27.9089 32.4384 27.8225C32.7952 26.9633 32.8794 26.8769 33.5038 26.8769V26.4497Z"
        fill={props.fill || 'white'}
      />
      <path
        d="M35.2791 27.9956C35.2791 27.9956 35.9877 30.0548 36.0769 30.0548H34.5706C34.4814 30.0548 35.2791 27.9956 35.2791 27.9956ZM36.5179 28.1684L35.8093 26.3636L35.3683 26.45L33.3318 31.514C32.9751 32.3732 32.8908 32.4596 32.2665 32.4596V32.8052H34.5706V32.4596H34.303C34.1246 32.4596 33.9463 32.3732 33.9463 32.2868V32.114C33.9463 32.0276 33.9463 31.9412 34.0354 31.7684L34.1246 31.5956C34.2138 31.25 34.3922 30.7364 34.4814 30.5636H36.3445C36.5228 31.0772 36.7855 31.682 36.8746 31.9364C36.8746 32.0228 36.9638 32.1092 36.9638 32.1956V32.282C36.9638 32.3684 36.8746 32.3684 36.6963 32.3684L36.4287 32.4548V32.714H39.0895V32.3684C38.5593 32.282 38.381 32.282 38.0242 31.4228L36.5179 28.1684Z"
        fill={props.fill || 'white'}
      />
      <path
        d="M41.2156 27.3074C41.2156 27.0482 41.2156 26.8802 41.3048 26.8802H41.7458C42.0134 26.8802 42.9846 26.9666 42.9846 28.0802C42.9846 28.853 42.4544 29.2802 41.5674 29.2802H41.2107C41.2156 29.2802 41.2156 27.3074 41.2156 27.3074ZM39.3575 26.4482V26.7938C40.0661 26.8802 40.1553 26.8802 40.1553 27.9938V31.4306C40.1553 32.4626 40.0661 32.549 39.2683 32.6306V32.9762H41.7508C42.5485 32.9762 43.2571 32.8034 43.703 32.549C44.3224 32.2034 44.59 31.6898 44.59 31.0898C44.59 30.2306 43.9706 29.6306 42.8161 29.4578C43.3463 29.3714 44.2332 28.9442 44.2332 28.085C44.2332 27.5714 44.0548 27.2258 43.703 26.9666C43.3463 26.7074 42.9053 26.621 42.1075 26.621H39.3575V26.4482ZM41.5724 29.5394C42.7269 29.5394 43.3463 30.053 43.3463 30.9986C43.3463 31.9442 42.6377 32.3714 42.0183 32.3714C41.7508 32.3714 41.5773 32.3714 41.4882 32.285C41.3098 32.1122 41.3098 31.8578 41.3098 31.3394V29.621C41.3048 29.5394 41.5724 29.5394 41.5724 29.5394Z"
        fill={props.fill || 'white'}
      />
    </svg>
  );
}

const MemoNoData = React.memo(CharlesSchwabIcon);
export default MemoNoData;
