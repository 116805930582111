import React, { useState, useEffect, useRef } from 'react';
import Slider from 'react-slick';
import {
  SplashCardContainer,
  ChatCard,
  StyledButton,
  DotsContainer,
} from './style';
import { Chat1, Chat2, Chat3, Chat4 } from './utils';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

interface CallBackProp {
  callBack: () => void;
}
function SplashSlider({ callBack }: CallBackProp): React.JSX.Element {
  const [activeSlide, setActiveSlide] = useState<number>(0);
  const [activeSlide2, setActiveSlide2] = useState<number>(0);
  const sliderRef = useRef<any>(null);
  console.log(' active slide--->', activeSlide, activeSlide2);

  useEffect(() => {
    const handleKeyPress = (e: any) => {
      if (e.keyCode === 39) {
        console.log('right key');
        // Right arrow key (keyCode 39) is pressed, trigger forceStartAutoPlay
        forceStartAutoPlay();
      } else if (e.keyCode === 37) {
        console.log('left key');
      }
    };
    // Attach the event listener when the component mounts
    document.addEventListener('keydown', handleKeyPress);

    // Remove the event listener when the component unmounts
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  useEffect(() => {
    if (activeSlide2 === 4 && typeof callBack === 'function') {
      callBack();
    }
  }, [activeSlide2, callBack]);

  const skipIntro = () => {
    console.log('skip clicked');
    if (sliderRef && sliderRef.current) {
      sliderRef.current.slickGoTo(9);
      if (typeof callBack === 'function') {
        callBack();
      }
    }
  };

  const forceStartAutoPlay = () => {
    console.log('forceStartAutoPlay...');
    if (sliderRef && sliderRef.current) {
      const currentSlide = sliderRef.current.innerSlider.state.currentSlide;
      const nextSlide = currentSlide + 1;

      // If the next slide is beyond the last slide, navigate to the conversation page
      if (nextSlide > 3) {
        if (typeof callBack === 'function') {
          callBack();
        }
      } else {
        // Otherwise, proceed with the next slide
        sliderRef.current.slickGoTo(nextSlide);
        sliderRef.current.slickPlay();
      }
    }
  };
  const [autoPlaySlider, setAutoplay] = useState(true);
  const handlePause = () => {
    if (autoPlaySlider) {
      setAutoplay(!autoPlaySlider);
    } else {
      setAutoplay(!autoPlaySlider);
      sliderRef.current.slickPlay();
    }
  };
  const settings = {
    dots: true,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: autoPlaySlider,
    speed: 500,
    autoplaySpeed: 3500,
    pauseOnHover: false,
    adaptiveHeight: true,
    nextArrow: <></>,
    prevArrow: <></>,
    focusOnSelect: false,
    beforeChange: (current: any, next: any) => setActiveSlide(next),
    afterChange: (current: any) => {
      setActiveSlide2(current);
      // Check if the last slide is reached
      if (current === 3 && typeof callBack === 'function') {
        setTimeout(() => {
          callBack();
        }, 3000);
      }
    },
  };

  return (
    <React.Fragment>
      <SplashCardContainer
        container
        className="splash-screen-container"
        justifyContent="center"
        alignItems="center"
        onClick={forceStartAutoPlay}
      >
        <div style={{ width: '100%', height: '100%' }}>
          <Slider ref={sliderRef} {...settings}>
            <div style={{ display: 'flex' }}>
              <ChatCard style={{ width: '100%' }}>
                <Chat1 />
              </ChatCard>
            </div>
            <div style={{ display: 'flex' }}>
              <ChatCard style={{ width: '100%' }}>
                <Chat2 />
              </ChatCard>
            </div>
            <div style={{ display: 'flex' }}>
              <ChatCard style={{ width: '100%' }}>
                <Chat3 />
              </ChatCard>
            </div>
            <div style={{ display: 'flex' }}>
              <ChatCard style={{ width: '100%' }}>
                <Chat4 />
              </ChatCard>
            </div>
          </Slider>
          <div
            style={{
              position: 'absolute',
              top: '80%',
              left: '10.7%',
              transform: 'translateY(-50%)',
              zIndex: 99,
              display: activeSlide === 4 ? 'none' : 'block',
            }}
          >
            <StyledButton onClick={skipIntro}>Skip</StyledButton>
          </div>
          <DotsContainer
            style={{
              display: activeSlide === 4 ? 'none' : 'block',
              cursor: 'pointer',
            }}
            onMouseEnter={() => {
              handlePause();
            }}
            onMouseLeave={() => {
              handlePause();
            }}
          >
            {Array.from({ length: 4 }).map((_, index) => (
              <Dot key={index} active={index === activeSlide} />
            ))}
          </DotsContainer>
        </div>
      </SplashCardContainer>
    </React.Fragment>
  );
}

const Dot = ({ active }: { active: boolean }) => (
  <span
    style={{
      width: '70px',
      height: '7px',
      margin: '0 5px',
      borderRadius: '20px',
      backgroundColor: active ? '#101828' : '#fff',
      display: 'inline-block',
    }}
  />
);

export default SplashSlider;
