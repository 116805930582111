import React from 'react';
import { Typography, IconButton } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import RowCollapse from './RowCollapse';
import { fontStyles, StyledTableCell, StyledTableRow } from './styles';
import { isEmpty } from 'utils/isEmpty';

interface PropsType {
  row: any;
  index: number;
  columns: Array<any>;
  baseLineActive: string;
}

const Row = (props: PropsType): React.JSX.Element => {
  const { row, index, columns = [], baseLineActive = '' } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment key={index}>
      <StyledTableRow
        sx={{
          '&.MuiTableRow-root': {
            cursor: 'pointer',
            '& .MuiTableCell-root': {
              ...(open && { borderBottom: 'none' }),
            },
          },
        }}
        onClick={() => setOpen(!open)}
      >
        <StyledTableCell component="td" scope="row" sx={{}}>
          <IconButton
            aria-label="expand row"
            size="small"
            //onClick={() => setOpen(!open)}
            sx={{
              '&.MuiButtonBase-root': {
                '&.MuiIconButton-root': {
                  color: '#464646',
                  height: '28px',
                  '&:hover': {
                    background: '#fff',
                    color: '#3A8482',
                  },
                },
              },
            }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </StyledTableCell>
        {columns.map((column, columnIndex) => {
          return (
            <StyledTableCell
              key={`td-${index}-${columnIndex}`}
              component="td"
              align="left"
              sx={{
                '&.MuiTableCell-root': {
                  ...(columnIndex > 1 &&
                    !row?.results[column?.value]?.matchBaseline && {
                      background: 'pink',
                    }),
                  ...(baseLineActive === column.value && {
                    background: '#a6d6d433',
                  }),
                },
                border: '0.5px solid #eee',
              }}
            >
              {isEmpty(column.value) ? (
                <React.Fragment>&nbsp;</React.Fragment>
              ) : (
                <Typography
                  component={'div'}
                  className="ellipsis-line-3"
                  dangerouslySetInnerHTML={{
                    __html: !isEmpty(row[column.value])
                      ? column.value === 'query' ||
                        column.value === 'classification'
                        ? row[column.value]
                        : row?.results[column?.value]?.matchBaseline
                          ? 'Passed'
                          : 'Failed'
                      : 'NA',
                  }}
                  sx={{
                    ...fontStyles,
                    maxWidth: '50vw',
                    height: '100%',
                    overflow: 'hidden',
                  }}
                />
              )}
            </StyledTableCell>
          );
        })}
      </StyledTableRow>
      <RowCollapse
        row={row}
        index={index}
        open={open}
        columns={columns}
        baseLineActive={baseLineActive}
      />
    </React.Fragment>
  );
};

export default Row;
