import React, { useState } from 'react';
import FirmListingPage from './FirmListingPage';
import { useRESTQuery } from '@hooks/useRESTQuery';
import EnterpriseListingPage from './EnterpriseListingPage';
import FirmCardDetails from './FirmCardDetails';
import { SuperAdminWrapper } from './styles';
import Loader from '@components/Loader';

function SuperAdminPage(): React.JSX.Element {
  const [stepper, setStepper] = useState<
    'ENTERPRISE_LISTING_PAGE' | 'FIRM_LISTING_PAGE' | 'FIRM_DETAILS_PAGE'
  >('ENTERPRISE_LISTING_PAGE');
  const [enterpriseDetails, setEnterpriseDetails] = useState({});
  const [firmDetails, setFirmDetails] = useState();

  const { data: allEntList, isLoading } = useRESTQuery(
    ['get-all-enterprise'],
    {
      endpoint: `/enterprise/`,
      method: 'GET',
      headers: {
        'X-Tifin-Ai-Auth': localStorage.getItem('x-tifin-ai-token'),
      },
    },
    {
      select: (response: any) => response?.data,
      onSuccess: (response: any) => {
        console.log('success response--->', response);
      },
      onError: (error: any) => {
        console.log(error);
      },
    },
  );

  if (isLoading) return <Loader />;

  return (
    <SuperAdminWrapper container>
      {(() => {
        switch (stepper) {
          case 'FIRM_LISTING_PAGE' /*Case 0 */:
            return (
              <FirmListingPage
                setStepper={setStepper}
                enterpriseDetails={enterpriseDetails}
                setFirmDetails={setFirmDetails}
              />
            );
          case 'FIRM_DETAILS_PAGE':
            return (
              <FirmCardDetails
                setStepper={setStepper}
                firmDetails={firmDetails}
              />
            );
          default:
            return (
              <EnterpriseListingPage
                setStepper={setStepper}
                stepper={stepper}
                setEnterpriseDetails={setEnterpriseDetails}
                enterpriseData={allEntList}
              />
            );
            break;
        }
      })()}
    </SuperAdminWrapper>
  );
}

export default SuperAdminPage;
