/* eslint-disable react/prop-types */

import React from 'react';

function BoltIcon(props: any): React.JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || '32'}
      height={props.height || '32'}
      fill="none"
      viewBox="0 0 32 32"
    >
      <rect
        width="32"
        height="32"
        fill={props.fill || '#98A2B3'}
        rx="2.667"
      ></rect>
      <path
        stroke={props.stroke || '#fff'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.8"
        d="M14 23l1-5-4-1.5L18 9l-1 5 4 1.5-7 7.5z"
      ></path>
    </svg>
  );
}

const MemoNoData = React.memo(BoltIcon);
export default MemoNoData;
