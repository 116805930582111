/* eslint-disable react/no-unescaped-entities */
import React, { useMemo } from 'react';
import Table from './Table';
import { Box } from '@mui/material';

const InvestmentColumn = ({
  name,
  ticker,
  onClick,
}: any): React.JSX.Element => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ fontSize: '11px' }}>{name}</Box>
      <Box
        sx={{
          width: 'fit-content',
          color: 'gray',
          mt: '3px',
          cursor: 'pointer',
          ':hover': { textDecoration: 'underline' },
        }}
        onClick={() => onClick(ticker)}
      >
        {ticker}
      </Box>
    </Box>
  );
};

const SecuritiesTable = ({
  data,
  setTicker,
}: {
  data: any;
  setTicker: any;
}): React.JSX.Element => {
  const transformedData = useMemo(() => {
    const tableData = data?.map(item => ({
      ticker: item['Ticker'],
      name: item['Name'],
      columns: item.data,
    }));

    return tableData.map(item => {
      const transformedDataItem = {
        investments: (
          <InvestmentColumn
            name={item.name}
            ticker={item.ticker}
            onClick={setTicker}
          />
        ),
      };
      item.columns?.forEach(dataItem => {
        transformedDataItem[dataItem.field_name] =
          typeof dataItem.value === 'number'
            ? `${(dataItem.value * 100).toFixed(2)}%`
            : dataItem.value;
      });

      return transformedDataItem;
    });
  }, [data]);

  return <Table data={transformedData} transformed={true} />;
};

export default SecuritiesTable;
