import styled from 'styled-components';

export const SuggestedFollowUpsContainer = styled.ul`
  margin: 0;
  padding: 0;
  width: 100%;
  list-style: none;
  color: #000;

  &.disabled {
    color: #818181;
  }
`;

export const SuggestedFollowUpsLi = styled.li`
  &:first-child {
    border-top: 1px solid #ececec;
  }
`;

export const SuggestedFollowUpsLink = styled.span`
  display: flex;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #ececec;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;

  &:hover { color: #1bc29a; }

  ${SuggestedFollowUpsContainer}.disabled & {
    cursor: default;
  }
`;

export const SuggestedFollowUpsMarker = styled.span`
  display: inline-block;
  margin-right: 6px;
  font-size: 18px;

  ${SuggestedFollowUpsContainer}:not(.disabled) ${SuggestedFollowUpsLink}:hover & {
    transition: transform 30s linear;
    transform: rotate(3600deg);
  }
`;
