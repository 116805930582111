import React, { useEffect, useState } from 'react';
import SplashSlider from './SplashSlider';

interface SplashScreenProps {
  callBack: () => void;
}

function SplashScreen({ callBack }: SplashScreenProps): React.JSX.Element {
  const [content, setContent] = useState(<div></div>);
  const onBoarding: any = localStorage.getItem('onboarding-state');
  useEffect(() => {
    if (onBoarding === 1 || onBoarding === '1') {
      setTimeout(() => {
        setContent(<SplashSlider callBack={callBack} />);
      }, 500);
    }
  }, [onBoarding, callBack]);

  return <React.Fragment>{content}</React.Fragment>;
}

export default SplashScreen;
