import React from 'react';
import {
  ComparisonContainerHeader,
  GraphContainer,
} from '@pages/ClientsV2/styles';
import SimpleLineGraph from '@components/UIComponents/SimpleLineGraph';
import { validArray } from '@common/validArray';
import CustomLegend from '@components/UIComponents/SimpleLineGraph/CustomLegend';
import { EColorMapping } from 'app/enums';
import { IHistoricalReturnsChatProps } from '../types';

const HistoricalReturnsChat: React.FC<IHistoricalReturnsChatProps> = ({
  content,
  currentPortfolioName,
  recommendedPortfolioName,
}) => {
  // Creating an array of objects for rendering legends with dynamic names and colors
  const customLegendItems = [
    {
      color: EColorMapping.Current,
      value: currentPortfolioName || 'Current Portfolio',
    },
    {
      color: EColorMapping.Proposed,
      value: recommendedPortfolioName || 'Proposed Portfolio',
    },
  ];

  // Collecting all unique keys (excluding 'date') from objects in formattedData
  const allKeys =
    (validArray(content) &&
      content?.reduce((keys, obj) => {
        Object.keys(obj).forEach((key: string) => {
          if (key !== 'date') {
            keys.add(key);
          }
        });
        return keys;
      }, new Set<string>())) ||
    new Set<string>();

  // Prepares data for rendering lines in the graph, assigning colors and names based on dynamic inputs
  const lineData = [...allKeys].map(key => ({
    dataKey: key,
    stroke:
      EColorMapping[
        (key.charAt(0).toUpperCase() +
          key.slice(1)) as keyof typeof EColorMapping
      ] || '#000000',
    name: key === 'current' ? currentPortfolioName : recommendedPortfolioName,
  }));

  return (
    validArray(content) && (
      <GraphContainer item container xs={12} sm={12}>
        <ComparisonContainerHeader>
          <strong>Performance</strong>
        </ComparisonContainerHeader>
        <SimpleLineGraph data={content} lines={lineData} />
        <CustomLegend customLegendItems={customLegendItems} />
      </GraphContainer>
    )
  );
};

export default HistoricalReturnsChat;
