import instance from 'utils/instances';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const viewpointStatus = async (payload: any) => {
  const createResponse = await instance.post('/viewpoints/publish', payload, {
    headers: {
      'x-tifin-ai-auth': localStorage.getItem('x-tifin-ai-token'),
      'content-type': undefined,
    },
  });
  return createResponse?.data;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const updateViewpoint = async (payload: FormData) => {
  const createResponse = await instance.put(
    `/viewpoints/extract?id=${payload.get('id')}`,
    payload,
    {
      headers: {
        'x-tifin-ai-auth': localStorage.getItem('x-tifin-ai-token'),
        'content-type': undefined,
      },
    },
  );
  return createResponse?.data;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const deleteViewpoint = async payload => {
  const createResponse = await instance.delete(
    `/viewpoints/?${payload.key}=${payload.value}`,
    {
      headers: {
        'x-tifin-ai-auth': localStorage.getItem('x-tifin-ai-token'),
        'content-type': undefined,
      },
    },
  );
  return createResponse?.data;
};
