import { Button, Tooltip } from '@mui/material';
import React from 'react';

import {
  CRMCardContainer,
  CRMCardWrapper,
  DescContainer,
  HeaderPartWrapper,
  ImgContainer,
  StyledBadgeI,
  TitleContainerNew,
} from './styles';

interface CRMCardDataProps {
  logo: React.ReactNode;
  name: string;
  description: string;
  crmData: any;
}

function CRMCard({
  logo,
  name,
  description,
  crmData,
}: CRMCardDataProps): React.JSX.Element {
  return (
    <>
      <CRMCardContainer>
        <CRMCardWrapper>
          <HeaderPartWrapper>
            <ImgContainer style={{ display: 'flex', height: '50px' }}>
              {logo}
            </ImgContainer>
            {crmData && (
              <StyledBadgeI $status={crmData?.status}>
                {crmData?.status}
              </StyledBadgeI>
            )}
          </HeaderPartWrapper>
          <TitleContainerNew>{name}</TitleContainerNew>
          {description.length > 75 ? (
            <Tooltip title={description} placement="top">
              <DescContainer>
                {`${description.substring(0, 75)}...`}
              </DescContainer>
            </Tooltip>
          ) : (
            <DescContainer>{description}</DescContainer>
          )}

          {(() => {
            switch (crmData?.status) {
              case 'CONNECTED':
                return (
                  <Button
                    variant="outlined"
                    style={{
                      width: '100%',
                      borderRadius: '20px',
                      border: '1px solid var(--neutral-200, #EFEFEF)',
                      boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.05)',
                      textTransform: 'none',
                      color: '#101828',
                      fontSize: '14px',
                      backgroundColor: 'white',
                    }}
                  >
                    Sync
                  </Button>
                );
              default:
                return (
                  <Button
                    variant="outlined"
                    style={{
                      width: '100%',
                      borderRadius: '20px',
                      border: '1px solid var(--neutral-200, #EFEFEF)',
                      boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.05)',
                      textTransform: 'none',
                      color: '#101828',
                      fontSize: '14px',
                      backgroundColor:
                        crmData?.status === 'CONNECTED' ? '#e5e6e8' : 'white',
                    }}
                  >
                    Connect
                  </Button>
                );
                break;
            }
          })()}
        </CRMCardWrapper>
      </CRMCardContainer>
    </>
  );
}

export default CRMCard;
