// GlobalSlice/saga.ts
import {
  AllEffect,
  ForkEffect,
  all,
  put,
  takeLatest,
} from 'redux-saga/effects';
import { actions } from './slice';

function* searchQuery() {
  try {
    // Show a toast message
    yield put(
      actions.displayToast({
        duration: 3000,
        toastMessage: 'Async action completed!',
        toastType: 'success',
      }),
    );
  } catch (error) {
    console.error('Async action error:', error);
  }
}

export function* globalSaga(): Generator<
  AllEffect<ForkEffect<never>>,
  void,
  unknown
> {
  yield all([
    // Define your sagas here
    takeLatest('CHAT_SEARCH_QUERY_FETCH', searchQuery),
  ]);
}
