import { CardContent, Grid, styled, Typography } from '@mui/material';

interface StyledCardImgContainerProps {
  avatarUrl: string;
}

interface StyledRectangleProps {
  height: string;
}

export const EntAdminCardContainer = styled(Grid)`
  max-height: 100%;
  overflow-y: auto;
`;

export const EntAdminCardWrapper = styled(Grid)`
  padding-top: 20px;
  flex-wrap: wrap;
  gap: 16px;
`;

export const StyledTitleContainer = styled(Grid)`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
`;

export const HeaderSubText = styled(Grid)`
  width: 100%;
  display: flex;
  flex-direction: row;
  font-size: 12px;
  color: #667085;
  margin-top: 5px;
`;

export const StyledCardImgContainer = styled(Grid)<StyledCardImgContainerProps>`
  display: flex;
  border-radius: 24px;
  background:
    url(${props => props.avatarUrl}),
    lightgray 50% / cover no-repeat,
    #f9fafb;
`;

export const AdminTitleContainer = styled(Grid)`
  padding-left: 25px;
  width: 20%;
  display: flex;
  flex-direction: column;
  padding-right: 10px;
`;

export const StyledNameLabel = styled(Typography)`
  color: #667085;
  font-size: 12px;
`;

export const StyledNameDiv = styled(Typography)`
  color: #101828;
  font-size: 15px;
  padding-top: 3px;
`;

export const AdminEmailContainer = styled(Grid)`
  padding-right: 10px;
`;

export const StyledEmailLabel = styled(Grid)`
  color: #667085;
  font-size: 12px;
`;

export const StyledEmailDiv = styled(Grid)`
  color: #101828;
  font-size: 15px;
  padding-top: 3px;
`;

export const StyledTypography = styled(Typography)`
  color: 1d2939;
  font-size: 12px;
  padding-bottom: 10px;

  @media (min-width: 600px) {
    font-size: 16px;
  }
`;

export const AddFirmAdminButtonWrapper = styled(Grid)`
  color: #000;
  border-radius: 20px;
  height: 45px;
  font-size: 14px;
  text-transform: none;
  background: linear-gradient(0deg, #fff 0%, #d5d5d5 921.25%);
  background-color: transparent;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.12);
  border: 1px solid #e4e7ec;
  cursor: pointer;
  @media (min-width: 900px) {
    font-size: 16px;
  }
`;

export const FirmAdminCard = styled(Grid)`
  padding: 15px;
  border: 1px solid #e4e7ec;
  background: #fff;
  border-radius: 8px;
  height: 65px;
`;

export const StyledHeaderDiv = styled(Grid)`
  background: transparent;
  border-bottom: 2px solid var(--Neutral-100, #f2f4f7);
  padding: 0px; /* Default for smallest screens */

  @media (min-width: 600px) {
    padding: initial; /* Revert to default or add specific padding for larger screens */
  }
`;

export const StyledCardContainer = styled(Grid)`
  padding: 15px;
  min-height: 130px; /* Default for smallest screens */
  background: #fff;
  border: 1px solid var(--Neutral-100, #f2f4f7);
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.12);
  cursor: pointer;
  transition: box-shadow 0.3s ease;

  &:hover {
    box-shadow: 0px 4px 8px 0px rgba(16, 24, 40, 0.2);
  }

  @media (min-width: 600px) {
    min-height: 150px; /* Apply larger height for screens wider than 600px */
  }
`;

export const StyledSquare = styled(Grid)`
  width: 65px;
  height: 65px;
  background: #f2f4f7;
  border-radius: 4px;
`;

export const StyledCardContent = styled(CardContent)`
  padding: 20px 0px 0px 0px;
`;

export const FirmAdminCardListSingularDiv = styled(Grid)`
  border: 1px solid #e4e7ec;
  border-radius: 8px;
  padding: 10px;
  background: #fff;
`;

export const StyledRectangle = styled(Grid)<StyledRectangleProps>`
  width: 50px;
  height: ${props => props.height};
  background: #f2f4f7;
  border-radius: 4px;
  margin-right: 10px;
`;
