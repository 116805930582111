import { Grid, Typography } from '@mui/material';
import styled from 'styled-components';
// import { LayoutSize } from 'utils/styles/variables';

export const FooterContainer = styled(Grid)`
  width: 100%;
`;

export const DateWrapper = styled(Grid)`
  width: 100%;
`;

export const DateLabel = styled(Typography)`
  padding-bottom: 3px;
  color: #fff;
`;

export const DateText = styled(Typography)`
  color: #fff;
`;
