/* eslint-disable react/prop-types */

import React from 'react';

function RightArrow(props: any): React.JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke={props.stroke || '#282829'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M6 12l4-4-4-4"
      ></path>
    </svg>
  );
}

const MemoRightArrow = React.memo(RightArrow);
export default MemoRightArrow;
