const getRandomColor = (): string => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const getUniqueRandomColors = (numColors: number): string[] => {
  const colorSet = new Set<string>();
  while (colorSet.size < numColors) {
    colorSet.add(getRandomColor());
  }
  return Array.from(colorSet);
};

export const largeColorSet = [
  '#00A9D7',
  '#F540DA',
  '#76E0D3',
  '#4DD56E',
  '#CC382D',
  '#9CE554',
  '#EAA350',
  '#D7162E',
  '#3C6FE9',
  '#F94332',
  '#BB89F3',
  '#4DECFA',
  '#23ED6D',
  '#0EAFA9',
  '#3DBE97',
  '#DAD986',
  '#564F5B',
  '#8261DE',
  '#5B51C3',
  '#5DE721',
  '#128011',
  '#050F34',
  '#D016B5',
];
