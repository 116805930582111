import { handleError } from '@common/error';
import { resendInvite } from '@pages/SuperAdminPage/mutation';
import { StyledResendLink } from '@pages/SuperAdminPage/styles';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { actions as globalActions } from 'app/features/Global/slice';
import React from 'react';

interface InviteStatusProps {
  status?: string;
  id?: string;
}

const InviteStatus: React.FC<InviteStatusProps> = ({ status, id }) => {
  const resendInviteMutation = useMutation(resendInvite);
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const handleInvite = (id: string) => {
    resendInviteMutation.mutate(
      { id },
      {
        onSuccess: (response: any) => {
          if (response && response.message) {
            queryClient.invalidateQueries('firm-details');
            dispatch(
              globalActions.displayToast({
                duration: 3000,
                toastType: 'success',
                toastMessage: response?.message,
              }),
            );
          } else {
            dispatch(
              globalActions.displayToast({
                duration: 3000,
                toastType: 'error',
                toastMessage: 'Unexpected response from the server.',
              }),
            );
          }
        },
        onError: (error: any) => {
          handleError(dispatch, error);
        },
      },
    );
  };
  if (status === 'INVITE_SENT') {
    return (
      <StyledResendLink onClick={() => handleInvite(id as string)}>
        Resend
      </StyledResendLink>
    );
  }
  return <>Accepted</>;
};
export default InviteStatus;
