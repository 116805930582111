export const ITEM_TYPES = {
  text: 'text',
  markdown: 'markdown',
  suggestions: 'suggestions',
  plot: 'plot',
  enhancement_data: 'enhancement_data',
  options: 'options',
  cio_card: 'cio_card',
  portfolio_card: 'portfolio_card',
  cio_details_card: 'cio_details_card',
  barchart: 'barchart',
  bullet_points: 'bullet_points',
  sample_categories: 'sample_categories',
  sample_questions: 'sample_questions',
  sample_basic_queries: 'sample_basic_queries',
  explanation: 'explanation',
  security_table: 'security_table',
  table: 'table',
  whatElseButton: 'whatElseButton',
  search_data: 'search_data',
  generate_proposal: 'generate_proposal',
  send_assessment: 'send_assessment',
  risk_questionnaire_pdf: 'risk_questionnaire_pdf',
  risk_methodology_pdf: 'risk_methodology_pdf',
  historical_returns: 'historical_returns',
  risk_details: 'risk_details',
  source_document: 'source_document',
  performance_graph: 'performance_graph',
  allocation_bar_chart: 'allocation_bar_chart',
  upload_model: 'upload_model',
};
