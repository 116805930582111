// AddClientForm.tsx
import React, { useEffect, useState } from 'react';
import {
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Grid,
  Select,
  MenuItem,
} from '@mui/material';
import { styled } from '@mui/system';
import { StyledInputLabel } from '@pages/Profile/styles';
import { useDispatch } from 'react-redux';
import { actions as globalActions } from 'app/features/Global/slice';
import { OPTIONS_QUERY_TYPE } from '@pages/TestResults/Queries/QueryAdd/constants';

interface AddClientFormProps {
  open: boolean;
  isLoading: boolean;
  onClose: () => void;
  type: string;
  selectedQuery?: {
    query: string;
    classification: string;
    id: string;
  };
  onSubmit: (data: { query: string; classification: string }) => void;
}

const CenteredDialogActions = styled(DialogActions)({
  justifyContent: 'center',
  paddingBottom: '20px', // Add padding to separate buttons from content
});

const StyledButton = styled(Button)({
  margin: '0 10px',
});

const AddQuery: React.FC<AddClientFormProps> = ({
  open,
  type,
  onClose,
  onSubmit,
  isLoading,
  selectedQuery,
}) => {
  const [query, setQuery] = useState<string>('');
  const [classification, setClassification] = useState<string>('');
  const dispatch = useDispatch();

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Validate the form data
    if (!query.trim() || !classification.trim()) {
      // Display an error message for empty fields
      dispatch(
        globalActions.displayToast({
          duration: 3000,
          toastType: 'error',
          toastMessage: 'Please fill all the mandatory fields.',
        }),
      );
      return; // Prevent the form submission if validation fails
    }
    // Call the onSubmit callback with the form data
    onSubmit({ query, classification });
  };

  useEffect(() => {
    if (type === 'add') {
      setQuery('');
      setClassification('');
    }
  }, [type]);
  useEffect(() => {
    if (type === 'update' && selectedQuery) {
      setQuery(selectedQuery.query);
      setClassification(selectedQuery.classification);
    }
  }, [selectedQuery]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>{type === 'add' ? 'Add Query' : 'Update Query'}</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <StyledInputLabel>
                <Typography variant="subtitle2">Query*</Typography>
              </StyledInputLabel>
              <TextField
                variant="outlined"
                fullWidth
                size={'small'}
                type="text"
                value={query}
                required
                onChange={e => setQuery(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <StyledInputLabel>
                <Typography variant="subtitle2">Classification*</Typography>
              </StyledInputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                fullWidth
                size={'small'}
                value={classification}
                label="Age"
                onChange={e => setClassification(e.target.value)}
              >
                {OPTIONS_QUERY_TYPE.map(item => {
                  return (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
          </Grid>
        </DialogContent>
        <CenteredDialogActions>
          <StyledButton
            type="button"
            variant="outlined"
            color="primary"
            onClick={onClose}
          >
            Cancel
          </StyledButton>
          <StyledButton
            type="submit"
            variant="contained"
            color="primary"
            disabled={isLoading}
            onClick={handleSubmit}
          >
            {isLoading ? 'Adding...' : 'Submit'}
          </StyledButton>
        </CenteredDialogActions>
      </form>
    </Dialog>
  );
};

export default AddQuery;
