import React, { Fragment, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Grid, useMediaQuery } from '@mui/material';
import { Logout, ChevronLeft, ChevronRight } from '@mui/icons-material';
import { getHeaderMenuList } from '@features/Global/selector';
import SageBlackLogo from '@icons/sage_logo_black';
import { NavButton } from '@components/Button';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { useOktaAuth } from '@okta/okta-react';
import { actions as profileActions } from '@features/Profile/slice';

import { useDispatch } from 'react-redux';
import { getAuthDetails } from '@features/Profile/selector';
import {
  NavMenuWrapper,
  LogoContainer,
  ToggleButton,
  LogoutWrapper,
  LogoMask,
  ProfileIconWrapper,
} from './styles';
import theme from '@styles/theme';
import MemoSageBlackLogoMobile from '@icons/sage_logo_mobile';

interface SideNavProps {
  collapse: boolean;
  onArrowClick:
    | ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void)
    | undefined;
}

export default function SideNav({
  collapse = false,
  onArrowClick,
}: SideNavProps): React.JSX.Element {
  const location = useLocation();
  const navigate = useNavigate();

  const isDesktopDevice = useMediaQuery(theme.breakpoints.up('md'));

  const { oktaAuth } = useOktaAuth();
  const { pathname } = location;
  const dispatch = useDispatch();
  const profileState = useSelector(getAuthDetails);
  const HeaderMenuList = useSelector(getHeaderMenuList);
  const userType = localStorage.getItem('tifin-ai-user-type');
  const [reRoute, setReRoute] = useState(false);
  const handleLogout = async () => {
    localStorage.clear();
    try {
      await oktaAuth.signOut();
    } catch (error) {
      console.error('Sign out error:', error);
    }
  };

  const handleRemoveImpersonation = async () => {
    localStorage.removeItem('impersonation-id');
    const token = localStorage.getItem('x-tifin-ai-token') as string;
    if (token) {
      setReRoute(true);
      dispatch(
        profileActions.userInfoRequest({
          token,
        }),
      );
    }
  };
  useEffect(() => {
    if (reRoute) {
      setReRoute(false);
      if (
        profileState?.role?.length > 0 &&
        (profileState.termsAndConditionsAcceptedAt === '' ||
          profileState.termsAndConditionsAcceptedAt === null)
      ) {
        localStorage.setItem('onboarding-state', '1');
        navigate('/terms-condition');
      } else {
        if (profileState.role === 'ADVISOR') {
          navigate('/conversations');
        } else if (profileState.role === 'FIRM_ADMIN') {
          navigate('/manage-firms');
        } else if (profileState.role === 'SUPER_ADMIN') {
          navigate('/admin-console');
        } else if (profileState.role === 'ENTERPRISE_ADMIN') {
          navigate('/firms');
        }
      }
    }
  }, [profileState]);

  const onMenuClick = (path: any) => {
    navigate(path);
  };

  const handleProfileClick = () => {
    console.log('clicked');
    navigate('/profile');
  };

  console.log(pathname);
  const isRouteActive = (path: string) => pathname.startsWith(path);

  console.log({ HeaderMenuList }, 'HeaderMenuList');
  console.log({ userType }, 'userType');
  return (
    <Fragment>
      <LogoContainer container collapse={collapse} alignItem={'center'}>
        {profileState.role === 'ADVISOR' ||
        profileState.role === 'FIRM_ADMIN' ? (
          <Link aria-label="conversations" to={'/conversations'}>
            <LogoMask collapse={collapse} isDesktopDevice={isDesktopDevice}>
              {isDesktopDevice ? (
                <SageBlackLogo
                  fill={'#283345'}
                  width={'100%'}
                  height={'46'}
                  style={{ marginRight: '50px' }}
                />
              ) : (
                <MemoSageBlackLogoMobile />
              )}
            </LogoMask>
          </Link>
        ) : (
          <LogoMask collapse={collapse} isDesktopDevice={isDesktopDevice}>
            {isDesktopDevice ? (
              <SageBlackLogo
                fill={'#283345'}
                width={'100%'}
                height={'46'}
                style={{ marginRight: '50px' }}
              />
            ) : (
              <MemoSageBlackLogoMobile />
            )}
          </LogoMask>
        )}

        {isDesktopDevice && (
          <ToggleButton
            role="toggle button"
            aria-label="expand/collapse button"
            collapse={collapse}
            onClick={onArrowClick}
            size={'large'}
            disabled={!isDesktopDevice}
          >
            {collapse ? (
              <ChevronRight style={{ color: '#333' }} />
            ) : (
              <ChevronLeft style={{ color: '#333' }} />
            )}
          </ToggleButton>
        )}
      </LogoContainer>
      <NavMenuWrapper
        container
        flexDirection={'column'}
        justifyContent={'space-between'}
      >
        <Grid
          item
          container
          px={isDesktopDevice ? 3 : 1.5}
          rowGap={1}
          margin={'0 auto'}
          justifyContent={'center'}
        >
          {HeaderMenuList.map((item, index) => {
            if (item.showToUser.includes(userType)) {
              return (
                <NavButton
                  key={index}
                  collapse={collapse}
                  cursor={item.cursor}
                  tooltipText={item.name}
                  startIcon={
                    item.Icon && (
                      <Fragment>
                        {typeof item.Icon === 'string' ? (
                          <img src={item.Icon} alt={item.name} />
                        ) : (
                          <item.Icon />
                        )}
                      </Fragment>
                    )
                  }
                  active={isRouteActive(item.path)}
                  enableNotifications={item.enableNotifications}
                  onClick={() =>
                    item.cursor !== 'not-allowed' && onMenuClick(item.path)
                  }
                >
                  {item.name}
                </NavButton>
              );
            }
          })}
        </Grid>
        <LogoutWrapper container alignItems="center">
          {!localStorage.getItem('impersonation-id') && (
            <NavButton
              collapse={collapse}
              cursor={'pointer'}
              tooltipText={'Logout'}
              startIcon={<Logout />}
              active={false}
              enableNotifications={false}
              onClick={() => handleLogout()}
            >
              Logout
            </NavButton>
          )}
          {localStorage.getItem('impersonation-id') && (
            <NavButton
              collapse={collapse}
              cursor={'pointer'}
              tooltipText={'Exit Impersonation'}
              startIcon={<Logout />}
              active={false}
              enableNotifications={false}
              onClick={() => handleRemoveImpersonation()}
            >
              Exit Impersonation
            </NavButton>
          )}
          {!collapse ? (
            <NavButton
              collapse={collapse}
              cursor={'pointer'}
              tooltipText={'My Profile'}
              startIcon={<AccountCircleOutlinedIcon />}
              active={false}
              enableNotifications={false}
              onClick={() => handleProfileClick()}
            >
              My Profile
            </NavButton>
          ) : (
            <ProfileIconWrapper onClick={() => handleProfileClick()}>
              RT
            </ProfileIconWrapper>
          )}
        </LogoutWrapper>
      </NavMenuWrapper>
    </Fragment>
  );
}
