import React from 'react';
import { CustomTextField, Filters, OutlineBox, TabsContainer } from './styles';
import { Grid, Menu, MenuItem, Tab } from '@mui/material';
import { clientFilters } from '@common/constant';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import clientSearch from '@images/client-search.svg';
import sort from '@images/ArrowsDownUp.svg';
import CustomAddClientBox from './CustomAddClientBox';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const ClientFilter = ({
  activeButton,
  handleButtonClick,
  search,
  setSearch,
  handleSortIconClick,
  selectedSortOption,
  handleClick,
  anchorEl,
  handleClose,
  handleSortOptionClick,
  openAddClientModal,
  addClientModal,
  setAddClientModal,
  handleClickOpenModel,
}: any): JSX.Element => {
  const renderTabsMenu = () => {
    const tabIndex = clientFilters.findIndex(
      filter => filter.name === activeButton,
    );

    return (
      <TabsContainer
        value={tabIndex}
        sx={{
          '& .MuiTab-root': {
            textTransform: 'none',
            padding: '8px 12px',
            minWidth: 'auto',
          },
        }}
        onChange={(ev, newTabIndex: number) => {
          handleButtonClick(clientFilters[newTabIndex].name);
        }}
      >
        {clientFilters.map(({ name }) => (
          <Tab label={name} data-name={name} />
        ))}
      </TabsContainer>
    );
  };

  const renderSortMenu = () => (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      sx={{
        marginTop: 3,
        '& .MuiPaper-root': {
          width: '16%',
          marginLeft: 22,
          background: '#fff',
        },
      }}
    >
      {['Name', 'Value'].map(option => (
        <MenuItem
          key={option}
          sx={{
            fontSize: 12,
            color: '#101828',
            '&:hover': {
              backgroundColor: '#fff',
            },
          }}
          onClick={() => handleSortOptionClick(option)}
        >
          Sort by {option}
        </MenuItem>
      ))}
    </Menu>
  );

  return (
    <Filters xs={12} alignItems={'center'}>
      {renderTabsMenu()}

      <Grid container justifyContent="flex-end" alignItems="center" gap={1}>
        <Grid item flexGrow={1}>
          <CustomTextField
            placeholder="Search for any client"
            value={search}
            onChange={e => setSearch(e.target.value)}
            fullWidth
            variant="standard"
            size="medium"
            InputProps={{
              startAdornment: (
                <img
                  alt="Search image"
                  src={clientSearch}
                  style={{ marginRight: 8, width: 20, height: 20 }}
                />
              ),
              disableUnderline: true,
              style: {
                padding: 8,
                height: 32,
                border: '1px solid #ededee',
                borderRadius: 8,
              },
            }}
          />
        </Grid>

        <Grid item>
          <OutlineBox
            item
            container
            justifyContent="center"
            alignItems="flex-start"
            height={32}
            minWidth={'90px'}
          >
            <Grid
              item
              container
              xs={3}
              sx={{ cursor: 'pointer' }}
              onClick={handleSortIconClick}
              role="button"
              tabIndex={0}
              aria-label="Sort"
            >
              <img src={sort} alt="Sort image" />
            </Grid>
            <Grid
              item
              container
              xs={5}
              alignItems="center"
              fontSize={12}
              color="#535256"
              fontWeight={500}
              lineHeight="16px"
            >
              {selectedSortOption}
            </Grid>
            <Grid
              item
              container
              xs={2}
              alignItems="center"
              sx={{ cursor: 'pointer' }}
              onClick={handleClick}
              role="button"
              tabIndex={0}
              aria-label="Sort menu open"
            >
              <KeyboardArrowDownIcon
                sx={{ height: 14, color: '#535256', fontSize: 20 }}
              />
            </Grid>
          </OutlineBox>

          {renderSortMenu()}
        </Grid>
        <Grid item>
          <CustomAddClientBox
            openAddClientModal={openAddClientModal}
            addClientModal={addClientModal}
            setAddClientModal={setAddClientModal}
            handleClickOpenModel={handleClickOpenModel}
          />
        </Grid>
      </Grid>
    </Filters>
  );
};

export default ClientFilter;
