import { useRef, useState, useEffect } from 'react';
import { debounce } from 'utils/helperFunctions';

const useElementWidth = (
  delay: number = 500,
): [React.RefObject<HTMLDivElement>, number] => {
  const elementRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);

  const updateWidth = debounce(() => {
    if (elementRef.current) {
      setWidth(elementRef.current.getBoundingClientRect().width);
    }
  }, delay);

  useEffect(() => {
    updateWidth();

    window.addEventListener('resize', updateWidth);

    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  return [elementRef, width];
};

export default useElementWidth;
