import { Grid } from '@mui/material';
import styled from 'styled-components';

export const SuggestionCardWidth = 242;
export const SuggestionCardHeight = 200;

export const SuggestionCardWrapper = styled(Grid)<{ checked?: boolean }>`
  width: ${SuggestionCardWidth}px;
  height: ${SuggestionCardHeight}px;
  margin: 0 auto;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid
    ${props => (props.checked ? props.theme.main : props.theme.borderColor)};
  background: ${props => props.theme.solidWhite};
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.18);
`;

export const CardHeader = styled(Grid)`
  width: 100%;
  border-bottom: 1px solid ${props => props.theme.dark};
  padding: 0 0 10px;
  justify-content: space-between;
  align-items: center;
`;

export const CardLabel = styled('div')<any>`
  padding-left: 5px;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  @media (min-width: 600px) {
    font-size: 16px;
  }
`;

export const Text = styled(Grid)<any>`
  // font-family: ${props => props?.fontSize ?? 'Inter'};
  font-size: ${props => props?.fontSize ?? '14px'};
  font-style: ${props => props?.fontSize ?? 'normal'};
  font-weight: ${props => props.fontWeight ?? '400'};
  line-height: ${props => props.fontWeight ?? 'normal'};
  color: ${props => props.color ?? props.theme.textBlack};
`;

export const Button = styled(Grid).withConfig({
  shouldForwardProp: prop => !['textAlign'].includes(prop),
})<any>`
  padding: 5px 16px;
  color: ${props => props.theme.textBlack};
  // font-family: ${props => props?.fontSize ?? 'Inter'};
  font-size: ${props => props?.fontSize ?? '14px'};
  font-style: ${props => props?.fontSize ?? 'normal'};
  font-weight: ${props => props.fontWeight ?? '400'};
  line-height: ${props => props.fontWeight ?? 'normal'};
  text-align: ${props => props?.textAlign ?? 'center'};
  color: ${props => props.color ?? props.theme.textBlack};
  border-radius: 4px;
  border: 1px solid ${props => props.theme.textBlack};
`;
