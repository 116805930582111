/* eslint-disable react/prop-types */

import React from 'react';

function ColorSageIcon(props: any): React.JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="37"
      fill="none"
      viewBox="0 0 36 37"
      {...props}
    >
      <circle
        cx="18"
        cy="18.5"
        r="18"
        fill="url(#paint0_linear_3055_64119)"
      ></circle>
      <path
        fill="#fff"
        d="M17.776 24.52l-.677-.66a.305.305 0 010-.437l.677-.66a.321.321 0 01.448 0l.677.66a.31.31 0 01.093.219.303.303 0 01-.093.218l-.677.66a.321.321 0 01-.448 0zm10.131-5.05l-5.18 5.05a.322.322 0 01-.448 0l-4.055-3.953a.321.321 0 00-.448 0l-4.055 3.954a.322.322 0 01-.448 0l-5.18-5.052a.305.305 0 010-.437l9.683-9.441A.318.318 0 0118 9.5a.324.324 0 01.224.09l9.683 9.442a.31.31 0 01.093.219.303.303 0 01-.093.218zm-9.683-1.098l1.803 1.758a.321.321 0 00.449 0l.677-.66a.309.309 0 00.093-.22.303.303 0 00-.093-.218l-2.929-2.855a.321.321 0 00-.448 0l-2.929 2.855a.308.308 0 00-.093.219.303.303 0 00.093.218l.677.66a.321.321 0 00.449 0l1.803-1.757a.316.316 0 01.224-.09.325.325 0 01.224.09zm4.503 3.953l2.929-2.855a.305.305 0 000-.438l-7.432-7.246a.32.32 0 00-.345-.067.32.32 0 00-.103.067l-7.432 7.246a.31.31 0 00-.093.219.303.303 0 00.093.218l2.929 2.856a.321.321 0 00.448 0l.678-.66a.305.305 0 000-.437l-1.803-1.759a.305.305 0 010-.437l5.18-5.05a.319.319 0 01.345-.068.32.32 0 01.103.067l5.18 5.051a.305.305 0 010 .438l-1.803 1.758a.305.305 0 000 .437l.678.66a.32.32 0 00.345.067.32.32 0 00.103-.067z"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_3055_64119"
          x1="18"
          x2="18"
          y1="0.5"
          y2="36.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB169"></stop>
          <stop offset="1" stopColor="#FF6C2C"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}

const MemoColorSage = React.memo(ColorSageIcon);
export default MemoColorSage;
