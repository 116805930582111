import PrimaryButton from './PrimaryButton';
import SecondaryButton from './SecondaryButton';
import OutlineButton from './OutlineButton';
import NavButton from './NavButton';

import { HeaderMenu } from './styles';

export {
  PrimaryButton,
  SecondaryButton,
  OutlineButton,
  HeaderMenu,
  NavButton,
  PrimaryButton as Button,
};
