/* eslint-disable react/prop-types */
import * as React from 'react';

function RedtailIcon(props: any): React.JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="29"
      viewBox="0 0 99 29"
    >
      <g fill={props.fill || '#C81414'}>
        <path d="M25.543 23.285V8.746h4.074c4.07 0 5.535 1.531 5.535 4.383 0 2.36-2.011 3.7-4.222 4.226.746 2.95 2.82 3.918 4.652 3.918v2.012c-3.406 0-6.684-1.71-7.094-7.77h1.149c1.91 0 3.078-.769 3.078-2.386s-1.012-2.383-3.082-2.383H27.93v12.54zm20.352 0h-8.188V8.746h8.188v2.008h-5.82v3.98h5.452v1.993h-5.453v4.546h5.82zm14.332-7.402c0 2.402-.664 4.234-1.993 5.504-1.328 1.27-3.238 1.902-5.73 1.898h-4.05V8.746h4.476c2.304 0 4.097.621 5.375 1.867 1.28 1.246 1.922 3.004 1.922 5.27zm-2.497.078c0-3.48-1.62-5.223-4.859-5.223h-2.05v10.555h1.702c3.477 0 5.211-1.777 5.207-5.332zm9.387 7.324H64.75V10.79h-4.258V8.746h10.883v2.04h-4.258zm13.625 0l-1.445-4.02h-5.508l-1.418 4.02h-2.48l5.398-14.539h2.555l5.39 14.54zm-2.066-6.058l-1.348-3.946c-.098-.261-.234-.68-.426-1.246-.191-.57-.297-.984-.359-1.25-.2.902-.457 1.793-.766 2.664L74.5 17.23zm6.195 6.058V8.746h2.367v14.54zm5.637 0V8.746h2.367V21.25H99v2.035zm-80.29 2.996C4.575 26.281 0 21.684 0 16.016S4.574 5.75 10.219 5.75c5.64 0 10.215 4.598 10.215 10.266S15.859 26.28 10.219 26.28zm9.364-10.265c0-5.196-4.191-9.41-9.363-9.41-5.176 0-9.367 4.214-9.367 9.41 0 5.195 4.191 9.41 9.367 9.41 5.172 0 9.363-4.215 9.363-9.41zm0 0"></path>
        <path d="M5.82 21.332c.14 0 .305-.055.39-.363l.474-1.664a2.76 2.76 0 01.68-1.047c.109.687.187 1.683.28 2.305.02.164.16.289.325.285a.27.27 0 00.195-.09.29.29 0 00.066-.203c0-.149 0-.246-.03-.582a9.45 9.45 0 01.257-2.094c.09-.274.559-.59.785-.5.637.234 1.258.504 1.863.8.258.145.473.798.56 1.282.085.488.26 1.625.37 2.223.008.168.14.308.313.32a.327.327 0 00.304-.297c.043-.723.059-2.137.114-2.895a.399.399 0 01.316-.398c.246-.07.3.133.379.43.152.562.539 2.176.539 2.176.02.164.156.289.32.289a.237.237 0 00.184-.094.246.246 0 00.055-.2c-.059-.605-.215-1.71-.305-2.62-.043-.45-.066-1.06-.133-1.56-.098-.726-.152-.75-.195-1.237a2.291 2.291 0 010-.832c.11-.383.48-1.188.601-1.184.22 0 .578.047.817.074a.78.78 0 00.683-.289l.328-.46c.133-.184.051-.294-.105-.34-.367-.118-1.09-.313-1.46-.43-.372-.117-.321-.485-.911-.5a1.617 1.617 0 00-.832.164c-.453-.606-.535.05-.957.496-.625.656-.527.851.598.613-.118.34-.297.652-.524.926-.371.469-.793.402-1.215.64-.422.239-2.355.083-3.43.403a3.02 3.02 0 01-1.5-1.578 5.449 5.449 0 01-.253-2.364c-.075-.03-.371.133-.582.583-.207.449-.211.75-.317.796-.11.047-.262-.164-.324-.136-.063.027-.082.285.066.648.118.293.403.488.332.629-.066.14-.304-.02-.34.043-.035.066.192.352.462.52.265.164.503.242.503.363 0 .117-.261.035-.277.125-.02.09.512.398 1.059.531.66.16.652.234.539.488-.457 1.067-1.004 3.961-1.278 5.407-.113.238.047.398.211.398zm0 0"></path>
      </g>
    </svg>
  );
}

const MemoNoData = React.memo(RedtailIcon);
export default MemoNoData;
