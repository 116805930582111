import { Box, Grid } from '@mui/material';
import styled, { css, keyframes } from 'styled-components';
import { AnimatedContainerProps, AnimateWrapperProps } from './types';
import { shouldForwardProp } from 'utils/forwardProp';
export const AnimationName = 'ease-out';
export const AnimationTime = '0.5s';
const HeightAnimationTime = '1s';
interface TabHeaderProps {
  active?: boolean;
}
const scrolly = keyframes`
  0%  { transform: translateY(calc(0%)); }
  25%  { transform: translateY(calc(-10%)); }
  50%  { transform: translateY(calc(-20%)); }
  75%  { transform: translateY(calc(-30%)); }
  100%  { transform: translateY(calc(-40%)); }
`;
const scrollClient = keyframes`
  0%  { transform: translateY(calc(0%)); }
  25%  { transform: translateY(calc(-15%)); }
  50%  { transform: translateY(calc(-35%)); }
  75%  { transform: translateY(calc(-50%)); }
  100%  { transform: translateY(calc(-70%)); }
`;
export const DashboardWrapper = styled(Grid)`
  padding-left: 48px;
  min-height: 77vh;
  justify-content: center;

  .grey-text {
    color: var(--Silver-700, #818181);
    font-size: 24px;
    font-weight: 400;
  }

  .bold-grey-text {
    color: #333;
    font-size: 64px;
    font-weight: 600;
    letter-spacing: -2.56px;
  }
`;

export const ConversationWrapper = styled(Grid)`
  padding: 2rem 0 1rem 2rem;
  height: 100%;
  .title {
    font-size: 26px !important;
    font-weight: 500 !important;
    color: #222124 !important;
  }

  .progress-bar {
    height: 2px;
    background: linear-gradient(90deg, #ffc38b 0%, #ff8c5a 100%);
    transform-origin: 0%;
  }
`;

export const ContentWrapper = styled(Box)<{ $margin?: number }>`
  margin: ${props => `${props.$margin}px 0 0 0`};
`;
export const FooterContainer = styled(Grid)`
  width: 100%;
  position: fixed;
  padding: 10px;
  bottom: 6%;
`;

export const AvatarWrapper = styled(Grid)<any>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const LayoutWrapper = styled(Grid)<AnimateWrapperProps>`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  height: 100%;

  ${({ animate }: AnimatedContainerProps) =>
    animate &&
    css`
      transition: opacity ${AnimationName} ${HeightAnimationTime};
    `}
`;

export const SuggestedCardContainer = styled(Grid)`
  width: 97%;
  height: 60vh;
  overflow: scroll;
  background: ${props => props.theme.background8};
  border-radius: 8px;
  padding: 16px;
  gap: 16px;
  flex: 1 0 0;
  overflow-y: auto;
`;
export const SugestedCardsWrapper = styled(Grid).withConfig({
  shouldForwardProp,
})<{
  duration: number;
  delay: number;
}>`
  animation: ${scrolly}
    ${props => `${props.duration}s linear ${props.delay}s 1 forwards`};
`;
export const TabHeaderContainer = styled.div`
  display: flex;
`;
export const OneLineDesc = styled.p`
  color: var(--Gray-2, #333);
  font-size: 14px;
  padding-left: 5px;
  margin-top: 20px;
  font-weight: 400;
  word-spacing: -1px;
`;
export const TabHeader = styled.div.withConfig({
  shouldForwardProp,
})<TabHeaderProps>`
  margin: 0 10px;
  cursor: pointer;
  color: ${({ active }) => (active ? '#101828' : '#818181')};
  border-bottom: ${({ active }) => (active ? '2px solid #101828' : 'none')};
  padding-bottom: 4px;
  font-size: 16px;
`;

export const FileName = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding-left: 5px;
  color: var(--Gray-2, #333);
  font-size: 14px;
  padding-top: 16px;
`;

export const ClientCardContainer = styled(Grid)`
  width: 100%;
  padding-right: 2rem;
`;
export const ContainerBox = styled(Box)`
  background-color: #ffffff;
  border: 1px solid #e4e7ec;
  height: auto;
`;

export const Filters = styled(Grid)`
  padding: 16px;
  border: 1px solid #e4e7ec;
  border-bottom: 0px;
  background-color: #fff;
`;

export const FooterButtonContainer = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  background: transparent;
  height: 10vh;
  border: 1px solid #e4e7ec;
  display: flex;
  align-items: center;
  backdrop-fliter: blur(8px);
`;

export const FooterButtonWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  align-items: center;
`;

export const PortfolioWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  padding: 10px 0px;
  align-items: center;
`;

export const StyledColumn1 = styled.div`
  width: 20%;
  display: flex;
  flex-direction: column;
`;

export const Column = styled.div`
  display: flex;
`;

export const StyledColumn2 = styled.div`
  color: var(--neutral-600, #475467);
  font-size: 12px;
`;

export const PortfolioContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  // max-height: 180px;
  height: 34%;
  // bottom: 5%;
`;

export const ReturnsHeader = styled.div`
  height: 250px;
`;

export const AssetAllocationContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

export const AssetAllocationHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  padding-bottom: 5px;
`;

export const ProgressBarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 98%;
  height: 15px;
  margin-bottom: 10px;
`;

export const CashFlowWrapper = styled.div`
  width: 20%;
  background: #10e4b1;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  margin-right: 2px;
`;

export const EquityFlowWrapper = styled.div`
  width: 50%;
  background: #8cacff;
  margin-right: 2px;
`;

export const DebtWrapper = styled.div`
  width: 20%;
  background: var(--Gray-2, #333);
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
`;

export const ProgressBarLegendContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 70%;
  justify-content: flex-start;
`;

export const LegendWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 15px;
`;

export const LegendColorDiv = styled.div`
  height: 12px;
  width: 12px;
  background: #10e4b1;
  border-radius: 2px;
`;

export const LegendTextDiv = styled.div`
  padding: 5px;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const DrawerContainer = styled.div`
  width: 90%;
  min-height: 93vh;
  padding: 25px;
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0;
  overflow: hidden;
`;

export const DrawerHeaderWrapper = styled.div`
  height: 40px;
  width: 100%;
  // padding: 0px 0px 15px 5px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: var(--neutral-900, #101828);
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

export const ExpectedFundDataContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  row-gap: 16px;
  column-gap: 16px;
  justify-content: space-between;
  width: 100%;
  // margin-bottom: 20px;
`;

export const ExpectedFundDataWrapper = styled.div`
  display: flex;
`;

export const FundDataText = styled.div`
  padding: 5px;
  color: var(--Gray-1, #818181);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const FundDataNumber = styled.div`
  padding: 5px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: var(--neutral-900, #101828);
`;

export const HoldingWrapper = styled.div`
  width: 94%;
  max-height: 150px;
  padding: 0px 20px 0px 10px;
  overflow-y: scroll;
  position: relative;
  top: 12%;
`;

export const AddClientBox = styled(Box)`
  float: right;
  margin-top: -20px;
  margin-bottom: 20px;
`;

export const ViewpointBox = styled.div`
  display: flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  // border: 1px solid ${props =>
    props.title === 'Rely on your core'
      ? 'orange'
      : 'var(--Info-Border, #b6cff3)'};
  background: ${props =>
    props.title === 'Rely on your core'
      ? '#EBDCF9'
      : props.title === 'Step out of cash'
        ? 'var(--Info-Surface, #ECF2FC)'
        : props.title === 'Rebuild Equity'
          ? 'var(--Success-Surface, #EBF5F0)'
          : 'var(--Info-Surface, #ecf2fc)'};
  font-size: 12px;
  color: ${props =>
    props.title === 'Rely on your core'
      ? '#9B51E0'
      : props.title === 'Step out of cash'
        ? '#0B5CD7'
        : props.title === 'Rebuild Equity'
          ? '#008243'
          : null};
  height: 15px;
  width: fit-content;
`;

export const FourthBox = styled.div`
  display: flex;
  padding: 12px 12px 12px 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  border: 1px solid
    ${props =>
      props.title === 'Rely on your core'
        ? '#FFCA99'
        : props.title === 'Step out of cash'
          ? '#B6CFF3'
          : props.title === 'Rebuild Equity'
            ? '#B3DAC7'
            : null};
  background: ${props =>
    props.title === 'Rely on your core'
      ? '#FFF2E7'
      : props.title === 'Step out of cash'
        ? 'var(--Info-Surface, #ECF2FC)'
        : props.title === 'Rebuild Equity'
          ? 'var(--Success-Surface, #EBF5F0)'
          : null};
  font-size: 12px;
  color: #101828;
  height: 30px;
  width: 95%;
`;

export const ClientsWrapper = styled(Grid).withConfig({
  shouldForwardProp,
})<{ duration: number; delay: number }>`
  border-radius: 8px;
  background: #f7f7f7;
  padding: 16px;
  animation: ${scrollClient}
    ${props => `${props.duration}s linear ${props.delay}s 1 forwards`};
`;

export const OutlineBox = styled(Grid)`
  border-radius: 4px;
  border: 1px solid var(--Neutral-200, #e4e7ec);
  background: var(--Neutral-0, #fff);
  padding: 8px 16px;
  /* Action Components */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.12);
`;

export const CardWrapper = styled(Grid)`
  padding: 19px 18px;
  background: #fff;
  min-height: 256px;

  .text {
    font-size: 12px;
    color: #818181;
  }

  .name {
    color: #333;
    font-size: 16px;
    font-weight: 500;
  }

  .price {
    color: #818181;
    font-size: 15px;
    font-weight: 500;
  }

  .curve-border {
    border-radius: 18px;
    border: 1px solid var(--Gray-1, #818181);
    background: #fff;
    padding: 3px;
  }

  .circle-border {
    border-radius: 20px;
    border: 0.9px solid #818181;
    background: var(--Neutral-0, #fff);

    /* Action Components */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.12);
    cursor: pointer;
  }
`;

export const CardContainer = styled(Grid)`
  /* padding: 19px 18px; */
`;

export const ClientOverviewContainer = styled.div`
  // border: 1px solid #e4e7ec;
  display: flex;
  padding: 16px;
  border-radius: 3px;
  align-items: flex-start;
  width: 97%;
  // box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.12);
  background: #fff;
  flex-direction: row;
  // margin-bottom: 20px;
  height: 75px;
`;

export const ClientsHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ClientsHeaderDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 25px;
`;

export const FirstBlockWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const StyledIconContainer = styled.div`
  display: flex;
  padding: 4px;
  // border: 1px solid #7b73e4;
  border-radius: 4px;
  margin-right: 5px;
  // margin-bottom: 5px;
  // background: #7b73e4;
  justify-content: center;
`;

export const FirstBlockText = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  color: #818181;
`;

export const FirstBlockData = styled.div`
  display: flex;
  color: var(--Gray-2, #333);
  font-size: 28px;
  font-weight: 400;
  margin-top: 10px;
  margin-left: 2px;
`;

export const SecondBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 10px;
`;

export const SecondBlockIconContainer = styled.div`
  display: flex;
  padding: 4px;
  // border: 1px solid #2196f3;
  border-radius: 4px;
  margin-right: 5px;
  // margin-bottom: 5px;
  // background: #2196f3;
  justify-content: center;
`;

export const RecentlyViewedSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RecentlyViewedSectionContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const RecentlyViewedIconContainer = styled.div`
  display: flex;
  padding: 4px;
  // border: 1px solid #2196f3;
  border-radius: 4px;
  margin-right: 5px;
  // margin-bottom: 5px;
  // background: #2196f3;
  justify-content: center;
`;

export const RecentlyViewedTextContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  color: #818181;
`;

export const RecentlyViewedDataWrapper = styled.div`
  display: flex;
  padding-top: 5px;
`;

export const RecentlyViewedUserContainer = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
  border: 1px solid #e4e7ec;
  border-radius: 16px;
  padding: 8px 16px;
  margin-right: 10px;
`;

export const SearchContainer = styled.div`
  margin-right: 10px;
`;

export const SearchBoxInput = styled.input`
  padding: 10px;
  margin-right: 10px;
  border: 1px solid #e4e7ec;
  border-radius: 4px;
  outline: none;
  box-shadow: 1px 2px 2px 0px rgba(16, 24, 40, 0.12) inset;
  width: 200px;
`;
export const CountWrapper = styled.span`
  display: flex;
  margin-left: 18px;
  height: 6px;
  align-items: center;
  background: rgba(255, 255, 255, 0.6);
  padding: 10px;
  border-radius: 2px;
  font-size: 14px;
  color: #333;
  font-weight: 600;
`;
