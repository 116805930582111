import * as React from 'react';

function Magic(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      {...props}
    >
      <g clipPath="url(#clip0_3247_4869)">
        <path
          fill="#86858B"
          d="M0 8a8.953 8.953 0 005.444-2.556A8.953 8.953 0 008 0a8.953 8.953 0 002.556 5.444A8.953 8.953 0 0016 8a8.858 8.858 0 00-8 8 8.953 8.953 0 00-2.556-5.444A8.953 8.953 0 000 8z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_3247_4869">
          <path fill="#fff" d="M0 0H16V16H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

const MemoMagic = React.memo(Magic);
export default MemoMagic;
