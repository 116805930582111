import styled from 'styled-components';

export const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

export const ValidationContainer = styled.div`
  width: 80%;
  display: flex;
  flex-direction: row;
`;

export const ValidationFirstBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;

export const ValidationFirstContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const FirstItemsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
  align-items: center;
`;
