import * as React from 'react';

function Archived(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 19 19" fill="none" {...props}>
      <path
        d="M14.958 3.313H3.708a.563.563 0 00-.562.562v11.25c0 .31.252.563.562.563h11.25c.311 0 .563-.252.563-.563V3.875a.562.562 0 00-.563-.562z"
        stroke="#fff"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.95 8.804l2.383 2.383 2.384-2.383M9.333 5.563v5.625M3.146 11.75h2.58a.556.556 0 01.394.162l1.364 1.364a.557.557 0 00.394.162h2.91a.556.556 0 00.394-.162l1.365-1.364a.554.554 0 01.393-.162h2.58"
        stroke="#fff"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const MemoArchived = React.memo(Archived);
export default MemoArchived;
