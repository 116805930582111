/* eslint-disable react/prop-types */
import * as React from 'react';

function FidelityIcon(props: any): React.JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="68"
      height="48"
      viewBox="0 0 68 48"
      fill="none"
    >
      <g clipPath="url(#clip0_1276_154462)">
        <path
          d="M4.31818 28.3917H0L3.46763 16.0884H12.3221L11.4824 19.0095H6.95707L6.37913 21.1053H10.8936L10.1412 23.7875H5.61582L4.31818 28.3917Z"
          fill={props.fill || '#408800'}
        />
        <path
          d="M14.329 28.3917H10.0545L12.5843 19.4004H16.8589L14.329 28.3917ZM17.0879 18.5425H12.8133L13.5112 16.0884H17.7858L17.0879 18.5425Z"
          fill={props.fill || '#408800'}
        />
        <path
          d="M25.4084 28.3923H21.2211L21.5155 27.3824C20.6759 28.0883 19.8144 28.5986 18.4404 28.5986C17.0447 28.5986 16.1723 27.9362 16.1723 26.5354C16.1723 24.9826 16.783 22.6804 17.6989 21.0624C18.3641 19.8571 19.5527 19.2273 20.9594 19.2273C22.1698 19.2273 23.064 19.7593 23.4129 20.4109L24.6015 16.0781H28.8761L25.4084 28.3923ZM22.2025 22.0723C21.8208 22.0723 21.4392 22.1918 21.2211 22.713C20.9049 23.4731 20.6213 24.2116 20.6213 24.9608C20.6213 25.3083 20.8721 25.6015 21.2865 25.6015C21.6137 25.6015 21.8317 25.5038 22.0171 25.3843L22.8786 22.4307C22.7259 22.2244 22.4642 22.0723 22.2025 22.0723Z"
          fill={props.fill || '#408800'}
        />
        <path
          d="M37.4787 24.4717H31.5794C31.4595 24.9495 31.3504 25.2753 31.3504 25.6553C31.3504 25.8399 31.3831 26.296 31.9829 26.296C32.5826 26.296 32.8443 26.068 33.0951 25.1884H37.2825C36.7481 27.7403 33.7058 28.6198 31.8302 28.6198C29.3004 28.6198 27.3267 28.2615 27.3267 25.8291C27.3267 24.4934 28.0027 22.2782 28.8969 21.1162C29.9764 19.7154 31.8411 19.1833 33.564 19.1833C36.2138 19.1833 37.9367 19.7263 37.9367 21.9741C37.9367 22.5171 37.7077 23.7224 37.4787 24.4717ZM33.2042 21.4529C32.6699 21.4529 32.31 21.8112 32.081 22.7234H33.8366C33.8912 22.5388 33.9566 22.2564 33.9566 22.0935C33.9457 21.594 33.6513 21.4529 33.2042 21.4529Z"
          fill={props.fill || '#408800'}
        />
        <path
          d="M41.7753 28.3917H37.5007L40.9574 16.0884H45.232L41.7753 28.3917Z"
          fill={props.fill || '#408800'}
        />
        <path
          d="M47.4117 28.3917H43.1371L45.667 19.4004H49.9416L47.4117 28.3917ZM50.1815 18.5425H45.9069L46.6048 16.0884H50.8793L50.1815 18.5425Z"
          fill={props.fill || '#408800'}
        />
        <path
          d="M57.8596 19.4018L58.2304 21.4325H54.959L53.9994 24.8422C53.9122 25.1029 53.8468 25.4395 53.8468 25.6458C53.8468 26.0367 54.0103 26.1779 54.5883 26.1779H55.5042L54.8718 28.3932H51.0661C49.8121 28.3932 49.3323 27.785 49.3323 26.862C49.3323 26.482 49.3868 26.0693 49.5177 25.6132L51.8403 17.4146H56.1149L55.5479 19.4127H57.8596V19.4018Z"
          fill={props.fill || '#408800'}
        />
        <path
          d="M64.6964 26.5896C64.053 26.5896 63.5296 27.1108 63.5296 27.7515C63.5296 28.3922 64.053 28.9134 64.6964 28.9134C65.3397 28.9134 65.8631 28.3922 65.8631 27.7515C65.8631 27.1 65.3397 26.5896 64.6964 26.5896ZM64.6964 28.6962C64.1729 28.6962 63.7368 28.2727 63.7368 27.7407C63.7368 27.2086 64.162 26.7851 64.6964 26.7851C65.2198 26.7851 65.656 27.2086 65.656 27.7407C65.656 28.2727 65.2307 28.6962 64.6964 28.6962Z"
          fill={props.fill || '#408800'}
        />
        <path
          d="M65.1647 27.5012C65.1647 27.2732 65.0339 27.1429 64.7395 27.1429H64.2924V28.3374H64.4996V27.8487H64.6631L64.9575 28.3374H65.1865L64.8703 27.8379C65.0448 27.8053 65.1647 27.7076 65.1647 27.5012ZM64.4996 27.6641V27.3275H64.6631C64.794 27.3275 64.9466 27.3383 64.9466 27.4904C64.9466 27.6533 64.794 27.6641 64.6631 27.6641H64.4996Z"
          fill={props.fill || '#408800'}
        />
        <path
          d="M63.6273 19.4005L61.3046 24.3848L61.3701 19.4005H57.0519L57.8806 28.3918C57.7716 28.913 57.5971 29.2171 57.27 29.3583C56.9101 29.5212 56.0923 29.4886 55.7979 29.4777H55.7433L55.0564 31.921H57.9788C59.7017 31.921 60.5413 31.0631 61.7735 29.1411L68 19.3896H63.6273V19.4005Z"
          fill={props.fill || '#408800'}
        />
      </g>
      <defs>
        <clipPath id="clip0_1276_154462">
          <rect width="68" height="48" rx="4" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

const MemoNoData = React.memo(FidelityIcon);
export default MemoNoData;
