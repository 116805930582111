import { Grid, InputLabel, styled, Typography } from '@mui/material';

export const FileUploadContainer = styled(Grid)`
  border-color: 2px dashed var(--Neutral-200, #96969b);
  background: #f6f4fc;
`;

export const UploadFileLabel = styled(Typography)`
  display: flex;
  justify-content: center;
  color: #101828;
  font-size: 14px;
  text-wrap: wrap;
`;

export const UploadFileLabelContainer = styled(InputLabel)`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  text-align: center;
  gap: 8px;
  padding: 65px 0;
  font-size: 14px;
`;
