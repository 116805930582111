import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
  useQueryClient,
} from 'react-query';
import instance from '../instances';
import { Method } from 'axios';

export interface RestQueryConfig {
  endpoint: string;
  queryParams?: any;
  headers?: any;
  method?: Method;
  body?: any;
}

export const useRESTQuery = (
  queryIdentifier: string | readonly unknown[],
  restApiConfig: RestQueryConfig,
  options?:
    | Omit<UseQueryOptions<any, unknown, any, any>, 'queryKey' | 'queryFn'>
    | undefined,
): UseQueryResult<any, unknown> => {
  const headers = {
    ...restApiConfig.headers,
  };

  return useQuery<any, unknown>(
    queryIdentifier,
    async () => {
      const {
        endpoint,
        queryParams = {},
        method = 'GET',
        body = {},
      } = restApiConfig;
      const response = await instance.request({
        method,
        url: endpoint,
        params: { ...queryParams },
        data: { ...body },
        headers: { ...headers },
      });
      return response.data;
    },
    options,
  );
};

// Add a function to force fetch the data
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useForceFetch = (queryIdentifier: string | readonly unknown[]) => {
  const queryClient = useQueryClient();
  return () => {
    queryClient.invalidateQueries(queryIdentifier);
  };
};
