import { Grid } from '@mui/material';
import React, { useState } from 'react';
import { actions as globalActions } from 'app/features/Global/slice';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { handleError } from '@common/error';
import { PrimaryButton } from '@components/Button';
import MemoDeleteIcon from '@icons/DeleteIcon';
import MemoPencilIcon from '@icons/PencilIcon';
import DoneIcon from '@mui/icons-material/Done';
import RemoveIcon from '@mui/icons-material/Remove';
import DialogModal from '@components/DialogModal';
import ConfirmationModal from '@pages/EnterpriseDashboardV2/ConfirmationModal';
import SuccessModal from '@components/SuccessModal';
import CurrentEventModal from '@components/CurrentEventsModal';
import { deleteCurrentEvent, publishCurrentEvent } from './mutation';
import { CardWrapper } from './styles';

interface CardProps {
  title: string;
  description: string;
  questions: string[];
  id: string;
  active: boolean;
  imgUrl: string;
}

function CurrentEventsCard({
  title,
  description,
  questions,
  id,
  active,
  imgUrl,
}: CardProps): React.JSX.Element {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const [showEditModel, setShowEditModel] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const { mutate, isLoading } = useMutation(deleteCurrentEvent);
  const { mutate: publishMutate } = useMutation(publishCurrentEvent);
  const handleDeleteClose = () => {
    setShowDeleteModal(false);
  };

  const handleClose = () => {
    setShowEditModel(false);
  };

  const handleDelete = () => {
    const payload = {
      id,
    };

    mutate(payload, {
      onSuccess: res => {
        queryClient.invalidateQueries('get-current-events');
        dispatch(
          globalActions.displayToast({
            duration: 3000,
            toastType: 'success',
            toastMessage: res?.message,
          }),
        );
      },
      onError: (error: any) => {
        handleError(dispatch, error);
      },
    });
  };

  const handlePublish = () => {
    const payload = {
      id,
      active: !active,
    };
    publishMutate(payload, {
      onSuccess: res => {
        queryClient.invalidateQueries('get-current-events');
        dispatch(
          globalActions.displayToast({
            duration: 3000,
            toastType: 'success',
            toastMessage: res?.message,
          }),
        );
      },
      onError: (err: any) => {
        handleError(dispatch, err);
      },
    });
  };

  const getButtonTextAndIcon = () => {
    if (active === false) {
      return {
        text: 'Publish Event',
        icon: <DoneIcon />,
      };
    } else {
      return {
        text: 'Unpublish Event',
        icon: <RemoveIcon />,
      };
    }
  };

  return (
    <>
      <CardWrapper container rowGap={1}>
        <Grid item container xs={12} justifyContent={'space-between'}>
          <Grid item container alignItems={'center'}>
            <Grid item xs={4}>
              <p className="title">{title}</p>
            </Grid>
            <Grid
              item
              container
              xs={8}
              justifyContent={'flex-end'}
              alignItems={'center'}
              columnGap={2}
            >
              <Grid
                item
                xs={1}
                container
                justifyContent={'center'}
                className="delete-wrapper"
                onClick={() => setShowDeleteModal(true)}
              >
                <MemoDeleteIcon />
              </Grid>
              <Grid
                item
                container
                xs={2}
                alignItems={'center'}
                columnGap={1}
                className="edit-wrapper"
                justifyContent={'center'}
                onClick={() => setShowEditModel(true)}
              >
                <Grid item>
                  <MemoPencilIcon />
                </Grid>
                <Grid item>
                  <p className="edit">Edit</p>
                </Grid>
              </Grid>
              <Grid item>
                <PrimaryButton
                  color="primary"
                  className="button"
                  style={{ textTransform: 'none' }}
                  onClick={handlePublish}
                >
                  <Grid item container alignItems={'center'} columnGap={1}>
                    <Grid item className="icon">
                      {getButtonTextAndIcon().icon}
                    </Grid>
                    <Grid item>{getButtonTextAndIcon().text}</Grid>
                  </Grid>
                </PrimaryButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <p className="desc">{description}</p>
        </Grid>
        <Grid item container direction={'column'} rowGap={2}>
          <Grid item>
            <p className="questions-title">List of Questions</p>
          </Grid>
          <Grid item container columnGap={2} rowGap={2}>
            {questions.map(item => (
              <Grid item className="question-wrapper">
                <p className="question">{item}</p>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </CardWrapper>

      {showDeleteModal && (
        <DialogModal
          openModal={showDeleteModal}
          handleClose={handleDeleteClose}
          // title={`${()}`}
        >
          <ConfirmationModal
            icon={<MemoDeleteIcon />}
            headerText={'Delete Viewpoint'}
            title={`You are about to permanently delete ${title} viewpoint for all your advisors. Are you sure you want to continue?`}
            subTitle={
              'Note - Once you delete this viewpoint it will not visible to any of your advisors.'
            }
            handleClose={handleDeleteClose}
            handleSubmit={handleDelete}
            isLoading={isLoading}
          />
        </DialogModal>
      )}

      {showEditModel && (
        <DialogModal openModal={showEditModel} handleClose={handleClose}>
          <CurrentEventModal
            handleClose={handleClose}
            editMode={true}
            description={description}
            questions={questions}
            focus={title}
            id={id}
            imgUrl={imgUrl}
          />
        </DialogModal>
      )}

      {showSuccessModal && (
        <SuccessModal
          isOpen={showSuccessModal}
          cta={'Continue'}
          message={'Viewpoint Updated Successfully!'}
          ctaMessage={''}
          handleCtaClick={() => setShowSuccessModal(false)}
          handleClose={() => setShowSuccessModal(false)}
        />
      )}
    </>
  );
}

export default CurrentEventsCard;
