import { Typography } from '@mui/material';
import styled from 'styled-components';

export const ChartTooltipTitle = styled(Typography)`
  font-size: 16px !important;
  font-family: Inter, sans-serif !important;
  font-weight: 900 !important;
  text-transform: uppercase;
`;

export const ChartTooltipLabel = styled(Typography)`
  font-size: 14px !important;
  font-family: Inter, sans-serif !important;
  font-weight: 500 !important;
  text-transform: capitalize;
`;

export const LegendStyles = styled.div`
  background: white;
  border-radius: 8px;
  padding: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  gap: 10px;

  @media (min-width: 768px) {
    flex-wrap: nowrap;
    gap: 0px;
  }
`;
