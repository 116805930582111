import React from 'react';
import { useDispatch } from 'react-redux';
import LoginIcon from '@mui/icons-material/Login';
import { actions as profileActions } from '@features/Profile/slice'; // Update the import path according to your project structure

interface ImpersonateProps {
  status?: string;
  userId?: string;
  setIsLoading?: (loading: boolean) => void;
}

const Impersonate: React.FC<ImpersonateProps> = ({
  status,
  userId,
  setIsLoading = () => {},
}) => {
  const dispatch = useDispatch();

  const impersonationIdExist = localStorage.getItem('impersonation-id');

  const handleImpersonate = e => {
    e.stopPropagation();
    if (!impersonationIdExist) {
      localStorage.setItem('impersonation-id', userId as string);
      setIsLoading(true);
      const token = localStorage.getItem('x-tifin-ai-token') as string;
      if (token) {
        dispatch(
          profileActions.userInfoRequest({
            token,
          }),
        );
      }
    }
  };

  if (status === 'INVITE_SENT') {
    return <></>;
  }
  return (
    <LoginIcon
      sx={{
        cursor: impersonationIdExist ? 'not-allowed' : 'pointer',
        color: impersonationIdExist ? '#555' : '#000',
      }}
      onClick={handleImpersonate}
    />
  );
};

export default Impersonate;
