import styled from 'styled-components';

export const Container = styled.div`
  width: 98%;
  height: 85vh;
`;

export const TermsContainer = styled.div`
  width: 80%;
  border: 1px solid #e4e7ec;
  display: flex;
  flex-direction: column;
  padding: 5px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.12);
  margin: 0 auto;
  margin-top: 94px;
`;

export const TermsHeader = styled.div`
  padding: 20px;
  font-size: 22px;
  color: #000;
  border-bottom: 1px solid #e4e7ec;
  height: 30px;
  display: flex;
  align-items: center;
  background: #fff;
  font-weight: 200;
`;

export const ConditionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const ConditionsList = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  padding: 20px;
  height: 60vh;
  overflow: auto;
  gap: 14px;
  border-right: 1px solid #e4e7ec;
  background: #fff;
`;

export const ConditionItem = styled.div`
  font-size: 12px;
  color: #101828;
  display: flex;
  align-items: center;
  line-height: 1;
`;

export const ContentWrapper = styled.div`
  width: 70%;
  overflow: auto;
  padding: 20px;
  height: 60vh;
  font-size: 14px;
  color: #101828;
  background: #fff;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 80%;
  margin: 0 auto;
  align-items: 'center';
`;

export const AcceptTerms = styled.span`
  font-size: 16px;
  color: #101828;
`;
