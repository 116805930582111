// import { Grid } from '@mui/material';
import React from 'react';
import CurrentEventsCard from './CurrentEventsCard';
import { CardMainContainer, ParentContainer } from './styles';
import { useRESTQuery } from '@hooks/useRESTQuery';
import Loader from '@components/Loader';

function index(): React.JSX.Element {
  const { data, isLoading } = useRESTQuery(
    ['get-current-events'],
    {
      endpoint: `/viewpoints/currentEvents`,
      method: 'GET',
      headers: {
        'X-Tifin-Ai-Auth': localStorage.getItem('x-tifin-ai-token'),
      },
    },
    {
      select: res => res.data,
    },
  );

  if (isLoading) return <Loader />;

  return (
    <ParentContainer container px={3}>
      <CardMainContainer
        item
        container
        rowGap={2}
        justifyContent={'flex-start'}
      >
        {Array.isArray(data) &&
          data.length > 0 &&
          data.map(currentEvent => {
            return (
              <CurrentEventsCard
                questions={currentEvent?.advisorQuestions}
                title={currentEvent?.focus}
                description={currentEvent?.summary}
                id={currentEvent?.id}
                active={currentEvent.active}
                imgUrl={currentEvent.image_s3_url}
              />
            );
          })}
      </CardMainContainer>
    </ParentContainer>
  );
}

export default index;
