import React, { Fragment } from 'react';
import { LoadingButton as MuiButton } from '@mui/lab';
import styled, { css, keyframes, useTheme } from 'styled-components';
import { makeStyles } from '@mui/styles';
import CustomTooltip from '@components/Tooltip';
import { shouldForwardProp } from 'utils/forwardProp';

type Props = {
  type?: 'submit' | 'reset' | 'button';
  id?: string;
  width?: any;
  loading?: boolean;
  color?: string;
  variant?: 'contained' | 'outlined' | 'text';
  fullWidth?: boolean;
  customHeight?: string;
  cursor: string;
  active?: boolean;
  collapse?: boolean;
  notification?: boolean;
  tooltipText?: string;
  enableNotifications?: boolean;
  children: React.ReactNode;
  onClick?:
    | ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void)
    | undefined;
  style?: React.CSSProperties;
  className?: string;
  disabled?: boolean;
  dataTestId?: string;
  startIcon?: React.ReactNode;
  loadingPosition?: string;
  awaitingNavItem?: boolean;
};

const pulseAnimation = keyframes`
  0%, 100% {
    background-color: white;
    color: black;
  }
  50% {
    background-color: black;
    color: white;
  }
`;

const Button = styled(MuiButton).withConfig({
  shouldForwardProp,
})<any>`
  ${({ collapse }: any) =>
    collapse &&
    css`
      transition:
        border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        width 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        transform 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      
      .MuiButton-startIcon {
        margin-left: -2px;
        min-width: 30px;
      },
    `}
  ${({ awaitingNavItem }) =>
    awaitingNavItem &&
    css`
      animation: ${pulseAnimation} 1.5s ease-in-out infinite;
    `}
`;

/* ${({ awaitingNavItem }) =>
    awaitingNavItem &&
    css`
      animation: ${pulseAnimation} 1s ease-in-out infinite;
    `} */

const HeaderMenuNotify = styled('span').withConfig({
  shouldForwardProp,
})<any>`
  position: absolute;
  width: 0;
  height: 0;
  top: 0;
  right: 0;
  border-top: 8px solid transparent;
  border-right: 8px solid transparent;
  clip-path: polygon(0 0, 100% 0, 0 100%);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15));
  transform: scaleX(-1);
  transition:
    background-color cubic-bezier(0.48, 0.65, 0.6, 0.79) 0.5s,
    transform cubic-bezier(0.48, 0.65, 0.6, 0.79) 0.5s;

  ${({ enableNotifications, notification }: any) =>
    enableNotifications &&
    css`
      background-color: ${notification ? '#FF0A9D' : 'rgb(255 255 255 / 21%)'};
    `}
`;

const useButtonStyles = makeStyles({
  root: (props: any) => ({
    '&.MuiButtonBase-root': {
      position: 'relative',
      width: '100%',
      height: '44px',
      minWidth: 'unset',
      maxWidth: 'inherit',
      padding: props.collapse ? '14px 13px' : '0px 24px',
      overflow: 'hidden',
      justifyContent: 'flex-start',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: 'normal',
      fontStyle: 'normal',
      textTransform: 'capitalize',
      cursor: props.cursor ?? 'pointer',
      color: props.active
        ? props?.theme?.solidWhite
        : props?.theme?.charcoalBlack3,
      backgroundColor: props.active && props.theme?.charcoalBlack3,

      borderColor: 'transparent',
      border: '2px solid transparent',
      // border: props.collapse
      //   ? `2px solid ${
      //       props.active ? props.theme?.info : 'transparent'
      //     } !important`
      //   : `2px solid transparent`,
      // borderLeft: !props.collapse
      //   ? `5px solid ${
      //       props.active ? props.theme?.info : 'transparent'
      //     } !important`
      //   : `2px solid ${
      //       props.active ? props.theme?.info : 'transparent'
      //     } !important`,
      // '& svg': {
      //   // Apply the same logic as text color to the icon
      //   filter: props.active ? 'invert(100%)' : 'invert(0%)',
      //   transition: 'filter 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      // },
      // '& img': {
      //   // Apply the same logic as text color to the icon
      //   filter: props.active ? 'invert(100%)' : 'invert(0%)',
      //   transition: 'filter 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      // },

      transition: `
        border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        width 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        transform 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms
      `,

      '&:hover': {
        backgroundColor: props.active && props.theme?.fadedBlack,
      },

      '&.MuiLoadingButton-loading': {
        color: props?.theme?.solidWhite,
        '&.MuiLoadingButton-loadingIndicator': {
          position: 'unset',
          color: props?.theme?.solidWhite,
        },
      },
      ...(props.enableNotifications && props.notification
        ? {
            '&:before': {
              content: '',
              position: 'absolute',
              width: 0,
              height: 0,
              top: 0,
              right: 0,
              borderTop: props?.notification
                ? '10.5px solid #151412'
                : '10.5px solid #151412',
              borderRight: props?.notification
                ? '10.5px solid #151412'
                : '10.5px solid #151412',
              backgroundColor: props?.notification ? '#151412' : '',
              clipPath: 'polygon(0 0, 100% 0, 0 100%)',
              transform: 'scaleX(-1)',
              transition:
                'background-color cubic-bezier(0.48, 0.65, 0.6, 0.79) 0.5s, transform cubic-bezier(0.48, 0.65, 0.6, 0.79) 0.5s',
            },
          }
        : {}),
    },
  }),
});

function NavButton({
  onClick,
  children,
  className,
  width = 'auto',
  type = 'button',
  variant = 'text',
  tooltipText,
  customHeight,
  active = false,
  disabled = false,
  collapse = false,
  fullWidth = false,
  dataTestId = undefined,
  notification = false,
  enableNotifications = false,
  awaitingNavItem = false,
  ...rest
}: Props): React.JSX.Element {
  const theme = useTheme();
  const classes = useButtonStyles({
    theme,
    active,
    collapse,
    notification,
    enableNotifications,
    ...rest,
    // ... other props needed for makeStyles
  });
  const RenderButton = (
    <Button
      type={type}
      width={width}
      active={active}
      variant={variant}
      disabled={disabled}
      collapse={collapse}
      onClick={onClick}
      className={`${classes?.root} ${className}`}
      fullWidth={fullWidth}
      customHeight={customHeight}
      data-cy={dataTestId}
      notification={notification}
      enableNotifications={enableNotifications}
      awaitingNavItem={awaitingNavItem}
      {...rest}
    >
      {children}
      {enableNotifications && notification && <HeaderMenuNotify />}
    </Button>
  );
  return (
    <Fragment>
      {collapse && tooltipText ? (
        <CustomTooltip placement={'right'} title={tooltipText}>
          {RenderButton}
        </CustomTooltip>
      ) : (
        RenderButton
      )}
    </Fragment>
  );
}

export default NavButton;
