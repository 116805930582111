/* eslint-disable react/prop-types */
import * as React from 'react';

function SageWatermark(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="674"
      height="614"
      fill="none"
      style={props?.style || {}}
      viewBox="0 0 674 614"
    >
      <path
        fill="url(#paint0_linear_1662_86780)"
        d="M416.311 654.762l-32.104-32.091a11.053 11.053 0 010-15.633l32.104-32.091a11.06 11.06 0 0115.64 0l32.104 32.091a11.054 11.054 0 012.398 12.047 11.054 11.054 0 01-2.398 3.586l-32.104 32.091a11.062 11.062 0 01-15.64 0zm428.015-221.3L664.948 612.763a70.492 70.492 0 01-49.839 20.635 70.494 70.494 0 01-49.839-20.635L433.64 481.188a13.458 13.458 0 00-9.509-3.937 13.46 13.46 0 00-9.509 3.937L282.993 612.763a70.5 70.5 0 01-49.839 20.635 70.5 70.5 0 01-49.84-20.635L3.939 433.462a13.44 13.44 0 010-19.011L414.622 3.937A13.437 13.437 0 01424.131 0a13.45 13.45 0 019.509 3.937l410.686 410.514a13.432 13.432 0 013.939 9.506 13.435 13.435 0 01-3.939 9.505zM433.64 385.736l76.471 76.44a13.45 13.45 0 0019.018 0l28.727-28.714a13.437 13.437 0 000-19.011L433.64 290.286a13.458 13.458 0 00-9.509-3.937 13.46 13.46 0 00-9.509 3.937L290.405 414.451a13.435 13.435 0 00-2.915 14.65 13.453 13.453 0 002.915 4.361l28.727 28.713a13.45 13.45 0 0019.018 0l76.472-76.439a13.441 13.441 0 0114.655-2.914 13.427 13.427 0 014.363 2.914zm190.978 171.891l124.216-124.165a13.435 13.435 0 000-19.011L433.64 99.386a13.448 13.448 0 00-19.018 0L99.428 414.452a13.44 13.44 0 000 19.011l124.216 124.162a13.451 13.451 0 0019.019 0l28.725-28.713a13.44 13.44 0 000-19.011l-76.469-76.438a13.439 13.439 0 010-19.01l219.703-219.618a13.448 13.448 0 0114.655-2.914 13.458 13.458 0 014.363 2.914l219.703 219.618a13.441 13.441 0 010 19.01l-76.47 76.439a13.44 13.44 0 000 19.011l28.726 28.715a13.47 13.47 0 004.363 2.914 13.468 13.468 0 0010.293 0 13.458 13.458 0 004.363-2.914z"
        opacity="0.2"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_1662_86780"
          x1="424.133"
          x2="424.133"
          y1="0"
          y2="658"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFDDA8"></stop>
          <stop offset="1" stopColor="#FFD2A9"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}

const MemoSageWatermark = React.memo(SageWatermark);
export default MemoSageWatermark;
