import { Box, Button, Grid, IconButton, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { actions as globalActions } from 'app/features/Global/slice';

import {
  CRMCardWrapper,
  DescContainer,
  ImgContainer,
  StyledBadgeI,
  TitleContainerNew,
} from './styles';
import CRMDrawer from './CRMDrawer';
import { useMutation, useQueryClient } from 'react-query';
import {
  syncRedtail,
  syncWealthbox,
  syncSalesforce,
  disconnectRedtail,
  disconnectWealthbox,
  disconnectSalesforce,
  disconnectHubspot,
  syncHubSpot,
} from './mutation';
import { useDispatch } from 'react-redux';
import { titleCase } from '@common/string';
import MemoSyncIcon from '@icons/SyncIcon';

interface CRMCardDataProps {
  logo: React.ReactNode;
  name: string;
  description: string;
  integrationListApi: () => void;
  handleSuccess: () => void;
  crmData: any;
  isLoading: boolean;
  link?: string;
}

const useStyles = makeStyles({
  drawer: {
    width: 500,
  },
  drawerPaper: {
    width: 500,
  },
  root: {
    display: 'flex',
  },
});

function CRMCard({
  logo,
  name,
  description,
  link,
  crmData,
  handleSuccess,
}: CRMCardDataProps): React.JSX.Element {
  const [open, setOpen] = useState(false);
  const [modalData, setModalData] = useState<any>({});
  const mutateRT = useMutation(syncRedtail);
  const mutateWB = useMutation(syncWealthbox);
  const mutateSF = useMutation(syncSalesforce);
  const mutateHubspot = useMutation(syncHubSpot);

  const mutateDisconnectRT = useMutation(disconnectRedtail);
  const mutateDisconnectWB = useMutation(disconnectWealthbox);
  const mutateDisconnectSF = useMutation(disconnectSalesforce);
  const mutateDisconnectHubspot = useMutation(disconnectHubspot);

  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const classes = useStyles();

  const handleOpen = (name: string) => {
    if (link && link !== 'Coming Soon') {
      // opening link in same tab as redirect url is added to link
      window.open(link, '_self');
    } else {
      setModalData({
        name,
        logo,
        description,
      });
      setOpen(true);
    }
  };

  const handleSync = name => {
    const payload = {
      id: crmData?.id,
    };
    if (name === 'Redtail') {
      mutateRT.mutate(payload, {
        onSuccess: res => {
          dispatch(
            globalActions.displayToast({
              duration: 3000,
              toastType: 'success',
              toastMessage: res.message,
            }),
          );
        },
        onError: (res: any) => {
          dispatch(
            globalActions.displayToast({
              duration: 3000,
              toastType: 'error',
              toastMessage: res.data.data.error,
            }),
          );
        },
      });
    } else if (name === 'Wealthbox') {
      mutateWB.mutate(payload, {
        onSuccess: res => {
          dispatch(
            globalActions.displayToast({
              duration: 3000,
              toastType: 'success',
              toastMessage: res.message,
            }),
          );
        },
        onError: (res: any) => {
          dispatch(
            globalActions.displayToast({
              duration: 3000,
              toastType: 'error',
              toastMessage: res.data.data.error,
            }),
          );
        },
      });
    } else if (name === 'Salesforce') {
      mutateSF.mutate(payload, {
        onSuccess: res => {
          dispatch(
            globalActions.displayToast({
              duration: 3000,
              toastType: 'success',
              toastMessage: res.message,
            }),
          );
        },
        onError: (res: any) => {
          dispatch(
            globalActions.displayToast({
              duration: 3000,
              toastType: 'error',
              toastMessage: res.data.data.error,
            }),
          );
        },
      });
    } else if (name === 'Hubspot') {
      mutateHubspot.mutate(payload, {
        onSuccess: res => {
          dispatch(
            globalActions.displayToast({
              duration: 3000,
              toastType: 'success',
              toastMessage: res.message,
            }),
          );
        },
        onError: (res: any) => {
          dispatch(
            globalActions.displayToast({
              duration: 3000,
              toastType: 'error',
              toastMessage: res.data.data.error,
            }),
          );
        },
      });
    }
  };

  const handleDisconnect = (name: string) => {
    const payload = {
      id: crmData?.id,
    };
    if (name === 'Redtail') {
      mutateDisconnectRT.mutate(payload, {
        onSuccess: res => {
          dispatch(
            globalActions.displayToast({
              duration: 3000,
              toastType: 'success',
              toastMessage: res.message,
            }),
          );
          queryClient.invalidateQueries('integrations');
        },
        onError: (res: any) => {
          dispatch(
            globalActions.displayToast({
              duration: 3000,
              toastType: 'error',
              toastMessage: res.message,
            }),
          );
        },
      });
    } else if (name === 'Wealthbox') {
      mutateDisconnectWB.mutate(payload, {
        onSuccess: res => {
          dispatch(
            globalActions.displayToast({
              duration: 3000,
              toastType: 'success',
              toastMessage: res.message,
            }),
          );
          queryClient.invalidateQueries('integrations');
        },
        onError: (res: any) => {
          dispatch(
            globalActions.displayToast({
              duration: 3000,
              toastType: 'error',
              toastMessage: res.message,
            }),
          );
        },
      });
    } else if (name === 'Salesforce') {
      mutateDisconnectSF.mutate(payload, {
        onSuccess: res => {
          dispatch(
            globalActions.displayToast({
              duration: 3000,
              toastType: 'success',
              toastMessage: res.message,
            }),
          );
          queryClient.invalidateQueries('integrations');
        },
        onError: (res: any) => {
          dispatch(
            globalActions.displayToast({
              duration: 3000,
              toastType: 'error',
              toastMessage: res.message,
            }),
          );
        },
      });
    } else if (name === 'Hubspot') {
      mutateDisconnectHubspot.mutate(payload, {
        onSuccess: res => {
          dispatch(
            globalActions.displayToast({
              duration: 3000,
              toastType: 'success',
              toastMessage: res.message,
            }),
          );
          queryClient.invalidateQueries('integrations');
        },
        onError: (res: any) => {
          dispatch(
            globalActions.displayToast({
              duration: 3000,
              toastType: 'error',
              toastMessage: res.message,
            }),
          );
        },
      });
    }
  };

  const formattedCRMCardStatusLabel = titleCase(crmData?.status.toLowerCase());

  return (
    <>
      <Grid
        container
        item
        justifyContent={'flex-start'}
        alignItems={'center'}
        xs={11.5}
        sm={5.7}
        lg={2.83}
      >
        <CRMCardWrapper item xs={12}>
          <Grid
            item
            container
            xs={12}
            justifyContent={'space-between'}
            alignItems={'flex-start'}
          >
            <Grid
              item
              container
              xs={6}
              justifyContent={'flex-start'}
              alignItems={'flex-start'}
              height={'65px'}
            >
              <ImgContainer item>{logo}</ImgContainer>
            </Grid>
            {crmData && (
              <Grid
                item
                container
                xs={6}
                justifyContent={'flex-end'}
                alignItems={'center'}
              >
                <StyledBadgeI item $status={formattedCRMCardStatusLabel}>
                  {formattedCRMCardStatusLabel}
                </StyledBadgeI>
              </Grid>
            )}
          </Grid>
          <Grid item xs={12}>
            <Grid container direction="column">
              <Grid item>
                <TitleContainerNew>{name}</TitleContainerNew>
              </Grid>
              <Grid item pt={0}>
                {description.length > 75 ? (
                  <Tooltip title={description} placement="top">
                    <DescContainer>{`${description.substring(0, 75)}...`}</DescContainer>
                  </Tooltip>
                ) : (
                  <DescContainer>{description}</DescContainer>
                )}
              </Grid>
            </Grid>
          </Grid>

          {(() => {
            switch (crmData?.status) {
              case 'CONNECTED':
                return (
                  <Box display={'flex'} justifyContent={'flex-start'} gap={1}>
                    <IconButton
                      style={{
                        padding: '0',
                        width: 'auto',
                        flexBasis: '20%',
                        textTransform: 'none',
                        marginBottom: '-8px',
                        color: '#101828',
                        fontSize: '14px',
                        backgroundColor: 'white',
                      }}
                      onClick={() => handleSync(name)}
                      title="Sync"
                    >
                      <MemoSyncIcon />
                    </IconButton>
                    <Button
                      variant="outlined"
                      style={{
                        flexBasis: '80%',
                        borderRadius: '20px',
                        border: '1px solid var(--neutral-200, #EFEFEF)',
                        boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.05)',
                        textTransform: 'none',
                        marginBottom: '-8px',
                        color: '#101828',
                        fontSize: '14px',
                        backgroundColor: 'white',
                      }}
                      onClick={() => handleDisconnect(name)}
                    >
                      Disconnect
                    </Button>
                  </Box>
                );
              default:
                return (
                  <Button
                    variant="outlined"
                    style={{
                      width: '100%',
                      borderRadius: '20px',
                      border: '1px solid var(--neutral-200, #EFEFEF)',
                      boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.05)',
                      textTransform: 'none',
                      marginBottom: '-8px',
                      color: '#101828',
                      fontSize: '14px',
                      backgroundColor:
                        crmData?.status === 'CONNECTED' ? '#e5e6e8' : 'white',
                    }}
                    disabled={link === 'Coming Soon'}
                    onClick={() => handleOpen(name)}
                  >
                    {link === 'Coming Soon' ? link : 'Connect'}
                  </Button>
                );
                break;
            }
          })()}
        </CRMCardWrapper>
      </Grid>
      {open && (
        <div className={classes.root}>
          <CRMDrawer
            open={open}
            setOpen={setOpen}
            modalData={modalData}
            handleSuccess={handleSuccess}
          />
        </div>
      )}
    </>
  );
}

export default CRMCard;
