export const shouldForwardProp = (prop: string): boolean =>
  ![
    'collapse',
    'active',
    'notification',
    'enableNotifications',
    'animate',
    'hover',
    'isSender',
    'lineClamp',
    'borderColor',
    'borderSize',
    'backgroundColor',
    'alignItem',
    'expand',
    'awaitingNavItem',
    'isVisible',
    'isDesktopDevice',
    'textAlign',
    'width',
    'delay',
    'duration',
    'padding',
  ].includes(prop);
