import React from 'react';
import {
  BoltIconDiv,
  DataSectionWrapper,
  DetailContainer,
  DetailsDataContainer,
  DetailsDataWrapper,
  FooterButtonContainer,
  FooterButtonWrapper,
  ImplementationHeader,
  StyledAccordion,
  StyledAccordionContainer,
  StyledAccordionDetails,
  StyledAccordionSummary,
  StyledApplyButton,
  StyledButton,
  StyledDesc,
  StyledDrawerContainer,
  StyledTitle,
  StyledTypography,
} from './styles';
import { Divider, Typography } from '@mui/material';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import BoltIcon from '@icons/bolt';
import CheckIcon from '@mui/icons-material/Check';

interface InsightDrawerProps {
  handleClose?: () => void;
  triggerQuery: (query: string) => void;
  selectedData: any;
}

interface ImplementationProps {
  key: number;
  title: string;
  funds: Array<string>;
}

function Implementation({
  title,
  funds,
}: ImplementationProps): React.JSX.Element {
  return (
    <StyledAccordion defaultExpanded sx={{ mt: 3 }}>
      <StyledAccordionSummary
        expandIcon={<ExpandMoreOutlinedIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <BoltIconDiv>
          <BoltIcon fill="#00B186" />
        </BoltIconDiv>
        <StyledTypography> {title} </StyledTypography>
      </StyledAccordionSummary>
      <StyledAccordionDetails>
        <DetailContainer>
          {funds.map((item, index) => (
            <DetailsDataContainer key={index}>
              <DetailsDataWrapper>
                <Typography>{item}</Typography>
              </DetailsDataWrapper>
            </DetailsDataContainer>
          ))}
        </DetailContainer>
      </StyledAccordionDetails>
    </StyledAccordion>
  );
}

function InsightDrawer({
  handleClose,
  triggerQuery,
  selectedData,
}: InsightDrawerProps): React.JSX.Element {
  const item = selectedData;
  let query = item?.viewpoint?.call_to_action;
  if (!query)
    query = `Show me details on how to implement "${item?.viewpoint?.call_to_action}" viewpoints`;

  return (
    <StyledDrawerContainer>
      <DataSectionWrapper style={{ width: '100%', paddingBottom: '15px' }}>
        <StyledTitle>{item?.viewpoint?.title}</StyledTitle>
        <StyledDesc>{item?.viewpoint?.description}</StyledDesc>
      </DataSectionWrapper>
      <Divider />
      <StyledAccordionContainer>
        <ImplementationHeader>Implementations</ImplementationHeader>
        {item?.implementations?.map((implementation: any, index) => (
          <Implementation
            key={index}
            title={implementation.description}
            funds={implementation.items}
          />
        ))}
      </StyledAccordionContainer>
      <FooterButtonContainer style={{ backdropFilter: 'blur(8px)' }}>
        <FooterButtonWrapper>
          <StyledButton variant="outlined" onClick={handleClose}>
            Hide Implementation
          </StyledButton>
          <StyledApplyButton
            variant="outlined"
            onClick={() => triggerQuery(query)}
          >
            <CheckIcon sx={{ fontSize: 'medium', marginRight: '4px' }} />
            Apply
          </StyledApplyButton>
        </FooterButtonWrapper>
      </FooterButtonContainer>
    </StyledDrawerContainer>
  );
}

export default InsightDrawer;
