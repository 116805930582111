import { Grid, styled } from '@mui/material';

export const SliderWrapper = styled(Grid)`
  height: 20px;

  .viewpoint_info {
    border-radius: var(--Radius-Regular, 8px);
    background: #f6f4fc;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: -11px;
  }

  .viewpoint_text {
    color: var(--Text-Dark-Strong, #282829);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }
`;
