import * as React from 'react';

function Warning(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 5.833c.368 0 .667.299.667.667V9a.667.667 0 01-1.334 0V6.5c0-.368.299-.667.667-.667z"
        fill="#EA780E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.167 1.557a1.66 1.66 0 012.273.61l.001.002 5.499 9.498a1.667 1.667 0 01-1.438 2.5H2.498a1.667 1.667 0 01-1.438-2.5L6.56 2.169v-.002a1.66 1.66 0 01.608-.61zm-.03.943l.577.334-5.5 9.5a.334.334 0 00.287.5h10.997a.333.333 0 00.287-.5L8.284 2.83a.327.327 0 00-.568 0L7.137 2.5z"
        fill="#EA780E"
      />
      <path d="M8 12a.75.75 0 100-1.5.75.75 0 000 1.5z" fill="#EA780E" />
    </svg>
  );
}

const MemoWarning = React.memo(Warning);
export default MemoWarning;
