import { Card, Container, Grid } from '@mui/material';
import styled from 'styled-components';
import { shouldForwardProp } from 'utils/forwardProp';

interface PriorityContainerProps {
  priority?: string;
}

export const ChatPageContainer = styled(Grid)`
  width: 100%;
  height: 100%;
`;

export const ChatPageWrapper = styled(Grid)`
  width: 100%;
  height: 100%;
`;

export const ChatBody = styled(Grid)`
  width: 100%;
  height: 100%;
  // border-radius: 8px;
  // border: 2px solid ${props => props.theme.borderColorShade};
  // background: ${props => props.theme.background1};
  // box-shadow: ${props => props.theme.boxShadow3};
  // backdrop-filter: blur(2.5px);
`;

export const DashboardContainer = styled(Container)`
  max-width: 100%;
`;

export const MainDiv = styled('div')`
  display: flex;
  align-items: center;
`;

export const InvestmentCard = styled(Card)`
  width: 46%;
  margin-top: 2rem;
  background-color: white;
  border: 2px solid #ff5c12 !important;
  box-shadow: 0px 0px 10px 0px #ffc38c !important;
  border-radius: 6px !important;
`;

export const InvestmentNumber = styled('div')`
  margin-right: 16px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background: linear-gradient(180deg, #f90 0%, #ff5c12 100%);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CardContainer = styled.div`
  width: 350px;
  height: 400px;
  border: 1px solid #e4e7ec;
  background: #fff;
  border-radius: 8px;
`;

export const ContentContainer = styled.div`
  width: 94%;
  padding: 10px;
  height: 310px;
  border-bottom: 1px solid #e4e7ec;
`;

export const LogoContainer = styled.div`
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PriorityContainer = styled.div<PriorityContainerProps>`
  width: fit-content;
  height: 7px;
  border: ${props =>
    props.priority === 'high priority'
      ? '1px solid #ffd7d7'
      : '1px solid #ffca99'};
  background: ${props =>
    props.priority === 'high priority' ? '#ffecec' : '#fff2e7'};
  border-radius: 20px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  color: ${props =>
    props.priority === 'high priority' ? '#fa2238' : '#ea780e'};
`;

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 0px 0px 15px;
`;

export const TitleText = styled.div`
  margin: 15px 0px 10px;
  font-size: 14px;
  color: #000;
`;

export const DescriptionText = styled.div`
  font-size: 12px;
  color: #000;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SubsequentLoginCardWrapper = styled(Grid)`
  border-radius: 18px;
  border: 1px solid #818181;
  background: #fff;
  padding: 8px 18px 10px 16px;
  height: 36px;
  display: flex;
  cursor: pointer;
`;

export const CardText = styled.p`
  font-size: 16px;
  color: #818181;
  font-weight: 500;
`;

export const HomepageCardQuestionWrapper = styled(Grid).withConfig({
  shouldForwardProp,
})<any>`
  padding: 0 2px;
  min-height: 20px;
  height: fit-content;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  height: calc(
    100% / 3
  ); // statically passed 3 as denominator as we need to match with design
`;

export const CardQuestionText = styled(Grid).withConfig({
  shouldForwardProp,
})<any>`
  font-size: 14px;
  color: ${props => (props.hover ? '#fff' : '#d2d2d2')};
  font-weight: 500;
  text-wrap: wrap;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3; /* Limit to 3 lines */
  overflow: hidden;
  text-overflow: ellipsis;
`;
