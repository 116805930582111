import ApiInstance from '@instances/index';
import { useQuery } from 'react-query';

const fetchViewPoints = async () => {
  const { data } = await ApiInstance.get(`viewpoints`, {
    headers: {
      'X-Tifin-Ai-Auth': localStorage.getItem('x-tifin-ai-token'),
    },
  });
  return data;
};

export const useGetViewPoints = (): any => {
  return useQuery(['get-cio-viewpoints'], fetchViewPoints, {
    select: (response: any) => {
      return (
        response?.data.flatMap(file =>
          file.data.map(viewpoint => {
            return {
              focus: viewpoint.focus,
              summary: viewpoint.summary,
            };
          }),
        ) || []
      );
    },
    onError: (error: any) => {
      console.error('Error fetching viewpoints:', error);
    },
    keepPreviousData: true,
  });
};
