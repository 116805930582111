import React from 'react';
import { WhatElseButton } from './SampleQuestionsV2';
import ChatFeedbackV2 from './ChatFeedbackV2';
import { WhatElseResponseContainer } from './styles';
import { Box } from '@mui/material';

interface Props {
  onFeedbackSubmit: (data: {
    scale: string | number | null;
    chatLogId: string | null;
    comment: string | null;
  }) => void;
  chatLogId: string | null;
  scale: string | number | null;
  isFeedbackEnabled: boolean;
}

const WhatElseResponse = (props: Props): React.JSX.Element => {
  const { onFeedbackSubmit, chatLogId, scale, isFeedbackEnabled } = props;

  return (
    <WhatElseResponseContainer>
      <ChatFeedbackV2
        hideTextAndIcon
        isFeedbackEnabled={isFeedbackEnabled}
        chatLogId={chatLogId}
        scale={scale}
        onFeedbackSubmit={onFeedbackSubmit}
      >
        <Box marginBottom={'-10px'}>
          <WhatElseButton />
        </Box>
      </ChatFeedbackV2>
    </WhatElseResponseContainer>
  );
};

export default WhatElseResponse;
