import styled from 'styled-components';
import Slider from 'react-slick';
import {
  SuggestionCardWidth,
  SuggestionCardHeight,
} from '../SuggestionCard/styles';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export const HoldingListSliderWrapper = styled(Slider)<any>`
  width: 100%;
  height: 100%;
  overflow: hidden;

  .slick-list {
    height: ${SuggestionCardHeight + 2}px !important;

    .slick-track {
      min-width: 99%;
      height: ${SuggestionCardHeight + 2}px !important;
      margin: 0;
    }
    .slick-slide {
      width: ${SuggestionCardWidth + 10}px !important;
      height: ${SuggestionCardHeight}px !important;
      margin-right: 1rem;
      margin-bottom: 1rem;
    }
  }

  .slick-dots {
    position: relative;
    width: 90%;
    margin: auto;
    bottom: 4px !important;

    li {
      button {
        &:before {
          color: ${props => props.theme.pink};
        }
      }
    }
  }

  .slick-next,
  .slick-prev {
    top: 90% !important;
    height: 50px !important;
  }
  .slick-prev {
    left: 12px !important;
  }
  .slick-next {
    right: 12px !important;
  }
`;
