import { Card, Grid, InputLabel, Button, styled } from '@mui/material';

export const ProfileCardContainer = styled(Grid)`
  width: 100%;
  height: 100%;
  overflow: auto;
  height: 86vh;
  /* padding-right: 4rem; */
`;

export const StyledInputLabel = styled(InputLabel)`
  font-size: 14px !important;
  margin-bottom: 4px;
`;

export const StyledCard = styled(Card)`
  text-align: center;
  padding: 0px;
  height: 140px;
  border-radius: 20px;
`;

export const DocumentContainer = styled(Grid)`
  border: 1px solid #e4e7ec;
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.12);
  background: #fff;
  color: #000;
`;

export const TextWrapper = styled('p')`
  width: 120px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  text-align: left;
`;

export const CustomButton = styled(Button)`
  background: #fff;
  color: #000;
  border-radius: 20px;
  font-size: 12px;
  text-transform: none;
  border: 1px solid #e4e7ec;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.12);
`;

export const SubmitButton = styled(Button)`
  border-radius: 20px;
  text-transform: none;
  padding: 8px 16px;
  font-size: 14px;
`;

export const UploadButton = styled(Button)`
  border-radius: 20px;
  text-transform: none;
  height: 36px;
  padding: 0px 16px;
  font-size: 14px;
`;

export const ImageWrapper = styled('img')`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
