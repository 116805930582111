/* eslint-disable react/prop-types */

import React from 'react';

function RiskIcon(props: any): React.JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      fill="none"
      viewBox="0 0 48 48"
    >
      <rect
        width="48"
        height="48"
        fill="url(#paint0_linear_2498_28868)"
        rx="4"
      ></rect>
      <path
        fill="#fff"
        d="M34.447 24.469l-3-7.5a.75.75 0 00-.86-.454l-5.837 1.297V15.75a.75.75 0 10-1.5 0v2.398l-6.163 1.37a.75.75 0 00-.534.45v.006l-3 7.495a.742.742 0 00-.053.281c0 2.185 2.3 3 3.75 3 1.45 0 3.75-.815 3.75-3a.742.742 0 00-.053-.281l-2.673-6.678 4.976-1.104V31.5h-1.5a.75.75 0 100 1.5h4.5a.75.75 0 100-1.5h-1.5V19.352l4.781-1.061-2.477 6.178a.742.742 0 00-.054.281c0 2.185 2.3 3 3.75 3 1.45 0 3.75-.815 3.75-3a.742.742 0 00-.053-.281zM17.25 29.25c-.706 0-2.134-.338-2.243-1.372l2.243-5.609 2.244 5.609c-.11 1.034-1.538 1.372-2.244 1.372zm13.5-3c-.706 0-2.134-.338-2.243-1.372l2.243-5.609 2.243 5.609c-.11 1.034-1.537 1.372-2.243 1.372z"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_2498_28868"
          x1="0"
          x2="48"
          y1="0"
          y2="48"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={props.stopColor || '#A8A8A8'}></stop>
          <stop offset="1" stopColor="#818181"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}

const MemoRiskIcon = React.memo(RiskIcon);
export default MemoRiskIcon;
