import {
  Autocomplete,
  Box,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { StyledInputLabel } from '@pages/Profile/styles';
import React, { Fragment, useEffect, useState } from 'react';
import {
  ClientsDrawerFooterButtonContainer,
  StyleTableBodyWrapper,
  StyledTableBodyContainer,
  StyledTableContainer,
  StyledTableHead,
  StyledTableHeaderContainer,
} from './styles';
import { Add } from '@mui/icons-material';
import {
  FooterButtonWrapper,
  StyledApplyButton,
  StyledButton,
} from '@pages/CIOViewpoints/styles';
import deleteSvg from '@images/new-delete.svg';

import { ITickerWeights, PortfolioData } from './types';
import { regExpNumber, regExpAlphaNumeric } from '@common/string';
import { DebounceInput } from '@components/UIComponents/Input';
import { useQuery } from 'react-query';
import { fetchTickerSearchResults } from './mutation';
import { cashSecuritySearchList } from '@common/constant';
import { validArray } from '@common/validArray';

interface PortfolioContentProps {
  onClose?: () => void;
  onSubmit?: () => void;
  showFooterButton?: boolean;
  refreshData?: boolean;
  title?: string;
  showTitle?: boolean;
  portfolioData: PortfolioData;
  setPortfolioData: (fieldName: string, value: string) => any;
  tickerWeights: ITickerWeights[];
  setTickerWeights: (value: any) => any;
}

const PortfolioForm: React.FC<PortfolioContentProps> = ({
  onClose,
  onSubmit,
  showFooterButton = false,
  refreshData = false,
  title = '',
  showTitle = false,
  portfolioData,
  setPortfolioData,
  tickerWeights,
  setTickerWeights,
}): React.JSX.Element => {
  const {
    accName,
    accNumber,
    // investmentObjectives,
    // constraints,
    // selectedRiskProfile,
  } = portfolioData;

  const initialTickerStates = { ticker: '', quantity: '' };

  const [optionList, setOptionList] = React.useState<string[]>([]);
  const [tickerData, setTickerdata] = useState<{
    ticker: string;
    quantity: string | number;
  }>(initialTickerStates);
  const [inputValue, setInputValue] = React.useState('');

  const { isLoading } = useQuery(
    ['tickerSearchResults', inputValue],
    () => fetchTickerSearchResults(inputValue),
    {
      enabled:
        !!inputValue &&
        !cashSecuritySearchList.includes(inputValue.toLowerCase()),
      onSuccess: res => {
        setOptionList(res);
      },
    },
  );

  const handleSearchSecurity = (name: string) => {
    if (
      cashSecuritySearchList.includes(name.toLowerCase()) &&
      optionList?.[0] !== '$CASH ($CASH)'
    ) {
      setOptionList(['$CASH ($CASH)', ...optionList]);
    } else {
      setInputValue(name);
    }
  };

  useEffect(() => {
    if (refreshData == true) {
      setTickerWeights([initialTickerStates]);
    }
  }, [refreshData]);

  const handleAddTicker = () => {
    if (tickerData.ticker !== '' || tickerData.quantity !== '') {
      const existingTicker = tickerWeights.find(
        item => tickerData.ticker === item.ticker,
      );

      let updatedTickerWeights;
      if (existingTicker) {
        // Update the existing ticker's quantity
        updatedTickerWeights = tickerWeights.map(item => {
          if (item.ticker === tickerData.ticker) {
            return {
              ...item,
              weight: Number(item.weight) + Number(tickerData.quantity),
            };
          }
          return item;
        });
      } else {
        // Find the corresponding ticker option based on the ticker symbol
        const selectedTickerOption =
          validArray(optionList) &&
          optionList.find(option => option.startsWith(tickerData.ticker));
        // Extract the ticker name from the ticker option
        const tickerName = selectedTickerOption
          ? selectedTickerOption
              .substring(selectedTickerOption.indexOf('('))
              .trim()
          : '';
        // Add the new ticker to the array with both ticker and ticker name

        updatedTickerWeights = [
          ...tickerWeights,
          {
            ticker: tickerData.ticker,
            weight: tickerData.quantity,
            tickerName,
          },
        ];
      }

      setTickerWeights(updatedTickerWeights);
      setTickerdata(initialTickerStates);
    }
  };

  const handleRemoveTicker = index => {
    const newTickerWeights = [...tickerWeights];
    newTickerWeights.splice(index, 1);
    setTickerWeights(newTickerWeights);
  };

  const handleTickerChange = (field, newValue) => {
    setTickerdata({
      ...tickerData,
      [field]: newValue,
    });
  };
  return (
    <>
      <Grid
        container
        p={2}
        sx={{ background: '#fff' }}
        rowGap={2}
        columnGap={2}
      >
        {showTitle && (
          <Grid item xs={12}>
            <Typography variant="h6">{title}</Typography>
          </Grid>
        )}
        <Grid
          item
          container
          xs={12}
          sm={12}
          columnGap={2}
          rowGap={2}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Grid item xs={12} md={5.7}>
            <StyledInputLabel>
              <Typography variant="subtitle2">Account Name</Typography>
            </StyledInputLabel>
            <TextField
              variant="outlined"
              fullWidth
              size={'small'}
              type="text"
              value={accName}
              placeholder="Account Name"
              onChange={e =>
                setPortfolioData(
                  'accName',
                  e.target.value.replace(regExpAlphaNumeric, ''),
                )
              }
              inputProps={{
                maxLength: 50,
              }}
            />
          </Grid>

          <Grid item xs={12} md={5.7}>
            <StyledInputLabel>
              <Typography variant="subtitle2">Account Number</Typography>
            </StyledInputLabel>
            <TextField
              variant="outlined"
              fullWidth
              size={'small'}
              type="text"
              value={accNumber}
              placeholder="Account Number"
              onChange={e =>
                setPortfolioData(
                  'accNumber',
                  e.target.value.replace(regExpNumber, ''),
                )
              }
              inputProps={{
                maxLength: 50,
              }}
            />
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={12}
          sm={12}
          columnGap={1}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Grid item xs={9} sm={8} md={5.5} lg={4.5}>
            <Typography
              variant="h6"
              gutterBottom
              fontSize={'16px'}
              onClick={handleAddTicker}
            >
              Add Portfolio Holding
            </Typography>
          </Grid>
          <Grid item xs={2.5} sm={3.5} md={6} lg={7}>
            <Divider />
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={12}
          justifyContent={'space-between'}
          alignItems={'center'}
          rowGap={1}
        >
          <Grid item xs={12} lg={5.7} xl={4.5}>
            <StyledInputLabel>
              <Typography variant="subtitle2">Ticker</Typography>
            </StyledInputLabel>
            <Autocomplete
              size={'small'}
              sx={{ minWidth: 250 }}
              options={optionList}
              defaultValue={tickerData?.ticker}
              value={tickerData?.ticker}
              onChange={(e: any, newValue: string | null) => {
                handleTickerChange('ticker', newValue);
              }}
              loading={isLoading}
              loadingText="Please wait..."
              renderInput={params => (
                <DebounceInput
                  className="autoCompleteTxt"
                  {...params}
                  variant="outlined"
                  type="text"
                  name="ticker"
                  size="small"
                  debounceTimeout={300}
                  handleDebounce={(val: string) => handleSearchSecurity(val)}
                  value={inputValue}
                  placeholder="Search"
                  title={tickerData?.ticker}
                />
              )}
              renderOption={(props, option) => (
                <Box component="li" fontSize="14px" {...props} key={option}>
                  {option}
                </Box>
              )}
              isOptionEqualToValue={(options, value) =>
                options.valueOf === value.valueOf
              }
            />
          </Grid>

          <Grid item xs={12} lg={5.7} xl={4}>
            <StyledInputLabel>
              <Typography variant="subtitle2">Quantity</Typography>
            </StyledInputLabel>
            <TextField
              variant="outlined"
              fullWidth
              size={'small'}
              type="text"
              name="quantity"
              value={tickerData.quantity}
              onChange={e =>
                handleTickerChange(
                  'quantity',
                  +e.target.value.replace(regExpNumber, ''),
                )
              }
              placeholder="Enter quantity"
            />
          </Grid>
          <Grid item xs={12} lg={5.7} xl={3}>
            <StyledButton
              variant="outlined"
              onClick={handleAddTicker}
              style={{ marginTop: '24px', width: '100%', fontSize: '14px' }}
              startIcon={<Add />}
              disabled={
                tickerData.ticker === '' ||
                tickerData.quantity === '' ||
                tickerData.quantity === 0
              }
            >
              Add Security
            </StyledButton>
          </Grid>
        </Grid>
        <StyledTableContainer item container xs={12} sm={12}>
          <StyledTableHeaderContainer
            item
            container
            xs={12}
            alignItems={'center'}
          >
            <StyledTableHead item xs={7} color="#86858B" fontSize="12px">
              Ticker
            </StyledTableHead>
            <StyledTableHead item xs={3} color="#86858B" fontSize="12px">
              Quantity
            </StyledTableHead>
            <StyledTableHead item xs={2}></StyledTableHead>
          </StyledTableHeaderContainer>
          <StyledTableBodyContainer item container xs={11.8}>
            {tickerWeights.map((item, index) => {
              const tickerSymbolMatch = item?.ticker.match(/^\w+/);
              const tickerSymbol = tickerSymbolMatch
                ? tickerSymbolMatch[0]
                : '';
              return (
                <StyleTableBodyWrapper
                  key={index}
                  item
                  container
                  xs={12}
                  alignItems={'flex-start'}
                >
                  <StyledTableHead item container xs={7} direction={'column'}>
                    {tickerSymbol}
                    <StyledTableHead
                      item
                      xs={12}
                      color="#86858B"
                      fontSize="12px"
                    >
                      {item?.tickerName &&
                        item.tickerName.replace(/^\(|\)$/g, '')}
                    </StyledTableHead>
                  </StyledTableHead>
                  <StyledTableHead
                    item
                    xs={3}
                    color="#86858B"
                    fontSize="14px"
                    style={{
                      textAlign: 'left',
                      display: 'flex',
                      alignItems: 'center',
                      padding: '5px 5px 5px 15px',
                    }}
                  >
                    {' '}
                    {item?.weight}
                  </StyledTableHead>
                  <StyledTableHead
                    item
                    xs={2}
                    container
                    alignItems={'center'}
                    justifyContent={'flex-end'}
                  >
                    <img
                      src={deleteSvg}
                      onClick={() => handleRemoveTicker(index)}
                      alt="Delete image"
                    />
                  </StyledTableHead>
                </StyleTableBodyWrapper>
              );
            })}
          </StyledTableBodyContainer>
        </StyledTableContainer>
        {showFooterButton && (
          <>
            <ClientsDrawerFooterButtonContainer
              style={{
                width: '100%',
                left: 0,
                top: '20px',
                position: 'relative',
              }}
            >
              <FooterButtonWrapper>
                <StyledButton variant="outlined" onClick={onClose}>
                  Cancel
                </StyledButton>
                <StyledApplyButton variant="outlined" onClick={onSubmit}>
                  Confirm
                </StyledApplyButton>
              </FooterButtonWrapper>
            </ClientsDrawerFooterButtonContainer>
          </>
        )}
      </Grid>
    </>
  );
};

export default PortfolioForm;
