import {
  ForumOutlined as ForumOutlinedIcon,
  StreamOutlined as StreamOutlinedIcon,
} from '@mui/icons-material';
// import GroupIcon from '@mui/icons-material/Group';
import { MenuListProps } from '@features/Global/types';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import { CHEAT_SHEET } from '@common/constant';

export const TOUR_NAVIGATION: Array<MenuListProps> = [
  {
    name: 'Conversations',
    enableNotifications: true,
    notification: true,
    path: '/conversations/start',
    cursor: 'pointer',
    showToUser: ['FIRM_ADMIN', 'ADVISOR'],
    children: [
      {
        name: 'Conversations',
        enableNotifications: true,
        notification: true,
        path: '/start',
        cursor: 'pointer',
        Icon: ForumOutlinedIcon,
      },
    ],
    Icon: ForumOutlinedIcon,
  },
  {
    name: 'Current Markets',
    enableNotifications: false,
    notification: false,
    path: '/actions',
    cursor: 'pointer',
    children: [],
    Icon: StreamOutlinedIcon,
    showToUser: ['FIRM_ADMIN', 'ADVISOR'],
  },
  {
    name: 'My Clients',
    enableNotifications: false,
    notification: false,
    path: '/clients',
    cursor: 'pointer',
    children: [],
    Icon: GroupsOutlinedIcon,
    showToUser: ['FIRM_ADMIN', 'ADVISOR'],
  },
  {
    name: 'Integrations',
    enableNotifications: false,
    notification: false,
    path: '/integrations',
    cursor: 'pointer',
    children: [],
    Icon: InsertLinkIcon,
    showToUser: ['FIRM_ADMIN', 'ADVISOR'],
  },
];

export const TOUR_SAMPLE_QUESTIONS: any = CHEAT_SHEET[1].content;

export const MOCKED_CIO = {
  data: [
    {
      filename: 'T Rowe Price - 2024 Outlook',
      data: [
        {
          id: '65ec2602-0573-4023-8d44-2fcacc891da5',
          documentId: 'f3b01495-bf35-419f-b9c8-75b39d3b9e7d',
          enterpriseId: '5f7ce0e6-362e-4ecf-bbd9-956b00a0f9a8',
          extractedViewpointId: 'a1b2820f-8eb8-404d-b5ca-1f67725c7b0d',
          advisorQuestions: [`Low cost healthcare ETF's`],
          type: 'sector',
          focus: 'Health Care',
          direction: 'positive',
          summary: `The Health Care sector is highlighted for its potential in biomedical devices and obesity treatments, which are expected to have broader implications for food, retail, and hospitality sectors. Innovations, including bioprocessing and medical devices, especially with the U.S. FDA's approval of diabetes medications for weight control, are anticipated to significantly impact equity opportunities in 2024. These developments could not only change patient outcomes but also transform health care economics, given obesity's role in various conditions.`,
          source: [
            {
              highlighted:
                'Health Care remains one of our favorite sectors going into the new year\nAnd we believe earnings growth for the sector should accelerate in 2024',
              page_number: 9,
            },
          ],
          createdAt: '2024-03-14T18:44:14.565Z',
          updatedAt: '2024-03-14T18:44:14.566Z',
          no_clients: 0,
          clients: [],
        },
        {
          id: 'fde71bac-7741-43aa-bd86-1fd9f29eb4ac',
          documentId: 'f3b01495-bf35-419f-b9c8-75b39d3b9e7d',
          enterpriseId: '5f7ce0e6-362e-4ecf-bbd9-956b00a0f9a8',
          extractedViewpointId: '0debf9aa-cb62-4a1e-bbe7-f22edfca71da',
          advisorQuestions: [
            `How do European equities compare to US Equities & Emerging Markets?`,
          ],
          type: 'themes',
          focus: 'Europe (EU)',
          direction: 'positive',
          summary: `The outlook for European equities is cautious, with economic challenges expected to weigh on markets, particularly in the first half of 2024. Near-term prospects appear less attractive compared to other developed markets and emerging markets, with potential for weakened demand and pressure on profit margins. However, a business cycle recovery anticipated later in the year could present further opportunities, with overall valuations remaining reasonable.`,
          source: [
            {
              highlighted:
                'Real assets can protect against inflation with low correlation to stocks and bonds.',
              page_number: 19,
            },
          ],
          createdAt: '2024-03-14T19:32:13.872Z',
          updatedAt: '2024-03-14T19:32:13.873Z',
          no_clients: 0,
          clients: [],
        },
        {
          id: '5bf14fd5-a470-47b6-8fbb-74ac309127e5',
          documentId: 'f3b01495-bf35-419f-b9c8-75b39d3b9e7d',
          enterpriseId: '5f7ce0e6-362e-4ecf-bbd9-956b00a0f9a8',
          extractedViewpointId: '13195735-5c9a-4579-a5fb-3e0835d625ee',
          advisorQuestions: [
            'What is the expected  return and risk of US treasuries?',
          ],
          type: 'asset class',
          focus: 'US Bonds',
          direction: 'positive',
          summary: `The outlook for US Bonds in 2024 suggests a challenging environment with high volatility, particularly at the long end of the yield curve, as major central banks, including the Federal Reserve, are expected to hold rates steady despite market expectations of rate cuts. The shift in U.S. Treasury issuance towards longer maturities and the anticipation of steepening yield curves reflect a complex landscape. Opportunities may arise in below investment-grade corporates and shorter-term investment-grade corporates, with careful issuer analysis being crucial. Credit spreads on longer-term investment-grade corporates may not fully compensate for risk, although potential spread widening could offer attractive opportunities.`,
          source: [
            {
              highlighted:
                'Stocks are likely to make new highs next year. Valuations are fair, and earnings will likely accelerate due to leaner cost structures and still positive top-line growth.',
              page_number: 19,
            },
          ],
          createdAt: '2024-03-14T18:44:08.979Z',
          updatedAt: '2024-03-14T18:44:08.979Z',
          no_clients: 0,
          clients: [],
        },
        {
          id: 'd1b7dfde-84a4-4606-9432-430854b2b519',
          documentId: 'f3b01495-bf35-419f-b9c8-75b39d3b9e7d',
          enterpriseId: '5f7ce0e6-362e-4ecf-bbd9-956b00a0f9a8',
          extractedViewpointId: '8876d257-b0e0-4dda-9718-dcb6044a2433',
          advisorQuestions: [
            'What is the correlation between commodities and equities?',
          ],
          type: 'asset class',
          focus: 'Commodities',
          direction: 'negative',
          summary: `Energy and commodity-related stocks are highlighted as potentially attractive hedges for 2024, particularly if inflation remains higher than expected or if geopolitical events lead to energy price shocks. The push towards decarbonization and the concentration of emerging markets (EMs) in energy and commodity sectors suggest these areas could benefit from increased capital spending on renewable energy projects.`,
          source: [
            {
              highlighted:
                'Cash may offer a compelling yield today, but still-high inflation erodes much of that potential income.',
              page_number: 3,
            },
            {
              highlighted:
                'The end of the Fed hiking cycle tends to be a good time to get invested, and history suggests core bond outperformance versus cash from here.',
              page_number: 3,
            },
          ],
          createdAt: '2024-03-14T18:44:11.674Z',
          updatedAt: '2024-03-14T18:44:11.674Z',
          no_clients: 0,
          clients: [],
        },
      ],
    },
  ],
  total: 1,
};

export const MY_CLIENT_TOUR_MOCK = [
  {
    id: '63aa349a-f9bf-4cd9-a9a7-62d9b9246af0',
    apl: null,
    email: 'jackson348579@yopmail.com',
    clientName: 'Jackson',
    dropdownValue: 'View Viewpoints',
    marketValue: 341725,
    restrictions: [],
    ClientPortfolios: [
      {
        id: 'adcd4339-745b-4763-b776-6d763dab9608',
        name: "Jackson's Portfolio",
        marketValue: 341725,
      },
    ],
    value: 341725,
    accounts: [
      {
        type: 'Trading Account',
        account_name: "Jackson's Portfolio",
        portfolio_value: 341725,
      },
    ],
  },
  {
    id: '79e7ca03-5f87-4acc-868b-037e85abe0df',
    apl: null,
    email: 'oceanwealthmoney@yopmail.com',
    clientName: 'Michael',
    title: 'Affected by Rebuild Equity Portfolio',
    dropdownValue: 'Suggested Adjustments',
    marketValue: 200000,
    restrictions: [],
    ClientPortfolios: [
      {
        id: '69a63334-b3a4-4454-88f5-a3f21e8eeaca',
        name: "Michael's Portfolio",
        marketValue: 100000,
      },
      {
        id: '4ad53d91-8ccb-4701-91fb-c75f421a6c19',
        name: 'Michael Joint Account',
        marketValue: 100000,
      },
    ],
    value: 200000,
    accounts: [
      {
        type: 'Trading Account',
        account_name: "Michael's Portfolio",
        portfolio_value: 100000,
      },
      {
        type: 'Trading Account',
        account_name: 'Michael Joint Account',
        portfolio_value: 100000,
      },
    ],
  },
  {
    id: 'bbd560c1-c199-4e1e-9c2f-852961bc6027',
    apl: null,
    email: 'ruslync@yopmail.com',
    title: 'Affected by Step Out of Cash',
    clientName: 'Irina Stewart',
    dropdownValue: 'Suggested Adjustments',
    marketValue: 100000,
    restrictions: [],
    ClientPortfolios: [
      {
        id: 'd3be3b35-97ab-44ff-af06-aacf2a78d160',
        name: "Irina's Portfolio",
        marketValue: 100000,
      },
    ],
    value: 100000,
    accounts: [
      {
        type: 'Trading Account',
        account_name: "Irina's Portfolio",
        portfolio_value: 100000,
      },
    ],
  },
  {
    id: 'c5bc30c8-cfb6-4322-a924-a0aed8314f4a',
    apl: null,
    email: 'nbart00@yopmail.com',
    clientName: 'Molly Corr',
    dropdownValue: 'View Viewpoints',
    marketValue: 556250,
    restrictions: [],
    ClientPortfolios: [
      {
        id: '202fcafb-bfc0-4085-9416-234dfa651746',
        name: "Molly's Portfolio",
        marketValue: 556250,
      },
    ],
    value: 556250,
    accounts: [
      {
        type: 'Trading Account',
        account_name: "Molly's Portfolio",
        portfolio_value: 556250,
      },
    ],
  },
  {
    id: '99c4f45d-c253-4541-8a31-ee1f6d9606a2',
    apl: null,
    email: 'ss@yopmail.com',
    clientName: 'Breanna  Walden',
    title: 'Affected by Rely on Your Core',
    dropdownValue: 'Suggested Adjustments',
    marketValue: 100000,
    restrictions: [],
    ClientPortfolios: [
      {
        id: 'c0fbbce7-4f0f-4ebb-8b88-3f4340c4c915',
        name: "Steve's Portfolio",
        marketValue: 100000,
      },
    ],
    value: 100000,
    accounts: [
      {
        type: 'Trading Account',
        account_name: "Steve's Portfolio",
        portfolio_value: 100000,
      },
    ],
  },
  {
    id: '3736cf21-5ccb-42df-af2e-89c86e974009',
    apl: null,
    email: 'jstone@yopmail.com',
    clientName: 'Jane',
    dropdownValue: 'Suggested Adjustments',
    title: 'Affected by Rely on Your Core',
    marketValue: 266911,
    restrictions: [],
    ClientPortfolios: [
      {
        id: 'b586ff4e-26e3-424d-8663-377e063475b4',
        name: "Jane's Portfolio",
        marketValue: 266911,
      },
    ],
    value: 266911,
    accounts: [
      {
        type: 'Trading Account',
        account_name: "Jane's Portfolio",
        portfolio_value: 266911,
      },
    ],
  },
  {
    id: 'f33d4b7b-a8b2-4f9c-ad48-616172237a00',
    apl: null,
    email: 'rdolan704@yopmail.com',
    clientName: 'Ryan',
    dropdownValue: 'Suggested Adjustments',
    marketValue: 5000000,
    title: 'Affected by Rebuild Equity Portfolio',
    restrictions: [],
    ClientPortfolios: [
      {
        id: '1d60c3b9-64e0-4ffe-b72d-4b6a03c7d911',
        name: "Ryan's Portfolio",
        marketValue: 5000000,
      },
    ],
    value: 5000000,
    accounts: [
      {
        type: 'Trading Account',
        account_name: "Ryan's Portfolio",
        portfolio_value: 5000000,
      },
    ],
  },
  {
    id: 'e36d753c-9d36-4300-93a0-9a1e08fb42b3',
    apl: null,
    email: 'amiler@yopmail.com',
    clientName: 'Christopher  Sutherland',
    dropdownValue: 'Suggested Adjustments',
    title: 'Affected by Rely on Your Core',
    marketValue: 1500000,
    restrictions: ['[{"data_node": "china", "operator": "==", "value": 0}]'],
    ClientPortfolios: [
      {
        id: 'e52b1240-3b66-4f45-bf27-21976e9407cc',
        name: "Alex's Portfolio",
        marketValue: 1500000,
      },
    ],
    value: 1500000,
    accounts: [
      {
        type: 'Trading Account',
        account_name: "Alex's Portfolio",
        portfolio_value: 1500000,
      },
    ],
  },
  {
    id: 'bd160ca7-19f2-4ac5-a2ec-a22b4593aef5',
    apl: null,
    email: 'astone1@yopmail.com',
    title: 'Affected by Rely on Your Core',
    clientName: 'Anna',
    dropdownValue: 'Suggested Adjustments',
    marketValue: 2000000,
    restrictions: [],
    ClientPortfolios: [
      {
        id: 'd9ec7e2b-b7ce-45f0-8a2f-9c108ba003d5',
        name: "Anna1's Portfolio",
        marketValue: 2000000,
      },
    ],
    value: 2000000,
    accounts: [
      {
        type: 'Trading Account',
        account_name: "Anna1's Portfolio",
        portfolio_value: 2000000,
      },
    ],
  },
  {
    id: 'fe8efe83-c73a-4564-9f65-e33caffacc91',
    apl: null,
    email: 'andrew.west@yopmail.com',
    clientName: 'Andrew',
    dropdownValue: 'View Viewpoints',
    marketValue: null,
    restrictions: [],
    ClientPortfolios: [],
    value: 0,
    accounts: [],
  },
];
