import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import CharlesSchwabIcon from '@icons/CharlesSchwabLogo';
import FidelityIcon from '@icons/Fidelity';
import BNYIcon from '@icons/BNYLogo';
import RedtailIcon from '@icons/Redtail';
import WealthboxIcon from '@icons/Wealthbox';
import HubspotIcon from '@icons/Hubspot';
import SalesforceIcon from '@icons/SalesforceLogo';
import { Fade, Grid } from '@mui/material';
import TextInfo from './reusable/TextInfo';
import CustodianCard from './reusable/CustodianCard';
import CRMCard from './reusable/CRMCard';
import TourContent from './TourContent';
import { TourComponentProps } from './types';
import { WEALTH_URL } from '@common/constant';
import { shouldForwardProp } from 'utils/forwardProp';
interface HeaderProps {
  active?: boolean;
}

const HeaderContainer = styled.div`
  display: flex;
  border-bottom: 1px solid #e4e7ec;
`;

const Header = styled.div.withConfig({
  shouldForwardProp,
})<HeaderProps>`
  margin: 0 10px;
  cursor: pointer;
  color: ${({ active }) => (active ? '#101828' : '#475467')};
  border-bottom: ${({ active }) => (active ? '2px solid #101828' : 'none')};
  padding-bottom: 10px;
`;

const ContentContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  // flex-wrap: wrap;
  // max-height: 80vh;
  // overflow-y: auto;
  padding-right: 10px;
  height: 33%;
  transition: 1s;
  overflow-y: scroll;
`;

const spinAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left: 4px solid #000000;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: ${spinAnimation} 1s linear infinite;
`;

const CioCardContainer = styled.div.withConfig({
  shouldForwardProp,
})<{ isVisible: boolean }>`
  transition:
    display 1s,
    opacity 0.5s linear;
  display: ${({ isVisible }) => (isVisible ? 'inline' : 'none')};
  opacity: ${({ isVisible }) => (isVisible ? '1' : '0')};
`;
interface NestedDataI {
  [key: string]: {
    logo: React.ReactElement | string;
    name: string;
    description: string;
    link?: string;
  };
}

const cardData: NestedDataI = {
  Schwab: {
    logo: <CharlesSchwabIcon />,
    name: 'Charles Schwab',
    description:
      'Connect your Master Account Number to pull in your Clients and Accounts',
  },
  Fidelity: {
    logo: <FidelityIcon />,
    name: 'Fidelity',
    description:
      'Connect your Rep Code or G-Number to pull in your Clients and Accounts',
  },
  Pershing: {
    logo: <BNYIcon />,
    name: 'BNY Mellon Pershing',
    description: 'Connect your IP Number and pull in your Clients and Accounts',
  },
  Orion: {
    logo: <BNYIcon />,
    name: 'Orion',
    description:
      'Connect your Orion account to pull in your Clients and Accounts',
  },
};

const CRMCardData: NestedDataI = {
  salesforce: {
    logo: <SalesforceIcon />,
    name: 'salesforce',
    description:
      'Connect your Salesforce account to pull in your clients and prospects',
  },
  Hubspot: {
    logo: <HubspotIcon />,
    name: 'Hubspot',
    description:
      'Connect your Hubspot account to pull in your clients and prospects',
  },
  Wealthbox: {
    logo: <WealthboxIcon />,
    name: 'Wealthbox',
    description:
      'Connect your Wealthbox account to pull in your clients and prospects',
    link: WEALTH_URL,
  },
  Redtail: {
    logo: <RedtailIcon />,
    name: 'Redtail',
    description:
      'Connect your Redtail account to pull in your contacts and their portfolios',
  },
};

const FirstComponent = () => {
  const [showClientData, setShowClientData] = useState(false);
  const [selectedTab, setSelectedTab] = useState(1);
  useEffect(() => {
    setTimeout(() => {
      setShowClientData(true);
    }, 1500);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setSelectedTab(2);
    }, 3000);
    setTimeout(() => {
      setSelectedTab(1);
    }, 4500);
  }, []);
  console.log('selectedTab : ', selectedTab);
  return (
    <Grid sx={{ height: 'auto' }}>
      <TextInfo
        textContent={
          'Finally, some housekeeping. To get some of the answers in the sections we just discussed, you will have to connect Sage to your Custodian and CRM platforms. This is where you do that once.'
        }
      />
      <Fade timeout={1500} in={showClientData}>
        <div>
          <SecondComponent selectedTab={selectedTab} />
        </div>
      </Fade>
    </Grid>
  );
};

function SecondComponent(props: any): React.JSX.Element {
  const [selectedOption, setSelectedOption] = React.useState<number | null>(
    props.selectedTab,
  );
  const [rows] = useState<any>({ integrationsList: [] });
  const [loading] = useState(false); // Loader state
  const [integrationId] = useState('');
  const [reviewAndSignLoading] = useState(false);

  const toggleHeader = (divNumber: number) => {
    setSelectedOption(divNumber === selectedOption ? null : divNumber);
  };

  useEffect(() => {
    setSelectedOption(props.selectedTab);
  }, [props.selectedTab]);

  return (
    <div>
      <HeaderContainer>
        <Header active={selectedOption === 1} onClick={() => toggleHeader(1)}>
          Custodian
        </Header>
        <Header active={selectedOption === 2} onClick={() => toggleHeader(2)}>
          CRM
        </Header>
      </HeaderContainer>
      {loading && !reviewAndSignLoading && <Spinner />}

      <ContentContainer>
        {Object.keys(cardData).map((item, index) => {
          const custodianStatus = rows?.integrationsList?.find(
            e => e.name === item,
          );
          const custodianDetails = cardData[item];
          return (
            <CioCardContainer key={index} isVisible={selectedOption === 1}>
              <CustodianCard
                {...custodianDetails}
                custodianData={custodianStatus}
                isLoading={
                  reviewAndSignLoading && integrationId === custodianStatus?.id
                }
              />
            </CioCardContainer>
          );
        })}
        {Object.keys(CRMCardData).map((item, index) => {
          const crmStatus = rows?.integrationsList?.find(e => e.name === item);
          const crmDetails = CRMCardData[item];
          return (
            <CioCardContainer key={index} isVisible={selectedOption === 2}>
              <CRMCard {...crmDetails} crmData={crmStatus} />
            </CioCardContainer>
          );
        })}
      </ContentContainer>
    </div>
  );
}

function Integration({
  setAwaitingNavItem,
  setRouteState,
  endTour,
}: TourComponentProps): React.JSX.Element {
  // eslint-disable-next-line react/jsx-key
  const components = [
    // eslint-disable-next-line react/jsx-key
    { component: <FirstComponent />, timeToShow: 10000, endTour: true },
  ];

  return (
    <TourContent
      setAwaitingNavItem={setAwaitingNavItem}
      setRouteState={setRouteState}
      endTour={endTour}
      components={components}
      showFooterArrow={false}
      margin={160}
    />
  );
}

export default Integration;
