import React from 'react';
import { Box } from '@mui/material';

interface ProgressBarProps {
  width: number;
  timeToTransit: number;
}

const ProgressBar = (props: ProgressBarProps): React.JSX.Element => {
  return (
    <Box
      style={{
        height: '5px',
        width: `${props.width}%`,
        background: 'linear-gradient(180deg, #FFC38B 0%, #FF8C5A 100%)',
        transition: `all ${props.timeToTransit}s ease-in`,
      }}
    ></Box>
  );
};
export default ProgressBar;
