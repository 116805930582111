import React, {
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  CardContainer,
  ClientCardContainer,
  ClientsLoaderContainer,
  ClientsWrapper,
  NoSearchResultText,
  useStyles,
} from './styles';
import { Dialog, Drawer, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { actions as profileActions } from 'app/features/Profile/slice';

import ClientCard from './ClientCard';
import ClientOverview from './ClientOverview';
import { Spinner } from '@pages/IntegrationPage/styles';
import ClientsDrawerData from './ClientDrawerData';
import { getAuthDetails } from '@features/Profile/selector';
import { validArray } from '@common/validArray';
import { useGetClients } from '@features/apis/client/getClients';
import { useIntersectionObserver } from '@features/hooks/intersectObserver';
import useDebounce from '@features/hooks/useDebounce';
import CreateModelDialog from './CreateModelDialog';
import CustomAddClientBox from './CustomAddClientBox';
import { filterAndSortData } from './helpers';
import ClientFilter from './ClientFilter';
import { useGetViewPoints } from '@features/apis/client/getViewPoints';
import NoClients from './NoClients';

function ClientsV2(): JSX.Element {
  const dispatch = useDispatch();
  const clientSummary = useSelector(getAuthDetails);
  const loadMoreRef = useRef<HTMLDivElement | null>(null);
  const classes = useStyles();

  const [open, setOpen] = useState<boolean>(false);
  const [selectedClientId, setSelectedClientId] = useState<string>('');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedSortOption, setSelectedSortOption] = useState<string>('Name');
  const [selectedFilterRange, setSelectedFilterRange] = useState<
    number[] | null
  >(null);
  const [filterAnchorEl, setFilterAnchorEl] = useState<null | HTMLElement>(
    null,
  );
  const [selectedTitle, setSelectedTitle] = useState<string>('');
  const [portfolioDiff, setPortfolioDiff] = useState<number>(0);
  const [activeButton, setActiveButton] = useState<string>('All');
  const [search, setSearch] = useState<string>('');
  const [clientData, setClientData] = useState<any[]>([]);
  const [openModelDialog, setOpenModelDialog] = useState(false);
  const [addClientModal, setAddClientModal] = useState<boolean>(false);
  const [initialRender, setInitialRender] = useState(true);

  const searchValue = useDebounce(search, 800);

  const {
    data: clientPaginatedData,
    fetchNextPage: fetchNextClientPage,
    hasNextPage: hasClientNextPage,
    isFetchingNextPage: isFetchingNextClientPage,
    isLoading: isClientLoading,
    isRefetching: isClientRefetching,
    refetch: refetchClients,
  } = useGetClients({
    search: searchValue,
    prospect: activeButton === 'Prospects',
    viewpoint: activeButton === 'Viewpoint Opportunities',
    allClients: activeButton === 'All',
  });

  const { data: viewpointsData, isLoading: isViewpointsLoading } =
    useGetViewPoints();

  useIntersectionObserver(
    loadMoreRef,
    () => {
      if (hasClientNextPage && !isFetchingNextClientPage) {
        fetchNextClientPage();
      }
    },
    [hasClientNextPage, isFetchingNextClientPage],
    0.1,
  );

  useEffect(() => {
    if (!isClientLoading) {
      setInitialRender(false);
    }
    if (clientPaginatedData) {
      setClientData(clientPaginatedData.pages || []);
      setSelectedFilterRange(
        calculateDefaultRange(clientPaginatedData.pages || []),
      );
    }
  }, [
    clientPaginatedData,
    isClientLoading,
    isClientRefetching,
    isFetchingNextClientPage,
  ]);

  useEffect(() => {
    setClientData([]);
    refetchClients();
  }, [activeButton, searchValue, refetchClients]);

  const calculateDefaultRange = useCallback((data: any[]): number[] => {
    if (!data.length) return [0, 5000];
    const values = data.map(client => client.value || 0);
    return [Math.min(...values), Math.max(...values)];
  }, []);

  const handleClientsDrawerOpen = useCallback(
    (id = '', title = '', portfolioDiff = 0, clientName = '') => {
      setSelectedClientId(id);
      setSelectedTitle(title);
      setPortfolioDiff(portfolioDiff);
      setOpen(true);
      if (id && clientName) {
        dispatch(profileActions.addToRecentlyViewed({ id, name: clientName }));
      }
    },
    [dispatch],
  );

  const toggleSortOrder = () => {
    setSortOrder(prevSortOrder => (prevSortOrder === 'asc' ? 'desc' : 'asc'));
  };

  const handleSortIconClick = () => {
    toggleSortOrder();
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => setAnchorEl(null);

  const handleSortOptionClick = (option: string) => {
    setSelectedSortOption(option);
    handleClose();
  };

  const handleFilterChange = (
    event: Event | SyntheticEvent,
    newValue: number | number[],
  ) => {
    setSelectedFilterRange(newValue as number[]);
  };

  const handleFilterButtonClick = (event: React.MouseEvent<HTMLElement>) =>
    setFilterAnchorEl(event.currentTarget);

  const handleFilterClose = () => setFilterAnchorEl(null);

  const isFilterPopoverOpen = Boolean(filterAnchorEl);

  const handleButtonClick = (buttonName: string) => setActiveButton(buttonName);

  const openAddClientModal = () => setAddClientModal(true);

  const handleCloseModel = () => setOpenModelDialog(false);
  const handleClickOpenModel = () => setOpenModelDialog(true);

  if (isClientLoading) {
    return (
      <ClientsLoaderContainer>
        <Spinner />
      </ClientsLoaderContainer>
    );
  }

  if (
    !validArray(clientData) &&
    !search &&
    activeButton === 'All' &&
    !isClientRefetching &&
    !isClientLoading &&
    !initialRender
  ) {
    return (
      <>
        <CustomAddClientBox
          openAddClientModal={openAddClientModal}
          addClientModal={addClientModal}
          setAddClientModal={setAddClientModal}
          handleClickOpenModel={handleClickOpenModel}
        />
        <NoClients openAddClientModal={openAddClientModal} />
      </>
    );
  }

  return (
    <>
      <ClientCardContainer
        sx={{ height: '88vh', overflowY: 'auto', scrollBehavior: 'smooth' }}
      >
        <CustomAddClientBox
          openAddClientModal={openAddClientModal}
          addClientModal={addClientModal}
          setAddClientModal={setAddClientModal}
          handleClickOpenModel={handleClickOpenModel}
        />
        <ClientsWrapper container rowGap={2}>
          <ClientOverview
            handleDrawerOpen={handleClientsDrawerOpen}
            clientSummary={clientSummary}
          />
          <ClientFilter
            clientData={clientData}
            activeButton={activeButton}
            handleButtonClick={handleButtonClick}
            search={search}
            setSearch={setSearch}
            handleSortIconClick={handleSortIconClick}
            selectedSortOption={selectedSortOption}
            handleClick={handleClick}
            anchorEl={anchorEl}
            handleClose={handleClose}
            handleSortOptionClick={handleSortOptionClick}
            handleFilterButtonClick={handleFilterButtonClick}
            isFilterPopoverOpen={isFilterPopoverOpen}
            filterAnchorEl={filterAnchorEl}
            handleFilterClose={handleFilterClose}
            handleFilterChange={handleFilterChange}
            selectedFilterRange={selectedFilterRange}
            calculateDefaultRange={calculateDefaultRange}
          />
          <Grid
            item
            container
            padding={{ xs: '10px', sm: '20px' }}
            sx={{ background: '#f7f7f7', borderRadius: '8px' }}
          >
            <CardContainer
              item
              container
              columnSpacing={2}
              rowGap={2}
              wrap="wrap"
            >
              {validArray(clientData) ? (
                filterAndSortData(
                  clientData,
                  selectedFilterRange,
                  sortOrder,
                  selectedSortOption,
                )?.map((client: any, index: number) => {
                  const roundedPortfolioDiff = parseFloat(
                    client?.portfolioDiff?.toFixed(2),
                  );
                  return (
                    <Grid
                      key={index}
                      item
                      xs={12}
                      sm={6}
                      lg={4}
                      ref={index === clientData.length - 1 ? loadMoreRef : null}
                    >
                      <ClientCard
                        clientName={client?.clientName}
                        priceValue={client?.value}
                        handleDrawerOpen={handleClientsDrawerOpen}
                        clientId={client?.id}
                        title={client?.viewpoint}
                        portfolioDiff={roundedPortfolioDiff || 0}
                        portfolioRisk={client?.currentPortfolioRisk}
                        riskPreference={client?.riskPreference}
                        riskCapacity={client?.riskCapacity}
                        viewpointsData={viewpointsData}
                        isLoading={isViewpointsLoading}
                      />
                    </Grid>
                  );
                })
              ) : search && !isClientRefetching ? (
                <NoSearchResultText item container xs={12}>
                  {` There is no search result for this ${search}, please try with a different name.`}
                </NoSearchResultText>
              ) : (
                isClientLoading &&
                !isClientRefetching && (
                  <NoSearchResultText item container xs={12}>
                    {`No ${activeButton} found.`}
                  </NoSearchResultText>
                )
              )}
            </CardContainer>
            {(isFetchingNextClientPage || isClientRefetching) && (
              <ClientsLoaderContainer>
                <Spinner />
              </ClientsLoaderContainer>
            )}
          </Grid>
        </ClientsWrapper>
      </ClientCardContainer>
      <div className={classes.root}>
        <Drawer
          anchor="right"
          variant="temporary"
          open={open}
          className={classes.drawer}
          classes={{ paper: classes.drawerPaper }}
          onClose={() => setOpen(false)}
        >
          <ClientsDrawerData
            onClose={() => setOpen(false)}
            clientId={selectedClientId}
            viewpoints={selectedTitle}
            portfolioDiff={portfolioDiff}
            clientsData={clientData}
          />
        </Drawer>
      </div>

      {openModelDialog && (
        <Dialog
          open={openModelDialog}
          onClose={handleCloseModel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            sx: {
              maxWidth: {
                xs: '500px',
                md: '800px',
              },
            },
          }}
        >
          <CreateModelDialog handleCloseModel={handleCloseModel} />
        </Dialog>
      )}
    </>
  );
}

export default ClientsV2;
