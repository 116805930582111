import React from 'react';
import PeopleGroupIcon from '@images/black-people.svg';
import DollarIcon from '@images/black-dollar.svg';
import { Divider } from '@mui/material';
import EyeIcon from '@images/black-eye.svg';
import { RECENTLY_VIEWED_USER } from '@common/constant';
import {
  ClientOverviewContainer,
  ClientsHeaderDataWrapper,
  ClientsHeaderWrapper,
  FirstBlockData,
  FirstBlockText,
  FirstBlockWrapper,
  RecentlyViewedDataWrapper,
  RecentlyViewedIconContainer,
  RecentlyViewedSectionContainer,
  RecentlyViewedSectionWrapper,
  RecentlyViewedTextContainer,
  RecentlyViewedUserContainer,
  SecondBlockIconContainer,
  SecondBlockWrapper,
  StyledIconContainer,
} from '../styles';

function ClientOverview(): React.JSX.Element {
  return (
    <ClientOverviewContainer>
      <ClientsHeaderWrapper>
        <ClientsHeaderDataWrapper>
          <FirstBlockWrapper>
            <StyledIconContainer>
              <img src={PeopleGroupIcon} alt="People in group image" />
            </StyledIconContainer>
            <FirstBlockText>Total Clients</FirstBlockText>
          </FirstBlockWrapper>
          <FirstBlockData>234</FirstBlockData>
        </ClientsHeaderDataWrapper>
        <SecondBlockWrapper>
          <FirstBlockWrapper>
            <SecondBlockIconContainer>
              <img src={DollarIcon} alt="Dollar image" />
            </SecondBlockIconContainer>
            <FirstBlockText>Total AUM</FirstBlockText>
          </FirstBlockWrapper>
          <FirstBlockData>
            <span
              style={{ fontSize: '13px', marginTop: '14px', color: '#818181' }}
            >
              $
            </span>
            1,000,000
          </FirstBlockData>
        </SecondBlockWrapper>
      </ClientsHeaderWrapper>
      <Divider
        orientation="vertical"
        flexItem
        style={{ marginLeft: '20px', marginRight: '20px' }}
      />
      <RecentlyViewedSectionWrapper>
        <RecentlyViewedSectionContainer>
          <RecentlyViewedIconContainer>
            <img src={EyeIcon} alt="Eye image" />
          </RecentlyViewedIconContainer>
          <RecentlyViewedTextContainer>
            Recently Viewed
          </RecentlyViewedTextContainer>
        </RecentlyViewedSectionContainer>
        <RecentlyViewedDataWrapper>
          {RECENTLY_VIEWED_USER.map((item, index) => (
            <RecentlyViewedUserContainer key={index}>
              <div style={{ fontSize: '14px', color: '#818181' }}>
                {item.name}
              </div>
            </RecentlyViewedUserContainer>
          ))}
        </RecentlyViewedDataWrapper>
      </RecentlyViewedSectionWrapper>
    </ClientOverviewContainer>
  );
}

export default ClientOverview;
