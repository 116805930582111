import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Container, Grid } from '@mui/material';
import { actions as chatActions } from '@features/Chat/slice';
import { getChatInitResponseState } from '@features/Chat/selector';
import { GreyLabelText } from '@styles/Typography';
import StartPageOverlay from '@components/StartPageOverlay';
import { useNavigate } from 'react-router-dom';
import { getAuthDetails } from '@features/Profile/selector';
// import MemoAnimator from '@components/UIComponents/MemoAnimator';
import Homepage from './NewHomePage';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import colorLine from '@images/color-line.svg';

// const SUBSEQUENT_DUMMY_CARD_DATA = [
//   {
//     text: 'Show me the onboarding tour again',
//     href: '/tour',
//   },
//   {
//     text: 'What are the latest market views?',
//     href: '/actions',
//   },
//   {
//     text: 'Help me connect my client data',
//     href: '/integrations',
//   },
// ];

// const SubSequentLoginCards = ({
//   onClick,
//   cDataText,
// }: {
//   onClick: any;
//   cDataText: string;
// }) => {
//   return <MemoAnimator onClick={onClick} text={cDataText} />;
// };

function SubsequentLogin(): React.JSX.Element {
  const dispatch = useDispatch();

  const { chatHistory: initHistoryState } = useSelector(
    getChatInitResponseState,
  );
  const advisorDetails = useSelector(getAuthDetails);
  const navigate = useNavigate();

  useEffect(() => {
    if (
      localStorage.getItem('x-tifin-ai-token') &&
      initHistoryState?.length === 0
    ) {
      dispatch(chatActions.loadChatRequest());
    }
  }, []);
  const goToConversationPage = () => {
    navigate('/conversations/stream', { state: { scroll: 0 } });
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent={'flex-end'}
      alignItems="flex-start"
      minHeight="77vh"
      maxHeight="77vh"
      zIndex={2}
    >
      <Container
        maxWidth="xl"
        sx={{
          overflowY: 'auto',
          maxHeight: '100%',
        }}
      >
        <Box mt={1}>
          <GreyLabelText
            fontSize="24px"
            color="#818181"
            fontWeight={400}
            mt={1}
          >
            Welcome {advisorDetails?.firstName ? advisorDetails?.firstName : ''}
          </GreyLabelText>
          <GreyLabelText
            fontSize="64px"
            color="#333"
            mt={2}
            fontWeight={600}
            style={{ letterSpacing: '-2.56px' }}
          >
            How can I help you today?
          </GreyLabelText>
        </Box>
        <Grid item xs={12} mt={2} mb={5}>
          <img src={colorLine} alt="" style={{ width: '98.8%' }} />
        </Grid>
        <Homepage />
        {/* <Grid item xs={12} mb={4}>
          {SUBSEQUENT_DUMMY_CARD_DATA.map((cData, index) => {
            return (
              <SubSequentLoginCards
                key={index}
                onClick={() => navigate(cData.href)}
                cDataText={cData.text}
              />
            );
          })}
           </Grid> */}
        <Grid item xs={12} mb={1.5}>
          <Button
            variant="outlined"
            color="primary"
            onClick={goToConversationPage}
            style={{
              marginTop: '2rem',
              borderRadius: '16px',
              backgroundColor: '#fff',
              border: '1px solid #333',
              textTransform: 'none',
              marginRight: '15px',
              color: '#000',
              marginBottom: '20px',
            }}
          >
            Continue last converstion&nbsp;
            <ChevronRightIcon />
          </Button>
        </Grid>
      </Container>
      <StartPageOverlay />
    </Box>
  );
}

export default SubsequentLogin;
