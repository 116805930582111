import React from 'react';
import {
  ButtonContainer,
  CustomButton,
  CustomTextField,
  Filters,
  OutlineBox,
  RedBadge,
  SortByStyle,
} from './styles';
import {
  Grid,
  Menu,
  MenuItem,
  Popover,
  Select,
  Slider,
  Typography,
  useMediaQuery,
} from '@mui/material';
import theme from '@styles/theme';
import { clientFilters } from '@common/constant';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import clientSearch from '@images/client-search.svg';
import sort from '@images/ArrowsDownUp.svg';
import filter from '@images/FunnelSimple.svg';
import { price } from '@common/number';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const ClientFilter = ({
  clientData,
  activeButton,
  handleButtonClick,
  search,
  setSearch,
  handleSortIconClick,
  selectedSortOption,
  handleClick,
  anchorEl,
  handleClose,
  handleSortOptionClick,
  handleFilterButtonClick,
  isFilterPopoverOpen,
  filterAnchorEl,
  handleFilterClose,
  handleFilterChange,
  selectedFilterRange,
  calculateDefaultRange,
}: any): JSX.Element => {
  const isDesktopDevice = useMediaQuery(theme.breakpoints.up('md'));

  const defaultRange = clientData
    ? calculateDefaultRange(clientData)
    : [0, 5000];

  const renderButtonContent = () =>
    clientFilters.map(({ name, disabled }) => (
      <CustomButton
        key={name}
        variant="outlined"
        active={activeButton === name}
        onClick={() => !disabled && handleButtonClick(name)}
        sx={{ cursor: disabled ? 'default' : 'pointer' }}
      >
        {name}
        {name === 'Viewpoint Opportunities' && (
          <RedBadge
            badgeContent={
              clientData?.filter(client => client?.viewpoint.length > 0)?.length
            }
          />
        )}
      </CustomButton>
    ));

  const renderSelectMenu = () => (
    <Select
      value={activeButton}
      onChange={event => handleButtonClick(event.target.value)}
      IconComponent={KeyboardArrowDownIcon}
      inputProps={{ 'aria-label': 'Without label' }}
      sx={{
        height: 35,
        width: '100%',
        fontSize: 14,
        color: '#101828',
        '& .MuiSelect-icon': {
          minHeight: 27,
          color: '#000',
          marginTop: '-2px',
        },
      }}
    >
      {clientFilters.map(({ name, disabled }) => (
        <MenuItem
          key={name}
          value={name}
          sx={{ fontSize: 14, color: '#101828' }}
          disabled={disabled}
        >
          {name}
        </MenuItem>
      ))}
    </Select>
  );

  const renderSortMenu = () => (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      sx={{
        marginTop: 3,
        '& .MuiPaper-root': {
          width: '16%',
          marginLeft: 22,
          background: '#fff',
        },
      }}
    >
      {['Name', 'Value'].map(option => (
        <MenuItem
          key={option}
          sx={{
            fontSize: 12,
            color: '#101828',
            '&:hover': {
              backgroundColor: '#fff',
            },
          }}
          onClick={() => handleSortOptionClick(option)}
        >
          Sort by {option}
        </MenuItem>
      ))}
    </Menu>
  );

  return (
    <Filters item container xs={12} alignItems={'center'}>
      <ButtonContainer item container xs={12} md={12} lg={7} xl={8.2}>
        {isDesktopDevice ? renderButtonContent() : renderSelectMenu()}
      </ButtonContainer>

      <Grid item xs={12} md={4} lg={2.2} xl={2}>
        <CustomTextField
          placeholder="Search for any client"
          value={search}
          onChange={e => setSearch(e.target.value)}
          fullWidth
          variant="standard"
          size="medium"
          InputProps={{
            startAdornment: (
              <img
                alt="Search image"
                src={clientSearch}
                style={{ marginRight: 8, width: 20, height: 20 }}
              />
            ),
            disableUnderline: true,
            style: {
              padding: 8,
              height: 32,
              border: '1px solid #ededee',
              borderRadius: 8,
            },
          }}
        />
      </Grid>

      {isDesktopDevice && (
        <SortByStyle item container xs={5.8} md={4} lg={2.6} xl={2}>
          <OutlineBox
            item
            container
            alignItems="flex-start"
            xs={5}
            lg={5.4}
            height={32}
            justifyContent="center"
            columnGap={0.5}
          >
            <Grid
              item
              container
              xs={3}
              sx={{ cursor: 'pointer' }}
              onClick={handleSortIconClick}
              role="button"
              tabIndex={0}
              aria-label="Sort"
            >
              <img src={sort} alt="Sort image" />
            </Grid>
            <Grid
              item
              container
              xs={5}
              alignItems="center"
              fontSize={12}
              color="#535256"
              fontWeight={500}
              lineHeight="16px"
            >
              {selectedSortOption}
            </Grid>
            <Grid
              item
              container
              xs={2}
              alignItems="center"
              sx={{ cursor: 'pointer' }}
              onClick={handleClick}
              role="button"
              tabIndex={0}
              aria-label="Sort menu open"
            >
              <KeyboardArrowDownIcon
                sx={{ height: 14, color: '#535256', fontSize: 20 }}
              />
            </Grid>
          </OutlineBox>
          <Grid
            item
            container
            xs={4}
            lg={5}
            justifyContent="center"
            alignItems="center"
            sx={{
              height: 32,
              cursor: 'pointer',
              border: '1px solid #ededee',
              borderRadius: 8,
              padding: '8px var(--Spacing-Small, 8px)',
              maxWidth: '100px !important',
            }}
            onClick={handleFilterButtonClick}
            role="button"
            tabIndex={0}
            aria-label="filter"
          >
            <Grid item container xs={4}>
              <img src={filter} alt="Filter image" />
            </Grid>
            <Grid
              item
              container
              xs={8}
              justifyContent="center"
              alignItems="center"
            >
              <Typography
                sx={{
                  color: '#535256',
                  fontSize: 12,
                  fontWeight: 500,
                  lineHeight: '16px',
                }}
              >
                Filter
              </Typography>
            </Grid>
          </Grid>
          <Popover
            open={isFilterPopoverOpen}
            anchorEl={filterAnchorEl}
            onClose={handleFilterClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            PaperProps={{
              style: {
                width: 300,
                padding: 16,
                height: 40,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              },
            }}
          >
            <Grid item xs={12}>
              <Typography
                id="filter-slider"
                gutterBottom
                sx={{ fontSize: 14, color: '#818181', paddingLeft: 16 }}
              >
                Filter by Portfolio Value
              </Typography>
              <Slider
                value={selectedFilterRange || defaultRange}
                onChange={handleFilterChange}
                valueLabelDisplay="auto"
                valueLabelFormat={value => price(value, true, false)}
                min={defaultRange[0]}
                max={defaultRange[1]}
                aria-labelledby="filter-slider"
                sx={{
                  width: 'calc(100% - 50px)',
                  left: 20,
                  '& .MuiSlider-rail': { height: 2 },
                  '& .MuiSlider-track': { height: 2 },
                  '& .MuiSlider-thumb': { width: 12, height: 12 },
                }}
              />
            </Grid>
          </Popover>
          {renderSortMenu()}
        </SortByStyle>
      )}
    </Filters>
  );
};

export default ClientFilter;
