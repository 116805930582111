import * as React from 'react';

function BlackTrashIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 25" fill="none" {...props}>
      <path
        d="M20.25 5.75H3.75"
        stroke="#101828"
        strokeWidth={2.667}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.75 10.25v6M14.25 10.25v6M18.75 5.75V20a.75.75 0 01-.75.75H6a.75.75 0 01-.75-.75V5.75M15.75 5.75v-1.5a1.5 1.5 0 00-1.5-1.5h-4.5a1.5 1.5 0 00-1.5 1.5v1.5"
        stroke="#101828"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const MemoBlackTrashIcon = React.memo(BlackTrashIcon);
export default MemoBlackTrashIcon;
