import { Box, CardContent } from '@mui/material';
import { GreyLabelText } from '@styles/Typography';
import React from 'react';
import { IPortfolio } from './types';
import { CIOCard, CIOChip } from './styles';
import { BoltIconDiv } from '@pages/CIOViewpoints/styles';
import BoltIcon from '@icons/bolt';

interface ICardProps {
  card: IPortfolio;
  displayTag: boolean;
}

function CIOCardDetails({ card, displayTag }: ICardProps): React.JSX.Element {
  return (
    <CIOCard sx={{ boxShadow: 'none' }}>
      <CardContent sx={{ paddingBottom: '9px !important', padding: '10px' }}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <BoltIconDiv>
            <BoltIcon
              fill={displayTag ? null : '#00B186'}
              style={{ marginRight: '-2px' }}
            />
          </BoltIconDiv>
          <Box flex="1">
            <GreyLabelText fontSize="14px" color="#000">
              {card?.name}
            </GreyLabelText>
          </Box>

          {displayTag && (
            <Box display="flex" alignItems="center">
              <CIOChip label="Core Equity" color="primary" />
            </Box>
          )}
        </Box>
      </CardContent>
    </CIOCard>
  );
}

export default CIOCardDetails;
