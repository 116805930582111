/* eslint-disable react/prop-types */
import * as React from 'react';

function SendIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
    >
      <path
        fill={props.fill || '#969696'}
        d="M16 3a13 13 0 1013 13A13.013 13.013 0 0016 3zm0 24a11 11 0 1111-11 11.012 11.012 0 01-11 11zm4.707-12.707a1 1 0 01-1.415 1.415L17 13.413V21a1 1 0 01-2 0v-7.586l-2.293 2.293a1 1 0 11-1.415-1.415l4-4a1.001 1.001 0 011.415 0l4 4z"
      ></path>
    </svg>
  );
}

const MemoSendIcon = React.memo(SendIcon);
export default MemoSendIcon;
