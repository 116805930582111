import React from 'react';
import SendIcon from 'assets/icons/SendIcon';
import {
  InputField,
  QuestionMarkCircle,
  SearchBoxContainer,
  SuffixIcon,
} from './styles';

type SearchBoxTypes = {
  userQuery: string;
  isStreaming: boolean;
  setUserQuery: (query: string) => void;
  onInputSubmit: () => void;
  handleQuestionMarkClick: () => void;
};

function SearchBox(props: SearchBoxTypes): React.JSX.Element {
  const {
    userQuery,
    isStreaming,
    setUserQuery,
    onInputSubmit,
    handleQuestionMarkClick,
  } = props;

  return (
    <SearchBoxContainer container>
      <QuestionMarkCircle onClick={handleQuestionMarkClick}>
        <span className="question-mark">?</span>
        <span className="question-text">What can I ask Sage?</span>
      </QuestionMarkCircle>

      <InputField
        disabled={isStreaming}
        placeholder={'How can I help you today?'}
        onChange={(e: any) => setUserQuery(e?.target?.value)}
        value={userQuery}
        onKeyDown={(e: any) => {
          if (e.key === 'Enter' && userQuery.trim() !== '') {
            e.preventDefault();
            onInputSubmit();
          }
        }}
      />

      <SuffixIcon
        role="button"
        aria-label="chat enter button"
        onClick={() => {
          if (userQuery.trim() !== '') {
            onInputSubmit();
          }
        }}
      >
        <SendIcon fill="#02C3DE" />
      </SuffixIcon>
    </SearchBoxContainer>
  );
}

export default SearchBox;
