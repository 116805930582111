import React from 'react';
import { DeleteWrapper } from './styles';
import { CircularProgress, Grid } from '@mui/material';
import { PrimaryButton } from '@components/Button';

interface ConfirmationProps {
  icon: any;
  title: string;
  subTitle: string;
  handleClose: any;
  handleSubmit: any;
  headerText: string;
  isLoading?: boolean;
}

function ConfirmationModal({
  icon,
  title,
  subTitle,
  handleClose,
  handleSubmit,
  headerText,
  isLoading,
}: ConfirmationProps): React.JSX.Element {
  return (
    <DeleteWrapper container direction={'column'} rowGap={2}>
      <Grid item container columnGap={1} alignItems={'center'}>
        <Grid item>{icon}</Grid>
        <Grid item>
          <p className="card-title">{headerText}</p>
        </Grid>
      </Grid>
      <Grid item>
        <p className="title">{title}</p>
      </Grid>
      <Grid item>
        <p className="sub-title">{subTitle}</p>
      </Grid>
      <Grid item container columnSpacing={2}>
        <Grid item xs={6}>
          <PrimaryButton
            variant="outlined"
            className="button"
            onClick={handleClose}
          >
            Cancel
          </PrimaryButton>
        </Grid>
        <Grid item xs={6}>
          <PrimaryButton
            className="button"
            onClick={handleSubmit}
            disabled={isLoading}
          >
            {isLoading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              'Submit'
            )}
          </PrimaryButton>
        </Grid>
      </Grid>
    </DeleteWrapper>
  );
}

export default ConfirmationModal;
