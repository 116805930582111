import { Grid } from '@mui/material';
import styled from 'styled-components';

export const CurrentEventWrapper = styled(Grid)`
  background: #fff;
  .custom-input {
    border-radius: 4px;
    border: 1px solid var(--Neutral-200, #e4e7ec);
    background: var(--Neutral-0, #fff);
    padding: 10px;

    box-shadow: 1px 2px 2px 0px rgba(16, 24, 40, 0.12) inset;
  }

  .button {
    width: 100%;
    border-radius: 20px;
  }

  .icon {
    cursor: pointer;
  }

  .input-wrapper {
    position: relative;
    width: 100%;
  }
`;
