/* eslint-disable react/prop-types */
import * as React from 'react';

function TableViewIcon({ fill }: { fill?: string }): React.JSX.Element {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.875 9.75H9.125C8.79348 9.75 8.47554 9.8817 8.24112 10.1161C8.0067 10.3505 7.875 10.6685 7.875 11V21C7.875 21.3315 8.0067 21.6495 8.24112 21.8839C8.47554 22.1183 8.79348 22.25 9.125 22.25H22.875C23.2065 22.25 23.5245 22.1183 23.7589 21.8839C23.9933 21.6495 24.125 21.3315 24.125 21V11C24.125 10.6685 23.9933 10.3505 23.7589 10.1161C23.5245 9.8817 23.2065 9.75 22.875 9.75ZM14.125 17.25V14.75H17.875V17.25H14.125ZM17.875 18.5V21H14.125V18.5H17.875ZM9.125 14.75H12.875V17.25H9.125V14.75ZM14.125 13.5V11H17.875V13.5H14.125ZM19.125 14.75H22.875V17.25H19.125V14.75ZM22.875 13.5H19.125V11H22.875V13.5ZM12.875 11V13.5H9.125V11H12.875ZM9.125 18.5H12.875V21H9.125V18.5ZM22.875 21H19.125V18.5H22.875V21Z"
        fill={fill ?? '#96969B'}
      />
    </svg>
  );
}

const MemoTableViewIcon = React.memo(TableViewIcon);
export default MemoTableViewIcon;
