import { Box, Badge } from '@mui/material';
import React from 'react';

interface RiskBarProps {
  riskPreference?: number;
  riskCapacity?: number;
  preferenceLabel?: string | React.ReactNode;
  capacityLabel?: string | React.ReactNode;
  showZeroLabel?: boolean;
  showHundredLabel?: boolean;
  hoverLine?: boolean;
}

const RiskBar: React.FC<RiskBarProps> = ({
  riskPreference,
  riskCapacity,
  preferenceLabel,
  capacityLabel,
  showZeroLabel = true,
  showHundredLabel = true,
  hoverLine = false,
}: RiskBarProps) => {
  if (riskPreference === undefined || riskCapacity === undefined) {
    return (
      <Box
        sx={{
          width: '90%',
          height: '5px',
          backgroundColor: '#ECECEC',
          borderRadius: '4px',
          margin: 'auto',
          marginTop: '20px',
        }}
      />
    );
  }

  const preferencePosition = `${riskPreference}%`;
  const capacityPosition = `${riskCapacity}%`;
  const divWidth = `${Math.abs(riskCapacity - riskPreference)}%`;

  return (
    <Box
      sx={{
        width: '90%',
        height: '5px',
        backgroundColor: '#ECECEC',
        position: 'relative',
        borderRadius: '4px',
        margin: 'auto',
        marginTop: '20px',
        '&:hover .hover-effect': hoverLine ? { opacity: 1 } : {},
      }}
    >
      {showZeroLabel && (
        <Box
          sx={{
            position: 'absolute',
            left: '-3%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            fontSize: '12px',
            color: '#535256',
          }}
        >
          0
        </Box>
      )}

      <Box
        sx={{
          height: '100%',
          backgroundColor: '#ECECEC',
          position: 'absolute',
          borderRadius: '4px',
          width: '100%',
          zIndex: -1,
        }}
      />

      <Box
        sx={{
          height: '80%',
          backgroundColor: '#353536',
          position: 'absolute',
          borderRadius: '4px',
          left:
            riskPreference < riskCapacity
              ? preferencePosition
              : capacityPosition,
          width: divWidth,
        }}
      />

      <Box
        sx={{
          width: '14px',
          height: '14px',
          backgroundColor: '#353536',
          borderRadius: '50%',
          position: 'absolute',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          left: `calc(${preferencePosition} - 0%)`,
          zIndex: 1,
        }}
      />

      <Box
        sx={{
          width: '14px',
          height: '14px',
          backgroundColor: '#353536',
          borderRadius: '50%',
          position: 'absolute',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          left: `calc(${capacityPosition} + 0%)`,
          zIndex: 1,
        }}
      />

      {/* Hover effect group */}
      <Box
        className="hover-effect"
        sx={{
          opacity: hoverLine ? 0 : 1,
          transition: 'opacity 0.3s ease',
        }}
      >
        <Badge
          color="secondary"
          badgeContent={riskPreference}
          sx={{
            '.MuiBadge-badge': {
              borderRadius: '50%',
              width: '24px',
              height: '24px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              background: '#353536',
              color: '#fff',
            },
            position: 'absolute',
            bottom: hoverLine ? -27 : -25,
            left: `calc(${preferencePosition} + 0%)`,
            zIndex: 1,
          }}
        />
        {!hoverLine && (
          <Box
            sx={{
              position: 'absolute',
              bottom: -33,
              left: `calc(${preferencePosition} + 18px)`,
              zIndex: 1,
              fontSize: '12px',
              color: '#535256',
            }}
          >
            {preferenceLabel}
          </Box>
        )}

        <Badge
          color="secondary"
          badgeContent={riskCapacity ?? 0}
          sx={{
            '.MuiBadge-badge': {
              borderRadius: '50%',
              width: '24px',
              height: '24px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              background: '#353536',
              color: '#fff',
            },
            position: 'absolute',
            top: hoverLine ? -30 : -25,
            left: `calc(${capacityPosition} - 0%)`,
            zIndex: 1,
          }}
        />
        {!hoverLine && (
          <Box
            sx={{
              position: 'absolute',
              top: -33,
              left: `calc(${capacityPosition} + 16px)`,
              zIndex: 1,
              fontSize: '12px',
              color: '#535256',
            }}
          >
            {capacityLabel}
          </Box>
        )}

        {!hoverLine && (
          <>
            <Box
              sx={{
                position: 'absolute',
                borderLeft: '1px solid black',
                height: 'calc(90% + 25px)',
                bottom: '-20px',
                left: `calc(${preferencePosition} + 0%)`,
              }}
            />

            <Box
              sx={{
                position: 'absolute',
                borderLeft: '1px solid black',
                height: 'calc(92% + 25px)',
                top: '-20px',
                left: `calc(${capacityPosition} - 0%)`,
              }}
            />
          </>
        )}
      </Box>

      {showHundredLabel && (
        <Box
          sx={{
            position: 'absolute',
            right: '-3%',
            top: '50%',
            transform: 'translate(50%, -50%)',
            fontSize: '12px',
            color: '#535256',
          }}
        >
          100
        </Box>
      )}
    </Box>
  );
};

export default RiskBar;
