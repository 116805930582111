import { Grid, IconButton } from '@mui/material';
import styled from 'styled-components';
import { shouldForwardProp } from 'utils/forwardProp';

interface InputStyleProps {
  padding?: string;
  fontSize?: string;
  fontWeight?: string;
  lineHeight?: string;
  expand?: boolean;
}

export const SearchBoxContainer = styled(Grid)`
  position: relative;
  width: 100%;
  height: 36px;
  transition: 0.2s ease-in;
`;

export const QuestionMarkCircle = styled.div<{
  $expand?: boolean;
}>`
  width: ${props => `${props.$expand ? 150 : 30}`}px;
  height: 30px;
  background-color: #101828;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  color: white;
  font-size: ${props => `${props.$expand ? 12 : 16}`}px;
  font-weight: bold;
  position: absolute !important;
  top: 5px;
  left: 5px;
  flex-shrink: 0;
  overflow: hidden;
  cursor: pointer;
  text-wrap: nowrap;
  transition: all 0.5s;
  & > div {
    opacity: ${props => `${props.$expand ? 1 : 0}`};
  }
  &:hover {
    width: 150px;
    font-size: 12px;
    border-radius: 15px;

    & > div {
      opacity: 1;
    }
  }
`;

export const RevealText = styled.div`
  opacity: 0;
  transition: opacity 0.5s;
`;

export const InputField = styled.input.withConfig({
  shouldForwardProp,
})<InputStyleProps>`
  width: 100%;
  height: ${props => props.height ?? '100%'};
  padding: ${props => props.padding ?? '0 40px 0 40px'};
  font-size: ${props => props.fontSize ?? '14px'};
  font-weight: ${props => props.fontWeight ?? '400'};
  line-height: ${props => props.lineHeight ?? 'normal'};
  color: ${props => props.theme.textBlack};
  outline: 0px;
  border-radius: 32px;
  border: 2px solid ${props => props.theme.orangeBorder};
  // background: ${props => props.theme.inputBackgroundColor};
  transition:
    border cubic-bezier(0.48, 0.65, 0.6, 0.79) 0.5s,
    background cubic-bezier(0.48, 0.65, 0.6, 0.79) 0.5s;

  &::placeholder {
    transform: ${props => `translateX(${props.expand ? 130 : 0}px)`};
    transition: transform 0.5s ease-in-out;
  }

  &:focus,
  &:focus-visible,
  &:focus-within {
    border: 2px solid ${props => props.theme.main} !important;
    background: rgba(255, 255, 255, 0.6);
    box-shadow: 0px 0px 16px 0px rgba(110, 91, 229, 0.1);
    &:placeholder {
      opacity: 0.5;
      transform: translateX(120px);
    }
  }

  &:hover {
    border: 2px solid ${props => props.theme.lightBlack};
    // background: rgba(255, 255, 255, 0.2);
    box-shadow: 0px 0px 16px 0px rgba(110, 91, 229, 0.1);
    &:placeholder {
      transform: translateX(120px); // Adjust the translation value as needed
    }
  }

  &:focus ~ label,
  &:hover ~ label {
    transition: 0.2s ease-in;
    font-size: ${props => props.fontSize ?? '10px'};
    font-weight: ${props => props.fontWeight ?? '600'};
    line-height: ${props => props.lineHeight ?? '12px'};
    color: ${props => props.theme.grey5};
  }
  ${QuestionMarkCircle}:hover + & {
    &::placeholder {
      transform: translateX(120px); // Adjust the translation value as needed
      transition: transform 0.1s ease-in-out;
    }
  }
`;

export const SuffixIcon = styled(IconButton)`
  position: absolute !important;
  top: 2px;
  right: 7px;
  width: 36px;
  height: 36px;
  flex-shrink: 0;
  transition: 0.2s ease-in;
`;
