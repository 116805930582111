import { handleError } from '@common/error';
import SuggestedFollowUps from '@components/ChatContent/SuggestedFollowUps';
import { CardLabel } from '@components/SuggestionCard/styles';
import CustomTooltip from '@components/Tooltip';
import ReadMoreReadLess from '@components/UIComponents/ReadMore';
import useElementWidth from '@hooks/useElementWidth';
import MemoArchived from '@icons/Archived';
import MemoMagic from '@icons/Magic';
import PriorityIcon from '@icons/PriorityIcon';
import MemoUndoIcon from '@icons/UndoIcon';
import MemoYellowBulb from '@icons/YellowBulb';
import viewpointImg from '@images/viewpoint_img.svg';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Grid, IconButton, Menu, MenuItem } from '@mui/material';
import { ParagraphLightText } from '@styles/Typography';
import { actions as globalActions } from 'app/features/Global/slice';
import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { addArchievedViewpoint, undoArchievedViewpoint } from './mutation';
import {
  ActionCardWrapper,
  CardContentContainer,
  CioCardDescription,
  CioCardImg,
  ConnectGrid,
  MessageGrid,
  SuggestedCardBody,
} from './styles';

interface CioCardProps {
  data: any[];
  triggerQuery?: (query: string) => void;
  clientCount: number;
  isArchived?: boolean;
}

function CioCard({
  data,
  triggerQuery = () => {},
  clientCount,
  isArchived = false,
}: CioCardProps): React.JSX.Element {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const [elementRef, width] = useElementWidth();

  const { mutate, isLoading } = useMutation(addArchievedViewpoint);
  const { mutate: undoMutate } = useMutation(undoArchievedViewpoint);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  const handleButtonClick = (query: string | undefined) => {
    triggerQuery(query || '');
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedIndex(null);
  };

  const getDropdownOptions = item => {
    if (Array.isArray(item?.advisorQuestions)) {
      return item?.advisorQuestions[0];
    }
    return item?.advisorQuestions;
  };

  const handleArchive = id => {
    const payload = { id };

    mutate(payload, {
      onSuccess: res => {
        queryClient.invalidateQueries('get-archived-viewpoints');
        queryClient.invalidateQueries('get-cio-viewpoints');
        dispatch(
          globalActions.displayToast({
            duration: 3000,
            toastType: 'success',
            toastMessage: res?.message,
          }),
        );
      },
      onError: (error: any) => {
        handleError(dispatch, error);
      },
    });
  };

  const handleUndo = id => {
    const payload = { id };

    undoMutate(payload, {
      onSuccess: res => {
        queryClient.invalidateQueries('get-archived-viewpoints');
        queryClient.invalidateQueries('get-cio-viewpoints');
        dispatch(
          globalActions.displayToast({
            duration: 3000,
            toastType: 'success',
            toastMessage: res?.message,
          }),
        );
      },
      onError: (error: any) => {
        handleError(dispatch, error);
      },
    });
  };

  return (
    <CardContentContainer container>
      <SuggestedCardBody
        item
        container
        columnGap={2}
        rowGap={2}
        justifyContent={'flex-start'}
      >
        {data.map((item, index) => {
          if (item === null) return null;
          const summary = item?.summary || '';

          return (
            <Grid
              item
              key={index}
              xs={12}
              sm={12}
              md={5.8}
              sx={{
                minWidth: {
                  xs: '100%',
                  md: '500px',
                },
              }}
              ref={elementRef}
            >
              <ActionCardWrapper item container>
                <Grid
                  item
                  container
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  sx={{
                    padding: '6px',
                    background: isArchived
                      ? 'var(--Neutral-400, #98A2B3)'
                      : '#333',
                    borderRadius: '4px',
                    marginBottom: '10px',
                  }}
                >
                  <Grid
                    item
                    container
                    alignItems={'center'}
                    flexWrap={'nowrap'}
                  >
                    <Grid item mt={0.2}>
                      <PriorityIcon />
                    </Grid>
                    <Grid item>
                      <CardLabel>{item?.focus}</CardLabel>
                    </Grid>
                    {!isArchived ? (
                      <Grid justifySelf={'flex-end'} marginLeft={'auto'}>
                        <CustomTooltip title={'Archive viewpoint'}>
                          <IconButton
                            onClick={() => {
                              if (!isLoading) {
                                handleArchive(item?.id);
                              }
                            }}
                            sx={{
                              padding: 0,
                              cursor: isLoading ? 'not-allowed' : 'pointer',
                            }}
                          >
                            <MemoArchived />
                          </IconButton>
                        </CustomTooltip>
                      </Grid>
                    ) : (
                      <Grid item marginLeft={'auto'}>
                        <CustomTooltip title={'Undo Archive'}>
                          <IconButton
                            onClick={() => {
                              if (!isLoading) {
                                handleUndo(item?.id);
                              }
                            }}
                            sx={{
                              padding: 0,
                              cursor: isLoading ? 'not-allowed' : 'pointer',
                            }}
                          >
                            <MemoUndoIcon />
                          </IconButton>
                        </CustomTooltip>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <CioCardDescription container>
                  {item?.image_s3_url && item?.image_s3_url ? (
                    <Grid item xs={12} md={4}>
                      <CioCardImg
                        src={item?.image_s3_url}
                        alt="Uploaded image"
                      />
                    </Grid>
                  ) : (
                    <Grid item xs={12} md={4}>
                      <CioCardImg
                        src={viewpointImg}
                        alt="Default image"
                        style={{ objectFit: 'cover' }}
                      />
                    </Grid>
                  )}

                  <Grid item xs={12} md={7.8}>
                    {summary && (
                      <MessageGrid
                        item
                        container
                        height="160px"
                        justifyContent={'flex-start'}
                        gap={1}
                      >
                        {item?.no_clients && item?.no_clients !== 0 ? (
                          <Grid item>
                            <ParagraphLightText color={'#818181'}>
                              {item?.no_clients}
                              {' clients can benefit from this'}
                            </ParagraphLightText>
                          </Grid>
                        ) : (
                          <></>
                        )}
                        <ReadMoreReadLess
                          maxHeight={
                            item?.no_clients && item?.no_clients !== 0
                              ? 115
                              : 135
                          }
                          lineClamp={
                            item?.no_clients && item?.no_clients !== 0 ? 5 : 6
                          }
                          text={summary}
                        />
                      </MessageGrid>
                    )}
                  </Grid>
                </CioCardDescription>
                <Grid
                  item
                  container
                  mt={'7px'}
                  justifyContent={'space-between'}
                >
                  <Grid
                    item
                    container
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    xs={12}
                    role="button"
                    aria-label="Select Option"
                    tabIndex={0}
                    onClick={event => {
                      event.stopPropagation();
                      handleButtonClick(getDropdownOptions(item));
                    }}
                    onKeyDown={event => {
                      if (event.key === 'Enter' || event.key === ' ') {
                        event.preventDefault();
                        handleButtonClick(getDropdownOptions(item));
                      }
                    }}
                  >
                    {item?.advisorQuestions.length && (
                      <SuggestedFollowUps
                        questions={item.advisorQuestions}
                        handleQuestionClick={handleButtonClick}
                      />
                    )}
                  </Grid>
                </Grid>

                {selectedIndex === index && (
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                    sx={{
                      marginTop: '23px',
                      '& .MuiPaper-root': {
                        width,
                        marginLeft: '12px',
                      },
                    }}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                  >
                    {Array.isArray(item?.advisorQuestions) ? (
                      item?.advisorQuestions.map((option, i) => (
                        <MenuItem
                          key={i}
                          onClick={() => {
                            handleButtonClick(option);
                            handleMenuClose();
                          }}
                          sx={{ fontSize: '12px', width: '100%' }}
                        >
                          <Grid
                            item
                            container
                            alignItems="center"
                            justifyContent={'flex-start'}
                            flexWrap={'nowrap'}
                            columnGap={0.75}
                            sx={{
                              width: 'fit-content',
                              border:
                                '1px solid var(--Text-Dark-Secondary, #86858b)',
                              borderRadius: '18px',
                              padding: '10px 18px 10px 16px',
                              background: '#fff',
                            }}
                          >
                            <Grid item alignItems={'center'}>
                              <MemoMagic />
                            </Grid>
                            <Grid
                              item
                              sx={{
                                textWrap: 'wrap',
                              }}
                            >
                              <p
                                style={{
                                  fontSize: '12px',
                                  color: ' var(--Text-Dark-Secondary, #86858B)',
                                  fontWeight: 500,
                                  lineHeight: 'normal',
                                }}
                              >
                                {option}
                              </p>
                            </Grid>
                          </Grid>
                          {/* {option} */}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem
                        onClick={() => {
                          handleButtonClick(item?.advisorQuestions);
                          handleMenuClose();
                        }}
                        sx={{ fontSize: '12px', width: '100%' }}
                      >
                        <Grid
                          item
                          container
                          alignItems="center"
                          justifyContent={'flex-start'}
                          flexWrap={'nowrap'}
                          columnGap={0.6}
                          sx={{
                            width: 'fit-content',
                            border:
                              '1px solid var(--Text-Dark-Secondary, #86858b)',
                            borderRadius: '18px',
                            padding: '10px 18px 10px 16px',
                            background: '#fff',
                          }}
                        >
                          <Grid item container alignItems={'center'}>
                            <MemoMagic />
                          </Grid>

                          <Grid item>
                            <p
                              style={{
                                fontSize: '12px',
                                color: ' var(--Text-Dark-Secondary, #86858B)',
                                fontWeight: 500,
                                lineHeight: 'normal',
                              }}
                            >
                              {item?.advisorQuestions}
                            </p>
                          </Grid>
                        </Grid>
                      </MenuItem>
                    )}
                  </Menu>
                )}
              </ActionCardWrapper>
              {clientCount === 0 ? (
                <ConnectGrid
                  item
                  container
                  xs={12}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  onClick={() => navigate('/integrations')}
                  style={{ marginTop: '-5px' }}
                >
                  <Grid
                    item
                    container
                    xs={10}
                    alignItems={'center'}
                    columnGap={1}
                  >
                    <Grid item>
                      <MemoYellowBulb />
                    </Grid>
                    <Grid item container xs={10} justifyContent={'flex-start'}>
                      <p className="text">
                        Connect client data to view impacts on your book
                      </p>
                    </Grid>
                  </Grid>

                  <Grid item container xs={2} justifyContent={'flex-end'}>
                    <KeyboardArrowRightIcon style={{ color: '#fff' }} />
                  </Grid>
                </ConnectGrid>
              ) : (
                <></>
              )}
            </Grid>
          );
        })}
      </SuggestedCardBody>
    </CardContentContainer>
  );
}

export default CioCard;
