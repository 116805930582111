/* eslint-disable react/prop-types */
import * as React from 'react';

function SageIcon(props: any): React.JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || '67'}
      height={props.height || '52'}
      fill="none"
      viewBox="0 0 67 52"
    >
      <path fill={props.fill || '#D3D3D3'} d="M0 0H67V52H0z"></path>
      <g clipPath="url(#clip0_696_79497)">
        <path
          fill="url(#paint0_linear_696_79497)"
          d="M0 0H1440V900H0z"
          transform="translate(-1032 -80)"
        ></path>
        <g>
          <path fill="#fff" d="M-948 -80H408V20H-948z"></path>
        </g>
        <g filter="url(#filter0_b_696_79497)">
          <path
            fill="#fff"
            d="M0 0H1356V800H0z"
            transform="translate(-948 20)"
          ></path>
        </g>
        <g>
          <path
            fill="#101828"
            fillOpacity="0.08"
            d="M-1032 -80H408V944H-1032z"
          ></path>
        </g>
        <g filter="url(#filter1_d_696_79497)">
          <path
            fill="#fff"
            d="M0 0H576V900H0z"
            transform="translate(-168 -80)"
          ></path>
          <g>
            <g>
              <rect
                width="510"
                height="300.943"
                x="-135.5"
                y="-47.5"
                fill="#FCFDFD"
                rx="3.5"
              ></rect>
              <rect
                width="510"
                height="300.943"
                x="-135.5"
                y="-47.5"
                stroke="#E4E7EC"
                rx="3.5"
              ></rect>
              <g>
                <path
                  fill="#283345"
                  d="M32.966 50.964l-2.498-2.498a.86.86 0 010-1.217l2.498-2.497a.86.86 0 011.216 0l2.498 2.497a.861.861 0 010 1.217l-2.498 2.498a.86.86 0 01-1.216 0zM66.268 33.74L52.31 47.695a5.484 5.484 0 01-7.755 0L34.314 37.454a1.049 1.049 0 00-1.48 0L22.593 47.695a5.484 5.484 0 01-7.756 0L.881 33.74a1.046 1.046 0 010-1.48L32.834.306a1.046 1.046 0 011.48 0L66.268 32.26a1.048 1.048 0 010 1.48zm-31.954-3.715l5.95 5.95a1.047 1.047 0 001.48 0l2.235-2.235a1.046 1.046 0 000-1.48l-9.665-9.664a1.046 1.046 0 00-1.48 0l-9.665 9.664a1.047 1.047 0 000 1.48l2.236 2.235a1.046 1.046 0 001.48 0l5.95-5.95a1.046 1.046 0 011.479 0zm14.86 13.38l9.664-9.665a1.044 1.044 0 000-1.48L34.314 7.736a1.046 1.046 0 00-1.48 0L8.31 32.259a1.046 1.046 0 000 1.48l9.665 9.664a1.046 1.046 0 001.48 0l2.235-2.235a1.046 1.046 0 000-1.48l-5.95-5.949a1.046 1.046 0 010-1.48l17.094-17.094a1.046 1.046 0 011.48 0L51.408 32.26a1.047 1.047 0 010 1.48l-5.95 5.95a1.046 1.046 0 000 1.48l2.235 2.235a1.046 1.046 0 001.48 0z"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
      <defs>
        <filter
          id="filter0_b_696_79497"
          width="1404"
          height="848"
          x="-972"
          y="-4"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feGaussianBlur
            in="BackgroundImageFix"
            stdDeviation="12"
          ></feGaussianBlur>
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_696_79497"
          ></feComposite>
          <feBlend
            in="SourceGraphic"
            in2="effect1_backgroundBlur_696_79497"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter1_d_696_79497"
          width="592"
          height="916"
          x="-176"
          y="-84"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="4"></feOffset>
          <feGaussianBlur stdDeviation="4"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.1 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_696_79497"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_696_79497"
            result="shape"
          ></feBlend>
        </filter>
        <linearGradient
          id="paint0_linear_696_79497"
          x1="720"
          x2="720"
          y1="0"
          y2="900"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC38B"></stop>
          <stop offset="1" stopColor="#FF8C5A"></stop>
        </linearGradient>
        <clipPath id="clip0_696_79497">
          <path
            fill="#fff"
            d="M0 0H1440V900H0z"
            transform="translate(-1032 -80)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

const MemoSageIcon = React.memo(SageIcon);
export default MemoSageIcon;
