import React from 'react';
import BoltIcon from '@icons/bolt';
import { EnterpriseName, IconContainer } from './styles';
import { price } from '@common/number';
import { Divider, Grid, Typography } from '@mui/material';
import { FirmAdminCard } from '@pages/EnterpriseAdmin/styles';

interface EnterpriseDetailsProps {
  data: any;
  enterpriseDetails: any;
}

const EnterpriseSection: React.FC<EnterpriseDetailsProps> = ({
  data,
  enterpriseDetails,
}) => {
  return (
    <Grid
      container
      xs={3.2}
      direction={'column'}
      p={2}
      sx={{
        background: 'var(--Neutral-50, #f9fafb)',
        borderRadius: '8px',
        maxHeight: '100%',
        overflowY: 'auto',
      }}
    >
      <Grid item container direction={'row'} alignItems={'center'}>
        <IconContainer />
        <EnterpriseName>{enterpriseDetails?.name}</EnterpriseName>
      </Grid>
      <Divider
        style={{
          width: '100%',
          margin: '15px 0px',
          border: '0.5px solid #e4e7ec',
        }}
      />
      <Grid item container rowGap={1.2}>
        {Array.isArray(data) &&
          data?.length > 0 &&
          data
            .filter(item =>
              ['Total Firms', 'Total AUM', 'Total Clients'].includes(item?.key),
            )
            .map((item: any, index) => {
              return (
                <FirmAdminCard item container key={index} columnGap={1}>
                  <Grid item>
                    <BoltIcon
                      width="36px"
                      height="36px"
                      fill="#E8FFF7"
                      stroke="#333"
                    />
                  </Grid>
                  <Grid
                    item
                    direction={'column'}
                    height={'30x'}
                    mt={'-3px'}
                    justifyContent={'flex-start'}
                  >
                    <Typography
                      sx={{
                        fontSize: '14px',
                        color: '#475467',
                      }}
                    >
                      {item.key}
                    </Typography>

                    <Typography
                      sx={{
                        fontSize: '18px',
                        color: '#101828',
                        marginTop: '-3px',
                      }}
                    >
                      {item.key === 'Total AUM'
                        ? price(item.value)
                        : item.value}
                    </Typography>
                  </Grid>
                </FirmAdminCard>
              );
            })}
      </Grid>
      <Grid item container pt={2}>
        {Array.isArray(data) &&
          data?.length > 0 &&
          data
            .filter(
              item =>
                !['Total Firms', 'Total AUM', 'Total Clients'].includes(
                  item?.key,
                ),
            )
            .map((item, index) => (
              <Grid
                item
                container
                direction={'row'}
                justifyContent={'space-between'}
                padding={'15px 5px'}
                alignItems={'center'}
                fontSize={'14px'}
                sx={{
                  borderTop: '1px solid #e4e7ec',
                }}
              >
                <Grid
                  item
                  key={index}
                  alignItems={'center'}
                  fontSize={'14px'}
                  color={'#475467'}
                >
                  {item.key}
                </Grid>
                <Grid
                  item
                  key={index}
                  alignItems={'center'}
                  fontSize={'14px'}
                  color={'#101828'}
                >
                  {item.value}
                </Grid>
              </Grid>
            ))}
      </Grid>
    </Grid>
  );
};

export default EnterpriseSection;
