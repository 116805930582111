/* eslint-disable no-useless-escape */
import {
  ForumOutlined as ForumOutlinedIcon,
  GridViewOutlined as GridViewOutlinedIcon,
  Settings as SettingsIcon,
  StreamOutlined as StreamOutlinedIcon,
} from '@mui/icons-material';
// import GroupIcon from '@mui/icons-material/Group';
import { MenuListProps } from '@features/Global/types';
// import InsertLinkIcon from '@mui/icons-material/InsertLink';
// import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import MemoClientBrief from '@icons/ClientBrief';
import ClientsIcon from '@icons/ClientsIcon';
import IntegrationIcon from '@icons/Integration';
import MemoPortfolio from '@icons/Portfolio';
import MemoUser from '@icons/User';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { EAssetMix, EClientFilter } from 'app/enums';
// import { settings } from 'config';
interface OtherAccordionDataProps {
  title: string;
  type: string;
}

interface OverviewClientsProps {
  name: string;
}

export const WEALTH_URL = `${process.env.REACT_APP_WEALTHBOX_AUTH_URL}?client_id=${process.env.REACT_APP_WEALTHBOX_CLIENT_ID}&scope=login+data&redirect_uri=${process.env.REACT_APP_FRONTEND_URL}/authorize/wealthbox&response_type=code`;
export const SALESFORCE_URL = `${process.env.REACT_APP_SALESFORCE_AUTH_URL}?response_type=code&client_id=${process.env.REACT_APP_SALESFORCE_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_FRONTEND_URL}/authorize/salesforce/&scope=api+web+openid+profile+refresh_token&prompt=login`;
export const HUBSPOT_URL = `${process.env.REACT_APP_HUBSPOT_BASE_URL}/oauth/authorize?client_id=${process.env.REACT_APP_HUBSPOT_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_HUBSPOT_REDIRECT_URI}&scope=${process.env.REACT_APP_HUBSPOT_SCOPE}&optional_scope=${process.env.REACT_APP_HUBSPOT_OPTIONAL_SCOPE}`;

export const Navigation: Array<MenuListProps> = [
  {
    name: 'Home',
    enableNotifications: true,
    notification: true,
    path: '/conversations/stream',
    cursor: 'pointer',
    showToUser: ['FIRM_ADMIN', 'ADVISOR'],
    children: [
      {
        name: 'Conversations',
        enableNotifications: true,
        notification: true,
        path: '/start',
        cursor: 'pointer',
        Icon: ForumOutlinedIcon,
      },
    ],
    Icon: ForumOutlinedIcon,
  },
  // {
  //   name: 'Stream',
  //   enableNotifications: false,
  //   notification: false,
  //   path: '/conversations/stream',
  //   cursor: 'pointer',
  //   children: [],
  //   Icon: ForumOutlinedIcon,
  //   showToUser: ['FIRM_ADMIN', 'ADVISOR'],
  // },
  {
    name: 'My Firms',
    enableNotifications: false,
    notification: false,
    path: '/firms',
    cursor: 'pointer',
    children: [],
    Icon: AccountBalanceIcon,
    showToUser: ['ENTERPRISE_ADMIN'],
  },
  {
    name: 'Insights & Viewpoints',
    enableNotifications: false,
    notification: false,
    path: '/viewpoints_dashboard',
    cursor: 'pointer',
    children: [],
    Icon: GridViewOutlinedIcon,
    showToUser: ['ENTERPRISE_ADMIN'],
  },
  {
    name: 'Configurations',
    enableNotifications: false,
    notification: false,
    path: '/questions',
    cursor: 'pointer',
    children: [],
    Icon: SettingsIcon,
    showToUser: ['ENTERPRISE_ADMIN'],
  },
  {
    name: 'Markets',
    enableNotifications: false,
    notification: false,
    path: '/actions',
    cursor: 'pointer',
    children: [],
    Icon: StreamOutlinedIcon,
    showToUser: ['FIRM_ADMIN', 'ADVISOR'],
  },
  // {
  //   name: 'CIO Viewpoints',
  //   enableNotifications: false,
  //   notification: false,
  //   path: '/view-points',
  //   cursor: 'pointer',
  //   children: [],
  //   Icon: StreamOutlinedIcon,
  //   showToUser: 'ADMIN',
  // },
  // {
  //   name: 'Insights',
  //   enableNotifications: false,
  //   notification: false,
  //   path: '/insights',
  //   cursor: 'pointer',
  //   children: [],
  //   Icon: GridViewOutlinedIcon,
  //   showToUser: 'ADMIN',
  // },
  {
    name: 'Firm Management',
    enableNotifications: false,
    notification: false,
    path: '/manage-firms',
    cursor: 'pointer',
    children: [],
    Icon: AccountBalanceIcon,
    showToUser: ['FIRM_ADMIN'],
  },

  {
    name: 'Clients',
    enableNotifications: false,
    notification: false,
    path: '/clients',
    cursor: 'pointer',
    children: [],
    // Icon: GroupsOutlinedIcon,
    Icon: ClientsIcon,
    showToUser: ['FIRM_ADMIN', 'ADVISOR'],
  },
  {
    name: 'Integrations',
    enableNotifications: false,
    notification: false,
    path: '/integrations',
    cursor: 'pointer',
    children: [],
    // Icon: InsertLinkIcon,
    Icon: IntegrationIcon,
    showToUser: ['FIRM_ADMIN', 'ADVISOR'],
  },
  {
    name: 'Tests',
    enableNotifications: false,
    notification: false,
    path: '/test/queries',
    cursor: 'pointer',
    children: [],
    Icon: StreamOutlinedIcon,
    showToUser: ['SUPER_ADMIN'],
  },
  {
    name: 'Test Results',
    enableNotifications: false,
    notification: false,
    path: '/test/results',
    cursor: 'pointer',
    Icon: StreamOutlinedIcon,
    children: [],
    showToUser: ['SUPER_ADMIN'],
  },
  {
    name: 'Administrator Console',
    enableNotifications: false,
    notification: false,
    path: '/admin-console',
    cursor: 'pointer',
    Icon: StreamOutlinedIcon,
    children: [],
    showToUser: ['SUPER_ADMIN'],
  },
  {
    name: 'Current Events',
    enableNotifications: false,
    notification: false,
    path: '/current-events',
    cursor: 'pointer',
    Icon: StreamOutlinedIcon,
    children: [],
    showToUser: ['SUPER_ADMIN'],
  },
];

export const HYPER_DATA = `
  hi this is sid <br></br> Thiis s <b>ankit</b> \n<table border=\"1\" class=\"dataframe\">\n  <thead>\n    <tr style=\"text-align:right;\">\n      <th>First Name</th>\n      <th>Last Name</th>\n      <th>Ticker</th>\n      <th>Weight</th>\n    </tr>\n  </thead>\n  <tbody>\n    <tr>\n      <td>Michael</td>\n      <td>Winters</td>\n      <td>VOO</td>\n      <td>27.00</td>\n    </tr>\n    <tr>\n      <td>Irina</td>\n      <td>Stewart</td>\n      <td>VOO</td>\n      <td>23.56</td>\n    </tr>\n  </tbody>\n</table> thiese are top 3 result <br> \n<table border=\"1\" class=\"dataframe\">\n  <thead>\n    <tr style=\"text-align:right;\">\n      <th>First Name</th>\n      <th>Last Name</th>\n      <th>Ticker</th>\n      <th>Weight</th>\n    </tr>\n  </thead>\n  <tbody>\n    <tr>\n      <td>Michael</td>\n      <td>Winters</td>\n      <td>VOO</td>\n      <td>27.00</td>\n    </tr>\n  </tbody>\n</table>
`;

export const INSIGHT_DATA = [
  {
    type: 'active',
    data: [
      {
        priority: 'moderate',
        date: 'Nov 29, 2023',
        highlight: {
          client: null,
          text: 'Bond markets are selling off due to receding recession risks and more uncertainty over the inflation and policy outlook. We agree that near term recession risks have receded, but also think that interest rates are high enough to slow growth and inflation from here. We think taking steps out of cash and into shorter duration bond strategies offers a pick-up in yield for most investors and potential for price appreciation if rates do fall.',
        },
        description:
          'Would you like to see accounts with high Cash Allocation?',
        query: 'View Accounts with High Cash Allocation',
        ctaText: 'View Accounts',
      },
      {
        priority: 'moderate',
        date: 'Nov 29, 2023',
        highlight: {
          client: null,
          text: "We believe the demographics of the Chinese labor force do not support a long term growth outlook that is consistent with the country's weighting as it relates to international equity benchmarks. However, we have do have a positive outlook for international equities excluding China. As such, we recommend an equal weighting to international equities in line with client mandates, while simultaneously zeroing out any exposure to  Chinese equities.",
        },
        description: 'Would you like to see accounts with exposure to China?',
        query:
          'Give me a list of clients that have at least a 10% allocation to China and recommend alternative emerging market funds that have no China exposure',
        ctaText: 'View Clients',
      },
      {
        priority: 'moderate',
        date: 'Nov 17, 2023',
        highlight: {
          client: 'Jackson Brees',
          text: 'The portfolio is not aligned with the new CIO viewpoints.',
        },
        description: "Would you like to see the Sage's recommendation?",
        query:
          "show me how Rely on your core will work for Jackson's portfolio",
        ctaText: 'View Recommendation',
      },
      {
        priority: 'high',
        date: 'Oct 18, 2023',
        highlight: {
          client: null,
          type: 'error',
          text: 'VOO has been removed from the approved list.',
        },
        description:
          'Would you like to see your impacted clients and recommended replacements?',
        query:
          'Show me impacted clients with VOO ticker and recommend replacements',
        ctaText: 'View Replacements',
      },
      {
        priority: 'high',
        date: 'Oct 18, 2023',
        highlight: {
          client: null,
          text: 'JP Morgan has updated their views to underweight US Large Caps and overweight US Small Caps.',
        },
        description:
          'Would you like to see how this would impact your clients?',
        query: null,
        ctaText: 'View Replacements',
      },
    ],
  },
  {
    type: 'follow up',
    data: [
      {
        priority: 'moderate',
        date: 'Sep 29, 2023',
        highlight: {
          client: 'Mark Jones',
          text: 'Had an unscheduled inflow of $10K, please confirm allocation and trades',
        },
        description: null,
        query: null,
        ctaText: 'View Portfolio',
      },
    ],
  },
  {
    type: 'info',
    data: [
      {
        priority: 'moderate',
        date: 'Dec 12, 2024',
        highlight: {
          client: null,
          text: 'The quarterly review and commentary of watch listed items will be sent out next Monday.',
        },
        description: null,
        query: null,
        ctaText: 'Review Accounts',
      },
      {
        priority: 'moderate',
        date: 'Dec 12, 2024',
        highlight: {
          client: null,
          text: 'The Fed raised short term rates by 50 bps. You have 35 clients with high allocation to fixed income and duration over 10 years.',
        },
        description:
          'Would you like to see how this would impact your clients?',
        query: null,
        ctaText: 'Review Accounts',
      },
    ],
  },
];

export const INVESTMENT_INSIGHTS_DATA = [
  {
    id: 'some-hash-code-1',
    title: 'Rely on your core',
    createdAt: '10/01/2023',
    desc: 'A stronger growth outlook and higher bond yields suggest that multi-asset portfolios are set to deliver on their “best in a decade” promise.',
    query: 'Show me details on how to implement "Rely on your core" viewpoints',
  },
  {
    id: 'some-hash-code-2',
    title: 'Rebuild equity portfolios',
    createdAt: '10/01/2023',
    desc: 'We see new highs for stock markets over the next year. We are focused on the sizes, factors and themes that could lead in the next cycle including small and mid cap stocks, AI and technology, real assets, and the energy transition.',
    query:
      'Show me details on how to implement "Rebuild Equity Portfolios" viewpoints',
  },
  {
    id: 'some-hash-code-3',
    title: 'Step out of cash',
    createdAt: '10/01/2023',
    desc: 'We expect cash to lag inflation and for rates to fall. Our clients have increased their allocations to cash & short term by over $150bn. We believe investors should move out of excess cash and into longer-duration fixed income or multi-asset portfolios.',
    query: 'Show me details on how to implement "Step out of cash" viewpoints',
  },
  {
    id: 'some-hash-code-4',
    title: 'Capitalize on distress',
    createdAt: '10/01/2023',
    desc: 'We believe there are opportunities forming because of regional bank stress and commercial real estate valuation declines.',
    query:
      'Show me details on how to implement "Capitalize on distress" viewpoints',
  },
  {
    id: 'some-hash-code-5',
    title: 'Manage your concentrated positions',
    createdAt: '10/01/2023',
    desc: 'We think proper diversification is key to long term plan, and 1 to 4 stocks in the Russell 3000 are still 75% below their 2021 high. We recommend strategies that can efficiently reduce concentration risk like exchange funds, covered call writing, Donor Advised Funds, and Grantor Retained Annuity Trusts.',
    query:
      'Show me details on how to implement "Manage your concentrated positions" viewpoints',
  },
];

export const PORTFOLIO_LIST: any = [
  {
    id: 'some-hash-code-portfolio-id-1',
    name: 'CIO Portfolios - Tax Smart',
    totalAssetsValue: '$1,000,000',
    investmentType: 'Equity',
    riskProfile: 'Conservative',
    primaryFocus: true,
    expectedRisk: 7.21,
    expectedReturns: 12.2,
    assestAllocation: [
      {
        assetId: 'asset-id-1',
        name: 'Equity',
        percentage: 70,
      },
      {
        assetId: 'asset-id-2',
        name: 'Bonds',
        percentage: 30,
      },
    ],
    holdings: [
      {
        id: 'holding-id-1',
        ticker: 'AAPL',
        name: 'Apple Inc.',
        nav: 168.99,
        quantity: 100,
        annualizedReturn: '10%',
        cumulativeReturn: '10%',
        weight: 85.2,
        category: 'category-name',
      },
      {
        id: 'holding-id-2',
        ticker: 'MSFT',
        name: 'Microsoft Corp.',
        nav: 245.99,
        quantity: 50,
        annualizedReturn: '15%',
        cumulativeReturn: '15%',
        weight: 10.6,
        category: 'category-name',
      },
      {
        id: 'holding-id-3',
        ticker: 'GOOG',
        name: 'Alphabet Inc.',
        nav: 1300.99,
        quantity: 25,
        annualizedReturn: '20%',
        cumulativeReturn: '20%',
        weight: 4.2,
        category: 'category-name',
      },
    ],
    returns: [],
  },
  {
    id: 'some-hash-code-portfolio-id-2',
    name: 'Managed Equity Portfolio',
    totalAssetsValue: 1000000,
    investmentType: 'Equity',
    riskProfile: 'Conservative',
    primaryFocus: false,
    expectedRisk: 2.61,
    expectedReturns: 4.71,
    assestAllocation: [
      {
        assetId: 'asset-id-1',
        name: 'Equity',
        percentage: 70,
      },
      {
        assetId: 'asset-id-2',
        name: 'Bonds',
        percentage: 30,
      },
    ],
    holdings: [
      {
        id: 'holding-id-1',
        ticker: 'AAPL',
        name: 'Apple Inc.',
        nav: 168.99,
        quantity: 100,
        annualizedReturn: '10%',
        cumulativeReturn: '10%',
        weight: 85.2,
        category: 'category-name',
      },
      {
        id: 'holding-id-2',
        ticker: 'MSFT',
        name: 'Microsoft Corp.',
        nav: 245.99,
        quantity: 50,
        annualizedReturn: '15%',
        cumulativeReturn: '15%',
        weight: 10.6,
        category: 'category-name',
      },
      {
        id: 'holding-id-3',
        ticker: 'GOOG',
        name: 'Alphabet Inc.',
        nav: 1300.99,
        quantity: 25,
        annualizedReturn: '20%',
        cumulativeReturn: '20%',
        weight: 4.2,
        category: 'category-name',
      },
    ],
    returns: [],
  },
];

export const PORTFOLIO_CARD_DATA = {
  id: 'some-hash-code-portfolio-id-1',
  name: 'CIO Portfolios - Tax Smart',
  totalAssetsValue: '$1,000,000',
  investmentType: 'Equity',
  riskProfile: 'Conservative',
  primaryFocus: true,
  expectedRisk: 7.21,
  expectedReturns: 12.2,
  assestAllocation: [
    {
      assetId: 'asset-id-1',
      name: 'Equity',
      percentage: 70,
    },
    {
      assetId: 'asset-id-2',
      name: 'Bonds',
      percentage: 30,
    },
  ],
  holdings: [
    {
      id: 'holding-id-1',
      ticker: 'AAPL',
      name: 'Apple Inc.',
      nav: 168.99,
      quantity: 100,
      annualizedReturn: '10%',
      cumulativeReturn: '10%',
      weight: 85.2,
      category: 'category-name',
    },
    {
      id: 'holding-id-2',
      ticker: 'MSFT',
      name: 'Microsoft Corp.',
      nav: 245.99,
      quantity: 50,
      annualizedReturn: '15%',
      cumulativeReturn: '15%',
      weight: 10.6,
      category: 'category-name',
    },
    {
      id: 'holding-id-3',
      ticker: 'GOOG',
      name: 'Alphabet Inc.',
      nav: 1300.99,
      quantity: 25,
      annualizedReturn: '20%',
      cumulativeReturn: '20%',
      weight: 4.2,
      category: 'category-name',
    },
  ],
  returns: [],
};

// Drawer Mock Data
export const SELECTED_INSIGHTS_OPTION_DATA = {
  id: 'some-hash-code-1',
  title: 'Rely on your core',
  createdAt: '10/01/2023',
  desc: 'A stronger growth outlook and higher bond yields suggest that multi-asset portfolios are set to deliver on their “best in a decade” promise.',
  portfolios: PORTFOLIO_LIST,
};

export const CHAT_API_INVESTMENT_INSIGHTS_DATA = [
  {
    type: 'text',
    data: `For John Smith I would actually recommend using the ‘Rebuild Equity Portfolios’ or ‘Trim Excess Cash’ investment insight.
  Select which insight would you like to proceed with -`,
  },
  {
    type: 'cio_card',
    data: INVESTMENT_INSIGHTS_DATA,
  },
];

export const CHAT_API_SELECTED_INSIGHTS_OPTION_DATA = [
  {
    type: 'text',
    data: `Here are the details of how this following investment insight must be implemented -`,
  },
  {
    type: 'cio_details_card',
    data: SELECTED_INSIGHTS_OPTION_DATA,
  },
];

export const CHAT_API_PORTFOLIO_LIST = [
  {
    type: 'text',
    data: `Here’s how the expected risk and return of John’s portfolio would change if we implemented the recommendation. As you can see expected return increases and so does expected risk, but expected risk is within acceptable range for John’s profile.  `,
  },
  {
    type: 'portfolio_card',
    data: PORTFOLIO_LIST,
  },
];

export const ACCORDION_DATA = [
  {
    title: 'Microsoft Corp',
    tag: 'MSFT',
    value: '8.52%',
  },
  {
    title: 'Alphabet Inc Class C',
    tag: 'GOOG',
    value: '4.71%',
  },
  {
    title: 'Amazon.com Inc',
    tag: 'AMZN',
    value: '4.61%',
  },
  {
    title: 'Apple Inc.',
    tag: 'AAPL',
    value: '4.54%',
  },
  {
    title: 'United Health Group Inc.',
    tag: 'UNH',
    value: '4.24%',
  },
  {
    title: 'NVIDIA Corp',
    tag: 'NVDA',
    value: '3.81%',
  },
  {
    title: 'Meta Platforms',
    tag: 'META',
    value: '3.57%',
  },
  {
    title: 'Mastercard Inc.',
    tag: 'MA',
    value: '3.07%',
  },
];

export const OTHER_ACCORDION_DATA: OtherAccordionDataProps[] = [
  {
    title: 'CIO Portfolios - Tax Smart',
    type: '',
  },
  {
    title: 'Managed Equity Portfolio',
    type: 'Core Equity',
  },
  {
    title: 'Focused Dividend Growth',
    type: 'Core Equity',
  },
  {
    title: 'JPM Hedged Equity',
    type: 'Core Equity',
  },
  {
    title: 'JPM Evergreen (JPMF)',
    type: 'Private Equity',
  },
  {
    title: 'Managed Fixed Income Portfolio',
    type: 'Fixed Income',
  },
];

export const CioCardData = [
  {
    data: [
      {
        header: 'Rely on Your Core',
        highlight: {
          client: null,
          text: 'A stronger growth outlook and higher bond yields suggest that multi-asset portfolios are set to deliver on their “best in a decade” promise.',
          description: '32 Clients Affected',
        },
        ctaText: 'View Implications',
      },
      {
        header: 'Rebuild Equity Portfolios',
        highlight: {
          client: null,
          text: 'We see new highs for stock markets over the next year. We are focused on the sizes, factors and themes that could lead in the next cycle.',
          description: '32 Clients Affected',
        },
        ctaText: 'View Implications',
      },
      {
        header: 'Step Out of Cash',
        highlight: {
          client: null,
          text: 'The end of the Fed’s hiking cycle also likely means the end of cash outperformance over fixed income.',
          description: '32 Clients Affected',
        },
        ctaText: 'View Implications',
      },
      {
        header: 'Capitalize on Distress',
        highlight: {
          client: null,
          text: 'We believe there are opportunities forming because of regional bank stress and commercial real estate valuation declines.',
          description: '32 Clients Affected',
        },
        ctaText: 'View Implications',
      },
      {
        header: 'Manage Concentrated Positions',
        highlight: {
          client: null,
          text: 'Proper diversification is essential for a long-term plan. Consider strategies that range from the most basic to the more complex. Gifting is also a critical tool.',
          description: '32 Clients Affected',
        },
        ctaText: 'View Implications',
      },
    ],
  },
];

export const EventCardData = [
  {
    data: [
      {
        header: 'Fed raises funds rate 50 bps',
        highlight: {
          client: null,
          text: 'The Federal Reserve raised the Fed Funds rate by 50 basis points taking the range on the overnight rate to 5.25% - 5.50%, with an effective rate of 5.33%.',
          description: '41 Clients Affected',
        },
        ctaText: 'View Impacted Clients',
      },
      {
        header: 'Consumer sentiment drops',
        highlight: {
          client: null,
          text: 'The Conference Board’s Consumer Confidence Index dropped to 45 in December. Levels below 50 are generally considered recessionary.',
          description: '73 Clients Affected',
        },
        ctaText: 'View Impacted Clients',
      },
      {
        header: 'M2 continues its drop',
        highlight: {
          client: null,
          text: 'Over the last 12 months the M2 money supply has decreased by 4.5%. Decreases in M2 have been linked to deflationary periods.',
          description: '64 Clients Affected',
        },
        ctaText: 'View Impacted Clients',
      },
      {
        header: 'Oil prices spike',
        highlight: {
          client: null,
          text: 'In the last 30 days the price of Brent Crude oil surged from $73.44 to $105.79.',
          description: '55 Clients Affected',
        },
        ctaText: 'View Impacted Clients',
      },
      {
        header: 'Gold increases',
        highlight: {
          client: null,
          text: 'The price of gold increased by 20% from $1,995 to $2,394',
          description: '32 Clients Affected',
        },
        ctaText: 'View Impacted Clients',
      },
    ],
  },
];

export const SAMPLE_QUESTION = [
  {
    head: 'Viewpoint',
    list: [
      'what are the capital market assumptions associated with the Rely on your core viewpoint?',
      'What are the risk and return expectations for US equities?',
      'Which sector has the best expected risk adjusted return?',
      'Will emerging market equity outperform developed non-US equity? ',
    ],
  },
  {
    head: 'Implications for My Book',
    list: [
      // 'Which of my clients would lose the most if Equity goes down?',
      // 'Which clients have the most expected volatility?',
      // 'Who are my top 3 clients that are the most exposed to utilities?',
      // 'Which of my clients have exposure to the Energy sector?',
      // 'Can you show me the clients who have a cash allocation greater than 10%?',
      // "What would be the consequence for Emily Christy's portfolio if the price of oil goes up by 25%?",
      // "How would Ryan Dolan's investments be affected if TSLA outperforms SPY by 30%?",
      // 'What investment moves should be made for Emily Christy if the Federal Reserve hikes interest rates?',
      'What are the implications of the Rely on your core viewpoint on my book of business?',
      'Which of my clients would be most impacted if gold goes down by 25%?',
      "How would John Smith's portfolio be impacted if Emerging market fixed income out performed US Fixed Income Taxable Core?",
      'How would NFLX be impacted if US Large cap equities went up by 10%?',
      'How would NFLX be impacted if US Large cap equities went up by 10%?',
    ],
  },
  {
    head: "Implications for Client's Portfolios",
    list: [
      // "What changes are necessary for Emily Christy's investment strategy if the 10 year treasury rate increases by 2%?",
      // 'How do I adjust the investment mix for Ryan if emerging market equities outperform US equities?',
      // "How to restructure Emily Christy' portfolio in the event of a bear market?",
      // 'If Michael wants to exit his poitions in Chinese equities, what are some options to eliminate China exposure',
      // "If US Equity goes down, what adjustments should I make to Billy Fried's portfolio?",
      // "If oil goes down by 20%, what adjustments should I make to Irina's portfolio?",
      'What are the implications of the Rely on your core viewpoint on my book of business?',
      'Which of my clients would be most impacted if gold goes down by 25%?',
      "How would John Smith's portfolio be impacted if Emerging market fixed income out performed US Fixed Income Taxable Core?",
      'How would NFLX be impacted if US Large cap equities went up by 10%?',
      'How would NFLX be impacted if US Large cap equities went up by 10%?',
    ],
  },
  {
    head: "Adjustments & Implications on Client's Portfolios",
    list: [
      // "What changes are necessary for Emily Christy's investment strategy if the 10 year treasury rate increases by 2%?",
      // 'How do I adjust the investment mix for Ryan if emerging market equities outperform US equities?',
      // "How to restructure Emily Christy' portfolio in the event of a bear market?",
      // 'If Michael wants to exit his poitions in Chinese equities, what are some options to eliminate China exposure',
      // "If US Equity goes down, what adjustments should I make to Billy Fried's portfolio?",
      // "If oil goes down by 20%, what adjustments should I make to Irina's portfolio?",
      "What adjustments should I make on William Johnson's portfolio so it aligns with the Rely on your core viewpoint?",
      "What are the impacts on William Johnson's portfolio if I align it with the Rely on your core viewpoint?",
      "How should I optimize Sally Smith's portfolio given the updated capital market expectations?",
      "How should I optimize Sally Smith's portfolio given the updated capital market expectations?",
      'How would NFLX be impacted if US Large cap equities went up by 10%?',
    ],
  },
];

export const FIRM_CARD_OVERVIEW_DATA = [
  {
    name: 'Firm Name',
    firm_number: '10 Firms',
    total_members: '123 Members',
  },
  {
    name: 'Firm Name',
    firm_number: '10 Firms',
    total_members: '123 Members',
  },
  {
    name: 'Firm Name',
    firm_number: '10 Firms',
    total_members: '123 Members',
  },
  {
    name: 'Firm Name',
    firm_number: '10 Firms',
    total_members: '123 Members',
  },
  {
    name: 'Firm Name',
    firm_number: '10 Firms',
    total_members: '123 Members',
  },
  {
    name: 'Firm Name',
    firm_number: '10 Firms',
    total_members: '123 Members',
  },
  {
    name: 'Firm Name',
    firm_number: '10 Firms',
    total_members: '123 Members',
  },
  {
    name: 'Firm Name',
    firm_number: '10 Firms',
    total_members: '123 Members',
  },
  {
    name: 'Firm Name',
    firm_number: '10 Firms',
    total_members: '123 Members',
  },
  {
    name: 'Firm Name',
    firm_number: '10 Firms',
    total_members: '123 Members',
  },
  {
    name: 'Firm Name',
    firm_number: '10 Firms',
    total_members: '123 Members',
  },
  {
    name: 'Firm Name',
    firm_number: '10 Firms',
    total_members: '123 Members',
  },
  {
    name: 'Firm Name',
    firm_number: '10 Firms',
    total_members: '123 Members',
  },
  {
    name: 'Firm Name',
    firm_number: '10 Firms',
    total_members: '123 Members',
  },
  {
    name: 'Firm Name',
    firm_number: '10 Firms',
    total_members: '123 Members',
  },
  {
    name: 'Firm Name',
    firm_number: '10 Firms',
    total_members: '123 Members',
  },
  {
    name: 'Firm Name',
    firm_number: '10 Firms',
    total_members: '123 Members',
  },
  {
    name: 'Firm Name',
    firm_number: '10 Firms',
    total_members: '123 Members',
  },
  {
    name: 'Firm Name',
    firm_number: '10 Firms',
    total_members: '123 Members',
  },
  {
    name: 'Firm Name',
    firm_number: '10 Firms',
    total_members: '123 Members',
  },
  {
    name: 'Firm Name',
    firm_number: '10 Firms',
    total_members: '123 Members',
  },
  {
    name: 'Firm Name',
    firm_number: '10 Firms',
    total_members: '123 Members',
  },
  {
    name: 'Firm Name',
    firm_number: '10 Firms',
    total_members: '123 Members',
  },
];

export const CHEAT_SHEET = [
  {
    type: 'text',
    content: 'Here are some questions and categories to help you get started',
  },
  {
    type: 'sample_questions',
    content: [
      {
        head: 'Viewpoints',
        list: [
          'What are the capital market assumptions?',
          'What are the return and volatility expectations for US Equity?',
          "What's the correlation between US Equity and Bonds?",
          'Find low cost alternatives for SPY.',
          'Which sector has the best expected risk adjusted return?',
        ],
      },
      {
        head: 'Implications for My Book',
        list: [
          'Which of my clients would be most impacted if gold goes down by 25%?',
          'Who are my top 3 clients with the most exposure to Utilities?',
          'If interest rates go up by 1%, how would that impact my book?',
          'Top 5 with most exposure to China.',
          'Show me the clients that hold more than 10% in cash.',
        ],
      },
      {
        head: 'Implications for My Clients',
        list: [
          "What's the impact of oil going down 10% on Emily's portfolio?",
          "What would be the impact on Wendy's portfolio if interest rates go down by 1%?",
          "What are the return and volatility expectations for Ryan's portfolio?",
          "How would Irina's portfolio perform if US Equity goes down by 10%?",
          "What would be the impact on Ashton's portfolio if VIX goes up?",
        ],
      },
      {
        head: 'Adjustments for My Clients',
        list: [
          "What changes are necessary for Emily Christy's portfolio if the 10 year treasury rate increases by 2%?",
          "If US Equity goes down, what adjustments should I make to Billy Fried's portfolio?",
          "Assuming oil goes down by 20%, what adjustments are needed for Irina's portfolio?",
          "What adjustments should I make to Jane's portfolio so it aligns with the Step out of Cash viewpoint?",
          "Implement the Real Assets viewpoint on Molly Corr's portfolio.",
        ],
      },
    ],
  },
];

export const TABLE_DATA = [
  // needs to be removed
  {
    ID: 'MSFOUSA00D3G',
    ISIN: 'US4812C04983',
    CUSIP: '4812C0498',
    Ticker: 'HLIEX',
    Name: 'JPMorgan Equity Income I',
    LegalName: 'JPMorgan Equity Income Fund Class I',
    SecurityType: 'MF',
    Category: 'Large-Cap Value',
    Institutional: true,
    Sponsor: 'JPMorgan',
    Logo: 'JPMorgan.png',
    data: [
      {
        field_name: 'Energy',
        value: 0.0837213,
      },
    ],
  },
  {
    ID: 'MSFOUSA00DYT',
    ISIN: 'US4812A06230',
    CUSIP: '4812A0623',
    Ticker: 'JEMSX',
    Name: 'JPMorgan Emerging Markets Equity I',
    LegalName: 'JPMorgan Emerging Markets Equity Fund Class I',
    SecurityType: 'MF',
    Category: 'Diversified Emerging Markets',
    Institutional: true,
    Sponsor: 'JPMorgan',
    Logo: 'JPMorgan.png',
    data: [
      {
        field_name: 'Energy',
        value: 0.0129393,
      },
    ],
  },
  {
    ID: 'MSFOUSA00D3F',
    ISIN: 'US4812C17100',
    CUSIP: '4812C1710',
    Ticker: 'HLGEX',
    Name: 'JPMorgan Mid Cap Growth I',
    LegalName: 'JPMorgan Mid Cap Growth Fund Class I',
    SecurityType: 'MF',
    Category: 'Mid-Cap Growth',
    Institutional: true,
    Sponsor: 'JPMorgan',
    Logo: 'JPMorgan.png',
    data: [
      {
        field_name: 'Energy',
        value: 0.0377769,
      },
    ],
  },
  {
    ID: 'MSFOUSA02TYM',
    ISIN: 'US3391831050',
    CUSIP: '339183105',
    Ticker: 'JMVSX',
    Name: 'JPMorgan Mid Cap Value I',
    LegalName: 'JPMorgan Mid Cap Value Fund Class I',
    SecurityType: 'MF',
    Category: 'Mid-Cap Value',
    Institutional: true,
    Sponsor: 'JPMorgan',
    Logo: 'JPMorgan.png',
    data: [
      {
        field_name: 'Energy',
        value: 0.0445324,
      },
    ],
  },
  {
    ID: 'MSFOUSA06UIZ',
    ISIN: 'US4812A44355',
    CUSIP: '4812A4435',
    Ticker: 'JMTSX',
    Name: 'JPMorgan Total Return I',
    LegalName: 'JPMorgan Total Return Fund Class I Shares',
    SecurityType: 'MF',
    Category: 'Intermediate Core Bond',
    Institutional: true,
    Sponsor: 'JPMorgan',
    Logo: 'JPMorgan.png',
    data: [
      {
        field_name: 'Energy',
        value: 0,
      },
    ],
  },
  {
    ID: 'MSF00001099D',
    ISIN: 'US46641Q2333',
    CUSIP: '46641Q233',
    Ticker: 'BBAX',
    Name: 'JPMorgan BetaBuilders Dev APAC ex-JpnETF',
    LegalName: 'JPMorgan BetaBuilders Developed Asia Pacific ex-Japan ETF',
    SecurityType: 'ETF',
    Category: 'Pacific/Asia ex-Japan',
    Sponsor: 'JPMorgan',
    Logo: 'JPMorgan.png',
    data: [
      {
        field_name: 'Energy',
        value: 0.041817599999999996,
      },
    ],
  },
  {
    ID: 'MSFOUSA05BV2',
    ISIN: 'US4812A25958',
    CUSIP: '4812A2595',
    Ticker: 'JVASX',
    Name: 'JPMorgan Value Advantage I',
    LegalName: 'JPMorgan Value Advantage Fund Class I',
    SecurityType: 'MF',
    Category: 'Large-Cap Value',
    Institutional: true,
    Sponsor: 'JPMorgan',
    Logo: 'JPMorgan.png',
    data: [
      {
        field_name: 'Energy',
        value: 0.0829498,
      },
    ],
  },
  {
    ID: 'MSFOUSA00L2F',
    ISIN: 'US4812A13731',
    CUSIP: '4812A1373',
    Ticker: 'VSEIX',
    Name: 'JPMorgan Small Cap Equity I',
    LegalName: 'JPMorgan Small Cap Equity Fund Class I',
    SecurityType: 'MF',
    Category: 'Small-Cap Blend',
    Institutional: true,
    Sponsor: 'JPMorgan',
    Logo: 'JPMorgan.png',
    data: [
      {
        field_name: 'Energy',
        value: 0.0151559,
      },
    ],
  },
];

export const RECENTLY_VIEWED_USER: OverviewClientsProps[] = [
  {
    name: 'Jerry Smithers',
  },
  {
    name: 'John Doe',
  },
  {
    name: 'Alex McCarthy',
  },
  {
    name: 'Jean Louis',
  },
];

export const PORTFOLIO_HISTORY_DATA = [
  {
    date: '07/18/2023 • 12:00 PM (MST)',
    sub_text: 'Model portfolio match for Jace’s portfolio',
    title: 'Risk-based model portfolio suggestion',
    desc: `BlackRock target allocation ETF 70/30 Model`,
    cta: 'Display conversation',
  },
  {
    date: '07/18/2023 • 12:00 PM (MST)',
    sub_text: 'Recommendation made for Jace’s portfolio ',
    title: 'Rely On Core',
    desc: `A stronger growth outlook and higher bond yields suggest that
    multi-asset portfolios are set to deliver on their “best in a
    decade” promise.`,
    cta: 'Display implementation',
  },
  {
    date: '07/18/2023 • 12:00 PM (MST)',
    sub_text: 'Recommendation made for Jace’s portfolio ',
    title: 'Rely On Core',
    desc: `A stronger growth outlook and higher bond yields suggest that
    multi-asset portfolios are set to deliver on their “best in a
    decade” promise.`,
    cta: 'Display implementation',
  },
];

export const PORTFOLIO_HOLDING_DATA = [
  {
    title: 'iShares Core US Agg Bond ETF',
    ticker: 'AGG',
    weight: '56%',
    noOfShares: '345.22',
    value: '$12,450.00',
  },
  {
    title: 'iShares Core US Agg Bond ETF',
    ticker: 'AGG',
    weight: '56%',
    noOfShares: '345.22',
    value: '$12,450.00',
  },
  {
    title: 'iShares Core US Agg Bond ETF',
    ticker: 'AGG',
    weight: '56%',
    noOfShares: '345.22',
    value: '$12,450.00',
  },
  {
    title: 'iShares Core US Agg Bond ETF',
    ticker: 'AGG',
    weight: '56%',
    noOfShares: '345.22',
    value: '$12,450.00',
  },
];

export const COLORS = [
  '#111111',
  '#FF4C56',
  '#F540DA',
  '#76E0D3',
  '#00A9D7',
  '#FFA400',
];
export const TABLE_EXTRA_OPTIONS = [
  {
    name: 'View Profile',
    Icon: MemoUser,
    path: '/conversations/stream',
  },
  {
    name: 'View Portfolio',
    Icon: MemoPortfolio,
    path: '/conversations/stream',
  },
  {
    name: 'Client Brief',
    Icon: MemoClientBrief,
    path: '/conversations/stream',
  },
];
export const ALLOCATION_MIX = [
  {
    identifier: EAssetMix.assetAllocation,
    name: 'Asset Mix',
  },
  {
    identifier: EAssetMix.regionAllocation,
    name: 'Region Mix',
  },
  {
    identifier: EAssetMix.sectorAllocation,
    name: 'Sector Mix',
  },
];

export const clientFilters = [
  { name: EClientFilter.All, disabled: false },
  { name: EClientFilter.Clients, disabled: false },
  { name: EClientFilter.Prospects, disabled: false },
];

export const holdingHeadersJson = [
  { key: 'Fund', value: 'name', width: '70%' },
  { key: 'Proposed Weight', value: 'weight', width: '30%' },
  // { key: 'Value', value: 'price', width: '30%' },
];

export const sampleCsvData = [
  ['model_name', 'ticker', 'weight'],
  ['Opportunistic Growth 1', 'IWM', 25],
  ['Opportunistic Growth 1', 'VOO', 25],
  ['Opportunistic Growth 1', 'XNTK', 25],
  ['Opportunistic Growth 1', 'MSFT', 5],
  ['Opportunistic Growth 1', 'XLK', 20],
  ['Technology focused Model', 'ARKW', 20],
  ['Technology focused Model', 'SKYY', 20],
  ['Technology focused Model', 'WCLD', 20],
  ['Technology focused Model', 'TECB', 30],
  ['Technology focused Model', 'IYW', 10],
];

export const TIFIN_LEGAL_CONSTANT = {
  PRIVACY_POLICY: 'https://www.tifin.com/privacy-policy/',
  TERMS_AND_CONDITIONS: 'https://tifin.com/terms-conditions/',
};

export const cashSecuritySearchList = ['$', '$c', '$ca', '$cas', '$cash'];
