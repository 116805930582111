/* eslint-disable react/prop-types */

import React from 'react';

function BackArrowIcon(props: any): React.JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || '24'}
      height={props.height || '24'}
      fill="none"
      viewBox="0 0 24 24"
    >
      <g>
        <path
          stroke={props.stroke || '#344054'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={props.strokeWidth || '2'}
          d="M14 18l-6-6 6-6"
        ></path>
      </g>
    </svg>
  );
}

const MemoBackArrowIcon = React.memo(BackArrowIcon);
export default MemoBackArrowIcon;
