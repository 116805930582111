import { TableCell } from '@mui/material';
import styled from 'styled-components';

export const TableHeader = styled(TableCell)`
  font-size: 13px !important;
  padding: 8px 15px !important;
  background-color: #f9fafb;
`;

export const TableColumn = styled(TableCell)`
  font-size: 12px !important;
  padding: 10px 15px !important;
`;

export const InnerTableColumn = styled(TableCell)`
  padding: 5px 15px !important;
`;
