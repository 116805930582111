import React from 'react';
import {
  Box,
  Button,
  Pagination,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

interface CustomPaginationProps {
  page: number;
  count: number;
  rowsPerPage: number;
  onPageChange: (newPage: number) => void;
  parentWidth?: string;
}

const CustomPagination: React.FC<CustomPaginationProps> = ({
  page,
  count,
  rowsPerPage,
  onPageChange,
  parentWidth = '100%',
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    newPage: number,
  ) => {
    onPageChange(newPage);
  };

  const goBackToPrev = () => {
    const newPage = page - 1;
    onPageChange(newPage);
  };

  const goToNextPage = () => {
    const newPage = page + 1;
    onPageChange(newPage);
  };

  /*   const buttonStyles = {
    backgroundColor: count === 0 || page === 1 ? 'grey' : '#fff',
    color: '#000',
    fontSize: isSmallScreen ? '0.5rem' : '1rem',
    padding: isSmallScreen ? '2px 4px' : '8px 16px',
  }; */

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent={!isSmallScreen ? 'space-between' : 'center'}
      my={2}
      width={parentWidth}
    >
      {!isSmallScreen ? (
        <Box>
          <Button
            disabled={count === 0 || page === 1}
            onClick={goBackToPrev}
            variant="outlined"
            // style={buttonStyles}
          >
            <ArrowBackIcon /> &nbsp;Previous
          </Button>
        </Box>
      ) : null}

      <Box>
        <Pagination
          size={!isSmallScreen ? 'medium' : 'small'}
          page={page}
          count={Math.ceil(count / rowsPerPage)}
          onChange={handleChangePage}
          siblingCount={isSmallScreen ? 0 : 1}
        />
      </Box>
      {!isSmallScreen ? (
        <Box>
          <Button
            variant="outlined"
            style={{
              // ...buttonStyles,
              backgroundColor:
                count === 0 ||
                page > count / rowsPerPage ||
                page === Math.ceil(count / rowsPerPage)
                  ? 'grey'
                  : '#fff',
            }}
            onClick={goToNextPage}
            disabled={
              count === 0 ||
              page > count / rowsPerPage ||
              page === Math.ceil(count / rowsPerPage)
            }
          >
            Next &nbsp;
            <ArrowForwardIcon />
          </Button>
        </Box>
      ) : null}
    </Box>
  );
};

export default CustomPagination;
