export enum EGetStartedQueries {
  TakeATour = 'Take a tour',
  ConnectClientAccounts = 'Connect Client Accounts',
  IdentifyOpportunities = 'Identify opportunities',
  IdeateOnPortfolios = 'Ideate on Portfolios',
  PrepareForMeeting = 'Prepare for a meeting',
  EvaluateModelsAndProducts = 'Evaluate Models & Products',
  UncoverHiddenRisks = 'Uncover hidden risks',
  CreateProposal = 'Create a Proposal (Coming Soon)',
  Viewpoints = 'Viewpoints',
  Personalization = 'Personalization',
  InvestmentSolutions = 'Investment Solutions',
}

export enum EAssetMix {
  assetAllocation = 'assetAllocation',
  regionAllocation = 'regionAllocation',
  sectorAllocation = 'sectorAllocation',
}

export enum AllocationType {
  AssetAllocation = 'Asset Allocation',
  SectorAllocation = 'Sector Allocation',
  RegionAllocation = 'Region Allocation',
}

export enum EGetTooltipLegendColors {
  Color1 = '#F540DA',
  Color2 = '#9C84FC',
}

export enum EAdjustmentStepper {
  ProposedAdjustments = 'PROPOSED_ADJUSTMENTS',
  PortfolioComparison = 'PORTFOLIO_COMPARISON',
}

export enum EColorMapping {
  Current = '#F540DA',
  Proposed = '#9C84FC',
}

export enum EQuestionToNavigate {
  WhichViewpointsImpactClients = 'Which viewpoints impact my clients?',
}

export enum EConvertedQuestionMapping {
  'Compare the performance of funds in different market environments' = 'Compare the performance of SPY and AGG in different market environments',
  'Find replacements for a fund' = 'Find replacements for BEXIX',
}

export enum ECustodians {
  Schwab = 'Schwab',
  FidelityInvestmentsTrust = 'FidelityInvestmentsTrust',
  FidelityInstitutionalBrokerage = 'FidelityInstitutionalBrokerage',
  Pershing = 'Pershing',
  Orion = 'Orion',
  Altruist = 'Altruist',
}

export enum EClientFilter {
  All = 'All',
  Clients = 'Clients',
  Prospects = 'Prospects',
  ViewpointOpportunities = 'Viewpoint Opportunities',
}

export enum EClientAction {
  AddClient = 'Add Client',
  AddProspect = 'Add Prospect',
}

export enum EClientModelUploadTypes {
  UploadModelCsv = 'Upload Model CSV',
  InputModelManually = 'Input Model Manually',
}

export enum ECURRENT_MARKET_TABS {
  CIO_VIEWS = 'CIO_VIEWS',
  MODEL_COMMENTARY = 'MODEL_COMMENTARY',
  CURRENT_EVENTS = 'CURRENT_EVENTS',
  ARCHIVE = 'ARCHIVE',
}
