import {
  Button,
  Grid,
  Modal,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { StyledApplyButton, StyledButton } from '@pages/CIOViewpoints/styles';
import { createAdvisor } from './mutations';
import { useMutation, useQueryClient } from 'react-query';
import { getAuthDetails } from '@features/Profile/selector';
import { useSelector, useDispatch } from 'react-redux';
import { actions as globalActions } from 'app/features/Global/slice';
import { ModalContainer, StyledLabel } from '@pages/SuperAdminPage/styles';

interface AddAdvisorModalProps {
  isOpen: boolean;
  onClose: () => void;
}

function AddAdvisorModal({
  isOpen,
  onClose,
}: AddAdvisorModalProps): React.JSX.Element {
  const { mutate } = useMutation(createAdvisor);
  const authState = useSelector(getAuthDetails);
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    const payload = {
      advisoryFirmId: authState?.advisoryFirmId,
      firstName,
      lastName,
      email,
    };

    mutate(payload, {
      onSuccess: (response: any) => {
        if (response && response.data) {
          queryClient.invalidateQueries('get-firm-details-for-firm-admin');
          dispatch(
            globalActions.displayToast({
              duration: 3000,
              toastType: 'success',
              toastMessage: response?.message,
            }),
          );
          setFirstName('');
          setLastName('');
          setEmail('');
          onClose();
        } else {
          console.log('Unexpected response:', response);
          dispatch(
            globalActions.displayToast({
              duration: 3000,
              toastType: 'error',
              toastMessage: 'Unexpected response from the server.',
            }),
          );
        }
      },
      onError: (error: any) => {
        console.log({ error });
        if (error?.response?.data && error?.response?.data?.message) {
          dispatch(
            globalActions.displayToast({
              duration: 3000,
              toastType: 'error',
              toastMessage: error?.response?.data?.message,
            }),
          );
        } else {
          dispatch(
            globalActions.displayToast({
              duration: 3000,
              toastType: 'error',
              toastMessage: 'An error occurred while processing the query.',
            }),
          );
        }
      },
    });
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <ModalContainer
        container
        rowGap={2.5}
        sx={{
          padding: isMobile ? '1.5rem' : '2.5rem',
          maxWidth: isMobile ? '95%' : '600px',
          width: '100%',
          margin: 'auto',
        }}
      >
        <Grid
          item
          container
          xs={12}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Typography variant="h6" component="div">
            Add Advisor
          </Typography>
          <Button
            onClick={onClose}
            style={{
              minWidth: 'unset',
              padding: '5px',
              marginLeft: 'auto',
            }}
          >
            <CloseIcon />
          </Button>
        </Grid>
        <Grid item container xs={12} columnGap={1}>
          <Grid item container xs={isMobile ? 12 : 5.8}>
            <StyledLabel htmlFor="firstName">First Name</StyledLabel>
            <TextField
              id="firstName"
              variant="outlined"
              size="small"
              fullWidth
              placeholder="Enter First Name"
              style={{ paddingTop: '5px' }}
              value={firstName}
              onChange={e => setFirstName(e.target.value)}
            />
          </Grid>
          <Grid item container xs={isMobile ? 12 : 5.8}>
            <StyledLabel htmlFor="lastName">Last Name</StyledLabel>
            <TextField
              id="lastName"
              variant="outlined"
              size="small"
              fullWidth
              placeholder="Enter Last Name"
              style={{ paddingTop: '5px' }}
              value={lastName}
              onChange={e => setLastName(e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid item container xs={12}>
          <StyledLabel htmlFor="email">Email</StyledLabel>
          <TextField
            id="email"
            variant="outlined"
            size="small"
            fullWidth
            placeholder="Enter Email"
            style={{ paddingTop: '5px' }}
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
        </Grid>
        <Grid
          item
          container
          xs={12}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <StyledButton
            variant="outlined"
            onClick={onClose}
            style={{ marginTop: '10px', width: isMobile ? '48%' : 'auto' }}
          >
            Cancel
          </StyledButton>
          <StyledApplyButton
            variant="outlined"
            onClick={handleSubmit}
            style={{ marginTop: '10px', width: isMobile ? '48%' : 'auto' }}
            disabled={!firstName || !lastName || !email}
          >
            Confirm
          </StyledApplyButton>
        </Grid>
      </ModalContainer>
    </Modal>
  );
}

export default AddAdvisorModal;
