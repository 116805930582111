/* eslint-disable react/no-unescaped-entities */
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import {
  ChatTableBody,
  ChatTableCell,
  ChatTableContainer,
  ChatTableHead,
  ChatTableRow,
  ChatTableSubTitle,
  ChatTableTitle,
  ChatTableWrapper,
  PopoverListText,
  PopoverMenuItem,
} from './styles';
import SwapVertIcon from '@mui/icons-material/Height';
import StraightIcon from '@mui/icons-material/ArrowRightAlt';
import IconButton from '@mui/material/IconButton';
import { formatFloatWithCommas } from 'utils/common/number';
import Popover from '@components/UIComponents/Popover';
import { Box, Checkbox, ListItemIcon, MenuList } from '@mui/material';
import MemoEllipses from '@icons/Ellipses';
import { TABLE_EXTRA_OPTIONS } from '@common/constant';

const showElipsis = false;
const TableHeader = ({
  columns,
  setSortData,
  sortColumn,
  sortOrder,
  numSelected,
  rowCount,
  onSelectAllClick,
  allowCheckbox,
}: any): React.JSX.Element => (
  <ChatTableRow>
    {allowCheckbox && (
      <ChatTableCell
        key={`table-header-row-cell-${name}}`}
        sx={{
          fontSize: '12px',
          width: '20px',
          height: '20px',
          paddingTop: '22px !important',
        }}
      >
        <Checkbox
          indeterminate={numSelected > 0 && numSelected < rowCount}
          checked={rowCount > 0 && numSelected === rowCount}
          onChange={onSelectAllClick}
          sx={{
            color: '#DEDEDF',
            padding: '0',
          }}
        />
      </ChatTableCell>
    )}
    {columns?.map(({ name, sortable }: any) => {
      return (
        <ChatTableCell
          key={`table-header-row-cell-${name}}`}
          sx={{
            fontSize: '12px !important',
            textAlign: 'left',
            paddingTop: '22px !important',
          }}
        >
          {name}
          {sortable && (
            <IconButton
              sx={{
                color: '#86858B',
                p: 0,
                transform: `rotateZ(${sortColumn === name ? (sortOrder ? 90 : -90) : 0}deg); scale: 0.75`,
                transition: 'all 0.15s ease-in',
              }}
              onClick={() => setSortData(name)}
            >
              {name === sortColumn ? <StraightIcon /> : <SwapVertIcon />}
            </IconButton>
          )}
        </ChatTableCell>
      );
    })}
    <ChatTableCell />
  </ChatTableRow>
);
const TableRow = ({
  columns,
  index,
  row,
  onClick,
  selected,
  allowCheckbox,
}: any): React.JSX.Element => (
  <ChatTableRow
    key={`table-body-row-${index}`}
    sx={{
      borderLeft:
        columns.includes('highlight') && row.highlight
          ? '3px solid #7B73E4'
          : '3px solid transparent',
    }}
  >
    {allowCheckbox && (
      <ChatTableCell
        sx={{
          backgroundColor: allowCheckbox && selected ? '#F7F7F7' : null,
        }}
      >
        <Checkbox
          color="primary"
          checked={selected}
          onChange={onClick}
          sx={{
            color: '#DEDEDF',
            padding: '0',
          }}
        />
      </ChatTableCell>
    )}

    {columns?.map((header: any, colIndex: number) => {
      let float = false;
      let val = row[header];
      let fValue = parseFloat(val);
      if (isNaN(fValue)) {
        if (typeof val === 'string' && val.startsWith('$')) {
          fValue = parseFloat(val.split('$', 2)[1]);
          if (!isNaN(fValue)) {
            val = `$ ${formatFloatWithCommas(fValue)}`;
            float = true;
          }
        }
      } else {
        float = true;
      }
      return (
        <ChatTableCell
          key={`table-body-row-cell-${index}-${colIndex}`}
          align={colIndex === 0 ? 'left' : 'center'}
          sx={{
            fontSize: '14px !important',
            textAlign: float ? 'right!important' : 'inherit',
            backgroundColor:
              allowCheckbox && selected
                ? '#F7F7F7'
                : row.highlight
                  ? '#EFEFFD'
                  : '#fff',
            color: colIndex === 0 ? '#535256' : '#86858B',
            alignItems: 'flex-end',
            justifyContent:
              index === columns.length - 1 ? 'flex-end' : 'flex-end',
            cursor: header === 'Ticker' ? 'pointer' : null,
          }}
        >
          <div style={{ marginRight: '12px' }}>{val}</div>
          {allowCheckbox && colIndex === 0 && (
            <Box sx={{ color: '#86858B', fontSize: '12px' }}>{''}</Box>
          )}
        </ChatTableCell>
      );
    })}
    {showElipsis && (
      <ChatTableCell
        align="right"
        sx={{
          backgroundColor: allowCheckbox && selected ? '#EFEFFD' : null,
        }}
      >
        <Popover
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
          icon={<MemoEllipses />}
        >
          <MenuList>
            {TABLE_EXTRA_OPTIONS.map(item => (
              <PopoverMenuItem key={item.name}>
                {item.Icon && (
                  <Fragment>
                    {typeof item.Icon === 'string' ? (
                      <img src={item.Icon} alt={item.name} />
                    ) : (
                      <ListItemIcon>{<item.Icon />}</ListItemIcon>
                    )}
                  </Fragment>
                )}
                <PopoverListText primary={item.name} />
              </PopoverMenuItem>
            ))}
          </MenuList>
        </Popover>
      </ChatTableCell>
    )}
  </ChatTableRow>
);

const CustomTable = ({
  data,
  transformed = false,
  allowCheckbox = false,
  title,
  subtitle,
}: {
  data: any;
  transformed?: boolean;
  allowCheckbox?: boolean;
  title?: string;
  subtitle?: string;
}): React.JSX.Element => {
  const [sortColumn, setSortColumn] = useState('');
  const [sortOrder, setSortOrder] = useState(1);
  const [sortedData, setSortedData] = useState([]);
  const [selected, setSelected] = useState<readonly number[]>([]);

  const transformedData = useMemo(() => {
    if (transformed) return data;
    const newData = data?.map(item => {
      const transformedDataItem = {};
      item.columns?.forEach(dataItem => {
        transformedDataItem[dataItem.field_name] = dataItem.value;
      });
      return transformedDataItem;
    });

    return newData;
  }, [data, transformed]);

  if (!sortedData.length && transformedData.length)
    setSortedData(transformedData);

  const sortData = (col, ord) => {
    return sortedData.sort((a: any, b: any) => {
      const valA =
        typeof a[col] === 'string' && a[col].startsWith('$')
          ? parseFloat(a[col].split('$')[1])
          : parseFloat(a[col]);
      const valB =
        typeof b[col] === 'string' && b[col].startsWith('$')
          ? parseFloat(b[col].split('$')[1])
          : parseFloat(b[col]);
      if (valA > valB) return ord ? 1 : -1;
      if (valA < valB) return ord ? -1 : 1;
      return 0;
    });
  };

  const setSortData = (columnName: string) => {
    const _sortOrder =
      columnName === sortColumn ? (sortOrder === 0 ? 1 : 0) : 0;
    setSortOrder(_sortOrder);
    setSortColumn(columnName);
    setSortedData(sortData(columnName, _sortOrder));
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = sortedData.map((_n: any, i: number) => i);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (id: number) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: number[] = [];

    switch (selectedIndex) {
      case -1:
        newSelected = newSelected.concat(selected, id);
        break;
      case 0:
        newSelected = newSelected.concat(selected.slice(1));
        break;
      case selected.length - 1:
        newSelected = newSelected.concat(selected.slice(0, -1));
        break;
      default:
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
        );
    }
    setSelected(newSelected);
  };

  const isSelected = (id: number) => selected.indexOf(id) !== -1;

  const headers = useMemo(() => {
    return Object.keys(transformedData[0] || {}).map(k => {
      return {
        name: k,
        sortable:
          !isNaN(parseFloat(transformedData[0][k])) ||
          (typeof transformedData[0][k] === 'string' &&
            transformedData[0][k].startsWith('$') &&
            !isNaN(Number(transformedData[0][k].split('$')[1]))),
      };
    });
  }, transformedData);

  useEffect(() => {
    setSortData(headers.filter(a => a.sortable)[0]?.name);
  }, [headers]);

  return (
    <Box
      sx={{
        borderRadius: '6px',
        background: '#F7F7F7',
        p: '1rem',
      }}
    >
      {title && <ChatTableTitle>Table Headline</ChatTableTitle>}
      {subtitle && <ChatTableSubTitle>Table Sub Head</ChatTableSubTitle>}
      <ChatTableContainer
        sx={{
          width: 'fit-content',
          maxWidth: '100%',
          border: 'none',
          maxHeight: '60vh',
        }}
      >
        <ChatTableWrapper
          stickyHeader
          data-key="content-default-table-container"
          sx={{
            border: '10px solid #fff',
          }}
        >
          <ChatTableHead>
            <TableHeader
              columns={headers}
              setSortData={setSortData}
              sortColumn={sortColumn}
              sortOrder={sortOrder}
              onSelectAllClick={handleSelectAllClick}
              rowCount={sortedData.length}
              numSelected={selected.length}
              allowCheckbox={allowCheckbox}
            />
          </ChatTableHead>
          <ChatTableBody>
            {sortedData?.map((row: any, rowIndex: number) => (
              <TableRow
                key={rowIndex}
                columns={headers.map(h => h.name)}
                index={rowIndex}
                row={row}
                onClick={() => handleClick(rowIndex)}
                selected={isSelected(rowIndex)}
                allowCheckbox={allowCheckbox}
              />
            ))}
          </ChatTableBody>
        </ChatTableWrapper>
      </ChatTableContainer>
    </Box>
  );
};

export default CustomTable;
