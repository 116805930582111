import { Grid, styled, Tooltip } from '@mui/material';
import React, { useEffect } from 'react';
import rightArrowPurple from '@images/right-arrow-purple.svg';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getChatInitResponseState } from '@features/Chat/selector';
import { actions } from '@features/Chat/slice';
import { useRESTQuery } from '@hooks/useRESTQuery';
import { ClientsLoaderContainer } from '@components/Loader';
import { Spinner } from '@pages/IntegrationPage/styles';
import {
  EConvertedQuestionMapping,
  EGetStartedQueries,
  EQuestionToNavigate,
} from 'app/enums';
import HomepageCard from './HomepageCard';
import { shouldForwardProp } from 'utils/forwardProp';
interface CardData {
  title: string;
  description: string;
  disabled?: boolean;
}

interface ColumnData {
  header: string;
  cards: CardData[];
}

const columnsData: ColumnData[] = [
  {
    header: 'GET STARTED',
    cards: [
      {
        title: EGetStartedQueries.TakeATour,
        description: 'See what Sage can do',
      },
      {
        title: EGetStartedQueries.ConnectClientAccounts,
        description: 'Link your CRM or custodian to integrate client data',
      },
    ],
  },
];

const EllipsisText = styled('div', { shouldForwardProp })<{
  lineClamp: number;
}>`
  display: -webkit-box;
  -webkit-line-clamp: ${props => props.lineClamp};
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const getConvertedQuestion = (question: string): string => {
  return EConvertedQuestionMapping[question] || question;
};

function Homepage(): React.JSX.Element {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isChatStreaming = localStorage.getItem('streaming') as string;
  const { data: questionsData } = useRESTQuery(
    ['extract-questions'],
    {
      endpoint: `/enterprise/enterpriseQuestions`,
      method: 'GET',
      headers: {
        'X-Tifin-Ai-Auth': localStorage.getItem('x-tifin-ai-token'),
      },
    },
    {
      select: res => [
        {
          type: 'sample_categories',
          content: [
            ...res.data
              .filter(category => category.question.length > 0)
              .map(question => {
                return {
                  head: question?.category,
                  list: question?.question,
                  headerText: question?.title,
                };
              }),
          ],
        },
      ],
    },
  );

  const { loading } = useSelector(getChatInitResponseState);

  const handleCardClick = (title: string) => {
    let newTitle = title;
    if (title.startsWith('Create a proposal')) {
      newTitle = 'Create a Proposal (Coming Soon)';
    }

    switch (newTitle) {
      case EGetStartedQueries.TakeATour:
        navigate('/tour');
        break;
      case EGetStartedQueries.ConnectClientAccounts:
        navigate('/integrations');
        break;
      case EGetStartedQueries.IdentifyOpportunities:
        navigate('/clients', { state: { showInfo: true } });
        break;
      default:
        navigate('/conversations');
        break;
    }
  };

  const handleCardClickV2 = (question: string) => {
    question = getConvertedQuestion(question);
    if (question === EQuestionToNavigate.WhichViewpointsImpactClients) {
      navigate('/actions');
      return;
    }

    if (isChatStreaming === 'true') {
      dispatch(actions.setSearchQuery(question));
    } else {
      dispatch(actions.searchQueryRequest({ query: question }));
    }

    navigate('/conversations/stream', { state: { scroll: 0 } });
  };

  const handleKeyDown = (event: React.KeyboardEvent, callback: () => void) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      callback();
    }
  };

  useEffect(() => {
    dispatch(actions.setSampleQuestion({ sampleQuestions: questionsData }));
  }, [questionsData, loading]);

  if (loading) {
    return (
      <ClientsLoaderContainer>
        <Spinner />
      </ClientsLoaderContainer>
    );
  }

  return (
    <Grid
      container
      item
      mb={2}
      columnGap={1}
      rowGap={1}
      justifyContent={'flex-start'}
      alignItems={'center'}
    >
      {columnsData.map((column, index) => (
        <Grid
          key={index}
          item
          container
          sm={5.8}
          md={3.9}
          lg={2.9}
          justifyContent={'flex-start'}
        >
          <Grid
            item
            xs={12}
            pb={1}
            sx={{
              fontSize: '12px',
              color: '#535256',
              fontWeight: 500,
              letterSpacing: '1.2px',
            }}
          >
            {column.header}
          </Grid>
          <Grid item container xs={12} direction={'column'} rowGap={1}>
            {column.cards.map((card, cardIndex) => (
              <Grid
                key={cardIndex}
                item
                container
                alignItems={'flex-start'}
                tabIndex={0}
                sx={{
                  height: '136px',
                  padding: '14px 16px',
                  gap: '6px',
                  border: '1px solid #282829',
                  pointerEvents: card.disabled ? 'none' : 'auto',
                  opacity: card.disabled ? 0.5 : 1,
                  cursor: 'pointer',
                  background:
                    column.header === 'GET STARTED' ? '#fff' : '#282829',
                  color: column.header === 'GET STARTED' ? '#282829' : '#fff',
                  '.description': {
                    color:
                      column.header === 'GET STARTED' ? '#535256' : '#c0c0c5',
                  },
                  '&:hover': {
                    background: '#535256',
                    color: '#fff',
                    '.description': {
                      color:
                        column.header === 'GET STARTED' ? '#DEDEDF' : '#c0c0c5',
                    },
                  },
                }}
                onClick={() => handleCardClick(card.title)}
                onKeyDown={event =>
                  handleKeyDown(event, () => handleCardClick(card.title))
                }
              >
                <Grid item container direction={'column'} rowGap={1}>
                  <Grid
                    item
                    container
                    direction={'row'}
                    justifyContent={'space-between'}
                  >
                    <Tooltip
                      title={<span>{card.title}</span>}
                      placement="top"
                      arrow
                    >
                      <Grid
                        item
                        container
                        xs={10}
                        justifyContent={'flex-start'}
                        sx={{
                          fontSize: '14px',
                        }}
                      >
                        <EllipsisText lineClamp={2}>{card.title}</EllipsisText>
                      </Grid>
                    </Tooltip>
                    <Grid
                      item
                      container
                      justifyContent={'flex-end'}
                      alignItems={'center'}
                      xs={2}
                    >
                      <img
                        src={rightArrowPurple}
                        alt="Right purple arrow image"
                      />
                    </Grid>
                  </Grid>
                  <Tooltip
                    title={<span>{card.description}</span>}
                    placement="top"
                    arrow
                  >
                    <Grid
                      item
                      sx={{
                        fontSize: '12px',
                      }}
                      className="description"
                    >
                      <EllipsisText lineClamp={2}>
                        {card.description}
                      </EllipsisText>
                    </Grid>
                  </Tooltip>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      ))}
      <HomepageCard
        handleCardClick={handleCardClickV2}
        data={questionsData?.[0]?.content}
      />
    </Grid>
  );
}

export default Homepage;
