import React, { useCallback, useState } from 'react';
import { AddClientsBtnText } from './styles';
import { Box, Button, Menu, MenuItem } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import AddClientModal from './AddClientModal';
import { EClientAction } from 'app/enums';
import { ITickerWeights, PortfolioData } from './types';
import useCreatePortfolio from '@features/apis/client/addPortfolio';
import useAddClient from '@features/apis/client/addClient';
const CustomAddClientBox = ({
  openAddClientModal,
  addClientModal,
  setAddClientModal,
  handleClickOpenModel,
}: {
  openAddClientModal: () => void;
  addClientModal: boolean;
  setAddClientModal: (value: boolean) => void;
  handleClickOpenModel: () => void;
}): JSX.Element => {
  const [selectedMenuItem, setSelectedMenuItem] = useState<string>('');

  const [step, setStep] = useState<number>(1);
  const [clientId, setClientId] = useState<string>('');
  const [cleanUpAddClientForm, setCleanUpAddClientForm] =
    useState<boolean>(false);
  const [cleanUpPortfolioModal, setCleanUpPortfolioModal] =
    useState<boolean>(false);

  const { mutate: AddPortfolioClientMutate } = useCreatePortfolio();
  const { mutate: AddClientMutate } = useAddClient();

  const [addClientAnchorEl, setAddClientAnchorEl] =
    useState<null | HTMLElement>(null);

  const handleContinue = useCallback(
    (data: { firstName: string; lastName: string; email: string }) => {
      const advisorId =
        JSON.parse(localStorage.getItem('tifin-ai-loggedUser') || '{}')
          ?.advisorId || '';
      const isProspect = selectedMenuItem === EClientAction.AddProspect;

      const payload = {
        ...data,
        advisorId,
        isProspect,
        selectedMenuItem,
        step,
        setClientId,
        setStep,
        setCleanUpAddClientForm,
      };

      AddClientMutate(payload);
    },
    [
      selectedMenuItem,
      step,
      AddClientMutate,
      setClientId,
      setStep,
      setCleanUpAddClientForm,
    ],
  );

  const handleSubmit = (data: {
    portfolioData: PortfolioData;
    tickerWeights: ITickerWeights[];
  }) => {
    const transformedHoldings = data?.tickerWeights?.map(item => ({
      ticker: item?.ticker.split(' ')[0],
      weight: item?.weight,
    }));
    const payload = {
      clientId: clientId,
      accountNumber: data.portfolioData.accNumber,
      name: data.portfolioData.accName,
      holdings: transformedHoldings,
      setStep,
      step,
      closeAddClientModal,
      setCleanUpPortfolioModal,
    };
    AddPortfolioClientMutate(payload);
  };

  const handleAddClientClick = (event: React.MouseEvent<HTMLElement>) =>
    setAddClientAnchorEl(event.currentTarget);

  const handleMenuItemClick = (menuItem: string) => {
    setSelectedMenuItem(menuItem);
    setAddClientAnchorEl(null);
    openAddClientModal();
  };

  const closeAddClientModal = useCallback(() => {
    setAddClientModal(false);
    setStep(1);
  }, []);
  const AddClientModalContent = useCallback(() => {
    const modalHeaderText =
      selectedMenuItem === EClientAction.AddClient
        ? 'Add New Client'
        : 'Add New Prospect';
    return (
      <AddClientModal
        open={addClientModal}
        onClose={closeAddClientModal}
        step={step}
        refreshData={cleanUpAddClientForm && cleanUpPortfolioModal}
        onSubmit={handleSubmit}
        onContinue={handleContinue}
        headerText={modalHeaderText}
      />
    );
  }, [addClientModal, step]);

  return (
    <>
      <Box>
        <Button
          variant="outlined"
          style={{
            borderRadius: '25px',
            textTransform: 'none',
            height: 32,
            marginRight: 8,
            background: '#F1F1F1',
            border: 'none',
          }}
          color="primary"
          onClick={handleClickOpenModel}
        >
          <AddIcon fontSize="small" />
          &nbsp;<AddClientsBtnText>Add Custom Model</AddClientsBtnText>
        </Button>
        <Button
          variant="contained"
          style={{
            borderRadius: '25px',
            textTransform: 'none',
            height: 32,
          }}
          sx={{ boxShadow: 'none' }}
          color="primary"
          onClick={handleAddClientClick}
        >
          <AddIcon fontSize="small" />
          &nbsp;<AddClientsBtnText>Add Prospect / Client</AddClientsBtnText>
        </Button>
        <Menu
          id="add-client-menu"
          anchorEl={addClientAnchorEl}
          open={Boolean(addClientAnchorEl)}
          onClose={() => setAddClientAnchorEl(null)}
          sx={{
            marginTop: '3px',
            width: '50%',
            '& .MuiPaper-root': {
              width: '26%',
              marginLeft: '0px',
              background: '#fff',
            },
            '& .MuiMenuItem-root:first-of-type': {
              background: '#fff',
            },
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          {Object.values(EClientAction).map((item: EClientAction) => (
            <MenuItem
              key={item}
              sx={{
                fontSize: '12px',
                color: '#101828',
                '& .MuiPaper-root': {
                  background: '#fff',
                },
                '&:hover': {
                  backgroundColor: '#fff',
                },
              }}
              onClick={() => handleMenuItemClick(item)}
            >
              {item}
            </MenuItem>
          ))}
        </Menu>
      </Box>
      <AddClientModalContent />
    </>
  );
};

export default React.memo(CustomAddClientBox);
