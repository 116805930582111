/* eslint-disable react/prop-types */

import React from 'react';

function PlusIcon(props: any): React.JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <g
        stroke={props.stroke || '#FCFDFD'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      >
        <path d="M4.25 12.5h16.5"></path>
        <path d="M12.5 4.25v16.5"></path>
      </g>
    </svg>
  );
}

const MemoNoData = React.memo(PlusIcon);
export default MemoNoData;
